import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  Button,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  Box,
  Divider,
  Chip,
  IconButton,
  Tooltip,
  Skeleton,
} from "@mui/material";
import { FileDownload as FileDownloadIcon } from "@mui/icons-material/";
import useAxios from "../../../../api/useAxios";
import ApproveDialog from "./ApproveDialog";
import AdminSnackBar from "../SnackBars/AdminSnackBar";
import DeclineDialog from "./DeclineDialog";
import { useLocation } from "react-router-dom";
import defaultimg from "../../../../img/mentorImage.png";
import { ROUTINGDATA } from "../../../ROUTINGDATA";
import { saveAs } from "file-saver";
import defaultImage from "../../../../img/mentorImage.png";
function MentorDialog({
  open,
  handleChange,
  getOne,
  setOpen,
  filterName,
  setFilterName,
  fetchApi,
  dialogLoad,
  setGetOne,
}) {
  const [getPutId, setGetPutId] = useState(0);
  const [getEmail, setGetEmail] = useState("");
  const [getPass, setGetPass] = useState("");
  const [value, setValue] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [snackOption, setSnackOption] = useState("");
  const [approveOpen, setApproveOpen] = useState(false);
  const [declineOpen, setDeclineOpen] = useState(false);

  const axiosData = useAxios();
  const baseUrl = axiosData.defaults.baseURL;
  let adminDetails_CEO = JSON.parse(localStorage.getItem("admin_dev"));

  const location = useLocation();
  const currentUrl = location.pathname;
  const updateApprove = async (id, email_id, password) => {
    setFilterName({ ...filterName, isLoading: true });
    try {
      await axiosData.put(`mentor/email/${email_id}`, { status: "A" });
      await axiosData.put(`users/${email_id}`, {
        password: password,
        status: "Active",
        usertype: "mentor",
      });
      setGetPutId(0);
      setGetEmail("");
      setGetPass("");
      setOpen(false);
      setApproveOpen(false);
      setOpenSnack(true);
      setSnackOption("success");
      fetchApi();
    } catch (err) {
      console.log("Error", err);
    }
    setFilterName({ ...filterName, isLoading: false });
  };
  const downloadResume = (id) => {
    saveAs(`${baseUrl}mentor/get/cv/${id}`, id + "'s CV");
  };
  const updateDecline = async (id, email_id) => {
    setFilterName({ ...filterName, isLoading: true });

    try {
      await axiosData.put(`mentor/email/${email_id}`, {
        status: "D",
        reason: value,
      });
      axiosData.put(
        `users/${email_id}`,
        { status: "InActive", usertype: "mentor", reason: value },

        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setGetPutId(0);
      setGetEmail("");
      setValue("");
      setDeclineOpen(false);
      setOpen(false);
      setOpenSnack(true);
      setSnackOption("error");
      fetchApi();
    } catch (err) {
      console.log("Error", err);
    }
    setFilterName({ ...filterName, isLoading: false });
  };
  return (
    <>
      {" "}
      <Dialog
        fullWidth
        open={open}
        onClose={handleChange}
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "20px",
          },
        }}
      >
        <DialogTitle bgcolor="#055779" color="white" height="100%">
          <Box id="main-box">
            <Box>
              {" "}
              <div id className="align-img">
                <img
                  alt="img"
                  id="profileImage"
                  src={
                    getOne.mentorProfile
                      ? `${baseUrl}mentor/get/profile/${getOne.email_id}`
                      : ""
                  }
                  onError={(e) => {
                    e.target.src = defaultImage;
                  }}
                  className="updateProfileImage"
                />
              </div>
            </Box>
            <Box>
              <Typography
                variant="body1"
                sx={{
                  textTransform: "uppercase",
                  fontFamily: "Barlow-Bold",
                }}
              >
                <div className="titleseeker">
                  {getOne.MentorName}
                  <div
                    style={{
                      textTransform: "capitalize",
                      fontFamily: "Barlow-Regular",
                    }}
                  >
                    {/* {getOne.work_status} */}
                  </div>
                </div>
              </Typography>
            </Box>

            <Box>
              {adminDetails_CEO?.emailId ===
              process.env.REACT_APP_EMAILID_FOR_ADMIN_DEVELOPER ? null : (
                <Tooltip title="Download CV" placement="top">
                  <IconButton
                    className="icon-btn-dbox  "
                    onClick={() => downloadResume(getOne.email_id)}
                    size="medium"
                    disabled={!getOne.CVUpload}
                  >
                    <FileDownloadIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent dividers="paper" sx={{ backgroundColor: "#f2f2f2" }}>
          <Box sx={{ padding: "20px" }}>
            <Grid container md={12} columnSpacing={1} direction="column">
              <Divider
                sx={{
                  "&::before, &::after": {
                    borderColor: "#055779",
                  },
                  color: "#055779 ",
                }}
                textAlign="left"
              >
                Personal Details
              </Divider>
              <Box sx={{ padding: "20px" }}>
                <Typography variant="h6" sx={{ fontFamily: "Poppins-Regular" }}>
                  <div className="align-text-mentor">
                    <span className="full-details-mentor"> Full Name:</span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        getOne.MentorName
                      )}
                    </div>
                  </div>
                </Typography>
                <Typography variant="h6">
                  <div className="align-text-mentor">
                    <span className="full-details-mentor"> Email:</span>
                    <div>
                      {" "}
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        getOne.email_id
                      )}
                    </div>
                  </div>
                </Typography>{" "}
                <Typography variant="h6">
                  <div className="align-text-mentor">
                    <span className="full-details-mentor"> Mobile:</span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        getOne.mobileNumber
                      )}
                    </div>
                  </div>
                </Typography>
                <Typography variant="h6">
                  <div className="align-text-mentor">
                    <span className="full-details-mentor">
                      {" "}
                      Official Address:
                    </span>
                    <div>
                      {" "}
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        getOne.officialAddress
                      )}
                    </div>
                  </div>
                </Typography>{" "}
              </Box>
              <Divider
                sx={{
                  "&::before, &::after": {
                    borderColor: "#055779",
                  },
                  color: "#055779 ",
                }}
                textAlign="left"
              >
                Education Details
              </Divider>
              <Box sx={{ padding: "20px" }}>
                <Typography variant="h6">
                  <div className="align-text-mentor">
                    <span className="full-details-mentor">Qualifications:</span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        getOne.educationQualifications
                      )}
                    </div>
                  </div>
                </Typography>
                <Typography variant="h6">
                  <div className="align-text-mentor">
                    <span className="full-details-mentor">
                      {" "}
                      Specialization:
                    </span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        getOne.Specialisation
                      )}
                    </div>
                  </div>
                </Typography>{" "}
              </Box>
              <Divider
                sx={{
                  "&::before, &::after": {
                    borderColor: "#055779",
                  },
                  color: "#055779 ",
                }}
                textAlign="left"
              >
                Professional Details
              </Divider>{" "}
              <Box sx={{ padding: "20px" }}>
                <Typography variant="h6">
                  <div className="align-text-mentor">
                    <span className="full-details-mentor">
                      Area of Expertise:
                    </span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        getOne.areaOfExpertise
                      )}
                    </div>
                  </div>
                </Typography>{" "}
                <Typography variant="h6">
                  <div className="align-text-mentor">
                    <span className="full-details-mentor">
                      {" "}
                      Year of Expertise in this area:
                    </span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        getOne.yearsOfExperience
                      )}
                    </div>
                  </div>
                </Typography>
                <Typography variant="h6">
                  <div className="align-text-mentor">
                    <span className="full-details-mentor"> Status:</span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        <Chip
                          label={getOne.status}
                          color={
                            getOne.status === "P"
                              ? "warning"
                              : getOne.status === "A"
                              ? "success"
                              : "error"
                          }
                          variant="contained"
                          sx={{ textAlign: "left" }}
                        />
                      )}
                    </div>
                  </div>
                </Typography>{" "}
                {getOne.status === "D" ? (
                  <>
                    <Typography variant="h6">
                      <div className="align-text">
                        <span className="full-details"> Reason:</span>
                        <div>{getOne.reason}</div>
                      </div>
                    </Typography>
                  </>
                ) : null}
                {/* <Typography variant="h6">
                  <div className="align-text-mentor">
                    <span className="full-details-mentor">
                      {" "}
                      Current Employer:
                    </span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        getOne.currentEmployer
                      )}
                    </div>
                  </div>
                </Typography>{" "}
                <Typography variant="h6">
                  <div className="align-text-mentor">
                    <span className="full-details-mentor">
                      {" "}
                      Designation(Current employer):
                    </span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        getOne.Designation
                      )}
                    </div>
                  </div>
                </Typography>{" "}
                <Typography variant="h6">
                  <div className="align-text-mentor">
                    <span className="full-details-mentor">
                      {" "}
                      Membership in Professional Societies/Associations
                    </span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        getOne.membershipInProfessional
                      )}
                    </div>
                  </div>
                </Typography>
                <Typography variant="h6">
                  <div className="align-text-mentor">
                    <span className="full-details-mentor">
                      {" "}
                      Membership in Professional Societies/Associations
                    </span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        getOne.membershipInProfessional
                      )}
                    </div>
                  </div>
                </Typography>
                <Typography variant="h6">
                  <div className="align-text-mentor">
                    <span className="full-details-mentor">
                      {" "}
                      Other Professional Certifications or Trainings
                    </span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        getOne.professionalCertifications
                      )}
                    </div>
                  </div>
                </Typography>
                <Typography variant="h6">
                  <div className="align-text-mentor">
                    <span className="full-details-mentor">
                      {" "}
                      Experience in providing guidance/ handholding
                    </span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        getOne.Experience
                      )}
                    </div>
                  </div>
                </Typography> */}
              </Box>
              {/*              
              <Box sx={{ padding: "20px" }}>
                <Divider
                  sx={{
                    "&::before, &::after": {
                      borderColor: "#055779",
                    },
                    color: "#055779 ",
                  }}
                  textAlign="left"
                >
                  Voting Details
                </Divider>{" "}
                <Typography variant="h6">
                  <div className="align-text">
                    <span className="full-details"> Voting District:</span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        getOne.votingDistrict
                      )}
                    </div>
                  </div>
                </Typography>{" "}
                <Typography variant="h6">
                  <div className="align-text">
                    <span className="full-details"> MP Constituency:</span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        getOne.mpConstituency
                      )}
                    </div>
                  </div>
                </Typography>{" "}
                <Typography variant="h6">
                  <div className="align-text">
                    <span className="full-details"> MLA Constituency:</span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        getOne.mlaConstituency
                      )}
                    </div>
                  </div>
                </Typography>{" "}
                <Typography variant="h6">
                  <div className="align-text">
                    <span className="full-details"> Pin-code:</span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        getOne.pincode
                      )}
                    </div>
                  </div>
                </Typography>{" "}
                <Typography variant="h6">
                  {getOne.videoUpload === undefined ||
                  getOne.videoUpload === "" ? (
                    <div className="align-text">
                      <span className="full-details">Video Profile:</span>
                      <div>No Video Uploaded</div>
                    </div>
                  ) : (
                    <div>
                      <div className="align-text">
                        <span
                          className="full-details"
                          style={{ textAlign: "left" }}
                        >
                          {" "}
                          Video Profile:
                        </span>
                      </div>
                      <div style={{ textAlign: "left" }}>
                        <video
                          className="VideoInput_video"
                          width="100%"
                          height="200px"
                          controls
                          src={
                            getOne.videoUpload !== undefined
                              ? getOne.videoUpload !== ""
                                ? `${baseUrl}seekers/video/${getOne.mobile_number}`
                                : ""
                              : ""
                          }
                        />
                      </div>
                    </div>
                  )}
                </Typography>
                <Typography textAlign="center" variant="h6">
                  <div className="align-text">
                    <span
                      className="full-details"
                      style={{ textAlign: "left" }}
                    >
                      {" "}
                      Status:
                    </span>
                    <div style={{ textAlign: "left" }}>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="rounded"
                          width={40}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        <Chip
                          label={getOne.status}
                          color={
                            getOne.status === "P"
                              ? "warning"
                              : getOne.status === "A"
                              ? "success"
                              : "error"
                          }
                          variant="contained"
                          sx={{ textAlign: "left" }}
                        />
                      )}
                    </div>
                  </div>
                </Typography>
                {getOne.status === "D" ? (
                  <>
                    <Typography variant="h6">
                      <div className="align-text">
                        <span className="full-details"> Reason:</span>
                        <div>{getOne.reason}</div>
                      </div>
                    </Typography>
                  </>
                ) : null}
              </Box> */}
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button sx={{ textTransform: "capitalize" }} onClick={handleChange}>
            Close
          </Button>
          {adminDetails_CEO?.emailId ===
          process.env.REACT_APP_EMAILID_FOR_ADMIN_DEVELOPER ? null : (
            <>
              <Button
                className="action-btn"
                sx={{ textTransform: "capitalize" }}
                onClick={() => {
                  setGetPutId(getOne._id);
                  setGetEmail(getOne.email_id);
                  setGetPass(getOne.password);
                  setApproveOpen(true);
                }}
                variant="contained"
                color="success"
                disabled={getOne.status === "A" ? true : false}
              >
                Approve
              </Button>{" "}
              <Button
                className="action-btn"
                sx={{ textTransform: "capitalize" }}
                color="error"
                onClick={() => {
                  setGetPutId(getOne._id);
                  setGetEmail(getOne.email_id);

                  setDeclineOpen(open);
                }}
                variant="contained"
                disabled={getOne.status === "D" ? true : false}
              >
                Decline
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
      <>
        <ApproveDialog
          approveOpen={approveOpen}
          setApproveOpen={setApproveOpen}
          updateApprove={updateApprove}
          filterName={filterName}
          getPutId={getPutId}
          getEmail={getEmail}
          getPass={getPass}
        />
        <DeclineDialog
          declineOpen={declineOpen}
          setDeclineOpen={setDeclineOpen}
          value={value}
          setValue={setValue}
          filterName={filterName}
          updateDecline={updateDecline}
          getPutId={getPutId}
          getEmail={getEmail}
        />
        <AdminSnackBar
          openSnack={openSnack}
          setOpenSnack={setOpenSnack}
          snackOption={snackOption}
          full_name={getOne.MentorName}
        />
      </>
    </>
  );
}

export default MentorDialog;
