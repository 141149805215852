import React, { useState } from "react";
import {
  CssBaseline,
  TextField,
  Paper,
  Box,
  Grid,
  Typography,
  MenuItem,
  FormControl,
  Autocomplete,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputAdornment,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Tooltip,
  Popper,
  CircularProgress,
  Button,
  Divider,
  Container,
  Chip,
  Select,
  Link,
  Checkbox,
  Avatar,
  Card,
  useMediaQuery,
  FormHelperText,
} from "@mui/material";
import { ReactComponent as VVlogo } from "../../../img/partner/Logo_as_svg.svg";
import {
  ContactPageRounded as ContactPageRoundedIcon,
  HomeWorkRounded as HomeWorkRoundedIcon,
  LockOutlined as LockOutlinedIcon,
  HandshakeRounded as HandshakeRoundedIcon,
  LocationOnRounded as LocationOnRoundedIcon,
  BusinessRounded as BusinessRoundedIcon,
  CurrencyExchangeRounded as CurrencyExchangeRoundedIcon,
  EmailRounded as EmailRoundedIcon,
  ArrowBackRounded as ArrowBackRoundedIcon,
} from "@mui/icons-material";

import { Formik } from "formik";
import * as Yup from "yup";
import { createTheme, useTheme, ThemeProvider } from "@mui/material/styles";

import { useEffect } from "react";
import useAxios from "../../../api/useAxios";
import { ReactComponent as PartnerSvg } from "../../../img/partner/undraw_agreement_re_d4dv.svg";
import "./partner_investor.css";
import { ROUTINGDATA } from "../../ROUTINGDATA";
import { useNavigate } from "react-router-dom";
function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function BecomeAInvestor() {
  const theme = useTheme();
  const navigate = useNavigate();
  const axiosData = useAxios();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isDevice = useMediaQuery(theme.breakpoints.up("md"));
  const isDeviceDown = useMediaQuery(theme.breakpoints.down("md"));
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectDefaultValue, setselectDefultvalue] = useState("Select");
  const [District_pincode, setDistrictPincode] = useState("");
  const [isFocused, setIsFocused] = useState({
    investor_name: false,
    email_id: false,
    mobileNumber: false,
    district_name: false,
    investment_range: false,
    location: false,
    investment_within: false,
  });
  const [captureBlur, setCaptureBlur] = useState({
    investor_name: false,
    email_id: false,
    mobileNumber: false,
    district_name: false,
    investment_range: false,
    location: false,
    investment_within: false,
  });
  const [getStatusCode, setGetStatusCode] = useState(false);
  const [getPincode, setGetPincode] = useState([]);
  const exceptThisSymbols = ["e", "E", "+", "-", ".", "ArrowUp", "ArrowDown"];
  const filterOptions = (options, { inputValue }) => {
    const inputValueLower = inputValue.toLowerCase();
    const startsWithInput = [];
    const containsInput = [];

    options.forEach((option) => {
      const optionLower = option.toLowerCase();
      if (optionLower.startsWith(inputValueLower)) {
        startsWithInput.push(option);
      } else if (optionLower.includes(inputValueLower)) {
        containsInput.push(option);
      }
    });

    return [...startsWithInput, ...containsInput];
  };
  const initialValues = {
    investor_name: "",
    email_id: "",
    mobileNumber: "",
    district_name: "",
    investment_range: "",
    location: "",
    investment_within: "",
  };
  const validationSchema = Yup.object().shape({
    investor_name: Yup.string()
      .max(50, "Must be 50 characters or less")
      .required("Name must be Required"),
    email_id: Yup.string()
      .email("Invalid email address")
      .required("Email must be Required")
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        "Invalid Email Address"
      )
      .test("is-valid-email", "Invalid Email Address", function (value) {
        const isValidEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
          value
        );
        if (!value) {
          return false;
        } else if (!isValidEmail) {
          return false;
        } else if (isValidEmail) {
          if (getStatusCode === 200) {
            return this.createError({
              message: "Email already exist in VelaiVendum.com",
              path: "email_id",
            });
          } else if (getStatusCode === 204) {
            return true;
          }
        } else {
          return true;
        }
        return true;
      }),
    mobileNumber: Yup.number()
      .min(1000000000, "Not Valid Mobile Number!")
      .max(9999999999, "Not Valid Mobile Number!")
      .required("Mobile Number must be Required"),
    district_name: Yup.string().required("District name must be required"),
    investment_range: Yup.string()
      .max(50, "Must be 50 characters or less")
      .required("Investment Range must be Required"),
    location: Yup.string()
      .max(50, "Must be 50 characters or less")
      .required("Location must be Required"),
    investment_within: Yup.string()
      .max(50, "Must be 50 characters or less")
      .required("Investment within must be Required"),
  });

  const handleFormikSubmit = async (values, { resetForm }) => {
    setIsSubmitting(true);

    try {
      await axiosData.post(`investors`, {
        investor_name: values.investor_name,
        email_id: values.email_id,
        mobileNumber: values.mobileNumber,
        district_name: values.district_name,
        investment_range: values.investment_range,
        location: values.location,
        investment_within: values.investment_within,
        typeoffranchise: District_pincode,
      });
      resetForm();
      setCaptureBlur({
        ...captureBlur,
        investor_name: false,
        email_id: false,
        mobileNumber: false,
        district_name: false,
        investment_range: false,
        location: false,
        investment_within: false,
      });
      setIsSubmitting(false);
      navigate(
        `${ROUTINGDATA.LAND}${ROUTINGDATA.MAIN}${ROUTINGDATA.ThankYouForLocal}`
      );
    } catch (err) {
      console.log("error", err);
    }
  };

  const [districtData, setDistrictData] = useState([]);
  const [district, setDistrict] = useState([]);
  const vodingDistrict = async () => {
    try {
      const getOneAxios = await axiosData.get(`/distritarea`);
      const data = getOneAxios.data;
      if (data) {
        const getDistrict = data.map((i) => {
          return i.District;
        });
        getDistrict.sort();
        setDistrictData(getDistrict);
        setDistrict(data);
      }
      return data;
    } catch (err) {
      console.log("Error", err);
    }
  };
  const [pincodeByDistrict, setPincodeByDistrict] = useState([]);
  const getPincodeByDistrict = async (value) => {
    try {
      const pin = await axiosData(`distritarea/getArea/${value}`);
      const { data } = pin;
      if (data) {
        setPincodeByDistrict(data[0].Pincode);
      }
    } catch (err) {}
  };
  const ITEM_HEIGHT = 50;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 350,
      },
    },
  };
  const investment_within = ["1 month", "2 months", "3 months"];
  const investmentRange = ["5-10", "11-20", "21-30", "31-40", "41-50"];
  useEffect(() => {
    vodingDistrict();
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "95vh",
          padding: theme.spacing(2),
          backgroundColor: "#005e82",
        }}
      >
        <Box
          sx={{
            height: "100%",
            display: "flex",
            alignItems: "flex-start",
          }}
        >
          <IconButton
            sx={{
              backgroundColor: "whitesmoke",
              transition: "0.3s all",
              "&:hover": { backgroundColor: "whitesmoke" },
              // width: "200px",
            }}
            onClick={() => navigate(-1)}
          >
            <ArrowBackRoundedIcon />
          </IconButton>
        </Box>
        <Card
          sx={{
            width: isMobile ? "100%" : "90%",
            display: "flex",
            flexDirection: "row",
            borderRadius: 0,
            overflow: "hidden",
            height: isMobile ? "100%" : isDeviceDown ? "80%" : "100%",
            backgroundColor: "#005e82",
            boxShadow: 0,
          }}
        >
          {isMobile ? null : (
            <Box
              sx={{
                width: "35%",
                position: "relative",
                height: "100%",
                backgroundColor: "#005e82",
              }}
            >
              <Box sx={{ my: -3, width: "100%", height: "100%" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "25px",
                    width: "100%",
                    height: "100%",
                    maxHeight: "100%",
                  }}
                >
                  <Box
                    sx={{
                      // height: "20%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CurrencyExchangeRoundedIcon
                      sx={{ fontSize: "10vw", color: "#ffffff" }}
                    />
                  </Box>
                  <Box>
                    {" "}
                    <Typography
                      width={"100%"}
                      variant="h5"
                      sx={{
                        fontFamily:
                          isDevice || isDeviceDown
                            ? "Poppins-Bold"
                            : "Poppins-SemiBold",
                        // fontWeight: "bold",
                        fontSize: isDevice
                          ? "25px"
                          : isDeviceDown
                          ? "3vw"
                          : "1vw",
                        // wordSpacing: "5px",
                        color: "#ffffff",
                      }}
                    >
                      Join Us
                    </Typography>{" "}
                  </Box>
                  <Box>
                    {" "}
                    <Typography
                      width={"90%"}
                      variant="h5"
                      sx={{
                        fontFamily:
                          isDevice || isDeviceDown
                            ? "Poppins-SemiBold"
                            : "Poppins-SemiBold",
                        fontWeight: "bold",
                        fontSize: isDevice
                          ? "15px"
                          : isDeviceDown
                          ? "1vw"
                          : "0.25vw",
                        wordSpacing: "3px",
                        color: "#ffffff",
                        textAlign: "center",
                      }}
                    >
                      Get Started And Become a Investor in Velaivendum.com
                    </Typography>{" "}
                  </Box>
                  <Box width="100%" display={"flex"} justifyContent={"center"}>
                    <Button
                      variant="contained"
                      sx={{
                        textTransform: "capitalize",
                        backgroundColor: "#ffffff",
                        borderRadius: "1.5rem",
                        width: isMobile
                          ? "14vw"
                          : isDeviceDown
                          ? "12vw"
                          : "10vw",
                        fontSize: isMobile
                          ? "0.6vw"
                          : isDeviceDown
                          ? "0.8vw"
                          : "1vw",
                        color: "#000000",
                        transition: "0.3s all",
                        boxShadow: 0,
                        fontFamily: "Poppins-Regular",
                        border: "2px solid #ffffff",
                        fontWeight: 600,
                        "&:hover": {
                          backgroundColor: "#005e82",
                          color: "#ffffff",
                          boxShadow: 0,
                          border: "2px solid #ffffff",
                        },
                      }}
                      onClick={() =>
                        navigate(
                          ROUTINGDATA.LAND + ROUTINGDATA.LANDPAGECONTACTUS
                        )
                      }
                    >
                      Contact Us
                    </Button>
                  </Box>
                  {/* <Box>
                    <PartnerSvg color="#ed1970" height={"50%"} width="50%" />
                  </Box> */}
                </Box>
              </Box>
            </Box>
          )}
          <Box
            sx={{
              width: isMobile ? "100%" : "65%",
              padding: theme.spacing(3),
              overflowY: "auto",
              borderTopLeftRadius: isMobile ? "0 " : "10% 50%",
              borderBottomLeftRadius: isMobile ? "0" : "10% 50%",
              backgroundColor: "#ffffff",
              border: "0.20rem double #005e82",
            }}
            className="form-box"
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-end",
                my: 1,
                // height: "100vh",
              }}
            >
              <Box display="flex" justifyContent={"center"}>
                <VVlogo
                  style={{
                    width: isDevice || isDeviceDown ? "40%" : "20wv",
                    height: "30%",
                  }}
                />
              </Box>

              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleFormikSubmit}
              >
                {(formikProps) => {
                  const disableCondition =
                    formikProps.isValid &&
                    formikProps.dirty &&
                    isSubmitting === false
                      ? false
                      : true;
                  const handleEmailChange = async (value, validateField) => {
                    const emailValidate =
                      validationSchema.fields.email_id.isValid(value);

                    if (emailValidate) {
                      try {
                        const response = await axiosData.post(`users/email`, {
                          email_id: value,
                        });
                        setGetStatusCode(response.status);
                      } catch (error) {
                        console.error("error", error);
                      }
                    }
                  };
                  return (
                    <>
                      <Box
                        display={"flex"}
                        flexDirection={"column"}
                        my={"20px"}
                        alignItems={"center"}
                        gap={"20px"}
                        width={"75%"}
                      >
                        <Box width="100%" textAlign={"center"}>
                          {/* <Typography variant="h5">Registration</Typography> */}
                        </Box>
                        <Box
                          display="flex"
                          flexDirection={
                            isMobile || isDeviceDown ? "column" : "row"
                          }
                          justifyContent={"space-between"}
                          width="100%"
                          gap="10px"
                        >
                          <FormControl sx={{ width: "100%" }}>
                            <FormLabel
                              sx={{ fontWeight: "bold" }}
                              style={{
                                color:
                                  isFocused.investor_name === true &&
                                  (formikProps.errors.investor_name ===
                                    undefined ||
                                    formikProps.touched.investor_name ===
                                      undefined)
                                    ? "#005e82"
                                    : formikProps.errors.investor_name !==
                                        undefined &&
                                      formikProps.touched.investor_name === true
                                    ? "#de6363"
                                    : "#666666",
                              }}
                            >
                              Full Name
                            </FormLabel>
                            <TextField
                              // margin="normal"
                              labelId="demo-simple-select-label"
                              inputProps={{
                                onFocus: () => {
                                  setIsFocused({
                                    ...isFocused,
                                    investor_name: true,
                                    email_id: false,
                                    mobileNumber: false,
                                    district_name: false,
                                    investment_range: false,
                                    location: false,
                                    investment_within: false,
                                  });
                                },
                                onBlur: () => {
                                  setIsFocused({
                                    ...isFocused,
                                    investor_name: false,
                                    email_id: false,
                                    mobileNumber: false,
                                    district_name: false,
                                    investment_range: false,
                                    location: false,
                                    investment_within: false,
                                  });
                                },
                              }}
                              fullWidth
                              required
                              placeholder={"Enter Your Name"}
                              id="Name"
                              // label={t("Full_Name")}
                              name="investor_name"
                              sx={{
                                margin: "0",
                                "& .MuiOutlinedInput-root": {
                                  "&.Mui-focused fieldset": {
                                    borderColor:
                                      isFocused.investor_name === true &&
                                      (formikProps.errors.investor_name ===
                                        undefined ||
                                        formikProps.touched.investor_name ===
                                          undefined)
                                        ? "#005e82"
                                        : "#de6363",
                                  },
                                },
                                // "& .MuiFormHelperText-root": {
                                //   fontSize: "11px",
                                //   mx: -0.1, // Change this color to your desired color
                                // },
                              }}
                              onBlur={formikProps.handleBlur}
                              onChange={(e) => {
                                const { value } = e.target;
                                const shopValue = value.replace(
                                  /[^a-z'. ]/gi,
                                  ""
                                );
                                formikProps.setFieldValue(
                                  "investor_name",
                                  shopValue
                                );
                              }}
                              value={formikProps.values.investor_name}
                              InputLabelProps={{
                                style: { color: "black" },
                              }}
                              InputProps={{
                                style: { color: "black" },
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <ContactPageRoundedIcon
                                      sx={{
                                        color:
                                          isFocused.investor_name === true &&
                                          (formikProps.errors.investor_name ===
                                            undefined ||
                                            formikProps.touched
                                              .investor_name === undefined)
                                            ? "#005e82"
                                            : formikProps.errors
                                                .investor_name !== undefined &&
                                              formikProps.touched
                                                .investor_name === true
                                            ? "#de6363"
                                            : "#666666",
                                      }}
                                    />{" "}
                                  </InputAdornment>
                                ),
                              }}
                              error={
                                formikProps.touched.investor_name &&
                                Boolean(formikProps.errors.investor_name)
                              }
                              helperText={
                                formikProps.touched.investor_name &&
                                formikProps.errors.investor_name
                              }
                            />
                          </FormControl>
                          <FormControl
                            className="ContactDetailsFormControl"
                            fullWidth
                          >
                            <FormLabel
                              sx={{ fontWeight: "bold" }}
                              style={{
                                color:
                                  isFocused.mobileNumber === true &&
                                  (formikProps.errors.mobileNumber ===
                                    undefined ||
                                    formikProps.touched.mobileNumber ===
                                      undefined ||
                                    captureBlur.mobileNumber === false)
                                    ? "#005e82"
                                    : formikProps.errors.mobileNumber !==
                                        undefined &&
                                      formikProps.touched.mobileNumber ===
                                        true &&
                                      captureBlur.mobileNumber === true
                                    ? "#de6363"
                                    : "#666666",
                              }}
                            >
                              Mobile Number
                            </FormLabel>
                            <TextField
                              // margin="normal"
                              labelId="demo-simple-select-label"
                              inputProps={{
                                onFocus: () => {
                                  setIsFocused({
                                    ...isFocused,
                                    partner_name: false,
                                    email_id: false,
                                    mobileNumber: true,
                                    district_name: false,
                                    investment_range: false,
                                    location: false,
                                    yearsOfExperience: false,
                                    business_name: false,
                                  });
                                },
                                onBlur: () => {
                                  setIsFocused({
                                    ...isFocused,
                                    partner_name: false,
                                    email_id: false,
                                    mobileNumber: false,
                                    district_name: false,
                                    investment_range: false,
                                    location: false,
                                    yearsOfExperience: false,
                                    business_name: false,
                                  });
                                },
                              }}
                              required
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  "&.Mui-focused fieldset": {
                                    borderColor:
                                      isFocused.mobileNumber === true &&
                                      (formikProps.errors.mobileNumber ===
                                        undefined ||
                                        formikProps.touched.mobileNumber ===
                                          undefined ||
                                        captureBlur.mobileNumber === false)
                                        ? "#005e82"
                                        : formikProps.errors.mobileNumber !==
                                            undefined &&
                                          formikProps.touched.mobileNumber ===
                                            true &&
                                          captureBlur.mobileNumber === true
                                        ? "#de6363"
                                        : "#666666",
                                  },
                                },
                              }}
                              placeholder={"Enter Your Mobile Number"}
                              name="mobileNumber"
                              // label={t("Mobile_Number")}
                              type="number"
                              onWheel={(e) => {
                                e.preventDefault();
                                e.target.blur();
                              }}
                              onKeyDown={(e) =>
                                exceptThisSymbols.includes(e.key) &&
                                e.preventDefault()
                              }
                              id="mobile_number"
                              onBlur={formikProps.handleBlur}
                              onBlurCapture={() =>
                                setCaptureBlur({
                                  ...captureBlur,
                                  mobileNumber: true,
                                })
                              }
                              onChange={(e) => {
                                setCaptureBlur({
                                  ...captureBlur,
                                  mobileNumber: false,
                                });
                                // setIsFocused({
                                //   ...isFocused,
                                //   mobileNumber: false,
                                // });
                                const { value } = e.target;
                                if (value.length <= 10) {
                                  formikProps.handleChange(e);
                                }
                              }}
                              value={formikProps.values.mobileNumber}
                              InputLabelProps={{
                                style: { color: "black" },
                              }}
                              InputProps={{
                                maxLength: 10,
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <b
                                      style={{
                                        color:
                                          isFocused.mobileNumber === true &&
                                          (formikProps.errors.mobileNumber ===
                                            undefined ||
                                            formikProps.touched.mobileNumber ===
                                              undefined ||
                                            captureBlur.mobileNumber === false)
                                            ? "#005e82"
                                            : formikProps.errors
                                                .mobileNumber !== undefined &&
                                              formikProps.touched
                                                .mobileNumber === true &&
                                              captureBlur.mobileNumber === true
                                            ? "#de6363"
                                            : "#666666",
                                      }}
                                    >
                                      +91
                                    </b>
                                  </InputAdornment>
                                ),
                              }}
                              error={
                                formikProps.touched.mobileNumber &&
                                Boolean(formikProps.errors.mobileNumber) &&
                                captureBlur.mobileNumber === true
                              }
                              helperText={
                                formikProps.touched.mobileNumber &&
                                captureBlur.mobileNumber === true &&
                                formikProps.errors.mobileNumber
                              }
                            />
                          </FormControl>
                        </Box>
                        <FormControl fullWidth>
                          <FormLabel
                            sx={{ fontWeight: "bold" }}
                            style={{
                              color:
                                isFocused.email_id === true &&
                                (formikProps.errors.email_id === undefined ||
                                  formikProps.touched.email_id === undefined ||
                                  captureBlur.email_id === false)
                                  ? "#005e82"
                                  : formikProps.errors.email_id !== undefined &&
                                    formikProps.touched.email_id === true &&
                                    captureBlur.email_id === true
                                  ? "#de6363"
                                  : "#666666",
                            }}
                          >
                            {"Email"}
                          </FormLabel>
                          <TextField
                            // margin="normal"
                            labelId="demo-simple-select-label"
                            inputProps={{
                              onFocus: () => {
                                setIsFocused({
                                  ...isFocused,
                                  investor_name: false,
                                  email_id: true,
                                  mobileNumber: false,
                                  district_name: false,
                                  investment_range: false,
                                  location: false,
                                  investment_within: false,
                                });
                              },
                              onBlur: () => {
                                setIsFocused({
                                  ...isFocused,
                                  investor_name: false,
                                  email_id: false,
                                  mobileNumber: false,
                                  district_name: false,
                                  investment_range: false,
                                  location: false,
                                  investment_within: false,
                                });
                              },
                            }}
                            fullWidth
                            required
                            placeholder={"Enter Your Email"}
                            id="Email"
                            // label={t("Full_Name")}
                            name="email_id"
                            sx={{
                              margin: "0",
                              "& .MuiOutlinedInput-root": {
                                "&.Mui-focused fieldset": {
                                  borderColor:
                                    isFocused.email_id === true &&
                                    (formikProps.errors.email_id ===
                                      undefined ||
                                      formikProps.touched.email_id ===
                                        undefined ||
                                      captureBlur.email_id === false)
                                      ? "#005e82"
                                      : "#de6363",
                                },
                              },
                            }}
                            onBlur={formikProps.handleBlur}
                            onBlurCapture={() =>
                              setCaptureBlur({ ...captureBlur, email_id: true })
                            }
                            onChange={(e) => {
                              setCaptureBlur({
                                ...captureBlur,
                                email_id: false,
                              });
                              const { value } = e.target;
                              const shopValue = value.replace(
                                /[^a-z0-9@.]/gi,
                                ""
                              );
                              formikProps.setFieldValue("email_id", shopValue);

                              handleEmailChange(
                                shopValue,
                                formikProps.validateField
                              );
                            }}
                            value={formikProps.values.email_id}
                            InputLabelProps={{
                              style: { color: "black" },
                            }}
                            InputProps={{
                              style: { color: "black" },
                              startAdornment: (
                                <InputAdornment position="start">
                                  <EmailRoundedIcon
                                    sx={{
                                      color:
                                        isFocused.email_id === true &&
                                        (formikProps.errors.email_id ===
                                          undefined ||
                                          formikProps.touched.email_id ===
                                            undefined ||
                                          captureBlur.email_id === false)
                                          ? "#005e82"
                                          : formikProps.errors.email_id !==
                                              undefined &&
                                            formikProps.touched.email_id ===
                                              true &&
                                            captureBlur.email_id === true
                                          ? "#de6363"
                                          : "#666666",
                                    }}
                                  />{" "}
                                </InputAdornment>
                              ),
                            }}
                            error={
                              formikProps.touched.email_id &&
                              Boolean(formikProps.errors.email_id) &&
                              captureBlur.email_id
                            }
                            helperText={
                              formikProps.touched.email_id &&
                              captureBlur.email_id &&
                              formikProps.errors.email_id
                            }
                          />
                        </FormControl>
                        <FormControl fullWidth>
                          <FormLabel
                            sx={{ fontWeight: "bold" }}
                            style={{
                              color:
                                isFocused.district_name === true &&
                                (formikProps.errors.district_name ===
                                  undefined ||
                                  formikProps.touched.district_name ===
                                    undefined)
                                  ? "#005e82"
                                  : formikProps.errors.district_name !==
                                      undefined &&
                                    formikProps.touched.district_name === true
                                  ? "#de6363"
                                  : "#666666",
                            }}
                          >
                            District Name
                          </FormLabel>
                          <Autocomplete
                            disableClearable={
                              formikProps.values.district_name ? false : true
                            }
                            filterOptions={filterOptions}
                            id="district_name"
                            name="district_name"
                            getOptionLabel={(option) => option}
                            options={districtData}
                            onBlur={formikProps.handleBlur}
                            onChange={(event, value, reason) => {
                              // setSelectedIndustry(value);
                              formikProps.setFieldValue("district_name", value);
                              if (reason === "selectOption") {
                                getPincodeByDistrict(value);
                              }
                              if (reason === "clear") {
                                setPincodeByDistrict([]);
                              }
                            }}
                            value={formikProps.values.district_name}
                            freeSolo
                            renderInput={(params) => (
                              <TextField
                                required
                                {...params}
                                id="district_name"
                                // label={t("Preffered_Industry")}
                                name="district_name"
                                // onClick={() => params.inputProps.onInputClick()}
                                placeholder={"Select District Name"}
                                error={
                                  formikProps.touched.district_name &&
                                  Boolean(formikProps.errors.district_name)
                                }
                                helperText={
                                  formikProps.touched.district_name &&
                                  formikProps.errors.district_name
                                }
                                onFocus={() =>
                                  setIsFocused({
                                    ...isFocused,
                                    investor_name: false,
                                    email_id: false,
                                    mobileNumber: false,
                                    district_name: true,
                                    investment_range: false,
                                    location: false,
                                    investment_within: false,
                                  })
                                }
                                onBlur={() =>
                                  setIsFocused({
                                    ...isFocused,
                                    investor_name: false,
                                    email_id: false,
                                    mobileNumber: false,
                                    district_name: false,
                                    investment_range: false,
                                    location: false,
                                    investment_within: false,
                                  })
                                }
                              />
                            )}
                            onOpen={(event) => event.preventDefault()}
                            PopperComponent={({ children, ...popperProps }) => (
                              <Popper
                                {...popperProps}
                                placement="bottom-start"
                                modifiers={{
                                  flip: {
                                    enabled: false,
                                  },
                                  preventOverflow: {
                                    enabled: true,
                                    boundariesElement: "scrollParent",
                                  },
                                }}
                                PopperProps={{
                                  placement: "bottom-start", // Placement set to "bottom-start"
                                }}
                              >
                                <Paper>{children}</Paper>
                              </Popper>
                            )}
                            ListboxProps={{
                              style: {
                                maxHeight: "180px",
                                overflowY: "auto",
                              },
                            }}
                          />
                        </FormControl>

                        {/* <FormControl fullWidth>
                          <FormLabel
                            sx={{ fontWeight: "bold" }}
                            style={{
                              color:
                                isFocused.business_name === true &&
                                (formikProps.errors.business_name ===
                                  undefined ||
                                  formikProps.touched.business_name ===
                                    undefined)
                                  ? "#005e82"
                                  : formikProps.errors.business_name !==
                                      undefined &&
                                    formikProps.touched.business_name === true
                                  ? "#de6363"
                                  : "#666666",
                            }}
                          >
                            Business Name
                          </FormLabel>
                          <TextField
                            // margin="normal"
                            labelId="demo-simple-select-label"
                            inputProps={{
                              onFocus: () => {
                                setIsFocused({
                                  ...isFocused,
                                  business_name: true,
                                });
                              },
                              onBlur: () => {
                                setIsFocused({
                                  ...isFocused,
                                  business_name: false,
                                });
                              },
                            }}
                            fullWidth
                            required
                            placeholder={"Enter Your Business Name"}
                            id="Name"
                            // label={t("Full_Name")}
                            name="business_name"
                            sx={{
                              margin: "0",
                              "& .MuiOutlinedInput-root": {
                                "&.Mui-focused fieldset": {
                                  borderColor:
                                    isFocused.business_name === true &&
                                    (formikProps.errors.business_name ===
                                      undefined ||
                                      formikProps.touched.business_name ===
                                        undefined)
                                      ? "#005e82"
                                      : "#de6363",
                                },
                              },
                            }}
                            onBlur={formikProps.handleBlur}
                            onChange={(e) => {
                              const { value } = e.target;
                              const shopValue = value.replace(
                                /[^a-z0-9'. ]/gi,
                                ""
                              );
                              formikProps.setFieldValue(
                                "business_name",
                                shopValue
                              );
                            }}
                            value={formikProps.values.business_name}
                            InputLabelProps={{
                              style: { color: "black" },
                            }}
                            InputProps={{
                              style: { color: "black" },
                              startAdornment: (
                                <InputAdornment position="start">
                                  <BusinessRoundedIcon
                                    sx={{
                                      color:
                                        isFocused.business_name === true &&
                                        (formikProps.errors.business_name ===
                                          undefined ||
                                          formikProps.touched.business_name ===
                                            undefined)
                                          ? "#005e82"
                                          : formikProps.errors.business_name !==
                                              undefined &&
                                            formikProps.touched
                                              .business_name === true
                                          ? "#de6363"
                                          : "#666666",
                                    }}
                                  />{" "}
                                </InputAdornment>
                              ),
                            }}
                            error={
                              formikProps.touched.business_name &&
                              Boolean(formikProps.errors.business_name)
                            }
                            helperText={
                              formikProps.touched.business_name &&
                              formikProps.errors.business_name
                            }
                          />
                        </FormControl> */}
                        <FormControl fullWidth>
                          <FormLabel
                            sx={{ fontWeight: "bold" }}
                            style={{
                              color:
                                isFocused.location === true &&
                                (formikProps.errors.location === undefined ||
                                  formikProps.touched.location === undefined)
                                  ? "#005e82"
                                  : formikProps.errors.location !== undefined &&
                                    formikProps.touched.location === true
                                  ? "#de6363"
                                  : "#666666",
                            }}
                          >
                            Location
                          </FormLabel>
                          <TextField
                            // margin="normal"
                            labelId="demo-simple-select-label"
                            inputProps={{
                              onFocus: () => {
                                setIsFocused({
                                  ...isFocused,
                                  investor_name: false,
                                  email_id: false,
                                  mobileNumber: false,
                                  district_name: false,
                                  investment_range: false,
                                  location: true,
                                  investment_within: false,
                                });
                              },
                              onBlur: () => {
                                setIsFocused({
                                  ...isFocused,
                                  investor_name: false,
                                  email_id: false,
                                  mobileNumber: false,
                                  district_name: false,
                                  investment_range: false,
                                  location: false,
                                  investment_within: false,
                                });
                              },
                            }}
                            fullWidth
                            required
                            placeholder={"Enter Your Location"}
                            id="Name"
                            // label={t("Full_Name")}
                            name="location"
                            sx={{
                              margin: "0",
                              "& .MuiOutlinedInput-root": {
                                "&.Mui-focused fieldset": {
                                  borderColor:
                                    isFocused.location === true &&
                                    (formikProps.errors.location ===
                                      undefined ||
                                      formikProps.touched.location ===
                                        undefined)
                                      ? "#005e82"
                                      : "#de6363",
                                },
                              },
                            }}
                            onBlur={formikProps.handleBlur}
                            onChange={(e) => {
                              const { value } = e.target;
                              const shopValue = value.replace(
                                /[^a-z0-9'. ]/gi,
                                ""
                              );
                              formikProps.setFieldValue("location", shopValue);
                            }}
                            value={formikProps.values.location}
                            InputLabelProps={{
                              style: { color: "black" },
                            }}
                            InputProps={{
                              style: { color: "black" },
                              startAdornment: (
                                <InputAdornment position="start">
                                  <LocationOnRoundedIcon
                                    sx={{
                                      color:
                                        isFocused.location === true &&
                                        (formikProps.errors.location ===
                                          undefined ||
                                          formikProps.touched.location ===
                                            undefined)
                                          ? "#005e82"
                                          : formikProps.errors.location !==
                                              undefined &&
                                            formikProps.touched.location ===
                                              true
                                          ? "#de6363"
                                          : "#666666",
                                    }}
                                  />{" "}
                                </InputAdornment>
                              ),
                            }}
                            error={
                              formikProps.touched.location &&
                              Boolean(formikProps.errors.location)
                            }
                            helperText={
                              formikProps.touched.location &&
                              formikProps.errors.location
                            }
                          />
                        </FormControl>
                        <div style={{ width: "100%" }}>
                          <FormControl>
                            <FormLabel
                              sx={{ fontWeight: "bold" }}
                              style={{
                                color:
                                  isFocused.investment_range === true &&
                                  (formikProps.errors.investment_range ===
                                    undefined ||
                                    formikProps.touched.investment_range ===
                                      undefined)
                                    ? "#005e82"
                                    : formikProps.errors.investment_range !==
                                        undefined &&
                                      formikProps.touched.investment_range ===
                                        true
                                    ? "#de6363"
                                    : "#666666",
                              }}
                            >
                              TYPE OF FRANCHISE
                            </FormLabel>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              value={District_pincode}
                              onChange={(e) => {
                                const data =
                                  e.currentTarget.value === "District Wise"
                                    ? "2-5Lacs"
                                    : "25K-2LACS";
                                setselectDefultvalue(data);
                                formikProps.setFieldValue(
                                  "investment_range",
                                  data
                                );

                                setDistrictPincode(e.currentTarget.value);
                              }}
                            >
                              <FormControlLabel
                                value="District Wise"
                                control={<Radio />}
                                label="District Wise"
                              />
                              <FormControlLabel
                                value="Pincode Wise"
                                control={<Radio />}
                                label="Pincode Wise"
                              />
                            </RadioGroup>
                          </FormControl>
                        </div>
                        <FormControl
                          fullWidth
                          //   disabled={formikProps.values.pincode ? false : true}
                        >
                          <FormLabel
                            sx={{ fontWeight: "bold" }}
                            style={{
                              color:
                                isFocused.investment_range === true &&
                                (formikProps.errors.investment_range ===
                                  undefined ||
                                  formikProps.touched.investment_range ===
                                    undefined)
                                  ? "#005e82"
                                  : formikProps.errors.investment_range !==
                                      undefined &&
                                    formikProps.touched.investment_range ===
                                      true
                                  ? "#de6363"
                                  : "#666666",
                            }}
                          >
                            Investment range
                          </FormLabel>
                          <Select
                            fullWidth
                            defaultValue={selectDefaultValue}
                            name="investment_range"
                            onFocus={() =>
                              setIsFocused({
                                ...isFocused,
                                investor_name: false,
                                email_id: false,
                                mobileNumber: false,
                                district_name: false,
                                investment_range: true,
                                location: false,
                                investment_within: false,
                              })
                            }
                            labelId="demo-simple-select-label"
                            id="investment_range"
                            disabled
                            placeholder={"Select Investment range"}
                            sx={{ margin: "0" }}
                            onBlur={(e) => {
                              setIsFocused({
                                ...isFocused,
                                investor_name: false,
                                email_id: false,
                                mobileNumber: false,
                                district_name: false,
                                investment_range: false,
                                location: false,
                                investment_within: false,
                              });
                              formikProps.handleBlur(e);
                            }}
                            onChange={(e) => {
                              formikProps.handleChange(e.target.value);
                            }}
                            value={selectDefaultValue}
                            InputLabelProps={{
                              style: { color: "black" },
                            }}
                            InputProps={{
                              style: { color: "black" },
                              startAdornment: (
                                <InputAdornment position="start">
                                  <HomeWorkRoundedIcon
                                    sx={{
                                      color:
                                        isFocused.investment_range === true &&
                                        (formikProps.errors.investment_range ===
                                          undefined ||
                                          formikProps.touched
                                            .investment_range === undefined)
                                          ? "#005e82"
                                          : formikProps.errors
                                              .investment_range !== undefined &&
                                            formikProps.touched
                                              .investment_range === true
                                          ? "#de6363"
                                          : "#666666",
                                    }}
                                  />{" "}
                                </InputAdornment>
                              ),
                            }}
                            error={
                              formikProps.touched.investment_range &&
                              Boolean(formikProps.errors.investment_range)
                            }
                            helperText={
                              formikProps.touched.investment_range &&
                              formikProps.errors.investment_range
                            }
                            displayEmpty
                            MenuProps={MenuProps}
                          >
                            {" "}
                            <MenuItem
                              defaultValue={selectDefaultValue}
                              value={selectDefaultValue}
                              disabled
                              disableRipple
                              disableTouchRipple
                            >
                              {selectDefaultValue}
                            </MenuItem>
                            {/* {investmentRange &&
                              investmentRange.map((element, index) => {
                                return (
                                  <MenuItem key={index} value={element}>
                                    {element}
                                  </MenuItem>
                                );
                              })} */}
                          </Select>
                          {formikProps.touched.investment_range &&
                            formikProps.errors.investment_range && (
                              <FormHelperText sx={{ color: "#de6363" }}>
                                {formikProps.errors.investment_range}
                              </FormHelperText>
                            )}
                        </FormControl>
                        <FormControl
                          fullWidth
                          //   disabled={formikProps.values.pincode ? false : true}
                        >
                          <FormLabel
                            sx={{ fontWeight: "bold" }}
                            style={{
                              color:
                                isFocused.investment_within === true &&
                                (formikProps.errors.investment_within ===
                                  undefined ||
                                  formikProps.touched.investment_within ===
                                    undefined)
                                  ? "#005e82"
                                  : formikProps.errors.investment_within !==
                                      undefined &&
                                    formikProps.touched.investment_within ===
                                      true
                                  ? "#de6363"
                                  : "#666666",
                            }}
                          >
                            Investment Within
                          </FormLabel>
                          <Select
                            fullWidth
                            name="investment_within"
                            onFocus={() =>
                              setIsFocused({
                                ...isFocused,
                                investor_name: false,
                                email_id: false,
                                mobileNumber: false,
                                district_name: false,
                                investment_range: false,
                                location: false,
                                investment_within: true,
                              })
                            }
                            labelId="demo-simple-select-label"
                            id="investment_within"
                            required
                            // placeholder={"Select Investment range"}
                            sx={{ margin: "0" }}
                            onBlur={(e) => {
                              setIsFocused({
                                ...isFocused,
                                investor_name: false,
                                email_id: false,
                                mobileNumber: false,
                                district_name: false,
                                investment_range: false,
                                location: false,
                                investment_within: false,
                              });
                              formikProps.handleBlur(e);
                            }}
                            onChange={formikProps.handleChange}
                            value={formikProps.values.investment_within}
                            InputLabelProps={{
                              style: { color: "black" },
                            }}
                            InputProps={{
                              style: { color: "black" },
                              startAdornment: (
                                <InputAdornment position="start">
                                  <HomeWorkRoundedIcon
                                    sx={{
                                      color:
                                        isFocused.investment_within === true &&
                                        (formikProps.errors
                                          .investment_within === undefined ||
                                          formikProps.touched
                                            .investment_within === undefined)
                                          ? "#005e82"
                                          : formikProps.errors
                                              .investment_within !==
                                              undefined &&
                                            formikProps.touched
                                              .investment_within === true
                                          ? "#de6363"
                                          : "#666666",
                                    }}
                                  />{" "}
                                </InputAdornment>
                              ),
                            }}
                            error={
                              formikProps.touched.investment_within &&
                              Boolean(formikProps.errors.investment_within)
                            }
                            helperText={
                              formikProps.touched.investment_within &&
                              formikProps.errors.investment_within
                            }
                            displayEmpty
                            MenuProps={MenuProps}
                          >
                            {" "}
                            <MenuItem
                              value=""
                              disabled
                              disableRipple
                              disableTouchRipple
                            >
                              {"Select Your Investment within"}
                            </MenuItem>
                            {investment_within &&
                              investment_within.map((element, index) => {
                                return (
                                  <MenuItem key={index} value={element}>
                                    {element}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                          {formikProps.touched.investment_within &&
                            formikProps.errors.investment_within && (
                              <FormHelperText sx={{ color: "#de6363" }}>
                                {formikProps.errors.investment_within}
                              </FormHelperText>
                            )}
                        </FormControl>
                        <Tooltip
                          arrow
                          title={
                            disableCondition
                              ? "Please fill out all the fields"
                              : ""
                          }
                          sx={{ width: "100%" }}
                        >
                          <Box width="100%">
                            <Button
                              type="submit"
                              disabled={disableCondition}
                              fullWidth
                              variant="contained"
                              sx={{
                                mt: 3,
                                mb: 2,
                                textTransform: "capitalize",
                                backgroundColor: "#005e82",
                                transition: "0.3s all",
                                "&:hover": { backgroundColor: "#005e82" },
                              }}
                              onClick={formikProps.handleSubmit}
                            >
                              Submit
                              {isSubmitting && (
                                <CircularProgress
                                  size={24}
                                  style={{
                                    color: "#ffffff",

                                    marginLeft: 12,
                                  }}
                                />
                              )}
                            </Button>
                          </Box>
                        </Tooltip>
                      </Box>
                    </>
                  );
                }}
              </Formik>
            </Box>
          </Box>
        </Card>
      </Box>
    </>
    // </ThemeProvider>
  );
}
