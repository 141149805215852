import React, { useEffect, useState, useRef } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import { useFormik } from "formik";
import * as Yup from "yup";
import Popper from "@mui/material/Popper";
import "./MentorRegistration.css";
import { Data } from "../VolunteerForm/Data";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import InputAdornment from "@mui/material/InputAdornment";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import logo from "../../../img/logo_for-landingpage-3.png";
import PeopleAltRoundedIcon from "@mui/icons-material/PeopleAltRounded";
import { FormControl, Grid, FormLabel } from "@mui/material";
import { useNavigate, Outlet, useLocation } from "react-router";
import useAxios from "../../../api/useAxios";
import { ROUTINGDATA } from "../../ROUTINGDATA";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Tab from "@mui/material/Tab";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import MentorProfile from "./MentorProfile";
import mentor from "../../.../../../img/mentorImage-2.png";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useLocationFind } from "../LocationFind";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const exceptThisSymbols = ["e", "E", "+", "-", ".", "ArrowUp", "ArrowDown"];

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const navigate = useNavigate();
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

function MentorRegistration() {
  const ref = React.createRef(null);
  const [focusout, setfocus] = useState();
  const axiosData = useAxios();
  const baseUrl = axiosData.defaults.baseURL;
  const [selectedIndustry, setSelectedIndustry] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t, i18n, ready } = useTranslation();
  const [formEmail, setFormEmail] = useState("");
  const [formEmailError, setFormEmailError] = useState("");
  const [emailErr, setEmailError] = useState(true);
  const [valid, setvalid] = useState(true);
  const [emailPopUp, setEmailPopUp] = useState(false);
  const [alerte, setalerte] = useState(false);
  const [industryArray, setIndustryArray] = useState();
  const [domainArray, setDomainArray] = useState();
  const fileInputField = useRef(null);
  const [data, setData] = useState(false);
  const [file, setFile] = useState("");
  const [resume, setResume] = useState("");
  const [uploadedImages, setUploadedImages] = useState([]);
  const [mentorExperience, setMentorExperience] = useState("");
  const storedState = localStorage.getItem("currentLocationState");
  const storedCountry = localStorage.getItem("currentLocationCountry");
  const storedArea = localStorage.getItem("currentLocationArea");
  const storedPincode = localStorage.getItem("currentLocationPincode");

  const { locationData, locatioError } = useLocationFind();
  const location = useLocation();

  const MAX_FILE_SIZE = 1048576 * 10;
  const handleFileChange = (e) => {
    if (e.target.files[0].size > MAX_FILE_SIZE) {
      setFile("File is too large");
      setResume(false);
    } else {
      setResume(e.target.files[0]);
      setFile(e.target.files[0].name);
    }
    setData(e.target.files[0].size);
  };
  const deleteFile = () => {
    fileInputField.current.value = "";
    setFile(null);
    setData(null);
    setResume(null);
    formik.setFieldValue("fileUpload", null);
  };

  useEffect(() => {
    if (alerte !== false) {
      setEmailPopUp(true);
      setFormEmail("");
      formik.setFieldValue("email_id", "");
    }
    setalerte(false);
  }, [alerte]);
  const errors = {};

  const validate = async (values) => {
    const errors = {};
    if (!values.mobileNumber) {
      errors.mobileNumber = t("Mobile_Number_must_be_Required");
      setvalid(true);
    } else if (/^\d{9}$/.test(values.mobileNumber)) {
      setvalid(true);
    }

    return errors;
  };
  const filterOptions = (options, { inputValue }) => {
    const inputValueLower = inputValue.toLowerCase();
    const startsWithInput = [];
    const containsInput = [];

    options.forEach((option) => {
      const optionLower = option.toLowerCase();
      if (optionLower.startsWith(inputValueLower)) {
        startsWithInput.push(option);
      } else if (optionLower.includes(inputValueLower)) {
        containsInput.push(option);
      }
    });

    return [...startsWithInput, ...containsInput];
  };

  const handleEmailChange = async (event) => {
    const email_id = event.target.value
      .replace(/[^a-z0-9@.]/gi, "")
      .toLowerCase();
    setFormEmail(email_id);
    if (!email_id) {
      setfocus(true);
      setFormEmailError(t("EmailId_must_be_Required"));
      errors.email_id = t("EmailId_must_be_Required");
      setEmailError(true);
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email_id)) {
      setfocus(false);
      setFormEmailError(t("Invalid_email_address4"));
      errors.email_id = t("Invalid_email_address4");
    } else if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email_id)) {
      setEmailError(true);
      setFormEmailError("");
      if (emailErr) {
        try {
          const response = await axiosData.post(`users/email`, {
            email_id: email_id,
          });
          if (response.status === 200) {
            setalerte(true);
            formik.setFieldValue("email_id", event.target.value);
          }
        } catch (error) {}
      }
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{3,}$/i.test(email_id)) {
      errors.email_id = t("Invalid_email_address4");
    } else if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{3,}$/i.test(email_id)) {
      setEmailError(true);
      if (emailErr) {
        try {
          const response = await axiosData.post(`users/email`, {
            email_id: email_id,
          });
          if (response.status === 200) {
            setalerte(true);
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      MentorName: "",
      educationQualifications: "",
      Specialisation: "",
      officialAddress: "",
      mobileNumber: "",
      email_id: "",
      linkedInLink: "",
      areaOfExpertise: "",
      currentLocation: "",
      yearsOfExperience: "",
      currentEmployer: "",
      Designation: "",
      membershipInProfessional: "",
      professionalCertifications: "",
      CVUpload: null,
      Experience: "",
      mentorProfile: null,
      state: "",
      country: "",
    },
    validate,
    validationSchema: Yup.object({
      MentorName: Yup.string()
        .max(50, t("Must_be_50_characters_or_less1"))
        .required(t("Mentor_Name_must_be_Required")),
      mobileNumber: Yup.number()
        .min(6000000000, t("Not_Valid_Mobile_Number4!"))
        .max(9999999999, t("Not_Valid_Mobile_Number4!"))
        .required(t("Mobile_Number_must_be_Required")),
      email_id: Yup.string()
        .email(t("Invalid email address"))
        .required(t("EmailId_must_be_Required"))
        .matches(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
          t("Invalid_email_address")
        ),
      educationQualifications: Yup.string().required(
        t("educationQualifications_Required")
      ),
      Designation: Yup.string()
        .required(t("Designation_must_be_Required4"))
        .max(50, t("Must_be_50_characters_or_less4")),
      Specialisation: Yup.string().required(t("Specialisation_Required")),
      officialAddress: Yup.string().required(t("official_Address_Required")),
      linkedInLink: Yup.string()
        .required(t("linkedIn_Link_Required"))
        .matches(
          /https:\/\/www.linkedin.com\/.*$/,
          t("Invalid_LinkedIn_URL_format")
        ),
      areaOfExpertise: Yup.string().required(t("area_Of_Expertise_Required")),
      currentLocation: Yup.string().required(t("Other_Specify_Required")),
      yearsOfExperience: Yup.string().required(
        t("years_Of_Experience_Required")
      ),
      currentEmployer: Yup.string().required(t("current_Employer_Required")),
      membershipInProfessional: Yup.string().required(
        t("Membership_in_Professional_Societies")
      ),
      professionalCertifications: Yup.string().required(
        t("Professional_Certifications")
      ),
      Experience: Yup.string().required(t("years_Of_Experience_Required")),
    }),

    onSubmit: async (values) => {
      if (resume) {
        values.CVUpload = resume;
        values.email_id = formEmail;
      }
      if (uploadedImages) {
        values.mentorProfile = uploadedImages[0];
      }
      if (storedCountry) {
        values.state = storedState;
      }
      if (storedCountry) {
        values.country = storedCountry;
      }
      values.registertype = location.state.registertype;
      values.registerby = location.state.registerby;
      setIsSubmitting(true);
      await axiosData
        .post("mentor", values, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          axiosData
            .post(
              "users",
              {
                usertype: "mentor",
                status: "InActive",
                uniqueId: String(values.mobileNumber).concat("M"),
                password: values.mobileNumber,
                email_id: values.email_id,
                user_id: res.data.user_id,
              },
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then((res) => {});
          setIsSubmitting(false);
          if (location.state.registertype === "home") {
            navigate(
              `${ROUTINGDATA.LAND}${ROUTINGDATA.MAIN}${ROUTINGDATA.ThankYou}`
            );
          } else {
            navigate(-1);
          }
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    },
  });
  const [focusb, setfocusb] = useState();
  const handleChange = (e) => {
    setfocusb(false);
    const { value } = e.target;
    if (value.length <= 10) {
      formik.handleChange(e);
    }
  };

  const theme = useTheme();
  const initialValue = parseInt(sessionStorage.getItem("selectedTab")) || 0;

  const [value, setValue] = useState(initialValue);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    sessionStorage.setItem("selectedTab", newValue);
  };

  const IndustryData = async () => {
    try {
      const getOneAxios = await axiosData.get(`/General/seekerSettings`);
      const data = getOneAxios?.data?.industryData;

      if (data) {
        data.unshift("All");
        setIndustryArray(data);
      }
      return data;
    } catch (err) {
      console.log("Error", err);
    }
  };

  const DomainData = async () => {
    try {
      const getOneAxios = await axiosData.get(`/General/seekerSettings`);
      const data = getOneAxios?.data?.industryData;
      if (data) {
        setDomainArray(data);
      }
      return data;
    } catch (err) {
      console.log("Error", err);
    }
  };

  const [getMentor, setGetMentor] = useState([]);
  const [noData, setNoData] = useState(false);
  const [searchSpecialisation, setsearchSpecialisation] = useState("All");
  const [mentorData, setMentorData] = useState(false);

  const getMentorData = async () => {
    try {
      const getOneAxios = await axiosData.get(`/mentor`);
      const data = getOneAxios.data;
      if (data) {
        const statA = data.filter((i) => {
          return i.status === "A";
        });

        if (statA) {
          const sortedData = statA.sort((a, b) => {
            const SpecialisationSort = a.Specialisation.localeCompare(
              b.Specialisation
            );
            if (SpecialisationSort !== 0) {
              return SpecialisationSort;
            }
            return a.MentorName.localeCompare(b.MentorName);
          });
          setGetMentor(sortedData);
        }

        setMentorData(true);
      }
      return data;
    } catch (err) {
      console.log("Error", err);
    }
  };

  const getMentorByDomain = async (value) => {
    try {
      setMentorData(false);
      const getOneAxios = await axiosData.post(`/mentor/domain`, value, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      const data = getOneAxios.data;
      setMentorData(true);
      if (data) {
        const statA = data.filter((i) => {
          return i.status === "A";
        });
        const sortedData = statA.sort((a, b) => {
          return a.MentorName.localeCompare(b.MentorName);
        });
        setGetMentor(sortedData);
        setMentorData(true);
      }
      if (getOneAxios.status === 204) {
        setGetMentor([]);
        //setNoData(true);
        setMentorData(true);
      }

      return data;
    } catch (err) {
      console.log("Error", err);
    }
  };
  const [experience, setExperience] = useState([]);
  const experienceData = async () => {
    try {
      const getOneAxios = await axiosData.get(`/General/yearOfExperience`);
      const data = getOneAxios?.data?.experience;
      if (data) {
        setExperience(data);
      }
      return data;
    } catch (err) {
      console.log("Error", err);
    }
  };

  useEffect(() => {
    DomainData();
    experienceData();
    IndustryData();
    getMentorData();
  }, []);

  const [mentorPagination, setMentorPagination] = useState(8);

  const allLoadMore = () => {
    setMentorPagination(mentorPagination + 8);
  };

  const allLoadLess = () => {
    setMentorPagination(mentorPagination - 8);
  };

  if (ready && mentorData) {
    return (
      <ThemeProvider theme={theme}>
        <Dialog TransitionComponent={Transition} open={emailPopUp}>
          <DialogTitle
            bgcolor="rgb(16 143 178)"
            sx={{ color: "white", fontWeight: 600 }}
          >
            {t("Email_Exits")}
          </DialogTitle>
          <DialogContent sx={{ mt: 3 }}>
            <DialogContentText sx={{ fontWeight: 600 }}>
              {t("This_Email_Id_already_exist")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => setEmailPopUp(false)}
            >
              {t("Ok")}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog TransitionComponent={Transition} open={noData}>
          <DialogTitle
            bgcolor="rgb(16 143 178)"
            sx={{ color: "white", fontWeight: 600 }}
          >
            Mentor Not Found
          </DialogTitle>
          <DialogContent sx={{ mt: 3 }}>
            <DialogContentText sx={{ fontWeight: 600 }}>
              In this domain no mentor available
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => setNoData(false)}
            >
              {t("Ok")}
            </Button>
          </DialogActions>
        </Dialog>
        <Box sx={{ bgcolor: "background.paper", width: "100%" }}>
          <AppBar
            sx={{ bgcolor: "background.paper", width: "100%" }}
            position="static"
          >
            <Tabs
              value={value}
              onChange={handleTabChange}
              indicatorColor="secondary"
              variant="fullWidth"
              aria-label="full width tabs example"
            >
              <Tab label="Mentor Registration" {...a11yProps(0)} />
              <Tab label="Mentors" {...a11yProps(1)} />
            </Tabs>
          </AppBar>
          <TabPanel
            sx={{
              bgcolor: "background.paper",
              width: "100%",
            }}
            value={value}
            index={0}
            dir={theme.direction}
          >
            <Grid
              container
              component="main"
              sx={{ height: "100vh" }}
              className="main-grid"
            >
              <CssBaseline />

              <Grid
                className="second-grid"
                item
                xs={12}
                sm={12}
                md={12}
                component={Paper}
                elevation={4}
                square
              >
                <form onSubmit={formik.handleSubmit}>
                  <Box
                    sx={{
                      my: 3,
                      mx: 8,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <PeopleAltRoundedIcon
                      sx={{ mb: 1, width: 56, height: 56, color: "#ed1e70" }}
                    />

                    <Typography
                      component="h1"
                      variant="h5"
                      sx={{ color: "#135c82" }}
                    >
                      {t("Mentor_Registration")}
                    </Typography>

                    <Box sx={{ mt: 6 }}>
                      <Grid container rowSpacing={2}>
                        <Grid
                          container
                          rowSpacing={3}
                          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                          sx={{ mb: 2 }}
                        >
                          <Grid item xs={12} md={6} lg={6}>
                            <FormControl fullWidth>
                              <FormLabel sx={{ fontWeight: "bold" }}>
                                {t("Mentor_Name")}
                              </FormLabel>
                              <TextField
                                required
                                fullWidth
                                placeholder={t("Enter_Your_Name")}
                                id="MentorName"
                                name="MentorName"
                                onBlur={formik.handleBlur}
                                onChange={(e) => {
                                  if (e) {
                                    const value = e.target.value.replace(
                                      /[^a-z.,' ]/gi,
                                      ""
                                    );
                                    formik.setFieldValue("MentorName", value);
                                  }
                                }}
                                value={formik.values.MentorName.replace(
                                  /[^a-z'. ]/gi,
                                  ""
                                )}
                                error={
                                  formik.touched.MentorName &&
                                  Boolean(formik.errors.MentorName)
                                }
                                helperText={
                                  formik.touched.MentorName &&
                                  formik.errors.MentorName
                                }
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6} lg={6}>
                            <FormControl fullWidth>
                              <FormLabel sx={{ fontWeight: "bold" }}>
                                {t("Email_Id")}
                              </FormLabel>
                              <TextField
                                required
                                fullWidth
                                placeholder={t("Enter_Your_Email_ID")}
                                id="email_id"
                                name="email_id"
                                onBlur={formik.handleBlur}
                                onChange={(e) => {
                                  formik.handleChange(e);
                                  handleEmailChange(e);
                                }}
                                value={formEmail}
                                onBlurCapture={() => {
                                  setfocus(true);
                                }}
                                error={
                                  formik.touched.email_id &&
                                  focusout &&
                                  Boolean(formik.errors.email_id)
                                }
                                helperText={
                                  formik.touched.email_id &&
                                  focusout &&
                                  formik.errors.email_id
                                }
                              />
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} md={6} lg={6}>
                            <FormControl fullWidth>
                              <FormLabel sx={{ fontWeight: "bold" }}>
                                {t("Mobile_Number")}
                              </FormLabel>
                              <TextField
                                type="number"
                                required
                                fullWidth
                                placeholder={t("Enter_Your_Mobile_Number")}
                                id="mobileNumber"
                                name="mobileNumber"
                                onBlur={formik.handleBlur}
                                onChange={handleChange}
                                value={formik.values.mobileNumber}
                                onWheel={(e) => {
                                  e.preventDefault();
                                  e.target.blur();
                                }}
                                onKeyDown={(e) =>
                                  exceptThisSymbols.includes(e.key) &&
                                  e.preventDefault()
                                }
                                onBlurCapture={() => {
                                  setfocusb(true);
                                }}
                                error={
                                  formik.touched.mobileNumber &&
                                  focusb &&
                                  Boolean(formik.errors.mobileNumber)
                                }
                                helperText={
                                  formik.touched.mobileNumber &&
                                  focusb &&
                                  formik.errors.mobileNumber
                                }
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6} lg={6}>
                            <FormControl fullWidth>
                              <FormLabel sx={{ fontWeight: "bold" }}>
                                {t("LinkedIn_Profile_Link")}
                              </FormLabel>
                              <TextField
                                required
                                fullWidth
                                placeholder={t("Enter_LinkedIn_Profile_Link")}
                                id="linkedInLink"
                                name="linkedInLink"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.linkedInLink}
                                error={
                                  formik.touched.linkedInLink &&
                                  Boolean(formik.errors.linkedInLink)
                                }
                                helperText={
                                  formik.touched.linkedInLink &&
                                  formik.errors.linkedInLink
                                }
                              />
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} md={6} lg={6}>
                            <FormControl fullWidth>
                              <FormLabel sx={{ fontWeight: "bold" }}>
                                {t("Education_qualifications")}
                              </FormLabel>
                              <TextField
                                required
                                fullWidth
                                placeholder={t(
                                  "Enter_Education_qualifications"
                                )}
                                id="Education qualifications"
                                name="educationQualifications"
                                onBlur={formik.handleBlur}
                                onChange={(e) => {
                                  if (e) {
                                    const value = e.target.value.replace(
                                      /[^a-z0-9'.,/& ]/gi,
                                      ""
                                    );
                                    formik.setFieldValue(
                                      "educationQualifications",
                                      value
                                    );
                                  }
                                }}
                                value={formik.values.educationQualifications}
                                error={
                                  formik.touched.educationQualifications &&
                                  Boolean(formik.errors.educationQualifications)
                                }
                                helperText={
                                  formik.touched.educationQualifications &&
                                  formik.errors.educationQualifications
                                }
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6} lg={6}>
                            {domainArray && (
                              <FormControl fullWidth>
                                <FormLabel sx={{ fontWeight: "bold" }}>
                                  {t("Specialisation/domain")}
                                </FormLabel>
                                <Autocomplete
                                  disableClearable={
                                    formik.values.Specialisation ? false : true
                                  }
                                  filterOptions={filterOptions}
                                  id="industry"
                                  name="Specialisation"
                                  options={domainArray}
                                  onBlur={formik.handleBlur}
                                  onChange={(event, value) => {
                                    setSelectedIndustry(value);
                                    formik.setFieldValue(
                                      "Specialisation",
                                      value
                                    );
                                  }}
                                  value={formik.values.Specialisation}
                                  renderInput={(params) => (
                                    <TextField
                                      required
                                      {...params}
                                      id="Specialisation"
                                      placeholder={t(
                                        "Enter_Specialisation/domain"
                                      )}
                                      name="Specialisation"
                                      // onClick={() =>
                                      //   params.inputProps.onInputClick()
                                      // }
                                      error={
                                        formik.touched.Specialisation &&
                                        Boolean(formik.errors.Specialisation)
                                      }
                                      helperText={
                                        formik.touched.Specialisation &&
                                        formik.errors.Specialisation
                                      }
                                    />
                                  )}
                                  onOpen={(event) => event.preventDefault()}
                                  PopperComponent={({
                                    children,
                                    ...popperProps
                                  }) => (
                                    <Popper
                                      {...popperProps}
                                      placement="bottom-start"
                                      modifiers={{
                                        flip: {
                                          enabled: false,
                                        },
                                        preventOverflow: {
                                          enabled: true,
                                          boundariesElement: "scrollParent",
                                        },
                                      }}
                                      PopperProps={{
                                        placement: "bottom-start", // Placement set to "bottom-start"
                                      }}
                                    >
                                      <Paper>{children}</Paper>
                                    </Popper>
                                  )}
                                  ListboxProps={{
                                    style: {
                                      maxHeight: "180px",
                                      overflowY: "auto",
                                    },
                                  }}
                                />
                              </FormControl>
                            )}
                          </Grid>
                          <Grid item xs={12} md={6} lg={6}>
                            <FormControl fullWidth>
                              <FormLabel sx={{ fontWeight: "bold" }}>
                                {t("Area_of_expertise")}
                              </FormLabel>
                              <TextField
                                required
                                fullWidth
                                placeholder={t("Enter_Area_of_expertise")}
                                id="areaOfExpertise"
                                name="areaOfExpertise"
                                onBlur={formik.handleBlur}
                                onChange={(e) => {
                                  if (e) {
                                    const value = e.target.value.replace(
                                      /[^a-z0-9'., ]/gi,
                                      ""
                                    );
                                    formik.setFieldValue(
                                      "areaOfExpertise",
                                      value
                                    );
                                  }
                                }}
                                value={formik.values.areaOfExpertise}
                                error={
                                  formik.touched.areaOfExpertise &&
                                  Boolean(formik.errors.areaOfExpertise)
                                }
                                helperText={
                                  formik.touched.areaOfExpertise &&
                                  formik.errors.areaOfExpertise
                                }
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6} lg={6}>
                            {experience ? (
                              <FormControl fullWidth>
                                <FormLabel sx={{ fontWeight: "bold" }}>
                                  {t("Years_of_experience_in_this_area")}
                                </FormLabel>
                                <Autocomplete
                                  fullWidth
                                  disableClearable={
                                    formik.values.yearsOfExperience
                                      ? false
                                      : true
                                  }
                                  id="yearsOfExperience"
                                  name="yearsOfExperience"
                                  value={formik.values.yearsOfExperience}
                                  onChange={(event, newValue) => {
                                    formik.setFieldValue(
                                      "yearsOfExperience",
                                      newValue
                                    );
                                  }}
                                  onBlur={formik.handleBlur}
                                  options={experience}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      required
                                      fullWidth
                                      name="yearsOfExperience"
                                      placeholder={t(
                                        "Enter_Years_of_experience_in_this_area"
                                      )}
                                      error={
                                        formik.touched.yearsOfExperience &&
                                        Boolean(formik.errors.yearsOfExperience)
                                      }
                                      helperText={
                                        formik.touched.yearsOfExperience &&
                                        formik.errors.yearsOfExperience
                                      }
                                    />
                                  )}
                                  ListboxProps={{
                                    style: {
                                      maxHeight: "180px",
                                      overflowY: "auto",
                                    },
                                  }}
                                />
                              </FormControl>
                            ) : null}
                          </Grid>
                          <Grid item xs={12} md={6} lg={6}>
                            <FormControl fullWidth>
                              <FormLabel sx={{ fontWeight: "bold" }}>
                                {t("Current_Location1")}
                              </FormLabel>
                              <TextField
                                required
                                fullWidth
                                placeholder={t("Enter_Current_Location1")}
                                id="currentLocation"
                                name="currentLocation"
                                onBlur={formik.handleBlur}
                                onChange={(e) => {
                                  if (e) {
                                    const value = e.target.value.replace(
                                      /[^a-z0-9'., ]/gi,
                                      ""
                                    );
                                    formik.setFieldValue(
                                      "currentLocation",
                                      value
                                    );
                                  }
                                }}
                                value={formik.values.currentLocation}
                                error={
                                  formik.touched.currentLocation &&
                                  Boolean(formik.errors.currentLocation)
                                }
                                helperText={
                                  formik.touched.currentLocation &&
                                  formik.errors.currentLocation
                                }
                              />
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} md={6} lg={6}>
                            <FormControl fullWidth>
                              <FormLabel sx={{ fontWeight: "bold" }}>
                                {t("Address-1")}
                              </FormLabel>
                              <TextField
                                required
                                fullWidth
                                placeholder={t("Enter_Your_address")}
                                id="officialAddress"
                                name="officialAddress"
                                onBlur={formik.handleBlur}
                                onChange={(e) => {
                                  if (e) {
                                    const value = e.target.value.replace(
                                      /[^a-z0-9'.,/#&: ]/gi,
                                      ""
                                    );
                                    formik.setFieldValue(
                                      "officialAddress",
                                      value
                                    );
                                  }
                                }}
                                value={formik.values.officialAddress}
                                error={
                                  formik.touched.officialAddress &&
                                  Boolean(formik.errors.officialAddress)
                                }
                                helperText={
                                  formik.touched.officialAddress &&
                                  formik.errors.officialAddress
                                }
                              />
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} md={6} lg={6}>
                            <FormControl fullWidth>
                              <FormLabel sx={{ fontWeight: "bold" }}>
                                {t("Current_employer")}
                              </FormLabel>
                              <TextField
                                required
                                fullWidth
                                placeholder={t("Enter_Current_employer")}
                                id="currentEmployer"
                                name="currentEmployer"
                                onBlur={formik.handleBlur}
                                onChange={(e) => {
                                  if (e) {
                                    const value = e.target.value.replace(
                                      /[^a-z0-9'.,&/ ]/gi,
                                      ""
                                    );
                                    formik.setFieldValue(
                                      "currentEmployer",
                                      value
                                    );
                                  }
                                }}
                                value={formik.values.currentEmployer}
                                error={
                                  formik.touched.currentEmployer &&
                                  Boolean(formik.errors.currentEmployer)
                                }
                                helperText={
                                  formik.touched.currentEmployer &&
                                  formik.errors.currentEmployer
                                }
                              />
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} md={6} lg={6}>
                            <FormControl fullWidth>
                              <FormLabel sx={{ fontWeight: "bold" }}>
                                {t("Designation(Current employer)")}
                              </FormLabel>
                              <TextField
                                required
                                fullWidth
                                placeholder={t(
                                  "Enter_Designation(Current employer)"
                                )}
                                id="Designation"
                                name="Designation"
                                onBlur={formik.handleBlur}
                                onChange={(e) => {
                                  if (e) {
                                    const value = e.target.value.replace(
                                      /[^a-z0-9'.,&/ ]/gi,
                                      ""
                                    );
                                    formik.setFieldValue("Designation", value);
                                  }
                                }}
                                value={formik.values.Designation}
                                error={
                                  formik.touched.Designation &&
                                  Boolean(formik.errors.Designation)
                                }
                                helperText={
                                  formik.touched.Designation &&
                                  formik.errors.Designation
                                }
                              />
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} md={6} lg={6}>
                            <FormControl fullWidth>
                              <FormLabel sx={{ fontWeight: "bold" }}>
                                {t(
                                  "Membership_in_Professional_Societies/Associations"
                                )}
                              </FormLabel>
                              <TextField
                                required
                                fullWidth
                                placeholder={t(
                                  "Enter_Membership_in_Professional_Societies/Associations"
                                )}
                                id="membershipInProfessional"
                                name="membershipInProfessional"
                                onBlur={formik.handleBlur}
                                onChange={(e) => {
                                  if (e) {
                                    const value = e.target.value.replace(
                                      /[^a-z0-9'.,&/ ]/gi,
                                      ""
                                    );
                                    formik.setFieldValue(
                                      "membershipInProfessional",
                                      value
                                    );
                                  }
                                }}
                                value={formik.values.membershipInProfessional}
                                error={
                                  formik.touched.membershipInProfessional &&
                                  Boolean(
                                    formik.errors.membershipInProfessional
                                  )
                                }
                                helperText={
                                  formik.touched.membershipInProfessional &&
                                  formik.errors.membershipInProfessional
                                }
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6} lg={6}>
                            <FormControl fullWidth>
                              <FormLabel sx={{ fontWeight: "bold" }}>
                                {t(
                                  "Other_Professional_Certifications_or_Trainings"
                                )}
                              </FormLabel>
                              <TextField
                                required
                                fullWidth
                                placeholder={t(
                                  "Enter_Other_Professional_Certifications_or_Trainings"
                                )}
                                id="professionalCertifications"
                                name="professionalCertifications"
                                onBlur={formik.handleBlur}
                                onChange={(e) => {
                                  if (e) {
                                    const value = e.target.value.replace(
                                      /[^a-z0-9'.,&/ ]/gi,
                                      ""
                                    );
                                    formik.setFieldValue(
                                      "professionalCertifications",
                                      value
                                    );
                                  }
                                }}
                                value={formik.values.professionalCertifications}
                                error={
                                  formik.touched.professionalCertifications &&
                                  Boolean(
                                    formik.errors.professionalCertifications
                                  )
                                }
                                helperText={
                                  formik.touched.professionalCertifications &&
                                  formik.errors.professionalCertifications
                                }
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Grid
                          sx={{ mt: -6, mb: -5 }}
                          item
                          xs={12}
                          md={12}
                          lg={12}
                        >
                          <MentorProfile
                            uploadedImages={uploadedImages}
                            setUploadedImages={setUploadedImages}
                          />
                        </Grid>
                        <Grid
                          container
                          rowSpacing={3}
                          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                        >
                          <Grid item xs={12} md={6} lg={6}>
                            <input
                              className="resumefile"
                              id="file-upload"
                              type="file"
                              accept=".docx,.doc,.pdf,"
                              onChange={handleFileChange}
                              ref={fileInputField}
                              required
                            />
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Button
                                disabled={file ? true : false}
                                htmlFor="file"
                                variant="contained"
                                color="error"
                                sx={{
                                  borderRadius: 10,
                                  mr: -2,
                                  width: "90%",
                                }}
                                name="fileUpload"
                                onClick={() => {
                                  fileInputField.current.click();
                                }}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.fileUpload}
                              >
                                <DriveFolderUploadIcon className="resumeIcon" />
                                CV Upload
                              </Button>
                            </div>
                          </Grid>
                          <Grid item xs={12} md={6} lg={6}>
                            {file ? (
                              <div className="fileName">
                                <Typography
                                  variant="div"
                                  sizewidth="2px"
                                  sx={
                                    resume
                                      ? {
                                          color: "rgb(16 143 178)",
                                          ml: -1,
                                          mb: -1,
                                        }
                                      : { color: "red", ml: -1, mb: -1 }
                                  }
                                  className="fileNameSize"
                                >
                                  {`${file}`}
                                </Typography>
                                <Tooltip title="Clear" arrow>
                                  <IconButton>
                                    <CloseIcon
                                      className="iconsize"
                                      sx={{ color: "red", mb: -0.5 }}
                                      onClick={() => {
                                        deleteFile();
                                      }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              </div>
                            ) : null}
                          </Grid>
                        </Grid>
                        <Grid item xs={12} md={6} lg={6}>
                          <div className="uploadbox1">
                            <p>
                              <span
                                style={
                                  resume
                                    ? { color: "rgb(16 143 178)" }
                                    : { color: "red" }
                                }
                                className="fileSize"
                              >
                                {data
                                  ? `${data / 1024}` >= 1024
                                    ? `${parseFloat(data / 1024 / 1024).toFixed(
                                        3
                                      )}Mb`
                                    : `${parseFloat(data / 1024).toFixed(3)}Kb`
                                  : null}
                              </span>{" "}
                              {t("File_must_be")}
                            </p>
                          </div>
                        </Grid>
                        <FormControl
                          fullWidth
                          sx={{ mt: 3 }}
                          className="MentorReactQuill"
                        >
                          <FormLabel sx={{ fontWeight: "bold" }}>
                            {t("Experience_in_providing_guidance")}
                          </FormLabel>
                        </FormControl>
                        <Grid item xs={12} md={12} lg={12}>
                          <ReactQuill
                            value={mentorExperience}
                            onChange={(value) => {
                              formik.setFieldValue("Experience", value);
                              setMentorExperience(value);
                            }}
                            modules={{
                              toolbar: [
                                ["bold", "italic", "underline", "strike"],
                                ["blockquote", "code-block"],
                                [{ list: "ordered" }, { list: "bullet" }],
                                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                                [{ color: [] }, { background: [] }],
                                [{ font: [] }],
                                [{ align: [] }],
                                ["clean"],
                              ],
                            }}
                            error={
                              formik.touched.Experience &&
                              Boolean(formik.errors.Experience)
                            }
                            helperText={
                              formik.touched.Experience &&
                              formik.errors.Experience
                            }
                            placeholder={t(
                              "Experience_in_providing_guidance_placeholder"
                            )}
                            className="scrollable-container-Mentor"
                          />
                        </Grid>

                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          sx={{ mt: 3, mb: 2 }}
                          disabled={
                            resume &&
                            uploadedImages.length !== 0 &&
                            formik.isValid &&
                            formik.dirty &&
                            isSubmitting === false &&
                            (mentorExperience === "<p><br></p>") === false
                              ? false
                              : true
                          }
                        >
                          {t("Submit4")}
                          {isSubmitting && (
                            <CircularProgress
                              size={24}
                              sx={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                marginTop: "-12px",
                                marginLeft: "-12px",
                              }}
                            />
                          )}
                        </Button>
                      </Grid>
                    </Box>
                  </Box>
                </form>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel
            sx={{ bgcolor: "background.paper", width: "100%" }}
            value={value}
            index={1}
            dir={theme.direction}
          >
            {mentorData ? (
              <>
                <div className="searchJobCard">
                  {industryArray && (
                    <FormControl sx={{ width: "350px", mt: 2 }}>
                      <FormLabel
                        sx={{
                          fontWeight: "bold",
                          display: "flex",
                          alignContent: "flex-start",
                        }}
                      >
                        Search by Specialisation/domain
                      </FormLabel>
                      <Autocomplete
                        disableClearable={searchSpecialisation ? false : true}
                        filterOptions={filterOptions}
                        id="industry"
                        name="searchSpecialisation"
                        options={industryArray}
                        onChange={(event, value) => {
                          if (!value) {
                            getMentorData();
                          }
                          if (value === "All") {
                            getMentorData();
                          } else {
                            getMentorByDomain({ Specialisation: value });
                          }
                          setSelectedIndustry(value);
                          setsearchSpecialisation(value);
                        }}
                        value={searchSpecialisation}
                        renderInput={(params) => (
                          <TextField
                            required
                            {...params}
                            id="searchSpecialisation"
                            placeholder="Specialisation/domain"
                            name="searchSpecialisation"
                            // onClick={() => params.inputProps.onInputClick()}
                          />
                        )}
                        onOpen={(event) => event.preventDefault()}
                        PopperComponent={({ children, ...popperProps }) => (
                          <Popper
                            {...popperProps}
                            placement="bottom-start"
                            modifiers={{
                              flip: {
                                enabled: false,
                              },
                              preventOverflow: {
                                enabled: true,
                                boundariesElement: "scrollParent",
                              },
                            }}
                            PopperProps={{
                              placement: "bottom-start",
                            }}
                          >
                            <Paper>{children}</Paper>
                          </Popper>
                        )}
                        ListboxProps={{
                          style: {
                            maxHeight: "180px",
                            overflowY: "auto",
                          },
                        }}
                      />
                    </FormControl>
                  )}
                </div>
                {getMentor.length === 0 ? (
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      fontSize: "16px",
                      fontWeight: "bold",
                      color: "rgb(56, 55, 55)",
                      marginTop: "20px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    No mentor available in this domain
                  </div>
                ) : (
                  <div className="MentorGrid">
                    {getMentor
                      ? getMentor.length !== 0
                        ? getMentor.slice(0, mentorPagination).map((item) => {
                            return (
                              <>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginBottom: "16px",
                                    marginTop: "16px",
                                  }}
                                >
                                  <div
                                    className="mentorcard"
                                    onClick={() => {
                                      navigate(
                                        ROUTINGDATA.LAND +
                                          ROUTINGDATA.MAIN +
                                          ROUTINGDATA.MENTORProfile,
                                        { state: { email_id: item.email_id } }
                                      );
                                    }}
                                  >
                                    <div
                                      class="member"
                                      sx={{ height: "220px" }}
                                    >
                                      <img
                                        src={`${baseUrl}mentor/get/profile/${item.email_id}`}
                                        className="member-img"
                                        alt="img"
                                        onError={(e) => {
                                          e.target.src = mentor;
                                        }}
                                      />
                                      <a
                                        href={item.linkedInLink}
                                        alt="link"
                                        target="blank"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                        }}
                                      >
                                        <div className="social">
                                          <LinkedInIcon
                                            className="socialLinkedInIcon"
                                            sx={{
                                              color: "#0077b5",
                                              padding: "5px",
                                              borderRadius: "50%",
                                              transition: "0.3s",
                                              cursor: "pointer",
                                              fontSize: "25px",
                                              backgroundColor: "white",
                                            }}
                                          />
                                        </div>
                                      </a>
                                    </div>
                                    <div class="member-info">
                                      <h4>{item.MentorName}</h4>
                                      <span>{item.Designation}</span>
                                      <Grid lg={12} md={12} sm={12} xs={12}>
                                        <p
                                          style={{
                                            fontWeight: "bold",
                                            wordWrap: "break-word",
                                          }}
                                        >
                                          {item.Specialisation}
                                        </p>
                                      </Grid>

                                      <p>{item.currentEmployer}</p>
                                      <p>
                                        {item.yearsOfExperience} Of Experience
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          })
                        : null
                      : null}
                  </div>
                )}
                <div className="pagination">
                  {mentorPagination > 8 && getMentor.length > 8 ? (
                    <Button variant="outlined" onClick={allLoadLess}>
                      See Less
                    </Button>
                  ) : null}
                  {mentorPagination < getMentor.length && (
                    <Button variant="contained" onClick={allLoadMore}>
                      See More
                    </Button>
                  )}
                </div>
              </>
            ) : (
              <div
                style={{
                  height: "70vh",
                  display: "grid",
                  placeItems: "center",
                }}
              >
                <div class="dots"></div>
              </div>
            )}
          </TabPanel>
        </Box>

        <Outlet />
      </ThemeProvider>
    );
  } else {
    return (
      <div
        style={{
          height: "70vh",
          display: "grid",
          placeItems: "center",
        }}
      >
        <div class="dots"></div>
      </div>
    );
  }
}
export default MentorRegistration;
