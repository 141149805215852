import React, { useEffect, useState, useRef } from "react";
import img from "../../../img/team-1.jpg";
import { useLocation, useNavigate } from "react-router";
import useAxios from "../../../api/useAxios";
import mentor from "../../.../../../img/mentorImage.png";
import Rating from "@mui/material/Rating";
import StarRateIcon from "@mui/icons-material/StarRate";
import ThumbsUpDownIcon from "@mui/icons-material/ThumbsUpDown";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { Box, Slider, CircularProgress, Backdrop } from "@mui/material";
import AvatarEditor from "react-avatar-editor";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Slide from "@mui/material/Slide";
import { Buffer } from "buffer";
import Tooltip from "@mui/material/Tooltip";
import { ROUTINGDATA } from "../../ROUTINGDATA";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import { Grid, Typography, DialogTitle } from "@mui/material";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import { saveAs } from "file-saver";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import TextField from "@mui/material/TextField";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MentorAboutUs = () => {
  const Location = useLocation();
  const navigate = useNavigate();
  const axiosData = useAxios();
  const [getMentor, setGetMentor] = useState([]);
  const [noData, setNoData] = useState(false);
  const baseUrl = axiosData.defaults.baseURL;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [content, setContent] = useState("");
  const [isFullContentDisplayed, setIsFullContentDisplayed] = useState(false);

  const currentUrl = Location.pathname;

  let seekerData = JSON.parse(localStorage.getItem("seeker"));
  console.log("seekerData", seekerData);

  const [openRating, setOpenRating] = useState(false);
  const [ratingValue, setRatingValue] = useState(0);
  const [ratedValue, setRatedValue] = useState(0);
  const [ratingDescription, setRatingDescription] = useState("");

  const handleRating = async () => {
    try {
      const getOneAxios = await axiosData.put(
        `mentor/email/${Location.state.email_id}`,
        { rating: ratingValue, ratingDescription: ratingDescription },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      getMentorData();
      console.log("Rating saved:", getOneAxios.data);
    } catch (error) {
      console.error("Error saving rating:", error);
    }
  };

  const getMentorData = async () => {
    try {
      const getOneAxios = await axiosData.get(
        `mentor/email/get/${Location.state.email_id}`
      );
      const data = getOneAxios.data;
      setRatedValue(getOneAxios.data.rating);
      if (data) {
        if (Location.state.usertype === "mentor") {
          const email_id = data.email_id;
          const MentorName = data.MentorName;

          localStorage.setItem(
            "mentor",
            JSON.stringify({ email_id, MentorName })
          );
        }
        setGetMentor(data);
        setContent(data.Experience);
      }
      return data;
    } catch (err) {
      console.log("Error", err);
    }
  };

  const toggleContentDisplay = () => {
    setIsFullContentDisplayed(!isFullContentDisplayed);
  };

  useEffect(() => {
    let mentorDetails = JSON.parse(localStorage.getItem("mentor"));
    if (Location.state.usertype === "mentor") {
      if (
        currentUrl ===
        ROUTINGDATA.LAND + ROUTINGDATA.MAIN + ROUTINGDATA.MENTORProfile
      ) {
        getMentorData();
      } else {
        if (mentorDetails === null || mentorDetails === undefined) {
          navigate(
            `${ROUTINGDATA.LAND}${ROUTINGDATA.MAIN}${ROUTINGDATA.PageNotFound}`
          );
        } else {
          getMentorData();
        }
      }
    } else {
      getMentorData();
    }
  }, []);

  const [image, setimage] = useState();
  const [open, setOpen] = useState(false);
  const [ref, setr] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  var editor = "";
  const [picture, setPicture] = useState({
    cropperOpen: false,
    img: null,
    zoom: 2,
    croppedImg: " ",
  });

  const handleSlider = (event, value) => {
    setPicture({
      ...picture,
      zoom: value,
    });
  };

  const handleCancel = () => {
    setPicture({
      ...picture,
      cropperOpen: false,
    });
  };

  const setEditorRef = (ed) => {
    editor = ed;
  };
  function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
  const [isLoading, setIsLoading] = useState(false);

  const handleSave = async (e, Context) => {
    if (setEditorRef) {
      const canvasScaled = editor.getImageScaledToCanvas();
      const croppedImg = canvasScaled.toDataURL();

      if (croppedImg) {
        setPicture({
          ...picture,
          img: null,
          cropperOpen: false,
          croppedImg: croppedImg,
        });
      }

      var block = croppedImg.split(";");

      var contentType = block[0].split(":")[1];
      var realData = block[1].split(",")[1];
      const img = b64toBlob(realData, contentType);

      const values = { mentorProfile: "" };
      if (picture) {
        fileInputField.current.value = "";
        values.mentorProfile = img;
        try {
          const response = await axiosData.delete(
            `mentor/get/profile/${Location.state.email_id}`,
            values,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
        } catch (err) {
          console.log();
        }

        setIsLoading(true);

        const response = await axiosData.put(
          `mentor/email/${Location.state.email_id}`,
          values,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response) {
          setimage(null);
          setOpen(false);
          setr(true);
        }
      }
    }
  };
  const fileInputField = useRef(null);

  const [imageSize, setImageSize] = useState(false);

  const handleFileChange = (e) => {
    let imgFile = e.target.files[0];

    // Check if a file is selected
    if (!imgFile) {
      return;
    }

    // Check if the file size is less than 2MB
    const maxSize = 5 * 1024 * 1024; // 2MB in bytes
    if (imgFile.size > maxSize) {
      // Handle the case when the image exceeds the size limit
      setImageSize(true);
      fileInputField.current.value = "";
      return;
    }

    // If the file is valid, proceed with setting the state
    handleClickOpen();
    setPicture({
      ...picture,
      img: imgFile,
      cropperOpen: true,
    });
  };

  const fun = async () => {
    const response = await axiosData.get(
      `mentor/email/get/${Location.state.email_id}`
    );

    if (response.data) {
      try {
        const response1 = await axiosData.get(
          `mentor/get/profile/${response.data.email_id}`,
          {
            responseType: "arraybuffer",
          }
        );

        const docBuffer = Buffer.from(response1.data, "binary");
        const docDataUrl = URL.createObjectURL(new Blob([docBuffer]));

        setimage(docDataUrl);
      } catch (error) {
        console.error(error);
      }
      setr(false);
    }
    setIsLoading(false);
    setIsLoading(false);
  };
  const [isDelete, setIsDelete] = useState(false);
  const [deleteResume, setDeleteResume] = useState(false);
  const [resumeUpload, setResumeUpload] = useState(false);
  const [resume, setResume] = useState("");

  const MAX_FILE_SIZE = 1048576 * 10; //10Mb
  const cvInputField = useRef(null);

  const CVhandleFileChange = async (e) => {
    if (e.target.files[0].size > MAX_FILE_SIZE) {
      setResumeUpload(true);
      setResume("");
      cvInputField.current.value = "";
    } else {
      setResume({ CVUpload: e.target.files[0] });

      const values = { CVUpload: "" };
      if (e.target.files[0]) {
        values.CVUpload = e.target.files[0];
      }
      setIsSubmitting(true);
      await axiosData.put(`/mentor/email/${Location.state.email_id}`, values, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      getMentorData();
      setIsSubmitting(false);
      cvInputField.current.value = "";
    }
  };

  useEffect(() => {
    setIsLoading(true);
    setimage(null);
    setTimeout(() => {
      if (Location.state !== undefined) {
        fun();
      } else {
        setr(true);
      }
    }, 1000);

    setr(false);
  }, [ref]);
  if (getMentor) {
    return (
      <div style={{ height: "100%" }}>
        <Dialog
          TransitionComponent={Transition}
          open={imageSize}
          sx={{ "& .MuiPaper-root": { width: "500px" } }}
        >
          <DialogTitle
            bgcolor="rgb(16 143 178)"
            sx={{ color: "white", fontWeight: 600 }}
          >
            <WarningAmberOutlinedIcon sx={{ mb: -0.5 }} /> Photo Upload Limit
          </DialogTitle>
          <DialogContent sx={{ mt: 1 }}>
            <DialogContentText sx={{ fontWeight: 600, mt: 2 }}>
              Note: Image file size exceeds the allowed limit of 2MB. Please
              choose a smaller size image.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => setImageSize(false)}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          TransitionComponent={Transition}
          open={deleteResume}
          sx={{ "& .MuiPaper-root": { color: "white", width: "400px" } }}
        >
          <DialogTitle
            bgcolor="rgb(16 143 178)"
            sx={{ backgroundColor: "rgb(16 143 178)", fontWeight: 600 }}
          >
            Delete CV
          </DialogTitle>
          <DialogContent sx={{ mt: 3 }}>
            <DialogContentText sx={{ fontWeight: 600 }}>
              Are you sure, you want to delete CV
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="error"
              variant="outlined"
              onClick={() => {
                setDeleteResume(false);
              }}
            >
              No
            </Button>
            <Button
              color="primary"
              variant="contained"
              disabled={isDelete}
              onClick={async () => {
                setIsDelete(true);
                await axiosData.delete(`/mentor/cv/${getMentor.email_id}`);
                getMentorData();
                setIsDelete(false);
                setDeleteResume(false);
              }}
            >
              Yes{" "}
              {isDelete && (
                <CircularProgress
                  size={24}
                  style={{
                    color: "#ffffff",
                    marginLeft: "4px",
                    marginBottom: "-2px",
                  }}
                />
              )}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog TransitionComponent={Transition} open={resumeUpload}>
          <DialogTitle
            bgcolor="rgb(16 143 178)"
            sx={{ color: "white", fontWeight: 600 }}
          >
            CV Upload
          </DialogTitle>
          <DialogContent sx={{ mt: 3 }}>
            <DialogContentText sx={{ fontWeight: 600 }}>
              {"File size is too large upload < 10MB file."}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => setResumeUpload(false)}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          TransitionComponent={Transition}
          open={openRating}
          sx={{ "& .MuiPaper-root": { width: "500px" } }}
        >
          <DialogTitle
            bgcolor="rgb(16 143 178)"
            sx={{ color: "white", fontWeight: 600 }}
          >
            <ThumbsUpDownIcon sx={{ mb: -0.5, mr: 0.8 }} />
            Rate This Mentor
          </DialogTitle>
          <DialogContent sx={{ mt: 1 }}>
            <DialogContentText sx={{ fontWeight: 600, mt: 2 }}>
              <Typography sx={{ mb: 1 }}>
                Reviews are public and include your account and device info
              </Typography>
              <Rating
                name="half-rating"
                sx={{ fontSize: "35px" }}
                value={ratingValue}
                precision={0.5}
                onChange={(event, newValue) => {
                  setRatingValue(newValue);
                }}
              />

              <TextField
                required
                fullWidth
                placeholder="Describe your experience (optional)"
                id="ratingDescription"
                name="ratingDescription"
                onChange={(e) => {
                  if (e) {
                    if (e.target.value.length <= 200) {
                      const value = e.target.value.replace(/[^a-z.,' ]/gi, "");
                      setRatingDescription(value);
                    }
                  }
                }}
                value={ratingDescription}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="error"
              variant="outlined"
              onClick={() => setOpenRating(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                handleRating();
                setOpenRating(false);
              }}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
        <div className="mentoraboutuspageheader">
          <div className="backbuttonformentor">
            <Tooltip title="Go back" arrow>
              <IconButton
                aria-label="delete"
                size="small"
                sx={{ backgroundColor: "white" }}
                onClick={() => {
                  navigate(-1);
                }}
              >
                <ArrowBackIcon sx={{ color: "#108fb2" }} />
              </IconButton>
            </Tooltip>
            {Location.state.usertype === "mentor" ? (
              <Button
                onClick={() => {
                  navigate("/main/mentorUpdate", {
                    state: {
                      email_id: Location.state.email_id,
                      usertype: Location.state.usertype,
                    },
                  });
                }}
                variant="contained"
                className="editbutton-mentor"
                sx={{
                  backgroundColor: "white",
                  color: "#108fb2",
                  borderRadius: "20px",
                  textTransform: "capitalize",
                  fontWeight: "700",
                }}
                startIcon={
                  <EditIcon
                    className="iconedit"
                    sx={{ color: "#108fb2", fontSize: "13px" }}
                  />
                }
              >
                Edit
              </Button>
            ) : null}
          </div>
          <div className="mentorpage">
            <div className="mentorpage-imgpart">
              <img
                src={image ? image : mentor}
                onError={(e) => {
                  e.target.src = mentor;
                }}
                className="mentorpage-img"
                alt="img"
              />
              {Location.state.usertype === "mentor" ? (
                <div className="text">
                  <CameraAltIcon
                    onClick={() => {
                      fileInputField.current.click();
                    }}
                    sx={{
                      color: "#108fb2",
                      backgroundColor: "white",
                      padding: "5px",
                      borderRadius: "50%",
                      cursor: "pointer",
                    }}
                  />
                </div>
              ) : null}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "absolute",
                  top: "50%",
                }}
              >
                {isLoading === true ? (
                  <CircularProgress
                    size={26}
                    style={{
                      color: "rgba(25,118,210,1)",
                      position: "absolute",
                    }}
                  />
                ) : null}
              </div>{" "}
            </div>
            <div className="mentorname">
              <h4>{getMentor.MentorName}</h4>
              <div className="Mentorinfo">
                <p>
                  <span style={{ fontWeight: "500", color: "#108fb2" }}>
                    ENGAGEMENT LEVEL:
                  </span>{" "}
                  EXPLORER
                </p>
                <p>
                  <span style={{ fontWeight: "500", color: "#108fb2" }}>
                    ACTIVE ON PORTAL SINCE:
                  </span>{" "}
                  {getMentor.createdDate}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="MentorBox">
          <div className="mentorbox-upperbox">
            <div className="mentor-fristcontect">
              <div className="fristbox combianbox">
                <div className="about-header-mentor">About</div>
                <Divider
                  sx={{
                    borderColor: "rgb(84 103 124 / 40%)",
                    marginTop: "5px",
                  }}
                />
                <div>
                  {isFullContentDisplayed ? (
                    <div dangerouslySetInnerHTML={{ __html: content }} />
                  ) : (
                    <>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: content.slice(0, 600),
                        }}
                      />
                      {content.length > 600 && "..."}
                    </>
                  )}
                  {content.length > 600 && (
                    <span
                      style={{
                        color: "#ed1e70",
                        cursor: "pointer",
                        fontSize: "14px",
                      }}
                      onClick={toggleContentDisplay}
                    >
                      {isFullContentDisplayed ? "See Less" : "See More"}
                    </span>
                  )}
                </div>
              </div>
              <div className="fristbox secondcount combianbox">
                <div className="about-header-mentor">personal Details</div>
                <Divider
                  sx={{
                    borderColor: "rgb(84 103 124 / 40%)",
                    marginTop: "5px",
                  }}
                />

                <div className="crad-contect-mentor">
                  <div>
                    <span className="spanmentor">mobile Number</span>
                    <div className="marginreduce">{getMentor.mobileNumber}</div>
                  </div>
                  <div>
                    <span className="spanmentor">email</span>
                    <div className="marginreduce">{getMentor.email_id}</div>
                  </div>
                  <div>
                    <span className="spanmentor">official Address </span>
                    <div className="marginreduce">
                      {getMentor.officialAddress}
                    </div>
                  </div>
                </div>
              </div>
              <div className="fristbox secondcount combianbox">
                <div className="about-header-mentor">Education Details</div>
                <Divider
                  sx={{
                    borderColor: "rgb(84 103 124 / 40%)",
                    marginTop: "5px",
                  }}
                />

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    padding: "10px 0px",
                  }}
                >
                  <div>
                    <span className="spanmentor">Qualifications</span>
                    <div className="marginreduce">
                      {getMentor.educationQualifications}
                    </div>
                  </div>
                  <div>
                    <span className="spanmentor">Specialisation</span>
                    <div className="marginreduce">
                      {getMentor.Specialisation}
                    </div>
                  </div>
                </div>
              </div>
              <div className="fristbox secondcount combianbox">
                <div className="about-header-mentor">professional Details</div>
                <Divider
                  sx={{
                    borderColor: "rgb(84 103 124 / 40%)",
                    marginTop: "5px",
                  }}
                />

                <div className="crad-contect-mentor">
                  <div>
                    <span className="spanmentor">area Of Expertise</span>
                    <div className="marginreduce">
                      {getMentor.areaOfExpertise}
                    </div>
                  </div>
                  <div>
                    <span className="spanmentor">Years Of Experience</span>
                    <div className="marginreduce">
                      {getMentor.yearsOfExperience}
                    </div>
                  </div>
                  <div>
                    <span className="spanmentor">Designation</span>
                    <div className="marginreduce">{getMentor.Designation}</div>
                  </div>
                </div>
                <div className="crad-contect-mentor">
                  <div>
                    <span className="spanmentor">current Employer</span>
                    <div className="marginreduce">
                      {getMentor.currentEmployer}
                    </div>
                  </div>
                  <div>
                    <span className="spanmentor">current Location</span>
                    <div className="marginreduce">
                      {getMentor.currentLocation}
                    </div>
                  </div>
                  <div>
                    <span className="spanmentor">
                      membership In Professional
                    </span>
                    <div className="marginreduce">
                      {getMentor.membershipInProfessional}
                    </div>
                  </div>
                  <div style={{ marginTop: "15px" }}>
                    <span className="spanmentor">
                      Professional Certifications
                    </span>
                    <div className="marginreduce">
                      {getMentor.professionalCertifications}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mentor-fristcontect">
              <div className="secoundbox combianbox">
                <div className="fristdiv-for-rating">
                  <div className="ratingcount">
                    <StarRateIcon sx={{ fontSize: "35px" }} />
                    <span style={{ fontSize: "42px", marginTop: "2px" }}>
                      {/* {getMentor.rating} */}0
                    </span>
                  </div>
                  <div className="ratingnumber">
                    <Rating name="half-rating" value={0} readOnly />

                    <span class="countrating">
                      {/* {getMentor.rating}  */}0 Rating
                    </span>
                  </div>
                </div>
                {Location.state.usertype !== "mentor" ? (
                  <div
                    // onClick={() => {
                    //   setOpenRating(true);
                    // }}
                    // style={{ cursor: "pointer" }}
                    className="secounddiv-for-rating"
                  >
                    <ThumbsUpDownIcon />{" "}
                    <span className="RateThisMentor">Rate This Mentor</span>
                  </div>
                ) : null}
                {Location.state.usertype === "mentor" ? (
                  <div className="secounddiv-for-rating">
                    <ThumbsUpDownIcon />{" "}
                    <span className="RateThisMentor">Your Rating</span>
                  </div>
                ) : null}
              </div>
              <div className="combianbox joindate">
                Joined VelaiVendum.com: {getMentor.createdDate}
              </div>
              {Location.state.usertype === "mentor" ? (
                <div className="mentorCVUpload">
                  <div className="about-header-mentor">CV Details</div>
                  <Divider
                    sx={{
                      borderColor: "rgb(84 103 124 / 40%)",
                      marginTop: "5px",
                    }}
                  />
                  <Box sx={{ mb: 1 }} aria-labelledby="Resume">
                    <Typography sx={{ fontWeight: 600, fontSize: "14px" }}>
                      <b>CV Name: </b>
                    </Typography>

                    {getMentor.CVUpload === "" ? (
                      <Typography>File not uploaded</Typography>
                    ) : (
                      <Typography
                        gutterBottom
                        sx={{
                          fontWeight: 600,
                          fontSize: "14px",
                          ml: 0.4,
                          wordWrap: "break-word",
                        }}
                        color="text.secondary"
                      >
                        {getMentor?.CVUpload?.name}
                      </Typography>
                    )}
                    {getMentor.CVUpload === "" ? (
                      ""
                    ) : (
                      <div>
                        <Tooltip title="Download Resume" arrow>
                          <IconButton
                            onClick={() => {
                              saveAs(
                                `${baseUrl}mentor/get/cv/${getMentor.email_id}`,
                                getMentor.CVUpload.name
                              );
                            }}
                          >
                            <FileDownloadOutlinedIcon
                              size="medium"
                              disabled={!getMentor.CVUpload}
                              sx={{
                                color: "green",
                                border: "1px solid green",
                                borderRadius: "50%",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                        |
                        <Tooltip title="Delete Resume" arrow>
                          <IconButton
                            onClick={() => {
                              setDeleteResume(true);
                            }}
                          >
                            <DeleteIcon
                              sx={{
                                color: "rgb(243 81 81)",
                                border: "1px solid rgb(243 81 81)",
                                borderRadius: "50%",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      </div>
                    )}
                  </Box>
                  <div className="CVUpload-card1">
                    <input
                      className="resumefile"
                      id="file-upload"
                      type="file"
                      accept=".docx,.doc,.pdf,"
                      onChange={(e) => {
                        CVhandleFileChange(e);
                      }}
                      ref={cvInputField}
                      required
                    />
                    <div>
                      <Button
                        disabled={getMentor.CVUpload ? true : false}
                        htmlFor="file"
                        variant="contained"
                        size="medium"
                        color="error"
                        className="resumeUpload"
                        sx={{ borderRadius: 10 }}
                        name="CVUpload"
                        onClick={() => {
                          cvInputField.current.click();
                        }}
                      >
                        <DriveFolderUploadIcon className="resumeIcon" />
                        CV Upload
                        {isSubmitting && (
                          <CircularProgress
                            size={24}
                            style={{
                              color: "#ffffff",
                              marginLeft: 12,
                            }}
                          />
                        )}
                      </Button>
                    </div>

                    <p className="main1">Supported files</p>
                    <p className="info1">
                      {".pdf/.doc/.docx only and File must be < 10Mb"}
                    </p>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <input
          ref={fileInputField}
          style={{ display: "none" }}
          type="file"
          accept="image/*"
          onChange={handleFileChange}
        />

        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {picture.cropperOpen && (
                <Box display="block">
                  <AvatarEditor
                    ref={setEditorRef}
                    image={picture.img}
                    width={200}
                    height={200}
                    border={20}
                    color={[255, 255, 255, 0.6]}
                    rotate={0}
                    scale={picture.zoom}
                  />
                  <Slider
                    aria-label="raceSlider"
                    value={picture.zoom}
                    min={1}
                    max={10}
                    step={0.1}
                    onChange={handleSlider}
                  ></Slider>
                </Box>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                handleCancel();
                handleClose();
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                handleSave();
                handleClose();
              }}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  } else {
    return (
      <div
        style={{
          height: "70vh",
          display: "grid",
          placeItems: "center",
        }}
      >
        <div class="dots"></div>
      </div>
    );
  }
};

export default MentorAboutUs;
