/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import useAxios from "../../../../api/useAxios";
import {
  Card,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Tooltip,
  Fab,
  FormControl,
  FormControlLabel,
  FormLabel,
  CardContent,
  CardHeader,
  Divider,
  Paper,
  Grid,
  Typography,
  Button,
  Chip,
  IconButton,
  Radio,
  RadioGroup,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  List,
  ListItemText,
  ListItem,
  Alert,
  Snackbar,
  TextField,
  FormHelperText,
} from "@mui/material";
import { ROUTINGDATA } from "../../../../components/ROUTINGDATA";
import SeekerDialog from "../../../../components/admin-panel/admin-components/DialogBoxes/SeekerDialog";
import ReactQuill from "react-quill";
import {
  ArrowBackRounded as ArrowBackRoundedIcon,
  CloseRounded as CloseRoundedIcon,
  VisibilityRounded as VisibilityRoundedIcon,
  EditRounded as EditRoundedIcon,
  FavoriteRounded as FavoriteRoundedIcon,
  CheckRounded as CheckRoundedIcon,
  SaveAlt as SaveAltIcon,
  PostAdd as PostAddIcon,
} from "@mui/icons-material";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import ContactSupportDialog from "../../../admin-panel/admin-components/DialogBoxes/ContactSupportDialog";
import ContactSupportSnack from "../../../admin-panel/admin-components/SnackBars/ContactSupportSnack";
import { saveAs } from "file-saver";
import ApproveDialog from "../../../admin-panel/admin-components/DialogBoxes/ApproveDialog";

function VerifierFullDetails() {
  const axiosData = useAxios();
  const baseUrl = axiosData.defaults.baseURL;
  const navigate = useNavigate();
  const location = useLocation();
  const [VerifierLoading, setVerifierLoading] = useState(false);
  const [approveOpen, setApproveOpen] = useState(false);
  const [isLoadingPage, setIsLoadingPage] = useState(false);
  const [MLAEmail, setMLAEmail] = useState("");
  const currentUrl = location.pathname;
  const verifierVolunteer = JSON.parse(localStorage.getItem("volunteer"));

  const volunteerValue = `Volunteer_${verifierVolunteer}`;

  const MP_MLA_shortRoute =
    ROUTINGDATA.LAND +
    ROUTINGDATA.MPConstituency +
    "/" +
    ROUTINGDATA.MP_MLAShortlisted_fullDetails;
  const MPshortRoute =
    ROUTINGDATA.LAND +
    ROUTINGDATA.MPConstituency +
    "/" +
    ROUTINGDATA.MP_Shortlisted_fullDetails;
  const MLAshortRoute =
    ROUTINGDATA.LAND +
    ROUTINGDATA.MLAconstituency +
    "/" +
    ROUTINGDATA.MLAShortlisted_fullDetails;
  const VolunteerShortRoute =
    ROUTINGDATA.LAND +
    ROUTINGDATA.volunteerPanel +
    "/" +
    ROUTINGDATA.volunteerShortlisted_fullDetails;

  const MP_MLA_supportRoute =
    ROUTINGDATA.LAND +
    ROUTINGDATA.MPConstituency +
    "/" +
    ROUTINGDATA.MP_MLASupported_fullDetails;
  const MPsupportRoute =
    ROUTINGDATA.LAND +
    ROUTINGDATA.MPConstituency +
    "/" +
    ROUTINGDATA.MP_Supported_fullDetails;
  const MLAsupportRoute =
    ROUTINGDATA.LAND +
    ROUTINGDATA.MLAconstituency +
    "/" +
    ROUTINGDATA.MLASupported_fullDetails;
  const VolunteerSupportRoute =
    ROUTINGDATA.LAND +
    ROUTINGDATA.volunteerPanel +
    "/" +
    ROUTINGDATA.volunteerSupported_fullDetails;

  const supportValueCondition =
    currentUrl == MP_MLA_shortRoute || currentUrl == MPshortRoute
      ? "MP"
      : currentUrl == MLAshortRoute
      ? "MLA"
      : currentUrl == VolunteerShortRoute
      ? volunteerValue
      : null;

  const verifierRoute =
    ROUTINGDATA.LAND +
    ROUTINGDATA.verifier +
    "/" +
    ROUTINGDATA.verifierFullDetails;

  const [familyApi, setFamilyApi] = useState({});

  const [fieldValue, setfieldValue] = useState("");
  const [getOne, setGetOne] = useState({});
  const [openContactDialog, setOpenContactDialog] = useState(false);
  const [open, setOpen] = useState(false);
  const [supportState, setSupportState] = useState({
    button: false,
    contactValue: false,
    hide: false,
    subButton: true,
  });
  const [openState, setOpenState] = useState({
    adminVerify: false,
    tick: false,
    close: false,
    loader: false,
    assign: true,
    select: false,

    buttonDisable: false,
    radio: false,
    value: "",
    dialog: false,
    verfiy: false,
  });
  // const supportState = sessionStorage.getItem("support_state");
  const state = location.state;

  const handleChange = () => {
    setOpen(!open);
  };
  const [openSnack, setOpenSnack] = useState(false);
  const [openSupportSnack, setOpenSupportSnack] = useState(false);
  const [snackOption, setSnackOption] = useState("");
  const fetchFamilyApi = async () => {
    try {
      const axiosFamilyData = await axiosData.get(`family/${state}`);
      const { data } = axiosFamilyData;
      setFamilyApi(data);
      if (data.contactPerson === "") {
        sessionStorage.setItem("support_state", false);
      }
    } catch (err) {
      console.log("Error", err);
    }
  };

  const updateFieldVerify = async (email_id, decide, seeker, verifier) => {
    try {
      await axiosData.put(`family/admin/${email_id}`, {
        fieldVerification: decide,
        remarks: openState.value,
        verifier_name: verifier,
        seeker_name: seeker,
      });
      fetchFamilyApi();
      setOpenSnack(true);
      setSnackOption("success");
      setOpenState({ ...openState, dialog: false });
    } catch (err) {
      console.log("Error", err);
    }
  };

  const getOneData = async (id) => {
    try {
      const getOneAxios = await axiosData.get(`seekers/email/${state}`);
      const { data } = getOneAxios;

      setGetOne(data);
      setVerifierLoading(true);
    } catch (err) {
      console.log("Error", err);
    }
  };

  const [checkedValue, setCheckedValue] = useState({
    fatherVerify: familyApi.Father?.adminverify
      ? familyApi.Father?.adminverify
      : "Not yet updated",
    motherVerify: familyApi.Mother?.adminverify
      ? familyApi.Mother?.adminverify
      : "Not yet updated",
    brother1Verify: familyApi.Brother1?.adminverify
      ? familyApi.Brother1?.adminverify
      : "Not yet updated",
    brother2Verify: familyApi.Brother2?.adminverify
      ? familyApi.Brother2?.adminverify
      : "Not yet updated",
    brother3Verify: familyApi.Brother3?.adminverify
      ? familyApi.Brother3?.adminverify
      : "Not yet updated",
    brother4Verify: familyApi.Brother4?.adminverify
      ? familyApi.Brother4?.adminverify
      : "Not yet updated",
  });
  const handleCheckboxChange = (event) => {
    setCheckedValue({
      ...checkedValue,
      [event.target.name]: event.target.value,
    });
    if (event.target.checked) {
      setOpenState({ ...openState, verfiy: true });
    }
  };
  const verifyStatusSubmit = async () => {
    try {
      await axiosData.put(`/family/verifier/${state}`, {
        Fatherverifystatus: checkedValue.fatherVerify,
        Motherverifystatus: checkedValue.motherVerify,
        Brother3verifystatus: checkedValue.brother3Verify,
        Brother2verifystatus: checkedValue.brother2Verify,
        Brother1verifystatus: checkedValue.brother1Verify,
      });
      fetchFamilyApi();
      setOpenState({ ...openState, radio: false, dialog: true, verfiy: false });
    } catch (err) {
      console.log("Error", err);
    }
  };

  const deleteFamilyDetails = async (id) => {
    try {
      await axiosData.put(`/family/verifier/${state}`, {
        Fatherverifystatus: "",
        Motherverifystatus: "",
        Brother3verifystatus: "",
        Brother2verifystatus: "",
        Brother1verifystatus: "",
      });
      fetchFamilyApi();
      setCheckedValue({
        ...checkedValue,
        fatherVerify: "Not yet updated",
        motherVerify: "Not yet updated",
        brother1Verify: "Not yet updated",
        brother2Verify: "Not yet updated",
        brother3Verify: "Not yet updated",
        brother4Verify: "Not yet updated",
      });

      setOpenState({ ...openState, dialog: false, radio: false });
      setfieldValue("");
    } catch (err) {
      console.log("Error", err);
    }
  };
  const handleValueChange = (v) => {
    setfieldValue(v);
  };
  const initialValues = {
    personName: "",
    mobile: Number,
    email_id: "",
    notes: "",
  };
  const validationSchema = Yup.object().shape({
    personName: Yup.string().required("Name is required"),
    email_id: Yup.string()
      .email("Invalid email")
      .required("Email is required")
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        "Invalid Email"
        // () => {
        //   setfocus(false);
        // }
      ),
    mobile: Yup.string()
      .matches(/^[0-9]+$/, "Invalid mobile number")
      .required("Mobile number is required")
      .min(10, "Mobile number must be at least 10 digits")
      .max(10, "Mobile number cannot exceed 10 digits"),
    // notes: Yup.string().required("Name is required"),
  });
  const handleSubmit = async (values) => {
    try {
      await axiosData.put(`/family/admin/${familyApi.email_id}`, {
        contactPerson: values.personName,
        contactMobile: values.mobile,
        contactEmail: values.email_id,
        contactNotes: values.notes,
        supported: supportValueCondition,
        email: familyApi.email_id,
        name: familyApi.Name,
        mobile: familyApi.mobile_number,
        contactSupport: "yes",
      });
      setOpenSupportSnack(true);
      fetchFamilyApi();
      setSupportState({
        ...supportState,
        button: true,
        contactValue: false,
        hide: false,
        subButton: false,
      });
      setOpenContactDialog(false);
    } catch (err) {}
  };

  const updateAdminVerify = async (email_id, decide, notes) => {
    setIsLoadingPage(true);
    try {
      await axiosData.put(
        `/seeker/email/${email_id}`,
        { notes: notes },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      await axiosData.put(`family/admin/${email_id}`, {
        shortList: decide,
        shortlistNotes: notes,
      });
      fetchFamilyApi();
      setApproveOpen(false);
    } catch (err) {
      console.log("Error", err);
    }
    setIsLoadingPage(false);
  };
  useEffect(() => {
    fetchFamilyApi();
    getOneData();
  }, []);
  if (VerifierLoading) {
    return (
      <>
        {" "}
        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <Box display="flex" justifyContent="space-between">
            <Tooltip title="Go Back">
              <Fab
                onClick={() => {
                  navigate(-1);
                }}
                size="small"
                sx={{
                  boxShadow: 0,
                  backgroundColor: "#339fcd",
                  color: "white",
                  "&:hover": { backgroundColor: "#217396" },
                }}
              >
                <ArrowBackRoundedIcon />
              </Fab>
            </Tooltip>
          </Box>
          <Card
            sx={{ borderRadius: "15px", boxShadow: "3px 3px 10px 3px #dddddd" }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                padding: "20px",
              }}
            >
              <span className="seeker-text">
                {familyApi.Name}'s Family Details
              </span>
              <Tooltip title="Full Details">
                <IconButton
                  onClick={() => setOpen(true)}
                  sx={{
                    color: "#339fcd",
                    transition: "0.3s",
                    "&:hover": { backgroundColor: "#217396", color: "#ffffff" },
                  }}
                >
                  <VisibilityRoundedIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Card>
          <Card sx={{ boxShadow: 0 }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow className="table-row">
                    <TableCell>Relation</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Qualification</TableCell>
                    <TableCell>Occupation</TableCell>
                    <TableCell>Annual Income</TableCell>
                    <TableCell>Own House</TableCell>
                    <TableCell>Plot/Land</TableCell>

                    {currentUrl == verifierRoute ? (
                      <TableCell>
                        Verified Status{" "}
                        {familyApi.fieldVerification === "Pending" ? (
                          !openState.radio ? (
                            <IconButton
                              sx={{ color: "#fff" }}
                              onClick={() =>
                                setOpenState({ ...openState, radio: true })
                              }
                            >
                              <EditRoundedIcon color="inherit" />
                            </IconButton>
                          ) : (
                            <IconButton
                              sx={{ color: "#fff" }}
                              onClick={() => {
                                setOpenState({
                                  ...openState,
                                  radio: false,
                                  verfiy: false,
                                });
                                setCheckedValue({
                                  ...checkedValue,
                                  fatherVerify: "Not yet updated",
                                  motherVerify: "Not yet updated",
                                  brother1Verify: "Not yet updated",
                                  brother2Verify: "Not yet updated",
                                  brother3Verify: "Not yet updated",
                                  brother4Verify: "Not yet updated",
                                });
                              }}
                            >
                              <CloseRoundedIcon />
                            </IconButton>
                          )
                        ) : null}
                      </TableCell>
                    ) : null}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!familyApi.Father?.Name ? (
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell>
                        <h3>No Data</h3>
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  ) : (
                    <>
                      {familyApi.Father?.Name && (
                        <TableRow hover className="tablecell">
                          <TableCell>Father</TableCell>
                          <TableCell>{familyApi.Father?.Name}</TableCell>
                          <TableCell>
                            {familyApi.Father?.Qualification}
                          </TableCell>
                          <TableCell>{familyApi.Father?.Occupation}</TableCell>
                          <TableCell>
                            {familyApi.Father?.AnnualIncome}
                          </TableCell>
                          <TableCell>{familyApi.Father?.OwnHouse}</TableCell>
                          <TableCell>{familyApi.Father?.Land}</TableCell>
                          {currentUrl == verifierRoute ? (
                            <TableCell>
                              {openState.radio ? (
                                <FormControl>
                                  <RadioGroup row>
                                    <FormControlLabel
                                      control={
                                        <Radio
                                          name="fatherVerify"
                                          defaultChecked={
                                            familyApi.Father?.verifystatus ===
                                            "Yes"
                                              ? true
                                              : false
                                          }
                                          value="Yes"
                                          onChange={(e) =>
                                            handleCheckboxChange(e)
                                          }
                                        />
                                      }
                                      label="Yes"
                                      labelPlacement="end"
                                    />
                                    <FormControlLabel
                                      control={
                                        <Radio
                                          name="fatherVerify"
                                          onChange={(e) =>
                                            handleCheckboxChange(e)
                                          }
                                        />
                                      }
                                      defaultChecked={
                                        familyApi?.Father?.verifystatus === "No"
                                          ? true
                                          : false
                                      }
                                      value="No"
                                      label="No"
                                      labelPlacement="end"
                                    />
                                  </RadioGroup>
                                </FormControl>
                              ) : familyApi.Father?.verifystatus ? (
                                familyApi.Father?.verifystatus
                              ) : (
                                checkedValue.fatherVerify
                              )}
                            </TableCell>
                          ) : null}
                        </TableRow>
                      )}
                      {familyApi.Mother?.Name && (
                        <TableRow hover className="tablecell">
                          <TableCell>Mother</TableCell>
                          <TableCell>{familyApi.Mother?.Name}</TableCell>
                          <TableCell>
                            {familyApi.Mother?.Qualification}
                          </TableCell>
                          <TableCell>{familyApi.Mother?.Occupation}</TableCell>
                          <TableCell>
                            {familyApi.Mother?.AnnualIncome}
                          </TableCell>
                          <TableCell>{familyApi.Mother?.OwnHouse}</TableCell>
                          <TableCell>{familyApi.Mother?.Land}</TableCell>
                          {currentUrl == verifierRoute ? (
                            <TableCell>
                              {openState.radio ? (
                                <FormControl>
                                  <RadioGroup row>
                                    <FormControlLabel
                                      control={
                                        <Radio
                                          name="motherVerify"
                                          value="Yes"
                                          onChange={(e) =>
                                            handleCheckboxChange(e)
                                          }
                                        />
                                      }
                                      label="Yes"
                                      labelPlacement="end"
                                    />
                                    <FormControlLabel
                                      control={
                                        <Radio
                                          name="motherVerify"
                                          onChange={(e) =>
                                            handleCheckboxChange(e)
                                          }
                                        />
                                      }
                                      value="No"
                                      label="No"
                                      labelPlacement="end"
                                    />
                                  </RadioGroup>
                                </FormControl>
                              ) : familyApi.Mother?.verifystatus ? (
                                familyApi.Mother?.verifystatus
                              ) : (
                                checkedValue.motherVerify
                              )}
                            </TableCell>
                          ) : null}
                        </TableRow>
                      )}
                      {familyApi.Brother1?.Name && (
                        <TableRow hover className="tablecell">
                          <TableCell>Brother 1</TableCell>
                          <TableCell>{familyApi.Brother1?.Name}</TableCell>
                          <TableCell>
                            {familyApi.Brother1?.Qualification}
                          </TableCell>
                          <TableCell>
                            {familyApi.Brother1?.Occupation}
                          </TableCell>
                          <TableCell>
                            {familyApi.Brother1?.AnnualIncome}
                          </TableCell>
                          <TableCell>{familyApi.Brother1?.OwnHouse}</TableCell>
                          <TableCell>{familyApi.Brother1?.Land}</TableCell>
                          {currentUrl == verifierRoute ? (
                            <TableCell>
                              {openState.radio ? (
                                <FormControl>
                                  <RadioGroup row>
                                    <FormControlLabel
                                      control={
                                        <Radio
                                          name="brother1Verify"
                                          value="Yes"
                                          onChange={(e) =>
                                            handleCheckboxChange(e)
                                          }
                                        />
                                      }
                                      label="Yes"
                                      labelPlacement="end"
                                    />
                                    <FormControlLabel
                                      control={
                                        <Radio
                                          name="brother1Verify"
                                          onChange={(e) =>
                                            handleCheckboxChange(e)
                                          }
                                        />
                                      }
                                      value="No"
                                      label="No"
                                      labelPlacement="end"
                                    />
                                  </RadioGroup>
                                </FormControl>
                              ) : familyApi.Brother1?.verifystatus ? (
                                familyApi.Brother1?.verifystatus
                              ) : (
                                checkedValue.brother1Verify
                              )}
                            </TableCell>
                          ) : null}
                        </TableRow>
                      )}
                      {familyApi.Brother2?.Name && (
                        <TableRow hover className="tablecell">
                          <TableCell>Brother2</TableCell>
                          <TableCell>{familyApi.Brother2?.Name}</TableCell>
                          <TableCell>
                            {familyApi.Brother2?.Qualification}
                          </TableCell>
                          <TableCell>
                            {familyApi.Brother2?.Occupation}
                          </TableCell>
                          <TableCell>
                            {familyApi.Brother2?.AnnualIncome}
                          </TableCell>
                          <TableCell>{familyApi.Brother2?.OwnHouse}</TableCell>
                          <TableCell>{familyApi.Brother2?.Land}</TableCell>
                          {currentUrl == verifierRoute ? (
                            <TableCell>
                              {openState.radio ? (
                                <FormControl>
                                  <RadioGroup row>
                                    <FormControlLabel
                                      control={
                                        <Radio
                                          name="brother2Verify"
                                          value="Yes"
                                          onChange={(e) =>
                                            handleCheckboxChange(e)
                                          }
                                        />
                                      }
                                      label="Yes"
                                      labelPlacement="end"
                                    />
                                    <FormControlLabel
                                      control={
                                        <Radio
                                          name="brother2Verify"
                                          onChange={(e) =>
                                            handleCheckboxChange(e)
                                          }
                                        />
                                      }
                                      value="No"
                                      label="No"
                                      labelPlacement="end"
                                    />
                                  </RadioGroup>
                                </FormControl>
                              ) : familyApi.Brother2?.verifystatus ? (
                                familyApi.Brother2?.verifystatus
                              ) : (
                                checkedValue.brother2Verify
                              )}
                            </TableCell>
                          ) : null}
                        </TableRow>
                      )}
                      {familyApi.Brother3?.Name && (
                        <TableRow hover className="tablecell">
                          <TableCell>Brother 3</TableCell>
                          <TableCell>{familyApi.Brother3?.Name}</TableCell>
                          <TableCell>
                            {familyApi.Brother3?.Qualification}
                          </TableCell>
                          <TableCell>
                            {familyApi.Brother3?.Occupation}
                          </TableCell>
                          <TableCell>
                            {familyApi.Brother3?.AnnualIncome}
                          </TableCell>
                          <TableCell>{familyApi.Brother3?.OwnHouse}</TableCell>
                          <TableCell>{familyApi.Brother3?.Land}</TableCell>
                          {currentUrl == verifierRoute ? (
                            <TableCell>
                              {openState.radio ? (
                                <FormControl>
                                  <RadioGroup row>
                                    <FormControlLabel
                                      control={
                                        <Radio
                                          name="brother3Verify"
                                          value="Yes"
                                          onChange={(e) =>
                                            handleCheckboxChange(e)
                                          }
                                        />
                                      }
                                      label="Yes"
                                      labelPlacement="end"
                                    />
                                    <FormControlLabel
                                      control={
                                        <Radio
                                          name="brother3Verify"
                                          onChange={(e) =>
                                            handleCheckboxChange(e)
                                          }
                                        />
                                      }
                                      value="No"
                                      label="No"
                                      labelPlacement="end"
                                    />
                                  </RadioGroup>
                                </FormControl>
                              ) : familyApi.Brother3?.verifystatus ? (
                                familyApi.Brother3?.verifystatus
                              ) : (
                                checkedValue.brother3Verify
                              )}
                            </TableCell>
                          ) : null}
                        </TableRow>
                      )}
                      {familyApi.Brother4?.Name && (
                        <TableRow hover className="tablecell">
                          <TableCell>Brother4</TableCell>
                          <TableCell>{familyApi.Brother4?.Name}</TableCell>
                          <TableCell>
                            {familyApi.Brother4?.Qualification}
                          </TableCell>
                          <TableCell>
                            {familyApi.Brother4?.Occupation}
                          </TableCell>
                          <TableCell>
                            {familyApi.Brother4?.AnnualIncome}
                          </TableCell>
                          <TableCell>{familyApi.Brother4?.OwnHouse}</TableCell>
                          <TableCell>{familyApi.Brother4?.Land}</TableCell>
                          {currentUrl == verifierRoute ? (
                            <TableCell>
                              {openState.radio ? (
                                <FormControl>
                                  <RadioGroup row>
                                    <FormControlLabel
                                      control={
                                        <Radio
                                          name="brother4Verify"
                                          value="Yes"
                                          onChange={(e) =>
                                            handleCheckboxChange(e)
                                          }
                                        />
                                      }
                                      label="Yes"
                                      labelPlacement="end"
                                    />
                                    <FormControlLabel
                                      control={
                                        <Radio
                                          name="brother4Verify"
                                          onChange={(e) =>
                                            handleCheckboxChange(e)
                                          }
                                        />
                                      }
                                      value="No"
                                      label="No"
                                      labelPlacement="end"
                                    />
                                  </RadioGroup>
                                </FormControl>
                              ) : familyApi.Brother4?.verifystatus ? (
                                familyApi.Brother4?.verifystatus
                              ) : (
                                checkedValue.brother4Verify
                              )}
                            </TableCell>
                          ) : null}
                        </TableRow>
                      )}
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
                padding: "10px",
              }}
            >
              {familyApi.fieldVerification ===
              "Completed" ? null : currentUrl == verifierRoute ? (
                <Button
                  sx={{
                    textTransform: "capitalize",
                  }}
                  variant="contained"
                  disabled={!openState.verfiy ? true : false}
                  onClick={verifyStatusSubmit}
                >
                  {" "}
                  Submit
                </Button>
              ) : null}
            </Box>
          </Card>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4.3}>
              <Box>
                <Card
                  sx={{
                    borderRadius: "15px",
                    boxShadow: "none",
                    width: "100%",
                  }}
                >
                  <CardContent>
                    <Box display="flex" flexDirection="column" gap="10px">
                      <Divider textAlign="left">Additional Details</Divider>
                      <Typography variant="body1">
                        <div className="align-text">
                          <span className="full-details">
                            {" "}
                            Types Of Support:
                          </span>
                          <div>{familyApi.TypeOfSupport}</div>
                        </div>
                      </Typography>
                      {familyApi.TypeOfSupport === "Others" && (
                        <Typography variant="body1">
                          <div className="align-text">
                            <span className="full-details"> Others:</span>
                            <div>{familyApi.Others}</div>
                          </div>
                        </Typography>
                      )}
                      <Typography variant="body1">
                        <div className="align-text">
                          <span className="full-details">
                            {" "}
                            Aadhaar Document:
                          </span>
                          <div>
                            <Tooltip
                              title={
                                familyApi?.aadharUpload
                                  ? ""
                                  : "Not Uploaded Yet"
                              }
                            >
                              <Box>
                                <Button
                                  variant="contained"
                                  startIcon={<SaveAltIcon />}
                                  disabled={!familyApi.aadharUpload}
                                  onClick={() => {
                                    saveAs(
                                      `${baseUrl}/family/proof/${familyApi.email_id}`
                                    );
                                  }}
                                >
                                  Download{" "}
                                </Button>
                              </Box>
                            </Tooltip>
                          </div>
                        </div>
                      </Typography>
                      {familyApi.TypeOfSupport === "Mentorship" ? null : (
                        <>
                          <Typography variant="body1">
                            <div className="align-text">
                              <span className="full-details">
                                {" "}
                                Approximate Amount:
                              </span>
                              <div>{familyApi.amount}</div>
                            </div>
                          </Typography>
                          <Divider textAlign="left">Bank Details</Divider>
                          <Typography variant="body1">
                            <div className="align-text">
                              <span className="full-details">
                                {" "}
                                Account Holder:
                              </span>
                              <div>{familyApi.BankDetails?.AccHolderName}</div>
                            </div>
                          </Typography>
                          <Typography variant="body1">
                            <div className="align-text">
                              <span className="full-details">
                                {" "}
                                Account Number:
                              </span>
                              <div>{familyApi.BankDetails?.AccountNumber}</div>
                            </div>
                          </Typography>
                          <Typography variant="body1">
                            <div className="align-text">
                              <span className="full-details"> IFSC code:</span>
                              <div>{familyApi.BankDetails?.IfscCode}</div>
                            </div>
                          </Typography>
                          <Typography variant="body1">
                            <div className="align-text">
                              <span className="full-details">
                                {" "}
                                Gpay Number:
                              </span>
                              <div>{familyApi.UpiNumber}</div>
                            </div>
                          </Typography>
                        </>
                      )}
                    </Box>
                    {currentUrl == verifierRoute ? (
                      <>
                        {" "}
                        <Box
                          display="flex"
                          flexDirection="column"
                          gap="10px"
                          sx={{ marginTop: "10px" }}
                        >
                          <Divider textAlign="left">
                            Verification Status
                          </Divider>
                          <Typography variant="body1">
                            <div className="align-text">
                              <span className="full-details">
                                Field Verification:
                              </span>
                              <div>
                                <Chip
                                  s
                                  label={familyApi.fieldVerification}
                                  variant="filled"
                                  color={
                                    familyApi.fieldVerification === "Pending" ||
                                    familyApi.fieldVerification === "pending"
                                      ? "warning"
                                      : familyApi.fieldVerification ===
                                          "completed" ||
                                        familyApi.fieldVerification ===
                                          "Completed"
                                      ? "success"
                                      : "error"
                                  }
                                  sx={{
                                    color: "#ffffff",
                                  }}
                                />
                              </div>
                            </div>
                          </Typography>
                        </Box>
                      </>
                    ) : (
                      <Box>
                        <Divider textAlign="left">Shortlist Status</Divider>
                        <Typography variant="body1">
                          <div className="align-text">
                            <span className="full-details">Shortlist:</span>
                            <div>
                              {familyApi.shortList === "" ? (
                                <Button
                                  startIcon={<PostAddIcon />}
                                  variant="contained"
                                  sx={{
                                    textTransform: "capitalize ",
                                    boxShadow: 0,
                                    borderRadius: "16px",
                                  }}
                                  onClick={() => {
                                    setMLAEmail(familyApi.email_id);
                                    setApproveOpen(true);
                                  }}
                                >
                                  Shortlist
                                </Button>
                              ) : (
                                <>
                                  <Chip
                                    label={
                                      familyApi.shortList === ""
                                        ? "Pending"
                                        : "Completed"
                                    }
                                    variant="filled"
                                    color={
                                      familyApi.shortlist === ""
                                        ? "warning"
                                        : "success"
                                    }
                                    sx={{
                                      color: "#ffffff",
                                    }}
                                  />
                                </>
                              )}
                            </div>
                          </div>
                        </Typography>
                        {familyApi.shortList === "" ? null : (
                          <Typography variant="body1">
                            <div
                              className="align-text"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <span className="full-details">
                                {" "}
                                Shortlisted notes:
                              </span>
                              <div>
                                <Typography
                                  dangerouslySetInnerHTML={{
                                    __html: familyApi.shortlistNotes,
                                  }}
                                />
                              </div>
                            </div>
                          </Typography>
                        )}
                      </Box>
                    )}
                  </CardContent>
                </Card>
              </Box>
            </Grid>

            <Grid item xs={12} md={7.7}>
              {currentUrl == MP_MLA_shortRoute ||
              currentUrl == MPshortRoute ||
              currentUrl == VolunteerShortRoute ||
              currentUrl == MLAshortRoute ||
              currentUrl == MP_MLA_supportRoute ||
              currentUrl == MPsupportRoute ||
              currentUrl == VolunteerSupportRoute ||
              currentUrl == MLAsupportRoute ? (
                <>
                  <Box>
                    <Card
                      sx={{
                        borderRadius: "15px",
                        boxShadow: "none",
                      }}
                    >
                      <CardHeader title="Contact Details for to support" />
                      <Divider />
                      <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                        validationSchema={validationSchema}
                      >
                        {(formikProps) => {
                          return (
                            <form onSubmit={formikProps.handleSubmit}>
                              <CardContent>
                                <Grid container direction={"column"}>
                                  <Grid item direction={"row"}>
                                    <Box sx={{ display: "flex", gap: "10px" }}>
                                      <Button
                                        disabled={
                                          familyApi.contactPerson === "" &&
                                          supportState.button === false
                                            ? false
                                            : true
                                        }
                                        variant="contained"
                                        sx={{
                                          textTransform: "capitalize ",
                                          boxShadow: 0,
                                          borderRadius: "16px",
                                        }}
                                        startIcon={
                                          familyApi.contactPerson === "" ? (
                                            <FavoriteRoundedIcon />
                                          ) : (
                                            <CheckRoundedIcon />
                                          )
                                        }
                                        onClick={() => {
                                          sessionStorage.setItem(
                                            "support_state",
                                            true
                                          );
                                          setSupportState({
                                            ...supportState,
                                            contactValue: true,
                                            button: true,
                                            hide: true,
                                          });
                                        }}
                                      >
                                        {familyApi.contactPerson === ""
                                          ? "Support"
                                          : "Supported"}
                                      </Button>

                                      {familyApi.contactPerson == "" ? null : (
                                        <Button
                                          // disabled={
                                          //   familyApi.contactPerson === "" ? false : true
                                          // }
                                          variant="contained"
                                          sx={{
                                            textTransform: "capitalize ",
                                            boxShadow: 0,
                                            borderRadius: "16px",
                                          }}
                                          startIcon={<EditRoundedIcon />}
                                          // startIcon={
                                          //   familyApi.contactPerson === "" ? (
                                          //     <FavoriteRoundedIcon />
                                          //   ) : (
                                          //     <CheckRoundedIcon />
                                          //   )
                                          // }
                                          onClick={() => {
                                            setSupportState({
                                              ...supportState,
                                              contactValue: true,
                                              hide: true,
                                              subButton: true,
                                            });
                                            formikProps.setFieldValue(
                                              "personName",
                                              familyApi.contactPerson
                                            );
                                            formikProps.setFieldValue(
                                              "mobile",
                                              familyApi.contactMobile
                                            );
                                            formikProps.setFieldValue(
                                              "email_id",
                                              familyApi.contactEmail
                                            );
                                            formikProps.setFieldValue(
                                              "notes",
                                              familyApi.contactNotes
                                            );
                                          }}
                                        >
                                          Edit
                                        </Button>
                                      )}
                                    </Box>
                                  </Grid>
                                  <Grid item>
                                    <Divider
                                      textAlign="left"
                                      sx={{ padding: "10px 0px" }}
                                    >
                                      Contact Details
                                    </Divider>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "10px",
                                      }}
                                    >
                                      <Typography variant="body1">
                                        <div className="align-text">
                                          <span className="full-details">
                                            {" "}
                                            Name:
                                          </span>
                                          <div>
                                            {" "}
                                            <FormControl>
                                              <FormLabel></FormLabel>
                                              {familyApi?.contactPerson &&
                                              supportState.contactValue ===
                                                false ? (
                                                familyApi.contactPerson
                                              ) : supportState.contactValue ===
                                                true ? (
                                                <TextField
                                                  variant="outlined"
                                                  size="small"
                                                  name="personName"
                                                  onChange={(e) => {
                                                    const value =
                                                      e.target.value;
                                                    const setdata =
                                                      value.replace(
                                                        /[^a-zA-Z'. ]/gi,
                                                        ""
                                                      );
                                                    formikProps.setFieldValue(
                                                      "personName",
                                                      setdata
                                                    );
                                                  }}
                                                  value={
                                                    formikProps.values
                                                      .personName
                                                  }
                                                  placeholder="Contact Person"
                                                  onBlur={
                                                    formikProps.handleBlur
                                                  }
                                                  error={
                                                    formikProps.touched
                                                      .personName &&
                                                    Boolean(
                                                      formikProps.errors
                                                        .personName
                                                    )
                                                  }
                                                  helperText={
                                                    formikProps.touched
                                                      .personName &&
                                                    formikProps.errors
                                                      .personName
                                                  }
                                                />
                                              ) : (
                                                "Not Updated Yet"
                                              )}
                                            </FormControl>
                                          </div>
                                        </div>
                                      </Typography>
                                      <Typography variant="body1">
                                        <div className="align-text">
                                          <span className="full-details">
                                            Email:
                                          </span>
                                          <div>
                                            {" "}
                                            <FormControl>
                                              <FormLabel></FormLabel>

                                              {familyApi?.contactPerson &&
                                              supportState.contactValue ===
                                                false ? (
                                                familyApi.contactEmail
                                              ) : supportState.contactValue ===
                                                true ? (
                                                <TextField
                                                  variant="outlined"
                                                  size="small"
                                                  name="email_id"
                                                  onChange={(e) => {
                                                    const value =
                                                      e.target.value;
                                                    const setdata =
                                                      value.replace(
                                                        /[^a-z0-9'.@]/gi,
                                                        ""
                                                      );
                                                    formikProps.setFieldValue(
                                                      "email_id",
                                                      setdata.toLowerCase()
                                                    );
                                                  }}
                                                  value={
                                                    formikProps.values.email_id
                                                  }
                                                  placeholder="Email-id"
                                                  onBlur={
                                                    formikProps.handleBlur
                                                  }
                                                  error={
                                                    formikProps.touched
                                                      .email_id &&
                                                    Boolean(
                                                      formikProps.errors
                                                        .email_id
                                                    )
                                                  }
                                                  helperText={
                                                    formikProps.touched
                                                      .email_id &&
                                                    formikProps.errors.email_id
                                                  }
                                                />
                                              ) : (
                                                "Not Updated Yet"
                                              )}
                                            </FormControl>
                                          </div>
                                        </div>
                                      </Typography>
                                      <Typography variant="body1">
                                        <div className="align-text">
                                          <span className="full-details">
                                            {" "}
                                            Mobile :
                                          </span>
                                          <div>
                                            <FormControl>
                                              <FormLabel></FormLabel>
                                              {familyApi.contactPerson &&
                                              supportState.contactValue ===
                                                false ? (
                                                familyApi?.contactMobile
                                              ) : supportState.contactValue ===
                                                true ? (
                                                <TextField
                                                  variant="outlined"
                                                  size="small"
                                                  name="mobile"
                                                  type="number"
                                                  onChange={(e) => {
                                                    const value =
                                                      e.target.value;
                                                    if (value.length <= 10) {
                                                      formikProps.handleChange(
                                                        e
                                                      );
                                                    }
                                                  }}
                                                  value={
                                                    formikProps.values.mobile
                                                  }
                                                  placeholder="Mobile Number"
                                                  onWheel={(e) => {
                                                    e.preventDefault();
                                                    e.target.blur();
                                                  }}
                                                  onKeyDown={(e) =>
                                                    [
                                                      "e",
                                                      "E",
                                                      "+",
                                                      "-",
                                                      ".",
                                                      "ArrowUp",
                                                      "ArrowDown",
                                                    ].includes(e.key) &&
                                                    e.preventDefault()
                                                  }
                                                  onBlur={
                                                    formikProps.handleBlur
                                                  }
                                                  error={
                                                    formikProps.touched
                                                      .mobile &&
                                                    Boolean(
                                                      formikProps.errors.mobile
                                                    )
                                                  }
                                                  helperText={
                                                    formikProps.touched
                                                      .mobile &&
                                                    formikProps.errors.mobile
                                                  }
                                                />
                                              ) : (
                                                "Not Updated Yet"
                                              )}
                                            </FormControl>
                                          </div>
                                        </div>
                                      </Typography>
                                      <Typography variant="body1">
                                        <div className="align-text">
                                          <span className="full-details">
                                            {" "}
                                            Remarks:
                                          </span>
                                          <div>
                                            {" "}
                                            <FormControl>
                                              <FormLabel></FormLabel>
                                              {familyApi.contactPerson &&
                                              supportState.contactValue ===
                                                false ? (
                                                familyApi.contactNotes
                                              ) : supportState.contactValue ===
                                                true ? (
                                                <TextField
                                                  variant="outlined"
                                                  size="small"
                                                  name="notes"
                                                  onChange={(e) => {
                                                    const value =
                                                      e.target.value;
                                                    const setdata =
                                                      value.replace(
                                                        /[^a-z0-9'. "]/gi,
                                                        ""
                                                      );
                                                    formikProps.setFieldValue(
                                                      "notes",
                                                      setdata
                                                    );
                                                  }}
                                                  value={
                                                    formikProps.values.notes
                                                  }
                                                  placeholder="Notes"
                                                  // onBlur={formikProps.handleBlur}
                                                  error={
                                                    formikProps.touched.notes &&
                                                    Boolean(
                                                      formikProps.errors.notes
                                                    )
                                                  }
                                                  helperText={
                                                    formikProps.touched.notes &&
                                                    formikProps.errors.notes
                                                  }
                                                />
                                              ) : (
                                                "Not Updated Yet"
                                              )}
                                            </FormControl>
                                          </div>
                                        </div>
                                      </Typography>
                                    </Box>
                                    <Button
                                      variant="outlined"
                                      sx={{
                                        textTransform: "capitalize",
                                        visibility: `${
                                          supportState.hide
                                            ? "visible"
                                            : "hidden"
                                        }`,
                                      }}
                                      onClick={() => {
                                        setSupportState({
                                          ...supportState,
                                          button: false,
                                          hide: false,
                                          contactValue: false,
                                        });
                                        formikProps.handleReset();
                                      }}
                                    >
                                      Cancel
                                    </Button>
                                    {formikProps.isValid &&
                                    formikProps.dirty &&
                                    supportState.subButton === true &&
                                    (formikProps.values.personName !=
                                      familyApi.contactPerson ||
                                      formikProps.values.email_id !=
                                        familyApi.contactEmail ||
                                      formikProps.values.mobile !=
                                        familyApi.contactMobile ||
                                      formikProps.values.notes !=
                                        familyApi.contactNotes) ? (
                                      <Button
                                        // type="submit"
                                        variant="contained"
                                        sx={{ textTransform: "capitalize" }}
                                        onClick={() =>
                                          setOpenContactDialog(true)
                                        }
                                      >
                                        submit
                                      </Button>
                                    ) : null}
                                  </Grid>
                                </Grid>
                              </CardContent>
                              <ContactSupportDialog
                                formikProps={formikProps}
                                supportState={supportState}
                                setSupportState={setSupportState}
                                openContactDialog={openContactDialog}
                                setOpenContactDialog={setOpenContactDialog}
                                setOpenSupportSnack={setOpenSupportSnack}
                              />
                              <ContactSupportSnack
                                openSupportSnack={openSupportSnack}
                                setOpenSupportSnack={setOpenSupportSnack}
                                contactName={formikProps.values.personName}
                                seekName={familyApi.Name}
                              />
                            </form>
                          );
                        }}
                      </Formik>
                    </Card>
                  </Box>
                </>
              ) : null}
            </Grid>
          </Grid>
        </Box>
        <SeekerDialog
          open={open}
          handleChange={handleChange}
          getOne={getOne}
          setOpen={setOpen}
        />
        {currentUrl == verifierRoute ? (
          <>
            {" "}
            <Dialog
              open={openState.dialog}
              sx={{
                "& .MuiPaper-root": {
                  borderRadius: "20px",
                  width: "100%",
                },
              }}
            >
              <DialogTitle bgcolor="#055779" color="white">
                Final Verification
              </DialogTitle>
              <DialogContent>
                <List>
                  <ListItem>
                    <Grid container columns={12}>
                      <ListItemText>
                        <Typography sx={{ fontWeight: "bold" }}>
                          {" "}
                          Relation
                        </Typography>
                      </ListItemText>
                      <ListItemText>
                        <Typography
                          sx={{ fontWeight: "bold", textAlign: "center" }}
                        >
                          Name
                        </Typography>
                      </ListItemText>

                      <ListItemText>
                        <Typography sx={{ fontWeight: "bold" }}>
                          {" "}
                          Verified Status
                        </Typography>
                      </ListItemText>
                    </Grid>
                  </ListItem>
                  <ListItem>
                    <Grid container columns={12}>
                      <ListItemText>Father</ListItemText>
                      <ListItemText sx={{ textAlign: "left" }}>
                        {familyApi.Father?.Name}
                      </ListItemText>

                      <ListItemText sx={{ textAlign: "left" }}>
                        {familyApi.Father?.verifystatus}
                      </ListItemText>
                    </Grid>
                  </ListItem>
                  <ListItem>
                    <Grid container columns={12}>
                      <ListItemText>Mother</ListItemText>
                      <ListItemText sx={{ textAlign: "left" }}>
                        {familyApi.Mother?.Name}
                      </ListItemText>

                      <ListItemText sx={{ textAlign: "left" }}>
                        {familyApi.Mother?.verifystatus}
                      </ListItemText>
                    </Grid>
                  </ListItem>
                  <ListItem>
                    <Grid container columns={12}>
                      {familyApi.Brother1?.Name && (
                        <>
                          {" "}
                          <ListItemText>Brother1</ListItemText>
                          <ListItemText sx={{ textAlign: "left" }}>
                            {familyApi.Brother1?.Name}
                          </ListItemText>
                          <ListItemText sx={{ textAlign: "left" }}>
                            {familyApi.Brother1?.verifystatus}
                          </ListItemText>
                        </>
                      )}
                    </Grid>
                  </ListItem>
                  <ListItem>
                    <Grid container columns={12}>
                      {familyApi.Brother2?.Name && (
                        <>
                          {" "}
                          <ListItemText>Brother2</ListItemText>
                          <ListItemText sx={{ textAlign: "left" }}>
                            {familyApi.Brother2?.Name}
                          </ListItemText>
                          <ListItemText sx={{ textAlign: "left" }}>
                            {familyApi.Brother2?.verifystatus}
                          </ListItemText>
                        </>
                      )}
                    </Grid>
                  </ListItem>
                  <ListItem>
                    <Grid container columns={12}>
                      {familyApi.Brother3?.Name && (
                        <>
                          {" "}
                          <ListItemText>Brother3</ListItemText>
                          <ListItemText sx={{ textAlign: "left" }}>
                            {familyApi.Brother3?.Name}
                          </ListItemText>
                          <ListItemText sx={{ textAlign: "left" }}>
                            {familyApi.Brother3?.verifystatus}
                          </ListItemText>
                        </>
                      )}
                    </Grid>
                  </ListItem>
                  <ListItem>
                    <Grid container columns={12}>
                      {familyApi.Brother4?.Name && (
                        <>
                          <ListItemText>Brother4</ListItemText>
                          <ListItemText sx={{ textAlign: "left" }}>
                            {familyApi.Brother4?.Name}
                          </ListItemText>

                          <ListItemText sx={{ textAlign: "left" }}>
                            {familyApi.Brother4?.verifystatus}
                          </ListItemText>
                        </>
                      )}
                    </Grid>
                  </ListItem>
                </List>
                <DialogContentText>
                  <p style={{ fontWeight: "bold" }}>
                    Kindly verify the details provided above. If you have any
                    concerns regarding the user, please specify in the field
                    below.
                  </p>
                </DialogContentText>
                <ReactQuill value={fieldValue} onChange={handleValueChange} />
              </DialogContent>
              <DialogActions>
                {" "}
                <Button
                  variant="outlined"
                  sx={{
                    backgroundColor: "#f2f2f2",
                    textTransform: "lowercase",

                    color: "black",
                    borderColor: "white",
                    fontFamily: "Poppins-Medium",
                    "&:hover": {
                      textTransform: "lowercase",

                      color: "#f2f2f2",
                      backgroundColor: "#339fcd",
                      borderColor: "#f2f2f2",
                      fontFamily: "Poppins-Medium",
                    },
                  }}
                  onClick={() => deleteFamilyDetails()}
                >
                  Cancel
                </Button>
                <Button
                  variant="outlined"
                  sx={{
                    textTransform: "lowercase",

                    color: "white",
                    backgroundColor: "#ed1067",
                    borderColor: "#ed1067",
                    fontFamily: "Poppins-Medium",
                    transition: "all 0.3s",
                    "&:hover": {
                      textTransform: "lowercase",

                      color: "#ed1067",
                      borderColor: "#ed1067",
                      fontFamily: "Poppins-Medium",
                    },
                  }}
                  onClick={() =>
                    updateFieldVerify(
                      familyApi.email_id,
                      "Completed",
                      familyApi.Name,
                      familyApi.VerifierName
                    )
                  }
                >
                  Submit{" "}
                </Button>
              </DialogActions>
            </Dialog>
            <Snackbar
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              open={openSnack}
              autoHideDuration={3000}
              onClose={() => setOpenSnack(false)}
            >
              <Alert
                severity={
                  snackOption === "success"
                    ? "success"
                    : snackOption === "error"
                    ? "error"
                    : ""
                }
                variant="filled"
                onClose={() => setOpenSnack(false)}
              >
                <Typography variant="body1">
                  {" "}
                  Field Verification
                  {snackOption === "success"
                    ? " was Completed,sent to admin for final verification !"
                    : snackOption === "error"
                    ? "was Declined !"
                    : ""}
                </Typography>
              </Alert>
            </Snackbar>
          </>
        ) : null}
        {currentUrl == MP_MLA_supportRoute ||
        currentUrl == MPsupportRoute ||
        currentUrl == VolunteerSupportRoute ||
        currentUrl == MLAsupportRoute ||
        currentUrl == MP_MLA_shortRoute ||
        currentUrl == MPshortRoute ||
        currentUrl == VolunteerShortRoute ||
        currentUrl == MLAshortRoute ||
        currentUrl == verifierRoute ? null : (
          <ApproveDialog
            approveOpen={approveOpen}
            setApproveOpen={setApproveOpen}
            mlaEmail={MLAEmail}
            isLoading={isLoadingPage}
            updateAdminVerify={updateAdminVerify}
          />
        )}
      </>
    );
  } else {
    return (
      <div
        style={{
          height: "70vh",
          display: "grid",
          placeItems: "center",
        }}
      >
        <div class="dots"></div>
      </div>
    );
  }
}

export default VerifierFullDetails;
