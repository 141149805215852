import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, Button, CircularProgress } from "@mui/material";
import useAxios from "../../../../api/useAxios";
import { useLocation, useNavigate } from "react-router";
import Context from "../Context";
import VisibilityIcon from "@mui/icons-material/Visibility";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import EditIcon from "@mui/icons-material/Edit";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { styled } from "@mui/system";


const DisabledButton = styled(Button)(({ theme }) => ({
  "&.Mui-disabled": {
    color: theme.palette.common.white,
    backgroundColor: "rgb(93 189 93 / 91%)",
  },
}));

function FamilyDetail({ setput }) {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isNOSubmitting, setIsNOSubmitting] = useState(false);

  const axiosData = useAxios();

  const Location = useLocation();

  const [family, setFamily] = useState();

  useEffect(() => {
    FamilyDetail();
  }, []);

  const FamilyDetail = async () => {
    try {
      const getOneAxios = await axiosData.get(`/family/${Location.state}`);

      const { data } = getOneAxios;
      if (data) {
        setFamily({ ...family, data });
      }

      return family;
    } catch (err) {
      console.log("Error", err);
    }
  };

  return (
    <Context.Consumer>
      {(Context) => {
        return (
          <div>
            <Grid xs={12} md={12} lg={12}>
              <Box className="needSupportHeader">
                <Box
                  id="FamilyDetails"
                  sx={{
                    fontSize: "18px",
                    textTransform: "uppercase",
                    mb: 1,
                    color: "black",
                  }}
                >
                  <b style={{color:"rgb(16 143 178)"}}>Need Any Support for getting Job ?</b>
                </Box>
                <Box className="needSupportButton">
                  <Box className="yesNoEditViewBtn">
                    <Box>
                      {Context.profile.needSupport !== "YES" ? (
                        <Button
                          variant="contained"
                          sx={{ ml: 1 }}
                          onClick={async () => {
                            setIsSubmitting(true);
                            await axiosData.put(
                              `/seeker/email/${Location.state}`,
                              { needSupport: "YES" },
                              {
                                headers: {
                                  "Content-Type": "multipart/form-data",
                                },
                              }
                            );

                            navigate("/main/needSupport", {
                              state: {
                                email_id: Location.state,
                                action: "view",
                                Name: Context.profile.full_name,
                                mla_constituency:
                                  Context.profile.mlaConstituency,
                                votingDistrict: Context.profile.votingDistrict,
                                industry: Context.profile.industry,
                                mpConstituency: Context.profile.mpConstituency,
                                mobile_number: Context.profile.mobile_number,
                              },
                            });
                            setIsSubmitting(false);
                          }}
                        >
                          Yes{" "}
                          {isSubmitting && (
                            <CircularProgress
                              size={24}
                              style={{
                                color: "#ffffff",
                                marginLeft: 12,
                              }}
                            />
                          )}
                        </Button>
                      ) : (
                        <DisabledButton
                          variant="contained"
                          color="success"
                          disabled={
                            Context.profile.HelpStatus === "Yes" ||
                            family?.data.VerifierName ||
                            family?.data?.finalVerification === "Completed" ||
                            Context.profile.HelpStatus === "No"
                          }
                          sx={{ ml: 1 }}
                          onClick={async () => {
                            setIsSubmitting(true);
                            await axiosData.put(
                              `/seeker/email/${Location.state}`,
                              { needSupport: "YES" },
                              {
                                headers: {
                                  "Content-Type": "multipart/form-data",
                                },
                              }
                            );

                            navigate("/main/needSupport", {
                              state: {
                                email_id: Location.state,
                                action: "view",
                                Name: Context.profile.full_name,
                                mla_constituency:
                                  Context.profile.mlaConstituency,
                                votingDistrict: Context.profile.votingDistrict,
                                industry: Context.profile.industry,
                                mpConstituency: Context.profile.mpConstituency,
                                mobile_number: Context.profile.mobile_number,
                              },
                            });
                            setIsSubmitting(false);
                          }}
                        >
                          Yes{" "}
                          {isSubmitting && (
                            <CircularProgress
                              size={24}
                              style={{
                                color: "#ffffff",
                                marginLeft: 12,
                              }}
                            />
                          )}
                        </DisabledButton>
                      )}

                      {Context.profile.needSupport !== "NO" ? (
                        <Button
                          variant="contained"
                          disabled={
                            family?.data.VerifierName ||
                            family?.data?.finalVerification === "Completed" ||
                            Context.profile.HelpStatus === "Yes" ||
                            Context.profile.HelpStatus === "No"
                          }
                          sx={{ ml: 1 }}
                          onClick={async () => {
                            setIsNOSubmitting(true);
                            await axiosData.put(
                              `/seeker/email/${Location.state}`,
                              { needSupport: "NO" },
                              {
                                headers: {
                                  "Content-Type": "multipart/form-data",
                                },
                              }
                            );
                            setput(true);
                            setIsNOSubmitting(false);
                          }}
                        >
                          No
                          {isNOSubmitting && (
                            <CircularProgress
                              size={24}
                              style={{
                                color: "#ffffff",
                                marginLeft: 12,
                              }}
                            />
                          )}
                        </Button>
                      ) : (
                        <DisabledButton
                          disabled
                          variant="contained"
                          color="success"
                          sx={{ ml: 1 }}
                          onClick={async () => {
                            setIsNOSubmitting(true);
                            await axiosData.put(
                              `/seeker/email/${Location.state}`,
                              { needSupport: "NO" },
                              {
                                headers: {
                                  "Content-Type": "multipart/form-data",
                                },
                              }
                            );
                            setput(true);
                            setIsNOSubmitting(false);
                          }}
                        >
                          No
                          {isNOSubmitting && (
                            <CircularProgress
                              size={24}
                              style={{
                                color: "#ffffff",
                                marginLeft: 12,
                              }}
                            />
                          )}
                        </DisabledButton>
                      )}
                    </Box>
                    <Box>
                      {family?.data.VerifierName ||
                      Context.profile.HelpStatus === "No" ||
                      Context.profile.HelpStatus === "Pending" ||
                      family?.data?.finalVerification === "Completed" ||
                      Context.profile.needSupport === "NO" ? null : (
                        <Tooltip title="Edit Details" arrow>
                          <IconButton
                            onClick={() => {
                              navigate("/main/needSupport", {
                                state: {
                                  email_id: Location.state,
                                  action: "view",
                                  Name: Context.profile.full_name,
                                  TypeOfSupport: Context.profile.TypeOfSupport,
                                  Others: Context.profile.Others,
                                  amount: Context.profile.Others,
                                  HelpStatus: Context.profile.HelpStatus,
                                  mla_constituency:
                                    Context.profile.mlaConstituency,
                                  votingDistrict:
                                    Context.profile.votingDistrict,
                                  industry: Context.profile.industry,
                                  mpConstituency:
                                    Context.profile.mpConstituency,
                                  mobile_number: Context.profile.mobile_number,
                                },
                              });
                            }}
                          >
                            <EditIcon
                              sx={{
                                color: "rgba(25,118,210,1)",
                                cursor: "pointer",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                      {Context.profile.HelpStatus !== "Pending" &&
                      Context.profile.needSupport !== "NO" ? (
                        <Tooltip title="View Details" arrow>
                          <IconButton
                            onClick={() => {
                              navigate("/main/needSupport", {
                                state: {
                                  email_id: Location.state,
                                  action: "edit",
                                  TypeOfSupport: Context.profile.TypeOfSupport,
                                  Others: Context.profile.Others,
                                  amount: Context.profile.Others,
                                  HelpStatus: Context.profile.HelpStatus,
                                  Name: Context.profile.full_name,
                                  mla_constituency:
                                    Context.profile.mlaConstituency,
                                  votingDistrict:
                                    Context.profile.votingDistrict,
                                  industry: Context.profile.industry,
                                  mpConstituency:
                                    Context.profile.mpConstituency,
                                  mobile_number: Context.profile.mobile_number,
                                },
                              });
                            }}
                          >
                            <VisibilityIcon
                              sx={{
                                color: "rgba(237,16,103,1)",
                                cursor: "pointer",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      ) : null}
                    </Box>
                  </Box>

                  {family?.data?.TypeOfSupport !== "Mentorship" &&
                  Context.profile.HelpStatus === "Yes" &&
                  Context.profile.needSupport !== "NO" ? (
                    <Tooltip title="Add Bank Details" arrow>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          navigate("/main/BankDetails", {
                            state: {
                              email_id: Location.state,
                              Name: Context.profile.full_name,
                              TypeOfSupport: Context.profile.TypeOfSupport,
                              Others: Context.profile.Others,
                              amount: Context.profile.Others,
                              HelpStatus: Context.profile.HelpStatus,
                            },
                          });
                        }}
                      >
                        {" "}
                        <AccountBalanceIcon sx={{ mr: 0.5 }} />
                        Bank Details
                      </Button>
                    </Tooltip>
                  ) : null}
                </Box>
              </Box>
              <hr/>

              {Context.profile.needSupport === "" ||
              Context.profile.needSupport === "NO" ? (
                <Typography sx={{ fontWeight: 600 }} color="text.secondary">
                  To fill this details is the most important thing for need any
                  support for getting Job.
                </Typography>
              ) : Context.profile.needSupport === "YES" &&
                Context.profile.TypeOfSupport === "" ? (
                <Typography sx={{ fontWeight: 600 }} color="text.secondary">
                  Please fill family details for need support.
                </Typography>
              ) : (
                <>
                  {Context.profile.needSupport === "YES" && family && (
                    <Box sx={{ mt: 1 }}>
                      <div className="FamilyDetailsAlign">
                        <div>
                          <Typography
                            className="FamilyNameDetailsAlign"
                            sx={{
                              fontSize: "16px",
                              fontWeight: 600,
                              color: "black",
                              mr: 1,
                            }}
                          >
                            <text>Type of support</text>
                            <text>:</text>
                          </Typography>
                        </div>
                        <div>
                          <Typography
                            gutterBottom
                            sx={{ fontWeight: 600, fontSize: "16px" }}
                            color="text.secondary"
                          >
                            {Context.profile.TypeOfSupport}
                          </Typography>
                        </div>
                      </div>

                      {Context.profile.TypeOfSupport === "Others" ? (
                        <div className="FamilyDetailsAlign">
                          <div>
                            <Typography
                              className="FamilyNameDetailsAlign"
                              sx={{
                                fontSize: "16px",
                                fontWeight: 600,
                                color: "black",
                                mr: 1,
                              }}
                            >
                              <text>Other support</text>
                              <text>:</text>
                            </Typography>
                          </div>
                          <div>
                            <Typography
                              gutterBottom
                              sx={{ fontWeight: 600, fontSize: "16px" }}
                              color="text.secondary"
                            >
                              {Context.profile.Others}
                            </Typography>
                          </div>
                        </div>
                      ) : null}
                      {family.data.TypeOfSupport === "Training" ||
                      family.data.TypeOfSupport === "Course Fees" ||
                      family.data.TypeOfSupport === "Accommodation" ? (
                        <div className="FamilyDetailsAlign">
                          <div>
                            <Typography
                              className="FamilyNameDetailsAlign"
                              sx={{
                                fontSize: "16px",
                                fontWeight: 600,
                                color: "black",
                                mr: 1,
                              }}
                            >
                              <text> Approximate amount</text>
                              <text>:</text>
                            </Typography>
                          </div>
                          <div>
                            <Typography
                              gutterBottom
                              sx={{ fontWeight: 600, fontSize: "16px" }}
                              color="text.secondary"
                            >
                              {family.data.amount}
                            </Typography>
                          </div>
                        </div>
                      ) : null}
                    </Box>
                  )}

                  {family && (
                    <Box>
                      <Box sx={{ width: "100%" }}>
                        {family.data.UpiNumber ? (
                          <div className="FamilyDetailsAlign">
                            <div>
                              <Typography
                                className="FamilyNameDetailsAlign"
                                sx={{
                                  fontSize: "16px",
                                  fontWeight: 600,
                                  color: "black",
                                  mr: 1,
                                }}
                              >
                                <text>GPay Number</text>
                                <text>:</text>
                              </Typography>
                            </div>
                            <div>
                              <Typography
                                gutterBottom
                                sx={{ fontWeight: 600, fontSize: "16px" }}
                                color="text.secondary"
                              >
                                {family?.data?.UpiNumber}
                              </Typography>
                            </div>
                          </div>
                        ) : (
                          <Box sx={{ width: "100%" }}>
                            {family.data.BankDetails.AccHolderName !== "" ? (
                              <>
                                <div className="FamilyDetailsAlign">
                                  <div>
                                    <Typography
                                      className="FamilyNameDetailsAlign"
                                      sx={{
                                        fontSize: "16px",
                                        fontWeight: 600,
                                        color: "black",
                                        mr: 1,
                                      }}
                                    >
                                      <text>AccHolder Name</text>
                                      <text>:</text>
                                    </Typography>
                                  </div>
                                  <div>
                                    <Typography
                                      gutterBottom
                                      sx={{ fontWeight: 600, fontSize: "16px" }}
                                      color="text.secondary"
                                    >
                                      {family?.data?.BankDetails?.AccHolderName}
                                    </Typography>
                                  </div>
                                </div>

                                <div className="FamilyDetailsAlign">
                                  <div>
                                    <Typography
                                      className="FamilyNameDetailsAlign"
                                      sx={{
                                        fontSize: "16px",
                                        fontWeight: 600,
                                        color: "black",
                                        mr: 1,
                                      }}
                                    >
                                      <text> Account Number</text>
                                      <text>:</text>
                                    </Typography>
                                  </div>
                                  <div>
                                    <Typography
                                      gutterBottom
                                      sx={{ fontWeight: 600, fontSize: "16px" }}
                                      color="text.secondary"
                                    >
                                      {family?.data?.BankDetails?.AccountNumber}
                                    </Typography>
                                  </div>
                                </div>

                                <div className="FamilyDetailsAlign">
                                  <div>
                                    <Typography
                                      className="FamilyNameDetailsAlign"
                                      sx={{
                                        fontSize: "16px",
                                        fontWeight: 600,
                                        color: "black",
                                        mr: 1,
                                      }}
                                    >
                                      <text> IFSC Number</text>
                                      <text>:</text>
                                    </Typography>
                                  </div>
                                  <div>
                                    <Typography
                                      gutterBottom
                                      sx={{ fontWeight: 600, fontSize: "16px" }}
                                      color="text.secondary"
                                    >
                                      {family?.data?.BankDetails?.IfscCode}
                                    </Typography>
                                  </div>
                                </div>
                              </>
                            ) : null}
                          </Box>
                        )}
                      </Box>
                    </Box>
                  )}

                  {family && (
                    <Box>
                      <div className="FamilyDetailsAlign">
                        <div>
                          <Typography
                            className="FamilyNameDetailsAlign"
                            sx={{
                              fontSize: "16px",
                              fontWeight: 600,
                              color: "black",
                              mr: 1,
                            }}
                          >
                            <text> Help Status</text>
                            <text>:</text>
                          </Typography>
                        </div>
                        <div>
                          <Typography
                            gutterBottom
                            sx={{ fontWeight: 600, fontSize: "16px" }}
                            color="text.secondary"
                          >
                            {Context.profile.HelpStatus}
                          </Typography>
                        </div>
                      </div>
                    </Box>
                  )}

                  {family?.data?.HelpStatus === "Yes"
                    ? family && (
                        <Box>
                          <div className="FamilyDetailsAlign">
                            <div>
                              <Typography
                                className="FamilyNameDetailsAlign"
                                sx={{
                                  fontSize: "16px",
                                  fontWeight: 600,
                                  color: "black",
                                  mr: 1,
                                }}
                              >
                                <text> Field Verification</text>
                                <text>:</text>
                              </Typography>
                            </div>
                            <div>
                              <Typography
                                gutterBottom
                                sx={{ fontWeight: 600, fontSize: "16px" }}
                                color="text.secondary"
                              >
                                {family.data.fieldVerification}
                              </Typography>
                            </div>
                          </div>
                        </Box>
                      )
                    : null}

                  

                  {family?.data?.contactPerson !== "" ? null : family?.data
                      ?.finalVerification === "Completed" ? (
                    <>
                      <Box
                        sx={{
                          width: "100%",
                          alignContent: "center",
                          alignItems: "center",
                          mt: 2,
                        }}
                      >
                        <Stack spacing={2}>
                          <Alert
                            className="FamilyAlert"
                            sx={{ mb: 2 }}
                            variant="filled"
                            severity="info"
                          >
                            Final verification completed, we will inform further
                            details.
                          </Alert>
                        </Stack>
                      </Box>
                    </>
                  ) : family?.data?.fieldVerification === "Completed" ? (
                    <Box
                      sx={{
                        width: "100%",
                        alignContent: "center",
                        alignItems: "center",
                        mt: 2,
                      }}
                    >
                      <Stack spacing={2}>
                        <Alert
                          className="FamilyAlert"
                          sx={{ m: 2 }}
                          variant="filled"
                          severity="info"
                        >
                          Field verification completed admin will verify the
                          final verification, we will inform further details.
                        </Alert>
                      </Stack>
                    </Box>
                  ) : family?.data?.VerifierName ? (
                    <Box
                      sx={{
                        width: "100%",
                        alignContent: "center",
                        alignItems: "center",
                        mb: 2,
                      }}
                    >
                      <Stack spacing={2}>
                        <Alert
                          className="FamilyAlert"
                          sx={{ mt: 2 }}
                          variant="filled"
                          severity="info"
                        >
                          Verifier has assigned to your profile for field
                          verification we will contact for further details.
                        </Alert>
                      </Stack>
                    </Box>
                  ) : null}

                  {family?.data?.HelpStatus === "No" ? (
                    <Box
                      sx={{
                        width: "100%",
                        alignContent: "center",
                        alignItems: "center",
                        mt: 2,
                      }}
                    >
                      <Stack spacing={2}>
                        <Alert
                          className="FamilyAlert"
                          variant="filled"
                          severity="info"
                        >
                          Your not eligible for support !
                        </Alert>
                      </Stack>
                    </Box>
                  ) : null}
                </>
              )}

{family?.data?.TypeOfSupport !== "Mentorship" &&
                  family?.data?.HelpStatus === "Yes" ? (
                    !family?.data?.UpiNumber &&
                    !family?.data?.BankDetails.AccountNumber ? (
                      <Box
                        sx={{
                          width: "100%",
                          alignContent: "center",
                          alignItems: "center",
                          mt: 2,
                        }}
                      >
                        <Stack spacing={2}>
                          <Alert
                            className="FamilyAlert"
                            sx={{ mt: -1 }}
                            variant="filled"
                            severity="warning"
                          >
                            Please fill the bank details.
                          </Alert>
                        </Stack>
                      </Box>
                    ) : null
                  ) : null}

              {family?.data?.contactPerson !== "" &&
              family?.data?.HelpStatus === "Yes" ? (
                <Box
                  sx={{
                    width: "100%",
                    alignContent: "center",
                    alignItems: "center",
                    mt: 2,
                  }}
                >
                  <Stack spacing={2}>
                    <Alert
                      className="FamilyAlert"
                      variant="filled"
                      severity="info"
                    >
                      <div>
                        {" "}
                        As per your need support this person "
                        {family?.data?.contactPerson}" has been assigned for
                        your support. For more
                      </div>
                      <div
                        className="MoreDetails"
                        onClick={() => {
                          navigate("/main/needSupport", {
                            state: {
                              email_id: Location.state,
                              action: "edit",
                              TypeOfSupport: Context.profile.TypeOfSupport,
                              Others: Context.profile.Others,
                              amount: Context.profile.Others,
                              HelpStatus: Context.profile.HelpStatus,
                              Name: Context.profile.full_name,
                              mla_constituency: Context.profile.mlaConstituency,
                              votingDistrict: Context.profile.votingDistrict,
                              industry: Context.profile.industry,
                              mpConstituency: Context.profile.mpConstituency,
                              mobile_number: Context.profile.mobile_number,
                            },
                          });
                        }}
                      >
                        {" "}
                        details{" "}
                      </div>
                    </Alert>
                  </Stack>
                </Box>
              ) : null}
            </Grid>
          </div>
        );
      }}
    </Context.Consumer>
  );
}

export default FamilyDetail;
