import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import useAxios from "../../../api/useAxios";
import {
  Grid,
  IconButton,
  Tooltip,
  Table,
  TableRow,
  TableBody,
  TableHead,
  Typography,
  TableContainer,
  Paper,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  DialogTitle,
  DialogContentText,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router";
import { ROUTINGDATA } from "../../ROUTINGDATA";
import WestIcon from "@mui/icons-material/West";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import moment from "moment";
import "./PostJob.css";
import AuditingData from "./AuditingData";
function Auditing() {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#108fb2",
      color: theme.palette.common.white,
      fontSize: 17,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 15,
      padding: 5,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const navigate = useNavigate();
  const axiosData = useAxios();
  const [viewAuditing, setViewAuditing] = useState(false);
  let userDetails = JSON.parse(localStorage.getItem("user"));
  const [loading, setLoading] = useState(true);
  const [data, setdata] = useState();
  const [filterdata, setfilter] = useState();
  let location = useLocation();
  const [Auditing, setAuditing] = useState();
  const [nodata, setnodata] = useState(false);
  const getFilterValue = (datasset) => {
    const temp = datasset.filter((element) => element.status === "O");
    if (temp.length === 0) {
      setnodata(true);
    } else {
      setfilter([...temp]);
      console.log(temp);
    }
  };
  const getAppliedSeekers = async () => {
    try {
      const response = await axiosData.get(
        `jobapply/getbyjobid/${location.state.id}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.data === "") {
        setLoading(false);
        setnodata(true);
      } else {
        setdata(response.data);
        const datasset = response.data;

        getFilterValue(datasset);
        setLoading(false);
      }
      // console.log(datas);
    } catch (err) {
      console.log(err);
    }
  };
  const diff = (date, datas) => {
    var a = moment(date, "DD-MM-YYYY"); //first date
    var b = moment(datas, "DD-MM-YYYY"); // second date
    var duration = moment.duration(b.diff(a)); // b-a
    var days = duration.asDays();
    console.log(days);
    return days;
  };
  useEffect(() => {
    if (userDetails === null || userDetails === undefined) {
      navigate(
        `${ROUTINGDATA.LAND}${ROUTINGDATA.MAIN}${ROUTINGDATA.PageNotFound}`
      );
    } else {
      getAppliedSeekers();
    }
  }, []);
  if (loading === false) {
    return (
      <div className="main-div">
        <div className="arrow-link">
          <div
            style={{
              display: "flex",
              width: "100%",
              gap: "20%",
              marginTop: "12px",
              marginBottom: "12px",
            }}
          >
            <Tooltip
              arrow
              onClick={() => {
                navigate(
                  ROUTINGDATA.LAND + ROUTINGDATA.MAIN + ROUTINGDATA.POSTEDJOB,
                  {
                    state: userDetails.userId,
                  }
                );
              }}
              className="GoBackTooltip"
              title="Go Back"
            >
              <IconButton
                className="GoBackTooltip  arrow"
                sx={{
                  backgroundColor: "#339fcd",
                  justifyContent: "center",
                  height: "40px",
                  marginTop: "15px",
                  marginLeft: "10px",
                  marginBottom: "10px",
                }}
              >
                <WestIcon sx={{ color: "#fff" }} />
              </IconButton>
            </Tooltip>

            <div>
              <Typography id="postHeading">
                Auditing for {location.state.title}
              </Typography>
            </div>
          </div>
        </div>

        <div>
          <div style={{ padding: "10px" }}>
            <Card className="table">
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="center">
                        Seeker Name
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Interviewer Name
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Interview Date
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Offered Date
                      </StyledTableCell>
                      <StyledTableCell align="center">Auditing</StyledTableCell>
                      <StyledTableCell align="center">
                        Total Days
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filterdata &&
                      filterdata.map((element, index) => {
                        return (
                          <StyledTableRow>
                            <StyledTableCell align="center">
                              {element.seeker_name}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {element.interviewer_name}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {element.interview_schedule_date}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {element.offered_date}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Tooltip title="View Full Auditing">
                                <IconButton
                                  sx={{ color: "#ed1e70" }}
                                  onClick={() => {
                                    setAuditing(element);
                                    setViewAuditing(true);
                                  }}
                                >
                                  <RemoveRedEyeIcon />
                                </IconButton>
                              </Tooltip>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {diff(element.apply_date, element.offered_date)}{" "}
                              Days
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          </div>
        </div>
        <Dialog
          open={viewAuditing}
          aria-labelledby="responsive-dialog-title"
          sx={{ "& .MuiPaper-root": { width: "600px", height: "600px" } }}
        >
          <DialogTitle
            id="responsive-dialog-title"
            bgcolor="rgb(16 143 178)"
            sx={{ color: "white", fontWeight: 600 }}
          >
            AUDITING DETAILS
          </DialogTitle>
          <DialogContent sx={{ padding: 0 }}>
            <DialogContentText>
              <div>
                <AuditingData Auditing={Auditing} diff={diff} />
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              autoFocus
              onClick={() => {
                setViewAuditing(false);
                // setAuditing();
              }}
            >
              ok
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={nodata}
          aria-labelledby="responsive-dialog-title"
          sx={{ "& .MuiPaper-root": { width: "500px" } }}
        >
          <DialogTitle
            id="responsive-dialog-title"
            bgcolor="rgb(16 143 178)"
            sx={{ color: "white", fontWeight: 600 }}
          >
            AUDITING
          </DialogTitle>
          <DialogContent sx={{ marginTop: 3 }}>
            <DialogContentText>No record for this job post</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={() => {
                setnodata(false);
                navigate(
                  ROUTINGDATA.LAND + ROUTINGDATA.MAIN + ROUTINGDATA.POSTEDJOB,
                  {
                    state: userDetails.userId,
                  }
                );
              }}
            >
              ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
export default Auditing;
