import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  Button,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Box,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { ROUTINGDATA } from "../../../ROUTINGDATA";
import { useEffect } from "react";
function LogOutDialog({ openLog, setOpenLog, verifier, act }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const [lang, setlang] = useState("");
  useEffect(() => {
    const lan = sessionStorage.getItem("lang");
    setlang(lan);
  }, []);

  const handleLogOut = () => {
    navigate(ROUTINGDATA.LAND);
    localStorage.removeItem(`${verifier}`);
    sessionStorage.clear();
    sessionStorage.setItem("lang", lang);
  };
  return (
    <>
      <Dialog
        open={openLog}
        onClose={() => setOpenLog(false)}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "#339fcd",
            borderRadius: "20px",
            width: "30%",
            [theme.breakpoints.down("sm")]: { width: "100%" },
          },
        }}
      >
        <Box>
          <DialogTitle sx={{ fontFamily: "Poppins-SemiBold", color: "white" }}>
            Log Out!
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              sx={{ fontFamily: "Poppins-Medium", color: "white" }}
            >
              Are You Sure to Logout?
            </DialogContentText>
          </DialogContent>
          <DialogActions
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="outlined"
              sx={{
                textTransform: "capitalize",
                borderRadius: "20px",
                color: "white",
                borderColor: "white",
                fontFamily: "Poppins-Medium",
                "&:hover": {
                  textTransform: "capitalize",
                  borderColor: "#339fcd",
                  color: "#339fcd",
                  borderRadius: "20px",
                  fontFamily: "Poppins-Medium",
                  backgroundColor: "#ffffff",
                },
              }}
              onClick={() => setOpenLog(false)}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              sx={{
                textTransform: "capitalize",
                borderRadius: "20px",
                backgroundColor: "#ed1067",
                borderColor: "#ed1067",
                fontFamily: "Poppins-Medium",
                color: "white",
                "&:hover": {
                  textTransform: "capitalize",
                  borderColor: "#ed1067",
                  color: "#ed1067",
                  borderRadius: "20px",
                  fontFamily: "Poppins-Medium",
                },
              }}
              onClick={() => handleLogOut()}
            >
              Log Out
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
}

export default LogOutDialog;
