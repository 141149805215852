import { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Box,
  Button,
  Divider,
  TextField,
  FormControl,
  FormLabel,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import ContactSupportDialog from "./ContactSupportDialog";
import * as Yup from "yup";
import useAxios from "../../../../api/useAxios";
import { useLocation } from "react-router-dom";
import { ROUTINGDATA } from "../../../ROUTINGDATA";
function EnterContactSupportDialog({
  setOpenFormikContactDialog,
  openFormikContactDialog,
  setContactSeekDetails,
  contactSeekDetails,
  getFunction,
  mlaMail,
  setOpenSupportSnack,
  seekName,
  seekMail,
  seekMobile,
}) {
  const axiosData = useAxios();

  const [openContactDialog, setOpenContactDialog] = useState(false);
  const location = useLocation();
  const currentUrl = location.pathname;
  const verifierVolunteer = JSON.parse(localStorage.getItem("volunteer"));

  const volunteerValue = `Volunteer_${verifierVolunteer}`;
  const seekerName = contactSeekDetails.name;

  const seekerEmail = contactSeekDetails.email_id;
  const MP_MLA_shortRoute =
    ROUTINGDATA.LAND +
    ROUTINGDATA.MPConstituency +
    "/" +
    ROUTINGDATA.MP_MLAShortlisted;
  const MPshortRoute =
    ROUTINGDATA.LAND +
    ROUTINGDATA.MPConstituency +
    "/" +
    ROUTINGDATA.MP_SHORTLISTED;
  const MLAshortRoute =
    ROUTINGDATA.LAND +
    ROUTINGDATA.MLAconstituency +
    "/" +
    ROUTINGDATA.MLAShortlisted;
  const VolunteerShortRoute =
    ROUTINGDATA.LAND +
    ROUTINGDATA.volunteerPanel +
    "/" +
    ROUTINGDATA.volunteerShortlisted;

  const supportValueCondition =
    currentUrl == MP_MLA_shortRoute || currentUrl == MPshortRoute
      ? "MP"
      : currentUrl == MLAshortRoute
      ? "MLA"
      : currentUrl == VolunteerShortRoute
      ? volunteerValue
      : null;

  const initialValues = {
    personName: "",
    mobile: Number,
    email_id: "",
    notes: "",
  };
  const validationSchema = Yup.object().shape({
    personName: Yup.string()
      .required("Name is required")
      .min(3, "Name must be at least 3 characters")
      .max(50, "Name cannot exceed 50 characters"),
    email_id: Yup.string()
      .email("Invalid email")
      .required("Email is required")
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        "Invalid Email"
        // () => {
        //   setfocus(false);
        // }
      ),
    mobile: Yup.string()
      .matches(/^[0-9]+$/, "Invalid mobile number")
      .required("Mobile number is required")
      .min(10, "Mobile number must be at least 10 digits")
      .max(10, "Mobile number cannot exceed 10 digits"),
    // notes: Yup.string().required("Name is required"),
  });
  const handleSubmit = async (values) => {
    try {
      await axiosData.put(`/family/admin/${contactSeekDetails.email_id}`, {
        contactPerson: values.personName,
        contactMobile: values.mobile,
        contactEmail: values.email_id,
        contactNotes: values.notes,
        supported: supportValueCondition,
        email: contactSeekDetails.email_id,
        name: contactSeekDetails.name,
        mobile: contactSeekDetails.mobile,
        contactSupport: "yes",
      });
      // setOpenSupportSnack(true);
      if (currentUrl == MP_MLA_shortRoute) {
        getFunction(mlaMail);
      } else {
        getFunction();
      }
      // setContactSeekDetails({
      //   ...contactSeekDetails,
      //   name: "",
      //   email_id: "",
      // });
      setOpenFormikContactDialog(false);
      setOpenContactDialog(false);
    } catch (err) {}
  };
  return (
    <Dialog
      open={openFormikContactDialog}
      // fullWidth
      sx={{
        "& .MuiPaper-root": {
          // backgroundColor: "#339fcd",
          borderRadius: "10px",
          // height: "60%",
        },
      }}
    >
      <DialogTitle>Contact Details</DialogTitle>
      <DialogContent sx={{ padding: "30px", paddingRight: "40px" }}>
        {" "}
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {(formikProps) => {
            return (
              <form onSubmit={formikProps.handleSubmit}>
                {/* <CardContent> */}
                <Grid container direction={"column"} spacing={2}>
                  <Grid item direction={"column"} md={8}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      <Typography variant="body1">
                        <div
                          className="align-text"
                          style={{ alignItems: "center" }}
                        >
                          <span className="full-details"> Name:</span>
                          <div>
                            {" "}
                            <FormControl>
                              <FormLabel></FormLabel>

                              <TextField
                                variant="outlined"
                                size="small"
                                name="personName"
                                onChange={formikProps.handleChange}
                                value={formikProps.values.personName.replace(
                                  /[^a-z0-9'. ]/gi,
                                  ""
                                )}
                                placeholder="Contact Person"
                                onBlur={formikProps.handleBlur}
                                error={
                                  formikProps.touched.personName &&
                                  Boolean(formikProps.errors.personName)
                                }
                                helperText={
                                  formikProps.touched.personName &&
                                  formikProps.errors.personName
                                }
                              />
                            </FormControl>
                          </div>
                        </div>
                      </Typography>
                      <Typography variant="body1">
                        <div
                          className="align-text"
                          style={{ alignItems: "center" }}
                        >
                          <span className="full-details">Email:</span>
                          <div>
                            {" "}
                            <FormControl>
                              <FormLabel></FormLabel>

                              <TextField
                                variant="outlined"
                                size="small"
                                name="email_id"
                                onChange={formikProps.handleChange}
                                value={formikProps.values.email_id.replace(
                                  /[^a-z0-9'.@]/gi,
                                  ""
                                )}
                                placeholder="Email-id"
                                onBlur={formikProps.handleBlur}
                                error={
                                  formikProps.touched.email_id &&
                                  Boolean(formikProps.errors.email_id)
                                }
                                helperText={
                                  formikProps.touched.email_id &&
                                  formikProps.errors.email_id
                                }
                              />
                            </FormControl>
                          </div>
                        </div>
                      </Typography>
                      <Typography variant="body1">
                        <div
                          className="align-text"
                          style={{ alignItems: "center" }}
                        >
                          <span className="full-details"> Mobile :</span>
                          <div>
                            {" "}
                            <FormControl>
                              <FormLabel></FormLabel>

                              <TextField
                                variant="outlined"
                                size="small"
                                name="mobile"
                                type="number"
                                onChange={formikProps.handleChange}
                                value={formikProps.values.mobile}
                                placeholder="Mobile Number"
                                onBlur={formikProps.handleBlur}
                                error={
                                  formikProps.touched.mobile &&
                                  Boolean(formikProps.errors.mobile)
                                }
                                helperText={
                                  formikProps.touched.mobile &&
                                  formikProps.errors.mobile
                                }
                              />
                            </FormControl>
                          </div>
                        </div>
                      </Typography>
                      <Typography variant="body1">
                        <div
                          className="align-text"
                          style={{ alignItems: "center" }}
                        >
                          <span className="full-details"> Remarks:</span>
                          <div>
                            {" "}
                            <FormControl>
                              <FormLabel></FormLabel>

                              <TextField
                                variant="outlined"
                                size="small"
                                name="notes"
                                onChange={formikProps.handleChange}
                                value={formikProps.values.notes}
                                placeholder="Notes"
                                // onBlur={formikProps.handleBlur}
                                error={
                                  formikProps.touched.notes &&
                                  Boolean(formikProps.errors.notes)
                                }
                                helperText={
                                  formikProps.touched.notes &&
                                  formikProps.errors.notes
                                }
                              />
                            </FormControl>
                          </div>
                        </div>
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item direction={"row"} md={4}>
                    <Box display={"flex"} justifyContent={"space-between"}>
                      <Button
                        variant="outlined"
                        sx={{ textTransform: "capitalize" }}
                        onClick={() => {
                          formikProps.handleReset();
                          setOpenFormikContactDialog(false);
                          setContactSeekDetails({
                            ...contactSeekDetails,
                            name: "",
                            email_id: "",
                          });
                        }}
                      >
                        Cancel
                      </Button>

                      <Button
                        variant="contained"
                        sx={{ textTransform: "capitalize" }}
                        disabled={
                          formikProps.isValid && formikProps.dirty
                            ? false
                            : true
                        }
                        // type="submit"
                        onClick={() => {
                          setOpenContactDialog(true);
                          setContactSeekDetails({
                            ...contactSeekDetails,
                            name: seekerName,
                            email_id: seekerEmail,
                            contactName: formikProps.values.personName,
                          });
                        }}
                      >
                        submit
                      </Button>
                    </Box>
                  </Grid>
                </Grid>

                <ContactSupportDialog
                  formikProps={formikProps}
                  openContactDialog={openContactDialog}
                  setOpenContactDialog={setOpenContactDialog}
                  name={contactSeekDetails.name}
                  contactPerson={formikProps.values.personName}
                  setOpenSupportSnack={setOpenSupportSnack}
                />
              </form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}

export default EnterContactSupportDialog;
