import React, { useState, useEffect } from "react";
import { MenuItem, Skeleton, TableSortLabel } from "@mui/material";
import Select from "@mui/material/Select";
import { useFormik } from "formik";
import * as Yup from "yup";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { utils, writeFile } from "xlsx";
import moment from "moment";
import jsPDF from "jspdf";
import "jspdf-autotable";
import xls from "../../../img/admin-panel/icons8-xls-48 (1).png";
import pdf from "../../../img/admin-panel/icons8-pdf-48.png";
import { RefreshSharp as RefreshSharpIcon } from "@mui/icons-material/";

import {
  Table,
  TableContainer,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  Divider,
  Card,
  IconButton,
  Tooltip,
  TablePagination,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  DialogContentText,
  CircularProgress,
  Paper,
  Chip,
  Button,
  Fab,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import useAxios from "../../../api/useAxios";
import "./userData.css";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import StatCardsUser from "./StatCards/StatCardsUser";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { ReactComponent as Warning } from "../../../img/admin-panel/warning-circle-svgrepo-com.svg";
import SkeletonMlaCard from "./StatCards/SkeletonMlaCard";
function UserData() {
  const [valid, setvalid] = useState(false);
  const [getApi, setGetApi] = useState([]);
  const [useApi, setUseApi] = useState([]);
  const [apiDatas, setApiDatas] = useState([]);
  const [getEmail, setEmail] = useState([]);
  const [page, setPage] = useState(0);
  const [emailExist, setEmailExist] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [openAdd, setOpenAdd] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [getOne, setGetOne] = useState({});
  const [getOneLoading, setGetOneLoading] = useState(true);
  const [value, setValue] = useState("");
  let [emailError, setEmailError] = useState("");
  const [getPutId, setGetPutId] = useState("");
  const [getEmailOne, setGetEmail] = useState("");
  const [getPass, setGetPass] = useState("");
  const [getUserType, setUserType] = useState("");
  const [approveOpen, setApproveOpen] = useState(false);
  const [sucfulMsg, setSucfulMsg] = useState(false);
  const [open, setOpen] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [snackOption, setSnackOption] = useState("");
  const [declineOpen, setDeclineOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchicon, setsearchicon] = useState(false);
  const [searchdata, setsearchdata] = useState("");
  const [mobileNumberError, setMobileNumberError] = useState("");
  const [nameError, setNameError] = useState("");
  const [blurCapture, setBlurCapture] = useState({
    email_id: false,
    mobile_number: false,
  });
  const [getPassword, setGetPassword] = useState("");
  const [verifierFull_nameValues, setVerifierFull_nameValues] = useState("");
  const [verifierMobileValues, setVerifierMobileValues] = useState(Number);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("email");
  const [sortedData, setSortedData] = useState([]);
  const userRadio = sessionStorage.getItem(`adminUserRadio`, null);
  const role = userRadio ? userRadio : "allusers";
  let adminDetails_CEO = JSON.parse(localStorage.getItem("admin_dev"));


  const adminUserSearch = sessionStorage.getItem("admin_user_Search");
  let response = "";
  const exceptThisSymbols = ["e", "E", "+", "-", ".", "ArrowUp", "ArrowDown"];
  const handleClickAdd = () => {
    setEmailError("");
    setOpenAdd(true);
  };
  const handleCloseAdd = () => {
    setOpenAdd(false);
  };
  const handleClickView = () => {
    setOpenView(true);
  };
  const handleCloseView = () => {
    setOpenView(false);
    setTimeout(() => {
      // setGetOne({});
      setGetOneLoading(true);
    }, 1000);
  };
  const handleClickDelete = () => {
    setOpenDelete(true);
  };
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };
  const [filterName, setFilterName] = useState({
    date: "today",
    status: "all",
    isLoading: false,
  });
  const axiosData = useAxios();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    const sortedData = useApi.sort((a, b) => {
      const valueA = a[property];
      const valueB = b[property];

      if (valueA === valueB) {
        return -1;
      }

      if (valueA < valueB) {
        return isAsc ? -1 : 1;
      }
      if (valueA > valueB) {
        return isAsc ? 1 : -1;
      }
      return 0;
    });
    setSortedData(sortedData);
  };
  // <!----GET--->

  const fetchApi = async () => {
    try {
      const useAxiosData = await axiosData.get("users");
      const { data } = useAxiosData;

      let bool = false;
      if (adminUserSearch !== null) {
        bool = adminUserSearch.length > 0;
      }
      data.reverse();

      if (true === bool) {
        searchdatas(adminUserSearch, data);
        setsearchicon(true);
        setGetApi(data);
      } else {
        setGetApi(data);

        setApiDatas(data);
        getFilterValue(role, data);
      }
      setIsLoading(true);
    } catch (err) {
      console.log("Error", err);
    }
  };
  const handleClickRefresh = () => {
    setsearchdata("");
    sessionStorage.setItem("admin_user_Search", "");
    sessionStorage.setItem(`adminUserRadio`, "allusers");
    setUseApi(apiDatas);
    setTotalRows(apiDatas.length);
    setsearchicon(false);
  };

  const validate = async (values) => {
    const errors = {};
    setEmailError("");

    if (!values.email_id) {
      setEmailError("Email id is required");
      errors.email_id = "Email id is required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email_id)
    ) {
      setEmailError("Invalid email format");
      errors.email_id = "Invalid email address";
    } else if (
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email_id)
    ) {
      try {
        if (response === "") {
          response = await axiosData.get("users");
        }

        response.data.map((element, index) => {
          if (element.email_id === values.email_id) {
            setEmailError("This Email already exits");

            return null;
          }
        });
        if (response.data === "") {
          setEmailError("");
        }
      } catch (err) {
        console.log("Error", err);
      }
    }

    return errors;
  };
  const initialValues = {
    email_id: "",
    usertype: "",
    mobile_number: "",
    full_name: "",
  };
  const validationSchema = Yup.object({
    full_name: Yup.string().max(50, "Must Be 50 characters or less"),
    email_id: Yup.string()
      .email("Invalid Email address")
      .required("Email Id must be required")
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        "Invalid Email Address"
      )
      .test(
        "email-exists",
        "Email already exists in the database",
        async function (value) {
          const emailExists = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
            value
          );
          if (emailExists) {
            try {
              const response = await axiosData.post(`users/email`, {
                email_id: value,
              });

              if (response.status === 200) {
                return this.createError({
                  message: "Email already exists in the database",
                  path: "email_id",
                });
              } else if (response.status === 204) {
                return true;
              }
            } catch (error) {
              console.log("Error", error);
            }
          }
          return true;
        }
      ),
    usertype: Yup.string().required("Select UserType"),
    mobile_number: Yup.number()
      .min(1000000000, " Not Valid Mobile Number")
      .max(9999999999, "Not Valid Mobile Number")
      .test(
        "is-valid-mobile-number",
        "Invalid Mobile Number",
        async function (value) {
          const isValidPhoneNumber = /^\d{10}$/i.test(value);
          if (!value) {
            return false;
          } else if (!isValidPhoneNumber) {
            return false;
          } else if (/^\d{9}$/.test(value)) {
            return true;
          } else if (/^\d{10}$/.test(value)) {
            if (isValidPhoneNumber) {
              const controller = new AbortController();
              try {
                const response = await axiosData.get(`users/Mobile/${value}`);
                if (response.status === 200) {
                  return this.createError({
                    message: "Mobile number already exists in the database",
                    path: "mobile_number",
                  });
                } else if (response.status === 204) {
                  return true; // Mobile number is valid and does not exist in the database
                }
              } catch (err) {
                console.log("Error", err);
              }
            }
          } else {
            return true; // For empty value, we don't show any error message
          }
          return true;
        }
      ),
  });
  const formik = useFormik({
    initialValues: initialValues,

    validationSchema: validationSchema,

    onSubmit: async (values, resetForm) => {
      try {
        if (values.usertype === "verifier") {
          const newuser = await axiosData.post(
            "users",
            {
              usertype: values.usertype,
              status: "InActive",
              password: values.mobile_number,
              email_id: values.email_id,
              uniqueId: values.mobile_number,
              mobile: values.mobile_number,
              full_name: values.full_name,
            },
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          if (newuser) {
            const email = values.email_id;
            try {
              axiosData.post(
                `users/createemail/${email}`,
                {
                  usertype: values.usertype,
                  email_id: email,
                  password: verifierMobileValues,
                },
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                }
              );
              handleCloseAdd();
              setSucfulMsg(true);
            } catch (err) {
              console.log("Error", err);
            }
          }
          formik.handleReset("");
          fetchApi();
        } else {
          const newuser = await axiosData.post(
            "users",
            {
              usertype: values.usertype,
              status: "InActive",
              password: 12345678,
              email_id: values.email_id,
            },
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          if (newuser) {
            const email = values.email_id;
            try {
              axiosData.post(
                `users/createemail/${email}`,
                {
                  usertype: values.usertype,
                  email_id: email,
                  password: 12345678,
                },
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                }
              );
              handleCloseAdd();
              setSucfulMsg(true);
            } catch (err) {
              console.log("Error", err);
            }
          }
          formik.handleReset("");
          fetchApi();
        }
      } catch (err) {
        console.log("Error", err);
      }
      resetForm();
      handleCloseAdd();
    },
  });
  const getFilterValue = (value, alldata) => {
    if (value === "allusers") {
      setUseApi([...alldata]);
      setTotalRows(alldata.length);
    } else if (value === "admin") {
      const fill = alldata.filter((d) => d.usertype.includes(value));
      setUseApi(fill);
      setTotalRows(fill.length);
    } else {
      const fill = alldata.filter((d) => d.usertype === value);
      setUseApi(fill);
      setTotalRows(fill.length);
    }
  };
  const closeFunctionCall = () => {
    getFilterValue(role, getApi);
  };

  useEffect(() => {
    fetchApi();
  }, []);

  const updateApprove = async (email_id, pass, usertype) => {
    const mobile_verifier = getOne.uniqueId;
    setFilterName({ ...filterName, isLoading: true });
    if (usertype === "verifier") {
      try {
        await axiosData.put(`users/user/${email_id}`, {
          status: "Active",
          usertype: usertype,
          password: mobile_verifier,
        });
        setGetEmail("");
        setGetPass("");
        setOpen(false);
        setApproveOpen(false);
        setOpenSnack(true);
        setSnackOption("success");
        fetchApi();
        handleCloseView();
      } catch (err) {
        console.log("Error", err);
      }
    } else if (usertype === "seeker") {
      try {
        await axiosData.put(`seeker/email/${email_id}`, {
          status: "A",
        });
        const password = getPassword;
        try {
          await axiosData.put(`users/user/${email_id}`, {
            status: "Active",
            usertype: usertype,
            password: password,
          });
          setGetEmail("");
          setGetPass("");
          setOpen(false);
          setApproveOpen(false);
          setOpenSnack(true);
          setSnackOption("success");
          fetchApi();
          handleCloseView();
        } catch (err) {
          console.log("Error", err);
        }
      } catch (err) {
        console.log("Error", err);
      }
    } else if (usertype === "provider") {
      try {
        await axiosData.put(`employers/email/${email_id}`, { status: "A" });
        const password = getPassword;
        try {
          await axiosData.put(`users/user/${email_id}`, {
            status: "Active",
            usertype: usertype,
            password: password,
          });
          setGetEmail("");
          setGetPass("");
          setOpen(false);
          setApproveOpen(false);
          setOpenSnack(true);
          setSnackOption("success");
          fetchApi();
          handleCloseView();
        } catch (err) {
          console.log("Error", err);
        }
      } catch (err) {
        console.log("Error", err);
      }
    } else if (usertype === "volunteer") {
      try {
        await axiosData.put(`volunteer/email/${email_id}`, { status: "A" });
        const password = getPassword;
        try {
          await axiosData.put(`users/user/${email_id}`, {
            status: "Active",
            usertype: usertype,
            password: password,
          });
          setGetEmail("");
          setGetPass("");
          setOpen(false);
          setApproveOpen(false);
          setOpenSnack(true);
          setSnackOption("success");
          fetchApi();
          handleCloseView();
        } catch (err) {
          console.log("Error", err);
        }
      } catch (err) {
        console.log("Error", err);
      }
    } else if (usertype === "mentor") {
      try {
        await axiosData.put(`mentor/email/${email_id}`, { status: "A" });
        const password = getPassword;
        console.log("password", password);
        try {
          await axiosData.put(`users/user/${email_id}`, {
            status: "Active",
            usertype: usertype,
            password: password,
          });
          setGetEmail("");
          setGetPass("");
          setOpen(false);
          setApproveOpen(false);
          setOpenSnack(true);
          setSnackOption("success");
          fetchApi();
          handleCloseView();
        } catch (err) {
          console.log("Error", err);
        }
      } catch (err) {
        console.log("Error", err);
      }
    } else if (usertype === "serviceProvider") {
      try {
        await axiosData.put(`localserviceprovider/email/${email_id}`, {
          status: "A",
        });
        const password = getPassword;
        try {
          await axiosData.put(`users/user/${email_id}`, {
            status: "Active",
            usertype: usertype,
            password: password,
          });
          setGetEmail("");
          setGetPass("");
          setOpen(false);
          setApproveOpen(false);
          setOpenSnack(true);
          setSnackOption("success");
          fetchApi();
          handleCloseView();
        } catch (err) {
          console.log("Error", err);
        }
      } catch (err) {
        console.log("Error", err);
      }
    } else if (usertype === "investor") {
      try {
        await axiosData.put(`investors/email/${email_id}`, {
          status: "S",
        });
        const password = getPassword;
        try {
          await axiosData.put(`users/user/${email_id}`, {
            status: "Active",
            usertype: usertype,
            password: password,
          });
          setGetEmail("");
          setGetPass("");
          setOpen(false);
          setApproveOpen(false);
          setOpenSnack(true);
          setSnackOption("success");
          fetchApi();
          handleCloseView();
        } catch (err) {
          console.log("Error", err);
        }
      } catch (err) {
        console.log("Error", err);
      }
    } else if (usertype === "partner") {
      try {
        await axiosData.put(`partners/email/${email_id}`, {
          status: "S",
        });
        const password = getPassword;
        try {
          await axiosData.put(`users/user/${email_id}`, {
            status: "Active",
            usertype: usertype,
            password: password,
          });
          setGetEmail("");
          setGetPass("");
          setOpen(false);
          setApproveOpen(false);
          setOpenSnack(true);
          setSnackOption("success");
          fetchApi();
          handleCloseView();
        } catch (err) {
          console.log("Error", err);
        }
      } catch (err) {
        console.log("Error", err);
      }
    } else {
      try {
        await axiosData.put(`users/user/${email_id}`, {
          status: "Active",
          usertype: usertype,
          password: 12345678,
        });
        setGetEmail("");
        setGetPass("");
        setOpen(false);
        setApproveOpen(false);
        setOpenSnack(true);
        setSnackOption("success");
        fetchApi();
        handleCloseView();
      } catch (err) {
        console.log("Error", err);
      }
    }
    setFilterName({ ...filterName, isLoading: false });
  };

  const updateDecline = async (email_id, pass, usertype) => {
    setFilterName({ ...filterName, isLoading: true });
    if (usertype === "seeker") {
      try {
        await axiosData.put(`seeker/email/${email_id}`, {
          status: "D",
          reason: value,
        });
      } catch (err) {
        console.log("Error", err);
      }
    } else if (usertype === "provider") {
      try {
        await axiosData.put(`employers/email/${email_id}`, {
          status: "D",
          reason: value,
        });
      } catch (err) {
        console.log("Error", err);
      }
    } else if (usertype === "volunteer") {
      try {
        await axiosData.put(`volunteer/email/${email_id}`, {
          status: "D",
          reason: value,
        });
      } catch (err) {
        console.log("Error", err);
      }
    } else if (usertype === "mentor") {
      try {
        await axiosData.put(`mentor/email/${email_id}`, {
          status: "D",
          reason: value,
        });
      } catch (err) {
        console.log("Error", err);
      }
    } else if (usertype === "serviceProvider") {
      try {
        await axiosData.put(`localserviceprovider/email/${email_id}`, {
          status: "D",
          reason: value,
        });
      } catch (err) {
        console.log("Error", err);
      }
    } else if (usertype === "investor") {
      try {
        await axiosData.put(`investors/email/${email_id}`, {
          status: "D",
          reason: value,
        });
      } catch (err) {
        console.log("Error", err);
      }
    } else if (usertype === "partner") {
      try {
        await axiosData.put(`partners/email/${email_id}`, {
          status: "D",
          reason: value,
        });
      } catch (err) {
        console.log("Error", err);
      }
    }
    try {
      await axiosData.put(
        `users/user/${email_id}`,
        {
          status: "InActive",
          usertype: usertype,
          reason: value,
          password: pass,
        },

        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setGetEmail("");
      setValue("");
      setDeclineOpen(false);
      setOpen(false);
      setOpenSnack(true);
      setSnackOption("error");
      handleCloseView();
      fetchApi();
    } catch (err) {
      console.log("Error", err);
    }
    setFilterName({ ...filterName, isLoading: false });
  };

  const getOneData = async (email_id, usertype) => {
    try {
      const getOneAxios = await axiosData.get(`users/${email_id}`);
      const { data } = getOneAxios;
      setGetOne(data);
      setGetOneLoading(false);
      if (usertype === "seeker") {
        try {
          const getOneAxios = await axiosData.get(`seekers/email/${email_id}`);

          setGetPassword(getOneAxios.data.password);
        } catch (err) {
          console.log("Error", err);
        }
      } else if (usertype === "provider") {
        try {
          const getOneAxios = await axiosData.get(
            `employers/email/${email_id}`
          );

          setGetPassword(getOneAxios.data.password);
        } catch (err) {
          console.log("Error", err);
        }
      } else if (usertype === "volunteer") {
        try {
          const getOneAxios = await axiosData.get(
            `volunteer/email/${email_id}`
          );

          setGetPassword(getOneAxios.data.password);
        } catch (err) {
          console.log("Error", err);
        }
      } else if (usertype === "mentor") {
        try {
          const getOneAxios = await axiosData.get(
            `mentor/email/${email_id}/users`
          );

          setGetPassword(getOneAxios.data.password);
        } catch (err) {
          console.log("Error", err);
        }
      } else if (usertype === "serviceProvider") {
        try {
          const getOneAxios = await axiosData.get(
            `localserviceprovider/email/${email_id}`
          );

          setGetPassword(getOneAxios.data.password);
        } catch (err) {
          console.log("Error", err);
        }
      } else if (usertype === "investor") {
        try {
          const getOneAxios = await axiosData.get(`investors/${email_id}`);

          setGetPassword(getOneAxios.data.password);
        } catch (err) {
          console.log("Error", err);
        }
      } else if (usertype === "partner") {
        try {
          const getOneAxios = await axiosData.get(`partners/${email_id}`);
          setGetPassword(getOneAxios.data.password);
        } catch (err) {
          console.log("Error", err);
        }
      }
    } catch (err) {
      console.log("Error", err);
    }
  };
  const deleteUser = async (getEmail, getUserType, user_id) => {
    setFilterName({ ...filterName, isLoading: true });
    const usertype = getUserType;
    const email_id = getEmail;
    if (usertype === "verifier") {
      try {
        await axiosData.delete(`verifierdetails/email/${email_id}`);
      } catch (err) {
        console.log("Error", err);
      }
    } else if (usertype === "seeker") {
      try {
        await axiosData.delete(`seekers/email/${email_id}`);
        await axiosData.delete(`family/admin/${email_id}`);
        await axiosData.delete(`jobapply/admin/${email_id}`);
      } catch (err) {
        console.log("Error", err);
      }
    } else if (usertype === "provider") {
      try {
        await axiosData.delete(`jobs/admin/${user_id}`);
        await axiosData.delete(`urgentjobs/admin/${email_id}`);
        await axiosData.delete(`employers/email/${email_id}`);
        await axiosData.delete(`jobs/user/${user_id}`);
      } catch (err) {
        console.log("Error", err);
      }
    } else if (usertype === "volunteer") {
      try {
        await axiosData.delete(`volunteer/email/${email_id}`);
      } catch (err) {
        console.log("Error", err);
      }
    } else if (usertype === "mentor") {
      try {
        await axiosData.delete(`mentor/email/${email_id}`);
      } catch (err) {
        console.log("Error", err);
      }
    } else if (usertype === "serviceProvider") {
      try {
        await axiosData.delete(`localserviceprovider/email/${email_id}`);
      } catch (err) {
        console.log("Error", err);
      }
    } else if (usertype === "investor") {
      try {
        await axiosData.delete(`investors/email/${email_id}`);
      } catch (err) {
        console.log("Error", err);
      }
    } else if (usertype === "partner") {
      try {
        await axiosData.delete(`partners/email/${email_id}`);
      } catch (err) {
        console.log("Error", err);
      }
    }
    try {
      await axiosData.delete(`users/${email_id}`);
    } catch (err) {
      console.log("Error", err);
    }
    fetchApi();

    setFilterName({ ...filterName, isLoading: false });
    setTimeout(() => {
      handleCloseDelete();
    }, 500);
  };
  const searchdatas = (searchdata, getApi) => {
    const datas = getApi.filter((item) => {
      const outputString = searchdata.replace(/\s/g, "");
      const searchtext2 = new RegExp(outputString, "i");
      var valuess = item.email_id.match(searchtext2);
      if (valuess != null) {
        return item;
      }
    });
    getFilterValue(role, datas);
    setPage(0);
  };
  function exportToCSV() {
    const selectedApiForExcel = useApi.map((i) => {
      return {
        "Email ID": i.email_id,
        "User Type": i.usertype,
        "Created Date ": i.lastlogin
          ? moment(i.lastlogin).format("DD/MM/YYYY")
          : i.lastlogin,
        Status: i.status,
      };
    });

    const worksheet = utils.json_to_sheet(selectedApiForExcel);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Data");
    writeFile(
      workbook,
      `${role}-Users-data(${moment().format("DD-MM-YYYY")}).csv`
    );
  }
  const generatePdf = () => {
    const doc = new jsPDF();

    const headers = [["Email ID", "User Type", "Created Date ", "Status"]];

    const data = useApi.map((user) => {
      return [
        user.email_id,
        user.usertype,
        user.lastlogin
          ? moment(user.lastlogin).format("DD/MM/YYYY")
          : user.lastlogin,
        user.status,
      ];
    });

    doc.autoTable({
      head: headers,
      body: data,
      columnStyles: {
        columnWidth: 400,
      },
      headerStyles: { fontSize: 6 },
      bodyStyles: { fontSize: 6, width: 400 },
    });

    doc.save(`${role}-Users-(${moment().format("DD-MM-YY")})`);
  };
  const handleNameChange = (event) => {
    const value = event;

    const isValidName = value;

    if (!isValidName) {
      setNameError("Name must be required");
    } else if (isValidName.length > 50) {
      setNameError("Name must be less than 50 characters");
    } else {
      setNameError("");
    }
  };

  const handleMobileNumberChange = async (event) => {
    const value = event.target.value;
    // setVerifierMobileValues(value);
    formik.setFieldValue("mobile_number", value);
    const isValidPhoneNumber = /^\d{10}$/i.test(value);
    if (!value) {
      setMobileNumberError("Mobile Number must be required");
      setvalid(true);
    } else if (!isValidPhoneNumber) {
      setMobileNumberError("Invalid Number");
      setvalid(true);
    } else if (/^\d{9}$/.test(value)) {
      setvalid(true);
    } else if (/^\d{10}$/.test(value)) {
      if (valid) {
        const controller = new AbortController();
        try {
          const response = await axiosData.get(`users/Mobile/${value}`);
          if (response.status === 200) {
            setMobileNumberError("Already exist in Velaivendum.com");
          } else if (response.status === 204) {
            setMobileNumberError("");
          }

          setvalid(false);

          return () => controller.abort();
        } catch (err) {
          console.log("Error", err);
        }
      }
    } else {
      setMobileNumberError("");
    }
  };
  const handleEmailChange = async (value, validateField) => {
    const emailValidate = validationSchema.fields.email_id.isValidSync(value);
    if (emailValidate) {
      try {
        const response = await axiosData.post(`users/email`, {
          email_id: value,
        });

        if (response.status === 200) {
          // setEmailError("This Email already exits");
          setEmailExist(true);
          formik.setFieldValue("email_id", "");
        } else if (response.status === 204) {
          formik.setFieldValue("email_id", value);
          // formik.setFieldTouched("email_id", false);
          // setBlurCapture({ ...blurCapture, email_id: false });
          // setEmailError("");
        }
      } catch (error) {
        formik.setFieldValue("email_id", value);

        setEmailError("");
      }
    }
  };
  if (isLoading) {
    return (
      <React.Fragment>
        <Divider textAlign="left">
          <span className="seeker-text">Users Data</span>
        </Divider>
        <Box>
          <StatCardsUser getApi={getApi} />
        </Box>

        <Paper sx={{ padding: "10px" }}>
          <Grid container rowSpacing={3}>
            <Grid item md={8} sm={6}>
              <FormControl>
                <FormLabel
                  id="demo-row-radio-buttons-group-label"
                  sx={{ fontFamily: "Poppins-SemiBold" }}
                >
                  User Type
                </FormLabel>
                <RadioGroup
                  row
                  id="radio-btn"
                  value={role}
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  sx={{ marginRight: "1px" }}
                  onChange={(e) => {
                    getFilterValue(e.target.value, getApi);
                    sessionStorage.setItem(`adminUserRadio`, e.target.value);
                    setPage(0);
                    setsearchdata("");
                    sessionStorage.setItem("admin_user_Search", "");
                    setsearchicon(false);
                  }}
                >
                  <FormControlLabel
                    name="usertype"
                    id="allusers"
                    control={<Radio value={"allusers"} />}
                    label="All Users"
                  />
                  <FormControlLabel
                    name="usertype"
                    control={<Radio value={"admin"} />}
                    label="Admin"
                  ></FormControlLabel>
                  <FormControlLabel
                    name="usertype"
                    control={<Radio value={"seeker"} />}
                    label="Seekers"
                  />
                  <FormControlLabel
                    name="usertype"
                    control={<Radio value={"provider"} />}
                    label="Providers"
                  />
                  <FormControlLabel
                    name="usertype"
                    control={<Radio value={"mp"} />}
                    label="MP's"
                  />
                  <FormControlLabel
                    name="usertype"
                    control={<Radio value={"mla"} />}
                    label="MLA's"
                  />
                  <FormControlLabel
                    name="usertype"
                    control={<Radio value={"verifier"} />}
                    label="Verifiers"
                  />
                  <FormControlLabel
                    name="usertype"
                    control={<Radio value={"volunteer"} />}
                    label="Volunteers"
                  />
                  <FormControlLabel
                    name="usertype"
                    control={<Radio value={"mentor"} />}
                    label="Mentors"
                  />
                  <FormControlLabel
                    name="usertype"
                    control={<Radio value={"serviceProvider"} />}
                    label="Service Providers"
                  />
                  <FormControlLabel
                    name="usertype"
                    control={<Radio value={"investor"} />}
                    label="Investors"
                  />
                  <FormControlLabel
                    name="usertype"
                    control={<Radio value={"partner"} />}
                    label="Partners"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item md={4} sm={6}>
              <Grid container rowSpacing={3}>
                <Grid item md={4} xs={6} sm={4}>
                  <Box display="flex" alignItems="center" height="100%">
                    {searchicon ? (
                      <TextField
                        label="Search"
                        variant="outlined"
                        value={searchdata ? searchdata : adminUserSearch}
                        onKeyDown={(e) =>
                          [
                            "(",
                            ")",
                            "^",
                            "[",
                            "]",
                            "*",
                            "<",
                            ">",
                            "\\",
                          ].includes(e.key) && e.preventDefault()
                        }
                        onChange={(e) => {
                          setsearchdata(e.currentTarget.value);
                          sessionStorage.setItem(
                            "admin_user_Search",
                            e.currentTarget.value
                          );
                          if (e.currentTarget.value.length === 0) {
                            setUseApi(getApi);
                            setTotalRows(getApi.length);
                            setPage(0);
                          }
                          if (e.currentTarget.value.length >= 3) {
                            searchdatas(e.currentTarget.value, getApi);
                          }
                        }}
                      />
                    ) : (
                      <>
                        {adminDetails_CEO?.emailId ===
                        process.env
                          .REACT_APP_EMAILID_FOR_ADMIN_DEVELOPER ? null : (
                          <Tooltip title="Export As Excel" placement="bottom">
                            <IconButton
                              disabled={
                                adminDetails_CEO?.emailId ===
                                process.env
                                  .REACT_APP_EMAILID_FOR_ADMIN_DEVELOPER
                                  ? true
                                  : false
                              }
                              size="small"
                              onClick={exportToCSV}
                            >
                              <img src={xls} alt="" />
                            </IconButton>
                          </Tooltip>
                        )}
                        {adminDetails_CEO?.emailId ===
                        process.env
                          .REACT_APP_EMAILID_FOR_ADMIN_DEVELOPER ? null : (
                          <Tooltip
                            title="Export As PDF"
                            placement="bottom"
                            onClick={generatePdf}
                          >
                            <IconButton size="small">
                              <img src={pdf} alt="" />
                            </IconButton>
                          </Tooltip>
                        )}
                      </>
                    )}
                  </Box>
                </Grid>
                <Grid item md={2} xs={2} sm={4}>
                  <Box
                    display="flex"
                    alignItems="center"
                    height="100%"
                    justifyContent="center"
                  >
                    {searchicon ? (
                      <Fab
                        size="small"
                        sx={{
                          color: "white",
                          backgroundColor: "#339fcd",
                          boxShadow: 0,
                          "&:hover": { backgroundColor: "#217396" },
                        }}
                        onClick={() => {
                          sessionStorage.setItem("admin_user_Search", "");
                          setsearchdata("");
                          setsearchicon(false);
                          closeFunctionCall();
                        }}
                      >
                        <CloseIcon />
                      </Fab>
                    ) : (
                      <Fab
                        size="small"
                        sx={{
                          color: "white",
                          backgroundColor: "#339fcd",
                          boxShadow: 0,
                          "&:hover": { backgroundColor: "#217396" },
                        }}
                        onClick={() => {
                          setsearchicon(true);
                        }}
                      >
                        <SearchRoundedIcon />
                      </Fab>
                    )}
                  </Box>
                </Grid>
                <Grid
                  item
                  md={2}
                  xs={3}
                  sm={3}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Tooltip title="Refresh" placement="bottom">
                    <Fab
                      size="small"
                      sx={{
                        color: "white",
                        backgroundColor: "#339fcd",
                        boxShadow: 0,
                        "&:hover": { backgroundColor: "#217396" },
                      }}
                      onClick={handleClickRefresh}
                    >
                      <RefreshSharpIcon />
                    </Fab>
                  </Tooltip>
                </Grid>
                <Grid item md={4} xs={12} sm={3}>
                  <Box display="flex" alignItems="center" height="100%">
                    {adminDetails_CEO?.emailId ===
                    process.env.REACT_APP_EMAILID_FOR_ADMIN_DEVELOPER ? null : (
                      <Button
                        sx={{
                          borderRadius: "20px",
                          backgroundColor: " #2294c5",
                          fontSize: "15px",
                          color: "white",
                          fontFamily: "Poppins-SemiBold",
                          "&:hover": { backgroundColor: "#217396" },
                        }}
                        variant="filled"
                        size="large"
                        onClick={() => {
                          handleClickAdd();
                        }}
                      >
                        Add User
                      </Button>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <Card sx={{ padding: "20px" }}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow className="table-row">
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === "email_id"}
                      direction={orderBy === "email_id" ? order : "asc"}
                      onClick={() => handleSort("email_id")}
                    >
                      Email ID
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === "lastlogin"}
                      direction={orderBy === "lastlogin" ? order : "asc"}
                      onClick={() => handleSort("lastlogin")}
                    >
                      Created Date
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>User Type</TableCell>
                  <TableCell align="center">
                    <TableSortLabel
                      active={orderBy === "status"}
                      direction={orderBy === "status" ? order : "asc"}
                      onClick={() => handleSort("status")}
                    >
                      Status
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {useApi.length === 0 ? (
                  <>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell>
                        <h3>No Data Available</h3>
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </>
                ) : (
                  useApi
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item) => (
                      <TableRow key={item._id} hover>
                        <TableCell>{item.email_id}</TableCell>
                        <TableCell>
                          {item.lastlogin
                            ? moment(item.lastlogin).format("DD/MM/YYYY")
                            : item.lastlogin}
                        </TableCell>
                        <TableCell>{item.usertype}</TableCell>
                        <TableCell align="center">
                          <Tooltip
                            title={
                              item.status === "Active" ? "Active" : "Inactive"
                            }
                            placement="right"
                          >
                            <Chip
                              label={
                                item.status === "Active" ? "Active" : "Inactive"
                              }
                              color={
                                item.status === "Active" ? "success" : "error"
                              }
                              variant="contained"
                            />
                          </Tooltip>
                        </TableCell>
                        <TableCell align="center">
                          <Tooltip title="Full Details" placement="top">
                            <IconButton
                              size="small"
                              onClick={() => {
                                getOneData(item.email_id, item.usertype);
                                setGetPutId(item.id);
                                handleClickView();
                              }}
                            >
                              <OpenInNewRoundedIcon />
                            </IconButton>
                          </Tooltip>
                          {adminDetails_CEO?.emailId ===
                          process.env
                            .REACT_APP_EMAILID_FOR_ADMIN_DEVELOPER ? null : (
                            <Tooltip title="Delete User" placement="top">
                              <IconButton
                                disabled={
                                  item.usertype === "admin" ? true : false
                                }
                                size="small"
                                onClick={() => {
                                  getOneData(item.email_id);
                                  setEmail(item.email_id);
                                  setUserType(item.usertype);
                                  handleClickDelete();
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                        </TableCell>
                      </TableRow>
                    ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25]}
            component="div"
            count={totalRows}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
        <Dialog open={openAdd} scroll="paper">
          <form onSubmit={formik.handleSubmit}>
            <DialogTitle>
              <span>Create New User</span>
            </DialogTitle>
            <DialogContent>
              <Grid container columnSpacing={1} direction="column">
                <TextField
                  margin="normal"
                  fullWidth
                  required
                  placeholder="Enter your email id"
                  id="email_id"
                  label="Email Id"
                  name="email_id"
                  onBlur={formik.handleBlur}
                  onBlurCapture={() =>
                    setBlurCapture({
                      ...blurCapture,
                      email_id: true,
                    })
                  }
                  onChange={(e) => {
                    setBlurCapture({
                      ...blurCapture,
                      email_id: false,
                    });
                    const { value } = e.target;
                    const shopValue = value.replace(/[^a-z0-9@.]/gi, "");
                    formik.handleChange(e);
                    formik.setFieldValue("email_id", shopValue);

                    // handleEmailChange(shopValue, formik.validateField);
                  }}
                  value={formik.values.email_id}
                  error={
                    formik.touched.email_id &&
                    Boolean(formik.errors.email_id) &&
                    blurCapture.email_id === true
                  }
                  helperText={
                    formik.touched.email_id &&
                    blurCapture.email_id &&
                    formik.errors.email_id
                  }
                  InputLabelProps={{ style: { color: "black" } }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <MailOutlineIcon />{" "}
                      </InputAdornment>
                    ),
                  }}
                />

                <FormControl sx={{ mt: 2 }} fullWidth required>
                  <InputLabel>UserType</InputLabel>
                  <Select
                    name="usertype"
                    variant="outlined"
                    labelId="demo-simple-select-label"
                    id="usertype"
                    label="User Type"
                    onBlur={formik.handleBlur}
                    value={formik.values.usertype}
                    onChange={(e) => {
                      formik.handleChange(e);

                      if (e.target.value === "verifier") {
                        formik.setFieldValue("full_name", "");
                        formik.setFieldValue("mobile_number", "");
                      } else {
                        formik.setFieldValue("full_name", "null");
                        formik.setFieldValue("mobile_number", "1000000000");
                      }
                    }}
                    error={
                      formik.touched.usertype && Boolean(formik.errors.usertype)
                    }
                    helperText={
                      formik.touched.usertype && formik.errors.usertype
                    }
                  >
                    <MenuItem value="admin">Admin</MenuItem>
                    <MenuItem value="mp">MP</MenuItem>
                    <MenuItem value="mla">MLA</MenuItem>
                    <MenuItem value="verifier">Verifier</MenuItem>
                  </Select>
                </FormControl>

                {formik.values.usertype === "verifier" ? (
                  <>
                    <TextField
                      margin="normal"
                      fullWidth
                      required
                      placeholder="Enter Full Name"
                      id="full_name"
                      label="Verifier Name"
                      name="full_name"
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                      InputLabelProps={{ style: { color: "black" } }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <MailOutlineIcon />{" "}
                          </InputAdornment>
                        ),
                      }}
                    />

                    <TextField
                      margin="normal"
                      fullWidth
                      // required
                      type="number"
                      placeholder="Enter Mobile"
                      id="mobile"
                      label="Verifier Mobile"
                      name="mobile_number"
                      value={formik.values.mobile_number}
                      onKeyDown={(e) =>
                        exceptThisSymbols.includes(e.key) && e.preventDefault()
                      }
                      onBlur={formik.handleBlur}
                      onBlurCapture={() =>
                        setBlurCapture({ ...blurCapture, mobile_number: true })
                      }
                      onChange={(event) => {
                        setBlurCapture({
                          ...blurCapture,
                          mobile_number: false,
                        });
                        if (event.target.value.length < 11) {
                          formik.handleChange(event);
                        }
                      }}
                      helperText={
                        formik.touched.mobile_number &&
                        blurCapture.mobile_number &&
                        formik.errors.mobile_number
                      }
                      error={
                        formik.touched.mobile_number &&
                        Boolean(formik.errors.mobile_number) &&
                        blurCapture.mobile_number
                      }
                      InputLabelProps={{ style: { color: "black" } }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <MailOutlineIcon />{" "}
                          </InputAdornment>
                        ),
                      }}
                    />
                    {/* {mobileNumberError && (
                      <div className="error" style={{ color: "red" }}>
                        {mobileNumberError}
                      </div>
                    )} */}
                  </>
                ) : null}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  formik.handleReset();
                  handleCloseAdd();
                }}
                variant="contained"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                disabled={formik.isValid && formik.dirty ? false : true}
              >
                Submit
              </Button>
            </DialogActions>
          </form>
        </Dialog>

        <Dialog
          open={openView}
          onClose={() => {
            handleCloseView();
            setGetOne({});
          }}
          scroll="paper"
        >
          <DialogTitle sx={{ textAlign: "center" }}>
            <span>User Details</span>
          </DialogTitle>
          <DialogContent dividers="paper" sx={{ backgroundColor: "#f2f2f2" }}>
            <Box sx={{ width: "100%" }}>
              <Grid container md={12} columnSpacing={1} direction="column">
                <Typography variant="h6">
                  <div className="align-text">
                    <span className="full-details">Email ID:</span>
                    <div>
                      {getOneLoading ? (
                        <>
                          {" "}
                          <Skeleton
                            animation="wave"
                            variant="text"
                            width={230}
                            height={40}
                            sx={{ borderRadius: "15px" }}
                          />
                        </>
                      ) : (
                        getOne.email_id
                      )}
                    </div>
                  </div>

                  <div className="align-text">
                    <span className="full-details">User Type:</span>
                    <div>
                      {getOneLoading ? (
                        <>
                          {" "}
                          <Skeleton
                            animation="wave"
                            variant="text"
                            width={230}
                            height={40}
                            sx={{ borderRadius: "15px" }}
                          />
                        </>
                      ) : (
                        getOne.usertype
                      )}
                    </div>
                  </div>
                </Typography>
                <Typography variant="h6">
                  <div className="align-text">
                    <span className="full-details">Status:</span>
                    <div>
                      {" "}
                      {getOneLoading ? (
                        <>
                          {" "}
                          <Skeleton
                            animation="wave"
                            variant="text"
                            width={230}
                            height={40}
                            sx={{ borderRadius: "15px" }}
                          />
                        </>
                      ) : (
                        getOne.status
                      )}
                    </div>
                  </div>
                </Typography>
                <Typography variant="h6">
                  <div className="align-text">
                    <span className="full-details">
                      Password Modified Date:
                    </span>
                    <div className="valuesUser">
                      {getOneLoading ? (
                        <>
                          {" "}
                          <Skeleton
                            animation="wave"
                            variant="text"
                            width={230}
                            height={40}
                            sx={{ borderRadius: "15px" }}
                          />
                        </>
                      ) : getOne.passmodified ? (
                        moment(getOne.passmodified).format("DD/MM/YYYY")
                      ) : (
                        getOne.passmodified
                      )}
                    </div>
                  </div>
                </Typography>{" "}
                <Typography variant="h6">
                  <div className="align-text">
                    <span className="full-details">Created Date:</span>
                    <div className="valuesUser">
                      {" "}
                      {getOneLoading ? (
                        <>
                          {" "}
                          <Skeleton
                            animation="wave"
                            variant="text"
                            width={230}
                            height={40}
                            sx={{ borderRadius: "15px" }}
                          />
                        </>
                      ) : getOne.lastlogin ? (
                        moment(getOne.lastlogin).format("DD/MM/YYYY")
                      ) : (
                        getOne.lastlogin
                      )}
                    </div>
                  </div>
                </Typography>{" "}
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                handleCloseView();
                setGetOne({});
              }}
            >
              Close
            </Button>
            {adminDetails_CEO?.emailId ===
            process.env.REACT_APP_EMAILID_FOR_ADMIN_DEVELOPER ? null : (
              <Button
                className="action-btn"
                onClick={() => {
                  setGetEmail(getOne.email_id);
                  setUserType(getOne.usertype);
                  setGetPass(getOne.password);
                  setApproveOpen(true);
                }}
                variant="contained"
                color="success"
                disabled={
                  getOne.status === "Active" ||
                  getOne.usertype === "admin" ||
                  getOne.usertype === "admin_dev" ||
                  adminDetails_CEO?.emailId ===
                    process.env.REACT_APP_EMAILID_FOR_ADMIN_DEVELOPER
                    ? true
                    : false
                }
              >
                Approve
              </Button>
            )}{" "}
            {adminDetails_CEO?.emailId ===
            process.env.REACT_APP_EMAILID_FOR_ADMIN_DEVELOPER ? null : (
              <Button
                className="action-btn"
                color="error"
                onClick={() => {
                  setGetEmail(getOne.email_id);
                  setUserType(getOne.usertype);
                  setGetPass(getOne.password);
                  setDeclineOpen(true);
                }}
                variant="contained"
                disabled={
                  getOne.status === "InActive" ||
                  getOne.usertype === "admin" ||
                  getOne.usertype === "admin_dev" ||
                  adminDetails_CEO?.emailId ===
                    process.env.REACT_APP_EMAILID_FOR_ADMIN_DEVELOPER
                    ? true
                    : false
                }
              >
                Decline
              </Button>
            )}
          </DialogActions>
        </Dialog>

        <Dialog
          sx={{
            "& .MuiPaper-root": {
              backgroundColor: "#339fcd",
              borderRadius: "20px",
            },
          }}
          open={openDelete}
          fullWidth
          onClose={() => handleCloseDelete(false)}
        >
          <Box>
            <Box>
              <DialogContent>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "space-around",
                    height: "100%",
                  }}
                >
                  <Box sx={{ paddingTop: "10px" }}>
                    <Warning height={100} width={100} />
                  </Box>
                  <Typography
                    variant="h6"
                    sx={{ paddingTop: "10px", fontFamily: "Poppins-SemiBold" }}
                  >
                    {" "}
                    Are you sure want to delete this user?
                  </Typography>
                  <Typography
                    sx={{
                      paddingTop: "10px",
                      fontFamily: "Poppins-Medium",
                      color: "white",
                    }}
                  >
                    You can't undo this action
                  </Typography>

                  <Box
                    sx={{
                      paddingTop: "20px",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                      width: "100%",
                    }}
                  >
                    <Button
                      onClick={() => {
                        handleCloseDelete();
                      }}
                      variant="outlined"
                      sx={{
                        backgroundColor: "#f2f2f2",
                        textTransform: "lowercase",
                        color: "black",
                        borderColor: "white",
                        fontFamily: "Poppins-Medium",
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="outlined"
                      disabled={filterName.isLoading}
                      sx={{
                        textTransform: "lowercase",
                        color: "#ed1067",
                        borderColor: "#ed1067",
                        fontFamily: "Poppins-Medium",
                        "&:hover": {
                          textTransform: "lowercase",
                          color: "white",
                          backgroundColor: "#ed1067",
                          borderColor: "#ed1067",
                          fontFamily: "Poppins-Medium",
                        },
                      }}
                      onClick={() => {
                        deleteUser(getEmail, getUserType, getOne.user_id);
                      }}
                    >
                      Delete{" "}
                      {filterName.isLoading && (
                        <CircularProgress
                          size={24}
                          style={{
                            color: "#ffffff",
                            marginLeft: 12,
                          }}
                        />
                      )}
                    </Button>
                  </Box>
                </Box>
              </DialogContent>
            </Box>
          </Box>
        </Dialog>

        <Dialog
          sx={{
            "& .MuiPaper-root": {
              backgroundColor: "#339fcd",
              borderRadius: "20px",
            },
          }}
          open={approveOpen}
          fullWidth
          onClose={() => setApproveOpen(false)}
        >
          <Box>
            <Box>
              <DialogContent>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "space-around",
                    height: "100%",
                  }}
                >
                  <Box sx={{ paddingTop: "10px" }}>
                    <Warning height={100} width={100} />
                  </Box>
                  <Typography
                    variant="h4"
                    sx={{ paddingTop: "10px", fontFamily: "Poppins-SemiBold" }}
                  >
                    {" "}
                    Are you sure?
                  </Typography>
                  <Typography
                    sx={{
                      paddingTop: "10px",
                      fontFamily: "Poppins-Medium",
                      color: "white",
                    }}
                  >
                    You can't undo this action
                  </Typography>

                  <Box
                    sx={{
                      paddingTop: "20px",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                      width: "100%",
                    }}
                  >
                    <Button
                      onClick={() => {
                        setApproveOpen(false);
                      }}
                      variant="outlined"
                      sx={{
                        backgroundColor: "#f2f2f2",
                        color: "black",
                        borderColor: "white",
                        fontFamily: "Poppins-Medium",
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="outlined"
                      disabled={filterName.isLoading}
                      sx={{
                        color: "#ed1067",
                        borderColor: "#ed1067",
                        fontFamily: "Poppins-Medium",
                        "&:hover": {
                          color: "white",
                          backgroundColor: "#ed1067",
                          borderColor: "#ed1067",
                          fontFamily: "Poppins-Medium",
                        },
                      }}
                      onClick={() => {
                        updateApprove(getEmailOne, getPass, getUserType);
                      }}
                    >
                      Approve{" "}
                      {filterName.isLoading && (
                        <CircularProgress
                          size={24}
                          style={{
                            color: "#ffffff",
                            marginLeft: 12,
                          }}
                        />
                      )}
                    </Button>
                  </Box>
                </Box>
              </DialogContent>
            </Box>
          </Box>
        </Dialog>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={openSnack}
          autoHideDuration={3000}
          onClose={() => setOpenSnack(false)}
        >
          <Alert
            severity={
              snackOption === "success"
                ? "success"
                : snackOption === "error"
                ? "error"
                : ""
            }
            variant="filled"
            onClose={() => setOpenSnack(false)}
          >
            <Typography variant="body1">
              {" "}
              <span>{getOne.usertype}</span>&nbsp;
              {snackOption === "success"
                ? " was Approved !"
                : snackOption === "error"
                ? "was Declined !"
                : ""}
            </Typography>
          </Alert>
        </Snackbar>
        <Dialog
          sx={{
            "& .MuiPaper-root": {
              backgroundColor: "#339fcd",
              borderRadius: "20px",
            },
          }}
          open={declineOpen}
          onClose={() => {
            setDeclineOpen(false);
          }}
        >
          <Box>
            <DialogTitle>
              <span style={{ color: "#ffffff" }}>Reason</span>
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                sx={{ color: "white", fontFamily: "Poppins-Medium" }}
              >
                To reject this approval, please enter Reason here. We will send
                updates occasionally.
              </DialogContentText>
              <TextField
                autoFocus
                name="reason"
                id="name"
                value={value}
                onChange={(e) => {
                  setValue(e.currentTarget.value);
                }}
                label="Reason"
                type="text"
                fullWidth
                variant="standard"
              />
            </DialogContent>
            <DialogActions>
              <Button
                variant="outlined"
                sx={{
                  backgroundColor: "#f2f2f2",
                  color: "black",
                  borderColor: "white",
                  fontFamily: "Poppins-Medium",
                  "&:hover": {
                    color: "#f2f2f2",
                    backgroundColor: "#339fcd",
                    borderColor: "#f2f2f2",
                    fontFamily: "Poppins-Medium",
                  },
                }}
                onClick={() => setDeclineOpen(false)}
              >
                Cancel
              </Button>
              <Button
                variant="outlined"
                sx={{
                  color: "#ed1067",
                  borderColor: "#ed1067",
                  fontFamily: "Poppins-Medium",
                  "&:hover": {
                    color: "white",
                    backgroundColor: "#ed1067",
                    borderColor: "#ed1067",
                    fontFamily: "Poppins-Medium",
                  },
                }}
                onClick={(e) =>
                  updateDecline(getEmailOne, getPass, getUserType)
                }
              >
                Submit{" "}
                {filterName.isLoading && (
                  <CircularProgress
                    size={24}
                    style={{
                      color: "#ffffff",
                      marginLeft: 12,
                    }}
                  />
                )}
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
        <Dialog
          open={sucfulMsg}
          onClose={() => {
            setSucfulMsg(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{ "& .MuiPaper-root": { width: "500px" } }}
        >
          <DialogTitle
            id="alert-dialog-title"
            bgcolor="rgb(16 143 178)"
            sx={{ color: "white", fontWeight: 600 }}
          >
            {"Information"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" sx={{ mt: 3 }}>
              User Created Successfully!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setSucfulMsg(false);
              }}
              autoFocus
            >
              ok
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={emailExist}
          onClose={() => {
            setEmailExist(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{ "& .MuiPaper-root": { width: "500px" } }}
        >
          <DialogTitle
            id="alert-dialog-title"
            bgcolor="rgb(16 143 178)"
            sx={{ color: "white", fontWeight: 600 }}
          >
            {"Information"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" sx={{ mt: 3 }}>
              Email Already Exist
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setEmailExist(false);
              }}
              autoFocus
            >
              ok
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  } else {
    return (
      <>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <Card sx={{ padding: "20px", borderRadius: "20px" }}>
            <Skeleton
              animation="wave"
              variant="text"
              width={230}
              height={40}
              sx={{ borderRadius: "15px" }}
            />
          </Card>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            <Card
              sx={{
                borderRadius: "20px",

                padding: "20px",
              }}
            >
              <SkeletonMlaCard />
            </Card>
          </Box>
          <Card
            sx={{
              borderRadius: "20px",

              padding: "20px",

              height: "500px",
              gap: "20px",
            }}
          >
            <Skeleton variant="rounded" />
            <Skeleton variant="rounded" width="100%" height="300px" />
          </Card>
        </Box>
      </>
    );
  }
}
export default UserData;
