import React, { useState } from "react";
import "./VideoUpload.css";
import VideocamIcon from "@mui/icons-material/Videocam";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { Typography, CircularProgress } from "@mui/material";
import { useLocation } from "react-router-dom";
import Context from "../Context";
import DeleteIcon from "@mui/icons-material/Delete";
import useAxios from "../../../../api/useAxios";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Button from "@mui/material/Button";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function VideoInput({ setput }) {
  const Location = useLocation();
  const inputRef = React.useRef();
  const [isDelete, setIsDelete] = useState(false);
  const [source, setSource] = useState();
  const [size, setSize] = useState();
  const [refresh, setrefresh] = useState(false);
  const axiosData = useAxios();
  const baseUrl = axiosData.defaults.baseURL
  const MAX_FILE_SIZE = 1048576 * 10; //10Mb
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [videoUpload, setVideoUpload] = useState(false);
  const [deleteVideo, setdeleteVideo] = useState(false);
  const handleFileChange = async (e) => {
    const file = e.target.files[0];

    const fileSize = e.target.files[0].size;
    setSize(fileSize);
    const url = URL.createObjectURL(file);
    setSource(url);

    const values = { videoUpload: "" };

    if (e.target.files[0]) {
      values.videoUpload = e.target.files[0];
    }

    if (e.target.files[0].size < MAX_FILE_SIZE) {
      setIsSubmitting(true);
      await axiosData.put(`/seeker/email/${Location.state}`, values, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setIsSubmitting(false);
      setput(true);
      inputRef.current.value = "";

      setrefresh(!refresh);
      setTimeout(() => {}, 5000);
    } else {
      setVideoUpload(true);
      inputRef.current.value = "";
    }
  };

  const handleChoose = (e) => {
    inputRef.current.click();
  };

  return (
    <Context.Consumer>
      {(Context) => {
        return (
          <div>
            <Dialog
              TransitionComponent={Transition}
              open={deleteVideo}
              sx={{ "& .MuiPaper-root": { color: "white", width: "400px" } }}
            >
              <DialogTitle
                bgcolor="rgb(16 143 178)"
                sx={{ backgroundColor: "rgb(16 143 178)", fontWeight: 600 }}
              >
                Delete video
              </DialogTitle>
              <DialogContent sx={{ mt: 3 }}>
                <DialogContentText sx={{ fontWeight: 600 }}>
                  Are you sure, you want to delete video
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  color="error"
                  variant="outlined"
                  onClick={() => {
                    setdeleteVideo(false);
                  }}
                >
                  No
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={isDelete}
                  onClick={async () => {
                    setIsDelete(true);
                    await axiosData.delete(
                      `/seekers/video/${Context.profile.mobile_number}`
                    );
                    setput(true);
                    setIsDelete(false);
                    setdeleteVideo(false);
                  }}
                >
                  Yes{" "}
                  {isDelete && (
                    <CircularProgress
                      size={24}
                      style={{
                        color: "#ffffff",
                        marginLeft: "4px",
                        marginBottom: "-7px",
                      }}
                    />
                  )}
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog TransitionComponent={Transition} open={videoUpload}>
              <DialogTitle
                bgcolor="rgb(16 143 178)"
                sx={{ color: "white", fontWeight: 600 }}
              >
                Video Upload
              </DialogTitle>
              <DialogContent sx={{ mt: 3 }}>
                <DialogContentText sx={{ fontWeight: 600 }}>
                  {"Video size is too large upload < 10MB file."}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => setVideoUpload(false)}
                >
                  Ok
                </Button>
              </DialogActions>
            </Dialog>
            <div className="VideoInput">
              <input
                ref={inputRef}
                className="VideoInput_input"
                type="file"
                onChange={handleFileChange}
                accept=".mov,.mp4"
              />
              {!Context.profile.videoUpload && (
                <>
                  <Tooltip title="Upload video" placement="top" arrow>
                    <IconButton
                        onClick={handleChoose}>
                      <VideocamIcon
                        sx={{ color: "purple" }}
                      />{" "}
                      {isSubmitting && (
                        <CircularProgress
                          size={24}
                          style={{
                            color: "purple",

                            marginLeft: 12,
                          }}
                        />
                      )}
                    </IconButton>
                  </Tooltip>
                </>
              )}
              {Context.profile.videoUpload && (
                <video
                  className="VideoInput_video"
                  width="100%"
                  height="150px"
                  controls
                  src={
                    Context.profile.videoUpload !== undefined
                      ? Context.profile.videoUpload !== ""
                        ? `${baseUrl}/seekers/video/${Context.profile.mobile_number}`
                        : ""
                      : ""
                  }
                />
              )}
              <Typography className="VideoInput_footer">
                {Context.profile.videoUpload ? (
                  <>
                    {Context.profile.videoUpload === "" ? (
                      ""
                    ) : (
                      <Tooltip title="Delete video" arrow>
                        <IconButton
                          onClick={() => {
                            setdeleteVideo(true);
                          }}
                        >
                          <DeleteIcon
                            disabled={!Context.fileUpload}
                            sx={{
                              color: "rgb(243 81 81)",

                              borderRadius: "50%",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    )}
                  </>
                ) : (
                  "No video selected(File must be < 10Mb and .mov,.mp4 only)"
                )}
              </Typography>
            </div>
          </div>
        );
      }}
    </Context.Consumer>
  );
}
