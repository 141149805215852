import React, { useState, useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { LinearProgress, Box } from "@mui/material";

const AllocatePinSnack = ({
  pinSnack,
  setPinSnack,
  getPincode,
  partnerN,
  message,
}) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let interval;
    let startTime = Date.now();
    if (pinSnack) {
      interval = setInterval(() => {
        const elapsedTime = Date.now() - startTime;
        const newProgress = (elapsedTime / 3000) * 100; // 3000ms (3 seconds) duration
        setProgress(newProgress >= 100 ? 100 : newProgress);
      }, 100); // Adjust the interval and progress increment as needed
    } else {
      clearInterval(interval);
      setProgress(0);
    }
    return () => clearInterval(interval);
    // const timer = setInterval(() => {
    //   setProgress((oldProgress) => {
    //     if (oldProgress === 100) {
    //       return 0;
    //     }
    //     const diff = Math.random() * 10;
    //     return Math.min(oldProgress + diff, 100);
    //   });
    // }, 500);

    // return () => {
    //   clearInterval(timer);
    // };
  }, [pinSnack]);

  return (
    <Box sx={{ position: "fixed", zIndex: "1400", bottom: 5, width: "100%" }}>
      <Snackbar
        open={pinSnack}
        autoHideDuration={3500}
        onClose={() => setPinSnack(false)}
        sx={{ position: "relative", width: "100%" }}
        //   className="pi-snack"
      >
        <Alert severity="success" sx={{ width: "50vh" }}>
          Pincode {getPincode.join(",")} allocated for a {message} {partnerN}
          <LinearProgress
            variant="determinate"
            value={progress}
            color="success"
            sx={{
              position: "absolute",
              left: 0,
              right: 0,
              bottom: 0.1,
              width: "50vh",
            }}
            //   className="pi-progress-bars"
          />
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AllocatePinSnack;
