import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import DescriptionIcon from "@mui/icons-material/Description";
import { useLocation, useNavigate } from "react-router-dom";
import useAxios from "../../../api/useAxios";
import Chip from "@mui/material/Chip";
import {
  TextField,
  Tooltip,
  IconButton,
} from "@mui/material";
import "../Landingpage.css";
import WestIcon from "@mui/icons-material/West";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Autocomplete from "@mui/material/Autocomplete";
import moment from "moment";
import Grid from "@mui/system/Unstable_Grid/Grid";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import "./SearchLogalJob.css";
import { ROUTINGDATA } from "../../ROUTINGDATA";
import { useContext } from "react";
import { CurrentStateContext } from "../../../App";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const renderParagraphs = (description) => {
  const parser = new DOMParser();
  const htmlDoc = parser.parseFromString(description, "text/html");
  const paragraphs = htmlDoc.querySelectorAll("p");
  const listItems = htmlDoc.querySelectorAll("li");

  for (let i = 0; i < paragraphs.length; i++) {
    const paragraph = paragraphs[i];
    if (paragraph.innerHTML.trim() === "<br>") {
      paragraph.parentNode.removeChild(paragraph);
    }
  }

  for (let i = 0; i < listItems.length; i++) {
    const listItem = listItems[i];
    if (listItem.innerHTML.trim() === "<br>") {
      listItem.parentNode.removeChild(listItem);
    }
  }

  const firstElement = [...paragraphs, ...listItems].find(
    (element) => element.innerText.trim() !== ""
  );

  if (listItems) {
    listItems.forEach((item) => {
      item.classList.add("selected-li");
    });
  }

  return firstElement ? firstElement.outerHTML : null;
};
const pincodedropdown = [
  { label: "Local Jobs" },
  { label: "Local Services" },
  { label: "Service Providers" },
];

function Searchpage() {
  let location = useLocation();
  const axiosData = useAxios();
  const navigate = useNavigate();
  const [serviceprovider, setserviceprovider] = useState([]);
  const [oneprovider, setOneprovider] = useState([]);
  const [jobDetails, setJobDetails] = useState([]);
  const [jobApplied, setJobApplied] = useState(false);
  const [openMoreDetails, setOpenMoreDetails] = useState(false);
  const [openMoreDetails1, setOpenMoreDetails1] = useState(false);
  const [jobDescription, setJobDescription] = useState([]);
  const [jobDescription1, setJobDescription1] = useState([]);
  const [searchPopUp, setSearchPopUp] = useState(false);
  const [mobilesearch, setmobilesearch] = useState(false);
  const [pincodeValue, setPincodeValue] = useState("");
  const [Areaname, setAreaname] = useState("");
  const [servicetype, setServicetype] = useState("");
  const [servicetypechange, setServicetypechange] = useState("");
  const [searchdata, setsearchdata] = useState("");
  const [error, setError] = useState("");
  const [searchResultDatas, setsearchResultDatas] = useState([]);
  const [openService, setOpenService] = useState(false);
  const [buyservice, setBuyService] = useState(false);
  const [disabled, setdisabled] = useState(true);
  const [invalidUserId, setInvalidUserId] = useState(false);
  const [assingedServiceDialog, setAssingedServiceDialog] = useState(false);
  const [assingedDetails, setAssingedDetails] = useState([]);
  const [getproviderid, setGetProviderId] = useState("");
  const [getprovideridContact, setGetProviderIdContact] = useState("");
  const [getcontact, setGetContact] = useState("");
  const [getservicecontactView, setGetServiceContactView] = useState([]);
  const [getservicecontact, setGetServiceContact] = useState(false);
  const [alreadyAssigned, setalreadyAssigned] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState({
    pincodeValue: null,
    servicetype: null,
    page_number: 1,
  });
  const [loading, setLoading] = useState(true);
  const [noResultPopUp, setNoResultPopUp] = useState(false);
  const [totalData, setTotalData] = useState();
  const [latestjobs, setlatestJobs] = useState();
  const [rejectedPagination, setRejectedPagination] = useState(10);
  const [higlightjobs_1, sethiglightjobs_1] = useState(true);
  const [provideridlocal, setprovideridlocal] = useState("");
  const [sericeIdArray, setsericeIdArray] = useState([]);
  const [searchjobid, setsearchjobid] = useState();
  const { getFunction, getAdminSetting, checked } =
    useContext(CurrentStateContext);

  const jobsExpiry = async () => {
    try {
      const response = await axiosData.get(`localjobs`, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data === "") {
      } else if (response) {
        const openJobs = response.data.filter((data) => data.status === "Open");

        const res = openJobs.map((data) => {
          return data.expiry_date_to;
        });
        const resDates = res.map((getdate) => {
          const getDate2 = getdate;
          const getDate3 = getDate2
            .split("-")
            .map((e) => (e[0] === "0" ? e.slice() : e));
          const getDateFormat =
            getDate3[1] + "/" + getDate3[0] + "/" + getDate3[2];
          const get = moment(new Date()).format("MM/DD/YYYY");
          if (get > getDateFormat) {
            return getDateFormat;
          }
        });
        const gdata = resDates.filter((data) => data !== undefined);

        if (gdata.length === 0) {
          searchResult1(location.state);
        } else {
          const dddeee = gdata.map((data) => {
            const getDateFormat3 = data;
            const getDate4 = getDateFormat3
              .split("/")
              .map((e) => (e[0] === "0" ? e.slice() : e));
            const getDateFormat5 =
              getDate4[1] + "-" + getDate4[0] + "-" + getDate4[2];
            return getDateFormat5;
          });
          const temp = [];

          dddeee.map((datalast) => {
            const datala = openJobs.filter((data) => {
              return data.expiry_date_to === datalast;
            });
            temp.push(...datala);
            return datala;
          });
          const tempId = temp.map((ids) => {
            return ids._id;
          });

          tempId.map(async (date) => {
            try {
              const responseExpiry = await axiosData.put(
                `localjobs/expiryJobs/${date}`,
                { status: "Close", _id: date },
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                }
              );

              if (responseExpiry.status === 201) {
                searchResult1(location.state);
              }
            } catch (err) {
              console.log(err);
            }
          });
          tempId.map(async (date) => {
            const deleteExpiry2 = await axiosData.delete(
              `urgentlocaljobs/deleteJobs/byId/${date}`,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );
            if (deleteExpiry2.status === 201) {
              searchResult1(location.state);
            }
          });
          tempId.map(async (date) => {
            const responseExpiry2 = await axiosData.put(
              `urgentlocaljobs/expiryJobs/${date}`,
              { status: "Close", job_id: date },
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );
            if (responseExpiry2.status === 201) {
              searchResult1(location.state);
            }
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  const searchResult1 = async (result) => {
    setLoading(true);
    selectedOptions.servicetype = result.servicetype;
    setSelectedOptions(selectedOptions);
    if (result.servicetype === "Service Providers") {
      sethiglightjobs_1(false);
      if (result.pincode === undefined || !result.pincode) {
        try {
          const response = await axiosData.get(`localserviceprovider`, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          if (response.data) {
            if (!result.Area) {
              const statusData = response.data.filter(
                (data) => data.status === "A"
              );
              setRejectedPagination(10);
              setTotalData(statusData.length);
              setJobDetails([...statusData.reverse()]);
              setLoading(false);
            } else {
              const outputString = result.Area.replace(/\s/g, "");
              const areadata = response.data.filter(
                (data) =>
                  data.area_street_name.replace(/\s/g, "").toLowerCase() ===
                  outputString.toLowerCase()
              );
              const statusData = areadata.filter((data) => data.status === "A");
              if (statusData.length === 0) {
                setLoading(false);
                setNoResultPopUp(true);
              }
              setRejectedPagination(10);
              setTotalData(statusData.length);
              setJobDetails([...statusData.reverse()]);
              setLoading(false);
            }
          } else if (response.status === 204 || response.data.length === 0) {
            setLoading(false);
            //setNoResultPopUp(true);
            selectedOptions.servicetype = null;
          }
        } catch (err) {
          console.log(err);
        }
      } else {
        try {
          const response = await axiosData.get(
            `localserviceprovider/${result.pincode}/searchpincode`,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          if (response.data) {
            if (!result.Area) {
              const statusData = response.data.filter(
                (data) => data.status === "A"
              );
              setRejectedPagination(10);
              setTotalData(statusData.length);

              setJobDetails([...statusData.reverse()]);

              setLoading(false);
            } else {
              const outputString = result.Area.replace(/\s/g, "");
              const areadata = response.data.filter(
                (data) =>
                  data.area_street_name.replace(/\s/g, "").toLowerCase() ===
                  outputString.toLowerCase()
              );
              const statusData = areadata.filter((data) => data.status === "A");
              if (statusData.length === 0) {
                setLoading(false);
                setNoResultPopUp(true);
              }
              setRejectedPagination(10);
              setTotalData(statusData.length);

              setJobDetails([...statusData.reverse()]);

              setLoading(false);
            }
          }
          if (response.status === 204 || response.data.length === 0) {
            setLoading(false);
            setNoResultPopUp(true);
            selectedOptions.servicetype = null;
          }
        } catch (err) {
          console.log(err);
        }
      }
    } else if (result.servicetype === "Local Services") {
      try {
        const responsepro = await axiosData.get(`localserviceprovider`, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        if (responsepro) {
          setserviceprovider(responsepro.data);
        }
      } catch (err) {}
      if (result.pincode === undefined || !result.pincode) {
        try {
          const response = await axiosData.get(`localservices`, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          if (response.data) {
            if (!result.Area) {
              const statusData = response.data.filter(
                (data) => data.status === "Open"
              );
              if (location.state.type_of_service) {
                sethiglightjobs_1(true);
                setlatestJobs("");
                if (location.state._id) {
                  try {
                    const response1 = await axiosData.get(
                      `/urgentservices/getOne/${location.state._id}`,
                      {
                        headers: {
                          "Content-Type": "multipart/form-data",
                        },
                      }
                    );
                    setlatestJobs(response1.data);

                    const outputString = location.state.type_of_service.replace(
                      /\s/g,
                      ""
                    );
                    const statusData2 = statusData.filter(
                      (data) =>
                        data.type_of_service
                          .replace(/\s/g, "")
                          .toLowerCase() === outputString.toLowerCase()
                    );
                    const statusData3 = await statusData2.filter(
                      (data) => data._id != location.state.job_id
                    );
                    setRejectedPagination(10);
                    setTotalData(statusData2.length);
                    setJobDetails([...statusData3.reverse()]);
                    setLoading(false);
                    setsearchjobid("");
                  } catch (err) {}
                }
              } else {
                sethiglightjobs_1(false);
                setRejectedPagination(10);
                setTotalData(statusData.length);
                setJobDetails([...statusData.reverse()]);
                setLoading(false);
              }
            } else {
              sethiglightjobs_1(false);
              const outputString = result.Area.replace(/\s/g, "");
              const areadata = response.data.filter(
                (data) =>
                  data.area_street_name.replace(/\s/g, "").toLowerCase() ===
                  outputString.toLowerCase()
              );
              const statusData = areadata.filter(
                (data) => data.status === "Open"
              );
              if (statusData.length === 0) {
                setLoading(false);
                setNoResultPopUp(true);
              }
              setRejectedPagination(10);
              setTotalData(statusData.length);

              setJobDetails([...statusData.reverse()]);

              setLoading(false);
            }
          } else if (response.status === 204 || response.data.length === 0) {
            sethiglightjobs_1(false);
            setLoading(false);
            // setNoResultPopUp(true);
            selectedOptions.servicetype = null;
          }
        } catch (err) {
          console.log(err);
        }
      } else {
        sethiglightjobs_1(false);
        try {
          const response = await axiosData.get(
            `localservices/${result.pincode}/searchpincode`,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          if (response.data) {
            if (!result.Area) {
              const statusData = response.data.filter(
                (data) => data.status === "Open"
              );
              setRejectedPagination(10);
              setTotalData(statusData.length);

              setJobDetails([...statusData.reverse()]);

              setLoading(false);
            } else {
              const outputString = result.Area.replace(/\s/g, "");
              const areadata = response.data.filter(
                (data) =>
                  data.area_street_name.replace(/\s/g, "").toLowerCase() ===
                  outputString.toLowerCase()
              );
              const statusData = areadata.filter(
                (data) => data.status === "Open"
              );
              if (statusData.length === 0) {
                setLoading(false);
                setNoResultPopUp(true);
              }
              setRejectedPagination(10);
              setTotalData(statusData.length);

              setJobDetails([...statusData.reverse()]);

              setLoading(false);
            }
          }
          if (response.status === 204 || response.data.length === 0) {
            setLoading(false);
            setNoResultPopUp(true);
            selectedOptions.servicetype = null;
          }
        } catch (err) {
          console.log(err);
        }
      }
    } else if (result.servicetype === "Local Jobs") {
      if (
        result.pincode === undefined ||
        !result.pincode ||
        result.pincode === null ||
        result.pincode === ""
      ) {
        try {
          const response = await axiosData.get(`localjobs`, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          if (response.data) {
            if (!result.Area) {
              const statusData = response.data.filter(
                (data) => data.status === "Open"
              );
              if (location.state.job_title) {
                sethiglightjobs_1(true);
                setlatestJobs("");
                if (location.state._id) {
                  try {
                    const response1 = await axiosData.get(
                      `/urgentlocaljobs/getOne/${location.state._id}`,
                      {
                        headers: {
                          "Content-Type": "multipart/form-data",
                        },
                      }
                    );
                    setlatestJobs(response1.data);
                    const outputString = location.state.job_title.replace(
                      /\s/g,
                      ""
                    );
                    const statusData2 = statusData.filter(
                      (data) =>
                        data.job_title.replace(/\s/g, "").toLowerCase() ===
                        outputString.toLowerCase()
                    );
                    const statusData3 = statusData2.filter(
                      (data) => data._id != location.state.job_id
                    );
                    if (statusData3.length === 0) {
                      setRejectedPagination(10);
                      setTotalData(statusData2.length);
                      setJobDetails([]);
                      setLoading(false);
                      setsearchjobid("");
                    } else {
                      setRejectedPagination(10);
                      setTotalData(statusData2.length);
                      setJobDetails([...statusData3.reverse()]);
                      setLoading(false);
                      setsearchjobid("");
                    }
                  } catch (err) {}
                }
              } else {
                sethiglightjobs_1(false);
                setRejectedPagination(10);
                setTotalData(statusData.length);
                setJobDetails([...statusData.reverse()]);
                setLoading(false);
              }
            } else {
              sethiglightjobs_1(false);
              const outputString = result.Area.replace(/\s/g, "");
              const areadata = response.data.filter(
                (data) =>
                  data.area_street_name.replace(/\s/g, "").toLowerCase() ===
                  outputString.toLowerCase()
              );
              const statusData = areadata.filter(
                (data) => data.status === "Open"
              );
              if (statusData.length === 0) {
                setLoading(false);
                setNoResultPopUp(true);
              }
              setRejectedPagination(10);
              setTotalData(statusData.length);
              setJobDetails([...statusData.reverse()]);
              setLoading(false);
            }
          } else if (response.status === 204 || response.data.length === 0) {
            sethiglightjobs_1(false);
            setLoading(false);
            //setNoResultPopUp(true);
            selectedOptions.servicetype = null;
          }
        } catch (err) {
          console.log(err);
        }
      } else {
        sethiglightjobs_1(false);
        try {
          const response = await axiosData.get(
            `localjobs/${result.pincode}/searchpincode`,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          if (response.data) {
            if (!result.Area) {
              const statusData = response.data.filter(
                (data) => data.status === "Open"
              );
              setRejectedPagination(10);
              setTotalData(statusData.length);
              setJobDetails([...statusData.reverse()]);
              setLoading(false);
            } else {
              const outputString = result.Area.replace(/\s/g, "");
              const areadata = response.data.filter(
                (data) =>
                  data.area_street_name.replace(/\s/g, "").toLowerCase() ===
                  outputString.toLowerCase()
              );
              const statusData = areadata.filter(
                (data) => data.status === "Open"
              );
              if (statusData.length === 0) {
                setLoading(false);
                setNoResultPopUp(true);
              }
              setRejectedPagination(10);
              setTotalData(statusData.length);
              setJobDetails([...statusData.reverse()]);
              setLoading(false);
            }
          }
          if (response.status === 204 || response.data.length === 0) {
            setLoading(false);
            setNoResultPopUp(true);
            selectedOptions.servicetype = null;
          }
        } catch (err) {
          console.log(err);
        }
      }
    }
  };
  const rejectedLoadMore = () => {
    setRejectedPagination(rejectedPagination + 10);
  };

  const rejectedLoadLess = () => {
    setRejectedPagination(rejectedPagination - 10);
  };
  useEffect(() => {
    setGetServiceContactView("")
    setGetProviderIdContact("")
    setprovideridlocal("");
    setsericeIdArray("");
    const provideridlocal1 = localStorage.getItem("provideridforservice");
    setprovideridlocal(provideridlocal1);
    const sericeIdArray1 =
      JSON.parse(localStorage.getItem("serviceidforcontact")) || [];
    setsericeIdArray(sericeIdArray1);
    setGetServiceContactView((current) => [...current, ...sericeIdArray1]);
    setLoading(true);
    setAreaname(location.state.Area);
    setPincodeValue(location.state.pincode);
    setServicetype(location.state.servicetype);
    setsearchjobid(location.state.job_id);
    jobsExpiry();
  }, []);

  const moreDetails = (description) => {
    setOpenMoreDetails(true);
    setJobDescription(
      servicetype === "Service Providers"
        ? jobDetails.find((item) => item.remarks === description)
        : servicetype === "Local Jobs"
        ? jobDetails.find((item) => item.nature_of_job === description)
        : jobDetails.find((item) => item.job_description === description)
    );
  };

  const moreDetails1 = (description1) => {
    setOpenMoreDetails1(true);
    setJobDescription1(description1);
  };
  const searchResult = async (service, areaname, pincode) => {
    sethiglightjobs_1(false);
    setLoading(true);
    if (service === null || service === "" || service === undefined) {
      setPincodeValue(pincode);
      setServicetypechange("");
      setAreaname(areaname);
      setOpenService(true);
      setsearchdata("");
    } else {
      setsearchdata("");
      setServicetype(service);
      setServicetypechange("");
      if (service === undefined) {
        setServicetype(servicetype);
      } else {
        setServicetype(service);
      }
      if (pincode === undefined) {
        setPincodeValue(pincodeValue);
      } else {
        setPincodeValue(pincode);
      }
      setSelectedOptions(selectedOptions);

      if (service === "Service Providers") {
        if (pincode === undefined || !pincode) {
          try {
            const response = await axiosData.get(`localserviceprovider`, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            });
            if (response.data) {
              if (!areaname) {
                const statusData = response.data.filter(
                  (data) => data.status === "A"
                );
                setRejectedPagination(10);
                setTotalData(statusData.length);

                setJobDetails([...statusData.reverse()]);
                setLoading(false);
              } else {
                const outputString = areaname.replace(/\s/g, "");
                const areadata = response.data.filter(
                  (data) =>
                    data.area_street_name.replace(/\s/g, "").toLowerCase() ===
                    outputString.toLowerCase()
                );
                const statusData = areadata.filter(
                  (data) => data.status === "A"
                );
                if (statusData.length === 0) {
                  setLoading(false);
                  setNoResultPopUp(true);
                }
                setRejectedPagination(10);
                setTotalData(statusData.length);

                setJobDetails([...statusData.reverse()]);
                setLoading(false);
              }
            } else if (response.status === 204 || response.data.length === 0) {
              setLoading(false);
              //setNoResultPopUp(true);
              selectedOptions.servicetype = null;
            }
          } catch (err) {
            console.log(err);
          }
        } else {
          try {
            const response = await axiosData.get(
              `localserviceprovider/${pincodeValue}/searchpincode`,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );

            if (response.data) {
              if (!areaname || areaname === undefined) {
                const statusData = response.data.filter(
                  (data) => data.status === "A"
                );
                setRejectedPagination(10);
                setTotalData(statusData.length);

                setJobDetails([...statusData.reverse()]);

                setLoading(false);
              } else {
                const outputString = areaname.replace(/\s/g, "");
                const areadata = response.data.filter(
                  (data) =>
                    data.area_street_name.replace(/\s/g, "").toLowerCase() ===
                    outputString.toLowerCase()
                );
                const statusData = areadata.filter(
                  (data) => data.status === "A"
                );
                if (statusData.length === 0) {
                  setLoading(false);
                  setNoResultPopUp(true);
                }
                setRejectedPagination(10);
                setTotalData(statusData.length);

                setJobDetails([...statusData.reverse()]);

                setLoading(false);
              }
            }

            if (response.status === 204 || response.data.length === 0) {
              setLoading(false);
              setNoResultPopUp(true);
            }
          } catch (err) {
            console.log(err);
          }
        }
      } else if (service === "Local Services") {
        try {
          const responsepro = await axiosData.get(`localserviceprovider`, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          if (responsepro) {
            setserviceprovider(responsepro.data);
          }
        } catch (err) {}
        if (pincode === undefined || !pincode) {
          try {
            const response = await axiosData.get(`localservices`, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            });
            if (response.data) {
              if (!areaname) {
                const statusData = response.data.filter(
                  (data) => data.status === "Open"
                );
                setRejectedPagination(10);
                setTotalData(statusData.length);

                setJobDetails([...statusData.reverse()]);

                setLoading(false);
              } else {
                const outputString = areaname.replace(/\s/g, "");
                const areadata = response.data.filter(
                  (data) =>
                    data.area_street_name.replace(/\s/g, "").toLowerCase() ===
                    outputString.toLowerCase()
                );
                const statusData = areadata.filter(
                  (data) => data.status === "Open"
                );
                if (statusData.length === 0) {
                  setLoading(false);
                  setNoResultPopUp(true);
                }
                setRejectedPagination(10);
                setTotalData(statusData.length);

                setJobDetails([...statusData.reverse()]);

                setLoading(false);
              }
            } else if (response.status === 204 || response.data.length === 0) {
              setLoading(false);
              //setNoResultPopUp(true);
              selectedOptions.servicetype = null;
            }
          } catch (err) {
            console.log(err);
          }
        } else {
          try {
            const response = await axiosData.get(
              `localservices/${pincodeValue}/searchpincode`,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );
            if (response.data) {
              if (!areaname || areaname === undefined) {
                const statusData = response.data.filter(
                  (data) => data.status === "Open"
                );
                setRejectedPagination(10);
                setTotalData(statusData.length);

                setJobDetails([...statusData.reverse()]);

                setLoading(false);
              } else {
                const outputString = areaname.replace(/\s/g, "");
                const areadata = response.data.filter(
                  (data) =>
                    data.area_street_name.replace(/\s/g, "").toLowerCase() ===
                    outputString.toLowerCase()
                );
                const statusData = areadata.filter(
                  (data) => data.status === "Open"
                );
                if (statusData.length === 0) {
                  setLoading(false);
                  setNoResultPopUp(true);
                }
                setRejectedPagination(10);
                setTotalData(statusData.length);

                setJobDetails([...statusData.reverse()]);

                setLoading(false);
              }
            }

            if (response.status === 204 || response.data.length === 0) {
              setLoading(false);
              setNoResultPopUp(true);
              selectedOptions.servicetype = null;
            }
          } catch (err) {
            console.log(err);
          }
        }
      } else if (service === "Local Jobs") {
        if (pincode === undefined || !pincode) {
          try {
            const response = await axiosData.get(`localjobs`, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            });
            if (response.data) {
              if (!areaname) {
                const statusData = response.data.filter(
                  (data) => data.status === "Open"
                );
                setRejectedPagination(10);
                setTotalData(statusData.length);

                setJobDetails([...statusData.reverse()]);

                setLoading(false);
              } else {
                const outputString = areaname.replace(/\s/g, "");
                const areadata = response.data.filter(
                  (data) =>
                    data.area_street_name.replace(/\s/g, "").toLowerCase() ===
                    outputString.toLowerCase()
                );
                const statusData = areadata.filter(
                  (data) => data.status === "Open"
                );
                if (statusData.length === 0) {
                  setLoading(false);
                  setNoResultPopUp(true);
                }
                setRejectedPagination(10);
                setTotalData(statusData.length);
                setJobDetails([...statusData.reverse()]);

                setLoading(false);
              }
            } else if (response.status === 204 || response.data.length === 0) {
              setLoading(false);
              // setNoResultPopUp(true);
              selectedOptions.servicetype = null;
            }
          } catch (err) {
            console.log(err);
          }
        } else {
          try {
            const response = await axiosData.get(
              `localjobs/${pincodeValue}/searchpincode`,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );
            if (response.data) {
              if (!areaname || areaname === undefined) {
                const statusData = response.data.filter(
                  (data) => data.status === "Open"
                );
                setRejectedPagination(10);
                setTotalData(statusData.length);

                setJobDetails([...statusData.reverse()]);

                setLoading(false);
              } else {
                const outputString = areaname.replace(/\s/g, "");
                const areadata = response.data.filter(
                  (data) =>
                    data.area_street_name.replace(/\s/g, "").toLowerCase() ===
                    outputString.toLowerCase()
                );
                const statusData = areadata.filter(
                  (data) => data.status === "Open"
                );
                if (statusData.length === 0) {
                  setLoading(false);
                  setNoResultPopUp(true);
                }
                setRejectedPagination(10);
                setTotalData(statusData.length);

                setJobDetails([...statusData.reverse()]);

                setLoading(false);
              }
            }

            if (response.status === 204 || response.data.length === 0) {
              setLoading(false);
              setNoResultPopUp(true);
              selectedOptions.servicetype = null;
            }
          } catch (err) {
            console.log(err);
          }
        }
      }
    }
  };
  const getproviderpaymentinfo = async (data, provider) => {
    setLoading(true);
    const datas = provider.filter((item) => item.provider_id === data);
    if (datas) {
      setLoading(false);
      if (datas.length === 0) {
        // setGetProviderId("")
        setError("Invalid User ID");
        //setInvalidUserId(true)
      } else {
        setOneprovider(datas[0]);
        setError("");
        setLoading(false);
      }
    }
  };
  const getproviderpayment = async (providerDetails) => {
    if (oneprovider.no_of_services > 0) {
      setBuyService(false);
      setGetServiceContactView((current) => [
        ...current,
        getcontact.id
      ]);
      setGetServiceContact(true);
      try {
        const responsePro = await axiosData.put(
          `localserviceprovider/buy/${providerDetails.provider_id}`,
          {
            no_of_services: providerDetails.no_of_services,
            contact_person_name: providerDetails.contact_person_name,
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (responsePro) {
        }
      } catch (err) {}
    } else {
      navigate(
        ROUTINGDATA.LAND + ROUTINGDATA.MAIN + ROUTINGDATA.SERVICESUBSCRIPTIONS
      );
    }
  };
  const assignToProvider2 = async (
    providerDetails,
    serviceId,
    assignProviderId
  ) => {
    setdisabled(true);
    if (
      assingedDetails.assign_provider_email === "" ||
      assingedDetails.assign_provider_email === undefined ||
      assingedDetails.assign_provider_email === null
    ) {
      try {
        const response = await axiosData.put(
          `/urgentservices/${serviceId}`,
          {
            assign_provider_id: providerDetails.provider_id,
            assign_provider_email: providerDetails.email_id,
            assign_provider_name: providerDetails.contact_person_name,
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (response) {
          try {
            await axiosData.put(
              `localservices/${getcontact.job_id}`,
              {
                assign_provider_id: providerDetails.provider_id,
                assign_provider_email: providerDetails.email_id,
                assign_provider_name: providerDetails.contact_person_name,
              },
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );
            if (response) {
              setAssingedDetails("");
              setAssingedServiceDialog(true);
              searchResult1(location.state);
              setdisabled(false);
            }
          } catch (err) {}
        }
      } catch (err) {}
    } else {
      const providerid = assignProviderId.match(providerDetails.provider_id);
      if (
        providerid === null ||
        providerid === undefined ||
        providerid === ""
      ) {
        const assign_provider_id =
          assingedDetails.assign_provider_id.split(",");
        const assign_provider_email =
          assingedDetails.assign_provider_email.split(",");
        const assign_provider_name =
          assingedDetails.assign_provider_name.split(",");
        const assigned_date = assingedDetails.assigned_date.split(",");
        assign_provider_id.push(providerDetails.provider_id);
        assign_provider_email.push(providerDetails.email_id);
        assign_provider_name.push(providerDetails.contact_person_name);
        try {
          const response = await axiosData.put(
            `/urgentservices/${serviceId}`,
            {
              assign_provider_id: assign_provider_id.toString(),
              assign_provider_email: assign_provider_email.toString(),
              assign_provider_name: assign_provider_name.toString(),
              assigned_date: assigned_date.toString(),
            },
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          if (response) {
            try {
              await axiosData.put(
                `localservices/${getcontact.job_id}`,
                {
                  assign_provider_id: assign_provider_id.toString(),
                  assign_provider_email: assign_provider_email.toString(),
                  assign_provider_name: assign_provider_name.toString(),
                  assigned_date: assigned_date.toString(),
                },
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                }
              );
              if (response) {
                setdisabled(false);
                setAssingedDetails("");
                setAssingedServiceDialog(true);
                searchResult1(location.state);
              }
            } catch (err) {}
          }
        } catch (err) {}
      } else {
        setGetProviderId("");
        setdisabled(false);
        setalreadyAssigned(true);
        searchResult1(location.state);
      }
    }
  };
  const assignToProvider = async (
    providerDetails,
    serviceId,
    assignProviderId
  ) => {
    setdisabled(true);
    if (
      assingedDetails.assign_provider_email === "" ||
      assingedDetails.assign_provider_email === undefined ||
      assingedDetails.assign_provider_email === null
    ) {
      try {
        const response = await axiosData.put(
          `localservices/${serviceId}`,
          {
            assign_provider_id: providerDetails.provider_id,
            assign_provider_email: providerDetails.email_id,
            assign_provider_name: providerDetails.contact_person_name,
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        try {
          await axiosData.put(
            `/urgentservices/urgent/${serviceId}`,
            {
              assign_provider_id: providerDetails.provider_id,
              assign_provider_email: providerDetails.email_id,
              assign_provider_name: providerDetails.contact_person_name,
            },
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
        } catch (err) {}
        if (response) {
          setAssingedServiceDialog(true);
          if (getcontact.getfrom === "searchbytitle") {
            setdisabled(false);
            searchResult(servicetype, Areaname, pincodeValue);
          } else {
            setdisabled(false);
            searchResult1(location.state);
          }
        }
      } catch (err) {}
    } else {
      const providerid = assignProviderId.match(providerDetails.provider_id);
      if (
        providerid === null ||
        providerid === undefined ||
        providerid === ""
      ) {
        const assign_provider_id =
          assingedDetails.assign_provider_id.split(",");
        const assign_provider_email =
          assingedDetails.assign_provider_email.split(",");
        const assign_provider_name =
          assingedDetails.assign_provider_name.split(",");
        const assigned_date = assingedDetails.assigned_date.split(",");
        assign_provider_id.push(providerDetails.provider_id);
        assign_provider_email.push(providerDetails.email_id);
        assign_provider_name.push(providerDetails.contact_person_name);

        try {
          const response = await axiosData.put(
            `localservices/${serviceId}`,
            {
              assign_provider_id: assign_provider_id.toString(),
              assign_provider_email: assign_provider_email.toString(),
              assign_provider_name: assign_provider_name.toString(),
              assigned_date: assigned_date.toString(),
            },
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          try {
            await axiosData.put(
              `/urgentservices/urgent/${serviceId}`,
              {
                assign_provider_id: assign_provider_id.toString(),
                assign_provider_email: assign_provider_email.toString(),
                assign_provider_name: assign_provider_name.toString(),
                assigned_date: assigned_date.toString(),
              },
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );
          } catch (err) {}
          if (response) {
            setdisabled(false);
            setAssingedServiceDialog(true);
            if (getcontact.getfrom === "searchbytitle") {
              if (higlightjobs_1) {
                searchResult1(location.state);
              } else {
                searchResult(servicetype, Areaname, pincodeValue);
              }
              // searchbytitledatas(searchdata, jobDetails);
            } else {
              searchResult1(location.state);
            }
          }
        } catch (err) {}
      } else {
        setdisabled(false);
        setGetProviderId("");
        setalreadyAssigned(true);
        if (getcontact.getfrom === "searchbytitle") {
          if (higlightjobs_1) {
            searchResult1(location.state);
          } else {
            searchResult(servicetype, Areaname, pincodeValue);
          }
          // searchbytitledatas(searchdata, jobDetails);
        } else {
          searchResult1(location.state);
        }
      }
    }
  };
  const handleSearch = () => {
    selectedOptions.page_number = 1;
    setSelectedOptions(selectedOptions);
    searchResult(selectedOptions);
  };
  const searchbytitledatas = (searchdata, getApi) => {
    sethiglightjobs_1(false);
    setLoading(true);
    const datas = getApi.filter((item) => {
      const outputString = searchdata.replace(/\s/g, "");
      const searchtext2 = new RegExp(outputString, "i");
      if (searchtext2) {
        var valuess =
          servicetype === "Service Providers"
            ? item.service_name
                .replace(/\s/g, "")
                .toLowerCase()
                .match(searchtext2)
            : servicetype === "Local Services"
            ? item.type_of_service
                .replace(/\s/g, "")
                .toLowerCase()
                .match(searchtext2)
            : item.job_title
                .replace(/\s/g, "")
                .toLowerCase()
                .match(searchtext2);
      }
      if (valuess != null) {
        return item;
      }
    });
    if (datas) {
      setLoading(false);
      if (datas.length === 0) {
        setRejectedPagination(10);
        setTotalData(getApi.length);
        setNoResultPopUp(true);
      } else {
        setsearchResultDatas([...datas]);
        setRejectedPagination(10);
        setTotalData(datas.length);
      }
    }
  };
  if (loading === false) {
    return (
      <div>
        <div
          style={{
            marginTop: "15px",
            marginBottom: "15px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Typography sx={{ fontSize: "28px", fontWeight: 600 }}>
              {servicetype === "Service Providers"
                ? "Search Service Provider"
                : servicetype === "Local Services"
                ? "Search Services"
                : "Search Jobs"}
            </Typography>
          </div>
          <div>
            <Tooltip
              arrow
              onClick={() => {
                navigate("/");
              }}
              className="GoBackTooltip"
              title="Go Back"
            >
              <IconButton
                className="GoBackTooltip  arrow"
                sx={{ backgroundColor: "#339fcd" }}
              >
                <WestIcon sx={{ color: "#fff" }} />
              </IconButton>
            </Tooltip>
          </div>
        </div>

        <div id="search-part" className="search-part">
          <div className="search-auto" style={{ border: "1px solid #108fb2" }}>
            <TextField
              className="autocom-search-first"
              type="number"
              sx={{
                "& fieldset": { border: "none" },
              }}
              onChange={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 6);
                setPincodeValue(e.target.value);
              }}
              value={pincodeValue}
              id="Enter_location"
              name="Enter_location"
              label="Pincode"
              size="small"
            />
            <TextField
              className="autocom-search-first"
              sx={{
                "& fieldset": { border: "none" },
              }}
              onChange={(e) => {
                setAreaname(
                  e.target.value === "" ||
                    e.target.value === undefined ||
                    e.target.value === null
                    ? ""
                    : e.target.value
                );
              }}
              value={Areaname}
              id="Enter_location"
              name="Enter_location"
              label="Area Name"
              size="small"
            />
            <Autocomplete
              disablePortal
              disableUnderline={false}
              disableClearable
              id="Select_experience"
              name="serviceType"
              options={pincodedropdown}
              onChange={(event, value) => {
                setServicetypechange(value);
              }}
              value={
                servicetypechange
                  ? servicetypechange.label
                    ? servicetypechange.label
                    : servicetypechange
                  : servicetype.label
                  ? servicetype.label
                  : servicetype
              }
              sx={{ width: { xs: 350, md: 250, lg: 320 } }}
              renderInput={(params) => (
                <TextField
                  className="autocom-search-first"
                  sx={{
                    "& fieldset": { border: "none" },
                  }}
                  id="Select_experience"
                  name="serviceType"
                  onChange={(e) => {
                    setServicetypechange(e.target.value);
                  }}
                  {...params}
                  label="Types of Service"
                  size="small"
                />
              )}
            />

            <Button
              sx={{
                borderRadius: "50px",
                padding: "10px 33px",
              }}
              onClick={() => {
                searchResult(
                  servicetypechange
                    ? servicetypechange.label
                      ? servicetypechange.label === undefined ||
                        servicetypechange.label === null
                        ? servicetypechange
                        : servicetypechange.label
                      : servicetypechange
                    : servicetype,
                  Areaname,
                  pincodeValue
                );
                sethiglightjobs_1(false);
                setGetContact("");
              }}
              variant="contained"
            >
              {"search"}
            </Button>
          </div>
        </div>

        <Box
          className="mobile-view-search"
          sx={{ display: { xs: "flex", md: "none", lg: "none" } }}
        >
          <Button
            variant="outlined"
            className={
              mobilesearch === "true" ? "mobile-search-active" : "mobile-search"
            }
            onClick={() => {
              setmobilesearch(true);
            }}
          >
            <SearchIcon sx={{ color: "#ed1067" }} />
            <p>Search...</p>
          </Button>
        </Box>
        <div
          className="searchJobCard"
          style={{ display: higlightjobs_1 ? "none" : "block" }}
        >
          <TextField
            sx={{
              "& fieldset": {
                borderRadius: "50px",
                border: "1px solid rgb(16, 143, 178)",
              },
            }}
            onChange={(e) => {
              setsearchResultDatas(jobDetails);
              //setServicetype(servicetype);
              setsearchdata(e.target.value);
              if (e.target.value.length === 0 || e.target.value === "") {
                setsearchResultDatas(jobDetails);
                setRejectedPagination(10);
                setTotalData(jobDetails.length);
              }
              if (e.target.value.length < 3) {
                setsearchResultDatas(jobDetails);
                setRejectedPagination(10);
                setTotalData(jobDetails.length);
              }
              if (e.target.value.length >= 3) {
                setsearchdata(e.target.value);
                searchbytitledatas(e.target.value, jobDetails);
              }
            }}
            value={searchdata.replace(/[^a-z'., ]/gi, "")}
            id="Enter_location"
            name="Enter_location"
            label="Search by title"
            size="small"
          />
        </div>
        <Drawer
          sx={{
            "& .MuiDrawer-paper": {
              height: "50%",
              background: "rbga(255,255,255,0.8)",
            },
          }}
          open={mobilesearch}
          anchor={"top"}
        >
          <div className="phone-view">
            <div className="mobile-search-a">
              <TextField
                type="number"
                className="autocom-search-first"
                sx={{
                  "& fieldset": { border: "none" },
                }}
                onChange={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 6);
                  setPincodeValue(e.target.value);
                }}
                value={pincodeValue}
                id="Enter_location"
                name="Enter_location"
                label="Pincode"
                size="small"
              />
              <TextField
                className="autocom-search-first"
                // {...params}
                sx={{
                  "& fieldset": { border: "none" },
                }}
                onChange={(e) => {
                  setAreaname(
                    e.target.value === "" ||
                      e.target.value === undefined ||
                      e.target.value === null
                      ? ""
                      : e.target.value
                  );
                }}
                value={Areaname}
                id="Enter_location"
                name="Enter_location"
                label="Area Name"
                size="small"
              />
              <Autocomplete
                disablePortal
                disableUnderline={false}
                disableClearable
                id="Select_experience"
                name="serviceType"
                options={pincodedropdown}
                onChange={(event, value) => {
                  setServicetypechange(value);
                }}
                value={
                  servicetypechange
                    ? servicetypechange.label
                      ? servicetypechange.label
                      : servicetypechange
                    : servicetype.label
                    ? servicetype.label
                    : servicetype
                }
                sx={{ width: { xs: 350, md: 250, lg: 320 } }}
                renderInput={(params) => (
                  <TextField
                    className="autocom-search-first"
                    sx={{
                      "& fieldset": { border: "none" },
                    }}
                    id="Select_experience"
                    name="serviceType"
                    onChange={(e) => {
                      setServicetypechange(e.target.value);
                    }}
                    {...params}
                    label="Types of Services"
                    size="small"
                  />
                )}
              />

              <Button
                sx={{
                  borderRadius: "50px",
                  padding: "10px 33px",
                }}
                onClick={() => {
                  searchResult(
                    servicetypechange
                      ? servicetypechange.label
                        ? servicetypechange.label === undefined ||
                          servicetypechange.label === null
                          ? servicetypechange
                          : servicetypechange.label
                        : servicetypechange
                      : servicetype,
                    Areaname,
                    pincodeValue
                  );
                  setmobilesearch(false);
                }}
                variant="contained"
              >
                {"search"}
              </Button>
              <div className="mobile-close-btn">
                <IconButton
                  onClick={() => {
                    setmobilesearch(false);
                    setAreaname(location.state.Area);
                    setPincodeValue(location.state.pincode);
                    setServicetype(location.state.servicetype);
                    searchResult1(location.state);
                  }}
                >
                  <CloseIcon sx={{ color: "red" }} />
                </IconButton>
              </div>
            </div>
          </div>
        </Drawer>
        <Dialog
          TransitionComponent={Transition}
          open={alreadyAssigned}
          sx={{ "& .MuiPaper-root": { color: "white", width: "400px" } }}
        >
          <DialogTitle
            bgcolor="rgb(16 143 178)"
            sx={{ backgroundColor: "rgb(16 143 178)", fontWeight: 600 }}
          >
            Warning
          </DialogTitle>
          <DialogContent sx={{ mt: 5, mb: 2 }}>
            <DialogContentText sx={{ fontWeight: 600 }}>
              Already this service is assigned by you!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => setalreadyAssigned(false)}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          TransitionComponent={Transition}
          open={getservicecontact}
          sx={{ "& .MuiPaper-root": { color: "white", width: "400px" } }}
        >
          <DialogTitle
            bgcolor="rgb(16 143 178)"
            sx={{ backgroundColor: "rgb(16 143 178)", fontWeight: 600 }}
          >
            Contact Information
          </DialogTitle>
          <DialogContent sx={{ mt: 5, mb: 2 }}>
            <DialogContentText sx={{ fontWeight: 600 }}>
              <Typography
                gutterBottom
                sx={{
                  fontWeight: 600,
                  fontSize: "14px",
                  ml: 0.5,
                }}
                color="text.secondary"
              >
                Now this service contact number is available!!!
                {/* Contact Number: {getcontact.contact} */}
              </Typography>
              {/* {getcontact.place_of_service ==="Company"?(
              <>
              <Typography
                gutterBottom
                style={{
                  fontWeight: 600,
                  fontSize: "14px",
                  ml: 0.5,  
                }}
                color="text.secondary"
              >
                Company/Shop Name: {getcontact.companyName}
              </Typography>
             <Typography
                gutterBottom
                style={{   
                  fontWeight: 600,
                  fontSize: "14px",
                 
                  ml: 0.5,              
                
                }}
                color="text.secondary"
              >
                Company/Shop Address: {getcontact.CompanyAddress}
              </Typography>
              </>):null} */}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => {
               
                setGetProviderIdContact(oneprovider.provider_id)
                localStorage.setItem(
                  "provideridforservice",
                  oneprovider.provider_id
                );
                setprovideridlocal(oneprovider.provider_id);
                localStorage.setItem(
                  "serviceidforcontact",
                  JSON.stringify(getservicecontactView)
                );
                setsericeIdArray((current) => [
                  ...current,
                  ...getservicecontactView,
                ]);

                setGetServiceContact(false);
                searchResult1(location.state);
                window.location.reload();
              }}
            >
              Cancel
            </Button>
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => {
                setGetProviderIdContact(oneprovider.provider_id)
                localStorage.setItem(
                  "provideridforservice",
                  oneprovider.provider_id
                );
                setprovideridlocal(oneprovider.provider_id);
                localStorage.setItem(
                  "serviceidforcontact",
                  JSON.stringify(getservicecontactView)
                );
                setsericeIdArray((current) => [
                  ...current,
                  ...getservicecontactView,
                ]);

                setGetServiceContact(false);
                if (getcontact.job === "urgentJobs") {
                  assignToProvider2(
                    oneprovider,
                    getcontact.id,
                    getcontact.assign_provider_id
                    );
                  } else {
                    assignToProvider(
                      oneprovider,
                      getcontact.id,
                      getcontact.assign_provider_id
                      );
                    }
                    window.location.reload();
              }}
            >
              Assign To Me{" "}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          TransitionComponent={Transition}
          open={invalidUserId}
          sx={{ "& .MuiPaper-root": { color: "white", width: "400px" } }}
        >
          <DialogTitle
            bgcolor="rgb(16 143 178)"
            sx={{ backgroundColor: "rgb(16 143 178)", fontWeight: 600 }}
          >
            Error
          </DialogTitle>
          <DialogContent sx={{ mt: 5, mb: 2 }}>
            <DialogContentText sx={{ fontWeight: 600 }}>
              Invalid Provider ID!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => setInvalidUserId(false)}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          TransitionComponent={Transition}
          open={assingedServiceDialog}
          sx={{ "& .MuiPaper-root": { color: "white", width: "400px" } }}
        >
          <DialogTitle
            bgcolor="rgb(16 143 178)"
            sx={{ backgroundColor: "rgb(16 143 178)", fontWeight: 600 }}
          >
            Confirmation
          </DialogTitle>
          <DialogContent sx={{ mt: 5, mb: 2 }}>
            <DialogContentText sx={{ fontWeight: 600 }}>
              Successfully this service assigned to You!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => {
                setAssingedServiceDialog(false);
                setGetProviderId("");
                setGetProviderIdContact("")
                setGetContact("");
                setOneprovider("");
              }}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          TransitionComponent={Transition}
          open={openService}
          sx={{ "& .MuiPaper-root": { color: "white", width: "400px" } }}
        >
          <DialogTitle
            bgcolor="rgb(16 143 178)"
            sx={{ backgroundColor: "rgb(16 143 178)", fontWeight: 600 }}
          >
            Search
          </DialogTitle>
          <DialogContent sx={{ mt: 5, mb: 2 }}>
            <DialogContentText sx={{ fontWeight: 600 }}>
              Types of Service is required
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => setOpenService(false)}
            >
              "Ok"
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          TransitionComponent={Transition}
          open={buyservice}
          sx={{ "& .MuiPaper-root": { color: "white", width: "400px" } }}
        >
          <DialogTitle
            bgcolor="rgb(16 143 178)"
            sx={{ backgroundColor: "rgb(16 143 178)", fontWeight: 600 }}
          >
            Buy Service
          </DialogTitle>
          <DialogContent sx={{ margin: "20px" }}>
            <DialogContentText sx={{ fontWeight: 600 }}>
              Enter Your Provider ID
            </DialogContentText>
            <TextField
              sx={{ marginTop: "20px" }}
              onChange={(e) => {
                setGetProviderIdContact("")
                setGetProviderId("");
                setGetProviderId(e.target.value);
                if (e.target.value.length > 5) {
                  setError("");
                  getproviderpaymentinfo(e.target.value, serviceprovider);
                } else if (e.target.value.length <= 0) {
                  setError("Please Enter Your Provider ID");
                }
              }}
              value={getproviderid.replace(/[^a-z0-9 ]/gi, "")}
              variant="outlined"
              id="outlined-basic"
              name="Enter_userid"
              label="Provider ID"
            />
            {error ? <div style={{ color: "red" }}>{error}</div> : null}
          </DialogContent>
          <DialogActions>
            <Button
              color="error"
              variant="contained"
              onClick={() => {
                setError("");
                setBuyService(false);
                setGetProviderId("");
                setGetProviderIdContact("")
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={
                error === "Please Enter Your Provider ID" ||
                error === "Invalid User ID" ||
                getproviderid === ""
                  ? true
                  : false
              }
              color="secondary"
              variant="contained"
              onClick={() => {
                if (
                  getproviderid === "" ||
                  getproviderid === undefined ||
                  getproviderid === null
                ) {
                  setError("Please Enter Your Provider ID");
                } else {
                  setError("");
                  setBuyService(false);
                  getproviderpayment(oneprovider);
                }
              }}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          TransitionComponent={Transition}
          open={noResultPopUp}
          // onClose={() => setNoResultPopUp(false)}
          sx={{ "& .MuiPaper-root": { color: "white", width: "400px" } }}
        >
          <DialogTitle
            bgcolor="rgb(16 143 178)"
            sx={{ backgroundColor: "rgb(16 143 178)", fontWeight: 600 }}
          >
            {servicetype === "Service Providers"
              ? "Local Service Provider Search"
              : servicetype === "Local Services"
              ? "Local Services Search"
              : "Local Jobs Search"}
          </DialogTitle>
          <DialogContent sx={{ mt: 3 }}>
            <DialogContentText sx={{ fontWeight: 600 }}>
              {servicetype === "Service Providers"
                ? "No Service Provider available for your search"
                : servicetype === "Local Services"
                ? "No Service available for your search"
                : "No Job available for your search"}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => {
                setNoResultPopUp(false);
                setsearchdata("");
              }}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          TransitionComponent={Transition}
          open={jobApplied}
          // onClose={() => setJobApplied(false)}
          sx={{
            "& .MuiPaper-root": {
              backgroundColor: "rgb(16 143 178)",
              width: "300px",
            },
          }}
        >
          <DialogTitle
            bgcolor="rgb(16 143 178)"
            sx={{ color: "white", fontWeight: 600 }}
          >
            Job Apply
          </DialogTitle>
          <DialogContent sx={{ mt: 1 }}>
            <DialogContentText
              sx={{ color: "white", fontWeight: 600 }}
            ></DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => setJobApplied(false)}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          TransitionComponent={Transition}
          open={openMoreDetails}
          jobDescription={jobDescription}
          // onClose={() => setOpenMoreDetails(false)}
          sx={{ "& .MuiPaper-root": { width: "500px" } }}
        >
          <DialogTitle
            bgcolor="rgb(16 143 178)"
            sx={{ color: "white", fontWeight: 600 }}
          >
            {servicetype === "Service Providers" ? "Remark" : "Job Description"}
          </DialogTitle>
          <DialogContent sx={{ mt: 1 }}>
            {jobDescription ? (
              <DialogContentText
                dangerouslySetInnerHTML={{
                  __html:
                    servicetype === "Service Providers"
                      ? jobDescription.remarks
                      : servicetype === "Local Jobs"
                      ? jobDescription.nature_of_job
                      : jobDescription.job_description,
                }}
              ></DialogContentText>
            ) : (
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: "16px",
                  ml: 0.5,
                  mt: 2,
                }}
                color="text.secondary"
              >
                {servicetype === "Service Providers"
                  ? "No remark for this Service Provider"
                  : servicetype === "Local Jobs"
                  ? "No description for this Job"
                  : "No description for this Service"}
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              sx={{ mr: 2, mb: 1 }}
              variant="outlined"
              onClick={() => setOpenMoreDetails(false)}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          TransitionComponent={Transition}
          open={openMoreDetails1}
          jobDescription1={jobDescription1}
          // onClose={() => setOpenMoreDetails(false)}
          sx={{ "& .MuiPaper-root": { width: "500px" } }}
        >
          <DialogTitle
            bgcolor="rgb(16 143 178)"
            sx={{ color: "white", fontWeight: 600 }}
          >
            {servicetype === "Service Providers" ? "Remark" : "Job Description"}
          </DialogTitle>
          <DialogContent sx={{ mt: 1 }}>
            {jobDescription1 ? (
              <DialogContentText
                dangerouslySetInnerHTML={{
                  __html:
                    servicetype === "Service Providers"
                      ? jobDescription1
                      : servicetype === "Local Jobs"
                      ? jobDescription1
                      : jobDescription1,
                }}
              ></DialogContentText>
            ) : (
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: "16px",
                  ml: 0.5,
                  mt: 2,
                }}
                color="text.secondary"
              >
                {servicetype === "Service Providers"
                  ? "No remark for this Service Provider"
                  : servicetype === "Local Jobs"
                  ? "No description for this Job"
                  : "No description for this Service"}
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              sx={{ mr: 2, mb: 1 }}
              variant="outlined"
              onClick={() => setOpenMoreDetails1(false)}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          TransitionComponent={Transition}
          open={searchPopUp}
          // onClose={() => setSearchPopUp(false)}
          sx={{ "& .MuiPaper-root": { color: "white", width: "400px" } }}
        >
          <DialogTitle
            bgcolor="rgb(16 143 178)"
            sx={{ backgroundColor: "rgb(16 143 178)", fontWeight: 600 }}
          >
            search
          </DialogTitle>
          <DialogContent sx={{ mt: 5, mb: 2 }}>
            <DialogContentText sx={{ fontWeight: 600 }}>
              Atleast one field is requried for search !
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => setSearchPopUp(false)}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        <Typography sx={{ fontSize: "18px", ml: 1, mb: 1, fontWeight: 500 }}>
          <b style={{ fontSize: "22px" }}>{totalData} </b>
          Search results found{" "}
        </Typography>

        <div className="jobFullContend">
          {jobDetails.length === 0 ? (
            higlightjobs_1 ? (
              <div className="recommendedJobCard">
                {higlightjobs_1 ? (
                  <Card
                    className="recommendedJob-InnerCard"
                    key={latestjobs._id}
                    sx={{ mt: 2, border: "5px solid #ed1e70" }}
                  >
                    <div className="ApplyCard">
                      <CardContent>
                        {higlightjobs_1.job_status === "InActive" ? (
                          <div className="AppliedDiv">
                            <Chip
                              className="AppliedButton"
                              sx={{ ml: 1, mr: -3, mt: -3.5 }}
                              label="Expired"
                              size="small"
                              color="error"
                              variant="outlined"
                            />
                          </div>
                        ) : null}
                        <Grid lg={12} md={12} sm={12} xs={12}>
                          <Typography
                            gutterBottom
                            sx={{
                              fontWeight: 600,
                              fontSize: "26px",
                              wordWrap: "break-word",
                              lineHeight: "2em",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              transition: "0.5s",
                              backgroundColor: "#22668a",
                              color: "white",
                              padding: "0px 5px",
                              textTransform: "uppercase",
                              borderRadius: "5px",
                            }}
                            className="nameTitleServiceProvider"
                          >
                            {servicetype === "Service Providers"
                              ? latestjobs.service_name
                              : servicetype === "Local Services"
                              ? latestjobs.type_of_service
                              : latestjobs.job_title}
                          </Typography>
                        </Grid>
                        <div className="mobileDeviceLocalJobs">
                          {servicetype === "Local Services" ? (
                            <>
                              <div className="LocalJobsAlignment">
                                <div>
                                  <Typography
                                    className="LocalJobsNameAlignment"
                                    gutterBottom
                                    sx={{
                                      fontWeight: 600,
                                      fontSize: "14px",
                                      mr: 1,
                                    }}
                                  >
                                    <text>Place of service</text>
                                    <text>:</text>
                                  </Typography>
                                </div>
                                <div>
                                  <Grid lg={12} md={12} sm={12} xs={12}>
                                    <Typography
                                      gutterBottom
                                      sx={{
                                        fontWeight: 600,
                                        fontSize: "14px",
                                        wordWrap: "break-word",
                                      }}
                                      color="text.secondary"
                                    >
                                      {latestjobs.place_of_service}
                                    </Typography>
                                  </Grid>
                                </div>
                              </div>
                              <div
                                className="LocalJobsAlignment"
                                style={{
                                  display:
                                    latestjobs.place_of_service === "Company"
                                      ? ""
                                      : "none",
                                }}
                              >
                                <div>
                                  <Typography
                                    className="LocalJobsNameAlignment"
                                    gutterBottom
                                    sx={{
                                      fontWeight: 600,
                                      fontSize: "14px",
                                      mr: 1,
                                    }}
                                  >
                                    <text>Company/Shop Name</text>
                                    <text>:</text>
                                  </Typography>
                                </div>
                                <div>
                                  <Grid lg={12} md={12} sm={12} xs={12}>
                                    <Typography
                                      gutterBottom
                                      disabled
                                      sx={{
                                        fontWeight: 600,
                                        fontSize: "14px",
                                        wordWrap: "break-word",
                                        filter:
                                          servicetype === "Local Services" &&
                                          getAdminSetting && getprovideridContact === ""&&
                                          provideridlocal != getprovideridContact &&
                                          sericeIdArray.includes(
                                            latestjobs._id
                                          ) <= 0
                                            ? "blur(3px)"
                                            : null,
                                      }}
                                      color="text.secondary"
                                    >
                                      {servicetype === "Local Services" &&
                                          getAdminSetting &&getprovideridContact === ""&&
                                          provideridlocal != getprovideridContact &&
                                          sericeIdArray.includes(
                                            latestjobs._id
                                          ) <= 0?"xxxxxxxx":latestjobs.shop_name}
                                    </Typography>
                                  </Grid>
                                </div>
                              </div>
                              <div
                                className="LocalJobsAlignment"
                                style={{
                                  display:
                                    latestjobs.place_of_service === "Company"
                                      ? ""
                                      : "none",
                                }}
                              >
                                <div>
                                  <Typography
                                    className="LocalJobsNameAlignment"
                                    gutterBottom
                                    sx={{
                                      fontWeight: 600,
                                      fontSize: "14px",
                                      mr: 1,
                                    }}
                                  >
                                    <text>Company/Shop Address</text>
                                    <text>:</text>
                                  </Typography>
                                </div>
                                <div>
                                  <Grid lg={12} md={12} sm={12} xs={12}>
                                    <Typography
                                      gutterBottom
                                      disabled
                                      sx={{
                                        fontWeight: 600,
                                        fontSize: "14px",
                                        wordWrap: "break-word",
                                        filter:
                                          servicetype === "Local Services" &&
                                          getAdminSetting &&getprovideridContact === ""&&
                                          provideridlocal != getprovideridContact &&
                                          sericeIdArray.includes(
                                            latestjobs._id
                                          ) <= 0
                                            ? "blur(3px)"
                                            : null,
                                      }}
                                      color="text.secondary"
                                    >
                                      {servicetype === "Local Services" &&
                                          getAdminSetting &&getprovideridContact === ""&&
                                          provideridlocal != getprovideridContact &&
                                          sericeIdArray.includes(
                                            latestjobs._id
                                          ) <= 0?"xxxxxxxx":latestjobs.shop_address}
                                    </Typography>
                                  </Grid>
                                </div>
                              </div>
                            </>
                          ) : null}
                          {servicetype === "Service Providers" ? (
                            <>
                              <div className="LocalJobsAlignment">
                                <div>
                                  <Typography
                                    className="LocalJobsNameAlignment"
                                    gutterBottom
                                    sx={{
                                      fontWeight: 600,
                                      fontSize: "14px",
                                      mr: 1,
                                    }}
                                  >
                                    <text>Place of service</text>
                                    <text>:</text>
                                  </Typography>
                                </div>
                                <div>
                                  <Grid lg={12} md={12} sm={12} xs={12}>
                                    <Typography
                                      gutterBottom
                                      sx={{
                                        fontWeight: 600,
                                        fontSize: "14px",
                                        wordWrap: "break-word",
                                      }}
                                      color="text.secondary"
                                    >
                                      {latestjobs.service_offered_by}
                                    </Typography>
                                  </Grid>
                                </div>
                              </div>

                              <div
                                className="LocalJobsAlignment"
                                style={{
                                  display:
                                    latestjobs.service_offered_by === "Company"
                                      ? ""
                                      : "none",
                                }}
                              >
                                <div>
                                  <Typography
                                    className="LocalJobsNameAlignment"
                                    gutterBottom
                                    sx={{
                                      fontWeight: 600,
                                      fontSize: "14px",
                                      mr: 1,
                                    }}
                                  >
                                    <text>Company/Shop Name</text>
                                    <text>:</text>
                                  </Typography>
                                </div>
                                <div>
                                  <Grid lg={12} md={12} sm={12} xs={12}>
                                    <Typography
                                      gutterBottom
                                      sx={{
                                        fontWeight: 600,
                                        fontSize: "14px",
                                        wordWrap: "break-word",
                                      }}
                                      color="text.secondary"
                                    >
                                      {latestjobs.shop_name}
                                    </Typography>
                                  </Grid>
                                </div>
                              </div>
                              <div
                                className="LocalJobsAlignment"
                                style={{
                                  display:
                                    latestjobs.service_offered_by === "Company"
                                      ? ""
                                      : "none",
                                }}
                              >
                                <div>
                                  <Typography
                                    className="LocalJobsNameAlignment"
                                    gutterBottom
                                    sx={{
                                      fontWeight: 600,
                                      fontSize: "14px",
                                      mr: 1,
                                    }}
                                  >
                                    <text>Company/Shop Address</text>
                                    <text>:</text>
                                  </Typography>
                                </div>
                                <div>
                                  <Grid lg={12} md={12} sm={12} xs={12}>
                                    <Typography
                                      gutterBottom
                                      sx={{
                                        fontWeight: 600,
                                        fontSize: "14px",
                                        wordWrap: "break-word",
                                      }}
                                      color="text.secondary"
                                    >
                                      {latestjobs.shop_address}
                                    </Typography>
                                  </Grid>
                                </div>
                              </div>
                            </>
                          ) : null}
                          {servicetype === "Local Jobs" ? (
                            <>
                              <div className="LocalJobsAlignment">
                                <div>
                                  <Typography
                                    className="LocalJobsNameAlignment"
                                    gutterBottom
                                    sx={{
                                      fontWeight: 600,
                                      fontSize: "14px",
                                      mr: 1,
                                    }}
                                  >
                                    <text>Company/Shop Name</text>
                                    <text>:</text>
                                  </Typography>
                                </div>
                                <div>
                                  <Grid lg={12} md={12} sm={12} xs={12}>
                                    <Typography
                                      gutterBottom
                                      sx={{
                                        fontWeight: 600,
                                        fontSize: "14px",
                                        wordWrap: "break-word",
                                      }}
                                      color="text.secondary"
                                    >
                                      {latestjobs.shop_name}
                                    </Typography>
                                  </Grid>
                                </div>
                              </div>
                              <div className="LocalJobsAlignment">
                                <div>
                                  <Typography
                                    className="LocalJobsNameAlignment"
                                    gutterBottom
                                    sx={{
                                      fontWeight: 600,
                                      fontSize: "14px",
                                      mr: 1,
                                    }}
                                  >
                                    <text>Company/Shop Address</text>
                                    <text>:</text>
                                  </Typography>
                                </div>
                                <div>
                                  <Typography
                                    gutterBottom
                                    sx={{ fontWeight: 600, fontSize: "14px" }}
                                    color="text.secondary"
                                  >
                                    {latestjobs.shop_address}
                                  </Typography>
                                </div>
                              </div>{" "}
                            </>
                          ) : null}
                          <div className="LocalJobsAlignment">
                            <div>
                              <Typography
                                className="LocalJobsNameAlignment"
                                gutterBottom
                                sx={{
                                  fontWeight: 600,
                                  fontSize: "14px",
                                  mr: 1,
                                }}
                              >
                                <text>Area/Street Name</text>
                                <text>:</text>
                              </Typography>
                            </div>
                            <div>
                              <Typography
                                gutterBottom
                                sx={{ fontWeight: 600, fontSize: "14px" }}
                                color="text.secondary"
                              >
                                {latestjobs.area_street_name}
                              </Typography>
                            </div>
                          </div>
                          <div className="LocalJobsAlignment">
                            <div>
                              <Typography
                                className="LocalJobsNameAlignment"
                                gutterBottom
                                sx={{
                                  fontWeight: 600,
                                  fontSize: "14px",
                                  mr: 1,
                                }}
                              >
                                <text>Post Office Name</text>
                                <text>:</text>
                              </Typography>
                            </div>
                            <div>
                              <Typography
                                gutterBottom
                                sx={{ fontWeight: 600, fontSize: "14px" }}
                                color="text.secondary"
                              >
                                {latestjobs.village_town_name}
                              </Typography>
                            </div>
                          </div>
                          <div className="LocalJobsAlignment">
                            <div>
                              <Typography
                                className="LocalJobsNameAlignment"
                                gutterBottom
                                sx={{
                                  fontWeight: 600,
                                  fontSize: "14px",
                                  mr: 1,
                                }}
                              >
                                <text>Pincode</text>
                                <text>:</text>
                              </Typography>
                            </div>
                            <div>
                              <Typography
                                gutterBottom
                                sx={{ fontWeight: 600, fontSize: "14px" }}
                                color="text.secondary"
                              >
                                {latestjobs.pincode}
                              </Typography>
                            </div>
                          </div>
                          {servicetype === "Local Jobs" ? (
                            <>
                              <div
                                className="LocalJobsAlignment"
                                style={{
                                  display:
                                    latestjobs.landline_number === null ||
                                    latestjobs.landline_number === undefined
                                      ? "none"
                                      : "",
                                }}
                              >
                                <div>
                                  <Typography
                                    className="LocalJobsNameAlignment"
                                    gutterBottom
                                    sx={{
                                      fontWeight: 600,
                                      fontSize: "14px",
                                      mr: 1,
                                    }}
                                  >
                                    <text>Landline Number</text>
                                    <text>:</text>
                                  </Typography>
                                </div>
                                <div>
                                  <Typography
                                    gutterBottom
                                    sx={{
                                      fontWeight: 600,
                                      fontSize: "14px",
                                    }}
                                    color="text.secondary"
                                  >
                                    {latestjobs.landline_number}
                                  </Typography>
                                </div>
                              </div>
                              <div className="LocalJobsAlignment">
                                <div>
                                  <Typography
                                    className="LocalJobsNameAlignment"
                                    gutterBottom
                                    sx={{
                                      fontWeight: 600,
                                      fontSize: "14px",
                                      mr: 1,
                                    }}
                                  >
                                    <text>Salary Range</text>
                                    <text>:</text>
                                  </Typography>
                                </div>
                                <div>
                                  {(latestjobs.salary_range_min === null &&
                                    latestjobs.salary_range_max === null) ||
                                  (latestjobs.salary_range_min === undefined &&
                                    latestjobs.salary_range_max ===
                                      undefined) ? (
                                    <Typography
                                      gutterBottom
                                      sx={{
                                        fontWeight: 600,
                                        fontSize: "14px",
                                        backgroundColor: "#22668a",
                                        color: "white",
                                        padding: "0px 5px",
                                        borderRadius: "5px",
                                      }}
                                      color="text.secondary"
                                    >
                                      No Salary Range
                                    </Typography>
                                  ) : (
                                    <Typography
                                      gutterBottom
                                      sx={{
                                        fontWeight: 600,
                                        fontSize: "14px",
                                        backgroundColor: "#22668a",
                                        color: "white",
                                        padding: "0px 5px",
                                        textTransform: "uppercase",
                                        borderRadius: "5px",
                                      }}
                                      color="text.secondary"
                                    >
                                      {latestjobs.salary_range_min} -{" "}
                                      {latestjobs.salary_range_max}
                                    </Typography>
                                  )}
                                </div>
                              </div>
                            </>
                          ) : null}
                          <div className="LocalJobsAlignment">
                            <div>
                              <Typography
                                className="LocalJobsNameAlignment"
                                gutterBottom
                                sx={{
                                  fontWeight: 600,
                                  fontSize: "14px",
                                  mr: 1,
                                }}
                              >
                                <text>Contact Person Name</text>
                                <text>:</text>
                              </Typography>
                            </div>
                            <div>
                              <Grid lg={12} md={12} sm={12} xs={12}>
                                <Typography
                                  gutterBottom
                                  sx={{
                                    fontWeight: 600,
                                    fontSize: "14px",
                                    wordWrap: "break-word",
                                  }}
                                  color="text.secondary"
                                >
                                  {latestjobs.contact_person_name}
                                </Typography>
                              </Grid>
                            </div>
                          </div>
                          <div className="LocalJobsAlignment">
                            <div>
                              <Typography
                                className="LocalJobsNameAlignment"
                                gutterBottom
                                sx={{
                                  fontWeight: 600,
                                  fontSize: "14px",
                                  mr: 1,
                                }}
                              >
                                <text>Contact Number</text>
                                <text>:</text>
                              </Typography>
                            </div>
                            <div>
                              <Typography
                                gutterBottom
                                disabled
                                sx={{
                                  fontWeight: 600,
                                  fontSize: "14px",
                                  backgroundColor: "#22668a",
                                  color: "white",
                                  padding: "0px 5px",
                                  filter:
                                    servicetype === "Local Services" &&
                                    getAdminSetting && getprovideridContact === ""&&
                                    provideridlocal != getprovideridContact &&
                                    sericeIdArray.includes(latestjobs._id) <= 0
                                      ? "blur(4px)"
                                      : null,
                                  textTransform: "uppercase",
                                  borderRadius: "5px",
                                }}
                                color="text.secondary"
                              >
                                {servicetype === "Local Services" &&
                                          getAdminSetting &&getprovideridContact === ""&&
                                          provideridlocal != getprovideridContact &&
                                          sericeIdArray.includes(
                                            latestjobs._id
                                          ) <= 0?"xxxxxxxx":latestjobs.contact_number}
                              </Typography>
                            </div>
                          </div>
                          {servicetype === "Local Jobs" ? (
                            <div className="LocalJobsAlignment">
                              <div>
                                <Typography
                                  className="LocalJobsNameAlignment"
                                  gutterBottom
                                  sx={{
                                    fontWeight: 600,
                                    fontSize: "14px",
                                    mr: 1,
                                  }}
                                >
                                  <text>Job Need Type</text>
                                  <text>:</text>
                                </Typography>
                              </div>
                              <div>
                                <Grid lg={12} md={12} sm={12} xs={12}>
                                  <Typography
                                    gutterBottom
                                    sx={{
                                      fontWeight: 600,
                                      fontSize: "14px",
                                      wordWrap: "break-word",
                                    }}
                                    color="text.secondary"
                                  >
                                    {latestjobs.job_need_type}
                                  </Typography>
                                </Grid>
                              </div>
                            </div>
                          ) : null}
                          {servicetype === "Local Services" ? (
                            <div className="LocalJobsAlignment">
                              <div>
                                <Typography
                                  className="LocalJobsNameAlignment"
                                  gutterBottom
                                  sx={{
                                    fontWeight: 600,
                                    fontSize: "14px",
                                    mr: 1,
                                  }}
                                >
                                  <text>Service Need Type</text>
                                  <text>:</text>
                                </Typography>
                              </div>
                              <div>
                                <Grid lg={12} md={12} sm={12} xs={12}>
                                  <Typography
                                    gutterBottom
                                    sx={{
                                      fontWeight: 600,
                                      fontSize: "14px",
                                      wordWrap: "break-word",
                                    }}
                                    color="text.secondary"
                                  >
                                    {latestjobs.need_type}
                                  </Typography>
                                </Grid>
                              </div>
                            </div>
                          ) : null}
                          {servicetype === "Service Providers" ? (
                            <div className="LocalJobsAlignment">
                              <div>
                                <Typography
                                  className="LocalJobsNameAlignment"
                                  gutterBottom
                                  sx={{
                                    fontWeight: 600,
                                    fontSize: "14px",
                                    mr: 1,
                                  }}
                                >
                                  <text>Email ID</text>
                                  <text>:</text>
                                </Typography>
                              </div>
                              <div>
                                <Grid lg={12} md={12} sm={12} xs={12}>
                                  <Typography
                                    gutterBottom
                                    sx={{
                                      fontWeight: 600,
                                      fontSize: "14px",
                                      wordWrap: "break-word",
                                    }}
                                    color="text.secondary"
                                  >
                                    {latestjobs.email_id}
                                  </Typography>
                                </Grid>
                              </div>
                            </div>
                          ) : null}
                          {servicetype === "Local Jobs" ? (
                            <>
                              <div className="LocalJobsAlignment">
                                <div>
                                  <Typography
                                    className="LocalJobsNameAlignment"
                                    gutterBottom
                                    sx={{
                                      fontWeight: 600,
                                      fontSize: "14px",
                                      mr: 1,
                                    }}
                                  >
                                    <text>Number of persons</text>
                                    <text>:</text>
                                  </Typography>
                                </div>
                                <div>
                                  <Typography
                                    gutterBottom
                                    sx={{ fontWeight: 600, fontSize: "14px" }}
                                    color="text.secondary"
                                  >
                                    {latestjobs.number_of_persons}
                                  </Typography>
                                </div>
                              </div>
                              <div className="LocalJobsAlignment">
                                <div>
                                  <Typography
                                    className="LocalJobsNameAlignment"
                                    gutterBottom
                                    sx={{
                                      fontWeight: 600,
                                      fontSize: "14px",
                                      mr: 1,
                                    }}
                                  >
                                    <text>Expiry Date Range</text>
                                    <text>:</text>
                                  </Typography>
                                </div>
                                <div>
                                  <Typography
                                    gutterBottom
                                    sx={{ fontWeight: 600, fontSize: "14px" }}
                                    color="text.secondary"
                                  >
                                    {latestjobs.expiry_date_from} -{" "}
                                    {latestjobs.expiry_date_to}
                                  </Typography>
                                </div>
                              </div>
                            </>
                          ) : null}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            marginTop: "5px",
                          }}
                        >
                          <DescriptionIcon
                            fontSize="small"
                            sx={{
                              color: "rgb(145 145 145)",
                            }}
                          />
                          {servicetype === "Service Providers" ? (
                            <Typography
                              gutterBottom
                              sx={{
                                fontWeight: 600,
                                fontSize: "14px",
                                ml: 0.5,
                                mt: -1.7,
                              }}
                              color="text.secondary"
                              dangerouslySetInnerHTML={{
                                __html: renderParagraphs(latestjobs.remarks),
                              }}
                            />
                          ) : servicetype === "Local Services" ? (
                            <Typography
                              gutterBottom
                              sx={{
                                fontWeight: 600,
                                fontSize: "14px",
                                ml: 0.5,
                                mt: -1.7,
                              }}
                              color="text.secondary"
                              dangerouslySetInnerHTML={{
                                __html: renderParagraphs(
                                  latestjobs.job_description
                                ),
                              }}
                            />
                          ) : (
                            <Typography
                              gutterBottom
                              sx={{
                                fontWeight: 600,
                                fontSize: "14px",
                                ml: 0.5,
                              }}
                              color="text.secondary"
                            >
                              {latestjobs.nature_of_job}
                            </Typography>
                          )}
                        </div>
                      </CardContent>

                      <div>
                        <CardActions
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            width: "99%",
                            marginTop: "10px",
                          }}
                        >
                          {servicetype === "Local Services" ? (
                            <Button
                              onClick={() => {
                                setAssingedDetails("");
                                setGetContact("");
                                setGetProviderId("")
                               
                                setAssingedDetails(
                                  ...[
                                    {
                                      assign_provider_id:
                                        latestjobs.assign_provider_id,
                                      assign_provider_email:
                                        latestjobs.assign_provider_email,
                                      assign_provider_name:
                                        latestjobs.assign_provider_name,
                                      assigned_date: latestjobs.assigned_date,
                                    },
                                  ]
                                );
                                setGetContact({
                                  ...getcontact,
                                  contact: latestjobs.contact_number,
                                  id: latestjobs._id,
                                  job: "urgentJobs",
                                  job_id: latestjobs.job_id,
                                  assign_provider_id:
                                    latestjobs.assign_provider_id,
                                  CompanyAddress: latestjobs.shop_address,
                                  companyName: latestjobs.shop_address,
                                  place_of_service: latestjobs.place_of_service,
                                });
                                setBuyService(true);
                              }}
                              sx={{
                                ml: 1,
                                mr: 1,
                              }}
                              disabled={
                                latestjobs.status === "Assigned" ||  (provideridlocal != "" &&
                                sericeIdArray.includes(
                                  latestjobs._id
                                ) > 0)? true : false
                              }
                              variant="contained"
                              color="success"
                              size="small"
                            >
                              View Contact
                            </Button>
                          ) : null}
                          <Button
                            onClick={() => {
                              moreDetails1(
                                servicetype === "Service Providers"
                                  ? latestjobs.remarks
                                  : servicetype === "Local Jobs"
                                  ? latestjobs.nature_of_job
                                  : latestjobs.job_description
                              );
                            }}
                            sx={{
                              ml: 1,
                              mr: 1,
                            }}
                            variant="outlined"
                            color="success"
                            size="small"
                          >
                            View More
                          </Button>

                          {latestjobs.status === "InActive" ||
                          latestjobs.status === "Closed" ? (
                            <Button disabled variant="contained" size="small">
                              Send Message
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              size="small"
                              onClick={() => {}}
                            >
                              Send Message
                            </Button>
                          )}
                        </CardActions>
                      </div>
                    </div>
                  </Card>
                ) : null}
              </div>
            ) : (
              <div style={{ textAlign: "center" }}>
                <h3>
                  {servicetype === "Service Providers"
                    ? "No Service Provider available for this search"
                    : servicetype === "Local Jobs"
                    ? "No Job available for this search"
                    : "No Service available for this search"}
                </h3>
              </div>
            )
          ) : (
            <div className="recommendedJobCard">
              {higlightjobs_1 ? (
                <Card
                  className="recommendedJob-InnerCard"
                  key={latestjobs._id}
                  sx={{ mt: 2, border: "5px solid #ed1e70" }}
                >
                  <div className="ApplyCard">
                    <CardContent>
                      {higlightjobs_1.job_status === "InActive" ? (
                        <div className="AppliedDiv">
                          <Chip
                            className="AppliedButton"
                            sx={{ ml: 1, mr: -3, mt: -3.5 }}
                            label="Expired"
                            size="small"
                            color="error"
                            variant="outlined"
                          />
                        </div>
                      ) : null}
                      <Grid lg={12} md={12} sm={12} xs={12}>
                        <Typography
                          gutterBottom
                          sx={{
                            fontWeight: 600,
                            fontSize: "26px",
                            wordWrap: "break-word",
                            lineHeight: "2em",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            transition: "0.5s",
                            backgroundColor: "#22668a",
                            color: "white",
                            padding: "0px 5px",
                            textTransform: "uppercase",
                            borderRadius: "5px",
                          }}
                          className="nameTitleServiceProvider"
                        >
                          {servicetype === "Service Providers"
                            ? latestjobs.service_name
                            : servicetype === "Local Services"
                            ? latestjobs.type_of_service
                            : latestjobs.job_title}
                        </Typography>
                      </Grid>
                      <div className="mobileDeviceLocalJobs">
                        {servicetype === "Local Services" ? (
                          <>
                            <div className="LocalJobsAlignment">
                              <div>
                                <Typography
                                  className="LocalJobsNameAlignment"
                                  gutterBottom
                                  sx={{
                                    fontWeight: 600,
                                    fontSize: "14px",
                                    mr: 1,
                                  }}
                                >
                                  <text>Place of service</text>
                                  <text>:</text>
                                </Typography>
                              </div>
                              <div>
                                <Grid lg={12} md={12} sm={12} xs={12}>
                                  <Typography
                                    gutterBottom
                                    sx={{
                                      fontWeight: 600,
                                      fontSize: "14px",
                                      wordWrap: "break-word",
                                    }}
                                    color="text.secondary"
                                  >
                                    {latestjobs.place_of_service}
                                  </Typography>
                                </Grid>
                              </div>
                            </div>
                            <div
                              className="LocalJobsAlignment"
                              style={{
                                display:
                                  latestjobs.place_of_service === "Company"
                                    ? ""
                                    : "none",
                              }}
                            >
                              <div>
                                <Typography
                                  className="LocalJobsNameAlignment"
                                  gutterBottom
                                  sx={{
                                    fontWeight: 600,
                                    fontSize: "14px",
                                    mr: 1,
                                  }}
                                >
                                  <text>Company/Shop Name</text>
                                  <text>:</text>
                                </Typography>
                              </div>
                              <div>
                                <Grid lg={12} md={12} sm={12} xs={12}>
                                  <Typography
                                    gutterBottom
                                    disabled
                                    sx={{
                                      fontWeight: 600,
                                      fontSize: "14px",
                                      wordWrap: "break-word",
                                      filter:
                                        servicetype === "Local Services" &&
                                        getAdminSetting &&getprovideridContact === ""&&
                                        provideridlocal != getprovideridContact &&
                                        sericeIdArray.includes(
                                          latestjobs._id
                                        ) <= 0
                                          ? "blur(3px)"
                                          : null,
                                    }}
                                    color="text.secondary"
                                  >
                                    {servicetype === "Local Services" &&
                                          getAdminSetting &&getprovideridContact === ""&&
                                          provideridlocal != getprovideridContact &&
                                          sericeIdArray.includes(
                                            latestjobs._id
                                          ) <= 0?"xxxxxxxx":latestjobs.shop_name}
                                  </Typography>
                                </Grid>
                              </div>
                            </div>
                            <div
                              className="LocalJobsAlignment"
                              style={{
                                display:
                                  latestjobs.place_of_service === "Company"
                                    ? ""
                                    : "none",
                              }}
                            >
                              <div>
                                <Typography
                                  className="LocalJobsNameAlignment"
                                  gutterBottom
                                  sx={{
                                    fontWeight: 600,
                                    fontSize: "14px",
                                    mr: 1,
                                  }}
                                >
                                  <text>Company/Shop Address</text>
                                  <text>:</text>
                                </Typography>
                              </div>
                              <div>
                                <Grid lg={12} md={12} sm={12} xs={12}>
                                  <Typography
                                    gutterBottom
                                    disabled
                                    sx={{
                                      fontWeight: 600,
                                      fontSize: "14px",
                                      wordWrap: "break-word",
                                      filter:
                                        servicetype === "Local Services" &&
                                        getAdminSetting &&getprovideridContact === ""&&
                                        provideridlocal != getprovideridContact &&
                                        sericeIdArray.includes(
                                          latestjobs._id
                                        ) <= 0
                                          ? "blur(3px)"
                                          : null,
                                    }}
                                    color="text.secondary"
                                  >
                                    {servicetype === "Local Services" &&
                                          getAdminSetting &&getprovideridContact === ""&&
                                          provideridlocal != getprovideridContact &&
                                          sericeIdArray.includes(
                                            latestjobs._id
                                          ) <= 0?"xxxxxxxx":latestjobs.shop_address}
                                  </Typography>
                                </Grid>
                              </div>
                            </div>
                          </>
                        ) : null}
                        {servicetype === "Service Providers" ? (
                          <>
                            <div className="LocalJobsAlignment">
                              <div>
                                <Typography
                                  className="LocalJobsNameAlignment"
                                  gutterBottom
                                  sx={{
                                    fontWeight: 600,
                                    fontSize: "14px",
                                    mr: 1,
                                  }}
                                >
                                  <text>Place of service</text>
                                  <text>:</text>
                                </Typography>
                              </div>
                              <div>
                                <Grid lg={12} md={12} sm={12} xs={12}>
                                  <Typography
                                    gutterBottom
                                    sx={{
                                      fontWeight: 600,
                                      fontSize: "14px",
                                      wordWrap: "break-word",
                                    }}
                                    color="text.secondary"
                                  >
                                    {latestjobs.service_offered_by}
                                  </Typography>
                                </Grid>
                              </div>
                            </div>

                            <div
                              className="LocalJobsAlignment"
                              style={{
                                display:
                                  latestjobs.service_offered_by === "Company"
                                    ? ""
                                    : "none",
                              }}
                            >
                              <div>
                                <Typography
                                  className="LocalJobsNameAlignment"
                                  gutterBottom
                                  sx={{
                                    fontWeight: 600,
                                    fontSize: "14px",
                                    mr: 1,
                                  }}
                                >
                                  <text>Company/Shop Name</text>
                                  <text>:</text>
                                </Typography>
                              </div>
                              <div>
                                <Grid lg={12} md={12} sm={12} xs={12}>
                                  <Typography
                                    gutterBottom
                                    sx={{
                                      fontWeight: 600,
                                      fontSize: "14px",
                                      wordWrap: "break-word",
                                    }}
                                    color="text.secondary"
                                  >
                                    {latestjobs.shop_name}
                                  </Typography>
                                </Grid>
                              </div>
                            </div>
                            <div
                              className="LocalJobsAlignment"
                              style={{
                                display:
                                  latestjobs.service_offered_by === "Company"
                                    ? ""
                                    : "none",
                              }}
                            >
                              <div>
                                <Typography
                                  className="LocalJobsNameAlignment"
                                  gutterBottom
                                  sx={{
                                    fontWeight: 600,
                                    fontSize: "14px",
                                    mr: 1,
                                  }}
                                >
                                  <text>Company/Shop Address</text>
                                  <text>:</text>
                                </Typography>
                              </div>
                              <div>
                                <Grid lg={12} md={12} sm={12} xs={12}>
                                  <Typography
                                    gutterBottom
                                    sx={{
                                      fontWeight: 600,
                                      fontSize: "14px",
                                      wordWrap: "break-word",
                                    }}
                                    color="text.secondary"
                                  >
                                    {latestjobs.shop_address}
                                  </Typography>
                                </Grid>
                              </div>
                            </div>
                          </>
                        ) : null}
                        {servicetype === "Local Jobs" ? (
                          <>
                            <div className="LocalJobsAlignment">
                              <div>
                                <Typography
                                  className="LocalJobsNameAlignment"
                                  gutterBottom
                                  sx={{
                                    fontWeight: 600,
                                    fontSize: "14px",
                                    mr: 1,
                                  }}
                                >
                                  <text>Company/Shop Name</text>
                                  <text>:</text>
                                </Typography>
                              </div>
                              <div>
                                <Grid lg={12} md={12} sm={12} xs={12}>
                                  <Typography
                                    gutterBottom
                                    sx={{
                                      fontWeight: 600,
                                      fontSize: "14px",
                                      wordWrap: "break-word",
                                    }}
                                    color="text.secondary"
                                  >
                                    {latestjobs.shop_name}
                                  </Typography>
                                </Grid>
                              </div>
                            </div>
                            <div className="LocalJobsAlignment">
                              <div>
                                <Typography
                                  className="LocalJobsNameAlignment"
                                  gutterBottom
                                  sx={{
                                    fontWeight: 600,
                                    fontSize: "14px",
                                    mr: 1,
                                  }}
                                >
                                  <text>Company/Shop Address</text>
                                  <text>:</text>
                                </Typography>
                              </div>
                              <div>
                                <Typography
                                  gutterBottom
                                  sx={{ fontWeight: 600, fontSize: "14px" }}
                                  color="text.secondary"
                                >
                                  {latestjobs.shop_address}
                                </Typography>
                              </div>
                            </div>{" "}
                          </>
                        ) : null}
                        <div className="LocalJobsAlignment">
                          <div>
                            <Typography
                              className="LocalJobsNameAlignment"
                              gutterBottom
                              sx={{
                                fontWeight: 600,
                                fontSize: "14px",
                                mr: 1,
                              }}
                            >
                              <text>Area/Street Name</text>
                              <text>:</text>
                            </Typography>
                          </div>
                          <div>
                            <Typography
                              gutterBottom
                              sx={{ fontWeight: 600, fontSize: "14px" }}
                              color="text.secondary"
                            >
                              {latestjobs.area_street_name}
                            </Typography>
                          </div>
                        </div>
                        <div className="LocalJobsAlignment">
                          <div>
                            <Typography
                              className="LocalJobsNameAlignment"
                              gutterBottom
                              sx={{
                                fontWeight: 600,
                                fontSize: "14px",
                                mr: 1,
                              }}
                            >
                              <text>Post Office Name</text>
                              <text>:</text>
                            </Typography>
                          </div>
                          <div>
                            <Typography
                              gutterBottom
                              sx={{ fontWeight: 600, fontSize: "14px" }}
                              color="text.secondary"
                            >
                              {latestjobs.village_town_name}
                            </Typography>
                          </div>
                        </div>
                        <div className="LocalJobsAlignment">
                          <div>
                            <Typography
                              className="LocalJobsNameAlignment"
                              gutterBottom
                              sx={{
                                fontWeight: 600,
                                fontSize: "14px",
                                mr: 1,
                              }}
                            >
                              <text>Pincode</text>
                              <text>:</text>
                            </Typography>
                          </div>
                          <div>
                            <Typography
                              gutterBottom
                              sx={{ fontWeight: 600, fontSize: "14px" }}
                              color="text.secondary"
                            >
                              {latestjobs.pincode}
                            </Typography>
                          </div>
                        </div>
                        {servicetype === "Local Jobs" ? (
                          <>
                            <div
                              className="LocalJobsAlignment"
                              style={{
                                display:
                                  latestjobs.landline_number === null ||
                                  latestjobs.landline_number === undefined
                                    ? "none"
                                    : "",
                              }}
                            >
                              <div>
                                <Typography
                                  className="LocalJobsNameAlignment"
                                  gutterBottom
                                  sx={{
                                    fontWeight: 600,
                                    fontSize: "14px",
                                    mr: 1,
                                  }}
                                >
                                  <text>Landline Number</text>
                                  <text>:</text>
                                </Typography>
                              </div>
                              <div>
                                <Typography
                                  gutterBottom
                                  sx={{
                                    fontWeight: 600,
                                    fontSize: "14px",
                                  }}
                                  color="text.secondary"
                                >
                                  {latestjobs.landline_number}
                                </Typography>
                              </div>
                            </div>
                            <div className="LocalJobsAlignment">
                              <div>
                                <Typography
                                  className="LocalJobsNameAlignment"
                                  gutterBottom
                                  sx={{
                                    fontWeight: 600,
                                    fontSize: "14px",
                                    mr: 1,
                                  }}
                                >
                                  <text>Salary Range</text>
                                  <text>:</text>
                                </Typography>
                              </div>
                              <div>
                                {(latestjobs.salary_range_min === null &&
                                  latestjobs.salary_range_max === null) ||
                                (latestjobs.salary_range_min === undefined &&
                                  latestjobs.salary_range_max === undefined) ? (
                                  <Typography
                                    gutterBottom
                                    sx={{
                                      fontWeight: 600,
                                      fontSize: "14px",
                                      backgroundColor: "#22668a",
                                      color: "white",
                                      padding: "0px 5px",
                                      borderRadius: "5px",
                                    }}
                                    color="text.secondary"
                                  >
                                    No Salary Range
                                  </Typography>
                                ) : (
                                  <Typography
                                    gutterBottom
                                    sx={{
                                      fontWeight: 600,
                                      fontSize: "14px",
                                      backgroundColor: "#22668a",
                                      color: "white",
                                      padding: "0px 5px",
                                      textTransform: "uppercase",
                                      borderRadius: "5px",
                                    }}
                                    color="text.secondary"
                                  >
                                    {latestjobs.salary_range_min} -{" "}
                                    {latestjobs.salary_range_max}
                                  </Typography>
                                )}
                              </div>
                            </div>
                          </>
                        ) : null}
                        <div className="LocalJobsAlignment">
                          <div>
                            <Typography
                              className="LocalJobsNameAlignment"
                              gutterBottom
                              sx={{
                                fontWeight: 600,
                                fontSize: "14px",
                                mr: 1,
                              }}
                            >
                              <text>Contact Person Name</text>
                              <text>:</text>
                            </Typography>
                          </div>
                          <div>
                            <Grid lg={12} md={12} sm={12} xs={12}>
                              <Typography
                                gutterBottom
                                sx={{
                                  fontWeight: 600,
                                  fontSize: "14px",
                                  wordWrap: "break-word",
                                }}
                                color="text.secondary"
                              >
                                {latestjobs.contact_person_name}
                              </Typography>
                            </Grid>
                          </div>
                        </div>
                        <div className="LocalJobsAlignment">
                          <div>
                            <Typography
                              className="LocalJobsNameAlignment"
                              gutterBottom
                              sx={{
                                fontWeight: 600,
                                fontSize: "14px",
                                mr: 1,
                              }}
                            >
                              <text>Contact Number</text>
                              <text>:</text>
                            </Typography>
                          </div>
                          <div>
                            <Typography
                              gutterBottom
                              disabled
                              sx={{
                                fontWeight: 600,
                                fontSize: "14px",
                                backgroundColor: "#22668a",
                                color: "white",
                                padding: "0px 5px",
                                filter:
                                  servicetype === "Local Services" &&
                                  getAdminSetting &&getprovideridContact ===""&&
                                  provideridlocal != getprovideridContact &&
                                  sericeIdArray.includes(latestjobs._id) <= 0
                                    ? "blur(4px)"
                                    : null,
                                textTransform: "uppercase",
                                borderRadius: "5px",
                              }}
                              color="text.secondary"
                            >
                              {servicetype === "Local Services" &&
                                          getAdminSetting &&getprovideridContact === ""&&
                                          provideridlocal != getprovideridContact &&
                                          sericeIdArray.includes(
                                            latestjobs._id
                                          ) <= 0?"xxxxxxxx":latestjobs.contact_number}
                            </Typography>
                          </div>
                        </div>
                        {servicetype === "Local Jobs" ? (
                          <div className="LocalJobsAlignment">
                            <div>
                              <Typography
                                className="LocalJobsNameAlignment"
                                gutterBottom
                                sx={{
                                  fontWeight: 600,
                                  fontSize: "14px",
                                  mr: 1,
                                }}
                              >
                                <text>Job Need Type</text>
                                <text>:</text>
                              </Typography>
                            </div>
                            <div>
                              <Grid lg={12} md={12} sm={12} xs={12}>
                                <Typography
                                  gutterBottom
                                  sx={{
                                    fontWeight: 600,
                                    fontSize: "14px",
                                    wordWrap: "break-word",
                                  }}
                                  color="text.secondary"
                                >
                                  {latestjobs.job_need_type}
                                </Typography>
                              </Grid>
                            </div>
                          </div>
                        ) : null}
                        {servicetype === "Local Services" ? (
                          <div className="LocalJobsAlignment">
                            <div>
                              <Typography
                                className="LocalJobsNameAlignment"
                                gutterBottom
                                sx={{
                                  fontWeight: 600,
                                  fontSize: "14px",
                                  mr: 1,
                                }}
                              >
                                <text>Service Need Type</text>
                                <text>:</text>
                              </Typography>
                            </div>
                            <div>
                              <Grid lg={12} md={12} sm={12} xs={12}>
                                <Typography
                                  gutterBottom
                                  sx={{
                                    fontWeight: 600,
                                    fontSize: "14px",
                                    wordWrap: "break-word",
                                  }}
                                  color="text.secondary"
                                >
                                  {latestjobs.need_type}
                                </Typography>
                              </Grid>
                            </div>
                          </div>
                        ) : null}
                        {servicetype === "Service Providers" ? (
                          <div className="LocalJobsAlignment">
                            <div>
                              <Typography
                                className="LocalJobsNameAlignment"
                                gutterBottom
                                sx={{
                                  fontWeight: 600,
                                  fontSize: "14px",
                                  mr: 1,
                                }}
                              >
                                <text>Email ID</text>
                                <text>:</text>
                              </Typography>
                            </div>
                            <div>
                              <Grid lg={12} md={12} sm={12} xs={12}>
                                <Typography
                                  gutterBottom
                                  sx={{
                                    fontWeight: 600,
                                    fontSize: "14px",
                                    wordWrap: "break-word",
                                  }}
                                  color="text.secondary"
                                >
                                  {latestjobs.email_id}
                                </Typography>
                              </Grid>
                            </div>
                          </div>
                        ) : null}
                        {servicetype === "Local Jobs" ? (
                          <>
                            <div className="LocalJobsAlignment">
                              <div>
                                <Typography
                                  className="LocalJobsNameAlignment"
                                  gutterBottom
                                  sx={{
                                    fontWeight: 600,
                                    fontSize: "14px",
                                    mr: 1,
                                  }}
                                >
                                  <text>Number of persons</text>
                                  <text>:</text>
                                </Typography>
                              </div>
                              <div>
                                <Typography
                                  gutterBottom
                                  sx={{ fontWeight: 600, fontSize: "14px" }}
                                  color="text.secondary"
                                >
                                  {latestjobs.number_of_persons}
                                </Typography>
                              </div>
                            </div>
                            <div className="LocalJobsAlignment">
                              <div>
                                <Typography
                                  className="LocalJobsNameAlignment"
                                  gutterBottom
                                  sx={{
                                    fontWeight: 600,
                                    fontSize: "14px",
                                    mr: 1,
                                  }}
                                >
                                  <text>Expiry Date Range</text>
                                  <text>:</text>
                                </Typography>
                              </div>
                              <div>
                                <Typography
                                  gutterBottom
                                  sx={{ fontWeight: 600, fontSize: "14px" }}
                                  color="text.secondary"
                                >
                                  {latestjobs.expiry_date_from} -{" "}
                                  {latestjobs.expiry_date_to}
                                </Typography>
                              </div>
                            </div>
                          </>
                        ) : null}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          marginTop: "5px",
                        }}
                      >
                        <DescriptionIcon
                          fontSize="small"
                          sx={{
                            color: "rgb(145 145 145)",
                          }}
                        />
                        {servicetype === "Service Providers" ? (
                          <Typography
                            gutterBottom
                            sx={{
                              fontWeight: 600,
                              fontSize: "14px",
                              ml: 0.5,
                              mt: -1.7,
                            }}
                            color="text.secondary"
                            dangerouslySetInnerHTML={{
                              __html: renderParagraphs(latestjobs.remarks),
                            }}
                          />
                        ) : servicetype === "Local Services" ? (
                          <Typography
                            gutterBottom
                            sx={{
                              fontWeight: 600,
                              fontSize: "14px",
                              ml: 0.5,
                              mt: -1.7,
                            }}
                            color="text.secondary"
                            dangerouslySetInnerHTML={{
                              __html: renderParagraphs(
                                latestjobs.job_description
                              ),
                            }}
                          />
                        ) : (
                          <Typography
                            gutterBottom
                            sx={{
                              fontWeight: 600,
                              fontSize: "14px",
                              ml: 0.5,
                            }}
                            color="text.secondary"
                          >
                            {latestjobs.nature_of_job}
                          </Typography>
                        )}
                      </div>
                    </CardContent>

                    <div>
                      <CardActions
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          width: "99%",
                          marginTop: "10px",
                        }}
                      >
                        {servicetype === "Local Services" ? (
                          <Button
                            onClick={() => {
                              setAssingedDetails("");
                              setGetContact("");
                              setGetProviderId("")
                              setAssingedDetails(
                                ...[
                                  {
                                    assign_provider_id:
                                      latestjobs.assign_provider_id,
                                    assign_provider_email:
                                      latestjobs.assign_provider_email,
                                    assign_provider_name:
                                      latestjobs.assign_provider_name,
                                    assigned_date: latestjobs.assigned_date,
                                  },
                                ]
                              );
                              setGetContact({
                                ...getcontact,
                                contact: latestjobs.contact_number,
                                id: latestjobs._id,
                                job: "urgentJobs",
                                job_id: latestjobs.job_id,
                                assign_provider_id:
                                  latestjobs.assign_provider_id,
                                CompanyAddress: latestjobs.shop_address,
                                companyName: latestjobs.shop_address,
                                place_of_service: latestjobs.place_of_service,
                              });
                              setBuyService(true);
                            }}
                            sx={{
                              ml: 1,
                              mr: 1,
                            }}
                            disabled={
                              latestjobs.status === "Assigned" ||(provideridlocal != "" &&
                              sericeIdArray.includes(
                                latestjobs._id
                              ) > 0)? true : false
                            }
                            variant="contained"
                            color="success"
                            size="small"
                          >
                            View Contact
                          </Button>
                        ) : null}
                        <Button
                          onClick={() => {
                            moreDetails1(
                              servicetype === "Service Providers"
                                ? latestjobs.remarks
                                : servicetype === "Local Jobs"
                                ? latestjobs.nature_of_job
                                : latestjobs.job_description
                            );
                          }}
                          sx={{
                            ml: 1,
                            mr: 1,
                          }}
                          variant="outlined"
                          color="success"
                          size="small"
                        >
                          View More
                        </Button>

                        {latestjobs.status === "InActive" ||
                        latestjobs.status === "Closed" ? (
                          <Button disabled variant="contained" size="small">
                            Send Message
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            size="small"
                            onClick={() => {}}
                          >
                            Send Message
                          </Button>
                        )}
                      </CardActions>
                    </div>
                  </div>
                </Card>
              ) : null}
              {jobDetails.length !== 0
                ? searchdata === "" || searchdata.length === 0
                  ? jobDetails
                      .slice(0, rejectedPagination)
                      .map((jobs, index) => (
                        <Card
                          className="recommendedJob-InnerCard"
                          key={jobs._id}
                          sx={{ mt: 2 }}
                        >
                          <div className="ApplyCard">
                            <CardContent>
                              <Grid lg={12} md={12} sm={12} xs={12}>
                                <Typography
                                  gutterBottom
                                  sx={{
                                    fontWeight: 600,
                                    fontSize: "26px",
                                    wordWrap: "break-word",
                                    lineHeight: "2em",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    transition: "0.5s",
                                    backgroundColor: "#22668a",
                                    color: "white",
                                    padding: "0px 5px",
                                    textTransform: "uppercase",
                                    borderRadius: "5px",
                                  }}
                                  className="nameTitleServiceProvider"
                                >
                                  {servicetype === "Service Providers"
                                    ? jobs.service_name
                                    : servicetype === "Local Services"
                                    ? jobs.type_of_service
                                    : jobs.job_title}
                                </Typography>
                              </Grid>
                              <div className="mobileDeviceLocalJobs">
                                {servicetype === "Local Services" ? (
                                  <>
                                    <div className="LocalJobsAlignment">
                                      <div>
                                        <Typography
                                          className="LocalJobsNameAlignment"
                                          gutterBottom
                                          sx={{
                                            fontWeight: 600,
                                            fontSize: "14px",
                                            mr: 1,
                                          }}
                                        >
                                          <text>Place of service</text>
                                          <text>:</text>
                                        </Typography>
                                      </div>
                                      <div>
                                        <Grid lg={12} md={12} sm={12} xs={12}>
                                          <Typography
                                            gutterBottom
                                            sx={{
                                              fontWeight: 600,
                                              fontSize: "14px",
                                              wordWrap: "break-word",
                                            }}
                                            color="text.secondary"
                                          >
                                            {jobs.place_of_service}
                                          </Typography>
                                        </Grid>
                                      </div>
                                    </div>
                                    <div
                                      className="LocalJobsAlignment"
                                      style={{
                                        display:
                                          jobs.place_of_service === "Company"
                                            ? ""
                                            : "none",
                                      }}
                                    >
                                      <div>
                                        <Typography
                                          className="LocalJobsNameAlignment"
                                          gutterBottom
                                          sx={{
                                            fontWeight: 600,
                                            fontSize: "14px",
                                            mr: 1,
                                          }}
                                        >
                                          <text>Company/Shop Name</text>
                                          <text>:</text>
                                        </Typography>
                                      </div>
                                      <div>
                                        <Grid lg={12} md={12} sm={12} xs={12}>
                                          <Typography
                                            gutterBottom
                                            disabled
                                            sx={{
                                              fontWeight: 600,
                                              fontSize: "14px",
                                              wordWrap: "break-word",
                                              filter:
                                                servicetype ===
                                                  "Local Services" &&
                                                getAdminSetting &&getprovideridContact === ""&&
                                                provideridlocal !=
                                                getprovideridContact &&
                                                sericeIdArray.includes(
                                                  jobs._id
                                                ) <= 0
                                                  ? "blur(3px)"
                                                  : null,
                                            }}
                                            color="text.secondary"
                                          >
                                            {servicetype === "Local Services" &&
                                          getAdminSetting &&getprovideridContact === ""&&
                                          provideridlocal != getprovideridContact &&
                                          sericeIdArray.includes(
                                            jobs._id
                                          ) <= 0?"xxxxxxxx":jobs.shop_name}
                                          </Typography>
                                        </Grid>
                                      </div>
                                    </div>
                                    <div
                                      className="LocalJobsAlignment"
                                      style={{
                                        display:
                                          jobs.place_of_service === "Company"
                                            ? ""
                                            : "none",
                                      }}
                                    >
                                      <div>
                                        <Typography
                                          className="LocalJobsNameAlignment"
                                          gutterBottom
                                          sx={{
                                            fontWeight: 600,
                                            fontSize: "14px",
                                            mr: 1,
                                          }}
                                        >
                                          <text>Company/Shop Address</text>
                                          <text>:</text>
                                        </Typography>
                                      </div>
                                      <div>
                                        <Grid lg={12} md={12} sm={12} xs={12}>
                                          <Typography
                                            gutterBottom
                                            disabled
                                            sx={{
                                              fontWeight: 600,
                                              fontSize: "14px",
                                              wordWrap: "break-word",
                                              filter:
                                                servicetype ===
                                                  "Local Services" &&
                                                getAdminSetting &&getprovideridContact === ""&&
                                                provideridlocal !=
                                                getprovideridContact &&
                                                sericeIdArray.includes(
                                                  jobs._id
                                                ) <= 0
                                                  ? "blur(3px)"
                                                  : null,
                                            }}
                                            color="text.secondary"
                                          >
                                            {servicetype === "Local Services" &&
                                          getAdminSetting &&getprovideridContact === ""&&
                                          provideridlocal != getprovideridContact &&
                                          sericeIdArray.includes(
                                            jobs._id
                                          ) <= 0?"xxxxxxxx":jobs.shop_address}
                                          </Typography>
                                        </Grid>
                                      </div>
                                    </div>
                                  </>
                                ) : null}
                                {servicetype === "Service Providers" ? (
                                  <>
                                    <div className="LocalJobsAlignment">
                                      <div>
                                        <Typography
                                          className="LocalJobsNameAlignment"
                                          gutterBottom
                                          sx={{
                                            fontWeight: 600,
                                            fontSize: "14px",
                                            mr: 1,
                                          }}
                                        >
                                          <text>Place of service</text>
                                          <text>:</text>
                                        </Typography>
                                      </div>
                                      <div>
                                        <Grid lg={12} md={12} sm={12} xs={12}>
                                          <Typography
                                            gutterBottom
                                            sx={{
                                              fontWeight: 600,
                                              fontSize: "14px",
                                              wordWrap: "break-word",
                                            }}
                                            color="text.secondary"
                                          >
                                            {jobs.service_offered_by}
                                          </Typography>
                                        </Grid>
                                      </div>
                                    </div>
                                    {/* <div className="LocalJobsAlignment">
                                <div>
                                  <Typography
                                    className="LocalJobsNameAlignment"
                                    gutterBottom
                                    sx={{
                                      fontWeight: 600,
                                      fontSize: "14px",
                                      mr: 1,
                                    }}
                                  >
                                    <text>Type of service</text>
                                    <text>:</text>
                                  </Typography>
                                </div>
                                <div>
                                  <Grid lg={12} md={12} sm={12} xs={12}>
                                    <Typography
                                      gutterBottom
                                      sx={{
                                        fontWeight: 600,
                                        fontSize: "14px",
                                        wordWrap: "break-word",
                                      }}
                                      color="text.secondary"
                                    >
                                      {jobs.service_name}
                                    </Typography>
                                  </Grid>
                                </div>
                              </div> */}
                                    <div
                                      className="LocalJobsAlignment"
                                      style={{
                                        display:
                                          jobs.service_offered_by === "Company"
                                            ? ""
                                            : "none",
                                      }}
                                    >
                                      <div>
                                        <Typography
                                          className="LocalJobsNameAlignment"
                                          gutterBottom
                                          sx={{
                                            fontWeight: 600,
                                            fontSize: "14px",
                                            mr: 1,
                                          }}
                                        >
                                          <text>Company/Shop Name</text>
                                          <text>:</text>
                                        </Typography>
                                      </div>
                                      <div>
                                        <Grid lg={12} md={12} sm={12} xs={12}>
                                          <Typography
                                            gutterBottom
                                            sx={{
                                              fontWeight: 600,
                                              fontSize: "14px",
                                              wordWrap: "break-word",
                                            }}
                                            color="text.secondary"
                                          >
                                            {jobs.shop_name}
                                          </Typography>
                                        </Grid>
                                      </div>
                                    </div>
                                    <div
                                      className="LocalJobsAlignment"
                                      style={{
                                        display:
                                          jobs.service_offered_by === "Company"
                                            ? ""
                                            : "none",
                                      }}
                                    >
                                      <div>
                                        <Typography
                                          className="LocalJobsNameAlignment"
                                          gutterBottom
                                          sx={{
                                            fontWeight: 600,
                                            fontSize: "14px",
                                            mr: 1,
                                          }}
                                        >
                                          <text>Company/Shop Address</text>
                                          <text>:</text>
                                        </Typography>
                                      </div>
                                      <div>
                                        <Grid lg={12} md={12} sm={12} xs={12}>
                                          <Typography
                                            gutterBottom
                                            sx={{
                                              fontWeight: 600,
                                              fontSize: "14px",
                                              wordWrap: "break-word",
                                            }}
                                            color="text.secondary"
                                          >
                                            {jobs.shop_address}
                                          </Typography>
                                        </Grid>
                                      </div>
                                    </div>
                                  </>
                                ) : null}
                                {servicetype === "Local Jobs" ? (
                                  <>
                                    <div className="LocalJobsAlignment">
                                      <div>
                                        <Typography
                                          className="LocalJobsNameAlignment"
                                          gutterBottom
                                          sx={{
                                            fontWeight: 600,
                                            fontSize: "14px",
                                            mr: 1,
                                          }}
                                        >
                                          <text>Company/Shop Name</text>
                                          <text>:</text>
                                        </Typography>
                                      </div>
                                      <div>
                                        <Grid lg={12} md={12} sm={12} xs={12}>
                                          <Typography
                                            gutterBottom
                                            sx={{
                                              fontWeight: 600,
                                              fontSize: "14px",
                                              wordWrap: "break-word",
                                            }}
                                            color="text.secondary"
                                          >
                                            {jobs.shop_name}
                                          </Typography>
                                        </Grid>
                                      </div>
                                    </div>
                                    <div className="LocalJobsAlignment">
                                      <div>
                                        <Typography
                                          className="LocalJobsNameAlignment"
                                          gutterBottom
                                          sx={{
                                            fontWeight: 600,
                                            fontSize: "14px",
                                            mr: 1,
                                          }}
                                        >
                                          <text>Company/Shop Address</text>
                                          <text>:</text>
                                        </Typography>
                                      </div>
                                      <div>
                                        <Typography
                                          gutterBottom
                                          sx={{
                                            fontWeight: 600,
                                            fontSize: "14px",
                                          }}
                                          color="text.secondary"
                                        >
                                          {jobs.shop_address}
                                        </Typography>
                                      </div>
                                    </div>{" "}
                                  </>
                                ) : null}
                                <div className="LocalJobsAlignment">
                                  <div>
                                    <Typography
                                      className="LocalJobsNameAlignment"
                                      gutterBottom
                                      sx={{
                                        fontWeight: 600,
                                        fontSize: "14px",
                                        mr: 1,
                                      }}
                                    >
                                      <text>Area/Street Name</text>
                                      <text>:</text>
                                    </Typography>
                                  </div>
                                  <div>
                                    <Typography
                                      gutterBottom
                                      sx={{ fontWeight: 600, fontSize: "14px" }}
                                      color="text.secondary"
                                    >
                                      {jobs.area_street_name}
                                    </Typography>
                                  </div>
                                </div>
                                <div className="LocalJobsAlignment">
                                  <div>
                                    <Typography
                                      className="LocalJobsNameAlignment"
                                      gutterBottom
                                      sx={{
                                        fontWeight: 600,
                                        fontSize: "14px",
                                        mr: 1,
                                      }}
                                    >
                                      <text>Post Office Name</text>
                                      <text>:</text>
                                    </Typography>
                                  </div>
                                  <div>
                                    <Typography
                                      gutterBottom
                                      sx={{ fontWeight: 600, fontSize: "14px" }}
                                      color="text.secondary"
                                    >
                                      {jobs.village_town_name}
                                    </Typography>
                                  </div>
                                </div>
                                <div className="LocalJobsAlignment">
                                  <div>
                                    <Typography
                                      className="LocalJobsNameAlignment"
                                      gutterBottom
                                      sx={{
                                        fontWeight: 600,
                                        fontSize: "14px",
                                        mr: 1,
                                      }}
                                    >
                                      <text>Pincode</text>
                                      <text>:</text>
                                    </Typography>
                                  </div>
                                  <div>
                                    <Typography
                                      gutterBottom
                                      sx={{ fontWeight: 600, fontSize: "14px" }}
                                      color="text.secondary"
                                    >
                                      {jobs.pincode}
                                    </Typography>
                                  </div>
                                </div>
                                {servicetype === "Local Jobs" ? (
                                  <>
                                    <div
                                      className="LocalJobsAlignment"
                                      style={{
                                        display:
                                          jobs.landline_number === null ||
                                          jobs.landline_number === undefined
                                            ? "none"
                                            : "",
                                      }}
                                    >
                                      <div>
                                        <Typography
                                          className="LocalJobsNameAlignment"
                                          gutterBottom
                                          sx={{
                                            fontWeight: 600,
                                            fontSize: "14px",
                                            mr: 1,
                                          }}
                                        >
                                          <text>Landline Number</text>
                                          <text>:</text>
                                        </Typography>
                                      </div>
                                      <div>
                                        <Typography
                                          gutterBottom
                                          sx={{
                                            fontWeight: 600,
                                            fontSize: "14px",
                                          }}
                                          color="text.secondary"
                                        >
                                          {jobs.landline_number}
                                        </Typography>
                                      </div>
                                    </div>
                                    <div className="LocalJobsAlignment">
                                      <div>
                                        <Typography
                                          className="LocalJobsNameAlignment"
                                          gutterBottom
                                          sx={{
                                            fontWeight: 600,
                                            fontSize: "14px",
                                            mr: 1,
                                          }}
                                        >
                                          <text>Salary Range</text>
                                          <text>:</text>
                                        </Typography>
                                      </div>
                                      <div>
                                        {(jobs.salary_range_min === null &&
                                          jobs.salary_range_max === null) ||
                                        (jobs.salary_range_min === undefined &&
                                          jobs.salary_range_max ===
                                            undefined) ? (
                                          <Typography
                                            gutterBottom
                                            sx={{
                                              fontWeight: 600,
                                              fontSize: "14px",
                                              backgroundColor: "#22668a",
                                              color: "white",
                                              padding: "0px 5px",
                                              borderRadius: "5px",
                                            }}
                                            color="text.secondary"
                                          >
                                            No Salary Range
                                          </Typography>
                                        ) : (
                                          <Typography
                                            gutterBottom
                                            sx={{
                                              fontWeight: 600,
                                              fontSize: "14px",
                                              backgroundColor: "#22668a",
                                              color: "white",
                                              padding: "0px 5px",
                                              textTransform: "uppercase",
                                              borderRadius: "5px",
                                            }}
                                            color="text.secondary"
                                          >
                                            {jobs.salary_range_min} -{" "}
                                            {jobs.salary_range_max}
                                          </Typography>
                                        )}
                                      </div>
                                    </div>
                                  </>
                                ) : null}
                                <div className="LocalJobsAlignment">
                                  <div>
                                    <Typography
                                      className="LocalJobsNameAlignment"
                                      gutterBottom
                                      sx={{
                                        fontWeight: 600,
                                        fontSize: "14px",
                                        mr: 1,
                                      }}
                                    >
                                      <text>Contact Person Name</text>
                                      <text>:</text>
                                    </Typography>
                                  </div>
                                  <div>
                                    <Grid lg={12} md={12} sm={12} xs={12}>
                                      <Typography
                                        gutterBottom
                                        sx={{
                                          fontWeight: 600,
                                          fontSize: "14px",
                                          wordWrap: "break-word",
                                        }}
                                        color="text.secondary"
                                      >
                                        {jobs.contact_person_name}
                                      </Typography>
                                    </Grid>
                                  </div>
                                </div>
                                {/* {servicetype === "Local Services" ? null : ( */}
                                <div className="LocalJobsAlignment">
                                  <div>
                                    <Typography
                                      className="LocalJobsNameAlignment"
                                      gutterBottom
                                      sx={{
                                        fontWeight: 600,
                                        fontSize: "14px",
                                        mr: 1,
                                      }}
                                    >
                                      <text>Contact Number</text>
                                      <text>:</text>
                                    </Typography>
                                  </div>
                                  <div>
                                    <Typography
                                      gutterBottom
                                      disabled
                                      sx={{
                                        fontWeight: 600,
                                        fontSize: "14px",
                                        backgroundColor: "#22668a",
                                        color: "white",
                                        filter:
                                          servicetype === "Local Services" &&
                                          getAdminSetting && getprovideridContact === ""&&
                                          provideridlocal != getprovideridContact &&
                                          sericeIdArray.includes(jobs._id) <= 0
                                            ? "blur(4px)"
                                            : null,
                                        padding: "0px 5px",
                                        textTransform: "uppercase",
                                        borderRadius: "5px",
                                      }}
                                      color="text.secondary"
                                    >
                                      {servicetype === "Local Services" &&
                                          getAdminSetting &&getprovideridContact === ""&&
                                          provideridlocal != getprovideridContact &&
                                          sericeIdArray.includes(
                                            jobs._id
                                          ) <= 0?"xxxxxxxx":jobs.contact_number}
                                    </Typography>
                                  </div>
                                </div>
                                {/* )} */}

                                {servicetype === "Local Jobs" ? (
                                  <div className="LocalJobsAlignment">
                                    <div>
                                      <Typography
                                        className="LocalJobsNameAlignment"
                                        gutterBottom
                                        sx={{
                                          fontWeight: 600,
                                          fontSize: "14px",
                                          mr: 1,
                                        }}
                                      >
                                        <text>Job Need Type</text>
                                        <text>:</text>
                                      </Typography>
                                    </div>
                                    <div>
                                      <Grid lg={12} md={12} sm={12} xs={12}>
                                        <Typography
                                          gutterBottom
                                          sx={{
                                            fontWeight: 600,
                                            fontSize: "14px",
                                            wordWrap: "break-word",
                                          }}
                                          color="text.secondary"
                                        >
                                          {jobs.job_need_type}
                                        </Typography>
                                      </Grid>
                                    </div>
                                  </div>
                                ) : null}
                                {servicetype === "Local Services" ? (
                                  <div className="LocalJobsAlignment">
                                    <div>
                                      <Typography
                                        className="LocalJobsNameAlignment"
                                        gutterBottom
                                        sx={{
                                          fontWeight: 600,
                                          fontSize: "14px",
                                          mr: 1,
                                        }}
                                      >
                                        <text>Service Need Type</text>
                                        <text>:</text>
                                      </Typography>
                                    </div>
                                    <div>
                                      <Grid lg={12} md={12} sm={12} xs={12}>
                                        <Typography
                                          gutterBottom
                                          sx={{
                                            fontWeight: 600,
                                            fontSize: "14px",
                                            wordWrap: "break-word",
                                          }}
                                          color="text.secondary"
                                        >
                                          {jobs.need_type}
                                        </Typography>
                                      </Grid>
                                    </div>
                                  </div>
                                ) : null}
                                {servicetype === "Service Providers" ? (
                                  <div className="LocalJobsAlignment">
                                    <div>
                                      <Typography
                                        className="LocalJobsNameAlignment"
                                        gutterBottom
                                        sx={{
                                          fontWeight: 600,
                                          fontSize: "14px",
                                          mr: 1,
                                        }}
                                      >
                                        <text>Email ID</text>
                                        <text>:</text>
                                      </Typography>
                                    </div>
                                    <div>
                                      <Grid lg={12} md={12} sm={12} xs={12}>
                                        <Typography
                                          gutterBottom
                                          sx={{
                                            fontWeight: 600,
                                            fontSize: "14px",
                                            wordWrap: "break-word",
                                          }}
                                          color="text.secondary"
                                        >
                                          {jobs.email_id}
                                        </Typography>
                                      </Grid>
                                    </div>
                                  </div>
                                ) : null}
                                {servicetype === "Local Jobs" ? (
                                  <>
                                    <div className="LocalJobsAlignment">
                                      <div>
                                        <Typography
                                          className="LocalJobsNameAlignment"
                                          gutterBottom
                                          sx={{
                                            fontWeight: 600,
                                            fontSize: "14px",
                                            mr: 1,
                                          }}
                                        >
                                          <text>Number of persons</text>
                                          <text>:</text>
                                        </Typography>
                                      </div>
                                      <div>
                                        <Typography
                                          gutterBottom
                                          sx={{
                                            fontWeight: 600,
                                            fontSize: "14px",
                                          }}
                                          color="text.secondary"
                                        >
                                          {jobs.number_of_persons}
                                        </Typography>
                                      </div>
                                    </div>
                                    <div className="LocalJobsAlignment">
                                      <div>
                                        <Typography
                                          className="LocalJobsNameAlignment"
                                          gutterBottom
                                          sx={{
                                            fontWeight: 600,
                                            fontSize: "14px",
                                            mr: 1,
                                          }}
                                        >
                                          <text>Expiry Date Range</text>
                                          <text>:</text>
                                        </Typography>
                                      </div>
                                      <div>
                                        <Typography
                                          gutterBottom
                                          sx={{
                                            fontWeight: 600,
                                            fontSize: "14px",
                                          }}
                                          color="text.secondary"
                                        >
                                          {jobs.expiry_date_from} -{" "}
                                          {jobs.expiry_date_to}
                                        </Typography>
                                      </div>
                                    </div>
                                  </>
                                ) : null}
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  marginTop: "5px",
                                }}
                              >
                                <DescriptionIcon
                                  fontSize="small"
                                  sx={{
                                    color: "rgb(145 145 145)",
                                  }}
                                />
                                {servicetype === "Service Providers" ? (
                                  <Typography
                                    gutterBottom
                                    sx={{
                                      fontWeight: 600,
                                      fontSize: "14px",
                                      ml: 0.5,
                                      mt: -1.7,
                                    }}
                                    color="text.secondary"
                                    dangerouslySetInnerHTML={{
                                      __html: renderParagraphs(jobs.remarks),
                                    }}
                                  />
                                ) : servicetype === "Local Services" ? (
                                  <Typography
                                    gutterBottom
                                    sx={{
                                      fontWeight: 600,
                                      fontSize: "14px",
                                      ml: 0.5,
                                      mt: -1.7,
                                    }}
                                    color="text.secondary"
                                    dangerouslySetInnerHTML={{
                                      __html: renderParagraphs(
                                        jobs.job_description
                                      ),
                                    }}
                                  />
                                ) : (
                                  <Typography
                                    gutterBottom
                                    sx={{
                                      fontWeight: 600,
                                      fontSize: "14px",
                                      ml: 0.5,
                                    }}
                                    color="text.secondary"
                                  >
                                    {jobs.nature_of_job}
                                  </Typography>
                                )}
                              </div>
                            </CardContent>

                            <div>
                              <CardActions
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  width: "99%",
                                  marginTop: "10px",
                                }}
                              >
                                {servicetype === "Local Services" ? (
                                  <Button
                                    onClick={() => {
                                      setAssingedDetails("");
                                      setGetContact("");
                                      setGetProviderId("")
                                      setAssingedDetails(
                                        ...[
                                          {
                                            assign_provider_id:
                                              jobs.assign_provider_id,
                                            assign_provider_email:
                                              jobs.assign_provider_email,
                                            assign_provider_name:
                                              jobs.assign_provider_name,
                                            assigned_date: jobs.assigned_date,
                                          },
                                        ]
                                      );
                                      setGetContact({
                                        ...getcontact,
                                        contact: jobs.contact_number,
                                        id: jobs._id,
                                        getfrom: "searchbytitle",
                                        assign_provider_id:
                                          jobs.assign_provider_id,
                                        CompanyAddress: jobs.shop_address,
                                        companyName: jobs.shop_address,
                                        place_of_service: jobs.place_of_service,
                                      });
                                      setBuyService(true);
                                    }}
                                    sx={{
                                      ml: 1,
                                      mr: 1,
                                    }}
                                    disabled={
                                      jobs.status === "Assigned" ||  (provideridlocal != "" &&
                                      sericeIdArray.includes(
                                        jobs._id
                                      ) > 0)? true : false
                                    }
                                    variant="contained"
                                    color="success"
                                    size="small"
                                  >
                                    View Contact
                                  </Button>
                                ) : null}
                                <Button
                                  onClick={() => {
                                    moreDetails(
                                      servicetype === "Service Providers"
                                        ? jobs.remarks
                                        : servicetype === "Local Jobs"
                                        ? jobs.nature_of_job
                                        : jobs.job_description
                                    );
                                  }}
                                  sx={{
                                    ml: 1,
                                    mr: 1,
                                  }}
                                  variant="outlined"
                                  color="success"
                                  size="small"
                                >
                                  View More
                                </Button>

                                {jobs.status === "InActive" ||
                                jobs.status === "Closed" ? (
                                  <Button
                                    disabled
                                    variant="contained"
                                    size="small"
                                  >
                                    Send Message
                                  </Button>
                                ) : (
                                  <Button
                                    variant="contained"
                                    size="small"
                                    onClick={() => {}}
                                  >
                                    Send Message
                                  </Button>
                                )}
                              </CardActions>
                            </div>
                          </div>
                        </Card>
                      ))
                  : searchResultDatas &&
                    searchResultDatas
                      .slice(0, rejectedPagination)
                      .map((jobs, index) => (
                        <Card
                          className="recommendedJob-InnerCard"
                          key={jobs._id}
                          sx={{ mt: 2 }}
                        >
                          <div className="ApplyCard">
                            <CardContent>
                              <Grid lg={12} md={12} sm={12} xs={12}>
                                <Typography
                                  gutterBottom
                                  sx={{
                                    fontWeight: 600,
                                    fontSize: "26px",
                                    wordWrap: "break-word",
                                    lineHeight: "2em",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    transition: "0.5s",
                                    backgroundColor: "#22668a",
                                    color: "white",
                                    padding: "0px 5px",
                                    textTransform: "uppercase",
                                    borderRadius: "5px",
                                  }}
                                  className="nameTitleServiceProvider"
                                >
                                  {servicetype === "Service Providers"
                                    ? jobs.service_name
                                    : servicetype === "Local Services"
                                    ? jobs.type_of_service
                                    : jobs.job_title}
                                </Typography>
                              </Grid>
                              <div className="mobileDeviceLocalJobs">
                                {servicetype === "Local Services" ? (
                                  <>
                                    <div className="LocalJobsAlignment">
                                      <div>
                                        <Typography
                                          className="LocalJobsNameAlignment"
                                          gutterBottom
                                          sx={{
                                            fontWeight: 600,
                                            fontSize: "14px",
                                            mr: 1,
                                          }}
                                        >
                                          <text>Place of service</text>
                                          <text>:</text>
                                        </Typography>
                                      </div>
                                      <div>
                                        <Grid lg={12} md={12} sm={12} xs={12}>
                                          <Typography
                                            gutterBottom
                                            sx={{
                                              fontWeight: 600,
                                              fontSize: "14px",
                                              wordWrap: "break-word",
                                            }}
                                            color="text.secondary"
                                          >
                                            {jobs.place_of_service}
                                          </Typography>
                                        </Grid>
                                      </div>
                                    </div>
                                    <div
                                      className="LocalJobsAlignment"
                                      style={{
                                        display:
                                          jobs.place_of_service === "Company"
                                            ? ""
                                            : "none",
                                      }}
                                    >
                                      <div>
                                        <Typography
                                          className="LocalJobsNameAlignment"
                                          gutterBottom
                                          sx={{
                                            fontWeight: 600,
                                            fontSize: "14px",
                                            mr: 1,
                                          }}
                                        >
                                          <text>Company/Shop Name</text>
                                          <text>:</text>
                                        </Typography>
                                      </div>
                                      <div>
                                        <Grid lg={12} md={12} sm={12} xs={12}>
                                          <Typography
                                            gutterBottom
                                            disabled
                                            sx={{
                                              fontWeight: 600,
                                              fontSize: "14px",
                                              wordWrap: "break-word",
                                              filter:
                                                servicetype ===
                                                  "Local Services" &&
                                                getAdminSetting &&getprovideridContact === ""&&
                                                provideridlocal !=
                                                getprovideridContact &&
                                                sericeIdArray.includes(
                                                  jobs._id
                                                ) <= 0
                                                  ? "blur(3px)"
                                                  : null,
                                            }}
                                            color="text.secondary"
                                          >
                                            {servicetype === "Local Services" &&
                                          getAdminSetting &&getprovideridContact === ""&&
                                          provideridlocal != getprovideridContact &&
                                          sericeIdArray.includes(
                                            jobs._id
                                          ) <= 0?"xxxxxxxx":jobs.shop_name}
                                          </Typography>
                                        </Grid>
                                      </div>
                                    </div>
                                    <div
                                      className="LocalJobsAlignment"
                                      style={{
                                        display:
                                          jobs.place_of_service === "Company"
                                            ? ""
                                            : "none",
                                      }}
                                    >
                                      <div>
                                        <Typography
                                          className="LocalJobsNameAlignment"
                                          gutterBottom
                                          sx={{
                                            fontWeight: 600,
                                            fontSize: "14px",
                                            mr: 1,
                                          }}
                                        >
                                          <text>Company/Shop Address</text>
                                          <text>:</text>
                                        </Typography>
                                      </div>
                                      <div>
                                        <Grid lg={12} md={12} sm={12} xs={12}>
                                          <Typography
                                            gutterBottom
                                            disabled
                                            sx={{
                                              fontWeight: 600,
                                              fontSize: "14px",
                                              wordWrap: "break-word",
                                              filter:
                                                servicetype ===
                                                  "Local Services" &&
                                                getAdminSetting &&getprovideridContact === ""&&
                                                provideridlocal !=
                                                getprovideridContact &&
                                                sericeIdArray.includes(
                                                  jobs._id
                                                ) <= 0
                                                  ? "blur(3px)"
                                                  : null,
                                            }}
                                            color="text.secondary"
                                          >
                                            {servicetype === "Local Services" &&
                                          getAdminSetting &&getprovideridContact === ""&&
                                          provideridlocal != getprovideridContact &&
                                          sericeIdArray.includes(
                                            jobs._id
                                          ) <= 0?"xxxxxxxx":jobs.shop_address}
                                          </Typography>
                                        </Grid>
                                      </div>
                                    </div>
                                  </>
                                ) : null}
                                {servicetype === "Service Providers" ? (
                                  <>
                                    <div className="LocalJobsAlignment">
                                      <div>
                                        <Typography
                                          className="LocalJobsNameAlignment"
                                          gutterBottom
                                          sx={{
                                            fontWeight: 600,
                                            fontSize: "14px",
                                            mr: 1,
                                          }}
                                        >
                                          <text>Place of service</text>
                                          <text>:</text>
                                        </Typography>
                                      </div>
                                      <div>
                                        <Grid lg={12} md={12} sm={12} xs={12}>
                                          <Typography
                                            gutterBottom
                                            sx={{
                                              fontWeight: 600,
                                              fontSize: "14px",
                                              wordWrap: "break-word",
                                            }}
                                            color="text.secondary"
                                          >
                                            {jobs.service_offered_by}
                                          </Typography>
                                        </Grid>
                                      </div>
                                    </div>
                                    {/* <div className="LocalJobsAlignment">
                                <div>
                                  <Typography
                                    className="LocalJobsNameAlignment"
                                    gutterBottom
                                    sx={{
                                      fontWeight: 600,
                                      fontSize: "14px",
                                      mr: 1,
                                    }}
                                  >
                                    <text>Type of service</text>
                                    <text>:</text>
                                  </Typography>
                                </div>
                                <div>
                                  <Grid lg={12} md={12} sm={12} xs={12}>
                                    <Typography
                                      gutterBottom
                                      sx={{
                                        fontWeight: 600,
                                        fontSize: "14px",
                                        wordWrap: "break-word",
                                      }}
                                      color="text.secondary"
                                    >
                                      {jobs.service_name}
                                    </Typography>
                                  </Grid>
                                </div>
                              </div> */}
                                    <div
                                      className="LocalJobsAlignment"
                                      style={{
                                        display:
                                          jobs.service_offered_by === "Company"
                                            ? ""
                                            : "none",
                                      }}
                                    >
                                      <div>
                                        <Typography
                                          className="LocalJobsNameAlignment"
                                          gutterBottom
                                          sx={{
                                            fontWeight: 600,
                                            fontSize: "14px",
                                            mr: 1,
                                          }}
                                        >
                                          <text>Company/Shop Name</text>
                                          <text>:</text>
                                        </Typography>
                                      </div>
                                      <div>
                                        <Grid lg={12} md={12} sm={12} xs={12}>
                                          <Typography
                                            gutterBottom
                                            sx={{
                                              fontWeight: 600,
                                              fontSize: "14px",
                                              wordWrap: "break-word",
                                            }}
                                            color="text.secondary"
                                          >
                                            {jobs.shop_name}
                                          </Typography>
                                        </Grid>
                                      </div>
                                    </div>
                                    <div
                                      className="LocalJobsAlignment"
                                      style={{
                                        display:
                                          jobs.service_offered_by === "Company"
                                            ? ""
                                            : "none",
                                      }}
                                    >
                                      <div>
                                        <Typography
                                          className="LocalJobsNameAlignment"
                                          gutterBottom
                                          sx={{
                                            fontWeight: 600,
                                            fontSize: "14px",
                                            mr: 1,
                                          }}
                                        >
                                          <text>Company/Shop Address</text>
                                          <text>:</text>
                                        </Typography>
                                      </div>
                                      <div>
                                        <Grid lg={12} md={12} sm={12} xs={12}>
                                          <Typography
                                            gutterBottom
                                            sx={{
                                              fontWeight: 600,
                                              fontSize: "14px",
                                              wordWrap: "break-word",
                                            }}
                                            color="text.secondary"
                                          >
                                            {jobs.shop_address}
                                          </Typography>
                                        </Grid>
                                      </div>
                                    </div>
                                  </>
                                ) : null}
                                {servicetype === "Local Jobs" ? (
                                  <>
                                    <div className="LocalJobsAlignment">
                                      <div>
                                        <Typography
                                          className="LocalJobsNameAlignment"
                                          gutterBottom
                                          sx={{
                                            fontWeight: 600,
                                            fontSize: "14px",
                                            mr: 1,
                                          }}
                                        >
                                          <text>Company/Shop Name</text>
                                          <text>:</text>
                                        </Typography>
                                      </div>
                                      <div>
                                        <Grid lg={12} md={12} sm={12} xs={12}>
                                          <Typography
                                            gutterBottom
                                            sx={{
                                              fontWeight: 600,
                                              fontSize: "14px",
                                              wordWrap: "break-word",
                                            }}
                                            color="text.secondary"
                                          >
                                            {jobs.shop_name}
                                          </Typography>
                                        </Grid>
                                      </div>
                                    </div>
                                    <div className="LocalJobsAlignment">
                                      <div>
                                        <Typography
                                          className="LocalJobsNameAlignment"
                                          gutterBottom
                                          sx={{
                                            fontWeight: 600,
                                            fontSize: "14px",
                                            mr: 1,
                                          }}
                                        >
                                          <text>Company/Shop Address</text>
                                          <text>:</text>
                                        </Typography>
                                      </div>
                                      <div>
                                        <Typography
                                          gutterBottom
                                          sx={{
                                            fontWeight: 600,
                                            fontSize: "14px",
                                          }}
                                          color="text.secondary"
                                        >
                                          {jobs.shop_address}
                                        </Typography>
                                      </div>
                                    </div>{" "}
                                  </>
                                ) : null}
                                <div className="LocalJobsAlignment">
                                  <div>
                                    <Typography
                                      className="LocalJobsNameAlignment"
                                      gutterBottom
                                      sx={{
                                        fontWeight: 600,
                                        fontSize: "14px",
                                        mr: 1,
                                      }}
                                    >
                                      <text>Area/Street Name</text>
                                      <text>:</text>
                                    </Typography>
                                  </div>
                                  <div>
                                    <Typography
                                      gutterBottom
                                      sx={{ fontWeight: 600, fontSize: "14px" }}
                                      color="text.secondary"
                                    >
                                      {jobs.area_street_name}
                                    </Typography>
                                  </div>
                                </div>
                                <div className="LocalJobsAlignment">
                                  <div>
                                    <Typography
                                      className="LocalJobsNameAlignment"
                                      gutterBottom
                                      sx={{
                                        fontWeight: 600,
                                        fontSize: "14px",
                                        mr: 1,
                                      }}
                                    >
                                      <text>Post Office Name</text>
                                      <text>:</text>
                                    </Typography>
                                  </div>
                                  <div>
                                    <Typography
                                      gutterBottom
                                      sx={{ fontWeight: 600, fontSize: "14px" }}
                                      color="text.secondary"
                                    >
                                      {jobs.village_town_name}
                                    </Typography>
                                  </div>
                                </div>
                                <div className="LocalJobsAlignment">
                                  <div>
                                    <Typography
                                      className="LocalJobsNameAlignment"
                                      gutterBottom
                                      sx={{
                                        fontWeight: 600,
                                        fontSize: "14px",
                                        mr: 1,
                                      }}
                                    >
                                      <text>Pincode</text>
                                      <text>:</text>
                                    </Typography>
                                  </div>
                                  <div>
                                    <Typography
                                      gutterBottom
                                      sx={{ fontWeight: 600, fontSize: "14px" }}
                                      color="text.secondary"
                                    >
                                      {jobs.pincode}
                                    </Typography>
                                  </div>
                                </div>
                                {servicetype === "Local Jobs" ? (
                                  <>
                                    <div
                                      className="LocalJobsAlignment"
                                      style={{
                                        display:
                                          jobs.landline_number === null ||
                                          jobs.landline_number === undefined
                                            ? "none"
                                            : "",
                                      }}
                                    >
                                      <div>
                                        <Typography
                                          className="LocalJobsNameAlignment"
                                          gutterBottom
                                          sx={{
                                            fontWeight: 600,
                                            fontSize: "14px",
                                            mr: 1,
                                          }}
                                        >
                                          <text>Landline Number</text>
                                          <text>:</text>
                                        </Typography>
                                      </div>
                                      <div>
                                        <Typography
                                          gutterBottom
                                          sx={{
                                            fontWeight: 600,
                                            fontSize: "14px",
                                          }}
                                          color="text.secondary"
                                        >
                                          {jobs.landline_number}
                                        </Typography>
                                      </div>
                                    </div>
                                    <div className="LocalJobsAlignment">
                                      <div>
                                        <Typography
                                          className="LocalJobsNameAlignment"
                                          gutterBottom
                                          sx={{
                                            fontWeight: 600,
                                            fontSize: "14px",
                                            mr: 1,
                                          }}
                                        >
                                          <text>Salary Range</text>
                                          <text>:</text>
                                        </Typography>
                                      </div>
                                      <div>
                                        {(jobs.salary_range_min === null &&
                                          jobs.salary_range_max === null) ||
                                        (jobs.salary_range_min === undefined &&
                                          jobs.salary_range_max ===
                                            undefined) ? (
                                          <Typography
                                            gutterBottom
                                            sx={{
                                              fontWeight: 600,
                                              fontSize: "14px",
                                              backgroundColor: "#22668a",
                                              color: "white",
                                              padding: "0px 5px",
                                              borderRadius: "5px",
                                            }}
                                            color="text.secondary"
                                          >
                                            No Salary Range
                                          </Typography>
                                        ) : (
                                          <Typography
                                            gutterBottom
                                            sx={{
                                              fontWeight: 600,
                                              fontSize: "14px",
                                              backgroundColor: "#22668a",
                                              color: "white",
                                              padding: "0px 5px",
                                              textTransform: "uppercase",
                                              borderRadius: "5px",
                                            }}
                                            color="text.secondary"
                                          >
                                            {jobs.salary_range_min} -{" "}
                                            {jobs.salary_range_max}
                                          </Typography>
                                        )}
                                      </div>
                                    </div>
                                  </>
                                ) : null}
                                <div className="LocalJobsAlignment">
                                  <div>
                                    <Typography
                                      className="LocalJobsNameAlignment"
                                      gutterBottom
                                      sx={{
                                        fontWeight: 600,
                                        fontSize: "14px",
                                        mr: 1,
                                      }}
                                    >
                                      <text>Contact Person Name</text>
                                      <text>:</text>
                                    </Typography>
                                  </div>
                                  <div>
                                    <Grid lg={12} md={12} sm={12} xs={12}>
                                      <Typography
                                        gutterBottom
                                        sx={{
                                          fontWeight: 600,
                                          fontSize: "14px",
                                          wordWrap: "break-word",
                                        }}
                                        color="text.secondary"
                                      >
                                        {jobs.contact_person_name}
                                      </Typography>
                                    </Grid>
                                  </div>
                                </div>
                                <div className="LocalJobsAlignment">
                                  <div>
                                    <Typography
                                      className="LocalJobsNameAlignment"
                                      gutterBottom
                                      sx={{
                                        fontWeight: 600,
                                        fontSize: "14px",
                                        mr: 1,
                                      }}
                                    >
                                      <text>Contact Number</text>
                                      <text>:</text>
                                    </Typography>
                                  </div>
                                  <div>
                                    <Typography
                                      gutterBottom
                                      disabled
                                      sx={{
                                        fontWeight: 600,
                                        fontSize: "14px",
                                        backgroundColor: "#22668a",
                                        color: "white",
                                        padding: "0px 5px",
                                        filter:
                                          servicetype === "Local Services" &&
                                          getAdminSetting &&getprovideridContact=== ""&&
                                          provideridlocal != getprovideridContact &&
                                          sericeIdArray.includes(jobs._id) <= 0
                                            ? "blur(4px)"
                                            : null,
                                        textTransform: "uppercase",
                                        borderRadius: "5px",
                                      }}
                                      color="text.secondary"
                                    >
                                      {servicetype === "Local Services" &&
                                          getAdminSetting &&getprovideridContact === ""&&
                                          provideridlocal != getprovideridContact &&
                                          sericeIdArray.includes(
                                            jobs._id
                                          ) <= 0?"xxxxxxxx":jobs.contact_number}
                                    </Typography>
                                  </div>
                                </div>
                                {servicetype === "Local Jobs" ? (
                                  <div className="LocalJobsAlignment">
                                    <div>
                                      <Typography
                                        className="LocalJobsNameAlignment"
                                        gutterBottom
                                        sx={{
                                          fontWeight: 600,
                                          fontSize: "14px",
                                          mr: 1,
                                        }}
                                      >
                                        <text>Job Need Type</text>
                                        <text>:</text>
                                      </Typography>
                                    </div>
                                    <div>
                                      <Grid lg={12} md={12} sm={12} xs={12}>
                                        <Typography
                                          gutterBottom
                                          sx={{
                                            fontWeight: 600,
                                            fontSize: "14px",
                                            wordWrap: "break-word",
                                          }}
                                          color="text.secondary"
                                        >
                                          {jobs.job_need_type}
                                        </Typography>
                                      </Grid>
                                    </div>
                                  </div>
                                ) : null}
                                {servicetype === "Local Services" ? (
                                  <div className="LocalJobsAlignment">
                                    <div>
                                      <Typography
                                        className="LocalJobsNameAlignment"
                                        gutterBottom
                                        sx={{
                                          fontWeight: 600,
                                          fontSize: "14px",
                                          mr: 1,
                                        }}
                                      >
                                        <text>Service Need Type</text>
                                        <text>:</text>
                                      </Typography>
                                    </div>
                                    <div>
                                      <Grid lg={12} md={12} sm={12} xs={12}>
                                        <Typography
                                          gutterBottom
                                          sx={{
                                            fontWeight: 600,
                                            fontSize: "14px",
                                            wordWrap: "break-word",
                                          }}
                                          color="text.secondary"
                                        >
                                          {jobs.need_type}
                                        </Typography>
                                      </Grid>
                                    </div>
                                  </div>
                                ) : null}
                                {servicetype === "Service Providers" ? (
                                  <div className="LocalJobsAlignment">
                                    <div>
                                      <Typography
                                        className="LocalJobsNameAlignment"
                                        gutterBottom
                                        sx={{
                                          fontWeight: 600,
                                          fontSize: "14px",
                                          mr: 1,
                                        }}
                                      >
                                        <text>Email ID</text>
                                        <text>:</text>
                                      </Typography>
                                    </div>
                                    <div>
                                      <Grid lg={12} md={12} sm={12} xs={12}>
                                        <Typography
                                          gutterBottom
                                          sx={{
                                            fontWeight: 600,
                                            fontSize: "14px",
                                            wordWrap: "break-word",
                                          }}
                                          color="text.secondary"
                                        >
                                          {jobs.email_id}
                                        </Typography>
                                      </Grid>
                                    </div>
                                  </div>
                                ) : null}
                                {servicetype === "Local Jobs" ? (
                                  <>
                                    <div className="LocalJobsAlignment">
                                      <div>
                                        <Typography
                                          className="LocalJobsNameAlignment"
                                          gutterBottom
                                          sx={{
                                            fontWeight: 600,
                                            fontSize: "14px",
                                            mr: 1,
                                          }}
                                        >
                                          <text>Number of persons</text>
                                          <text>:</text>
                                        </Typography>
                                      </div>
                                      <div>
                                        <Typography
                                          gutterBottom
                                          sx={{
                                            fontWeight: 600,
                                            fontSize: "14px",
                                          }}
                                          color="text.secondary"
                                        >
                                          {jobs.number_of_persons}
                                        </Typography>
                                      </div>
                                    </div>
                                    <div className="LocalJobsAlignment">
                                      <div>
                                        <Typography
                                          className="LocalJobsNameAlignment"
                                          gutterBottom
                                          sx={{
                                            fontWeight: 600,
                                            fontSize: "14px",
                                            mr: 1,
                                          }}
                                        >
                                          <text>Expiry Date Range</text>
                                          <text>:</text>
                                        </Typography>
                                      </div>
                                      <div>
                                        <Typography
                                          gutterBottom
                                          sx={{
                                            fontWeight: 600,
                                            fontSize: "14px",
                                          }}
                                          color="text.secondary"
                                        >
                                          {jobs.expiry_date_from} -{" "}
                                          {jobs.expiry_date_to}
                                        </Typography>
                                      </div>
                                    </div>
                                  </>
                                ) : null}
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  marginTop: "5px",
                                }}
                              >
                                <DescriptionIcon
                                  fontSize="small"
                                  sx={{
                                    color: "rgb(145 145 145)",
                                  }}
                                />
                                {servicetype === "Service Providers" ? (
                                  <Typography
                                    gutterBottom
                                    sx={{
                                      fontWeight: 600,
                                      fontSize: "14px",
                                      ml: 0.5,
                                      mt: -1.7,
                                    }}
                                    color="text.secondary"
                                    dangerouslySetInnerHTML={{
                                      __html: renderParagraphs(jobs.remarks),
                                    }}
                                  />
                                ) : servicetype === "Local Services" ? (
                                  <Typography
                                    gutterBottom
                                    sx={{
                                      fontWeight: 600,
                                      fontSize: "14px",
                                      ml: 0.5,
                                      mt: -1.7,
                                    }}
                                    color="text.secondary"
                                    dangerouslySetInnerHTML={{
                                      __html: renderParagraphs(
                                        jobs.job_description
                                      ),
                                    }}
                                  />
                                ) : (
                                  <Typography
                                    gutterBottom
                                    sx={{
                                      fontWeight: 600,
                                      fontSize: "14px",
                                      ml: 0.5,
                                    }}
                                    color="text.secondary"
                                  >
                                    {jobs.nature_of_job}
                                  </Typography>
                                )}
                              </div>
                            </CardContent>

                            <div>
                              <CardActions
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  //width: "99%",
                                  marginTop: "10px",
                                }}
                              >
                                {servicetype === "Local Services" ? (
                                  <Button
                                    onClick={() => {
                                      setAssingedDetails("");
                                      setGetContact("");
                                      setGetProviderId("")
                                      setAssingedDetails(
                                        ...[
                                          {
                                            assign_provider_id:
                                              jobs.assign_provider_id,
                                            assign_provider_email:
                                              jobs.assign_provider_email,
                                            assign_provider_name:
                                              jobs.assign_provider_name,
                                            assigned_date: jobs.assigned_date,
                                          },
                                        ]
                                      );
                                      setGetContact({
                                        ...getcontact,
                                        contact: jobs.contact_number,
                                        id: jobs._id,
                                        getfrom: "searchbytitle",
                                        assign_provider_id:
                                          jobs.assign_provider_id,
                                        CompanyAddress: jobs.shop_address,
                                        companyName: jobs.shop_address,
                                        place_of_service: jobs.place_of_service,
                                      });
                                      setBuyService(true);
                                    }}
                                    sx={{
                                      ml: 1,
                                      mr: 1,
                                    }}
                                    disabled={
                                      jobs.status === "Assigned" ||  (provideridlocal != "" &&
                                      sericeIdArray.includes(
                                        jobs._id
                                      ) > 0)? true : false
                                    }
                                    variant="contained"
                                    color="success"
                                    size="small"
                                  >
                                    View Contact
                                  </Button>
                                ) : null}

                                <Button
                                  onClick={() => {
                                    moreDetails(
                                      servicetype === "Service Providers"
                                        ? jobs.remarks
                                        : servicetype === "Local Jobs"
                                        ? jobs.nature_of_job
                                        : jobs.job_description
                                    );
                                  }}
                                  sx={{
                                    ml: 1,
                                    mr: 1,
                                  }}
                                  variant="outlined"
                                  color="success"
                                  size="small"
                                >
                                  View More
                                </Button>

                                {jobs.status === "InActive" ||
                                jobs.status === "Closed" ? (
                                  <Button
                                    disabled
                                    variant="contained"
                                    size="small"
                                  >
                                    Send Message
                                  </Button>
                                ) : (
                                  <Button
                                    variant="contained"
                                    size="small"
                                    onClick={() => {}}
                                  >
                                    Send Message
                                  </Button>
                                )}
                              </CardActions>
                            </div>
                          </div>
                        </Card>
                      ))
                : null}
            </div>
          )}
          <div style={{ width: "98%", textAlign: "right" }}>
            <div className="pagination">
              {jobDetails.length === 0 ? (
                location.state.job_id ? null : null
              ) : rejectedPagination > 10 ? (
                <Button variant="contained" onClick={rejectedLoadLess}>
                  Load Less
                </Button>
              ) : (
                <></>
              )}
              {rejectedPagination < totalData && (
                <Button variant="contained" onClick={rejectedLoadMore}>
                  Load More
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div
        style={{
          height: "70vh",
          display: "grid",
          placeItems: "center",
          // transform: "translate(0,50vh)",
        }}
      >
        <div class="dots"></div>
      </div>
    );
  }
}

export default Searchpage;
