import { Box } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import {
  Table,
  TableContainer,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  Card,
  Tooltip,
  FormControlLabel,
  FormGroup,
  Switch,
  Skeleton,
  TablePagination,
  Grid,
  Typography,
  Paper,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

function PaymentVendors() {
  const [loading, setLoading] = useState(false);
  const [paymentVendorsDetails, setPaymentVendorsDetails] = useState(false);
  if (!loading) {
    return (
      <div>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <Card sx={{ padding: "20px" }}>
            <Paper>
              <Box
                sx={{
                  padding: "20px",
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                  backgroundColor: "#f2f2f2",
                }}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <TextField
                      sx={{ width: "30%" }}
                      id="outlined-basic"
                      label="Search"
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                <div>
                  <Button
                    variant="contained"
                    style={{ margin: "10px 20px 0px 0px" }}
                    onClick={() => {
                      setPaymentVendorsDetails(true);
                    }}
                  >
                    ADD
                  </Button>
                </div>
              </Box>
            </Paper>
            <TableContainer
              component={Paper}
              sx={{ marginTop: "10px", boxShadow: 0, maxHeight: 440 }}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow className="table-row">
                    <TableCell>Name</TableCell>
                    <TableCell> Callback URL</TableCell>
                    <TableCell>Redirective URL</TableCell>
                    <TableCell> Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell>
                        <Typography
                          variant="body1"
                          sx={{ fontFamily: "Barlow-Bold" }}
                        >
                          NO Data Available
                        </Typography>
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </>
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              disabled
              //   rowsPerPageOptions={[10, 25]}
              component="div"
              // count={totalRows}
              // rowsPerPage={rowsPerPage}
              // page={page}
              // onPageChange={handleChangePage}
              // onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <Dialog
              sx={{
                "& .MuiPaper-root": {
                  width: "400px",
                },
              }}
              open={paymentVendorsDetails}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle
                id="alert-dialog-title"
                bgcolor="#055779"
                color="white"
              >
                {"Payment Vendors Details"}
              </DialogTitle>
              <DialogContent>
                <TextField
                  sx={{ marginTop: 3 }}
                  fullWidth
                  id="outlined-basic"
                  label="Name"
                  variant="outlined"
                />{" "}
                <TextField
                  sx={{ marginTop: 3 }}
                  fullWidth
                  id="outlined-basic"
                  label="Callback URL"
                  variant="outlined"
                />{" "}
                <TextField
                  sx={{ marginTop: 3 }}
                  fullWidth
                  id="outlined-basic"
                  label="Rederective URL"
                  variant="outlined"
                />
                <FormGroup sx={{ alignItems: "end" }}>
                  <FormControlLabel
                    control={<Switch defaultChecked />}
                    label="Active"
                  />
                </FormGroup>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setPaymentVendorsDetails(false);
                  }}
                  autoFocus
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    setPaymentVendorsDetails(false);
                  }}
                  autoFocus
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
          </Card>
        </Box>
      </div>
    );
  } else {
    return (
      <>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <Card sx={{ padding: "20px", borderRadius: "20px" }}>
            <Skeleton
              animation="wave"
              variant="text"
              width={230}
              height={40}
              sx={{ borderRadius: "15px" }}
            />
          </Card>
          <Card
            sx={{
              borderRadius: "20px",
              padding: "20px",
              height: "500px",
              gap: "20px",
            }}
          >
            <Skeleton variant="rounded" />
            <Skeleton variant="rounded" width="100%" height="300px" />
          </Card>
        </Box>
      </>
    );
  }
}

export default PaymentVendors;
