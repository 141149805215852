import React, { useState, useEffect } from "react";
import { Card, Skeleton, Box, Divider } from "@mui/material";
import StatCards from "./StatCards/StatCards";
import useAxios from "../../../api/useAxios";
import SkeletonSeekCard from "../admin-components/StatCards/SkeletonSeekCard";
function Dashboard() {
  const [getApi, setGetApi] = useState([]);
  const [getEmployer, setGetEmployer] = useState([]);
  const [getVolunteer, setGetVolunteer] = useState([]);
  const [getMentor, setGetMentor] = useState([]);
  const [getServiceProvider, setGetServiceProvider] = useState([]);
  const [getInvestor, setGetInvestor] = useState([]);
  const [getPartner, setGetPartner] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const axiosData = useAxios();
  const fetchApi = async () => {
    try {
      const useAxiosData = await axiosData.get("seekers");
      const { data } = useAxiosData;

      if (useAxiosData.status === 204) {
        setGetApi([]);
      } else {
        setGetApi(data);
      }
    } catch (err) {
      console.log("Error", err);
    }
  };

  const fetchEmpApi = async () => {
    try {
      const useAxiosData1 = await axiosData.get("volunteer");
      const { data } = useAxiosData1;

      if (useAxiosData1.status === 204) {
        setGetMentor([]);
      } else {
        setGetMentor(data);
      }
      // setGetEmployer(data);
      // setIsLoading(false);
    } catch (err) {
      console.log("Error", err);
    }
  };
  const fetchVolApi = async () => {
    try {
      const useAxiosData1 = await axiosData.get("employers");
      const { data } = useAxiosData1;

      if (useAxiosData1.status === 204) {
        setGetEmployer([]);
      } else {
        setGetEmployer(data);
      }
      // setGetEmployer(data);
    } catch (err) {
      console.log("Error", err);
    }
  };
  const fetchMentorApi = async () => {
    try {
      const useAxiosData1 = await axiosData.get("mentor");
      const { data } = useAxiosData1;
      
      if (useAxiosData1.status === 204) {
        setGetVolunteer([]);
      } else {
        setGetVolunteer(data);
      }
      // setGetEmployer(data);
      setIsLoading(false);
    } catch (err) {
      console.log("Error", err);
    }
  };
  const fetchServiceProviderApi = async () => {
    try {
      const useAxiosData1 = await axiosData.get("localserviceprovider");
      const { data } = useAxiosData1;
      
      if (useAxiosData1.status === 204) {
        setGetServiceProvider([]);
      } else {
        setGetServiceProvider(data);
      }
      setIsLoading(false);
    } catch (err) {
      console.log("Error", err);
    }
  };
  const fetchInvestorApi = async () => {
    try {
      const useAxiosData1 = await axiosData.get("investors");
      const { data } = useAxiosData1;
      
      if (useAxiosData1.status === 204) {
        setGetInvestor([]);
      } else {
        setGetInvestor(data);
      }
      setIsLoading(false);
    } catch (err) {
      console.log("Error", err);
    }
  };
  const fetchPartnerApi = async () => {
    try {
      const useAxiosData1 = await axiosData.get("partners");
      const { data } = useAxiosData1;
      
      if (useAxiosData1.status === 204) {
        setGetPartner([]);
      } else {
        setGetPartner(data);
      }
      setIsLoading(false);
    } catch (err) {
      console.log("Error", err);
    }
  };
  useEffect(() => {
    fetchApi();
    fetchEmpApi();
    fetchVolApi();
    fetchMentorApi();
    fetchServiceProviderApi();
    fetchInvestorApi();
    fetchPartnerApi();
  }, []);
  if (!isLoading) {
    const getAll = [
      { name: "Job Seekers Data", data: getApi },
      { name: "Employers Data", data: getEmployer },
      { name: "Volunteers Data", data: getVolunteer },
      { name: "Mentors Data", data: getMentor },
      { name: "Service Providers Data", data: getServiceProvider },
      { name: "Investors Data", data: getInvestor },
      { name: "Partners Data", data: getPartner },
    ];
    return (
      <>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <Card
            sx={{ borderRadius: "15px", boxShadow: "3px 3px 10px 3px #dddddd" }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                padding: "10px",
              }}
            >
              <h3 className="seeker-text">Dashboard</h3>
            </Box>
          </Card>
          {getAll.map((item) => {
            return (
              <>
                <Card>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      paddingTop: "20px",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <Box>
                      <Divider textAlign="left" sx={{ width: "100%" }}>
                        <span className="seeker-text">{item.name}</span>
                      </Divider>
                    </Box>

                    <StatCards getApi={item.data} />
                  </Box>
                </Card>
              </>
            );
          })}
        </Box>
      </>
    );
  } else {
    return (
      <>
        {" "}
        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <Card sx={{ padding: "20px", borderRadius: "20px" }}>
            <Skeleton
              animation="wave"
              variant="text"
              width={230}
              height={40}
              sx={{ borderRadius: "15px" }}
            />
          </Card>

          <Card>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: "20px",
                gap: "10px",
              }}
            >
              {" "}
              <Skeleton
                animation="wave"
                variant="text"
                width={230}
                height={40}
                sx={{ borderRadius: "15px" }}
              />
              <SkeletonSeekCard />
            </Box>
          </Card>
          <Card>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: "20px",
                gap: "10px",
              }}
            >
              {" "}
              <Skeleton
                animation="wave"
                variant="text"
                width={230}
                height={40}
                sx={{ borderRadius: "15px" }}
              />
              <SkeletonSeekCard />
            </Box>
          </Card>
        </Box>
      </>
    );
  }
}

export default Dashboard;
