import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import WestIcon from "@mui/icons-material/West";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useFormik } from "formik";
import * as Yup from "yup";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import InputAdornment from "@mui/material/InputAdornment";
import logo from "../../../img/logo_for-landingpage-3.png";
import ArticleIcon from "@mui/icons-material/Article";
import { useNavigate, Outlet } from "react-router";
import { useLocation } from "react-router-dom";
import useAxios from "../../../api/useAxios";
import "./PostJob.css";
import { Data } from "../employer/Data";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CircularProgress from "@mui/material/CircularProgress";
import { ROUTINGDATA } from "../../ROUTINGDATA";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Popper from "@mui/material/Popper";
import moment from "moment";
import Autocomplete from "@mui/material/Autocomplete";
import Slide from "@mui/material/Slide";
import VerifiedIcon from "@mui/icons-material/Verified";
import { ShareLocationRounded as ShareLocationRoundedIcon } from "@mui/icons-material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Chip,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  IconButton,
  Tooltip,
  FormControl,
  Grid,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
const theme = createTheme();
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function PostJob() {
  var datetime = new Date().toLocaleString("en-US", {
    timeZone: "Asia/Kolkata",
  });
  const [sucfulPostMsg, setSucfulPostMsg] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [skill, setSkill] = useState("");
  const [from, setFrom] = useState(moment());
  const [to, setTo] = useState(moment());
  const [chip, setChip] = useState([]);
  const [declined, setDeclined] = useState(false);
  const [focus, setfocus] = useState(false);
  const [focusNo, setFocusNo] = useState(false);
  const today = moment().subtract(1, "days");
  const [selectedIndustry, setSelectedIndustry] = useState();
  const [postedJobData, setPostedJobData] = useState([]);
  const [industryArray, setIndustryArray] = useState();
  const [postedJobSubcription, setPostedJobSubcription] = useState(false);
  const [districtData, setDistrictData] = useState([]);
  const [experience, setExperience] = useState([]);
  const [eductionType, setEductionType] = useState([]);
  const exceptThisSymbols = ["e", "E", "+", "-", ".", "ArrowUp", "ArrowDown"];
  const [comdata, setComdata] = useState([]);
  const [pinLoad, setpinLoad] = useState(false);
  const [experienceType, setExperienceType] = useState("Experience");
  const [job_description, setJob_description] = useState("");
  let location = useLocation();
  let userDetails = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();
  const axiosData = useAxios();
  useEffect(() => {
    datetime = new Date(from);
    datetime.setDate(datetime.getDate() + 30);
    setTo(moment(datetime));
    console.log("datetime",moment(datetime))
  }, [from]);

  const IndustryData = async () => {
    try {
      const getOneAxios = await axiosData.get(`/General/seekerSettings`);
      const data = getOneAxios?.data?.industryData;
      if (data) {
        setIndustryArray(data);
      }
      return data;
    } catch (err) {
      console.log("Error", err);
    }
  };
  const vodingDistrict = async () => {
    try {
      const getOneAxios = await axiosData.get(`/district`);
      const data = getOneAxios.data;
      if (data) {
        const getDistrict = data.map((i) => {
          return i.District;
        });
        getDistrict.sort();
        setDistrictData(getDistrict);
      }
      return data;
    } catch (err) {
      console.log("Error", err);
    }
  };
  const experienceData = async () => {
    try {
      const getOneAxios = await axiosData.get(`/General/yearOfExperience`);
      const data = getOneAxios?.data?.experience;
      console.log("data:", data);
      if (data) {
        setExperience(data);
        console.log("experience:", experience);
      }
      return data;
    } catch (err) {
      console.log("Error", err);
    }
  };
  const checkpost = async () => {
    try {
      const response = await axiosData.get(
        `employers/email/${userDetails.emailId}/users`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.no_of_free_posting <= 0) {
        if (
          response.data.no_of_Standard_posting <= 0 &&
          response.data.no_of_hot_posting <= 0
        ) {
          setPostedJobSubcription(true);
          setIsSubmitting(false);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  const educationData = async () => {
    try {
      const getOneAxios = await axiosData.get(`/General/education`);
      const data = getOneAxios?.data?.educationData;

      if (data) {
        setEductionType(data);
      }
      return data;
    } catch (err) {
      console.log("Error", err);
    }
  };
  useEffect(() => {
    checkpost();
    IndustryData();
    experienceData();
    educationData();
    vodingDistrict();
    jobs();
  }, []);

  const formik = useFormik({
    initialValues: {
      job_title: "",
      experience_min: "",
      experience_max: "",
      salary_type: "Month",
      experience_type: "Experience",
      salary_range_min: 0,
      salary_range_max: "",
      education_details: "",
      key_skills: "",
      job_location: "",
      industry: "",
      job_description: "",
      number_of_positions: "",
      contact_email_id: "",
      phone_number: "",
      expiry_date_from: "",
      need_type: "Normal",
      expiry_date_to: "",
      unique_code: "",
      employer_email_id: "",
      district: "",
      pincode: "",
    },

    validationSchema: Yup.object({
      job_title: Yup.string().required("Job Title must be Required"),
      experience_type: Yup.string().required(""),
      experience_min:
        experienceType === "Experience"
          ? Yup.string()
              .required("Minimum Experience Required")
              .test(
                "experience_min",
                "Minimum value must be less than Maximum value",
                function (values) {
                  const { experience_max } = this.parent;
                  if (experience_max) {
                    const Splitmax = experience_max.split(" ");
                    const Splitmin = values.split(" ");
                    const [c, d] = Splitmax;
                    const [a, b] = Splitmin;

                    const max = parseFloat(c);
                    const min = parseFloat(a);
                    console.log("1min", max, min);
                    return max > min;
                  }
                }
              )
          : Yup.string(),
      experience_max:
        experienceType === "Experience"
          ? Yup.string()
              .required("Maximum Experience Required")
              .test(
                "experience_max",
                "Maximum value must be greater than Minimum value",
                function (values) {
                  const { experience_min } = this.parent;
                  if (experience_min) {
                    const Splitmin = experience_min.split(" ");
                    const Splitmax = values.split(" ");
                    const [c, d] = Splitmin;
                    const [a, b] = Splitmax;
                    console.log("c,d", typeof a, c);
                    const min = parseFloat(c);
                    const max = parseFloat(a);
                    console.log("min", min, max, min < max);
                    return min < max;
                  }
                }
              )
          : Yup.string(),

      education_details: Yup.string().required(
        "Education Details must be Required"
      ),

      key_skills: Yup.string().required("Key Skills must be Required"),
      job_location: Yup.string().required("Job Location is required"),
      industry: Yup.string().required("Select Industry"),
      job_description: Yup.string().required("Required"),

      number_of_positions: Yup.number().required(
        "Number of Positions Must be Required"
      ),
      salary_range_min: Yup.number()
        .test(
          "salary_range_min",
          "Minimum Salary must be less than Maximum Salary",
          function (value) {
            const { salary_range_max } = this.parent;
            if (!salary_range_max || !value) return true;
            return value < salary_range_max;
          }
        )
        .required("Minimum Salary Must be Required"),
      salary_range_max: Yup.number()
        .test(
          "salary_range_max",
          "Maximum Salary must be greater than Minimum Salary",
          function (value) {
            const { salary_range_min } = this.parent;
            if (!salary_range_min || !value) return true;
            return value > salary_range_min;
          }
        )
        .required("Maximum Salary Must be Required"),
      contact_email_id: Yup.string()
        .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, "Invalid Email")
        .required("Email is must be Required"),
      phone_number: Yup.number()
        .min(6000000000, "Not Valid Phone Number!")
        .max(9999999999, "Not Valid Phone Number!")
        .required("Phone number is required"),
      district: Yup.string().required("Select District"),
      pincode: Yup.string().required("Pincode Must be Required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      if (chip.length !== 0) {
        formik.values.key_skills = chip.toString();
      }
      if (chip[0] === undefined) {
        formik.values.key_skills[0] = "";
      }
      const pincodeSplit = values.pincode.split("/");
      const [a, b] = pincodeSplit;
      values.pincode = a;
      values.area = b;
      const From = from.format("DD/MM/YYYY");
      const To = to.format("DD/MM/YYYY");
      setIsSubmitting(true);
      values.expiry_date_from = From;
      values.expiry_date_to = To;
      values.unique_code = Math.random().toString(16).slice(-6);
      values.user_id = location.state;
      values.company_name = userDetails.company_name;
      values.employer_mobile_number = userDetails.mobile_number;
      values.employer_email_id = userDetails.emailId;
      values.logoUpload = userDetails.logoPic;
      if (values.need_type === "Urgent") {
        if (postedJobData.no_of_hot_posting <= 0) {
          setPostedJobSubcription(true);
          setIsSubmitting(false);
        } else {
          try {
            const response = await axiosData.post("jobs", values, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            });
            values.job_id = response.data._id;
            values.user_id = response.data.user_id;

            if (response.status === 201) {
              await axiosData.put(
                `employers/email/${userDetails.emailId}`,
                {
                  no_of_hot_posting: postedJobData.no_of_hot_posting - 1,
                },
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                }
              );
              await axiosData.post("urgentjobs", values, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              });
              //urgent
              postedJobData.no_of_hot_posting =
                postedJobData.no_of_hot_posting - 1;
              setPostedJobData(postedJobData);
              setIsSubmitting(false);
              resetForm();
              setSucfulPostMsg(true);
            } else if (response.status === 204) {
              alert(response);
            } else if (response.status === 412) {
              alert(response);
            }
          } catch (err) {
            console.log(err);
          }
        }
      } else {
        if (postedJobData.no_of_free_posting > 0) {
          try {
            const response = await axiosData.post("jobs", values, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            });
            if (response.status === 201) {
              await axiosData.put(
                `employers/email/${userDetails.emailId}`,
                { no_of_free_posting: postedJobData.no_of_free_posting - 1 },
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                }
              );
              setIsSubmitting(false);
              resetForm();
              setSucfulPostMsg(true);
            } else if (response.status === 204) {
              alert(response);
            } else if (response.status === 412) {
              alert(response);
            }
          } catch (err) {
            console.log(err);
          }
        } else if (postedJobData.no_of_Standard_posting > 0) {
          try {
            const response = await axiosData.post("jobs", values, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            });
            if (response.status === 201) {
              try {
                await axiosData.put(
                  `employers/email/${userDetails.emailId}`,
                  {
                    no_of_Standard_posting:
                      postedJobData.no_of_Standard_posting - 1,
                  },
                  {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  }
                );
              } catch (err) {
                console.log(err);
              }
              //normal
              postedJobData.no_of_Standard_posting =
                postedJobData.no_of_Standard_posting - 1;
              setPostedJobData(postedJobData);
              setIsSubmitting(false);
              resetForm();
              setSucfulPostMsg(true);
            } else if (response.status === 204) {
              alert(response);
            } else if (response.status === 412) {
              alert(response);
            }
          } catch (err) {
            console.log(err);
            if (err.response.status === 403) {
              localStorage.removeItem("user");
              setDeclined(true);
            }
          }
        } else {
          setPostedJobSubcription(true);
          setIsSubmitting(false);
        }
      }
    },
  });
  const jobs = async () => {
    try {
      const response = await axiosData.get(
        `employers/email/${userDetails.emailId}/users`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setPostedJobData(response.data);
    } catch (err) {
      console.log(err);
    }
  };
  const handleChange = (e) => {
    setFocusNo(false);
    const { value } = e.target;
    if (value.length <= 10) {
      formik.handleChange(e);
    }
  };
  const searchobj = async (values) => {
    // setAdDistrict(values);
    setpinLoad(true);
    try {
      const getOneAxiospin = await axiosData.get(
        `/distritarea/getArea/${values}`
      );
      const datapin = getOneAxiospin.data;
      if (datapin) {
        const getpin = datapin.map((i) => {
          return i.Pincode;
        });
        const getpost = datapin.map((i) => {
          return i.Post_Office;
        });
        const getpost1 = getpost[0];
        const result = getpin[0].reduce(
          (accumulator, currentPincode, index) => {
            return accumulator.concat(`${currentPincode}/${getpost1[index]}`);
          },
          []
        );
        const getPostFinal = result.map((i) => {
          return i;
        });
        // setPincodeData(getpin[0]);
        // setPostOfficeData(getpost[0]);
        // setPincode(datapin);
        setComdata([...getPostFinal]);
        setpinLoad(false);
      }
      // return data;
    } catch (err) {
      console.log("Error", err);
    }
  };
  const filterOptions = (options, { inputValue }) => {
    const inputValueLower = inputValue.toLowerCase();
    const startsWithInput = [];
    const containsInput = [];

    options.forEach((option) => {
      const optionLower = option.toLowerCase();
      if (optionLower.startsWith(inputValueLower)) {
        startsWithInput.push(option);
      } else if (optionLower.includes(inputValueLower)) {
        containsInput.push(option);
      }
    });

    return [...startsWithInput, ...containsInput];
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const handleFromDateChange = (event) => {
    const newEndDate = moment(event);
    setFrom(newEndDate);
    datetime = new Date(event);
    datetime.setDate(datetime.getDate() + 30);
    setTo(moment(datetime));
    console.log(datetime);
  };
  const handleEndDateChange = (event) => {
    const newEndDate = moment(event);
    setTo(newEndDate);
  };
  const addSkill = (Context) => {
    const lowercaseSkill = skill.toLowerCase();
    const lowercaseChip = chip.map((item) => item.toLowerCase());

    if (lowercaseChip.includes(lowercaseSkill)) {
      setSkill("");
      return;
    }

    chip.push(skill);
    setChip([...chip]);

    const uniqueArray = Array.from(new Set(chip.map((item) => item)));
    setChip(uniqueArray);

    const temp = uniqueArray.join(", ");
    if (temp) {
      formik.setFieldValue("key_skills", temp);
    }

    setSkill("");
  };
  const handleSkill = (e) => {
    const { value } = e.target;
    const regex = /^[A-Za-z#+/.0-9 ]*$/;
    if (regex.test(value)) {
      setSkill(value);
    }
  };

  const handleDelete = (index) => {
    chip.splice(index, 1);
    setChip([...chip]);
    const temp = chip.toString();
    formik.setFieldValue("key_skills", temp);
  };
  const routePostedPage = () => {
    navigate(ROUTINGDATA.LAND + ROUTINGDATA.MAIN + ROUTINGDATA.POSTEDJOB, {
      state: userDetails.userId,
    });
  };
  useEffect(() => {
    if (userDetails === null || userDetails === undefined) {
      navigate(
        `${ROUTINGDATA.LAND}${ROUTINGDATA.MAIN}${ROUTINGDATA.PageNotFound}`
      );
    }
  }, []);

  if (userDetails !== null || userDetails !== undefined) {
    return (
      <ThemeProvider theme={theme}>
        <Dialog TransitionComponent={Transition} open={declined}>
          <DialogTitle
            bgcolor="rgb(16 143 178)"
            sx={{ color: "white", fontWeight: 600 }}
          >
            Declined alert !
          </DialogTitle>
          <DialogContent sx={{ mt: 3 }}>
            <DialogContentText sx={{ fontWeight: 600 }}>
              {
                "Your profile is declined by admin so please contact contactus@usglobalsolutions.com."
              }
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setDeclined(false);
                navigate(`${ROUTINGDATA.LAND}`);
              }}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        <Grid
          container
          component="main"
          sx={{ height: "100vh" }}
          className="Postmain-grid"
        >
          <CssBaseline />
          <Grid className="Postimg-grid" item xs={false} sm={false} md={4}>
            <div className="arrow-link-post">
              <Tooltip
                arrow
                onClick={() => {
                  navigate(
                    ROUTINGDATA.LAND +
                      ROUTINGDATA.MAIN +
                      ROUTINGDATA.EMPLOYERUPDATE,
                    {
                      state: userDetails.emailId,
                    }
                  );
                }}
                className="GoBackTooltip"
                title="Go Back"
              >
                <IconButton
                  className="GoBackTooltip  arrow"
                  sx={{
                    backgroundColor: "#339fcd",
                    justifyContent: "center",
                    height: "40px",
                    marginTop: "20px",
                    marginLeft: "20px",
                  }}
                >
                  <WestIcon sx={{ color: "#fff" }} />
                </IconButton>
              </Tooltip>
            </div>
            <Box className="Postsideimg">
              <div className="Postsideimglogo">
                <img src={logo} alt="logo" style={{ width: "75%" }} />
              </div>
              <div className="Postsidecontect">
                <Typography variant="h6" gutterBottom>
                  Job Posting You Can
                </Typography>
                <div className="Postlistofsidecontect">
                  <div>
                    <CheckCircleIcon sx={{ color: "green" }} />
                  </div>
                  <div>Introduce Your Company</div>
                </div>
                <div className="Postlistofsidecontect">
                  <div>
                    <CheckCircleIcon sx={{ color: "green" }} />
                  </div>
                  <div>Spell Out The Top Benefits</div>
                </div>
                <div className="Postlistofsidecontect">
                  <div>
                    <CheckCircleIcon sx={{ color: "green" }} />
                  </div>
                  <div>Best Candidates Reach Out To You Directly</div>
                </div>
              </div>
            </Box>
          </Grid>
          <Grid
            className="Postsecond-grid"
            item
            xs={12}
            sm={12}
            md={8}
            component={Paper}
            elevation={4}
            square
          >
            <form onSubmit={formik.handleSubmit}>
              <Box
                sx={{
                  my: 3,
                  mx: 8,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <ArticleIcon
                  sx={{ mb: 2, width: 56, height: 56, color: "#ed1e70" }}
                />

                <Typography
                  component="h1"
                  variant="h5"
                  sx={{ fontWeight: 600, color: "#135c82" }}
                >
                  Post New Job
                </Typography>

                <Box sx={{ mt: 1, maxWidth: "100%" }}>
                  <Grid container rowSpacing={1} columnSpacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        required
                        margin="normal"
                        fullWidth
                        id="job_title"
                        label="Job Title"
                        name="job_title"
                        onBlur={formik.handleBlur}
                        onChange={(e) => {
                          if (e) {
                            const value = e.target.value.replace(
                              /[^a-z0-9#+/. ]/gi,
                              ""
                            );
                            formik.setFieldValue("job_title", value);
                          }
                        }}
                        value={formik.values.job_title}
                      />
                      {formik.touched.job_title && formik.errors.job_title ? (
                        <div style={{ color: "red" }}>
                          {formik.errors.job_title}
                        </div>
                      ) : null}
                    </Grid>

                    <Grid item xs={12}>
                      <Typography sx={{ marginTop: 1 }}>
                        <b>Experience(years)</b>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="experience_type"
                        onChange={(e) => {
                          formik.setFieldValue("experience_min", "");
                          formik.setFieldValue("experience_max", "");
                          setExperienceType(e.target.value);
                          formik.setFieldValue(
                            "experience_type",
                            e.target.value
                          );
                          console.log(
                            "use",
                            experienceType,
                            formik.values.experience_type
                          );
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.experience_type}
                      >
                        <FormControlLabel
                          value="Freshers Only"
                          control={<Radio />}
                          label="Freshers Only"
                        />
                        <FormControlLabel
                          value="Experience"
                          control={<Radio />}
                          label="Experience"
                        />
                      </RadioGroup>
                    </Grid>
                    {formik.values.experience_type === "Experience" ? (
                      <>
                        {" "}
                        <Grid item xs={6}>
                          <FormControl fullWidth required>
                            <InputLabel id="demo-simple-select-label">
                              Min
                            </InputLabel>

                            <Select
                              MenuProps={MenuProps}
                              labelId="demo-simple-select-label"
                              id="experience_min"
                              label="Min"
                              name="experience_min"
                              onBlur={formik.handleBlur}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  "experience_min",
                                  e.target.value
                                );
                              }}
                              value={formik.values.experience_min}
                            >
                              {experience.map((data, index) => (
                                <MenuItem value={data} keys={index}>
                                  {data}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          {formik.touched.experience_min &&
                          formik.errors.experience_min ? (
                            <div style={{ color: "red" }}>
                              {formik.errors.experience_min}
                            </div>
                          ) : null}
                        </Grid>
                        <Grid item xs={6}>
                          <FormControl fullWidth required>
                            <InputLabel id="demo-simple-select-label">
                              Max
                            </InputLabel>

                            <Select
                              MenuProps={MenuProps}
                              labelId="demo-simple-select-label"
                              id="experience_max"
                              label="Max"
                              name="experience_max"
                              onBlur={formik.handleBlur}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  "experience_max",
                                  e.target.value
                                );
                              }}
                              value={formik.values.experience_max}
                            >
                              {experience.map((data, index) => (
                                <MenuItem value={data} keys={index}>
                                  {data}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          {formik.touched.experience_max &&
                          formik.errors.experience_max ? (
                            <div style={{ color: "red" }}>
                              {formik.errors.experience_max}
                            </div>
                          ) : null}
                        </Grid>
                      </>
                    ) : null}

                    <Grid item xs={12}>
                      <Typography sx={{ marginTop: 2 }}>
                        <b>Salary Range</b>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <RadioGroup
                        defaultValue="Month"
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="salary_type"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.salary_type}
                      >
                        <FormControlLabel
                          value="Month"
                          control={<Radio />}
                          label="Month"
                        />
                        <FormControlLabel
                          value="Year"
                          control={<Radio />}
                          label="Year"
                        />
                        {/* <FormControlLabel
                          value="None"
                          control={<Radio />}
                          label="None"
                        /> */}
                      </RadioGroup>
                    </Grid>
                    {formik.values.salary_type !== "None" && (
                      <>
                        <Grid item xs={6}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              Min
                            </InputLabel>
                            <OutlinedInput
                              margin="normal"
                              fullWidth
                              id="salary_range_min"
                              type="number"
                              label="Min"
                              variant="outlined"
                              name="salary_range_min"
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={formik.values.salary_range_min}
                              onKeyDown={(e) =>
                                exceptThisSymbols.includes(e.key) &&
                                e.preventDefault()
                              }
                              onWheel={(e) => {
                                e.preventDefault();
                                e.target.blur();
                              }}
                            />
                          </FormControl>
                          {formik.touched.salary_range_min &&
                          formik.errors.salary_range_min ? (
                            <div style={{ color: "red" }}>
                              {formik.errors.salary_range_min}
                            </div>
                          ) : null}
                        </Grid>
                        <Grid item xs={6}>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              Max
                            </InputLabel>
                            <OutlinedInput
                              margin="normal"
                              fullWidth
                              id="salary_range_max"
                              type="number"
                              label="Max"
                              variant="outlined"
                              name="salary_range_max"
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={formik.values.salary_range_max}
                              onKeyDown={(e) =>
                                exceptThisSymbols.includes(e.key) &&
                                e.preventDefault()
                              }
                              onWheel={(e) => {
                                e.preventDefault();
                                e.target.blur();
                              }}
                            />
                          </FormControl>
                          {formik.touched.salary_range_max &&
                          formik.errors.salary_range_max ? (
                            <div style={{ color: "red" }}>
                              {formik.errors.salary_range_max}
                            </div>
                          ) : null}
                        </Grid>
                      </>
                    )}

                    <Grid item xs={12} sx={{ marginTop: 3 }}>
                      <FormControl fullWidth required>
                        <InputLabel id="demo-simple-select-label">
                          Educations Details
                        </InputLabel>

                        <Select
                          labelId="demo-simple-select-label"
                          id="education_details"
                          label="Education Details"
                          name="education_details"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.education_details}
                        >
                          {eductionType.map((data, index) => (
                            <MenuItem key={index} value={data}>
                              {data}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {formik.touched.education_details &&
                      formik.errors.education_details ? (
                        <div style={{ color: "red" }}>
                          {formik.errors.education_details}
                        </div>
                      ) : null}
                    </Grid>
                    <Grid item xs={12} sx={{ marginTop: 1.5 }}>
                      <Box>
                        {chip.map((element, index) => {
                          if (element !== "") {
                            return (
                              <Chip
                                key={index}
                                label={element}
                                onDelete={() => handleDelete(index)}
                              />
                            );
                          }
                        })}
                      </Box>
                    </Grid>
                    <Grid item xs={9}>
                      <TextField
                        required
                        fullWidth
                        id="Key Skills"
                        label="Key Skills"
                        name="key_skills"
                        value={skill}
                        onChange={handleSkill}
                      />
                      {formik.touched.key_skills && formik.errors.key_skills ? (
                        <div style={{ color: "red" }}>
                          {formik.errors.key_skills}
                        </div>
                      ) : null}
                    </Grid>
                    <Grid item xs={3}>
                      <Button
                        fullWidth
                        variant="contained"
                        sx={{ p: 1.8, borderRadius: 4 }}
                        disabled={!skill}
                        onClick={() => {
                          addSkill();
                        }}
                      >
                        Add
                      </Button>
                    </Grid>
                    <Grid>
                      <p
                        style={{
                          margin: "5px 0px 0px 20px",
                          fontSize: "14px",
                          fontWeight: 700,
                          color: "#8b8b8b",
                        }}
                      >
                        ( Note : Once you fill the Key Skills after Click the
                        add button )
                      </p>
                    </Grid>

                    <Grid item xs={12} sx={{ marginTop: 1 }}>
                      <TextField
                        required
                        margin="normal"
                        fullWidth
                        id="job_location"
                        label="Job Location"
                        name="job_location"
                        onBlur={formik.handleBlur}
                        onChange={(e) => {
                          if (e) {
                            const value = e.target.value.replace(
                              /[^a-zA-Z ]/gi,
                              ""
                            );
                            formik.setFieldValue("job_location", value);
                          }
                        }}
                        value={formik.values.job_location}
                      />
                      {formik.touched.job_location &&
                      formik.errors.job_location ? (
                        <div style={{ color: "red" }}>
                          {formik.errors.job_location}
                        </div>
                      ) : null}
                    </Grid>
                    <Grid item xs={12} sx={{ marginTop: 1.5 }}>
                      <FormControl fullWidth required>
                        <Autocomplete
                          disableClearable={
                            formik.values.district ? false : true
                          }
                          fullWidth
                          filterOptions={filterOptions}
                          name="district"
                          id="district"
                          value={formik.values.district}
                          onChange={(event, value) => {
                            formik.setFieldValue("district", value);
                            searchobj(value);
                            formik.setFieldValue("pincode", "");
                          }}
                          onBlur={formik.handleBlur}
                          options={districtData}
                          getOptionLabel={(option) => option}
                          renderInput={(params) => (
                            <TextField
                              required
                              {...params}
                              label="District"
                              //onClick={() => params.inputProps.onInputClick()}
                              InputLabelProps={{
                                style: { marginLeft: "7px" },
                              }}
                            />
                          )}
                          onOpen={(event) => event.preventDefault()}
                          PopperComponent={({ children, ...popperProps }) => (
                            <Popper
                              {...popperProps}
                              placement="bottom-start"
                              modifiers={{
                                flip: {
                                  enabled: false,
                                },
                                preventOverflow: {
                                  enabled: true,
                                  boundariesElement: "scrollParent",
                                },
                              }}
                              PopperProps={{
                                placement: "bottom-start", // Placement set to "bottom-start"
                              }}
                            >
                              <Paper>{children}</Paper>
                            </Popper>
                          )}
                          ListboxProps={{
                            style: { maxHeight: "180px", overflowY: "auto" },
                          }}
                        />
                        {formik.touched.district && formik.errors.district ? (
                          <div style={{ color: "red" }}>
                            {formik.errors.district}
                          </div>
                        ) : null}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <Tooltip
                        title={
                          formik.values.district ? "" : "please Select District"
                        }
                      >
                        <FormControl fullWidth required sx={{ marginY: 2 }}>
                          <Autocomplete
                            disableClearable={
                              formik.values.pincode ? false : true
                            }
                            disablePortal
                            freeSolo
                            id="pincode"
                            name="pincode"
                            disabled={
                              formik.values.district && !pinLoad ? false : true
                            }
                            options={comdata}
                            value={formik.values.pincode}
                            onChange={(event, value) => {
                              formik.setFieldValue("pincode", value);
                            }}
                            onBlur={formik.handleBlur}
                            renderInput={(params) => (
                              <TextField
                                required
                                // onChange={formik.handleChange}
                                {...params}
                                label="Pincode"
                                InputProps={{
                                  ...params.InputProps,
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      {pinLoad ? (
                                        <CircularProgress
                                          // size={24}
                                          style={{
                                            width: "1.4rem",
                                            height: "1.4rem",
                                            color: "#4991dd",
                                          }}
                                        />
                                      ) : (
                                        <ShareLocationRoundedIcon
                                          sx={{
                                            color: formik.values.district
                                              ? "#414141"
                                              : "#bdbdbd",
                                          }}
                                        />
                                      )}{" "}
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                          />

                          {/* <TextField
                        margin="normal"
                        fullWidth
                        name="pincode"
                        label={t("Pincode")}
                        type="Number"
                        id="pincode"
                        onWheel={(e) => {
                          e.preventDefault();
                          e.target.blur();
                        }}
                        onKeyDown={(e) =>
                          exceptThisSymbols.includes(e.key) &&
                          e.preventDefault()
                        }
                        onBlurCapture={() => {
                          setFocusPin(true);
                        }}
                        placeholder={t("Enter_Pincode(Optional)")}
                        onBlur={formik.handleBlur}
                        onChange={handleChangePincode}
                        value={formik.values.pincode}
                      /> */}
                          {formik.touched.pincode && formik.errors.pincode ? (
                            <div style={{ color: "red", fontSize: "15px" }}>
                              {formik.errors.pincode}
                            </div>
                          ) : null}
                        </FormControl>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={12}>
                      {industryArray && (
                        <FormControl sx={{ mt: 1.5, mb: 1 }} fullWidth required>
                          <Autocomplete
                            disableClearable={
                              formik.values.industry ? false : true
                            }
                            filterOptions={filterOptions}
                            id="industry"
                            name="industry"
                            options={industryArray}
                            onBlur={formik.handleBlur}
                            onChange={(event, value) => {
                              setSelectedIndustry(value);
                              formik.setFieldValue("industry", value);
                            }}
                            value={formik.values.industry}
                            renderInput={(params) => (
                              <TextField
                                required
                                {...params}
                                id="industry"
                                label="Preffered Industry"
                                name="industry"
                                // onClick={() => params.inputProps.onInputClick()}
                              />
                            )}
                            onOpen={(event) => event.preventDefault()}
                            PopperComponent={({ children, ...popperProps }) => (
                              <Popper
                                {...popperProps}
                                placement="bottom-start"
                                modifiers={{
                                  flip: {
                                    enabled: false,
                                  },
                                  preventOverflow: {
                                    enabled: true,
                                    boundariesElement: "scrollParent",
                                  },
                                }}
                                PopperProps={{
                                  placement: "bottom-start", // Placement set to "bottom-start"
                                }}
                              >
                                <Paper>{children}</Paper>
                              </Popper>
                            )}
                            ListboxProps={{
                              style: { maxHeight: "180px", overflowY: "auto" },
                            }}
                          />
                        </FormControl>
                      )}
                      {formik.touched.industry && formik.errors.industry ? (
                        <div style={{ color: "red" }}>
                          {formik.errors.industry}
                        </div>
                      ) : null}
                    </Grid>

                    <Grid item xs={12} sx={{ marginTop: 2, marginBottom: 1 }}>
                      <FormControl fullWidth required>
                        <InputLabel id="demo-simple-select-label">
                          Number of Positions
                        </InputLabel>
                        <OutlinedInput
                          margin="normal"
                          fullWidth
                          id="number_of_positions"
                          type="number"
                          label="Number of Positions"
                          variant="outlined"
                          name="number_of_positions"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.number_of_positions}
                          onKeyDown={(e) =>
                            exceptThisSymbols.includes(e.key) &&
                            e.preventDefault()
                          }
                          onWheel={(e) => {
                            e.preventDefault();
                            e.target.blur();
                          }}
                        />
                      </FormControl>
                      {formik.touched.number_of_positions &&
                      formik.errors.number_of_positions ? (
                        <div style={{ color: "red" }}>
                          {formik.errors.number_of_positions}
                        </div>
                      ) : null}
                    </Grid>
                    <Grid item xs={12} sx={{ marginTop: 2, marginBottom: 1 }}>
                      <FormControl fullWidth required>
                        <Typography id="demo-controlled-radio-buttons-group">
                          <b>Job Need Type</b>
                        </Typography>

                        <RadioGroup
                          row
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="need_type"
                          value={formik.values.need_type}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        >
                          <FormControlLabel
                            value="Normal"
                            control={<Radio />}
                            label="Normal"
                          />
                          <Tooltip
                            arrow
                            title={
                              postedJobData.no_of_hot_posting === 0
                                ? "Buy Premium Post"
                                : `Only ${postedJobData.no_of_hot_posting} Post is Available`
                            }
                          >
                            <FormControlLabel
                              disabled={
                                postedJobData.no_of_hot_posting === 0
                                  ? true
                                  : false
                              }
                              value="Urgent"
                              control={<Radio />}
                              label="Urgent"
                            />
                          </Tooltip>

                          {/* <FormControlLabel
                              value="Later"
                              control={<Radio />}
                              label="Later"
                            /> */}
                        </RadioGroup>
                      </FormControl>
                      {formik.touched.need_type && formik.errors.need_type ? (
                        <div style={{ color: "red" }}>
                          {formik.errors.need_type}
                        </div>
                      ) : null}
                    </Grid>

                    <Grid item xs={12}>
                      <Typography sx={{ marginTop: 1 }}>
                        <b>Date Range</b>
                      </Typography>
                    </Grid>

                    <Grid item xs={6}>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          disablePast
                          className="ddd"
                          label="From"
                          name="cals"
                          format="DD/MM/YYYY"
                          value={from}
                          onChange={(e) => {
                            handleFromDateChange(e);
                          }}
                        />
                      </LocalizationProvider>
                      {from < today ? (
                        <Typography sx={{ color: "red" }}>
                          This is past date
                        </Typography>
                      ) : from >= to ? (
                        <Typography sx={{ color: "red" }}>
                          From date must be before To date
                        </Typography>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                          disabled={true}
                          disablePast
                          className="ddd"
                          label="To"
                          name="cals"
                          format="DD/MM/YYYY"
                          value={to}
                          onChange={(e) => {
                            handleEndDateChange(e);
                          }}
                        />
                      </LocalizationProvider>
                      {to < today ? (
                        <Typography sx={{ color: "red" }}>
                          This is past date
                        </Typography>
                      ) : from >= to ? (
                        <Typography sx={{ color: "red" }}>
                          To date must be after From date
                        </Typography>
                      ) : (
                        ""
                      )}
                    </Grid>

                    <Grid item xs={12} sx={{ marginTop: 1 }}>
                      <TextField
                        required
                        margin="normal"
                        fullWidth
                        id="contact_email_id"
                        label="Contact Email Id"
                        name="contact_email_id"
                        onBlurCapture={() => {
                          setfocus(true);
                        }}
                        onBlur={formik.handleBlur}
                        onChange={(e) => {
                          setfocus(false);
                          const value = e.target.value.replace(
                            /[^a-z0-9@.]/gi,
                            ""
                          );
                          formik.setFieldValue("contact_email_id", value);
                        }}
                        value={formik.values.contact_email_id}
                      />
                      {formik.touched.contact_email_id &&
                      formik.errors.contact_email_id &&
                      focus ? (
                        <div style={{ color: "red" }}>
                          {formik.errors.contact_email_id}
                        </div>
                      ) : null}
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        required
                        margin="normal"
                        fullWidth
                        id="phone_number"
                        label="Phone Number"
                        name="phone_number"
                        type="number"
                        onBlurCapture={() => {
                          setFocusNo(true);
                        }}
                        onKeyDown={(e) =>
                          exceptThisSymbols.includes(e.key) &&
                          e.preventDefault()
                        }
                        onBlur={formik.handleBlur}
                        onChange={handleChange}
                        value={formik.values.phone_number}
                        placeholder="Enter phone number"
                        onWheel={(e) => {
                          e.preventDefault();
                          e.target.blur();
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <b>+91</b>
                            </InputAdornment>
                          ),
                        }}
                      />
                      {formik.touched.phone_number &&
                      formik.errors.phone_number &&
                      focusNo ? (
                        <div style={{ color: "red" }}>
                          {formik.errors.phone_number}
                        </div>
                      ) : null}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        marginTop: 1,
                      }}
                    >
                      <ReactQuill
                        value={job_description}
                        onChange={(value) => {
                          formik.setFieldValue("job_description", value);
                          setJob_description(value);
                        }}
                        modules={{
                          toolbar: [
                            ["bold", "italic", "underline", "strike"],
                            ["blockquote", "code-block"],
                            [{ list: "ordered" }, { list: "bullet" }],
                            [{ header: [1, 2, 3, 4, 5, 6, false] }],
                            [{ color: [] }, { background: [] }],
                            [{ font: [] }],
                            [{ align: [] }],
                            ["clean"],
                          ],
                        }}
                        placeholder="Write something amazing..."
                        className="scrollable-container-postjob"
                      />
                      {formik.touched.job_description &&
                      formik.errors.job_description ? (
                        <div style={{ color: "red" }}>
                          {formik.errors.job_description}
                        </div>
                      ) : null}
                    </Grid>

                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2, ml: 2 }}
                      disabled={
                        formik.isValid &&
                        formik.dirty &&
                        !isSubmitting &&
                        (job_description === "<p><br></p>") === false
                          ? from < to && from > today
                            ? false
                            : true
                          : true
                      }
                    >
                      Submit
                      {isSubmitting && (
                        <CircularProgress
                          size={24}
                          sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            marginTop: "-12px",
                            marginLeft: "-12px",
                          }}
                        />
                      )}
                    </Button>
                  </Grid>
                </Box>
              </Box>
            </form>
          </Grid>
        </Grid>
        <Outlet />
        <Dialog
          open={sucfulPostMsg}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{ "& .MuiPaper-root": { width: "500px" } }}
        >
          <DialogTitle
            id="alert-dialog-title"
            bgcolor="rgb(16 143 178)"
            sx={{ color: "white", fontWeight: 600 }}
          >
            {"Information"}
          </DialogTitle>
          <DialogContent>
            <Typography
              sx={{
                fontFamily: "Poppins-Medium",
                mt: 3,
                textAlign: "center",
                fontWeight: 700,
                color: "green",
              }}
            >
              {" "}
              <VerifiedIcon sx={{ fontSize: 50 }} />{" "}
            </Typography>{" "}
            <Typography
              sx={{
                fontFamily: "Poppins-Medium",
                color: "green",
                mt: 1,
                textAlign: "center",
                fontSize: "18px",
                fontWeight: 700,
              }}
            >
              Your Job Post Created Successfully
            </Typography>
            <Typography
              sx={{
                fontFamily: "Poppins-Medium",
                color: "#444",
                mt: 2,
              }}
            >
              Your Remaining Urgent Job Post Access Count is{" "}
              {postedJobData.no_of_hot_posting}
            </Typography>
            <Typography
              sx={{
                fontFamily: "Poppins-Medium",
                color: "#444",
                mt: 1,
              }}
            >
              Your Remaining Normal Job Post Access Count is{" "}
              {postedJobData.no_of_Standard_posting}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                routePostedPage();
                setSucfulPostMsg(false);
              }}
              autoFocus
            >
              ok
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={postedJobSubcription}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{ "& .MuiPaper-root": { width: "500px" } }}
        >
          <DialogTitle
            id="alert-dialog-title"
            bgcolor="rgb(16 143 178)"
            sx={{ color: "white", fontWeight: 600 }}
          >
            Subscribe
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" sx={{ mt: 3 }}>
              You want proceed job post , please Subscribe
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setPostedJobSubcription(false);
              }}
              size="small"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                navigate(
                  ROUTINGDATA.LAND +
                    ROUTINGDATA.MAIN +
                    ROUTINGDATA.SUBSCRIPTIONS,
                  {
                    state: userDetails.userId,
                  }
                );
              }}
              autoFocus
              size="small"
            >
              Subscribe
            </Button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    );
  }
}

export default PostJob;
