import { Box } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import useAxios from "../../../../api/useAxios";
import {
  Table,
  TableContainer,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  Card,
  Tooltip,
  TablePagination,
  Grid,
  Typography,
  Paper,
  Switch,
  Fab,
  Chip,
  Skeleton,
  FormControl,
  Select,
  MenuItem,
  IconButton,
  InputLabel,
  TextField,
  OutlinedInput,
  Autocomplete,
} from "@mui/material";
import AvatarEditor from "react-avatar-editor";
import EditIcon from "@mui/icons-material/Edit";

import {
  CheckRounded as CheckRoundedIcon,
  RefreshSharp as RefreshSharpIcon,
  SignalCellularNullRounded,
} from "@mui/icons-material/";
import { ShareLocationRounded as ShareLocationRoundedIcon } from "@mui/icons-material";
import CircularProgress from "@mui/material/CircularProgress";
import { ReactComponent as Warning } from "../../../../img/admin-panel/warning-circle-svgrepo-com.svg";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import Popper from "@mui/material/Popper";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import defaultimg from "../../../../img/addefaultimage.jpg";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment/moment";
import "jspdf-autotable";
import ExportCS from "../../../admin-panel/admin-components/ExportComponents/ExportCS.js";
import ExportPDF from "../../../admin-panel/admin-components/ExportComponents/ExportPDF.js";
import { useContext } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "../../../Landingpage/Landingpage.css";
import DeleteIcon from "@mui/icons-material/Delete";
import { Slide, Slider } from "@mui/material";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function AdPaymentSettings() {
  const [getApi, setGetApi] = useState([]);
  const [getApiFull, setGetApiFull] = useState([]);
  const [useApi, setUseApi] = useState([]);
  const [page, setPage] = useState(0);
  const [filterPosition, setFilterPosition] = useState("imageAd");
  const [filterStatus, setFilterStatus] = useState("");
  const [loading, setLoading] = useState({ isLoading: true, dialogLoad: true });
  const [totalRows, setTotalRows] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [getOne, setGetOne] = useState("");
  const [getOneAD, setGetOneAD] = useState("");
  const [getOneAdDetails, setGetOneAdDetails] = useState({});
  const [postJopEditPopup, setPostJopEditPopup] = useState(false);
  const [isValid, setIsValid] = useState(true);
  // <!----SORt--->
  const [confirmCloseAd, setConfirmCloseAd] = useState(false);
  const axiosData = useAxios();
  const baseUrl = axiosData.defaults.baseURL;
  const [widthSize, setWidthSize] = useState("1092px");
  const [heightSize, setheightSize] = useState("275px");
  const [videoAd, setVideoAd] = useState("");
  const [toShowImage, setToShowImage] = useState([]);
  const [uploadedImages, setUploadedImages] = useState(null);
  const [oneWeek, setOneweek] = useState("");
  const [twoWeek, setTwoweek] = useState("");
  const [threeWeek, setThreeweek] = useState("");
  const [fourWeek, setFourweek] = useState("");
  const [fifteenDays, setFifteenDays] = useState("");
  const [thirtyDays, setThirtyDays] = useState("");
  const [addGetPaymentDistrict, setAddGetPaymentDistrict] = useState([]);
  const [addGetPayment, setAddGetPayment] = useState([]);
  const [onOffset, setonOffset] = useState({
    check: "",
    ad_id: "",
  });
  const getAddDetails = async (id) => {
    try {
      const response = await axiosData.get(`addsdetails/${id}`, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.data) {
        const week = response.data;
        setGetOneAdDetails(week);
      }
      if (response.status === 204 || response.data.length === 0) {
        //setLoading(false);
        //setAddGetList("");
      }
    } catch (err) {
      console.log(err);
    }
  };
  const getpaymentforallpincode = async () => {
    try {
      const getOneAxios = await axiosData.get(
        `/General/adPaymentForSelectAllDistrict`
      );
      const data = getOneAxios.data.collections;
      if (data) {
        setAddGetPaymentDistrict(data);
      }
    } catch (err) {
      console.log("Error", err);
    }
    try {
      const getOneAxios = await axiosData.get(
        `/General/adPaymentForSelectAllPincode`
      );
      const data = getOneAxios.data.collections;
      if (data) {
        setAddGetPayment(data);
      }
    } catch (err) {
      console.log("Error", err);
    }
  };
  const handleClickFilter = (data) => {  
    if (filterPosition === "imageAd") {
        setUseApi(data);
        //setsearchicon(true);
        setTotalRows(data.length);
        setPage(0);
    } else {
        setUseApi(data);
        //setsearchicon(true);
        setTotalRows(data.length);
        setPage(0);
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const fetchApi = async () => {
    setLoading({ ...loading, isLoading: true });
    try {
      const useAxiosData = await axiosData.get(`addsdetails`);
      const  datavalue  = useAxiosData.data;
      
      if (useAxiosData.status === 204) {
        setGetApi([]);
        setLoading({ ...loading, isLoading: false });
      } else {
        const data = datavalue.filter((d)=>d.position !="fullpageimage")
        const datafull = datavalue.filter((d)=>d.position ==="fullpageimage")
        datafull.reverse();
        data.reverse();
        setGetApi(data);
        setGetApiFull(datafull);
        handleClickFilter(data);
       // setsearchicon(true);
        setPage(page);
        setLoading({ ...loading, isLoading: false });
      }
    } catch (err) {
      console.log("Error", err);
    }
  };
  const updateAd = async (values) => {
    setLoading({ ...loading, isLoading: true });
    const id = getOneAdDetails._id;
    try {
      const response = await axiosData.put(
        `addsdetails/Details/${id}`,
        values,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.status === 201) {
        window.location.reload();
        setPostJopEditPopup(false);
        fetchApi();
      } else if (response.status === 403) {
      }
    } catch (err) {}
  };
  useEffect(() => {
    fetchApi();
    setFilterPosition("imageAd");
    setFilterStatus("");
  }, []);
  const postDataEdit = (data, _id) => {
    setPostJopEditPopup(true);
    setWidthSize(data.width);
    setheightSize(data.height);
    setOneweek(data.oneweek);
    setTwoweek(data.twoweek);
    setThreeweek(data.threeweek);
    setFourweek(data.fourweek);
    setFifteenDays(data.fifteendays);
    setThirtyDays(data.thirtydays);
    // formik.setFieldValue("Ad_district", data.Ad_district);
    // formik.setFieldValue("position", data.position);
    // formik.setFieldValue("days_conut", data.days_conut);
    // formik.setFieldValue("Ad_pincode", data.Ad_pincode);
    //setUploadedImages(data.addUpload)
  };
  if (!loading.isLoading) {
    return (
      <>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <Card sx={{ padding: "20px" }}>
            <Paper>
              <Box
                sx={{
                  padding: "20px",
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                  backgroundColor: "#f2f2f2",
                }}
              >
                <Grid container spacing={3}>
                  <Grid item xs={6} sm={2} md={2.4}>
                    <FormControl
                      sx={{ width: { md: "100%", xs: "100%", sm: "100%" } }}
                    >
                      <InputLabel id="demo-simple-select-label">
                      Filter By Ad Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={filterPosition}
                        label="Filter By Ad Type"
                        onChange={(e) => {
                          setFilterPosition(e.target.value);
                          if(e.target.value === "fullpageimage"){
                            handleClickFilter(getApiFull);
                          }else{
                          handleClickFilter(getApi);
                          }
                        //   handleSelectPosition(
                        //     getApi,
                        //     e.target.value,
                        //     filterStatus
                        //   );
                        }}
                      >
                        <MenuItem value={"imageAd"}>Image Ad Amounts</MenuItem>
                        {/* <MenuItem disabled value={"videoads"}>Video Ad Amounts</MenuItem> */}
                        <MenuItem value={"fullpageimage"}>Full Page Ad Amounts</MenuItem>
                        <MenuItem disabled value={"allpincodeimage"}>All Pincode Ad Amounts</MenuItem>
                        <MenuItem disabled value={"alldistrictimage"}>All District Ad Amounts</MenuItem>   
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
            <TableContainer
              component={Paper}
              sx={{ marginTop: "10px", boxShadow: 0, maxHeight: 440 }}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow className="table-row">
                    <TableCell>Ad Position</TableCell>
                    {filterPosition === "fullpageimage"?null:
                    (<><TableCell>Width</TableCell>
                    <TableCell>Height</TableCell></>)}
                    {filterPosition === "fullpageimage"?<TableCell>
                              One Day
                            </TableCell>:null}
                    <TableCell> 1 Week Amount</TableCell>
                    <TableCell>2 Weeks Amount</TableCell>
                    <TableCell>3 Weeks Amount</TableCell>
                    <TableCell>4 Weeks Amount</TableCell>
                    <TableCell>15 Days Amount</TableCell>
                    <TableCell>30 Days Amount</TableCell>
                    <TableCell align="center"> Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {useApi.length > 0 ? (
                    <>
                      {useApi

                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((item) => (
                          <TableRow key={item._id} hover className="tablecell">
                            <TableCell align="left">
                              {" "}
                              {item.position === "bottomimagefooter"
                                ? "Bottom Ad"
                                : item.position === "popupimage"
                                ? "Popup Ad"
                                : item.position === "bottomimage"
                                ? "Flash Ad"
                                : item.position === "topimage"
                                ? "Top Ad"
                                : item.position === "sideimageright"
                                ? "Side Ad Right"
                                : item.position === "sideimageleft"
                                ? "Side Ad Left"
                                : item.position === "instagrampostsize"
                                ? "Instagrampost Ad"
                                : item.position === "facebookpostsize"
                                ? "Facebookpost Ad"
                                : item.position === "popupfacebookimage"
                                ? "Poppup Facebookpost Ad"
                                : item.position === "centerimage"? "Center Ad":item.position === "fullpageimage"? "Full Page Ad":""}{" "}
                            </TableCell>
                            {filterPosition === "fullpageimage"?null:
                            (<><TableCell>{item.width}</TableCell>
                            <TableCell>
                              {item.height}
                            </TableCell></>)}
                            {filterPosition === "fullpageimage"?<TableCell>
                              {item.oneday}
                            </TableCell>:null}
                            <TableCell>{item.oneweek}</TableCell>
                            <TableCell>{item.twoweek}</TableCell>
                            <TableCell>{item.threeweek}</TableCell>
                            <TableCell>{item.fourweek}</TableCell>
                            <TableCell>{item.fifteendays}</TableCell>
                            <TableCell>
                              {item.thirtydays}
                            </TableCell>
                            <TableCell align="center">
                              <Tooltip title="Edit Ad Details" arrow>
                                <IconButton
                                  aria-label="Example"
                                  sx={{ justifyContent: "end" }}
                                  onClick={() => {
                                    setGetOneAdDetails({});
                                    //setGetOneAD(item.addUpload.mimetype);
                                    getAddDetails(item._id);
                                    postDataEdit(item, item._id);
                                  }}
                                >
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>
                              
                            </TableCell>
                          </TableRow>
                        ))}
                    </>
                  ) : (
                    <>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell>
                          {" "}
                          <Typography
                            variant="body1"
                            sx={{ fontFamily: "Barlow-Bold" }}
                          >
                            NO Data Available
                          </Typography>
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25]}
              component="div"
              count={totalRows}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Box>
       
        <Dialog
          open={postJopEditPopup}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {`Update Ad Amounts`}
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
            {filterPosition === "fullpageimage"?null:(<>
            <Grid item xs={6}>
                <TextField
                  margin="normal"
                  id="width"
                  label="Width Size"
                  name="width"
                  onChange={(e) => {
                    setIsValid("");
                    if (e) {
                      const inputValue = e.target.value;
                      setWidthSize(inputValue);
                    }
                  }}
                  value={widthSize}
                />
                </Grid>
                <Grid item xs={6}>
                 <TextField
                  margin="normal"
                  id="height"
                  label="Height Size"
                  name="height"
                  onChange={(e) => {
                    setIsValid("");
                    if (e) {
                      const inputValue = e.target.value;
                      setheightSize(inputValue);
                    }
                  }}
                  value={heightSize}
                />
                </Grid></>)}
              <Grid item xs={6}>
                <TextField
                  margin="normal"
                  id="oneweek"
                  label="One Week"
                  name="oneweek"
                  onChange={(e) => {
                    setIsValid("");
                    if (e) {
                      const inputValue = e.target.value;
                      setOneweek(inputValue);
                    }
                  }}
                  value={oneWeek}
                />
                </Grid>
                <Grid item xs={6}>
                 <TextField
                  margin="normal"
                  id="twoweek"
                  label="Two Week"
                  name="twoweek"
                  onChange={(e) => {
                    setIsValid("");
                    if (e) {
                      const inputValue = e.target.value;
                      setTwoweek(inputValue);
                    }
                  }}
                  value={twoWeek}
                />
                </Grid>
              <Grid item xs={6}>
                <TextField
                  margin="normal"
                  id="threeweek"
                  label="Three Week"
                  name="threeweek"
                  onChange={(e) => {
                    setIsValid("");
                    if (e) {
                      const inputValue = e.target.value;
                      setThreeweek(inputValue);
                    }
                  }}
                  value={threeWeek}
                /></Grid>
                 <Grid item xs={6}>
                <TextField
                  margin="normal"
                  id="fourweek"
                  label="Four Week"
                  name="fourweek"
                  onChange={(e) => {
                    setIsValid("");
                    if (e) {
                      const inputValue = e.target.value;
                      setFourweek(inputValue);
                    }
                  }}
                  value={fourWeek}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  margin="normal"
                  id="fifteendays"
                  label="15 Days"
                  name="fifteendays"
                  onChange={(e) => {
                    setIsValid("");
                    if (e) {
                      const inputValue = e.target.value;
                      setFifteenDays(inputValue);
                    }
                  }}
                  value={fifteenDays}
                />
                </Grid>
                <Grid item xs={6}>
                 <TextField
                  margin="normal"
                  id="thirtydays"
                  label="30 Days"
                  name="thirtydays"
                  onChange={(e) => {
                    setIsValid("");
                    if (e) {
                      const inputValue = e.target.value;
                      setThirtyDays(inputValue);
                    }
                  }}
                  value={thirtyDays}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setPostJopEditPopup(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                const value = {
                  oneweek:oneWeek,
                  twoweek:twoWeek,
                  threeweek:threeWeek,
                  fourweek:fourWeek,
                  fifteendays:fifteenDays,
                  thirtydays:thirtyDays,
                  width:widthSize,
                  height:heightSize
                };
                updateAd(value);
              }}
            >
              Update
            </Button>
          </DialogActions>
        </Dialog>
      
        <Dialog
          sx={{
            "& .MuiPaper-root": {
              backgroundColor: "#339fcd",
              borderRadius: "20px",
            },
          }}
          open={confirmCloseAd}
          fullWidth
        >
          <Box>
            <Box>
              <DialogContent>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "space-around",
                    height: "100%",
                  }}
                >
                  <Box sx={{ paddingTop: "10px" }}>
                    <Warning height={100} width={100} />
                  </Box>
                  <Typography
                    variant="h4"
                    sx={{ paddingTop: "10px", fontFamily: "Poppins-SemiBold" }}
                  >
                    {" "}
                    Are you sure?
                  </Typography>
                  <Typography
                    sx={{
                      paddingTop: "10px",
                      fontFamily: "Poppins-Medium",
                      color: "white",
                    }}
                  >
                    Once you Submitted,You can't modify your advertisement
                  </Typography>

                  <Box
                    sx={{
                      paddingTop: "20px",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                      width: "100%",
                    }}
                  >
                    <Button
                      onClick={() => {
                        setConfirmCloseAd(false);
                        setonOffset({
                          ...onOffset,
                          check: "",
                          ad_id: "",
                        });
                      }}
                      variant="outlined"
                      sx={{
                        backgroundColor: "#f2f2f2",
                        textTransform: "lowercase",

                        color: "black",
                        borderColor: "white",
                        fontFamily: "Poppins-Medium",
                        "&:hover": {
                          textTransform: "lowercase",
                          color: "#f2f2f2",
                          backgroundColor: "#339fcd",
                          borderColor: "#f2f2f2",
                          fontFamily: "Poppins-Medium",
                        },
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="outlined"
                      sx={{
                        textTransform: "lowercase",

                        color: "white",
                        backgroundColor: "#ed1067",
                        borderColor: "#ed1067",
                        fontFamily: "Poppins-Medium",
                        transition: "all 0.3s",
                        "&:hover": {
                          textTransform: "lowercase",

                          color: "#ed1067",
                          borderColor: "#ed1067",
                          fontFamily: "Poppins-Medium",
                        },
                      }}
                      onClick={() => {
                        //updateASettings(onOffset.check, onOffset.ad_id);
                        setConfirmCloseAd(false);
                        fetchApi();
                      }}
                    >
                      Confirm
                    </Button>
                  </Box>
                </Box>
              </DialogContent>
            </Box>
          </Box>
        </Dialog>
      </>
    );
  } else {
    return (
      <>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <Card sx={{ padding: "20px", borderRadius: "20px" }}>
            <Skeleton
              animation="wave"
              variant="text"
              width={230}
              height={40}
              sx={{ borderRadius: "15px" }}
            />
          </Card>
          <Card
            sx={{
              borderRadius: "20px",

              padding: "20px",

              height: "500px",
              gap: "20px",
            }}
          >
            <Skeleton variant="rounded" />
            <Skeleton variant="rounded" width="100%" height="300px" />
          </Card>
        </Box>
      </>
    );
  }
}

export default AdPaymentSettings;
