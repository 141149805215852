import React from "react";
import { Box, Card, Grid, styled, Typography, Tooltip } from "@mui/material";
import CountUp from "react-countup";
import {
  Diversity3 as Diversity3Icon,
  Apartment as ApartmentIcon,
  LocalLibrary as LocalLibraryIcon,
  PlayLesson as PlayLessonIcon,
  Pending as PendingIcon,
} from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTINGDATA } from "../../../ROUTINGDATA";

const StyledCard = styled(Card)(({ theme }) => ({
  "&.MuiCard-root": {
    [theme.breakpoints.down("sm")]: {
      padding: "16px !important",
      flexDirection: "row",
      gap: "10px",
    },

    padding: "25px",
    borderRadius: "15px",
    display: "flex",
    flexDirection: "column",
    alignContent: "space-between",
    boxShadow: "0 0 8px 2px rgba(0,0,0,0.1) !important",
    transition: "0.2s",
  },
}));

function VoluteerTypeOfsupport({ state }) {
  const navigate = useNavigate();
  const location = useLocation();
  const currentUrl = location.pathname;
  const Training = state.filter((item) => {
    return item.TypeOfSupport === "Training";
  });
  const Accomodation = state.filter((item) => {
    return item.TypeOfSupport === "Accommodation";
  });
  const CourseFees = state.filter((item) => {
    return item.TypeOfSupport === "Course Fees";
  });
  const Mentorship = state.filter((item) => {
    return item.TypeOfSupport === "Mentorship";
  });
  const Others = state.filter((item) => {
    return item.TypeOfSupport === "Others";
  });

  const volunteerDashRoute =
    ROUTINGDATA.LAND +
    ROUTINGDATA.volunteerPanel +
    ROUTINGDATA.volunteerDashboard;

  const [training, accomodation, courseFees, mentorship, others] = [
    Training,
    Accomodation,
    CourseFees,
    Mentorship,
    Others,
  ];

  const cardList = [
    {
      name: "Training",
      count: training.length,
      bgColor: "#ffa07a",
      svg: <LocalLibraryIcon fontSize="large" />,
      filterValue: "Training",
    },
    {
      name: "Accommodation",
      count: accomodation.length,
      bgColor: "#6b8e23",
      svg: <ApartmentIcon fontSize="large" />,
      filterValue: "Accommodation",
    },
    {
      name: "Course Fees",
      count: courseFees.length,
      bgColor: "#0077b5",
      svg: <PlayLessonIcon fontSize="large" />,
      filterValue: "Course Fees",
    },
    {
      name: "MentorShip",
      count: mentorship.length,
      bgColor: "#ffa07a",
      svg: <Diversity3Icon fontSize="large" />,
      filterValue: "Mentorship",
    },
    {
      name: "Others",
      count: others.length,
      bgColor: "#ffa07a",
      svg: <PendingIcon fontSize="large" />,
      filterValue: "Others",
    },
  ];
  const handleNeedAllClick = (event) => {
    sessionStorage.setItem("mpneedall_active", event);
    sessionStorage.removeItem("mpneedshort_activ");
    sessionStorage.removeItem("mpall_active");
  };
  const handleClick = (event) => {
    sessionStorage.setItem("vol_act", event);
  };
  // const handleClick = (event) => {
  //   if (currentUrl == MLAroute) {
  //     sessionStorage.setItem("active", event);
  //     sessionStorage.removeItem("mpmlaneedshort_active");
  //     sessionStorage.removeItem("mpmlaneedall_active");
  //     sessionStorage.removeItem("activeneed");
  //   } else {
  //     sessionStorage.setItem("mp_active", event);

  //     if (event === "dash") {
  //       sessionStorage.removeItem("mpneed_active");
  //       sessionStorage.removeItem("mpall_active");
  //       sessionStorage.removeItem("mpneedshort_active");
  //       sessionStorage.removeItem("mpneedall_active");
  //     }
  //   }
  // };
  const handleNeedClick = (event) => {
    sessionStorage.setItem("mpneed_active", event);
    sessionStorage.removeItem("mpall_active");
  };
  const handleClickLog = (event) => {
    sessionStorage.setItem("activeneed", event);
    sessionStorage.removeItem("active");
  };

  const handleAllClick = (event) => {
    sessionStorage.setItem("mpmlaneedall_active", event);
    sessionStorage.removeItem("mpmlaneedshort_active");
    sessionStorage.removeItem("active");
  };
  const handleShortClick = (event) => {
    sessionStorage.setItem("mpmlaneedshort_active", event);
    sessionStorage.removeItem("mpmlaneedall_active");
    sessionStorage.removeItem("active");
  };

  return (
    <>
      <Grid
        container
        direction="row"
        spacing={5}
        md={12}
        sx={{ mb: "24px", width: "100%" }}
      >
        {cardList.map((item, index) => (
          <Grid item xs={12} md={3} key={index}>
            <Tooltip
              title={
                currentUrl == volunteerDashRoute ? "Go to this filter" : ""
              }
              arrow
            >
              <StyledCard
                elevation={6}
                sx={{
                  "&.MuiCard-root:hover": {
                    boxShadow: `${
                      currentUrl == volunteerDashRoute
                        ? "0 0 8px 2px rgba(0,0,0,0.4) !important"
                        : "0 0 8px 2px rgba(0,0,0,0.1) !important"
                    }`,
                    cursor: `${
                      currentUrl == volunteerDashRoute ? "pointer" : "default"
                    }`,
                  },
                }}
                onClick={() => {
                  // sessionStorage.setItem(`shortradio${ind}`, null);
                  // sessionStorage.setItem(
                  //   `mpmlaneedradio${ind}`,
                  //   item.filterValue
                  // );
                  if (currentUrl == volunteerDashRoute) {
                    navigate(
                      ROUTINGDATA.LAND +
                        ROUTINGDATA.volunteerPanel +
                        "/" +
                        ROUTINGDATA.volunteerNeedsupport,
                      {
                        state: {
                          //   mla: state,
                          volunteerRadioValue: item.filterValue,
                          //   index: ind,
                        },
                      }
                    );
                    handleClick("needsupport");
                  }
                }}
              >
                <Box>
                  <Box
                    sx={{ display: "flex", flexDirection: "row", gap: "10px" }}
                  >
                    <Box sx={{ color: `${item.bgColor}` }}>{item.svg}</Box>
                    <CountUp
                      style={{
                        fontSize: "26px",
                        color: `${item.bgColor}`,
                      }}
                      start={0}
                      end={item.count}
                      duration={1}
                    />
                  </Box>
                </Box>

                <Box>
                  <Typography
                    variant="body1"
                    sx={{ fontFamily: "Poppins-SemiBold", color: "inherit" }}
                  >
                    {item.name}
                  </Typography>
                </Box>
              </StyledCard>
            </Tooltip>
          </Grid>
        ))}
      </Grid>
    </>
  );
}

export default VoluteerTypeOfsupport;
