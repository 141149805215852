import React, { useEffect } from "react";
import "../Landingpage/Landingpage.css";


import Landingheaderpage from "./landingpagecontext/Landingheaderpage";
import Landingpagecontext from "./landingpagecontext/Landingpagecontext";
import { Outlet } from "react-router-dom";

const Landingpage = (props) => {
 
  const currentScrollPosition =
  document.documentElement.scrollTop || document.body.scrollTop;  

  return (
    <div className="landingcss" >
      <div className="headerlandingpage">
       
        <Landingheaderpage setlanguagetranslator={props.setlanguagetranslator}/>
      </div>
      <div>
       <Outlet />
      </div>
    </div>
  );
};

export default Landingpage;
