import React, { useState, useEffect } from "react";
import { Box, Card, Typography, Skeleton } from "@mui/material";

import StatMPcards from "../../../admin-panel/admin-components/StatCards/StatMPcards";
import useAxios from "../../../../api/useAxios";
import SkeletonSeekCard from "../../../admin-panel/admin-components/StatCards/SkeletonSeekCard";
import StatMLAcards from "../../../admin-panel/admin-components/StatCards/StatMLAcards";
import StatCardForNeedSupport from "../../../admin-panel/admin-components/StatCards/StatCardForNeedSupport";
function MLAdashboard() {
  const [freshers, setfreshers] = useState([]);
  const [need, setneed] = useState([]);
  const [registered, setRegistered] = useState([]);
  const [fulldata, setfulldata] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const mla = JSON.parse(localStorage.getItem("mla"));

  const axiosData = useAxios();
  const getMpEmailId = async () => {
    try {
      const MpData = await axiosData.post("mpmla/mlaconstituencyseeker", {
        email_id: mla,
      });
      const { data } = MpData;
      setfulldata(data[0]);
      setRegistered(data[0].seekers);
      setneed(data[0].needSupport);
      setfreshers(data[0].totalFresher);
      setIsLoading(false);
    } catch (err) {
      console.log("Error", err);
    }
  };

  useEffect(() => {
    getMpEmailId();
  }, []);
  if (!isLoading) {
    return (
      <>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <Card sx={{ padding: "20px" }}>
            <Typography
              variant="h6"
              sx={{ fontFamily: "Barlow-Black", textTransform: "capitalize" }}
            >
              {fulldata.mla_constituency}'s Dashboard
            </Typography>
          </Card>

          <Card>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: "20px",
              }}
            >
              <StatMPcards
                registered={registered.length}
                freshers={freshers.length}
                need={need.length}
              />
            </Box>
          </Card>
          <Card sx={{ padding: "20px" }}>
            <Typography variant="h6" sx={{ fontFamily: "Barlow-Black" }}>
              Need Support
            </Typography>
          </Card>
          <StatCardForNeedSupport state={fulldata} />
          <Card sx={{ padding: "20px" }}>
            <Typography variant="h6" sx={{ fontFamily: "Barlow-Black" }}>
              Types of Support
            </Typography>
          </Card>
          <StatMLAcards state={fulldata} />
        </Box>
      </>
    );
  } else {
    return (
      <>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <Card sx={{ padding: "20px", borderRadius: "20px" }}>
            <Skeleton
              animation="wave"
              variant="text"
              width={230}
              height={40}
              sx={{ borderRadius: "15px" }}
            />
          </Card>

          <Card>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: "20px",
              }}
            >
              <SkeletonSeekCard />
            </Box>
          </Card>
        </Box>
      </>
    );
  }
}

export default MLAdashboard;
