import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import useAxios from "../../../api/useAxios";
import { saveAs } from "file-saver";
import {
  Card,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Tooltip,
  Fab,
  Switch,
  CardHeader,
  Select,
  MenuItem,
  FormControl,
  CardContent,
  Grid,
  Container,
  Paper,
  Typography,
  Button,
  Divider,
  Backdrop,
  CircularProgress,
  FormLabel,
  Chip,
  IconButton,
  Skeleton,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import { ROUTINGDATA } from "../../ROUTINGDATA";
import Checkbox from '@mui/material/Checkbox';
import TextField from "@mui/material/TextField";
import {
  OpenInNewRounded as OpenInNewRoundedIcon,
  ArrowBackRounded as ArrowBackRoundedIcon,
  VisibilityRounded as VisibilityRoundedIcon,
} from "@mui/icons-material";
import Autocomplete from "@mui/material/Autocomplete";
import Breadcrumb from "../.././Dashboards/BreadCrumb";
import SeekerDialog from "./DialogBoxes/SeekerDialog";
import MentorDialog from "./DialogBoxes/MentorDialog";
import SkeletonSeekCard from "./StatCards/SkeletonSeekCard";
import DescriptionIcon from '@mui/icons-material/Description';
import {
  CheckCircleOutline as CheckCircleOutlineIcon,
  WorkRounded as WorkRoundedIcon,
  PersonSearchRounded as PersonSearchRoundedIcon,
  VolunteerActivism as VolunteerActivismIcon,
  PeopleAltRounded as PeopleAltRoundedIcon,
  VerifiedUser as VerifiedUserIcon,
  ContactSupport as ContactSupportIcon,
  EngineeringRounded as EngineeringRoundedIcon,
} from "@mui/icons-material";
import WorkIcon from '@mui/icons-material/Work';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import HailIcon from '@mui/icons-material/Hail';
const CustomBackDrop = styled(Backdrop)(({ theme }) => ({
  color: "white ",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  position: "absolute",
  zIndex: theme.zIndex.drawer - 1,
  opacity: 0.5,
  borderRadius: "15px",
}));

function SeekerVelaivendumSupport() {
  const axiosData = useAxios();
  const baseUrl = axiosData.defaults.baseURL;
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state.item;
  const currentUrl = location.pathname;
  const [familyApi, setFamilyApi] = useState({});
  const [oneVerifier, setOneVerifier] = useState({});
  const [verifierApi, setVerifierApi] = useState([]);
  const [supportList, setSupportList] = useState([]);
  const [mentorList, setMentorList] = useState([]);
  const [mentorName, setMentorName] = useState("");
  const [mentorEmail, setMentorEmail] = useState("");
  const [checkedvalue, setCheckedvalue] = useState([]);
console.log("checkedvalue",checkedvalue)
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openmentor, setOpenmentor] = useState(false);
  const [skipOpen, setSkipOpen] = useState(false);
  const [getOne, setGetOne] = useState({});
  const [getOneMentor, setGetOneMentor] = useState({});
  const [action, setAction] = useState({
    button: "",
    api: "",
  });
  const [verifierName, setverifierName] = useState(
    oneVerifier.full_name
      ? `${oneVerifier.full_name}(${oneVerifier.email_id})`
      : "Not Yet Assigned"
  );
  const [openState, setOpenState] = useState({
    adminVerify: false,
    tick: false,
    close: false,
    loader: false,
    assign: true,
    select: false,
    oneVerifierAndFamilyApi: false,
    isLoading: false,

    buttonDisable: false,
  });
  const getsupportlistforseeker = async () => {
    setLoading(true);
    try {
      const getOneAxios = await axiosData.get(
        `/General/seekervelaivendumsupport`
      );
      const data = getOneAxios.data.collections;
      console.log("getOneAxios", getOneAxios.data.collections);
      if (data) {
        const valuesList = Object.values(data);
        setSupportList(valuesList);
        setLoading(false);
        getOneData();
      }
    } catch (err) {
      console.log("Error", err);
    }
  };
  useEffect(() => {
    setSupportList([]);
    getsupportlistforseeker();
   
    mentorListget();
  }, []);
  const mentorListget = async () => {
    setMentorList([])
    try {
      const getOneAxios = await axiosData.get(`/mentor`);
      const data = getOneAxios.data;
      if (data) {
        const getmentor = data.map((i) => {
          return i;
        });
        console.log("data", data, getmentor);
        //getmentor.sort();
        setMentorList(getmentor);
      }
      return data;
    } catch (err) {
      console.log("Error", err);
    }
  };
  const getOneDataMentor = async (id) => {
    console.log("id",id)
    try {
      const getOneAxios = await axiosData.get(
        `/mentor/email/get/${id}`
      );
      const { data } = getOneAxios;
      setGetOneMentor(data);
      console.log("dataone", data);
    } catch (err) {
      console.log("Error", err);
    }
  };
  const getOneData = async (id) => {
    try {
      const getOneAxiosSeeker = await axiosData.get(
        `seekers/email/${state.email_id}`
      );
      const  dataSeeker  = getOneAxiosSeeker.data;
      setGetOne(dataSeeker);
      try {
        const getOneAxiosGet = await axiosData.get(
          `/vvsupports/${state.email_id}`
        );
        const  dataGet  = getOneAxiosGet.data;
        console.log("dataonecheck", getOneAxiosGet.data);
        if(dataGet.Mentorship === "Pending"){
          setCheckedvalue([...checkedvalue,"Mentorship"]);
        }else{
         
        }
        if(getOneAxiosGet.data === 0){
          try {
            const getOneAxiosPost = await axiosData.post(
              `/vvsupports`,{
                email_id :state.email_id,
                full_name:state.full_name,
                mobile_number:state.mobile_number,
              }
            );
            const  dataPost  = getOneAxiosPost.data;
          } catch (err) {
            console.log("Error", err);
          }
        }else{
          console.log("first")
        }
      } catch (err) {
        console.log("Error", err);
      }
      
     
    } catch (err) {
      console.log("Error", err);
    }
  };
  
  const handleChange = () => {
    setOpen(!open);
  };
  const handleChangementor = () => {
    setOpenmentor(!openmentor);
  };
  const switchProps = [
    { name: supportList[0], icon: <WorkRoundedIcon fontSize="medium" /> },
    {
      name: supportList[1],
      icon: <PersonSearchRoundedIcon fontSize="medium" />,
    },
    { name: supportList[2], icon: <VolunteerActivismIcon fontSize="medium" /> },
    { name: supportList[3], icon: <HailIcon fontSize="medium" /> },
    { name: supportList[4], icon: <ContactSupportIcon fontSize="medium" /> },
    { name: supportList[5], icon: <DescriptionIcon fontSize="medium" /> },
    { name: supportList[6], icon: <EngineeringRoundedIcon fontSize="medium" /> },
    { name: supportList[7], icon: <WorkOutlineIcon fontSize="medium" /> },
    { name: supportList[8], icon: <WorkIcon fontSize="medium" /> },
  ];
  if (!loading) {
    return (
      <>
        {" "}
        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <Box display="flex" justifyContent="space-between">
            <Tooltip title="Go Back">
              <Fab
                onClick={() => navigate(-1)}
                size="small"
                sx={{
                  boxShadow: 0,
                  backgroundColor: "#339fcd",
                  color: "white",
                  "&:hover": { backgroundColor: "#217396" },
                }}
              >
                <ArrowBackRoundedIcon />
              </Fab>
            </Tooltip>
            <Breadcrumb />
          </Box>
          <Card
            sx={{ borderRadius: "15px", boxShadow: "3px 3px 10px 3px #dddddd" }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                padding: "20px",
              }}
            >
              <span className="seeker-text">{getOne.full_name}'s Details</span>
              <Tooltip title="Full Details">
                <IconButton
                  onClick={() => setOpen(true)}
                  sx={{
                    color: "#339fcd",
                    transition: "0.3s",
                    "&:hover": { backgroundColor: "#217396", color: "#ffffff" },
                  }}
                >
                  <VisibilityRoundedIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Card>
          <Card
            sx={{
              boxShadow: "3px 3px 10px 3px #ddd",
              borderRadius: "6px",
            }}
          >
            <Container fixed>
              <CardContent>
                <Box display="flex" flexDirection={"column"} gap={"20px"}>
                  <Box display="flex" flexDirection={"column"} gap={"5px"}>
                    <Typography
                      variant="h5"
                      sx={{ fontFamily: "Poppins-SemiBold" }}
                    >
                      Velaivendum Support
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{ fontFamily: "Barlow-Medium" }}
                    >
                      100 days job accuracy{" "}
                    </Typography>
                  </Box>
                  {switchProps.map((item, index) => {
                    return (
                      <>
                        <div style={{ display: "grid",gridTemplateColumns:"35% 65%", gap: 20 }}>
                          <div style={{display:"flex",alignItems:"center"}}>
                            <ListItemIcon >{item.icon}</ListItemIcon> <span style={{ fontFamily: "Poppins-Regular" }}>{item.name}</span>
                          </div>
                          <div style={{display:"flex",alignItems:"center",gap:"20px"}}>
                            <Checkbox
                             checked={checkedvalue.map((d)=>{return d}) === item.name}
                              onChange={(e) => {
                                console.log("check",item.name,switchProps.indexOf(item.name),e.target.checked)
                                if(e.target.checked === true){
                                  setCheckedvalue([...checkedvalue,`${item.name}`]); 
                                }else{

                                }

                               
                              }}
                            />{" "}
                            {item.name === "Mentorship" ? (
                              <>
                                <Autocomplete
                                  disableClearable={mentorName ? false : true}
                                  disablePortal
                                  id="mentor_name"
                                  name="mentor_name"
                                  options={mentorList.map((d) => {
                                    return d.MentorName;
                                  })}
                                  value={mentorName}
                                  onChange={(event, value) => {
                                    const indec =mentorList.map((di)=>{
                                        if(di.MentorName.includes(value)){
                                            return di
                                        }else{

                                        }
                                    });
                                    console.log("mentorName",indec)
                                    const getmentoremail = indec.filter((s)=> s != undefined)
                                    console.log("getmentoremail",getmentoremail[0])
                                    setMentorEmail(getmentoremail[0].email_id)
                                    setMentorName(value);
                                  }}
                                   sx={{ width: 200, marginLeft: "10px" }}
                                  renderInput={(params) => (
                                    <TextField
                                      onChange={(e) => {
                                        setMentorName(e.target.value);
                                      }}
                                      {...params}
                                      label={"Select Mentor Name"}
                                    />
                                  )}
                                />
                                <Tooltip
                                  title="Mentor Full Details"
                                  placement="top"
                                >
                                  <IconButton
                                    className="icon-btn"
                                    size="small"
                                    onClick={() => {
                                    getOneDataMentor(mentorEmail)
                                      handleChangementor();
                                    }}
                                  >
                                    <OpenInNewRoundedIcon />
                                  </IconButton>
                                </Tooltip>
                              </>
                            ) : null} </div>
                        </div>
                      </>
                    );
                  })}
                  <div style={{textAlign:"right"}}>
                  <Button sx={{ boxShadow: 0,
                  backgroundColor: "#339fcd",
                  color: "white",
                  marginRight:"10px",
                  "&:hover": { backgroundColor: "#217396" },}}>Cancel</Button>
                  <Button sx={{ boxShadow: 0,
                  backgroundColor: "#339fcd",
                  color: "white",
                  "&:hover": { backgroundColor: "#217396" },}}>Update</Button>
                  </div>
                </Box>
              </CardContent>
            </Container>
          </Card>
          <SeekerDialog
            open={open}
            handleChange={handleChange}
            getOne={getOne}
            setOpen={setOpen}
          />
          {/* <MentorDialog
           open={openmentor}
           handleChange={handleChangementor}
           getOne={getOneMentor}
           setOpen={setOpenmentor}
          /> */}
        </Box>
      </>
    );
  } else {
    return (
      <>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <Card sx={{ padding: "20px", borderRadius: "20px" }}>
            <Skeleton
              animation="wave"
              variant="text"
              width={230}
              height={40}
              sx={{ borderRadius: "15px" }}
            />
          </Card>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            <Card
              sx={{
                borderRadius: "20px",

                padding: "20px",
              }}
            >
              <SkeletonSeekCard />
            </Card>
          </Box>
          <Card
            sx={{
              borderRadius: "20px",

              padding: "20px",

              height: "500px",
              gap: "20px",
            }}
          >
            <Skeleton variant="rounded" />
            <Skeleton variant="rounded" width="100%" height="300px" />
          </Card>
        </Box>
      </>
    );
  }
}

export default SeekerVelaivendumSupport;
