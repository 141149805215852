import React, { useState, useRef } from "react";
import AvatarEditor from "react-avatar-editor";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Slide,
  Slider,
  Tooltip,
  Typography,
  Grid,
} from "@mui/material";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import DialogTitle from "@mui/material/DialogTitle";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MentorProfile = ({ formikProps, uploadedImages, setUploadedImages }) => {
  const [image, setImage] = useState();
  const [open, setOpen] = useState(false);
  const [ref, setRef] = useState(false);
  const [limit, setLimit] = useState(false);
  const [picture, setPicture] = useState({
    cropperOpen: false,
    img: null,
    zoom: 2,
    croppedImg: "",
  });
  const [imageLimit, setImageLimit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const fileInputField = useRef(null);
  const [toShowImage, setToShowImage] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSlider = (event, value) => {
    setPicture({
      ...picture,
      zoom: value,
    });
  };

  const handleCancel = () => {
    setPicture({
      ...picture,
      cropperOpen: false,
    });
    fileInputField.current.value = "";
  };

  const setEditorRef = (ed) => {
    setRef(ed);
  };

  function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  const handleSave = () => {
    if (ref) {
      const canvasScaled = ref.getImageScaledToCanvas();
      const croppedImg = canvasScaled.toDataURL();

      if (croppedImg && uploadedImages.length < 1) {
        // Check if maximum number of images is not reached
        setPicture({
          ...picture,
          img: null,
          cropperOpen: false,
          croppedImg: croppedImg,
        });

        var block = croppedImg.split(";");

        var contentType = block[0].split(":")[1];
        var realData = block[1].split(",")[1];
        const img = b64toBlob(realData, contentType);
        setToShowImage(croppedImg);
        setUploadedImages((prevImages) => [...prevImages, img]);
      } else {
        setLimit(true);
        setImageLimit(true);
      }
    }
  };

  const [imageSize,setImageSize] = useState(false)
  const handleFileChange = (e) => {
    let imgFile = e.target.files[0];
  
    // Check if a file is selected
    if (!imgFile) {
      return;
    }
  
    // Check if the file size is less than 2MB
    const maxSize = 5 * 1024 * 1024; // 2MB in bytes
    if (imgFile.size > maxSize) {
      // Handle the case when the image exceeds the size limit
      setImageSize(true)
      fileInputField.current.value = "";
      return;
    }
  
    // If the file is valid, proceed with setting the state
    handleClickOpen();
    setPicture({
      ...picture,
      img: imgFile,
      cropperOpen: true,
    });
  };

  const deleteFile = () => {
    setUploadedImages([]);
    setToShowImage(null);
    setLimit(false);
    fileInputField.current.value = "";
  };

  return (
    <div>
      <Box>
        <Dialog
          TransitionComponent={Transition}
          open={imageLimit}
          sx={{ "& .MuiPaper-root": { width: "300px" } }}
        >
          <DialogTitle
            bgcolor="rgb(16 143 178)"
            sx={{ color: "white", fontWeight: 600 }}
          >
            <WarningAmberOutlinedIcon sx={{ mb: -0.5 }} /> Photo Upload Limit
          </DialogTitle>
          <DialogContent sx={{ mt: 1 }}>
            <DialogContentText sx={{ fontWeight: 600, mt: 2 }}>
              Note: Max 1 photos allowed
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => setImageLimit(false)}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          TransitionComponent={Transition}
          open={imageSize}
          sx={{ "& .MuiPaper-root": { width: "500px" } }}
        >
          <DialogTitle
            bgcolor="rgb(16 143 178)"
            sx={{ color: "white", fontWeight: 600 }}
          >
           <WarningAmberOutlinedIcon sx={{mb:-0.5}}/> Photo Upload Limit
          </DialogTitle>
          <DialogContent sx={{ mt: 1 }}>
            <DialogContentText sx={{ fontWeight: 600, mt:2 }}>
            Note: Image file size exceeds the allowed limit of 2MB. Please
              choose a smaller size image.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => setImageSize(false)}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        <Grid
          container
          rowSpacing={3}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{ mb: 2 }}
        >
          <Grid item xs={12} md={6} lg={6}>
            <div className="MentorImageContent">
              {isLoading === true ? (
                <CircularProgress
                  className="CircularProgressIcon"
                  size={26}
                  style={{
                    color: "rgba(25,118,210,1)",
                    position: "absolute",
                  }}
                />
              ) : (
                <>
                  <div className="MentorImageBorder">
                    <div>
                    <Tooltip title="Upload Image" arrow>
                      <AddPhotoAlternateOutlinedIcon
                        sx={{ fontSize: 50, cursor: "pointer" }}
                        onClick={() => {
                          fileInputField.current.click();
                        }}
                      />
                    </Tooltip>
                    </div>
                  <Typography variant="caption" sx={{ textAlign: "center" }}>
                    Upload your profile image
                  </Typography>
                  </div>
                </>
              )}
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <div className="RenderImageContent">
              {toShowImage && (
                <div className="RenderImageBorder">
                  <div>
                    <img
                      alt={`Uploaded`}
                      src={toShowImage}
                      className="MentorProfile"
                    />
                  </div>
                  <div className="MentorDelete" >
                    <Tooltip title="Delete Image" arrow>
                      <IconButton>
                        <DeleteIcon
                          className="iconsize"
                          sx={{ color: "#e94c4c", mb: -0.5 }}
                          onClick={() => {
                            deleteFile();
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              )}
            </div>
          </Grid>
        </Grid>

        <input
          disabled={limit}
          ref={fileInputField}
          style={{ visibility: "hidden" }}
          type="file"
          accept="image/*"
          onChange={handleFileChange}
        />
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {picture.cropperOpen && (
                <Box display="block">
                  <AvatarEditor
                    ref={setEditorRef}
                    image={picture.img}
                    width={250}
                    height={250}
                    border={20}
                    color={[255, 255, 255, 0.6]}
                    rotate={0}
                    scale={picture.zoom}
                  />
                  <Slider
                    aria-label="raceSlider"
                    value={picture.zoom}
                    min={1}
                    max={10}
                    step={0.1}
                    onChange={handleSlider}
                  />
                </Box>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                handleCancel();
                handleClose();
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                handleSave();
                handleClose();
              }}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </div>
  );
};

export default MentorProfile;
