import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Card,
  Stack,
  Skeleton,
  Box,
  Divider,
} from "@mui/material";

import useAxios from "../../../../api/useAxios";
import SkeletonSeekCard from "../../../admin-panel/admin-components/StatCards/SkeletonSeekCard";
import StatsForVolunteer from "../../../admin-panel/admin-components/StatCards/StatsForVolunteer";
import StatForVolunteer2 from "../../../admin-panel/admin-components/StatCards/StatForVolunteer2";
import StatForVolunteer3 from "../../../admin-panel/admin-components/StatCards/StatForVolunteer3";
import VolunteerNeedsupportCard from "../../../admin-panel/admin-components/StatCards/VolunteerNeedsupportCard";
import VoluteerTypeOfsupport from "../../../admin-panel/admin-components/StatCards/VoluteerTypeOfsupport";
function VolunteerDashboard() {
  const [getApi, setGetApi] = useState([]);
  const [getFamilyData, setGetFamilyData] = useState([]);
  const [getVolunteerData, setGetVolunteerData] = useState({});
  const verifierVolunteer = JSON.parse(localStorage.getItem("volunteer"));
  const [isLoading, setIsLoading] = useState(false);
  const axiosData = useAxios();
  const fetchApi = async () => {
    try {
      const useAxiosData = await axiosData.get("seekers");
      const { data } = useAxiosData;

      if (useAxiosData.status === 204) {
        setGetApi([]);
      } else {
        setGetApi(data);
      }
    } catch (err) {
      console.log("Error", err);
    }
  };
  const fetchFamilyDetails = async () => {
    try {
      const axiosFamilyData = await axiosData.get("family/needsupport");
      const { data } = axiosFamilyData;
      if (axiosFamilyData.status === 204) {
        setGetFamilyData([]);
      } else {
        const finalVerify = data.filter((item) => {
          return item.finalVerification === "Completed";
        });
        setGetFamilyData(finalVerify);
      }
    } catch (err) {
      console.log("Error", err);
    }
  };
  const getVolunteerByEmail = async () => {
    try {
      const useAxiosData = await axiosData.get(
        `volunteer/email/${verifierVolunteer}`
      );
      const { data } = useAxiosData;
      setGetVolunteerData(data);
    } catch (err) {
      console.log("Error", err);
    }
  };
  const initialLoad = () => {
    fetchApi();
    fetchFamilyDetails();
    getVolunteerByEmail();
    setIsLoading(true);
  };
  useEffect(() => {
    initialLoad();
  }, []);
  if (isLoading) {
    return (
      <>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <Card
            sx={{ borderRadius: "15px", boxShadow: "3px 3px 10px 3px #dddddd" }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                padding: "10px",
              }}
            >
              <h3 className="seeker-text">Dashboard</h3>
            </Box>
          </Card>

          <Grid container spacing={2}>
            <Grid item md={12}>
              <Card sx={{ borderRadius: "20px", boxShadow: "none" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingTop: "20px",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <Box>
                    <Divider textAlign="left" sx={{ width: "100%" }}>
                      <span className="seeker-text">All Seekers Data</span>
                    </Divider>
                  </Box>

                  <StatsForVolunteer
                    seeker={getApi}
                    family={getFamilyData}
                    volunteer={getVolunteerData}
                  />
                </Box>
              </Card>
            </Grid>
            <Grid item md={12}>
              <Card sx={{ borderRadius: "20px", boxShadow: "none" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingTop: "20px",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <Box>
                    <Divider textAlign="left" sx={{ width: "100%" }}>
                      <span className="seeker-text">Need Support Data</span>
                    </Divider>
                  </Box>

                  <VolunteerNeedsupportCard
                    state={getFamilyData}
                    email={verifierVolunteer}
                  />
                </Box>
              </Card>
              <Card sx={{ borderRadius: "20px", boxShadow: "none" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingTop: "20px",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <Box>
                    <Divider textAlign="left" sx={{ width: "100%" }}>
                      <span className="seeker-text">Types of support Data</span>
                    </Divider>
                  </Box>

                  <VoluteerTypeOfsupport
                    state={getFamilyData}
                    email={verifierVolunteer}
                  />
                </Box>
              </Card>
            </Grid>

            <Grid item md={12} alignItems={"center"}>
              <Card sx={{ borderRadius: "20px" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingTop: "20px",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <Box>
                    <Divider textAlign="left" sx={{ width: "100%" }}>
                      <span className="seeker-text">
                        Seekers Data in {getVolunteerData.district}
                      </span>
                    </Divider>
                  </Box>
                  <StatForVolunteer2
                    seeker={getApi}
                    family={getFamilyData}
                    volunteer={getVolunteerData}
                  />
                </Box>
              </Card>
            </Grid>
            <Grid item md={12} alignItems={"center"}>
              <Card sx={{ borderRadius: "20px" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingTop: "20px",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <Box>
                    <Divider textAlign="left" sx={{ width: "100%" }}>
                      <span className="seeker-text">
                        Seekers Data in {getVolunteerData.industry}{" "}
                      </span>
                    </Divider>
                  </Box>
                  <StatForVolunteer3
                    seeker={getApi}
                    family={getFamilyData}
                    volunteer={getVolunteerData}
                  />
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </>
    );
  } else {
    return (
      <>
        {" "}
        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <Card sx={{ padding: "20px", borderRadius: "20px" }}>
            <Skeleton
              animation="wave"
              variant="text"
              width={230}
              height={40}
              sx={{ borderRadius: "15px" }}
            />
          </Card>

          <Card>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: "20px",
                gap: "10px",
              }}
            >
              {" "}
              <Skeleton
                animation="wave"
                variant="text"
                width={230}
                height={40}
                sx={{ borderRadius: "15px" }}
              />
              <SkeletonSeekCard />
            </Box>
          </Card>
          <Card>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: "20px",
                gap: "10px",
              }}
            >
              {" "}
              <Skeleton
                animation="wave"
                variant="text"
                width={230}
                height={40}
                sx={{ borderRadius: "15px" }}
              />
              <SkeletonSeekCard />
            </Box>
          </Card>
        </Box>
      </>
    );
  }
}

export default VolunteerDashboard;
