import React from "react";
import { Box, Card, Grid, styled, Typography } from "@mui/material";
import CountUp from "react-countup";
import { ROUTINGDATA } from "../../../ROUTINGDATA";
import { useLocation, useNavigate } from "react-router-dom";
import {
  HourglassFullRounded as HourglassFullRoundedIcon,
  AddTaskRounded as AddTaskRoundedIcon,
} from "@mui/icons-material";

const StyledCard = styled(Card)(({ theme }) => ({
  "&.MuiCard-root": {
    [theme.breakpoints.down("sm")]: {
      padding: "18px !important",
      flexDirection: "row",
      gap: "10px",
    },

    padding: "25px",
    borderRadius: "15px",

    display: "flex",
    flexDirection: "column",
    alignContent: "space-between",
    boxShadow: "0 0 8px 2px rgba(0,0,0,0.1) !important",
    transition: "0.2s",
  },
}));

function StatMPcards({ registered, freshers, need, shortlisted, supported }) {
  const location = useLocation();
  const currentUrl = location.pathname;
  const MPDashRoute =
    ROUTINGDATA.LAND + ROUTINGDATA.MPConstituency + ROUTINGDATA.MPDASHBOARD;
  const MLADashRoute =
    ROUTINGDATA.LAND + ROUTINGDATA.MLAconstituency + ROUTINGDATA.MLAdashboard;
  const seeker = {
    name: "Total Seekers Registered",
    count: registered,
    bgColor: "linear-gradient(78deg, #2b2f77 0%, #141852 50%, #070b34 100%);",
    svg: <HourglassFullRoundedIcon fontSize="large" />,
    filterValue: "all_seekers",
  };
  const cardList = [
    {
      name: "Total Seekers Registered",
      count: registered,
      bgColor: "linear-gradient(78deg, #2b2f77 0%, #141852 50%, #070b34 100%);",
      svg: <HourglassFullRoundedIcon fontSize="large" />,
      filterValue: "all_seekers",
    },

    {
      name: "Total Need Support",
      count: need,
      bgColor: "linear-gradient(294deg, #cd1b1b 0%, #f84c4e 100%);",
      svg: <AddTaskRoundedIcon fontSize="large" />,
      filterValue: "all",
    },
    {
      name: "Total Pending Review",
      count: freshers,
      bgColor: "linear-gradient(114deg, #198214 0%, #75e36f 100%);",
      svg: <AddTaskRoundedIcon fontSize="large" />,
      filterValue: "Pending",
    },
    {
      name: "Total Shortlisted",
      count: shortlisted,
      bgColor: "linear-gradient(114deg, #198214 0%, #75e36f 100%);",
      svg: <AddTaskRoundedIcon fontSize="large" />,
      filterValue: "MP",
    },
    {
      name: "Total Supported",
      count: supported,
      bgColor: "linear-gradient(114deg, #198214 0%, #75e36f 100%);",
      svg: <AddTaskRoundedIcon fontSize="large" />,
      filterValue: "MP",
    },
  ];

  const navigate = useNavigate();
  const handleClick = (event) => {
    sessionStorage.setItem("mp_active", event);

    // if (event === "dash") {
    //   sessionStorage.removeItem("mpneed_active");
    //   sessionStorage.removeItem("mpall_active");
    //   sessionStorage.removeItem("mpneedshort_active");
    //   sessionStorage.removeItem("mpneedall_active");
    //   sessionStorage.removeItem("mpneedsupport_activ");
    //   for (let i = 0; i < 5; i++) {
    //     sessionStorage.removeItem(`mpmlaneedradio${i}`);
    //     sessionStorage.removeItem(`shortradio${i}`);
    //   }
    //   sessionStorage.removeItem(`mp_shortradio`);
    //   sessionStorage.removeItem("mp_typeofsupport");
    // }
  };
  const handleClick1 = (event) => {
    sessionStorage.setItem("active", event);
  };
  return (
    <>
      <Grid
        container
        direction="row"
        spacing={5}
        md={12}
        sx={{ mb: "24px", width: "100%" }}
      >
        {currentUrl == MPDashRoute ? (
          cardList.map((item, index) => {
            return (
              <Grid item xs={12} md={3} key={index}>
                <StyledCard
                  sx={{
                    background: `${item.bgColor}`,
                    "&.MuiCard-root:hover": {
                      boxShadow: "0 0 8px 2px rgba(0,0,0,0.4) !important",
                      cursor: "pointer",
                    },
                  }}
                  elevation={6}
                  onClick={() => {
                    // sessionStorage.setItem(`mpmlaneedradio${ind}`, null);
                    // sessionStorage.setItem(
                    //   `shortradio${ind}`,
                    //   item.filterValue
                    // );
                    if (currentUrl == MPDashRoute) {
                      if (item.filterValue === "all_seekers") {
                        navigate(
                          ROUTINGDATA.LAND +
                            ROUTINGDATA.MPConstituency +
                            "/" +
                            ROUTINGDATA.MP_ALL_DATA
                        );
                        handleClick("mp_seek");
                      } else if (index === 3) {
                        navigate(
                          ROUTINGDATA.LAND +
                            ROUTINGDATA.MPConstituency +
                            "/" +
                            ROUTINGDATA.MP_SHORTLISTED
                        );
                        handleClick("mp_short");
                      } else if (index === 4) {
                        navigate(
                          ROUTINGDATA.LAND +
                            ROUTINGDATA.MPConstituency +
                            "/" +
                            ROUTINGDATA.MP_Supported
                        );
                        handleClick("mp_supported");
                      } else {
                        navigate(
                          ROUTINGDATA.LAND +
                            ROUTINGDATA.MPConstituency +
                            "/" +
                            ROUTINGDATA.MP_NEEDSUPPORT,
                          {
                            state: {
                              // mla: state,
                              needSupportFilterValue: item.filterValue,
                              // index: ind,
                            },
                          }
                        );
                        handleClick("mp_need");
                      }
                    } else {
                      navigate(
                        ROUTINGDATA.LAND +
                          ROUTINGDATA.MLAconstituency +
                          "/" +
                          ROUTINGDATA.MLASeeker
                      );
                    }
                  }}
                >
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                      }}
                    >
                      <Box sx={{ color: "white" }}>{item.svg}</Box>
                      <CountUp
                        style={{
                          fontSize: "26px",
                          color: "#f2f2f2",
                        }}
                        start={0}
                        end={item.count}
                        duration={1}
                      />
                    </Box>
                  </Box>

                  <Box>
                    <Typography
                      variant="body1"
                      sx={{ fontFamily: "Poppins-SemiBold", color: "#f2f2f2" }}
                    >
                      {item.name}
                    </Typography>
                  </Box>
                </StyledCard>
              </Grid>
            );
          })
        ) : (
          <Grid item xs={12} md={3}>
            <StyledCard
              sx={{
                background: `${seeker.bgColor}`,
                "&.MuiCard-root:hover": {
                  boxShadow: `${
                    currentUrl == MLADashRoute
                      ? "0 0 8px 2px rgba(0,0,0,0.4) !important"
                      : "0 0 8px 2px rgba(0,0,0,0.1) !important"
                  }`,
                  cursor: `${
                    currentUrl == MLADashRoute ? "pointer" : "default"
                  }`,
                },
              }}
              elevation={6}
              onClick={() => {
                // sessionStorage.setItem(`mpmlaneedradio${ind}`, null);
                // sessionStorage.setItem(
                //   `shortradio${ind}`,
                //   item.filterValue
                // );
                handleClick1("seeker");

                if (currentUrl == MLADashRoute) {
                  navigate(
                    ROUTINGDATA.LAND +
                      ROUTINGDATA.MLAconstituency +
                      "/" +
                      ROUTINGDATA.MLASeeker
                  );
                }
              }}
            >
              <Box>
                <Box
                  sx={{ display: "flex", flexDirection: "row", gap: "10px" }}
                >
                  <Box sx={{ color: "white" }}>{seeker.svg}</Box>
                  <CountUp
                    style={{
                      fontSize: "26px",
                      color: "#f2f2f2",
                    }}
                    start={0}
                    end={seeker.count}
                    duration={1}
                  />
                </Box>
              </Box>

              <Box>
                <Typography
                  variant="body1"
                  sx={{ fontFamily: "Poppins-SemiBold", color: "#f2f2f2" }}
                >
                  {seeker.name}
                </Typography>
              </Box>
            </StyledCard>
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default StatMPcards;
