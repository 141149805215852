import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  Button,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  Box,
  Divider,
  Chip,
  Skeleton,
} from "@mui/material";

import useAxios from "../../../../api/useAxios";
import ApproveDialog from "./ApproveDialog";
import AdminSnackBar from "../SnackBars/AdminSnackBar";
import DeclineDialog from "./DeclineDialog";
import { useLocation } from "react-router-dom";
import { ROUTINGDATA } from "../../../ROUTINGDATA";
import { saveAs } from "file-saver";
function PartnerDialog({
  open,
  handleChange,
  getOne,
  setOpen,
  filterName,
  setFilterName,
  fetchApi,
  dialogLoad,
  setGetOne,
}) {
  const [getPutId, setGetPutId] = useState(0);
  const [getEmail, setGetEmail] = useState("");
  const [getPass, setGetPass] = useState("");
  const [value, setValue] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [snackOption, setSnackOption] = useState("");
  const [approveOpen, setApproveOpen] = useState(false);
  const [declineOpen, setDeclineOpen] = useState(false);
  const [stat, setStat] = useState("");
  const axiosData = useAxios();
  let adminDetails_CEO = JSON.parse(localStorage.getItem("admin_dev"));

  const location = useLocation();
  const currentUrl = location.pathname;
  const updateApprove = async (id, email_id, password) => {
    setFilterName({ ...filterName, isLoading: true });
    const statStat = stat === "S" ? "success" : stat === "H" ? "info" : "";
    try {
      await axiosData.put(`partners/${id}`, { status: stat });

      setGetPutId(0);
      setGetEmail("");
      setGetPass("");
      setOpen(false);
      setApproveOpen(false);
      setOpenSnack(true);
      setSnackOption(statStat);
      fetchApi();
    } catch (err) {
      console.log("Error", err);
    }
    setFilterName({ ...filterName, isLoading: false });
  };

  const updateDecline = async (id, email_id) => {
    setFilterName({ ...filterName, isLoading: true });

    try {
      await axiosData.put(`partners/${id}`, { status: "D", reason: value });

      setGetPutId(0);
      setGetEmail("");
      setValue("");
      setDeclineOpen(false);
      setOpen(false);
      setOpenSnack(true);
      setSnackOption("error");
      fetchApi();
    } catch (err) {
      console.log("Error", err);
    }
    setFilterName({ ...filterName, isLoading: false });
  };
  return (
    <>
      {" "}
      <Dialog
        fullWidth
        open={open}
        // onClose={handleChange}
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "20px",
          },
        }}
      >
        <DialogTitle bgcolor="#055779" color="white" height="100%">
          <Box id="main-box">
            <Box>
              {" "}
              <div id className="align-img"></div>
            </Box>
            <Box>
              <Typography
                variant="body1"
                sx={{
                  textTransform: "uppercase",
                  fontFamily: "Barlow-Bold",
                }}
              >
                <div className="titleseeker">
                  {getOne.partner_name}
                  <div
                    style={{
                      textTransform: "capitalize",
                      fontFamily: "Barlow-Regular",
                    }}
                  >
                    {getOne.location}
                  </div>
                </div>
              </Typography>
            </Box>

            <Box></Box>
          </Box>
        </DialogTitle>
        <DialogContent dividers="paper" sx={{ backgroundColor: "#f2f2f2" }}>
          <Box sx={{ padding: "20px", width: "100%" }}>
            <Grid container md={12} direction="column">
              <Divider
                sx={{
                  "&::before, &::after": {
                    borderColor: "#055779",
                  },
                  color: "#055779 ",
                }}
                textAlign="left"
              >
                Personal Details
              </Divider>
              <Box sx={{ padding: "20px" }}>
                <Typography variant="h6" sx={{ fontFamily: "Poppins-Regular" }}>
                  <div className="align-text">
                    <span className="full-details"> Full Name:</span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        getOne.partner_name
                      )}
                    </div>
                  </div>
                </Typography>
                <Typography variant="h6">
                  <div className="align-text">
                    <span className="full-details"> Email:</span>
                    <div>
                      {" "}
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        getOne.email_id
                      )}
                    </div>
                  </div>
                </Typography>{" "}
                <Typography variant="h6">
                  <div className="align-text">
                    <span className="full-details"> Mobile:</span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        getOne.mobileNumber
                      )}
                    </div>
                  </div>
                </Typography>{" "}
                <Typography variant="h6">
                  <div className="align-text">
                    <span className="full-details"> District:</span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        getOne.district_name
                      )}
                    </div>
                  </div>
                </Typography>
                <Typography variant="h6">
                  <div className="align-text">
                    <span className="full-details"> Location:</span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        getOne.location
                      )}
                    </div>
                  </div>
                </Typography>
              </Box>
              <Divider
                sx={{
                  "&::before, &::after": {
                    borderColor: "#055779",
                  },
                  color: "#055779 ",
                }}
                textAlign="left"
              >
                Professional Details
              </Divider>{" "}
              <Box sx={{ padding: "20px" }}>
                <Typography variant="h6">
                  <div className="align-text">
                    <span className="full-details"> Business Name:</span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        getOne.business_name
                      )}
                    </div>
                  </div>
                </Typography>
                <Typography variant="h6">
                  <div className="align-text">
                    <span className="full-details"> Years of Experience:</span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        getOne.yearsOfExperience
                      )}
                    </div>
                  </div>
                </Typography>
              </Box>
              <Divider
                sx={{
                  "&::before, &::after": {
                    borderColor: "#055779",
                  },
                  color: "#055779 ",
                }}
                textAlign="left"
              >
                Investment Details
              </Divider>{" "}
              <Box sx={{ padding: "20px" }}>
                <Typography variant="h6">
                  <div className="align-text">
                    <span className="full-details"> Investment Range :</span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        getOne.investment_range
                      )}
                    </div>
                  </div>
                </Typography>

                <Typography variant="h6">
                  <div className="align-text">
                    <span className="full-details"> Investment Within:</span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        getOne.investment_within
                      )}
                    </div>
                  </div>
                </Typography>
              </Box>
              <Divider
                sx={{
                  "&::before, &::after": {
                    borderColor: "#055779",
                  },
                  color: "#055779 ",
                }}
                textAlign="left"
              >
                Status
              </Divider>
              <Box sx={{ padding: "20px" }}>
                <Typography textAlign="center" variant="h6">
                  <div className="align-text">
                    <span
                      className="full-details"
                      style={{ textAlign: "left" }}
                    >
                      {" "}
                      Allocated Pincode:
                    </span>
                    <div style={{ textAlign: "left" }}>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="rounded"
                          width={40}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        getOne.allocate_pincode_postOffice.map((i) => {
                          return `${i.pincode} ${i.village_name}.\n`;
                        })
                      )}
                    </div>
                  </div>
                </Typography>
                <Typography textAlign="center" variant="h6">
                  <div className="align-text">
                    <span
                      className="full-details"
                      style={{ textAlign: "left" }}
                    >
                      {" "}
                      Status:
                    </span>
                    <div style={{ textAlign: "left" }}>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="rounded"
                          width={40}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        <Chip
                          label={
                            getOne.status === "P"
                              ? "Pending"
                              : getOne.status === "S"
                              ? "Selected"
                              : getOne.status === "H"
                              ? "In Hold"
                              : "Declined"
                          }
                          color={
                            getOne.status === "P"
                              ? "warning"
                              : getOne.status === "S"
                              ? "success"
                              : getOne.status === "H"
                              ? "info"
                              : "error"
                          }
                          variant="contained"
                        />
                      )}
                    </div>
                  </div>
                </Typography>
                {getOne.status === "D" ? (
                  <>
                    <Typography variant="h6">
                      <div className="align-text">
                        <span className="full-details"> Reason:</span>
                        <div>{getOne.reason}</div>
                      </div>
                    </Typography>
                  </>
                ) : null}
              </Box>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button sx={{ textTransform: "capitalize" }} onClick={handleChange}>
            Close
          </Button>

          {adminDetails_CEO?.emailId ===
          process.env.REACT_APP_EMAILID_FOR_ADMIN_DEVELOPER ? null : (
            <>
              <Button
                className="action-btn"
                sx={{ textTransform: "capitalize" }}
                onClick={() => {
                  setGetPutId(getOne._id);
                  setGetEmail(getOne.email_id);
                  setGetPass(getOne.password);
                  setStat("S");
                  setApproveOpen(true);
                }}
                variant="contained"
                color="success"
                disabled={getOne.status === "S" ? true : false}
              >
                Select Profile
              </Button>{" "}
              <Button
                className="action-btn"
                sx={{ textTransform: "capitalize" }}
                color="error"
                onClick={() => {
                  setGetPutId(getOne._id);
                  setGetEmail(getOne.email_id);

                  setDeclineOpen(open);
                }}
                variant="contained"
                disabled={getOne.status === "D" ? true : false}
              >
                Decline
              </Button>
              <Button
                variant="contained"
                className="action-btn"
                color="info"
                sx={{
                  textTransform: "capitalize",
                }}
                disabled={getOne.status === "P" ? false : true}
                onClick={() => {
                  setGetPutId(getOne._id);
                  setGetEmail(getOne.email_id);
                  setGetPass(getOne.password);
                  setStat("H");
                  setApproveOpen(true);
                }}
              >
                Hold
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
      <ApproveDialog
        approveOpen={approveOpen}
        setApproveOpen={setApproveOpen}
        updateApprove={updateApprove}
        filterName={filterName}
        getPutId={getPutId}
        getEmail={getEmail}
        getPass={getPass}
        stat={stat}
        setStat={setStat}
      />
      <DeclineDialog
        declineOpen={declineOpen}
        setDeclineOpen={setDeclineOpen}
        value={value}
        setValue={setValue}
        filterName={filterName}
        updateDecline={updateDecline}
        getPutId={getPutId}
        getEmail={getEmail}
      />
      <AdminSnackBar
        openSnack={openSnack}
        setOpenSnack={setOpenSnack}
        snackOption={snackOption}
        full_name={getOne.partner_name}
      />
    </>
  );
}

export default PartnerDialog;
