import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import useAxios from "../../../../api/useAxios";
import { saveAs } from "file-saver";
import {
  Card,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Tooltip,
  Fab,
  CardHeader,
  Select,
  MenuItem,
  FormControl,
  CardContent,
  Grid,
  Paper,
  Typography,
  Button,
  Divider,
  Backdrop,
  CircularProgress,
  FormLabel,
  Chip,
  IconButton,
  Skeleton,
} from "@mui/material";
import { ROUTINGDATA } from "../../../../components/ROUTINGDATA";

import {
  ArrowBackRounded as ArrowBackRoundedIcon,
  AssignmentInd as AssignmentIndIcon,
  FileDownloadRounded as FileDownloadRoundedIcon,
  CheckRounded as CheckRoundedIcon,
  CloseRounded as CloseRoundedIcon,
  VisibilityRounded as VisibilityRoundedIcon,
  InfoOutlined as InfoOutlinedIcon,
  PanToolRounded as PanToolRoundedIcon,
  SkipNext as SkipNextIcon,
} from "@mui/icons-material";
import Breadcrumb from "../../../Dashboards/BreadCrumb";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import SeekerDialog from "../DialogBoxes/SeekerDialog";
import ApproveDialog from "../DialogBoxes/ApproveDialog";
import CompleteByAdminDialog from "../DialogBoxes/CompleteByAdminDialog";
import SkeletonSeekCard from "../StatCards/SkeletonSeekCard";

const CustomBackDrop = styled(Backdrop)(({ theme }) => ({
  color: "white ",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  position: "absolute",
  zIndex: theme.zIndex.drawer - 1,
  opacity: 0.5,
  borderRadius: "15px",
}));

function FullFamilyDetails() {
  const axiosData = useAxios();
  const baseUrl = axiosData.defaults.baseURL;
  const navigate = useNavigate();
  const location = useLocation();
  const currentUrl = location.pathname;
  const [familyApi, setFamilyApi] = useState({});
  const [oneVerifier, setOneVerifier] = useState({});
  const [verifierApi, setVerifierApi] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [approveOpen, setApproveOpen] = useState(false);
  const [skipOpen, setSkipOpen] = useState(false);
  const [getOne, setGetOne] = useState({});
  const [action, setAction] = useState({
    button: "",
    api: "",
  });
  let adminDetails_CEO = JSON.parse(localStorage.getItem("admin_dev"));
  const [verifierName, setverifierName] = useState(
    oneVerifier.full_name
      ? `${oneVerifier.full_name}(${oneVerifier.email_id})`
      : "Not Yet Assigned"
  );
  const [openState, setOpenState] = useState({
    adminVerify: false,
    tick: false,
    close: false,
    loader: false,
    assign: true,
    select: false,
    oneVerifierAndFamilyApi: false,
    isLoading: false,

    buttonDisable: false,
  });

  const state = location.state;

  const ITEM_HEIGHT = 50;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 350,
      },
    },
  };

  const fetchFamilyApi = async () => {
    setLoading(true);
    try {
      const axiosFamilyData = await axiosData.get(`family/${state.email_id}`);
      const { data } = axiosFamilyData;
      setFamilyApi(data);
    } catch (err) {
      console.log("Error", err);
    }
    setLoading(false);
  };
  const fetchVerifierData = async () => {
    try {
      const axiosVerifierData = await axiosData.get("verifierdetails");
      const { data } = axiosVerifierData;
      setVerifierApi(data);
    } catch (err) {
      console.log("Error", err);
    }
  };
  const fetchOneVerifierData = async (email_id) => {
    try {
      const axiosOneVerifierData = await axiosData.get(
        `verifierdetails/${email_id}`
      );
      const { data } = axiosOneVerifierData;
      setOneVerifier(data);
    } catch (err) {
      console.log("Error", err);
    }
  };

  const updateAdminVerify = async (email_id, decide) => {
    setOpenState({ ...openState, loader: true });

    try {
      await axiosData.put(`family/admin/${email_id}`, {
        adminVerify: decide,
      });
      fetchFamilyApi();
    } catch (err) {
      console.log("Error", err);
    }
    setOpenState({ ...openState, loader: false });
  };
  const updateVerifierDetails = async (
    famEmail,
    name,
    email,
    mobile,
    seeker
  ) => {
    try {
      await axiosData.put(`family/admin/${famEmail}`, {
        VerifierName: name,
        VerifierEmail: email,
        VerifierMobile: mobile,
        seeker_name: seeker,
        verifier_name: name,
      });
      setOpenState({
        ...openState,
        tick: false,
        close: false,
        select: false,
        assign: true,
        oneVerifierAndFamilyApi: false,
      });
      fetchFamilyApi();
    } catch (err) {
      console.log("Error", err);
    }
  };
  const updateFinalVerify = async (email, action) => {
    try {
      await axiosData.put(`family/admin/${email}`, {
        finalVerification: action,
        finalverify: action,
        email: familyApi.email_id,
      });

      fetchFamilyApi();
    } catch (err) {
      console.log("Error", err);
    }
  };

  const getOneData = async (id) => {
    try {
      const getOneAxios = await axiosData.get(
        `seekers/email/${state.email_id}`
      );
      const { data } = getOneAxios;

      setGetOne(data);
    } catch (err) {
      console.log("Error", err);
    }
  };

  const handleChange = () => {
    setOpen(!open);
  };
  useEffect(() => {
    fetchFamilyApi();
    fetchVerifierData();
    getOneData();
  }, []);

  if (!loading) {
    return (
      <>
        {" "}
        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <Box display="flex" justifyContent="space-between">
            <Tooltip title="Go Back">
              <Fab
                onClick={() => navigate(-1)}
                size="small"
                sx={{
                  boxShadow: 0,
                  backgroundColor: "#339fcd",
                  color: "white",
                  "&:hover": { backgroundColor: "#217396" },
                }}
              >
                <ArrowBackRoundedIcon />
              </Fab>
            </Tooltip>
            <Breadcrumb />
          </Box>
          <Card
            sx={{ borderRadius: "15px", boxShadow: "3px 3px 10px 3px #dddddd" }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                padding: "20px",
              }}
            >
              <span className="seeker-text">
                {familyApi.Name}'s Family Details
              </span>
              <Tooltip title="Full Details">
                <IconButton
                  onClick={() => setOpen(true)}
                  sx={{
                    color: "#339fcd",
                    transition: "0.3s",
                    "&:hover": { backgroundColor: "#217396", color: "#ffffff" },
                  }}
                >
                  <VisibilityRoundedIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Card>
          <Card sx={{ boxShadow: 0 }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow className="table-row">
                    <TableCell>Relation</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Qualification</TableCell>
                    <TableCell>Occupation</TableCell>
                    <TableCell>Annual Income</TableCell>
                    <TableCell>Own House</TableCell>
                    <TableCell>Plot/Land</TableCell>
                    {familyApi.fieldVerification === "Completed" ? (
                      <TableCell>Verified Status</TableCell>
                    ) : null}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!familyApi.Father?.Name ? (
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell>
                        <h3>No Data</h3>
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  ) : (
                    <>
                      {familyApi.Father?.Name && (
                        <TableRow hover className="tablecell">
                          <TableCell>Father</TableCell>
                          <TableCell>{familyApi.Father?.Name}</TableCell>
                          <TableCell>
                            {familyApi.Father?.Qualification}
                          </TableCell>
                          <TableCell>{familyApi.Father?.Occupation}</TableCell>
                          <TableCell>
                            {familyApi.Father?.AnnualIncome}
                          </TableCell>
                          <TableCell>{familyApi.Father?.OwnHouse}</TableCell>
                          <TableCell>{familyApi.Father?.Land}</TableCell>
                          {familyApi.fieldVerification === "Completed" ? (
                            <TableCell>
                              {familyApi.Father?.verifystatus}
                            </TableCell>
                          ) : null}
                        </TableRow>
                      )}
                      {familyApi.Mother?.Name && (
                        <TableRow hover className="tablecell">
                          <TableCell>Mother</TableCell>
                          <TableCell>{familyApi.Mother?.Name}</TableCell>
                          <TableCell>
                            {familyApi.Mother?.Qualification}
                          </TableCell>
                          <TableCell>{familyApi.Mother?.Occupation}</TableCell>
                          <TableCell>
                            {familyApi.Mother?.AnnualIncome}
                          </TableCell>
                          <TableCell>{familyApi.Mother?.OwnHouse}</TableCell>
                          <TableCell>{familyApi.Mother?.Land}</TableCell>
                          {familyApi.fieldVerification === "Completed" ? (
                            <TableCell>
                              {familyApi.Mother?.verifystatus}
                            </TableCell>
                          ) : null}
                        </TableRow>
                      )}
                      {familyApi.Brother1?.Name && (
                        <TableRow hover className="tablecell">
                          <TableCell>Brother 1</TableCell>
                          <TableCell>{familyApi.Brother1?.Name}</TableCell>
                          <TableCell>
                            {familyApi.Brother1?.Qualification}
                          </TableCell>
                          <TableCell>
                            {familyApi.Brother1?.Occupation}
                          </TableCell>
                          <TableCell>
                            {familyApi.Brother1?.AnnualIncome}
                          </TableCell>
                          <TableCell>{familyApi.Brother1?.OwnHouse}</TableCell>
                          <TableCell>{familyApi.Brother1?.Land}</TableCell>
                          {familyApi.fieldVerification === "Completed" ? (
                            <TableCell>
                              {familyApi.Brother1?.verifystatus}
                            </TableCell>
                          ) : null}
                        </TableRow>
                      )}
                      {familyApi.Brother2?.Name && (
                        <TableRow hover className="tablecell">
                          <TableCell>Brother2</TableCell>
                          <TableCell>{familyApi.Brother2?.Name}</TableCell>
                          <TableCell>
                            {familyApi.Brother2?.Qualification}
                          </TableCell>
                          <TableCell>
                            {familyApi.Brother2?.Occupation}
                          </TableCell>
                          <TableCell>
                            {familyApi.Brother2?.AnnualIncome}
                          </TableCell>
                          <TableCell>{familyApi.Brother2?.OwnHouse}</TableCell>
                          <TableCell>{familyApi.Brother2?.Land}</TableCell>
                          {familyApi.fieldVerification === "Completed" ? (
                            <TableCell>
                              {familyApi.Brother2?.verifystatus}
                            </TableCell>
                          ) : null}
                        </TableRow>
                      )}
                      {familyApi.Brother3?.Name && (
                        <TableRow hover className="tablecell">
                          <TableCell>Brother 3</TableCell>
                          <TableCell>{familyApi.Brother3?.Name}</TableCell>
                          <TableCell>
                            {familyApi.Brother3?.Qualification}
                          </TableCell>
                          <TableCell>
                            {familyApi.Brother3?.Occupation}
                          </TableCell>
                          <TableCell>
                            {familyApi.Brother3?.AnnualIncome}
                          </TableCell>
                          <TableCell>{familyApi.Brother3?.OwnHouse}</TableCell>
                          <TableCell>{familyApi.Brother3?.Land}</TableCell>
                          {familyApi.fieldVerification === "Completed" ? (
                            <TableCell>
                              {familyApi.Brother3?.verifystatus}
                            </TableCell>
                          ) : null}
                        </TableRow>
                      )}
                      {familyApi.Brother4?.Name && (
                        <TableRow hover className="tablecell">
                          <TableCell>Brother4</TableCell>
                          <TableCell>{familyApi.Brother4?.Name}</TableCell>
                          <TableCell>
                            {familyApi.Brother4?.Qualification}
                          </TableCell>
                          <TableCell>
                            {familyApi.Brother4?.Occupation}
                          </TableCell>
                          <TableCell>
                            {familyApi.Brother4?.AnnualIncome}
                          </TableCell>
                          <TableCell>{familyApi.Brother4?.OwnHouse}</TableCell>
                          <TableCell>{familyApi.Brother4?.Land}</TableCell>
                          {familyApi.fieldVerification === "Completed" ? (
                            <TableCell>
                              {familyApi.Brother4?.verifystatus}
                            </TableCell>
                          ) : null}
                        </TableRow>
                      )}
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4.3}>
              <Box>
                <Card
                  sx={{
                    borderRadius: "15px",
                    boxShadow: "none",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <CardContent>
                    <Box display="flex" flexDirection="column" gap="20px">
                      <Divider textAlign="left">Additional Details</Divider>
                      <Typography variant="body1">
                        <div className="align-text">
                          <span className="full-details">
                            {" "}
                            Types Of Support:
                          </span>
                          <div>{familyApi.TypeOfSupport}</div>
                        </div>
                      </Typography>

                      {familyApi?.TypeOfSupport === "Others" && (
                        <Typography variant="body1">
                          <div className="align-text">
                            <span className="full-details">
                              {" "}
                              Other Support:
                            </span>
                            <div>{familyApi.Others}</div>
                          </div>
                        </Typography>
                      )}
                      {familyApi.TypeOfSupport === "Mentorship" ? null : (
                        <>
                          <Typography variant="body1">
                            <div className="align-text">
                              <span className="full-details">
                                {" "}
                                Approximate Amount:
                              </span>
                              <div>{familyApi.amount}</div>
                            </div>
                          </Typography>
                          <Divider textAlign="left">Bank Details</Divider>
                          <Typography variant="body1">
                            <div className="align-text">
                              <span className="full-details">
                                {" "}
                                Account Holder:
                              </span>
                              <div>{familyApi.BankDetails?.AccHolderName}</div>
                            </div>
                          </Typography>
                          <Typography variant="body1">
                            <div className="align-text">
                              <span className="full-details">
                                {" "}
                                Account Number:
                              </span>
                              <div>{familyApi.BankDetails?.AccountNumber}</div>
                            </div>
                          </Typography>
                          <Typography variant="body1">
                            <div className="align-text">
                              <span className="full-details"> IFSC code:</span>
                              <div>{familyApi.BankDetails?.IfscCode}</div>
                            </div>
                          </Typography>
                          <Typography variant="body1">
                            <div className="align-text">
                              <span className="full-details">
                                {" "}
                                Gpay Number:
                              </span>
                              <div>{familyApi.UpiNumber}</div>
                            </div>
                          </Typography>
                        </>
                      )}
                      {familyApi.aadharNumber ? (
                        <>
                          <Divider textAlign="left">Aadhar Details</Divider>
                          <Typography variant="body1">
                            <div className="align-text">
                              <span className="full-details"> Aadhar No:</span>
                              <div>{familyApi.aadharNumber}</div>
                            </div>
                          </Typography>
                          {familyApi.aadharUpload ? (
                            <Typography variant="body1">
                              <div className="align-text">
                                <span className="full-details">
                                  {" "}
                                  Aadhar Proof:
                                </span>
                                <Typography
                                  gutterBottom
                                  sx={{
                                    fontWeight: 600,
                                    fontSize: "16px",
                                    mt: -0.8,
                                  }}
                                  color="text.secondary"
                                >
                                  {familyApi.aadharUpload?.name}{" "}
                                  <Tooltip title="Download Resume" arrow>
                                    <IconButton
                                      disabled={
                                        adminDetails_CEO?.emailId ===
                                        process.env
                                          .REACT_APP_EMAILID_FOR_ADMIN_DEVELOPER
                                          ? true
                                          : false
                                      }
                                    >
                                      <FileDownloadOutlinedIcon
                                        onClick={() => {
                                          saveAs(
                                            `${baseUrl}/family/proof/${familyApi.email_id}`,
                                            familyApi.aadharUpload?.name
                                          );
                                        }}
                                        size="medium"
                                        sx={{
                                          color:
                                            adminDetails_CEO?.emailId ===
                                            process.env
                                              .REACT_APP_EMAILID_FOR_ADMIN_DEVELOPER
                                              ? ""
                                              : "green",
                                          border:
                                            adminDetails_CEO?.emailId ===
                                            process.env
                                              .REACT_APP_EMAILID_FOR_ADMIN_DEVELOPER
                                              ? ""
                                              : "1px solid green",
                                          borderRadius: "50%",
                                        }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </Typography>
                              </div>
                            </Typography>
                          ) : null}
                        </>
                      ) : null}
                      {familyApi?.remarks && (
                        <Typography variant="body1">
                          <div className="align-text">
                            <span className="full-details"> Remarks:</span>
                            <div>
                              {" "}
                              <Typography
                                dangerouslySetInnerHTML={{
                                  __html: familyApi.remarks,
                                }}
                              />
                            </div>
                          </div>
                        </Typography>
                      )}
                      <Divider textAlign="left">Verification Status</Divider>
                      <Typography variant="body1">
                        <div className="align-text">
                          <span className="full-details">
                            Field Verification:
                          </span>
                          <div>
                            <Chip
                              label={
                                familyApi.fieldVerification === "Admin"
                                  ? "Completed By Admin"
                                  : familyApi.fieldVerification
                              }
                              variant="filled"
                              color={
                                familyApi.fieldVerification === "Pending" ||
                                familyApi.fieldVerification === "pending"
                                  ? "warning"
                                  : familyApi.fieldVerification ===
                                      "completed" ||
                                    familyApi.fieldVerification === "Completed"
                                  ? "success"
                                  : familyApi.fieldVerification === "Admin"
                                  ? "info"
                                  : "error"
                              }
                              sx={{
                                color: "#ffffff",
                              }}
                            />
                          </div>
                        </div>
                      </Typography>
                      {currentUrl ==
                      ROUTINGDATA.LAND +
                        ROUTINGDATA.MPConstituency +
                        "/" +
                        ROUTINGDATA.MP_MLAfullDetais ? null : (
                        <Box>
                          {familyApi.fieldVerification === "Completed" && (
                            <Card
                              sx={{
                                borderRadius: "15px",
                                boxShadow: "none",
                                width: "100%",
                                backgroundColor: "#E6F7FF",
                                overflowX: "auto",
                              }}
                            >
                              <CardHeader avatar={<InfoOutlinedIcon />} />
                              <Divider />
                              <CardContent>
                                <Box
                                  sx={{
                                    display: "grid",
                                    placeItems: "center",
                                    gap: "20px",
                                  }}
                                >
                                  <Typography variant="body1">
                                    {familyApi.finalVerification === "Pending"
                                      ? ` "Verifier completed the field verification process for this user. Please indicate whether you would like to approve or reject their application."`
                                      : familyApi.finalVerification === "Hold"
                                      ? `"The Final Approval process has been placed on hold."`
                                      : familyApi.finalVerification ===
                                        "Declined"
                                      ? `"This user's Need support was declined"`
                                      : familyApi.finalVerification ===
                                        "Completed"
                                      ? `"The Final Approval has been completed and the user data has been successfully transmitted to the MP,MLA and Volunteer dashboard."`
                                      : ""}
                                  </Typography>
                                  <Box display="flex" gap={"20px"}>
                                    <Button
                                      variant="contained"
                                      disabled={
                                        (familyApi.finalVerification ===
                                          "Completed" ||
                                          familyApi.finalVerification ===
                                            "Declined" ||
                                          adminDetails_CEO?.emailId ===
                                            process.env
                                              .REACT_APP_EMAILID_FOR_ADMIN_DEVELOPER) &&
                                        true
                                      }
                                      startIcon={<CheckRoundedIcon />}
                                      color="success"
                                      sx={{
                                        textTransform: "capitalize",
                                        borderRadius: "20px",
                                      }}
                                      onClick={() => {
                                        setApproveOpen(true);
                                        setAction({
                                          ...action,
                                          button: "Approve",
                                          api: "Completed",
                                        });
                                      }}
                                    >
                                      {familyApi.finalVerification ===
                                      "Completed"
                                        ? "Approved"
                                        : "Approve"}
                                    </Button>
                                    <Button
                                      variant="contained"
                                      disabled={
                                        (familyApi.finalVerification ===
                                          "Completed" ||
                                          familyApi.finalVerification ===
                                            "Declined" ||
                                          adminDetails_CEO?.emailId ===
                                            process.env
                                              .REACT_APP_EMAILID_FOR_ADMIN_DEVELOPER) &&
                                        true
                                      }
                                      startIcon={<CloseRoundedIcon />}
                                      color="error"
                                      sx={{
                                        textTransform: "capitalize",
                                        borderRadius: "20px",
                                      }}
                                      onClick={() => {
                                        setApproveOpen(true);
                                        setAction({
                                          ...action,
                                          button: "Decline",
                                          api: "Declined",
                                        });
                                      }}
                                    >
                                      {familyApi.finalVerification ===
                                      "Declined"
                                        ? "Declined"
                                        : "Decline"}
                                    </Button>
                                    <Button
                                      variant="contained"
                                      disabled={
                                        (!familyApi.finalVerification ===
                                          "Pending" ||
                                          adminDetails_CEO?.emailId ===
                                            process.env
                                              .REACT_APP_EMAILID_FOR_ADMIN_DEVELOPER) &&
                                        true
                                      }
                                      startIcon={<PanToolRoundedIcon />}
                                      color="info"
                                      sx={{
                                        textTransform: "capitalize",
                                        borderRadius: "20px",
                                      }}
                                      onClick={() =>
                                        updateFinalVerify(
                                          familyApi.email_id,
                                          "Hold"
                                        )
                                      }
                                    >
                                      {familyApi.finalVerification === "Hold"
                                        ? "In Hold"
                                        : "Hold"}
                                    </Button>
                                  </Box>
                                </Box>
                              </CardContent>
                            </Card>
                          )}
                        </Box>
                      )}

                      <Typography variant="body1">
                        <div className="align-text">
                          <span className="full-details">Final Approval:</span>
                          <div>
                            <Chip
                              variant="filled"
                              label={familyApi.finalVerification}
                              color={
                                familyApi.finalVerification === "Pending" ||
                                familyApi.finalVerification === "pending"
                                  ? "warning"
                                  : familyApi.finalVerification ===
                                      "completed" ||
                                    familyApi.finalVerification === "Completed"
                                  ? "success"
                                  : familyApi.finalVerification === "Hold"
                                  ? "info"
                                  : "error"
                              }
                              sx={{
                                color: "#ffffff",
                              }}
                            />
                          </div>
                        </div>
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
            <Grid item xs={12} md={7.7}>
              {familyApi.fieldVerification === "Admin" ? null : (
                <Box sx={{ position: "relative" }}>
                  <Box>
                    {familyApi.adminVerify === "YES" ||
                    familyApi.adminVerify === "Yes" ||
                    familyApi.adminVerify === "yes" ? (
                      <Box>
                        <Card
                          sx={{
                            borderRadius: "15px",
                            boxShadow: "none",
                          }}
                        >
                          <CardHeader title="Verifier Details" />
                          <Divider />
                          <CardContent>
                            <Grid container gap="12px">
                              <Grid item md={12}>
                                <Paper
                                  className="paper-verifier"
                                  sx={{ boxShadow: 0 }}
                                >
                                  <Grid container>
                                    <Grid item md={4}>
                                      {familyApi.fieldVerification ===
                                      "Completed" ? null : (
                                        <Button
                                          disabled={
                                            adminDetails_CEO?.emailId ===
                                            process.env
                                              .REACT_APP_EMAILID_FOR_ADMIN_DEVELOPER
                                              ? true
                                              : false
                                          }
                                          variant="contained"
                                          startIcon={<AssignmentIndIcon />}
                                          onClick={() => {
                                            setOpenState({
                                              ...openState,
                                              assign: false,
                                              close: true,
                                              select: true,
                                            });
                                          }}
                                          sx={{
                                            visibility: `${
                                              openState.assign
                                                ? "visible"
                                                : "hidden"
                                            }`,
                                            borderRadius: "20px",
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {familyApi?.VerifierName
                                            ? "Re Assign"
                                            : "Assign"}
                                        </Button>
                                      )}
                                    </Grid>

                                    <Grid item md={4}>
                                      {familyApi.fieldVerification ===
                                      "Completed" ? null : (
                                        <Button
                                          disabled={
                                            adminDetails_CEO?.emailId ===
                                            process.env
                                              .REACT_APP_EMAILID_FOR_ADMIN_DEVELOPER
                                              ? true
                                              : false
                                          }
                                          variant="contained"
                                          startIcon={<SkipNextIcon />}
                                          onClick={() => {
                                            setSkipOpen(true);
                                          }}
                                          sx={{
                                            // visibility: `${
                                            //   openState.assign
                                            //     ? "visible"
                                            //     : "hidden"
                                            // }`,
                                            borderRadius: "20px",
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          Skip Verification
                                        </Button>
                                      )}
                                      <FormControl
                                        sx={{
                                          width: "100%",
                                          visibility: `${
                                            openState.select
                                              ? "visible"
                                              : "hidden"
                                          }`,
                                        }}
                                      >
                                        <FormLabel>
                                          Select Verifier Name
                                        </FormLabel>
                                        <Select
                                          value={verifierName}
                                          onChange={(e) => {
                                            setverifierName(e.target.value);
                                          }}
                                          MenuProps={MenuProps}
                                        >
                                          {verifierApi.length > 0 ? (
                                            verifierApi.map((i) => (
                                              <MenuItem
                                                key={i.email_id}
                                                value={i.email_id}
                                                onClick={() => {
                                                  fetchOneVerifierData(
                                                    i.email_id
                                                  );
                                                  setOpenState({
                                                    ...openState,
                                                    tick: true,
                                                    oneVerifierAndFamilyApi: true,
                                                  });
                                                }}
                                              >
                                                {`${i.full_name} (${i.email_id})`}
                                              </MenuItem>
                                            ))
                                          ) : (
                                            <MenuItem>
                                              No Data available
                                            </MenuItem>
                                          )}
                                        </Select>
                                      </FormControl>
                                    </Grid>
                                    <Grid item md={4}>
                                      <Box
                                        sx={{
                                          height: "100%",
                                          display: "flex",
                                          gap: "10px",
                                          justifyContent: "center",
                                          alignItems: "end",
                                          paddingBottom: "10px",
                                        }}
                                      >
                                        <Fab
                                          size="small"
                                          sx={{
                                            visibility: `${
                                              openState.tick
                                                ? "visible"
                                                : "hidden"
                                            }`,
                                          }}
                                          onClick={() => {
                                            updateVerifierDetails(
                                              familyApi.email_id,
                                              oneVerifier.full_name,
                                              oneVerifier.email_id,
                                              oneVerifier.mobile,
                                              familyApi.Name
                                            );
                                          }}
                                        >
                                          {openState.tickLoader ? (
                                            <CircularProgress
                                              color="inherit"
                                              size={25}
                                            />
                                          ) : (
                                            <CheckRoundedIcon />
                                          )}
                                        </Fab>{" "}
                                        <Fab
                                          size="small"
                                          sx={{
                                            visibility: `${
                                              openState.close
                                                ? "visible"
                                                : "hidden"
                                            }`,
                                          }}
                                          onClick={() => {
                                            setOpenState({
                                              ...openState,
                                              tick: false,
                                              close: false,
                                              assign: true,
                                              select: false,
                                              oneVerifierAndFamilyApi: false,
                                            });
                                            setverifierName(
                                              oneVerifier.full_name
                                                ? `${oneVerifier.full_name}(${oneVerifier.email_id})`
                                                : "Not Yet Assigned"
                                            );
                                          }}
                                        >
                                          <CloseRoundedIcon />
                                        </Fab>
                                      </Box>
                                    </Grid>
                                  </Grid>
                                  <Typography variant="h6">
                                    <div className="align-text">
                                      <span className="full-details">
                                        {" "}
                                        Full Name:
                                      </span>
                                      <div>
                                        {!openState.oneVerifierAndFamilyApi
                                          ? familyApi.VerifierName
                                          : oneVerifier.full_name}
                                      </div>
                                    </div>
                                  </Typography>
                                  <Typography variant="h6">
                                    <div className="align-text">
                                      <span className="full-details">
                                        {" "}
                                        Email:
                                      </span>
                                      <div>
                                        {!openState.oneVerifierAndFamilyApi
                                          ? familyApi.VerifierEmail
                                          : oneVerifier?.email_id
                                          ? oneVerifier?.email_id
                                          : "no data"}
                                      </div>
                                    </div>
                                  </Typography>
                                  <Typography variant="h6">
                                    <div className="align-text">
                                      <span className="full-details">
                                        {" "}
                                        Mobile:
                                      </span>
                                      <div>
                                        {!openState.oneVerifierAndFamilyApi
                                          ? familyApi.VerifierMobile
                                          : oneVerifier.mobile}
                                      </div>
                                    </div>
                                  </Typography>
                                </Paper>
                              </Grid>
                              {/* <Grid item md={12}>
                 <Paper
                   className="paper-verifier"
                   sx={{ boxShadow: 0 }}
                 >
                   <Box>
                     {" "}
                     <Typography>
                       {" "}
                       <span className="full-details">
                         Verifier Uploaded File
                       </span>
                     </Typography>
                     <Tooltip
                       title={
                         familyApi?.AfterVFile
                           ? ""
                           : "Verifier Isn't Uploaded Yet"
                       }
                       arrow
                     >
                       <span>
                         <Button
                           sx={{ textTransform: "capitalize" }}
                           variant="contained"
                           endIcon={<FileDownloadRoundedIcon />}
                           disabled={
                             familyApi?.AfterVFile ? false : true
                           }
                         >
                           Download File
                         </Button>
                       </span>
                     </Tooltip>
                   </Box>
                 </Paper>
               </Grid> */}
                            </Grid>
                          </CardContent>
                        </Card>
                      </Box>
                    ) : familyApi.adminVerify === "Pending" ||
                      familyApi.adminVerify === "pending" ? (
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <Card
                          sx={{
                            borderRadius: "15px",
                            boxShadow: "none",
                            width: "60%",
                          }}
                        >
                          <CardHeader title="Admin Verify" />
                          <Divider />
                          <CardContent>
                            <Box
                              sx={{
                                display: "grid",
                                placeItems: "center",
                                gap: "20px",
                              }}
                            >
                              <Typography>
                                To ensure accuracy, can you please verify Above
                                user Details are valid?
                              </Typography>
                              <Box display="flex" gap={"20px"}>
                                <Button
                                  disabled={
                                    adminDetails_CEO?.emailId ===
                                    process.env
                                      .REACT_APP_EMAILID_FOR_ADMIN_DEVELOPER
                                      ? true
                                      : false
                                  }
                                  variant="contained"
                                  sx={{ textTransform: "capitalize" }}
                                  onClick={() =>
                                    updateAdminVerify(familyApi.email_id, "YES")
                                  }
                                >
                                  Yes
                                </Button>
                                <Button
                                  disabled={
                                    adminDetails_CEO?.emailId ===
                                    process.env
                                      .REACT_APP_EMAILID_FOR_ADMIN_DEVELOPER
                                      ? true
                                      : false
                                  }
                                  variant="contained"
                                  sx={{ textTransform: "capitalize" }}
                                  onClick={() =>
                                    updateAdminVerify(familyApi.email_id, "NO")
                                  }
                                >
                                  No
                                </Button>
                              </Box>
                            </Box>
                          </CardContent>
                        </Card>
                      </Box>
                    ) : null}
                  </Box>

                  <CustomBackDrop open={openState.loader}>
                    <CircularProgress color="inherit" />
                  </CustomBackDrop>
                </Box>
              )}
            </Grid>
          </Grid>

          <SeekerDialog
            open={open}
            handleChange={handleChange}
            getOne={getOne}
            setOpen={setOpen}
          />
        </Box>
        <ApproveDialog
          approveOpen={approveOpen}
          setApproveOpen={setApproveOpen}
          action={action}
          setAction={setAction}
          fetchFamilyApi={fetchFamilyApi}
          openState={openState}
          setOpenState={setOpenState}
          email={familyApi.email_id}
          isLoading={openState.isLoading}
        />
        <CompleteByAdminDialog
          skipOpen={skipOpen}
          setSkipOpen={setSkipOpen}
          action={"Admin"}
          fetchFamilyApi={fetchFamilyApi}
          email={familyApi.email_id}
          openState={openState}
          setOpenState={setOpenState}
        />
      </>
    );
  } else {
    return (
      <>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <Card sx={{ padding: "20px", borderRadius: "20px" }}>
            <Skeleton
              animation="wave"
              variant="text"
              width={230}
              height={40}
              sx={{ borderRadius: "15px" }}
            />
          </Card>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            <Card
              sx={{
                borderRadius: "20px",

                padding: "20px",
              }}
            >
              <SkeletonSeekCard />
            </Card>
          </Box>
          <Card
            sx={{
              borderRadius: "20px",

              padding: "20px",

              height: "500px",
              gap: "20px",
            }}
          >
            <Skeleton variant="rounded" />
            <Skeleton variant="rounded" width="100%" height="300px" />
          </Card>
        </Box>
      </>
    );
  }
}

export default FullFamilyDetails;
