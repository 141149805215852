import React from "react";
import { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { ROUTINGDATA } from "./components/ROUTINGDATA";
import Main from "./components/Main";
import Thankyou from "./components/ThankYouForm/Thankyou";
import Login from "./components/login/Login";
import ForgotPassword from "./components/login/forgot password/ForgotPassword";
import ForgotPW2 from "./components/login/forgot password/ForgotPW2";
import ChangePassword from "./components/login/change password/ChangePassword";
import JobSeekerRegForm from "./components/skeleton/jobseeker/JobSeekerRegForm";
import OtpforPassword from "./components/login/forgot password/OtpforPassword";
import Employer from "./components/skeleton/employer/Employer";
import AdminPanel from "./components/admin-panel/AdminPanel";
import Dashboard from "./components/admin-panel/admin-components/Dashboard";
import EmployerData from "./components/admin-panel/admin-components/EmployerData";

import SeekerData from "./components/admin-panel/admin-components/SeekerData";
import VolunteerData from "./components/admin-panel/admin-components/VolunteerData";
import UserData from "./components/admin-panel/admin-components/UserData";
import UpdateRegisterForm from "./components/skeleton/UpdateComponent/UpdateProfile/UpdateRegisterForm";
import LandingPage from "./components/Landingpage/Landingpage";
import EmployerUpdate from "./components/skeleton/EmployerUpdateProfile/EmployerUpdate";
import NeedSupport from "./components/skeleton/UpdateComponent/JobSeekerComponent/NeedSupport";
import BankDetails from "./components/skeleton/UpdateComponent/JobSeekerComponent/BankDetails";
import SeekerNeedSupport from "./components/admin-panel/admin-components/SeekerFamilyDetails/SeekerNeedSupport";
import PostJob from "./components/skeleton/PostJob/PostJob";
import PostedJob from "./components/skeleton/PostJob/PostedJob";
import MPconstituencyPanel from "./components/Dashboards/MPconstituency/MPconsitiuencyPanel";
import MPDashboard from "./components/Dashboards/MPconstituency/Components/MPDashboard";
import AppliedSeekers from "./components/skeleton/PostJob/AppliedSeekers";
import RecommendedJob from "./components/skeleton/UpdateComponent/JobSeekerComponent/RecommendedJob";
import FullFamilyDetails from "./components/admin-panel/admin-components/SeekerFamilyDetails/FullFamilyDetails";
import MLAconstituency1 from "./components/Dashboards/MPconstituency/Components/MLAconstituency1";
import SeekersApplied from "./components/skeleton/PostJob/SeekersApplied";
import VerifierPanel from "./components/Dashboards/Verifier/VerifierPanel";
import MLAconsitiuencyPanel from "./components/Dashboards/MLAconsitituency/MLAconstituencyPanel";
import VerifierDashboard from "./components/Dashboards/Verifier/Components/VerifierDashboard";
import VerifierFullDetails from "./components/Dashboards/Verifier/Components/VerifierFullDetails";
import Searchpage from "./components/Landingpage/Searchpage/Searchpage";
import Searchpageforlocalservices from "./components/Landingpage/Searchpage/Searchpageforlocalservices";
import AppliedJobs from "./components/skeleton/UpdateComponent/JobSeekerComponent/AppliedJobs";

import MLASeekers from "./components/Dashboards/MLAconsitituency/components/MLASeekers";
import MPMLAneedSupport from "./components/Dashboards/MPconstituency/Components/MPMLAneedSupport";
import MLAneedSupport from "./components/Dashboards/MLAconsitituency/components/MLAneedSupport";
import MLAdashboard from "./components/Dashboards/MLAconsitituency/components/MLAdashboard";
import MLAsupported from "./components/Dashboards/MLAconsitituency/components/MLAsupported";
import PageNotFound from "../src/components/skeleton/PageNotFound/PageNotFound";
import PaymentSuccess from "../src/components/skeleton/Subscriptions/Paymentmessage";
import { createContext } from "react";
import { BrowserRouter, HashRouter } from "react-router-dom";
import "./App.css";

import Volunteer from "./components/skeleton/VolunteerForm/Volunteer";
import VolunteerPanel from "./components/Dashboards/Volunteer/VolunteerPanel";

import VolunteerDashboard from "./components/Dashboards/Volunteer/components/VolunteerDashboard";
import VolunteerAllDetails from "./components/Dashboards/Volunteer/components/VolunteerAllDetails";
import VolunteerNeedSupport from "./components/Dashboards/Volunteer/components/VolunteerNeedSupport";
import VolunteerShortList from "./components/Dashboards/Volunteer/components/VolunteerShortList";
import MPMLAShortList from "./components/Dashboards/MPconstituency/Components/MPMLAShortList";
import ServiceSubscription from "./components/skeleton/Local_jobs_and_services/ServiceSubscription/Servicesubscription";
import MentorRegistration from "./components/skeleton/Mentor/MentorRegistration";
import MPAllData from "./components/Dashboards/MPconstituency/Components/Master/MPAllData";
import MPNeedSupport from "./components/Dashboards/MPconstituency/Components/Master/MPNeedSupport";
import MPShortList from "./components/Dashboards/MPconstituency/Components/Master/MPShortList";
import MPMLAsupported from "./components/Dashboards/MPconstituency/Components/MPMLAsupported";
import MPsupported from "./components/Dashboards/MPconstituency/Components/Master/MPsupported";
import VolunteerSupported from "./components/Dashboards/Volunteer/components/VolunteerSupported";
import Landingpagecontext from "./components/Landingpage/landingpagecontext/Landingpagecontext";
import LandingpageContactus from "./components/Landingpage/landingpagecontext/LandingpageContactus";
import LocalJobRegForm from "./components/skeleton/Local_jobs_and_services/LocalJob/LocalJobRegForm";
import LocalServiceProviderForm from "./components/skeleton/Local_jobs_and_services/LocalServiceProvider/LocalServiceProviderForm";
import LocalServicesForm from "./components/skeleton/Local_jobs_and_services/LocalServices/LocalServicesForm";
import Auditing from "./components/skeleton/PostJob/Auditing";
import ThankYouPageForLocal from "./components/ThankYouForm/ThankYouPageForLocal";
import MentorAboutUs from "./components/skeleton/Mentor/MentorAboutUs";
import MentorAdminData from "./components/admin-panel/admin-components/MentorAdminData";
import MentorUpdateForm from "./components/skeleton/Mentor/MentorUpdateForm";
import Subscribe from "./components/skeleton/Subscriptions/Subscribe";
import AdminSettings from "./components/admin-panel/admin-settings/AdminSettings";
import ProviderPanel from "./components/Dashboards/LocalJobsProvider/ProviderPanel";
import ProviderDashboard from "./components/Dashboards/LocalJobsProvider/Components/ProviderDashboard";
import AdminApproval from "./components/admin-panel/admin-settings/components/AdminApproval";
import AdminEmployerSettings from "./components/admin-panel/admin-settings/components/AdminEmployerSettings";
import Advertisement from "./components/Landingpage/Advertisement/Advertisement";
import useAxios from "./api/useAxios";
import AdminServiceProvider from "./components/admin-panel/admin-components/ServiceProvider";
import ServiceProvider from "./components/admin-panel/admin-components/ServiceProvider";
import LocalServiceLogin from "./components/skeleton/Local_jobs_and_services/LocalServices/LocalServiceLogin";
import LocalServicePanel from "./components/skeleton/Local_jobs_and_services/LocalServices/LocalServicePanel";
import AdvertisePayment from "./components/Landingpage/Advertisement/AdvertisePayment";
import BecomeAPartner from "./components/skeleton/Become_a_Partner_investor/BecomeAPartner";
import TermsAndCondition from "./components/termsAndCondition/TermsAndCondition";
import PrivacyPolicy from "./components/termsAndCondition/PrivacyPolicy";
import PurchasePolicy from "./components/termsAndCondition/PurchasePolicy";
import RefundPolicy from "./components/termsAndCondition/RefundPolicy";
import DeliveryPolicy from "./components/termsAndCondition/DeliveryPolicy";
import PricingPolicy from "./components/termsAndCondition/PricingPolicy";
import BecomeAInvestor from "./components/skeleton/Become_a_Partner_investor/BecomeAInvestor";
import AdminAdvertisementSettings from "./components/admin-panel/admin-settings/components/AdminAdvertisementSettings";
import AdminPartners from "./components/admin-panel/admin-components/AdminPartners";
import AdminInvestors from "./components/admin-panel/admin-components/AdminInvestors";
import PartnerPanel from "./components/Dashboards/partner/PartnerPanel";
import PartnerDashboard from "./components/Dashboards/partner/components/PartnerDashboard";
import ImageUploadSettings from "./components/admin-panel/admin-settings/components/ImageUploadSettings";
import AdminImageUpload from "./components/admin-panel/admin-settings/components/AdminImageUpload";
import SeekerVelaivendumSupport from "./components/admin-panel/admin-components/SeekerVelaivendumSupport";
import AdPaymentSettings from "./components/admin-panel/admin-settings/components/AdPaymentSettings";
import InvestorDashboard from "./components/Dashboards/investor/component/InvestorDashboard";
import InvestorPanel from "./components/Dashboards/investor/Investorpanel";
import InvestorAllocatedPincodes from "./components/Dashboards/investor/component/InvestorAllocatedPincodes";
import InvestorCreatePincodeFranchiser from "./components/Dashboards/investor/component/InvestorCreatePincodeFranchiser";
import InvestorPincodewisePayment from "./components/Dashboards/investor/component/InvestorPincodewisePayment";
import InvestorTodayCollectionReport from "./components/Dashboards/investor/component/InvestorTodayCollectionReport";
import InvestorMasterMyCoustomer from "./components/Dashboards/investor/component/InvestorMasterMyCoustomer"
import InvestorMasterMyLeads from "./components/Dashboards/investor/component/InvestorMasterMyLeads"
import InvestorMyEmployer from "./components/Dashboards/investor/component/InvestorMyEmployer"
import PaymentVendors from "./components/admin-panel/admin-settings/components/PaymentVendors"

import BuyDatabaseAccess from "./components/skeleton/EmployerDatabaseAccess/BuyDatabaseAccess";
import ViewDatabase from "./components/skeleton/EmployerDatabaseAccess/ViewDatabase";
import DataBaseThanksCard from "./components/skeleton/EmployerDatabaseAccess/DataBaseThanksCard";
export const CurrentStateContext = createContext("");

function App() {
  const axiosData = useAxios();
  const lang = sessionStorage.getItem("lang");
  const langCheck = lang === null ? "en" : lang;
  useEffect(() => {
    sessionStorage.setItem("lang", langCheck);
  }, []);
  const [languagetranslator, setlanguagetranslator] = useState(
    lang ? lang : "en"
  );
  const [getAdminSetting, setGetAdminSetting] = useState({});
  const [checked, setChecked] = useState({
    checked0: null,
    checked1: null,
    checked2: null,
    checked3: null,
    checked4: null,
    checked5: null,
    checked6: null,
  });
  const getAdminSettingData = async () => {
    try {
      const adminSet = await axiosData.get("application");
      const { data } = adminSet;
      setGetAdminSetting(data[0]);
      setChecked({
        ...checked,
        checked0: data[0].jobSeekerApproval,
        checked1: data[0].employerApproval,
        checked2: data[0].volunteerApproval,
        checked3: data[0].mentorApproval,
        checked4: data[0].needSupportApproval,
        checked5: data[0].verifierApproval,
        checked6: data[0].servicesProviderApproval,
      });
    } catch (err) {
      console.error(err);
    }
  };
  const getFunction = { myFunction: getAdminSettingData };
  useEffect(() => {
    getAdminSettingData();
  }, []);

  return (
    <CurrentStateContext.Provider
      value={{ languagetranslator, getAdminSetting, getFunction, checked }}
    >
      <React.Fragment>
        {/* <BrowserRouter history={createBrowserHistory()} forceRefresh={false}> */}
        <HashRouter>
          <Routes>
            <Route
              path={ROUTINGDATA.LAND}
              element={
                <LandingPage setlanguagetranslator={setlanguagetranslator} />
              }
            >
              <Route
                path={ROUTINGDATA.LANDPAGECONTECT}
                element={<Landingpagecontext />}
              />
              <Route
                path={ROUTINGDATA.LANDPAGECONTACTUS}
                element={<LandingpageContactus />}
              />
              <Route
                path={ROUTINGDATA.TERMSANDCONDITION}
                element={<TermsAndCondition />}
              />
              <Route
                path={ROUTINGDATA.PrivacyPolicy}
                element={<PrivacyPolicy />}
              />
              <Route
                path={ROUTINGDATA.PurchasePolicy}
                element={<PurchasePolicy />}
              />
              <Route
                path={ROUTINGDATA.RefundPolicy}
                element={<RefundPolicy />}
              />
              <Route
                path={ROUTINGDATA.DeliveryPolicy}
                element={<DeliveryPolicy />}
              />
              <Route
                path={ROUTINGDATA.PricingPolicy}
                element={<PricingPolicy />}
              />
            </Route>
            <Route path={ROUTINGDATA.LOGIN} element={<Login />} />
            <Route
              path={ROUTINGDATA.BECOMEAPARTNER}
              element={<BecomeAPartner />}
            />
            <Route
              path={ROUTINGDATA.BECOMEAINVESTOR}
              element={<BecomeAInvestor />}
            />
            <Route
              path={ROUTINGDATA.LOCAL_SERVICE_LOGIN}
              element={<LocalServiceLogin />}
            />
            <Route
              path={ROUTINGDATA.LOCAL_SERVICE_PANEL}
              element={<LocalServicePanel />}
            />
            <Route
              path={ROUTINGDATA.MAIN}
              element={<Main setlanguagetranslator={setlanguagetranslator} />}
            >
              <Route path={ROUTINGDATA.POSTJOB} element={<PostJob />} />
              <Route path={ROUTINGDATA.AUDITING} element={<Auditing />} />
              <Route path={ROUTINGDATA.POSTEDJOB} element={<PostedJob />} />
              <Route
                path={ROUTINGDATA.BUYDATABASEACCESS}
                element={<BuyDatabaseAccess />}
              />
              <Route
                path={ROUTINGDATA.VIEWDATABASE}
                element={<ViewDatabase />}
              />
              <Route
                path={ROUTINGDATA.DataBaseThanksCard}
                element={<DataBaseThanksCard />}
              />
              <Route path={ROUTINGDATA.SUBSCRIPTIONS} element={<Subscribe />} />
              <Route
                path={ROUTINGDATA.PAYMENTSUCCESS}
                element={<PaymentSuccess />}
              />
              <Route
                path={ROUTINGDATA.APPLIEDSEEKERS}
                element={<SeekersApplied />}
              />
              <Route
                path={ROUTINGDATA.SEEKERSAPPLIED}
                element={<AppliedSeekers />}
              />
              <Route
                path={ROUTINGDATA.JOBSEEK}
                element={<JobSeekerRegForm />}
              />
              <Route
                path={ROUTINGDATA.EMPLOYERUPDATE}
                element={<EmployerUpdate />}
              />
              <Route path={ROUTINGDATA.ThankYou} element={<Thankyou />} />
              <Route
                path={ROUTINGDATA.ThankYouForLocal}
                element={<ThankYouPageForLocal />}
              />
              <Route
                path={ROUTINGDATA.PageNotFound}
                element={<PageNotFound />}
              />
              <Route path={ROUTINGDATA.JOBPROVIDER} element={<Employer />} />
              <Route path={ROUTINGDATA.VOLUNTEER} element={<Volunteer />} />
              <Route
                path={ROUTINGDATA.MENTOR}
                element={<MentorRegistration />}
              />
              <Route
                path={ROUTINGDATA.MENTORABOUTUS}
                element={<MentorAboutUs />}
              />
              <Route
                path={ROUTINGDATA.MENTORUPDATE}
                element={<MentorUpdateForm />}
              />
              <Route
                path={ROUTINGDATA.MENTORProfile}
                element={<MentorAboutUs />}
              />
              <Route
                path={ROUTINGDATA.SEEKERNEEDSUPPORT}
                element={<NeedSupport />}
              />
              <Route path={ROUTINGDATA.BankDetails} element={<BankDetails />} />
              <Route
                path={ROUTINGDATA.RecommendedJob}
                element={<RecommendedJob />}
              />
              <Route
                path={ROUTINGDATA.ADVERTISEMENT}
                element={<Advertisement />}
              />
              <Route
                path={ROUTINGDATA.AdvertisePayment}
                element={<AdvertisePayment />}
              />
              <Route path={ROUTINGDATA.AppliedJobs} element={<AppliedJobs />} />
              <Route path={ROUTINGDATA.Searchpage} element={<Searchpage />} />
              <Route
                path={ROUTINGDATA.Searchpageforlocalservices}
                element={<Searchpageforlocalservices />}
              />
              <Route
                path={ROUTINGDATA.seekerProfile}
                element={<UpdateRegisterForm />}
              ></Route>
              {/* <Route path={ROUTINGDATA.EMPLOYERUPDATE} element={<EmployerUpdate />} /> */}
              <Route
                path={ROUTINGDATA.LOCALJOB}
                element={<LocalJobRegForm />}
              />
              <Route
                path={ROUTINGDATA.LOCAL_SERVICE_PROVIDER}
                element={<LocalServiceProviderForm />}
              />
              <Route
                path={ROUTINGDATA.LOCAL_SERVICES}
                element={<LocalServicesForm />}
              />
              <Route
                path={ROUTINGDATA.SERVICESUBSCRIPTIONS}
                element={<ServiceSubscription />}
              />
            </Route>

            <Route path={ROUTINGDATA.LOGIN} element={<Login />} />
            <Route path={ROUTINGDATA.ForgotPW} element={<ForgotPassword />} />
            <Route path={ROUTINGDATA.ForgotPwOtp} element={<ForgotPW2 />} />
            <Route
              path={ROUTINGDATA.ChangePassword}
              element={<ChangePassword />}
            />
            <Route
              path={ROUTINGDATA.OtpforPassword}
              element={<OtpforPassword />}
            />
            <Route
              path={ROUTINGDATA.ChangePassword}
              element={<ChangePassword />}
            />
            {/* ADMIN */}
            <Route path={ROUTINGDATA.ADMINPANEL} element={<AdminPanel />}>
              <Route
                path={ROUTINGDATA.ADMINDASHBOARD}
                element={<Dashboard />}
              />
              <Route
                path={ROUTINGDATA.ADMINSETTINGS}
                element={<AdminSettings />}
              >
                <Route
                  path={ROUTINGDATA.ADMINAPPROVAL}
                  element={<AdminApproval />}
                />
                <Route
                  path={ROUTINGDATA.ADMINEMPLOYERSETTING}
                  element={<AdminEmployerSettings />}
                />
                <Route
                  path={ROUTINGDATA.ADMINIMAGEUPLOADSETTING}
                  element={<ImageUploadSettings />}
                />
                <Route
                  path={ROUTINGDATA.AdminImageUpload}
                  element={<AdminImageUpload />}
                />
                <Route
                  path={ROUTINGDATA.ADPAYMENTSETTING}
                  element={<AdPaymentSettings />}
                />
              </Route>
              <Route path={ROUTINGDATA.ADMINSEEKERS} element={<SeekerData />} />
              <Route
                path={ROUTINGDATA.ADMINSERVICEPROVIDER}
                element={<ServiceProvider />}
              />
              <Route path="user" element={<UserData />} />
              <Route
                path={ROUTINGDATA.ADMINEMPLOYER}
                element={<EmployerData />}
              />
              <Route
                path={ROUTINGDATA.ADMINVOLUNTEER}
                element={<VolunteerData />}
              />
              <Route
                path={ROUTINGDATA.ADMINMENTOR}
                element={<MentorAdminData />}
              />{" "}
              <Route
                path={ROUTINGDATA.ADMINPARTNER}
                element={<AdminPartners />}
              />
              <Route
                path={ROUTINGDATA.ADMININVESTOR}
                element={<AdminInvestors />}
              >
                <Route
                  path={ROUTINGDATA.BECOMEAINVESTOR}
                  element={<BecomeAInvestor />}
                />
              </Route>
              <Route
                path={ROUTINGDATA.ADMINMENTORABOUTUS}
                element={<MentorAboutUs />}
              />
              <Route
                path={ROUTINGDATA.ADVERTISEMENTSETTING}
                element={<AdminAdvertisementSettings />}
              />
              <Route
                path={ROUTINGDATA.PAYMENTVENDORS}
                element={<PaymentVendors />}
              />
              <Route
                path={ROUTINGDATA.ADMINNeedSupport}
                element={<SeekerNeedSupport />}
              />
              <Route
                path={ROUTINGDATA.ADMINSeekerFullFamilyDetails}
                element={<FullFamilyDetails />}
              />
              <Route
                path={ROUTINGDATA.ADMINSeekerVelaivendumSupport}
                element={<SeekerVelaivendumSupport />}
              />
              <Route
                path={ROUTINGDATA.fieldVerifiyCompletedFullDetails}
                element={<FullFamilyDetails />}
              />
            </Route>
            {/* -------- */}
            <Route
              path={ROUTINGDATA.MPConstituency}
              element={<MPconstituencyPanel />}
            >
              <Route path={ROUTINGDATA.MPDASHBOARD} element={<MPDashboard />} />
              <Route path={ROUTINGDATA.MP_ALL_DATA} element={<MPAllData />} />
              <Route
                path={ROUTINGDATA.MP_NEEDSUPPORT}
                element={<MPNeedSupport />}
              />
              <Route
                path={ROUTINGDATA.MP_SHORTLISTED}
                element={<MPShortList />}
              />
              <Route
                path={ROUTINGDATA.MP_Shortlisted_fullDetails}
                element={<VerifierFullDetails />}
              />
              <Route
                path={ROUTINGDATA.MP_MLAComponent}
                element={<MLAconstituency1 />}
              />
              <Route
                path={ROUTINGDATA.MP_MLAneedSupport}
                element={<MPMLAneedSupport />}
              />
              <Route
                path={ROUTINGDATA.MP_MLAShortlisted}
                element={<MPMLAShortList />}
              />
              <Route
                path={ROUTINGDATA.MP_MLAfullDetais}
                element={<VerifierFullDetails />}
              />
              <Route
                path={ROUTINGDATA.MP_MLAShortlisted_fullDetails}
                element={<VerifierFullDetails />}
              />
              <Route
                path={ROUTINGDATA.MP_MLASupported}
                element={<MPMLAsupported />}
              />
              <Route
                path={ROUTINGDATA.MP_MLASupported_fullDetails}
                element={<VerifierFullDetails />}
              />
              <Route
                path={ROUTINGDATA.MP_Supported}
                element={<MPsupported />}
              />
              <Route
                path={ROUTINGDATA.MP_Supported_fullDetails}
                element={<VerifierFullDetails />}
              />
            </Route>

            {/* MLA CONSTITUENCY */}
            <Route
              path={ROUTINGDATA.MLAconstituency}
              element={<MLAconsitiuencyPanel />}
            >
              <Route
                path={ROUTINGDATA.MLAdashboard}
                element={<MLAdashboard />}
              />
              <Route path={ROUTINGDATA.MLASeeker} element={<MLASeekers />} />
              <Route
                path={ROUTINGDATA.MLAneedSupport}
                element={<MLAneedSupport />}
              />
              <Route
                path={ROUTINGDATA.MLAShortlisted}
                element={<MLAneedSupport />}
              />
              <Route
                path={ROUTINGDATA.MLAfulDetails}
                element={<VerifierFullDetails />}
              />
              <Route
                path={ROUTINGDATA.MLAShortlisted_fullDetails}
                element={<VerifierFullDetails />}
              />
              <Route
                path={ROUTINGDATA.MLASupported}
                element={<MLAsupported />}
              />
              <Route
                path={ROUTINGDATA.MLASupported_fullDetails}
                element={<VerifierFullDetails />}
              />
            </Route>

            {/*------- VERIFIER_PANEL----- */}

            <Route path={ROUTINGDATA.verifier} element={<VerifierPanel />}>
              <Route
                path={ROUTINGDATA.verifierDash}
                element={<VerifierDashboard />}
              />
              <Route
                path={ROUTINGDATA.verifierFullDetails}
                element={<VerifierFullDetails />}
              />
            </Route>

            {/* VOLUNTEER */}

            <Route
              path={ROUTINGDATA.volunteerPanel}
              element={<VolunteerPanel />}
            >
              <Route
                path={ROUTINGDATA.volunteerDashboard}
                element={<VolunteerDashboard />}
              />
              <Route
                path={ROUTINGDATA.volunteerAllData}
                element={<VolunteerAllDetails />}
              />
              <Route
                path={ROUTINGDATA.volunteerNeedsupport}
                element={<VolunteerNeedSupport />}
              />
              <Route
                path={ROUTINGDATA.volunteerShortlisted}
                element={<VolunteerShortList />}
              />
              <Route
                path={ROUTINGDATA.volunteerFullDetails}
                element={<VerifierFullDetails />}
              />
              <Route
                path={ROUTINGDATA.volunteerShortlisted_fullDetails}
                element={<VerifierFullDetails />}
              />
              <Route
                path={ROUTINGDATA.volunteerSupported}
                element={<VolunteerSupported />}
              />
              <Route
                path={ROUTINGDATA.volunteerSupported_fullDetails}
                element={<VerifierFullDetails />}
              />
              {/* <Route
            path={ROUTINGDATA.volunteerAllData}
            element={<VolunteerAllDetails />}
          /> */}
            </Route>

            <Route
              path={ROUTINGDATA.LOCALPROVIDERPANEL}
              element={<ProviderPanel />}
            >
              <Route
                path={ROUTINGDATA.LOCALPROVIDERDASHBOARD}
                element={<ProviderDashboard />}
              />
            </Route>
            <Route path={ROUTINGDATA.PARTNERPANEL} element={<PartnerPanel />}>
              <Route
                path={ROUTINGDATA.PARTNERDASH}
                element={<PartnerDashboard />}
              />
            </Route>
            <Route path={ROUTINGDATA.INVESTORPANEL} element={<InvestorPanel />}>
              <Route
                path={ROUTINGDATA.INVESTORDASH}
                element={<InvestorDashboard />}
              />
              <Route
                path={ROUTINGDATA.INVESTORALLOCATEDPINCODES}
                element={<InvestorAllocatedPincodes />}
              />
              <Route
                path={ROUTINGDATA.INVESTORCREATEPINCODEFRANCHISER}
                element={<InvestorCreatePincodeFranchiser />}
              />
              <Route
                path={ROUTINGDATA.INVESTORPINCODEWISEPAYMENT}
                element={<InvestorPincodewisePayment />}
              />
              <Route
                path={ROUTINGDATA.INVESTORTODAYCOLLECTIONREPORT}
                element={<InvestorTodayCollectionReport />}
              />
               <Route
                path={ROUTINGDATA.INVESTORMASTERMYLEADS}
                element={<InvestorMasterMyLeads />}
              />
               <Route
                path={ROUTINGDATA.INVESTORMASTERMYCOUSTOMER}
                element={<InvestorMasterMyCoustomer />}
              />
              <Route
                path={ROUTINGDATA.INVESTORMASTERMYEMPLOYER}
                element={<InvestorMyEmployer />}
              />
            </Route>
          </Routes>
        </HashRouter>

        {/* </BrowserRouter> */}
      </React.Fragment>
    </CurrentStateContext.Provider>
  );
}

export default App;
