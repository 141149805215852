import React from "react";
import { IconButton } from "@mui/material";
import { utils, writeFile } from "xlsx";
import xls from "../../../../img/admin-panel/icons8-xls-48 (1).png";
import moment from "moment";
// import pdf from "../../../img/admin-panel/icons8-pdf-48.png";
function ExportCS({ selectedApiForExcel, fileName }) {
  function exportToCSV() {
    const worksheet = utils.json_to_sheet(selectedApiForExcel);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Data");
    writeFile(workbook, `${fileName}(${moment().format("DD-MM-YYYY")}).csv`);
  }
  return (
    <IconButton size="small" onClick={exportToCSV}>
      <img src={xls} alt="" />
    </IconButton>
  );
}

export default ExportCS;
