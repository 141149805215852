import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  Button,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  Box,
  Divider,
  Chip,
  IconButton,
  Tooltip,
  Skeleton,
} from "@mui/material";
import { FileDownload as FileDownloadIcon } from "@mui/icons-material/";
import useAxios from "../../../../api/useAxios";
import defaultimg from "../../../../img/mentorImage.png";
import ApproveDialog from "./ApproveDialog";
import AdminSnackBar from "../SnackBars/AdminSnackBar";
import DeclineDialog from "./DeclineDialog";
import { useLocation } from "react-router-dom";
import { ROUTINGDATA } from "../../../ROUTINGDATA";
import { saveAs } from "file-saver";
function SeekerDialog({
  open,
  handleChange,
  getOne,
  setOpen,
  filterName,
  setFilterName,
  fetchApi,
  dialogLoad,
  setGetOne,
}) {
  const [getPutId, setGetPutId] = useState(0);
  const [getEmail, setGetEmail] = useState("");
  const [getPass, setGetPass] = useState("");
  const [value, setValue] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [snackOption, setSnackOption] = useState("");
  const [approveOpen, setApproveOpen] = useState(false);
  const [declineOpen, setDeclineOpen] = useState(false);

  const axiosData = useAxios();
  const baseUrl = axiosData.defaults.baseURL;
  let adminDetails_CEO = JSON.parse(localStorage.getItem("admin_dev"));

  const location = useLocation();
  const currentUrl = location.pathname;
  const updateApprove = async (id, email_id, password) => {
    setFilterName({ ...filterName, isLoading: true });
    try {
      await axiosData.put(`seekers/${id}`, { status: "A" });
      await axiosData.put(`users/${email_id}`, {
        password: password,
        status: "Active",
        usertype: "seeker",
      });
      setGetPutId(0);
      setGetEmail("");
      setGetPass("");
      setOpen(false);
      setApproveOpen(false);
      setOpenSnack(true);
      setSnackOption("success");
      fetchApi();
    } catch (err) {
      console.log("Error", err);
    }
    setFilterName({ ...filterName, isLoading: false });
  };
  const downloadResume = (id) => {
    saveAs(`${baseUrl}seekers/resume/${id}`, id + "Resume");
  };
  const updateDecline = async (id, email_id) => {
    setFilterName({ ...filterName, isLoading: true });

    try {
      await axiosData.put(`seekers/${id}`, { status: "D", reason: value });
      axiosData.put(
        `users/${email_id}`,
        { status: "InActive", usertype: "seeker", reason: value },

        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setGetPutId(0);
      setGetEmail("");
      setValue("");
      setDeclineOpen(false);
      setOpen(false);
      setOpenSnack(true);
      setSnackOption("error");
      fetchApi();
    } catch (err) {
      console.log("Error", err);
    }
    setFilterName({ ...filterName, isLoading: false });
  };
  return (
    <>
      {" "}
      <Dialog
        fullWidth
        open={open}
        onClose={handleChange}
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "20px",
          },
        }}
      >
        <DialogTitle bgcolor="#055779" color="white" height="100%">
          <Box id="main-box">
            <Box>
              {" "}
              <div id className="align-img">
                <img
                  alt="img"
                  id="profileImage"
                  src={`${baseUrl}seekers/profile/${getOne.mobile_number}`}
                  onError={(e) => {
                    e.target.src = defaultimg;
                  }}
                  className="updateProfileImage"
                />
              </div>
            </Box>
            <Box>
              <Typography
                variant="body1"
                sx={{
                  textTransform: "uppercase",
                  fontFamily: "Barlow-Bold",
                }}
              >
                <div className="titleseeker">
                  {getOne.full_name}
                  <div
                    style={{
                      textTransform: "capitalize",
                      fontFamily: "Barlow-Regular",
                    }}
                  >
                    {getOne.work_status}
                  </div>
                </div>
              </Typography>
            </Box>

            <Box>
              {adminDetails_CEO?.emailId ===
              process.env.REACT_APP_EMAILID_FOR_ADMIN_DEVELOPER ? null : (
                <Tooltip title="Download Resume" placement="top">
                  <IconButton
                    className="icon-btn-dbox  "
                    onClick={() => downloadResume(getOne.mobile_number)}
                    size="medium"
                    disabled={!getOne.fileUpload}
                  >
                    <FileDownloadIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent dividers="paper" sx={{ backgroundColor: "#f2f2f2" }}>
          <Box sx={{ padding: "20px" }}>
            <Grid container md={12} columnSpacing={1} direction="column">
              <Divider
                sx={{
                  "&::before, &::after": {
                    borderColor: "#055779",
                  },
                  color: "#055779 ",
                }}
                textAlign="left"
              >
                Personal Details
              </Divider>
              <Box sx={{ padding: "20px" }}>
                <Typography variant="h6" sx={{ fontFamily: "Poppins-Regular" }}>
                  <div className="align-text">
                    <span className="full-details"> Full Name:</span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        getOne.full_name
                      )}
                    </div>
                  </div>
                </Typography>
                <Typography variant="h6">
                  <div className="align-text">
                    <span className="full-details"> Gender:</span>
                    <div>
                      {" "}
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        getOne.gender
                      )}
                    </div>
                  </div>
                </Typography>{" "}
                <Typography variant="h6">
                  <div className="align-text">
                    <span className="full-details"> Email:</span>
                    <div>
                      {" "}
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        getOne.email_id
                      )}
                    </div>
                  </div>
                </Typography>{" "}
                <Typography variant="h6">
                  <div className="align-text">
                    <span className="full-details"> Mobile:</span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        getOne.mobile_number
                      )}
                    </div>
                  </div>
                </Typography>{" "}
              </Box>
              <Divider
                sx={{
                  "&::before, &::after": {
                    borderColor: "#055779",
                  },
                  color: "#055779 ",
                }}
                textAlign="left"
              >
                Professional Details
              </Divider>{" "}
              <Box sx={{ padding: "20px" }}>
                <Typography variant="h6">
                  <div className="align-text">
                    <span className="full-details">Experience:</span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        getOne.work_status
                      )}
                    </div>
                  </div>
                </Typography>{" "}
                {getOne.work_status === "Experienced" && (
                  <Typography variant="h6">
                    <div className="align-text">
                      <span className="full-details">
                        {" "}
                        Years of Experience:
                      </span>
                      <div>
                        {dialogLoad ? (
                          <Skeleton
                            animation="wave"
                            variant="text"
                            width={230}
                            height={40}
                            sx={{ borderRadius: "15px" }}
                          />
                        ) : (
                          getOne.Experience
                        )}
                      </div>
                    </div>
                  </Typography>
                )}
                <Typography variant="h6">
                  <div className="align-text">
                    <span className="full-details"> Industry:</span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        getOne.industry
                      )}
                    </div>
                  </div>
                </Typography>{" "}
                <Typography variant="h6">
                  <div className="align-text">
                    <span className="full-details"> Current Location:</span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        getOne.currentLocation
                      )}
                    </div>
                  </div>
                </Typography>{" "}
              </Box>
              <Divider
                sx={{
                  "&::before, &::after": {
                    borderColor: "#055779",
                  },
                  color: "#055779 ",
                }}
                textAlign="left"
              >
                Education Details
              </Divider>
              <Box sx={{ padding: "20px" }}>
                <Typography variant="h6">
                  <div className="align-text">
                    <span className="full-details">Course:</span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        getOne.Education?.course
                      )}
                    </div>
                  </div>
                </Typography>
                <Typography variant="h6">
                  <div className="align-text">
                    <span className="full-details"> Education:</span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        getOne.Education?.Education
                      )}
                    </div>
                  </div>
                </Typography>{" "}
                <Typography variant="h6">
                  <div className="align-text">
                    <span className="full-details"> Institute:</span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        getOne.Education?.Institute
                      )}
                    </div>
                  </div>
                </Typography>{" "}
                <Typography variant="h6">
                  <div className="align-text">
                    <span className="full-details">Passed Out Year:</span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        getOne.Education?.courseDuration
                      )}
                    </div>
                  </div>
                </Typography>
                <Typography variant="h6">
                  <div className="align-text">
                    <span className="full-details"> Percentage:</span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        getOne.Education?.grade
                      )}
                      {getOne.Education?.grade && "%"}
                    </div>
                  </div>
                </Typography>
                <Typography variant="h6">
                  <div className="align-text">
                    <span className="full-details"> Key Skills:</span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        `${getOne.Keyskill}${"   "}`
                      )}
                    </div>
                  </div>
                </Typography>
              </Box>
              <Divider
                sx={{
                  "&::before, &::after": {
                    borderColor: "#055779",
                  },
                  color: "#055779 ",
                }}
                textAlign="left"
              >
                Profile Summary
              </Divider>
              <Box sx={{ padding: "20px" }}>
                <Typography variant="h6">{getOne.ProfileSummary}</Typography>
              </Box>
              <Box sx={{ padding: "20px" }}>
                <Divider
                  sx={{
                    "&::before, &::after": {
                      borderColor: "#055779",
                    },
                    color: "#055779 ",
                  }}
                  textAlign="left"
                >
                  Voting Details
                </Divider>{" "}
                <Typography variant="h6">
                  <div className="align-text">
                    <span className="full-details"> Voting District:</span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        getOne.votingDistrict
                      )}
                    </div>
                  </div>
                </Typography>{" "}
                <Typography variant="h6">
                  <div className="align-text">
                    <span className="full-details"> MP Constituency:</span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        getOne.mpConstituency
                      )}
                    </div>
                  </div>
                </Typography>{" "}
                <Typography variant="h6">
                  <div className="align-text">
                    <span className="full-details"> MLA Constituency:</span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        getOne.mlaConstituency
                      )}
                    </div>
                  </div>
                </Typography>{" "}
                <Typography variant="h6">
                  <div className="align-text">
                    <span className="full-details"> Pin-code:</span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        getOne.pincode
                      )}
                    </div>
                  </div>
                </Typography>{" "}
                <Typography variant="h6">
                  {getOne.videoUpload === undefined ||
                  getOne.videoUpload === "" ? (
                    <div className="align-text">
                      <span className="full-details">Video Profile:</span>
                      <div>No Video Uploaded</div>
                    </div>
                  ) : (
                    <div>
                      <div className="align-text">
                        <span
                          className="full-details"
                          style={{ textAlign: "left" }}
                        >
                          {" "}
                          Video Profile:
                        </span>
                      </div>
                      <div style={{ textAlign: "left" }}>
                        <video
                          className="VideoInput_video"
                          width="100%"
                          height="200px"
                          controls
                          src={
                            getOne.videoUpload !== undefined
                              ? getOne.videoUpload !== ""
                                ? `${baseUrl}seekers/video/${getOne.mobile_number}`
                                : ""
                              : ""
                          }
                        />
                      </div>
                    </div>
                  )}
                </Typography>
                {currentUrl ==
                ROUTINGDATA.LAND +
                  ROUTINGDATA.ADMINPANEL +
                  "/" +
                  ROUTINGDATA.ADMINSEEKERS ? (
                  <Typography textAlign="center" variant="h6">
                    <div className="align-text">
                      <span
                        className="full-details"
                        style={{ textAlign: "left" }}
                      >
                        {" "}
                        Status:
                      </span>
                      <div style={{ textAlign: "left" }}>
                        {dialogLoad ? (
                          <Skeleton
                            animation="wave"
                            variant="rounded"
                            width={40}
                            height={40}
                            sx={{ borderRadius: "15px" }}
                          />
                        ) : (
                          <Chip
                            label={getOne.status}
                            color={
                              getOne.status === "P"
                                ? "warning"
                                : getOne.status === "A"
                                ? "success"
                                : "error"
                            }
                            variant="contained"
                            sx={{ textAlign: "left" }}
                          />
                        )}
                      </div>
                    </div>
                  </Typography>
                ) : null}
                {getOne.status === "D" ? (
                  <>
                    <Typography variant="h6">
                      <div className="align-text">
                        <span className="full-details"> Reason:</span>
                        <div>{getOne.reason}</div>
                      </div>
                    </Typography>
                  </>
                ) : null}
              </Box>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button sx={{ textTransform: "capitalize" }} onClick={handleChange}>
            Close
          </Button>
          {currentUrl ==
          ROUTINGDATA.LAND +
            ROUTINGDATA.ADMINPANEL +
            "/" +
            ROUTINGDATA.ADMINSEEKERS ? (
            adminDetails_CEO?.emailId ===
            process.env.REACT_APP_EMAILID_FOR_ADMIN_DEVELOPER ? null : (
              <>
                <Button
                  className="action-btn"
                  sx={{ textTransform: "capitalize" }}
                  onClick={() => {
                    setGetPutId(getOne._id);
                    setGetEmail(getOne.email_id);
                    setGetPass(getOne.password);
                    setApproveOpen(true);
                  }}
                  variant="contained"
                  color="success"
                  disabled={getOne.status === "A" ? true : false}
                >
                  Approve
                </Button>{" "}
                <Button
                  className="action-btn"
                  sx={{ textTransform: "capitalize" }}
                  color="error"
                  onClick={() => {
                    setGetPutId(getOne._id);
                    setGetEmail(getOne.email_id);

                    setDeclineOpen(open);
                  }}
                  variant="contained"
                  disabled={getOne.status === "D" ? true : false}
                >
                  Decline
                </Button>
              </>
            )
          ) : null}
        </DialogActions>
      </Dialog>
      {currentUrl ==
      ROUTINGDATA.LAND +
        ROUTINGDATA.ADMINPANEL +
        "/" +
        ROUTINGDATA.ADMINSEEKERS ? (
        <>
          <ApproveDialog
            approveOpen={approveOpen}
            setApproveOpen={setApproveOpen}
            updateApprove={updateApprove}
            filterName={filterName}
            getPutId={getPutId}
            getEmail={getEmail}
            getPass={getPass}
          />
          <DeclineDialog
            declineOpen={declineOpen}
            setDeclineOpen={setDeclineOpen}
            value={value}
            setValue={setValue}
            filterName={filterName}
            updateDecline={updateDecline}
            getPutId={getPutId}
            getEmail={getEmail}
          />
          <AdminSnackBar
            openSnack={openSnack}
            setOpenSnack={setOpenSnack}
            snackOption={snackOption}
            full_name={getOne.full_name}
          />
        </>
      ) : null}
    </>
  );
}

export default SeekerDialog;
