import React, { useState, useEffect } from "react";
import Tooltip from "@mui/material/Tooltip";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import { Toolbar, Typography, IconButton, Button } from "@mui/material";
import logo from "../../img/logo_for-landingpage-3.png";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MoreIcon from "@mui/icons-material/MoreVert";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router";
import { ROUTINGDATA } from "../ROUTINGDATA";
import { useLocation } from "react-router-dom";
import { useContext } from "react";
import { CurrentStateContext } from "../../App";
import { useTranslation } from "react-i18next";

export default function Header(props) {
  const [clicked, setClicked] = useState(false);

  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const lang = sessionStorage.getItem("lang");
  const [lag, setlag] = useState(lang === "en" ? "English" : "தமிழ்");
  const handleClick = (event) => {
    console.log("event", event);
    if (event.target.innerText === "English") {
      setlag("தமிழ்");
      handleChangeLanguage("tamil");
      sessionStorage.setItem("lang", "tamil");
    } else {
      setlag("English");
      handleChangeLanguage("en");
      sessionStorage.setItem("lang", "en");
    }
  };

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const searchparams = ROUTINGDATA.Searchpage.split("/");
  const [searchcondtion, setcondtion] = useState(false);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const { t, i18n, ready } = useTranslation();
  const { languagetranslator } = useContext(CurrentStateContext);
  let userDetails = JSON.parse(localStorage.getItem("user"));
  let emailName;
  let fullName;
  if (userDetails) {
    emailName = userDetails.emailId;
    fullName = userDetails.company_name;
  }
  const logoutClickedemployer = () => {
    localStorage.removeItem("user");
    navigate(`${ROUTINGDATA.LAND}`);
  };
  let seekerDetails = JSON.parse(localStorage.getItem("seeker"));
  let emailName2;
  let fullName2;
  const logoutClickedseeker = () => {
    localStorage.removeItem("seeker");
    navigate(`${ROUTINGDATA.LAND}`);
  };
  if (seekerDetails) {
    emailName2 = seekerDetails.emailId;
    fullName2 = seekerDetails.name;
  }
  let mentorDetails = JSON.parse(localStorage.getItem("mentor"));
  let emailNamementor;
  let fullNamementor;
  const logoutClickedmentor = () => {
    localStorage.removeItem("mentor");
    navigate(`${ROUTINGDATA.LAND}`);
  };
  if (mentorDetails) {
    emailNamementor = mentorDetails.email_id;
    fullNamementor = mentorDetails.MentorName;
  }

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    sessionStorage.removeItem("activeLink");
    setAnchorEl(null);

    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const handleChangeLanguage = (val) => {
    props.setlanguagetranslator(val);
  };
  const changeLanguage = (languagetranslator) => {
    i18n.changeLanguage(languagetranslator);
  };

  useEffect(() => {
    handleChangeLanguage();
    changeLanguage(languagetranslator);
  }, [languagetranslator]);

  const location = useLocation();
  const currentUrl = location.pathname;
  useEffect(() => {
    const currentUrl = location.pathname;
    //setlag(lang === "en"?"English":"Tamil")
    const urlArray = currentUrl.split("/");
    if (
      urlArray[2] === "searchpage" ||
      urlArray[2] === "payment" ||
      urlArray[2] === "AdvertisePayment" ||
      urlArray[2] === "DataBaseThanksCard"
    ) {
      setcondtion(true);
    } else {
      setcondtion(false);
    }
  }, [searchparams]);
  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      className="menu-for-lagbtn"
    >
      <MenuItem
        onClick={() => {
          handleMenuClose();
          currentUrl ===
            ROUTINGDATA.LAND + ROUTINGDATA.MAIN + ROUTINGDATA.EMPLOYERUPDATE ||
          currentUrl ===
            ROUTINGDATA.LAND + ROUTINGDATA.MAIN + ROUTINGDATA.POSTJOB ||
          currentUrl ===
            ROUTINGDATA.LAND + ROUTINGDATA.MAIN + ROUTINGDATA.POSTEDJOB ||
          currentUrl ===
            ROUTINGDATA.LAND + ROUTINGDATA.MAIN + ROUTINGDATA.APPLIEDSEEKERS ||
          currentUrl ===
            ROUTINGDATA.LAND + ROUTINGDATA.MAIN + ROUTINGDATA.SEEKERSAPPLIED ||
          currentUrl ===
            ROUTINGDATA.LAND + ROUTINGDATA.MAIN + ROUTINGDATA.AUDITING ||
          currentUrl ===
            ROUTINGDATA.LAND + ROUTINGDATA.MAIN + ROUTINGDATA.VIEWDATABASE ||
          currentUrl ===
            ROUTINGDATA.LAND +
              ROUTINGDATA.MAIN +
              ROUTINGDATA.BUYDATABASEACCESS ||
          currentUrl ===
            ROUTINGDATA.LAND +
              ROUTINGDATA.MAIN +
              ROUTINGDATA.DataBaseThanksCard ||
          currentUrl ===
            ROUTINGDATA.LAND + ROUTINGDATA.MAIN + ROUTINGDATA.SUBSCRIPTIONS
            ? logoutClickedemployer()
            : currentUrl ===
                ROUTINGDATA.LAND +
                  ROUTINGDATA.MAIN +
                  ROUTINGDATA.seekerProfile ||
              currentUrl ===
                ROUTINGDATA.LAND +
                  ROUTINGDATA.MAIN +
                  ROUTINGDATA.RecommendedJob ||
              currentUrl ===
                ROUTINGDATA.LAND +
                  ROUTINGDATA.MAIN +
                  ROUTINGDATA.SEEKERNEEDSUPPORT ||
              currentUrl ===
                ROUTINGDATA.LAND + ROUTINGDATA.MAIN + ROUTINGDATA.AppliedJobs ||
              currentUrl ===
                ROUTINGDATA.LAND + ROUTINGDATA.MAIN + ROUTINGDATA.BankDetails
            ? logoutClickedseeker()
            : currentUrl ===
                ROUTINGDATA.LAND +
                  ROUTINGDATA.MAIN +
                  ROUTINGDATA.MENTORABOUTUS ||
              currentUrl ===
                ROUTINGDATA.LAND + ROUTINGDATA.MAIN + ROUTINGDATA.MENTORUPDATE
            ? logoutClickedmentor()
            : localStorage.removeItem(null);
          navigate(`${ROUTINGDATA.LAND}`);
        }}
      >
        <LogoutIcon sx={{ color: "gray" }} />
        <span className="logout">Logout</span>
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      className="menu-for-lagbtn"
    >
      <MenuItem
        sx={{
          display:
            currentUrl ===
              ROUTINGDATA.LAND + ROUTINGDATA.MAIN + ROUTINGDATA.JOBSEEK ||
            currentUrl ===
              ROUTINGDATA.LAND + ROUTINGDATA.MAIN + ROUTINGDATA.JOBPROVIDER ||
            currentUrl ===
              ROUTINGDATA.LAND + ROUTINGDATA.MAIN + ROUTINGDATA.VOLUNTEER ||
            currentUrl ===
              ROUTINGDATA.LAND + ROUTINGDATA.MAIN + ROUTINGDATA.MENTOR
              ? "block"
              : "none",
        }}
        onClick={() => {
          navigate(`${ROUTINGDATA.LAND}${ROUTINGDATA.LOGIN}`);
          handleMenuClose();
        }}
      >
        <span className="logout">{t("Login")}</span>
      </MenuItem>
      <MenuItem
        sx={{
          display:
            currentUrl ===
              ROUTINGDATA.LAND + ROUTINGDATA.MAIN + ROUTINGDATA.JOBSEEK ||
            currentUrl ===
              ROUTINGDATA.LAND + ROUTINGDATA.MAIN + ROUTINGDATA.JOBPROVIDER ||
            currentUrl ===
              ROUTINGDATA.LAND + ROUTINGDATA.MAIN + ROUTINGDATA.Searchpage ||
            currentUrl ===
              ROUTINGDATA.LAND + ROUTINGDATA.MAIN + ROUTINGDATA.VOLUNTEER ||
            currentUrl ===
              ROUTINGDATA.LAND + ROUTINGDATA.MAIN + ROUTINGDATA.MENTOR ||
            currentUrl ===
              ROUTINGDATA.LAND + ROUTINGDATA.MAIN + ROUTINGDATA.MENTORProfile
              ? "none"
              : "block",
        }}
        onClick={() => {
          navigate(ROUTINGDATA.LAND);
          currentUrl ===
            ROUTINGDATA.LAND + ROUTINGDATA.MAIN + ROUTINGDATA.EMPLOYERUPDATE ||
          currentUrl ===
            ROUTINGDATA.LAND + ROUTINGDATA.MAIN + ROUTINGDATA.POSTJOB ||
          currentUrl ===
            ROUTINGDATA.LAND + ROUTINGDATA.MAIN + ROUTINGDATA.POSTEDJOB ||
          currentUrl ===
            ROUTINGDATA.LAND + ROUTINGDATA.MAIN + ROUTINGDATA.APPLIEDSEEKERS ||
          currentUrl ===
            ROUTINGDATA.LAND + ROUTINGDATA.MAIN + ROUTINGDATA.SEEKERSAPPLIED ||
          currentUrl ===
            ROUTINGDATA.LAND + ROUTINGDATA.MAIN + ROUTINGDATA.AUDITING ||
          currentUrl ===
            ROUTINGDATA.LAND + ROUTINGDATA.MAIN + ROUTINGDATA.VIEWDATABASE ||
          currentUrl ===
            ROUTINGDATA.LAND +
              ROUTINGDATA.MAIN +
              ROUTINGDATA.BUYDATABASEACCESS ||
          currentUrl ===
            ROUTINGDATA.LAND +
              ROUTINGDATA.MAIN +
              ROUTINGDATA.DataBaseThanksCard ||
          currentUrl ===
            ROUTINGDATA.LAND + ROUTINGDATA.MAIN + ROUTINGDATA.SUBSCRIPTIONS
            ? localStorage.removeItem("user")
            : currentUrl ===
                ROUTINGDATA.LAND +
                  ROUTINGDATA.MAIN +
                  ROUTINGDATA.seekerProfile ||
              currentUrl ===
                ROUTINGDATA.LAND +
                  ROUTINGDATA.MAIN +
                  ROUTINGDATA.RecommendedJob ||
              currentUrl ===
                ROUTINGDATA.LAND + ROUTINGDATA.MAIN + ROUTINGDATA.AppliedJobs ||
              currentUrl ===
                ROUTINGDATA.LAND +
                  ROUTINGDATA.MAIN +
                  ROUTINGDATA.SEEKERNEEDSUPPORT ||
              currentUrl ===
                ROUTINGDATA.LAND + ROUTINGDATA.MAIN + ROUTINGDATA.BankDetails
            ? localStorage.removeItem("seeker")
            : currentUrl ===
                ROUTINGDATA.LAND +
                  ROUTINGDATA.MAIN +
                  ROUTINGDATA.MENTORABOUTUS ||
              currentUrl ===
                ROUTINGDATA.LAND + ROUTINGDATA.MAIN + ROUTINGDATA.MENTORUPDATE
            ? localStorage.removeItem("mentor")
            : localStorage.removeItem(null);
          handleMenuClose();
        }}
      >
        <span className="logout">Logout</span>
      </MenuItem>
    </Menu>
  );
  if (ready) {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="static"
          className="headerpart"
          sx={{ boxShadow: "0px 0px 8px 2px rgba(0, 94, 130,0.3)" }}
        >
          <Toolbar className="splite-content" disableGutters>
            <Typography component="div" className="logo-div-size">
              <div
                className={
                  currentUrl ===
                    ROUTINGDATA.LAND +
                      ROUTINGDATA.MAIN +
                      ROUTINGDATA.ThankYou ||
                  currentUrl ===
                    ROUTINGDATA.LAND +
                      ROUTINGDATA.MAIN +
                      ROUTINGDATA.PageNotFound ||
                  currentUrl ===
                    ROUTINGDATA.LAND +
                      ROUTINGDATA.MAIN +
                      ROUTINGDATA.Searchpage ||
                  ROUTINGDATA.LAND + ROUTINGDATA.MAIN + ROUTINGDATA.LOCALJOB ||
                  currentUrl ===
                    ROUTINGDATA.LAND +
                      ROUTINGDATA.MAIN +
                      ROUTINGDATA.LOCAL_SERVICES ||
                  currentUrl ===
                    ROUTINGDATA.LAND +
                      ROUTINGDATA.MAIN +
                      ROUTINGDATA.LOCAL_SERVICE_PROVIDER ||
                  currentUrl ===
                    ROUTINGDATA.LAND +
                      ROUTINGDATA.MAIN +
                      ROUTINGDATA.Searchpageforlocalservices
                    ? "headerlogoimg2"
                    : "headerlogoimg"
                }
              >
                <img
                  alt=""
                  style={{ cursor: "pointer" }}
                  src={logo}
                  onClick={() => navigate(ROUTINGDATA.LAND)}
                />
              </div>
            </Typography>
            {/* <Box sx={{ flexGrow: 1 }} /> */}
            {/* <Button
              className={
                lang === "tamil" ? "header-link-active" : "login-btn-header"
              }
              sx={{
                display:
                  currentUrl ===
                    ROUTINGDATA.LAND + ROUTINGDATA.MAIN + ROUTINGDATA.JOBSEEK ||
                  currentUrl ===
                    ROUTINGDATA.LAND +
                      ROUTINGDATA.MAIN +
                      ROUTINGDATA.JOBPROVIDER ||
                  currentUrl ===
                    ROUTINGDATA.LAND +
                      ROUTINGDATA.MAIN +
                      ROUTINGDATA.VOLUNTEER ||
                  currentUrl ===
                    ROUTINGDATA.LAND + ROUTINGDATA.MAIN + ROUTINGDATA.MENTOR ||
                  currentUrl === ROUTINGDATA.LAND ||
                  currentUrl ===
                    ROUTINGDATA.LAND +
                      ROUTINGDATA.MAIN +
                      ROUTINGDATA.LOCALJOB ||
                  currentUrl ===
                    ROUTINGDATA.LAND +
                      ROUTINGDATA.MAIN +
                      ROUTINGDATA.LOCAL_SERVICES ||
                  currentUrl ===
                    ROUTINGDATA.LAND +
                      ROUTINGDATA.MAIN +
                      ROUTINGDATA.LOCAL_SERVICE_PROVIDER
                    ? "block"
                    : "none",
                marginRight: "10px",
                color: "white",
                fontWeight: "600",
                fontSize: "14px",
                borderBottom: "3px solid transparent",
              }}
              size="small"
              onClick={() => {
                handleChangeLanguage("tamil");
                sessionStorage.setItem("lang", "tamil");
              }}
            >
              {t("Tamil")}
            </Button> */}

            {/* <Button
              className={
                lang === "en" ? "header-link-active" : "login-btn-header"
              }
              sx={{
                display:
                  currentUrl ===
                    ROUTINGDATA.LAND + ROUTINGDATA.MAIN + ROUTINGDATA.JOBSEEK ||
                  currentUrl ===
                    ROUTINGDATA.LAND +
                      ROUTINGDATA.MAIN +
                      ROUTINGDATA.JOBPROVIDER ||
                  currentUrl ===
                    ROUTINGDATA.LAND +
                      ROUTINGDATA.MAIN +
                      ROUTINGDATA.VOLUNTEER ||
                  currentUrl ===
                    ROUTINGDATA.LAND + ROUTINGDATA.MAIN + ROUTINGDATA.MENTOR ||
                  currentUrl === ROUTINGDATA.LAND ||
                  currentUrl ===
                    ROUTINGDATA.LAND +
                      ROUTINGDATA.MAIN +
                      ROUTINGDATA.LOCALJOB ||
                  currentUrl ===
                    ROUTINGDATA.LAND +
                      ROUTINGDATA.MAIN +
                      ROUTINGDATA.LOCAL_SERVICES ||
                  currentUrl ===
                    ROUTINGDATA.LAND +
                      ROUTINGDATA.MAIN +
                      ROUTINGDATA.LOCAL_SERVICE_PROVIDER
                    ? "block"
                    : "none",
                marginRight: "10px",
                color: "white",
                fontWeight: "600",
                fontSize: "14px",
                borderBottom: "3px solid transparent",
              }}
              onClick={() => {
                handleChangeLanguage("en");
                sessionStorage.setItem("lang", "en");
              }}
              size="small"
            >
              {t("English")}
            </Button> */}

            {/* <Box
              sx={{
                padding: " 0px 10px",
                marginRight: "5px",
                display: {
                  xs: "none",
                  md: `${
                    currentUrl === ROUTINGDATA.LAND ||
                    currentUrl === ROUTINGDATA.LAND + ROUTINGDATA.MAIN ||
                    currentUrl ===
                      ROUTINGDATA.LAND +
                        ROUTINGDATA.MAIN +
                        ROUTINGDATA.JOBSEEK ||
                    currentUrl ===
                      ROUTINGDATA.LAND +
                        ROUTINGDATA.MAIN +
                        ROUTINGDATA.JOBPROVIDER ||
                    currentUrl ===
                      ROUTINGDATA.LAND +
                        ROUTINGDATA.MAIN +
                        ROUTINGDATA.VOLUNTEER ||
                    currentUrl ===
                      ROUTINGDATA.LAND + ROUTINGDATA.MAIN + ROUTINGDATA.MENTOR
                      ? "block"
                      : "none"
                  }`,
                },
              }}
            >
              <p
                className="logintext"
                onClick={() =>
                  navigate(`${ROUTINGDATA.LAND}${ROUTINGDATA.LOGIN}`)
                }
              >
                {t("Login")}
              </p>
            </Box> */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
            >
              <Button
                className="btn-header dd"
                sx={{
                  display:
                    currentUrl ===
                      ROUTINGDATA.LAND +
                        ROUTINGDATA.MAIN +
                        ROUTINGDATA.JOBSEEK ||
                    currentUrl ===
                      ROUTINGDATA.LAND +
                        ROUTINGDATA.MAIN +
                        ROUTINGDATA.JOBPROVIDER ||
                    currentUrl ===
                      ROUTINGDATA.LAND +
                        ROUTINGDATA.MAIN +
                        ROUTINGDATA.VOLUNTEER ||
                    currentUrl ===
                      ROUTINGDATA.LAND +
                        ROUTINGDATA.MAIN +
                        ROUTINGDATA.MENTOR ||
                    currentUrl === ROUTINGDATA.LAND ||
                    currentUrl ===
                      ROUTINGDATA.LAND +
                        ROUTINGDATA.MAIN +
                        ROUTINGDATA.LOCALJOB ||
                    currentUrl ===
                      ROUTINGDATA.LAND +
                        ROUTINGDATA.MAIN +
                        ROUTINGDATA.LOCAL_SERVICES ||
                    currentUrl ===
                      ROUTINGDATA.LAND +
                        ROUTINGDATA.MAIN +
                        ROUTINGDATA.LOCAL_SERVICE_PROVIDER
                      ? ""
                      : "none",
                  marginRight: { xs: "10px" },
                }}
                onClick={(e) => {
                  handleClick(e);
                }}
              >
                {lag}
              </Button>
              <Box
                sx={{
                  marginLeft: "15px",
                  display: {
                    xs: "none",
                    md: `${
                      currentUrl === ROUTINGDATA.LAND ||
                      currentUrl === ROUTINGDATA.LAND + ROUTINGDATA.MAIN ||
                      currentUrl ===
                        ROUTINGDATA.LAND +
                          ROUTINGDATA.MAIN +
                          ROUTINGDATA.JOBSEEK ||
                      currentUrl ===
                        ROUTINGDATA.LAND +
                          ROUTINGDATA.MAIN +
                          ROUTINGDATA.JOBPROVIDER ||
                      currentUrl ===
                        ROUTINGDATA.LAND +
                          ROUTINGDATA.MAIN +
                          ROUTINGDATA.VOLUNTEER ||
                      currentUrl ===
                        ROUTINGDATA.LAND + ROUTINGDATA.MAIN + ROUTINGDATA.MENTOR
                        ? "block"
                        : "none"
                    }`,
                  },
                }}
              >
                <div className="login-content">
                  <p>
                    Already Registered?{" "}
                    <text
                      onClick={() =>
                        navigate(`${ROUTINGDATA.LAND}${ROUTINGDATA.LOGIN}`)
                      }
                    >
                      Login
                    </text>{" "}
                    here
                  </p>
                </div>
              </Box>
            </div>
            <Box
              sx={{
                display: {
                  xs: "none",
                  md: `${
                    currentUrl ===
                      ROUTINGDATA.LAND +
                        ROUTINGDATA.MAIN +
                        ROUTINGDATA.JOBSEEK ||
                    currentUrl ===
                      ROUTINGDATA.LAND +
                        ROUTINGDATA.MAIN +
                        ROUTINGDATA.JOBPROVIDER ||
                    currentUrl ===
                      ROUTINGDATA.LAND +
                        ROUTINGDATA.MAIN +
                        ROUTINGDATA.ThankYou ||
                    currentUrl ===
                      ROUTINGDATA.LAND +
                        ROUTINGDATA.MAIN +
                        ROUTINGDATA.ThankYouForLocal ||
                    currentUrl ===
                      ROUTINGDATA.LAND +
                        ROUTINGDATA.MAIN +
                        ROUTINGDATA.PageNotFound ||
                    currentUrl ===
                      ROUTINGDATA.LAND +
                        ROUTINGDATA.MAIN +
                        searchparams[0] +
                        `result` ||
                    searchcondtion ||
                    currentUrl ===
                      ROUTINGDATA.LAND +
                        ROUTINGDATA.MAIN +
                        ROUTINGDATA.VOLUNTEER ||
                    currentUrl ===
                      ROUTINGDATA.LAND +
                        ROUTINGDATA.MAIN +
                        ROUTINGDATA.MENTOR ||
                    currentUrl ===
                      ROUTINGDATA.LAND +
                        ROUTINGDATA.MAIN +
                        ROUTINGDATA.LOCALJOB ||
                    currentUrl ===
                      ROUTINGDATA.LAND +
                        ROUTINGDATA.MAIN +
                        ROUTINGDATA.LOCAL_SERVICES ||
                    currentUrl ===
                      ROUTINGDATA.LAND +
                        ROUTINGDATA.MAIN +
                        ROUTINGDATA.LOCAL_SERVICE_PROVIDER ||
                    currentUrl ===
                      ROUTINGDATA.LAND +
                        ROUTINGDATA.MAIN +
                        ROUTINGDATA.Searchpageforlocalservices ||
                    currentUrl ===
                      ROUTINGDATA.LAND +
                        ROUTINGDATA.MAIN +
                        ROUTINGDATA.MENTORProfile ||
                    currentUrl ===
                      ROUTINGDATA.LAND +
                        ROUTINGDATA.MAIN +
                        ROUTINGDATA.ADVERTISEMENT
                      ? "none"
                      : "block"
                  }`,
                },
              }}
            >
              <div style={{ display: "flex" }}>
                <Typography sx={{ marginTop: 1.4, marginLeft: 0.5 }}>
                  {currentUrl ===
                    ROUTINGDATA.LAND +
                      ROUTINGDATA.MAIN +
                      ROUTINGDATA.seekerProfile ||
                  currentUrl ===
                    ROUTINGDATA.LAND +
                      ROUTINGDATA.MAIN +
                      ROUTINGDATA.AppliedJobs ||
                  currentUrl ===
                    ROUTINGDATA.LAND +
                      ROUTINGDATA.MAIN +
                      ROUTINGDATA.RecommendedJob ||
                  currentUrl ===
                    ROUTINGDATA.LAND +
                      ROUTINGDATA.MAIN +
                      ROUTINGDATA.SEEKERNEEDSUPPORT ||
                  currentUrl ===
                    ROUTINGDATA.LAND +
                      ROUTINGDATA.MAIN +
                      ROUTINGDATA.BankDetails
                    ? emailName2
                    : currentUrl ===
                        ROUTINGDATA.LAND +
                          ROUTINGDATA.MAIN +
                          ROUTINGDATA.EMPLOYERUPDATE ||
                      currentUrl ===
                        ROUTINGDATA.LAND +
                          ROUTINGDATA.MAIN +
                          ROUTINGDATA.APPLIEDSEEKERS ||
                      currentUrl ===
                        ROUTINGDATA.LAND +
                          ROUTINGDATA.MAIN +
                          ROUTINGDATA.POSTEDJOB ||
                      currentUrl ===
                        ROUTINGDATA.LAND +
                          ROUTINGDATA.MAIN +
                          ROUTINGDATA.POSTJOB ||
                      currentUrl ===
                        ROUTINGDATA.LAND +
                          ROUTINGDATA.MAIN +
                          ROUTINGDATA.SEEKERSAPPLIED ||
                      currentUrl ===
                        ROUTINGDATA.LAND +
                          ROUTINGDATA.MAIN +
                          ROUTINGDATA.AUDITING ||
                      currentUrl ===
                        ROUTINGDATA.LAND +
                          ROUTINGDATA.MAIN +
                          ROUTINGDATA.VIEWDATABASE ||
                      currentUrl ===
                        ROUTINGDATA.LAND +
                          ROUTINGDATA.MAIN +
                          ROUTINGDATA.BUYDATABASEACCESS ||
                      currentUrl ===
                        ROUTINGDATA.LAND +
                          ROUTINGDATA.MAIN +
                          ROUTINGDATA.DataBaseThanksCard ||
                      currentUrl ===
                        ROUTINGDATA.LAND +
                          ROUTINGDATA.MAIN +
                          ROUTINGDATA.SUBSCRIPTIONS
                    ? emailName
                    : currentUrl ===
                        ROUTINGDATA.LAND +
                          ROUTINGDATA.MAIN +
                          ROUTINGDATA.MENTORABOUTUS ||
                      currentUrl ===
                        ROUTINGDATA.LAND +
                          ROUTINGDATA.MAIN +
                          ROUTINGDATA.MENTORUPDATE
                    ? emailNamementor
                    : null}
                </Typography>
                <div>
                  <Tooltip
                    title={
                      <div>
                        <span
                          style={{
                            fontWeight: "500",
                            fontSize: "12px",
                            textTransform: "uppercase",
                            letterSpacing: "2px",
                          }}
                        >
                          VelaiVendum account
                        </span>
                        <p
                          style={{
                            margin: "5px 0px",
                            fontSize: "13px",
                            textTransform: "uppercase",
                          }}
                        >
                          {currentUrl ===
                            ROUTINGDATA.LAND +
                              ROUTINGDATA.MAIN +
                              ROUTINGDATA.seekerProfile ||
                          currentUrl ===
                            ROUTINGDATA.LAND +
                              ROUTINGDATA.MAIN +
                              ROUTINGDATA.AppliedJobs ||
                          currentUrl ===
                            ROUTINGDATA.LAND +
                              ROUTINGDATA.MAIN +
                              ROUTINGDATA.RecommendedJob ||
                          currentUrl ===
                            ROUTINGDATA.LAND +
                              ROUTINGDATA.MAIN +
                              ROUTINGDATA.SEEKERNEEDSUPPORT ||
                          currentUrl ===
                            ROUTINGDATA.LAND +
                              ROUTINGDATA.MAIN +
                              ROUTINGDATA.BankDetails
                            ? fullName2
                            : currentUrl ===
                                ROUTINGDATA.LAND +
                                  ROUTINGDATA.MAIN +
                                  ROUTINGDATA.EMPLOYERUPDATE ||
                              currentUrl ===
                                ROUTINGDATA.LAND +
                                  ROUTINGDATA.MAIN +
                                  ROUTINGDATA.APPLIEDSEEKERS ||
                              currentUrl ===
                                ROUTINGDATA.LAND +
                                  ROUTINGDATA.MAIN +
                                  ROUTINGDATA.POSTEDJOB ||
                              currentUrl ===
                                ROUTINGDATA.LAND +
                                  ROUTINGDATA.MAIN +
                                  ROUTINGDATA.POSTJOB ||
                              currentUrl ===
                                ROUTINGDATA.LAND +
                                  ROUTINGDATA.MAIN +
                                  ROUTINGDATA.SEEKERSAPPLIED ||
                              currentUrl ===
                                ROUTINGDATA.LAND +
                                  ROUTINGDATA.MAIN +
                                  ROUTINGDATA.VIEWDATABASE ||
                              currentUrl ===
                                ROUTINGDATA.LAND +
                                  ROUTINGDATA.MAIN +
                                  ROUTINGDATA.BUYDATABASEACCESS ||
                              currentUrl ===
                                ROUTINGDATA.LAND +
                                  ROUTINGDATA.MAIN +
                                  ROUTINGDATA.DataBaseThanksCard ||
                              currentUrl ===
                                ROUTINGDATA.LAND +
                                  ROUTINGDATA.MAIN +
                                  ROUTINGDATA.AUDITING ||
                              currentUrl ===
                                ROUTINGDATA.LAND +
                                  ROUTINGDATA.MAIN +
                                  ROUTINGDATA.SUBSCRIPTIONS
                            ? fullName
                            : currentUrl ===
                                ROUTINGDATA.LAND +
                                  ROUTINGDATA.MAIN +
                                  ROUTINGDATA.MENTORABOUTUS ||
                              currentUrl ===
                                ROUTINGDATA.LAND +
                                  ROUTINGDATA.MAIN +
                                  ROUTINGDATA.MENTORUPDATE
                            ? fullNamementor
                            : null}
                        </p>
                        <p style={{ fontSize: "12px", margin: "0" }}>
                          {currentUrl ===
                            ROUTINGDATA.LAND +
                              ROUTINGDATA.MAIN +
                              ROUTINGDATA.seekerProfile ||
                          currentUrl ===
                            ROUTINGDATA.LAND +
                              ROUTINGDATA.MAIN +
                              ROUTINGDATA.AppliedJobs ||
                          currentUrl ===
                            ROUTINGDATA.LAND +
                              ROUTINGDATA.MAIN +
                              ROUTINGDATA.RecommendedJob ||
                          currentUrl ===
                            ROUTINGDATA.LAND +
                              ROUTINGDATA.MAIN +
                              ROUTINGDATA.SEEKERNEEDSUPPORT ||
                          currentUrl ===
                            ROUTINGDATA.LAND +
                              ROUTINGDATA.MAIN +
                              ROUTINGDATA.BankDetails
                            ? emailName2
                            : currentUrl ===
                                ROUTINGDATA.LAND +
                                  ROUTINGDATA.MAIN +
                                  ROUTINGDATA.EMPLOYERUPDATE ||
                              currentUrl ===
                                ROUTINGDATA.LAND +
                                  ROUTINGDATA.MAIN +
                                  ROUTINGDATA.APPLIEDSEEKERS ||
                              currentUrl ===
                                ROUTINGDATA.LAND +
                                  ROUTINGDATA.MAIN +
                                  ROUTINGDATA.POSTEDJOB ||
                              currentUrl ===
                                ROUTINGDATA.LAND +
                                  ROUTINGDATA.MAIN +
                                  ROUTINGDATA.POSTJOB ||
                              currentUrl ===
                                ROUTINGDATA.LAND +
                                  ROUTINGDATA.MAIN +
                                  ROUTINGDATA.VIEWDATABASE ||
                              currentUrl ===
                                ROUTINGDATA.LAND +
                                  ROUTINGDATA.MAIN +
                                  ROUTINGDATA.BUYDATABASEACCESS ||
                              currentUrl ===
                                ROUTINGDATA.LAND +
                                  ROUTINGDATA.MAIN +
                                  ROUTINGDATA.DataBaseThanksCard ||
                              currentUrl ===
                                ROUTINGDATA.LAND +
                                  ROUTINGDATA.MAIN +
                                  ROUTINGDATA.SEEKERSAPPLIED
                            ? emailName
                            : currentUrl ===
                                ROUTINGDATA.LAND +
                                  ROUTINGDATA.MAIN +
                                  ROUTINGDATA.MENTORABOUTUS ||
                              currentUrl ===
                                ROUTINGDATA.LAND +
                                  ROUTINGDATA.MAIN +
                                  ROUTINGDATA.MENTORUPDATE ||
                              currentUrl ===
                                ROUTINGDATA.LAND +
                                  ROUTINGDATA.MAIN +
                                  ROUTINGDATA.AUDITING ||
                              currentUrl ===
                                ROUTINGDATA.LAND +
                                  ROUTINGDATA.MAIN +
                                  ROUTINGDATA.SUBSCRIPTIONS
                            ? emailNamementor
                            : null}
                        </p>
                      </div>
                    }
                    placement="bottom-end"
                  >
                    <IconButton
                      size="large"
                      edge="end"
                      aria-label="account of current user"
                      aria-controls={menuId}
                      aria-haspopup="true"
                      onClick={handleProfileMenuOpen}
                      color="inherit"
                    >
                      <AccountCircle />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            </Box>
            <Box
              sx={{
                display: {
                  xs: `${
                    currentUrl ===
                      ROUTINGDATA.LAND +
                        ROUTINGDATA.MAIN +
                        ROUTINGDATA.ThankYou ||
                    currentUrl ===
                      ROUTINGDATA.LAND +
                        ROUTINGDATA.MAIN +
                        ROUTINGDATA.ThankYouForLocal ||
                    currentUrl ===
                      ROUTINGDATA.LAND +
                        ROUTINGDATA.MAIN +
                        ROUTINGDATA.PageNotFound ||
                    currentUrl ===
                      ROUTINGDATA.LAND +
                        ROUTINGDATA.MAIN +
                        ROUTINGDATA.Searchpage ||
                    currentUrl ===
                      ROUTINGDATA.LAND +
                        ROUTINGDATA.MAIN +
                        ROUTINGDATA.LOCALJOB ||
                    currentUrl ===
                      ROUTINGDATA.LAND +
                        ROUTINGDATA.MAIN +
                        ROUTINGDATA.LOCAL_SERVICES ||
                    currentUrl ===
                      ROUTINGDATA.LAND +
                        ROUTINGDATA.MAIN +
                        ROUTINGDATA.LOCAL_SERVICE_PROVIDER ||
                    currentUrl ===
                      ROUTINGDATA.LAND +
                        ROUTINGDATA.MAIN +
                        ROUTINGDATA.Searchpageforlocalservices ||
                    currentUrl ===
                      ROUTINGDATA.LAND +
                        ROUTINGDATA.MAIN +
                        ROUTINGDATA.MENTORProfile ||
                    currentUrl ===
                      ROUTINGDATA.LAND +
                        ROUTINGDATA.MAIN +
                        ROUTINGDATA.ADVERTISEMENT
                      ? "none"
                      : "block"
                  }`,
                  md: "none",
                },
              }}
            >
              <div style={{ display: "flex" }}>
                <Typography
                  sx={{
                    marginTop: 1.9,
                    marginLeft: 0.5,
                    marginRight: -2,
                    fontSize: "12px",
                  }}
                >
                  {currentUrl ===
                    ROUTINGDATA.LAND +
                      ROUTINGDATA.MAIN +
                      ROUTINGDATA.seekerProfile ||
                  currentUrl ===
                    ROUTINGDATA.LAND +
                      ROUTINGDATA.MAIN +
                      ROUTINGDATA.AppliedJobs ||
                  currentUrl ===
                    ROUTINGDATA.LAND +
                      ROUTINGDATA.MAIN +
                      ROUTINGDATA.RecommendedJob ||
                  currentUrl ===
                    ROUTINGDATA.LAND +
                      ROUTINGDATA.MAIN +
                      ROUTINGDATA.SEEKERNEEDSUPPORT ||
                  currentUrl ===
                    ROUTINGDATA.LAND +
                      ROUTINGDATA.MAIN +
                      ROUTINGDATA.BankDetails
                    ? emailName2
                    : currentUrl ===
                        ROUTINGDATA.LAND +
                          ROUTINGDATA.MAIN +
                          ROUTINGDATA.EMPLOYERUPDATE ||
                      currentUrl ===
                        ROUTINGDATA.LAND +
                          ROUTINGDATA.MAIN +
                          ROUTINGDATA.APPLIEDSEEKERS ||
                      currentUrl ===
                        ROUTINGDATA.LAND +
                          ROUTINGDATA.MAIN +
                          ROUTINGDATA.POSTEDJOB ||
                      currentUrl ===
                        ROUTINGDATA.LAND +
                          ROUTINGDATA.MAIN +
                          ROUTINGDATA.POSTJOB ||
                      currentUrl ===
                        ROUTINGDATA.LAND +
                          ROUTINGDATA.MAIN +
                          ROUTINGDATA.VIEWDATABASE ||
                      currentUrl ===
                        ROUTINGDATA.LAND +
                          ROUTINGDATA.MAIN +
                          ROUTINGDATA.BUYDATABASEACCESS ||
                      currentUrl ===
                        ROUTINGDATA.LAND +
                          ROUTINGDATA.MAIN +
                          ROUTINGDATA.DataBaseThanksCard ||
                      currentUrl ===
                        ROUTINGDATA.LAND +
                          ROUTINGDATA.MAIN +
                          ROUTINGDATA.SEEKERSAPPLIED
                    ? emailName
                    : currentUrl ===
                        ROUTINGDATA.LAND +
                          ROUTINGDATA.MAIN +
                          ROUTINGDATA.MENTORABOUTUS ||
                      currentUrl ===
                        ROUTINGDATA.LAND +
                          ROUTINGDATA.MAIN +
                          ROUTINGDATA.MENTORUPDATE ||
                      currentUrl ===
                        ROUTINGDATA.LAND +
                          ROUTINGDATA.MAIN +
                          ROUTINGDATA.AUDITING ||
                      currentUrl ===
                        ROUTINGDATA.LAND +
                          ROUTINGDATA.MAIN +
                          ROUTINGDATA.SUBSCRIPTIONS
                    ? emailNamementor
                    : null}
                </Typography>
                <IconButton
                  size="large"
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                  color="inherit"
                >
                  <MoreIcon />
                </IconButton>
              </div>
            </Box>
          </Toolbar>
        </AppBar>
        {renderMobileMenu}
        {renderMenu}
      </Box>
    );
  } else {
    return (
      <div
        style={{
          height: "70vh",
          display: "grid",
          placeItems: "center",
          // transform: "translate(0,50vh)",
        }}
      >
        <div class="dots"></div>
      </div>
    );
  }
}
