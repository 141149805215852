import React, { useState, useEffect, useSyncExternalStore } from "react";
import {
  Table,
  TableContainer,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  Divider,
  Card,
  IconButton,
  Tooltip,
  TablePagination,
  Typography,
  Paper,
  Chip,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import useAxios from "../../../../api/useAxios";

import {
  OpenInNewRounded as OpenInNewRoundedIcon,
  PostAdd as PostAddIcon,
} from "@mui/icons-material/";

import { useLocation, useNavigate } from "react-router-dom";
import { ROUTINGDATA } from "../../../ROUTINGDATA";

import ApproveDialog from "../../../admin-panel/admin-components/DialogBoxes/ApproveDialog";
import ExportCS from "../../../admin-panel/admin-components/ExportComponents/ExportCS";

function VolunteerNeedSupport() {
  const [getApi, setGetApi] = useState([]);
  const [useApi, setUseApi] = useState([]);
  const [familyApi, setFamilyApi] = useState([]);
  const [approveMail, setApproveMail] = useState("");
  const [approveOpen, setApproveOpen] = useState(false);
  const [isLoadingPage, setIsLoadingPage] = useState(false);

  const [page, setPage] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [getOne, setGetOne] = useState({});
  // <!----SORt--->
  const [sortedData, setSortedData] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [verifierApi, setVerifierApi] = useState([]);

  const [volunteerDistrict, setVolunteerDistrict] = useState({});
  const navigate = useNavigate();
  const location = useLocation();

  const pageNumbe = sessionStorage.getItem("page_number");
  const pageNumber = parseInt(pageNumbe);
  const volunteerRadioValue = location.state?.volunteerRadioValue;
  const shortlisted = sessionStorage.getItem("volunteer_short");
  const filterRadio = sessionStorage.getItem("volunteer_radio");
  const industry = sessionStorage.getItem("volunteerindustry");
  const district = sessionStorage.getItem("volunteerdistrict");
  const verifierVolunteer = JSON.parse(localStorage.getItem("volunteer"));

  const vol = `Volunteer_${volunteerDistrict.email_id}`;

  const openApproveDialog = () => {
    setApproveOpen(true);
  };
  const axiosData = useAxios();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // <!----GET--->

  // <!-----API FUNCTIONS---->

  //<.. needsupport  radio button Session valule..>
  const shortlist_session_value =
    shortlisted === (district === null ? "" : district) ||
    shortlisted === (industry === null ? "" : industry) ||
    shortlisted === "ByMe" ||
    shortlisted === "Volunteer" ||
    shortlisted === "Pending"
      ? shortlisted
      : false;
  //<.. needsupport  radio button session valule..>
  const typesof_session_value =
    filterRadio === "Accommodation" ||
    filterRadio === "Training" ||
    filterRadio === "all" ||
    filterRadio === "Course Fees" ||
    filterRadio === "Mentorship" ||
    filterRadio === "Others"
      ? filterRadio
      : false;
  //<..check two session value of needsupport and typeofsupport..>
  const totalsessionfiltervalue =
    shortlist_session_value === false
      ? typesof_session_value === false
        ? "all"
        : typesof_session_value
      : shortlist_session_value;
  //<.. typesof and needsupport  radio button State valule..>
  const shortlist_state_value =
    volunteerRadioValue === "all" || volunteerRadioValue === "Pending"
      ? volunteerRadioValue
      : false;
  //<..check  session and state value ..>
  const totalfiltervalue =
    shortlist_session_value === false && typesof_session_value === false
      ? shortlist_state_value === false
        ? totalsessionfiltervalue
        : shortlist_state_value
      : totalsessionfiltervalue;
  const fetchFamilyApi = async () => {
    try {
      const axiosFamilyData = await axiosData.get("family/needsupport");
      const { data } = axiosFamilyData;
      const reverseData = data.reverse();
      const finalVerifyComplete = reverseData.filter(
        (item) => item.finalVerification === "Completed"
      );

      if (
        totalfiltervalue === (district === null ? "" : district) ||
        totalfiltervalue === (industry === null ? "" : industry) ||
        totalfiltervalue === "Volunteer" ||
        totalfiltervalue === "Pending" ||
        totalfiltervalue === "ByMe"
      ) {
        shortlistBy(totalfiltervalue, finalVerifyComplete);
      } else {
        filterFunction(totalfiltervalue, finalVerifyComplete);
      }
      setGetApi(finalVerifyComplete);
    } catch (err) {
      console.log("Error", err);
    }
  };
  const filterFunction = (value, alldata) => {
    if (value === "all") {
      const all = [...alldata];
      setFamilyApi(all);
      setTotalRows(all.length);
    } else if (value === "Accommodation") {
      const accomodation = alldata.filter((item) => {
        return item.TypeOfSupport === "Accommodation";
      });
      setFamilyApi(accomodation);
      setTotalRows(accomodation.length);
    } else if (value === "Mentorship") {
      const mentorship = alldata.filter((item) => {
        return item.TypeOfSupport === "Mentorship";
      });
      setFamilyApi(mentorship);
      setTotalRows(mentorship.length);
    } else if (value === "Training") {
      const training = alldata.filter((item) => {
        return item.TypeOfSupport === "Training";
      });
      setFamilyApi(training);
      setTotalRows(training.length);
    } else if (value === "Course Fees") {
      const courseFees = alldata.filter((item) => {
        return item.TypeOfSupport === "Course Fees";
      });
      setFamilyApi(courseFees);
      setTotalRows(courseFees.length);
    } else if (value === "Others") {
      const others = alldata.filter((item) => {
        return item.TypeOfSupport === "Others";
      });
      setFamilyApi(others);
      setTotalRows(others.length);
    }
  };
  const shortlistBy = (shortvalue, total) => {
    const voldistrict = volunteerDistrict.district
      ? volunteerDistrict.district
      : district;
    const volindustry = industry ? industry : volunteerDistrict.industry;
    if (shortvalue === voldistrict) {
      const district = total.filter((item) => {
        return item.votingDistrict === voldistrict;
      });
      setFamilyApi(district);
      setTotalRows(district.length);
    } else if (shortvalue === volindustry) {
      const district = total.filter((item) => {
        return item.industry === volindustry;
      });
      setFamilyApi(district);
      setTotalRows(district.length);
    } else if (shortvalue === "Volunteer") {
      const youvolunteer = total.filter(
        (d) => d.shortList !== `Volunteer_${verifierVolunteer}`
      );
      const volun = youvolunteer.filter((d) => {
        const dhiv = d.shortList.split("_")[0];
        if (dhiv === "Volunteer") {
          return dhiv;
        }
      });
      const fill = total.filter((d) => d.shortList === vol);
      setFamilyApi(volun);
      setTotalRows(volun.length);
    } else if (shortvalue === "Pending") {
      const fill = total.filter((d) => d.shortList === "");
      setFamilyApi(fill);
      setTotalRows(fill.length);
    } else if (shortvalue === "ByMe") {
      const youvolunteer = total.filter(
        (d) => d.shortList === `Volunteer_${verifierVolunteer}`
      );
      setFamilyApi(youvolunteer);
      setTotalRows(youvolunteer.length);
    }
  };
  const volunteerFamilyData = [...familyApi].filter((i) => i.shortList === vol);
  const selectedApiForExcel = familyApi.map((i) => {
    return {
      "Full Name": i.Name,
      Email: i.email_id,
      Mobile: i.mobile_number,
      "Types Of Support": i.TypeOfSupport,
      "Approx Amount": i.amount,

      "Voting District": i.votingDistrict,
      "MP Constituency": i.mpConstituency,
      "MLA Constituency": i.mlaConstituency,
      "Created Date": i.createdDate,
    };
  });
  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    const sortedData = useApi.sort((a, b) => {
      const valueA = a[property];
      const valueB = b[property];

      if (valueA === valueB) {
        return -1;
      }

      if (valueA < valueB) {
        return isAsc ? -1 : 1;
      }
      if (valueA > valueB) {
        return isAsc ? 1 : -1;
      }
      return 0;
    });
    setSortedData(sortedData);
  };

  const finalverifier = (data, alldata) => {
    const value = data ? data : "Pending";
    if (
      value === "Pending" ||
      value === "Completed" ||
      value === "Hold" ||
      value === "Declined"
    ) {
      const fill = alldata.filter(
        (d) =>
          d.finalVerification === value && d.fieldVerification === "Completed"
      );
      setFamilyApi(fill);
      setTotalRows(fill.length);
      setPage(0);
    }
  };

  const updateAdminVerify = async (email_id, decide, notes) => {
    setIsLoadingPage(true);
    try {
      await axiosData.put(
        `/seeker/email/${email_id}`,
        { notes: notes },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      await axiosData.put(`family/admin/${email_id}`, {
        shortList: decide,
        shortlistNotes: notes,
      });
      fetchFamilyApi();
      setApproveOpen(false);
    } catch (err) {
      console.log("Error", err);
    }
    setIsLoadingPage(false);
  };
  useEffect(() => {
    const getVolunteerByEmail = async () => {
      const verifierVolunteer = JSON.parse(localStorage.getItem("volunteer"));

      try {
        const useAxiosData = await axiosData.get(
          `volunteer/email/${verifierVolunteer}`
        );
        const { data } = useAxiosData;
        sessionStorage.setItem("volunteerindustry", data.industry);
        sessionStorage.setItem("volunteerdistrict", data.district);
        setVolunteerDistrict(data);
      } catch (err) {
        console.log("Error", err);
      }
    };

    getVolunteerByEmail();
    fetchFamilyApi();

    handleSort(orderBy);
    setPage(pageNumber ? pageNumber : 0);
    sessionStorage.removeItem("page_number");
  }, [location.state]);

  return (
    <>
      <Divider textAlign="left">
        <span className="seeker-text">Need Support Data</span>
      </Divider>

      <Card sx={{ padding: "20px" }}>
        <Paper>
          <Box
            sx={{
              padding: "20px",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              backgroundColor: "#f2f2f2",
            }}
          >
            <Box>
              <FormControl>
                <RadioGroup
                  row
                  id="radio-btn"
                  value={totalfiltervalue}
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  onChange={(e) => {
                    sessionStorage.setItem("volunteer_radio", e.target.value);
                    sessionStorage.setItem("volunteer_short", null);
                    filterFunction(e.target.value, getApi);
                    setPage(0);
                  }}
                >
                  <FormControlLabel
                    name="usertype"
                    id="all"
                    control={<Radio value={"all"} />}
                    label="All"
                  />
                  <FormControlLabel
                    name="usertype"
                    control={<Radio value={"Training"} />}
                    label="Training"
                  ></FormControlLabel>
                  <FormControlLabel
                    name="usertype"
                    control={<Radio value={"Accommodation"} />}
                    label="Accommodation"
                  />
                  <FormControlLabel
                    name="usertype"
                    control={<Radio value={"Course Fees"} />}
                    label="Course Fees"
                  />
                  <FormControlLabel
                    name="usertype"
                    control={<Radio value={"Mentorship"} />}
                    label="Mentorship"
                  />
                  <FormControlLabel
                    name="usertype"
                    control={<Radio value={"Others"} />}
                    label="Others"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            <Box sx={{ display: "flex" }}>
              <FormControl sx={{ flexGrow: "1" }}>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Filter By ShortList/Industry/District
                </FormLabel>
                <RadioGroup
                  row
                  id="radio-btn"
                  value={totalfiltervalue}
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  sx={{ marginRight: "18px" }}
                  onChange={(e) => {
                    sessionStorage.setItem(`volunteer_short`, e.target.value);
                    sessionStorage.setItem("volunteer_radio", null);
                    shortlistBy(e.target.value, getApi);
                    setPage(0);
                  }}
                >
                  <FormControlLabel
                    name="usertype"
                    control={<Radio value="Pending" />}
                    label="Pending"
                  ></FormControlLabel>

                  <FormControlLabel
                    name="usertype"
                    control={<Radio value={"Volunteer"} />}
                    label="Completed by Other Volunteer"
                  />
                  <FormControlLabel
                    name="usertype"
                    control={<Radio value={"ByMe"} />}
                    label="Completed by You"
                  />
                  <FormControlLabel
                    name="usertype"
                    control={<Radio value={volunteerDistrict.district} />}
                    label={volunteerDistrict.district}
                  />
                  <FormControlLabel
                    name="usertype"
                    control={<Radio value={volunteerDistrict.industry} />}
                    label={volunteerDistrict.industry}
                  />
                </RadioGroup>
              </FormControl>
              <Box>
                <Tooltip title="Export As Excel" placement="bottom">
                  <ExportCS
                    selectedApiForExcel={selectedApiForExcel}
                    fileName={"Seeker-NeedSupport-Data"}
                  />
                </Tooltip>
              </Box>
            </Box>
          </Box>
        </Paper>

        <TableContainer
          component={Paper}
          sx={{ marginTop: "10px", boxShadow: 0 }}
        >
          <Table>
            <TableHead>
              <TableRow className="table-row">
                <TableCell>Name</TableCell>
                <TableCell>Email </TableCell>

                <TableCell>Family Income</TableCell>
                <TableCell>ShortList Status</TableCell>

                <TableCell> Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {familyApi.length > 0 ? (
                <>
                  {familyApi
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item) => {
                      let shortlistStat =
                        item.shortList === ""
                          ? "pending"
                          : item.shortList === "MP"
                          ? "Completed By MP"
                          : item.shortList === "MLA"
                          ? "Completed By MLA"
                          : item.shortList === vol
                          ? "Completed By You"
                          : "Completed By Other Volunteer";
                      return (
                        <TableRow key={item._id} hover className="tablecell">
                          <TableCell>{item.Name}</TableCell>
                          <TableCell>{item.email_id}</TableCell>

                          <TableCell>{item.FamilyIncome}</TableCell>
                          <TableCell>
                            <Chip
                              label={shortlistStat}
                              variant="filled"
                              color={
                                item.shortList === ""
                                  ? "warning"
                                  : item.shortList === "MLA" ||
                                    item.shortList === "MP"
                                  ? "info"
                                  : item.shortList === vol
                                  ? "success"
                                  : "success"
                              }
                              sx={{
                                color: "#ffffff",
                              }}
                            />
                          </TableCell>

                          <TableCell>
                            <Tooltip title="Full Details" placement="top">
                              <IconButton
                                className="icon-btn"
                                size="Medium"
                                onClick={() => {
                                  navigate(
                                    ROUTINGDATA.LAND +
                                      ROUTINGDATA.volunteerPanel +
                                      "/" +
                                      ROUTINGDATA.volunteerFullDetails,
                                    {
                                      state: item.email_id,
                                    }
                                  );
                                  sessionStorage.setItem("page_number", page);
                                }}
                              >
                                <OpenInNewRoundedIcon />
                              </IconButton>
                            </Tooltip>

                            {item.shortList === "" ? (
                              <Tooltip>
                                <Button
                                  startIcon={<PostAddIcon />}
                                  variant="contained"
                                  sx={{
                                    textTransform: "capitalize ",
                                    boxShadow: 0,
                                    borderRadius: "16px",
                                  }}
                                  onClick={() => {
                                    setApproveMail(item.email_id);
                                    openApproveDialog();
                                  }}
                                >
                                  Shortlist
                                </Button>
                              </Tooltip>
                            ) : null}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </>
              ) : (
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>

                  <TableCell>
                    <Typography
                      variant="body1"
                      sx={{ fontFamily: "Barlow-Bold" }}
                    >
                      NO Data Available
                    </Typography>
                  </TableCell>

                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25]}
          component="div"
          count={totalRows}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>

      <ApproveDialog
        userEmail={approveMail}
        approveOpen={approveOpen}
        setApproveOpen={setApproveOpen}
        mlaEmail={approveMail}
        isLoading={isLoadingPage}
        MLA={vol}
        updateAdminVerify={updateAdminVerify}
      />
    </>
  );
}

export default VolunteerNeedSupport;
