import React, { useEffect, useState } from "react";
import {
  Table,
  TableContainer,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  Box,
  Card,
  Typography,
  IconButton,
  Tooltip,
  TablePagination,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  FormControl,
  Grid,
  Paper,
  Skeleton,
  TextField,
  Chip,
  Button,
} from "@mui/material";
import {
  OpenInNewRounded as OpenInNewRoundedIcon,
  CloseRounded as CloseRoundedIcon,
  PostAdd as PostAddIcon,
  FavoriteRounded as FavoriteRoundedIcon,
} from "@mui/icons-material";
import defaultimg from "../../../../img/mentorImage.png";
import { useLocation, useNavigate } from "react-router-dom";
import useAxios from "../../../../api/useAxios";
import { ROUTINGDATA } from "../../../ROUTINGDATA";
import StatMLAcards from "../../../admin-panel/admin-components/StatCards/StatMLAcards";
import { utils, writeFile } from "xlsx";
import moment from "moment";
import SkeletonSeekCard from "../../../admin-panel/admin-components/StatCards/SkeletonSeekCard";
import ApproveDialog from "../../../admin-panel/admin-components/DialogBoxes/ApproveDialog";
import ExportCS from "../../../admin-panel/admin-components/ExportComponents/ExportCS";
import EnterContactSupportDialog from "../../../admin-panel/admin-components/DialogBoxes/EnterContactSupportDialog";
import ContactSupportSnack from "../../../admin-panel/admin-components/SnackBars/ContactSupportSnack";
function MLAneedSupport() {
  const location = useLocation();
  const currentUrl = location.pathname;
  const shortRoute =
    ROUTINGDATA.LAND +
    ROUTINGDATA.MLAconstituency +
    "/" +
    ROUTINGDATA.MLAShortlisted;
  const navigate = useNavigate();
  const axiosData = useAxios();
  const baseUrl = axiosData.defaults.baseURL;
  const [MLAneed, setMLAneed] = useState([]);
  const [allData, setAllData] = useState([]);
  const [openFormikContactDialog, setOpenFormikContactDialog] = useState(false);
  const [contactSeekDetails, setContactSeekDetails] = useState({
    name: "",
    email_id: "",
    contactName: "",
    mobile: 0,
  });
  const [openSupportSnack, setOpenSupportSnack] = useState(false);
  const [approveOpen, setApproveOpen] = useState(false);
  const [page, setPage] = useState(0);

  const [totalRows, setTotalRows] = useState(0);
  const [totalRowsShort, setTotalRowshort] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openContactDialog, setOpenContactDialog] = useState(false);
  const [searchdata, setsearchdata] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingPage, setIsLoadingPage] = useState(false);
  const [searchicon, setsearchicon] = useState(false);
  const [MLAEmail, setMLAEmail] = useState("");
  const [shortlistedData, setShortListedData] = useState([]);
  const MLAPageNumbe = sessionStorage.getItem("mla_page_number");
  const MLAShortPageNumbe = sessionStorage.getItem("mla_short_page_number");
  const MLAPageNumber = parseInt(MLAPageNumbe);
  const MLAShortPageNumber = parseInt(MLAShortPageNumbe);
  const setMLAPageNumber = MLAPageNumber ? MLAPageNumber : 0;
  const setMLAShortPageNumber =
    MLAShortPageNumber >= 0 ? MLAShortPageNumber : 0;
  const [getapi, setgetapi] = useState([]);
  //mla support session
  const mlasupport = sessionStorage.getItem(`mla_supportradio`);
  //mla shortlist session and state
  const mla = JSON.parse(localStorage.getItem("mla"));
  const mlaNeedSearch = sessionStorage.getItem("mla_need_search");
  const needSupportstateValue = location.state?.needSupportFilterValue;
  const typeofSupportstateValue = location.state?.typesOfSupportFilterValue;
  const shortlisted = sessionStorage.getItem(`shortradio`);
  const typeofsupportvalue = sessionStorage.getItem("typeofsupport");

  const openApproveDialog = () => {
    setApproveOpen(true);
  };

  //<.. needsupport  radio button Session valule..>
  const shortlist_session_value =
    shortlisted === "MP" ||
    shortlisted === "MLA" ||
    shortlisted === "Volunteer" ||
    shortlisted === "Pending"
      ? shortlisted
      : false;
  //<.. typesof  radio button Session valule..>
  const typesof_session_value =
    typeofsupportvalue === "Accommodation" ||
    typeofsupportvalue === "Training" ||
    typeofsupportvalue === "all" ||
    typeofsupportvalue === "Course Fees" ||
    typeofsupportvalue === "Mentorship" ||
    typeofsupportvalue === "Others"
      ? typeofsupportvalue
      : false;
  //<.. type of and  needsupport  radio button State valule..>

  const shortlist_state_value =
    needSupportstateValue === "all" || needSupportstateValue === "Pending"
      ? needSupportstateValue
      : false;
  //<.. type of and  typeofstupport  radio button State valule..>
  const typeofsupport_state_value =
    typeofSupportstateValue === "Accommodation" ||
    typeofSupportstateValue === "Training" ||
    typeofSupportstateValue === "Course Fees" ||
    typeofSupportstateValue === "Mentorship" ||
    typeofSupportstateValue === "Others"
      ? typeofSupportstateValue
      : false;
  //<..check two session value of needsupport and typeofsupport..>
  const totalsessionfiltervalue =
    shortlist_session_value === false
      ? typesof_session_value === false
        ? "all"
        : typesof_session_value
      : shortlist_session_value;
  //<..check two state value of needsupport and typeofsupport..>
  const totalstatefiltervalue =
    shortlist_state_value === false
      ? typeofsupport_state_value === false
        ? false
        : typeofsupport_state_value
      : shortlist_state_value;
  //<..check  session and state value ..>
  const totalfiltervalue =
    shortlist_session_value === false && typesof_session_value === false
      ? shortlist_state_value === false && typeofsupport_state_value === false
        ? totalsessionfiltervalue
        : totalstatefiltervalue
      : totalsessionfiltervalue;
  //---------

  const getMpEmailId = async () => {
    try {
      const MpData = await axiosData.post("mpmla/mlaconstituencyseeker", {
        email_id: mla,
      });
      const { data } = MpData;

      setAllData(data[0]);

      const shortlistedDatas = data[0].shortListed.reverse();
      setgetapi(shortlistedDatas);
      supportedBy(mlasupport ? mlasupport : "all", shortlistedDatas);
      let bool = false;
      if (mlaNeedSearch !== null) {
        bool = mlaNeedSearch.length > 0;
      }
      if (true === bool) {
        searchdatas(mlaNeedSearch, data[0].needSupport, shortlistedDatas);
        searchicon(true);
      } else if (
        totalfiltervalue === "MP" ||
        totalfiltervalue === "MLA" ||
        totalfiltervalue === "Volunteer" ||
        totalfiltervalue === "Pending"
      ) {
        shortlistBy(totalfiltervalue, data[0].needSupport);
      } else if (
        totalfiltervalue === "Accommodation" ||
        totalfiltervalue === "Training" ||
        totalfiltervalue === "all" ||
        totalfiltervalue === "Course Fees" ||
        totalfiltervalue === "Mentorship" ||
        totalfiltervalue === "Others"
      ) {
        finalverifier(totalfiltervalue, data[0]);
      }

      setIsLoading(false);
    } catch (err) {
      console.log("Error", err);
      setIsLoading(false);
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const selectedApiForExcel = MLAneed.map((i) => {
    return {
      "Full Name": i.Name,
      Email: i.email_id,
      Mobile: i.mobile_number,
      "Types Of Support": i.TypeOfSupport,
      "Approx Amount": i.amount,

      "Voting District": i.votingDistrict,
      "MP Constituency": i.mpConstituency,
      "MLA Constituency": i.mlaConstituency,
    };
  });
  const selectedApiForExcelShort = shortlistedData.map((i) => {
    return {
      "Full Name": i.Name,
      Email: i.email_id,
      Mobile: i.mobile_number,
      "Types Of Support": i.TypeOfSupport,
      "Approx Amount": i.amount,

      "Voting District": i.votingDistrict,
      "MP Constituency": i.mpConstituency,
      "MLA Constituency": i.mlaConstituency,
    };
  });

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const finalverifier = (value, fullarray) => {
    if (value === "all") {
      const needSupportArray = [...fullarray.needSupport];
      setMLAneed(needSupportArray.reverse());
      setTotalRows(needSupportArray.length);
      setPage(setMLAPageNumber);
    } else if (value === "Accommodation") {
      const AccomodationArray = [...fullarray.Accommodation];

      setMLAneed(AccomodationArray.reverse());
      setTotalRows(AccomodationArray.length);
      setPage(setMLAPageNumber);
    } else if (value === "Training") {
      const TrainingArray = [...fullarray.Training];

      setMLAneed(TrainingArray.reverse());
      setTotalRows(TrainingArray.length);
      setPage(setMLAPageNumber);
    } else if (value === "Course Fees") {
      const CourseArray = [...fullarray["Course Fees"]];

      setMLAneed(CourseArray.reverse());
      setTotalRows(CourseArray.length);
      setPage(setMLAPageNumber);
    } else if (value === "Mentorship") {
      const MentorshipArray = [...fullarray.Mentorship];

      setMLAneed(MentorshipArray.reverse());
      setTotalRows(MentorshipArray.length);
      setPage(setMLAPageNumber);
    } else if (value === "Others") {
      const OthersArray = [...fullarray.Others];
      setMLAneed(OthersArray.reverse());
      setTotalRows(OthersArray.length);
      setPage(setMLAPageNumber);
    }
  };
  const supportedBy = (shortvalue, total) => {
    if (shortvalue === "all") {
      const allData = [...total];
      setShortListedData(allData);
      setTotalRowshort(allData.length);
      setPage(setMLAPageNumber);
    } else if (shortvalue === "MLA") {
      const fill = total.filter((d) => {
        return d.supported === "MLA";
      });
      setShortListedData(fill);
      setTotalRowshort(fill.length);
      setPage(setMLAPageNumber);
    } else if (shortvalue === "Pending") {
      const fill = total.filter((d) => d.supported === "");
      setShortListedData(fill);
      setTotalRowshort(fill.length);
      setPage(setMLAPageNumber);
    }
  };
  const shortlistBy = (shortvalue, total) => {
    if (shortvalue === "MP") {
      const fill = total.filter((d) => {
        return d.shortList === "MP";
      });
      setMLAneed(fill);
      setTotalRows(fill.length);
      setPage(setMLAPageNumber);
    } else if (shortvalue === "MLA") {
      const fill = total.filter((d) => {
        return d.shortList === "MLA";
      });
      setMLAneed(fill);
      setTotalRows(fill.length);
      setPage(setMLAPageNumber);
    } else if (shortvalue === "Volunteer") {
      const volun = total.filter((d) => {
        const dhiv = d.shortList.split("_")[0];
        if (dhiv === "Volunteer") {
          return dhiv;
        }
      });
      setMLAneed(volun);
      setTotalRows(volun.length);
      setPage(setMLAPageNumber);
    } else if (shortvalue === "Pending") {
      const fill = total.filter((d) => d.shortList === "");
      setMLAneed(fill);
      setTotalRows(fill.length);
      setPage(setMLAPageNumber);
    }
  };
  const updateAdminVerify = async (email_id, decide, notes) => {
    setIsLoadingPage(true);
    try {
      await axiosData.put(
        `/seeker/email/${email_id}`,
        { notes: notes },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      await axiosData.put(`family/admin/${email_id}`, {
        shortList: decide,
        shortlistNotes: notes,
      });
      getMpEmailId();
      setApproveOpen(false);
    } catch (err) {
      console.log("Error", err);
    }
    setIsLoadingPage(false);
  };
  const closeFunctionCall = () => {
    finalverifier(totalfiltervalue, allData);
  };
  const searchdatas = (searchdata, need, short) => {
    if (currentUrl == shortRoute) {
      const datass = short.filter((item) => {
        const searchtext = new RegExp(searchdata, "i");
        var valuess =
          item.Name.match(searchtext) || item.email_id.match(searchtext);

        if (valuess != null) {
          return item;
        }
      });
      setShortListedData(datass);
      setTotalRowshort(datass.length);
      setPage(0);
    } else {
      const datas = need.filter((item) => {
        const searchtext = new RegExp(searchdata, "i");
        var valuess =
          item.Name.match(searchtext) || item.email_id.match(searchtext);

        if (valuess != null) {
          return item;
        }
      });
      setMLAneed(datas);
      setTotalRows(datas.length);
    }
  };
  useEffect(() => {
    getMpEmailId();
    setPage(
      currentUrl == shortRoute ? setMLAShortPageNumber : setMLAPageNumber
    );

    if (currentUrl == shortRoute) {
      sessionStorage.removeItem("mla_short_page_number");
    } else {
      sessionStorage.removeItem("mla_page_number");
    }
  }, [currentUrl, location.state]);
  if (!isLoading) {
    return (
      <>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <Box>
            <Card sx={{ padding: "20px" }}>
              <Typography
                variant="body1"
                sx={{ fontFamily: "Barlow-Bold", textTransform: "capitalize" }}
              >
                {allData.mla_constituency}
                {currentUrl == shortRoute
                  ? "'s Shortlisted Data"
                  : "'s Need Support"}
              </Typography>
            </Card>
          </Box>

          {currentUrl == shortRoute ? null : (
            <Card>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingTop: "20px",
                }}
              >
                <StatMLAcards state={allData} />
              </Box>
            </Card>
          )}
          <Card sx={{ boxShadow: 0 }}>
            {currentUrl === shortRoute ? null : (
              <Paper>
                <Box
                  sx={{
                    padding: "20px",
                    backgroundColor: "#f2f2f2",
                  }}
                >
                  <Grid container>
                    <Grid item md={8}>
                      <FormControl>
                        <FormLabel id="demo-row-radio-buttons-group-label">
                          Types Of Support
                        </FormLabel>
                        <RadioGroup
                          row
                          id="radio-btn"
                          value={totalfiltervalue}
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          sx={{ marginRight: "18px" }}
                          onChange={(e) => {
                            if (searchicon === true) {
                              setsearchicon(false);
                              sessionStorage.setItem("mla_need_search", "");
                              setsearchdata("");
                            }
                            sessionStorage.setItem(`shortradio`, null);
                            sessionStorage.setItem(
                              "typeofsupport",
                              e.target.value
                            );

                            finalverifier(e.target.value, allData);
                          }}
                        >
                          <FormControlLabel
                            name="usertype"
                            control={<Radio value={"all"} />}
                            label="All"
                          ></FormControlLabel>
                          <FormControlLabel
                            name="usertype"
                            control={<Radio value={"Training"} />}
                            label="Training"
                          ></FormControlLabel>
                          <FormControlLabel
                            name="usertype"
                            control={<Radio value={"Accommodation"} />}
                            label="Accommodation"
                          />
                          <FormControlLabel
                            name="usertype"
                            control={<Radio value={"Course Fees"} />}
                            label="Course Fees"
                          />
                          <FormControlLabel
                            name="usertype"
                            control={<Radio value={"Mentorship"} />}
                            label="Mentorship"
                          />
                          <FormControlLabel
                            name="usertype"
                            control={<Radio value={"Others"} />}
                            label="Others"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item md={4}>
                      <TextField
                        fullWidth
                        label="Search"
                        variant="outlined"
                        value={searchdata ? searchdata : mlaNeedSearch}
                        InputProps={{
                          endAdornment: (
                            <IconButton
                              sx={{
                                visibility: `${
                                  searchicon ? "visible" : "hidden"
                                }`,
                              }}
                              onClick={() => {
                                closeFunctionCall();
                                setsearchicon(false);
                                sessionStorage.setItem("mla_need_search", "");
                                setsearchdata("");
                              }}
                            >
                              <CloseRoundedIcon />
                            </IconButton>
                          ),
                        }}
                        onKeyDown={(e) =>
                          [
                            "(",
                            ")",
                            "^",
                            "[",
                            "]",
                            "*",
                            "<",
                            ">",
                            "\\",
                          ].includes(e.key) && e.preventDefault()
                        }
                        onChange={(e) => {
                          sessionStorage.setItem(`shortradio`, null);
                          sessionStorage.setItem("typeofsupport", "all");
                          setsearchdata(e.currentTarget.value);
                          sessionStorage.setItem(
                            "mla_need_search",
                            e.currentTarget.value
                          );
                          if (e.currentTarget.value.length === 0) {
                            closeFunctionCall();
                          }
                          if (e.currentTarget.value.length >= 3) {
                            searchdatas(
                              e.currentTarget.value,
                              allData.needSupport,
                              shortlistedData
                            );
                          }
                          setsearchicon(true);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            )}
            <Paper>
              <Box
                sx={{
                  padding: "20px",
                  backgroundColor: "#f2f2f2",
                }}
              >
                {currentUrl == shortRoute ? (
                  <Grid container>
                    <Grid item md={8}>
                      <FormControl>
                        <FormLabel id="demo-row-radio-buttons-group-label">
                          Filter By Support
                        </FormLabel>
                        <RadioGroup
                          row
                          id="radio-btn"
                          value={mlasupport ? mlasupport : "all"}
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          sx={{ marginRight: "18px" }}
                          onChange={(e) => {
                            sessionStorage.setItem(
                              `mla_supportradio`,
                              e.target.value
                            );

                            supportedBy(e.target.value, getapi);
                          }}
                        >
                          <FormControlLabel
                            name="usertype"
                            control={<Radio value="all" />}
                            label="All"
                          ></FormControlLabel>
                          <FormControlLabel
                            name="usertype"
                            control={<Radio value="Pending" />}
                            label="Pending"
                          ></FormControlLabel>
                          <FormControlLabel
                            name="usertype"
                            control={<Radio value={"MLA"} />}
                            label="Completed"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item md={2}>
                      <Tooltip title="Export As Excel" placement="bottom">
                        <ExportCS
                          selectedApiForExcel={
                            currentUrl == shortRoute
                              ? selectedApiForExcelShort
                              : selectedApiForExcel
                          }
                          fileName={`Seeker-${
                            currentUrl == shortRoute
                              ? "ShortListed"
                              : "NeedSupport"
                          }-Data`}
                        />
                      </Tooltip>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container>
                    <Grid item md={8}>
                      <FormControl>
                        <FormLabel id="demo-row-radio-buttons-group-label">
                          Filter By ShortList
                        </FormLabel>
                        <RadioGroup
                          row
                          id="radio-btn"
                          value={totalfiltervalue}
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          sx={{ marginRight: "18px" }}
                          onChange={(e) => {
                            if (searchicon === true) {
                              setsearchicon(false);
                              sessionStorage.setItem("mla_need_search", "");
                              setsearchdata("");
                            }
                            sessionStorage.setItem(
                              `shortradio`,
                              e.target.value
                            );
                            sessionStorage.setItem("typeofsupport", null);
                            shortlistBy(e.target.value, allData.needSupport);
                          }}
                        >
                          <FormControlLabel
                            name="usertype"
                            control={<Radio value="Pending" />}
                            label="Pending"
                          ></FormControlLabel>
                          <FormControlLabel
                            name="usertype"
                            control={<Radio value={"MP"} />}
                            label="Completed by MP"
                          />
                          <FormControlLabel
                            name="usertype"
                            control={<Radio value={"MLA"} />}
                            label="Completed by MLA"
                          />
                          <FormControlLabel
                            name="usertype"
                            control={<Radio value={"Volunteer"} />}
                            label="Completed by Volunteer"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item md={2}>
                      <Tooltip title="Export As Excel" placement="bottom">
                        <ExportCS
                          selectedApiForExcel={selectedApiForExcel}
                          fileName={"Seeker-NeedSupport-Data"}
                        />
                      </Tooltip>
                    </Grid>
                  </Grid>
                )}
              </Box>
            </Paper>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow className="table-row">
                    <TableCell>Profile</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Family Income</TableCell>

                    {currentUrl == shortRoute ? (
                      <TableCell>Supported Status</TableCell>
                    ) : (
                      <TableCell>ShortList Status</TableCell>
                    )}
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentUrl == shortRoute ? (
                    <>
                      {shortlistedData.length > 0 ? (
                        shortlistedData
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((item) => {
                            let supportedStat =
                              item.supported === "" ? "pending" : "Supported";
                            return (
                              <TableRow className="tablecell">
                                <TableCell>
                                  <img
                                    src={`${baseUrl}seekers/profile/email/${item.email_id}`}
                                    height={"50"}
                                    width="50"
                                    onError={(e) => {
                                      e.target.src = defaultimg;
                                    }}
                                    style={{ clipPath: "circle(50%)" }}
                                    alt="img"
                                  />
                                </TableCell>
                                <TableCell>{item.Name}</TableCell>
                                <TableCell>{item.email_id}</TableCell>
                                <TableCell>{item.FamilyIncome}</TableCell>

                                <TableCell>
                                  <Chip
                                    label={supportedStat}
                                    variant="filled"
                                    color={
                                      item.supported === "" ? "warning" : "info"
                                    }
                                    sx={{
                                      color: "#ffffff",
                                    }}
                                  />
                                </TableCell>
                                <TableCell>
                                  <Tooltip title="Full Details" placement="top">
                                    <IconButton
                                      className="icon-btn"
                                      size="Medium"
                                      onClick={() => {
                                        sessionStorage.setItem(
                                          "mla_short_page_number",
                                          page
                                        );
                                        navigate(
                                          `${ROUTINGDATA.LAND}${ROUTINGDATA.MLAconstituency}/${ROUTINGDATA.MLAShortlisted_fullDetails}`,
                                          {
                                            state: item.email_id,
                                          }
                                        );
                                      }}
                                    >
                                      <OpenInNewRoundedIcon />
                                    </IconButton>
                                  </Tooltip>
                                  {item.supported === "" ? (
                                    <Button
                                      variant="contained"
                                      sx={{
                                        textTransform: "capitalize ",
                                        boxShadow: 0,
                                        borderRadius: "16px",
                                      }}
                                      startIcon={<FavoriteRoundedIcon />}
                                      onClick={() => {
                                        setContactSeekDetails({
                                          ...contactSeekDetails,
                                          name: item.Name,
                                          email_id: item.email_id,
                                          mobile: item.mobile_number,
                                        });
                                        setOpenFormikContactDialog(true);
                                      }}
                                    >
                                      Support
                                    </Button>
                                  ) : null}
                                </TableCell>
                              </TableRow>
                            );
                          })
                      ) : (
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell>
                            <Typography
                              variant="body1"
                              sx={{ fontFamily: "Barlow-Bold" }}
                            >
                              NO Data Available
                            </Typography>
                          </TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      )}
                      <EnterContactSupportDialog
                        openFormikContactDialog={openFormikContactDialog}
                        setOpenFormikContactDialog={setOpenFormikContactDialog}
                        contactSeekDetails={contactSeekDetails}
                        setContactSeekDetails={setContactSeekDetails}
                        getFunction={getMpEmailId}
                        setOpenSupportSnack={setOpenSupportSnack}
                      />
                      <ContactSupportSnack
                        contactName={contactSeekDetails.contactName}
                        seekName={contactSeekDetails.name}
                        openSupportSnack={openSupportSnack}
                        setOpenSupportSnack={setOpenSupportSnack}
                      />
                    </>
                  ) : (
                    <>
                      {" "}
                      {MLAneed.length > 0 ? (
                        MLAneed.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        ).map((item) => {
                          let shortlistStat =
                            item.shortList === ""
                              ? "pending"
                              : item.shortList === "MP"
                              ? "Completed By MP"
                              : item.shortList === "MLA"
                              ? "Completed By You"
                              : "Completed By Volunteer";
                          return (
                            <TableRow className="tablecell">
                              <TableCell>
                                <img
                                  src={`${baseUrl}seekers/profile/email/${item.email_id}`}
                                  height={"50"}
                                  width="50"
                                  onError={(e) => {
                                    e.target.src = defaultimg;
                                  }}
                                  style={{ clipPath: "circle(50%)" }}
                                  alt="img"
                                />
                              </TableCell>
                              <TableCell>{item.Name}</TableCell>
                              <TableCell>{item.email_id}</TableCell>
                              <TableCell>{item.FamilyIncome}</TableCell>
                              {/* <TableCell>{item.mlaConstituency}</TableCell> */}
                              <TableCell>
                                <Chip
                                  label={shortlistStat}
                                  variant="filled"
                                  color={
                                    item.shortList === ""
                                      ? "warning"
                                      : item.shortList === "MLA" ||
                                        item.shortList === "MP"
                                      ? "info"
                                      : "success"
                                  }
                                  sx={{
                                    color: "#ffffff",
                                  }}
                                />
                              </TableCell>

                              <TableCell>
                                <Tooltip title="Full Details" placement="top">
                                  <IconButton
                                    className="icon-btn"
                                    size="Medium"
                                    onClick={() => {
                                      sessionStorage.setItem(
                                        "mla_page_number",
                                        page
                                      );
                                      navigate(
                                        `${ROUTINGDATA.LAND}${ROUTINGDATA.MLAconstituency}/${ROUTINGDATA.MLAfulDetails}`,
                                        {
                                          state: item.email_id,
                                        }
                                      );
                                    }}
                                  >
                                    <OpenInNewRoundedIcon />
                                  </IconButton>
                                </Tooltip>
                                {item.shortList === "" ? (
                                  <Tooltip>
                                    <Button
                                      startIcon={<PostAddIcon />}
                                      variant="contained"
                                      sx={{
                                        textTransform: "capitalize ",
                                        boxShadow: 0,
                                        borderRadius: "16px",
                                      }}
                                      onClick={() => {
                                        setMLAEmail(item.email_id);
                                        openApproveDialog();
                                      }}
                                    >
                                      Shortlist
                                    </Button>
                                  </Tooltip>
                                ) : null}
                              </TableCell>
                            </TableRow>
                          );
                        })
                      ) : (
                        <TableRow>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell>
                            <Typography
                              variant="body1"
                              sx={{ fontFamily: "Barlow-Bold" }}
                            >
                              NO Data Available
                            </Typography>
                          </TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      )}
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25]}
              component="div"
              count={currentUrl == shortRoute ? totalRowsShort : totalRows}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Box>

        {currentUrl == shortRoute ? null : (
          <ApproveDialog
            approveOpen={approveOpen}
            setApproveOpen={setApproveOpen}
            mlaEmail={MLAEmail}
            isLoading={isLoadingPage}
            MLA={"MLA"}
            updateAdminVerify={updateAdminVerify}
          />
        )}
      </>
    );
  } else {
    return (
      <>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <Card sx={{ padding: "20px", borderRadius: "20px" }}>
            <Skeleton
              animation="wave"
              variant="text"
              width={230}
              height={40}
              sx={{ borderRadius: "15px" }}
            />
          </Card>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            <Card>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingTop: "20px",
                }}
              >
                <SkeletonSeekCard />
              </Box>
            </Card>
          </Box>
          <Card
            sx={{
              borderRadius: "20px",
              display: "flex",
              flexDirection: "column",

              height: "500px",
              gap: "20px",
            }}
          >
            <Skeleton variant="rounded" />
            <Skeleton variant="rounded" width="100%" height="300px" />
          </Card>
        </Box>
      </>
    );
  }
}

export default MLAneedSupport;
