/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import {
  Table,
  TableContainer,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  Box,
  Card,
  Typography,
  IconButton,
  Tooltip,
  TablePagination,
  Paper,
  Button,
  Chip,
} from "@mui/material";
import {
  OpenInNewRounded as OpenInNewRoundedIcon,
  FavoriteRounded as FavoriteRoundedIcon,
} from "@mui/icons-material";
import defaultimg from "../../../../img/mentorImage.png";
import { useLocation, useNavigate } from "react-router-dom";
import useAxios from "../../../../api/useAxios";
import { ROUTINGDATA } from "../../../ROUTINGDATA";

function MPMLAShortList() {
  const location = useLocation();
  const currentUrl = location.pathname;
  const shortRoute =
    ROUTINGDATA.LAND +
    ROUTINGDATA.MPConstituency +
    "/" +
    ROUTINGDATA.MP_MLAShortlisted;

  const navigate = useNavigate();
  const axiosData = useAxios();
  const baseUrl = axiosData.defaults.baseURL;
  const MPMLAPageNumbe = sessionStorage.getItem("mla_supported_page_number");
  const MPMLAPageNumber = parseInt(MPMLAPageNumbe);
  const setMPMLAPageNumber = MPMLAPageNumber ? MPMLAPageNumber : 0;
  const [shortlistedData, setShortListedData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [page, setPage] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [MLAloading, setMLAloading] = useState(false);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const mla = JSON.parse(localStorage.getItem("mla"));
  // const state = location.state.mla;

  // const load = location.state.load;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getMpEmailId = async () => {
    try {
      const MpData = await axiosData.post("mpmla/mlaconstituencyseeker", {
        email_id: mla,
      });
      const { data } = MpData;
      setAllData(data[0]);
      const shortlistedDatas = data[0].supported.reverse();

      setShortListedData(shortlistedDatas);

      setTotalRows(shortlistedDatas.length);
      setMLAloading(true);
    } catch (err) {
      console.log("Error", err);
    }
  };
  useEffect(() => {
    getMpEmailId();
    setPage(setMPMLAPageNumber);
  }, []);
  if (MLAloading) {
    return (
      <>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <Box>
            <Card sx={{ padding: "20px" }}>
              <Typography
                variant="body1"
                sx={{ fontFamily: "Barlow-Bold", textTransform: "capitalize" }}
              >
                {allData.mla_constituency}'s&nbsp;Supported list
              </Typography>
            </Card>
          </Box>

          <Card sx={{ boxShadow: 0 }}>
            <Paper>
              <Box
                sx={{
                  padding: "20px",

                  backgroundColor: "#f2f2f2",
                }}
              ></Box>
            </Paper>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow className="table-row">
                    <TableCell>Profile</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Family Income</TableCell>
                    <TableCell>Contact Person</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {shortlistedData.length > 0 ? (
                    shortlistedData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((item) => {
                        const supportedStat =
                          item.supported === ""
                            ? "pending"
                            : item.supported === "MP"
                            ? "Completed By You"
                            : item.supported === "MLA"
                            ? "Completed By MLA"
                            : "Completed By Volunteer";
                        return (
                          <TableRow className="tablecell">
                            <TableCell>
                              <img
                                src={`${baseUrl}seekers/profile/email/${item.email_id}`}
                                height={"50"}
                                width="50"
                                onError={(e) => {
                                  e.target.src = defaultimg;
                                }}
                                style={{ clipPath: "circle(50%)" }}
                                alt="img"
                              />
                            </TableCell>
                            <TableCell>{item.Name}</TableCell>
                            <TableCell>{item.email_id}</TableCell>
                            <TableCell>{item.FamilyIncome}</TableCell>
                            <TableCell>{item.contactPerson}</TableCell>
                            <TableCell>
                              <Tooltip title="Full Details" placement="top">
                                <IconButton
                                  className="icon-btn"
                                  size="Medium"
                                  onClick={() => {
                                    navigate(
                                      `${ROUTINGDATA.LAND}${ROUTINGDATA.MLAconstituency}/${ROUTINGDATA.MLASupported_fullDetails}`,
                                      {
                                        state: item.email_id,
                                      }
                                    );
                                    sessionStorage.setItem(
                                      "mla_supported_page_number",
                                      page
                                    );
                                  }}
                                >
                                  <OpenInNewRoundedIcon />
                                </IconButton>
                              </Tooltip>
                              {/* {item.supported === "" ? (
                              <Button
                                variant="contained"
                                sx={{
                                  textTransform: "capitalize ",
                                  boxShadow: 0,
                                  borderRadius: "16px",
                                }}
                                startIcon={<FavoriteRoundedIcon />}
                              >
                                Support
                              </Button>
                            ) : null} */}
                            </TableCell>
                          </TableRow>
                        );
                      })
                  ) : (
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell>
                        <Typography
                          variant="body1"
                          sx={{ fontFamily: "Barlow-Bold" }}
                        >
                          NO Data Available
                        </Typography>
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25]}
              component="div"
              count={totalRows}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Box>
      </>
    );
  } else {
    return (
      <div
        style={{
          height: "70vh",
          display: "grid",
          placeItems: "center",
        }}
      >
        <div class="dots"></div>
      </div>
    );
  }
}

export default MPMLAShortList;
