import React from "react";
import { useField, ErrorMessage } from "formik";
import { useTranslation } from "react-i18next";

const CustomErrorMessage = ({ name, formikName }) => {
  const [field, meta] = useField(name);

  const { t, ready } = useTranslation();

  // Custom error message for "Invalid Date" issue
  const getErrorMessage = () => {
    const errorMessage = meta.error;

    if (field.value == null) {
      return `${formikName === "from" ? t("fromdate") : t("todate")} ${t(
        "date_is_required3"
      )}`;
    }
    if (field.value._d == "Invalid Date") {
      return t("Invalid_date_format._Please_enter_a_valid_date");
    }
    return errorMessage;
  };

  if (ready) {
    return (
      <>
        {getErrorMessage() ? (
          <div style={{ color: "#de6363" }}>{getErrorMessage()}</div>
        ) : (
          <ErrorMessage name={name} />
        )}
      </>
    );
  } else {
    return (
      <div
        style={{
          height: "70vh",
          display: "grid",
          placeItems: "center",
        }}
      >
        <div class="dots"></div>
      </div>
    );
  }
};

export default CustomErrorMessage;
