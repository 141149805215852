import React, { useEffect, useState } from "react";
import StatCardsForServiceProvider from "../../../admin-panel/admin-components/StatCards/StatCardsForServiceProvider";
import {
  Table,
  TableContainer,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  Card,
  IconButton,
  Tooltip,
  TablePagination,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Grid,
  Typography,
  Paper,
  TableSortLabel,
  Box,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Fab,
  InputLabel,
  ClickAwayListener,
  FormControlLabel,
  Radio,
  RadioGroup,
  Skeleton,
  Chip,
} from "@mui/material";

import {
  OpenInNewRounded as OpenInNewRoundedIcon,
  LinkedIn as LinkedInIcon,
  CheckRounded as CheckRoundedIcon,
  FileDownload as FileDownloadIcon,
  RefreshSharp as RefreshSharpIcon,
  VideocamRounded as VideocamRoundedIcon,
  CloseRounded as CloseRoundedIcon,
} from "@mui/icons-material/";
import moment from "moment";
import jsPDF from "jspdf";
import useAxios from "../../../../api/useAxios";
import { saveAs } from "file-saver";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import LocalServiceDialog from "../../../admin-panel/admin-components/DialogBoxes/LocalServiceDialog";
import { B } from "react-html5video";
function ProviderDashboard() {
  const [getApi, setGetApi] = useState([]);
  const [useApi, setUseApi] = useState([]);
  const [page, setPage] = useState(0);
  const [getEmail, setGetEmail] = useState("");
  const [getId, SetGetId] = useState("");
  const [dateRange, setDateRange] = useState({
    startDate: "",
    endDate: "",
    openTooltip: false,
    openStartTool: false,
  });

  const [loading, setLoading] = useState({ isLoading: true, dialogLoad: true });
  const [volunteerDistrict, setVolunteerDistrict] = useState({});

  const [totalRows, setTotalRows] = useState(0);
  const [open, setOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [getOne, setGetOne] = useState({});
  const [getOneProvider, setGetOneProvider] = useState({});
  const [providerForPut, setProviderForPut] = useState({
    name: "",
    email_id: "",
    provider_id: "",
  });
  const [searchdata, setsearchdata] = useState("");
  const [disabled, setdisabled] = useState(false);
  // <!----SORt--->
  const [sortedData, setSortedData] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");

  const [searchicon, setsearchicon] = useState(false);

  const [filterName, setFilterName] = useState({
    date: "today",
    status: "all",
    isLoading: false,
  });

  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const [startdisabled, setstartdisabled] = useState(false);
  const [enddisabled, setenddisabled] = useState(false);
  const [starterror, setstartError] = useState(null);
  const [enderror, setendError] = useState(null);
  const [dateenable, setdateenable] = useState(false);
  const [openCloseDialog, setOpenCloseDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [serviceName, setServiceName] = useState([]);
  const [allProviderData, setAllProviderData] = useState([]);
  const todate = moment();
  const todayDate = moment().format("DD-MM-YYYY");
  const yesterdayDate = moment().subtract(1, "day").format("DD-MM-YYYY");
  const minDate = moment("01-06-2023", "DD-MM-YYYY");
  const maxDate = moment();
  const starterrorMessage = React.useMemo(() => {
    switch (starterror) {
      case "maxDate": {
        return `Please select a date before the end date`;
      }
      case "minDate": {
        return `Please select a date before the end date`;
      }
      case "invalidDate": {
        return "Invalid date range";
      }
      default: {
        return "";
      }
    }
  }, [starterror]);

  const enderrorMessage = React.useMemo(() => {
    switch (enderror) {
      case "maxDate": {
        return `Please select today date or before the today date`;
      }
      case "minDate": {
        return `Please select a date after the start date`;
      }
      case "invalidDate": {
        return "Invalid date range";
      }
      default: {
        return "";
      }
    }
  }, [enderror]);
  const handleChange = () => {
    setOpen(!open);
    setGetOne({});
    setLoading({ ...loading, dialogLoad: true });
  };

  const axiosData = useAxios();
  const baseUrl = axiosData.defaults.baseURL;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // <!----GET--->
  const ProviderMail = JSON.parse(localStorage.getItem("service-provider"));

  const fetchApi = async () => {
    try {
      const useAxiosData = await axiosData.get("/localservices");
      const { data } = useAxiosData;
      if (useAxiosData.status === 204) {
        setGetApi([]);
      } else {
        data.reverse();
        let ind;
        const filteredDataOfProvider = data.filter((item) => {
          const getAssignedEmail = item.assign_provider_email.split(",");

          const getAssignedDate = item.assigned_date.split(",");
          const getProviderMail = getAssignedEmail.filter((element, index) => {
            if (element === ProviderMail) {
              ind = index;
              return element;
            } else {
              return false;
            }
          });

          const getProviderDate = getAssignedDate.filter((element, index) => {
            if (index === ind) {
              return element;
            } else {
              return false;
            }
          });
          ind = undefined;

          return getProviderMail[0] === ProviderMail;
          // return item.assign_provider_email === ProviderMail;
        });
        setGetApi(filteredDataOfProvider);
        getFilterValue(
          filterName.status,
          filterName.date,
          filteredDataOfProvider
        );
      }

      setLoading({ ...loading, isLoading: false });
    } catch (err) {
      console.log("Error", err);
    }
  };
  const getProviderDetails = async () => {
    try {
      const getData = await axiosData.get(
        `localserviceprovider/email/${ProviderMail}`
      );
      const { data } = getData;
      const serviceNames = data.service_name.split(",");
      setAllProviderData(data);
      setServiceName(serviceNames);
    } catch (err) {}
  };
  // <!-----API FUNCTIONS---->
  const getOneData = async (id) => {
    try {
      const getOneAxios = await axiosData.get(`localservices/${id}`);
      const { data } = getOneAxios;

      setGetOne(data);
      setLoading({ ...loading, dialogLoad: false });
    } catch (err) {
      console.log("Error", err);
    }
  };
  const updateClose = async (email) => {
    const statusClosedBy = {
      name: `${allProviderData.contact_person_name}`,
      email_id: `${allProviderData.email_id}`,
      provider_id: `${allProviderData.provider_id}`,
    };
    setIsLoading(true);
    try {
      // const providername = `${providerForPut.name}`;
      // const providerid = `${providerForPut.provider_id}`;
      // const provideremail = `${providerForPut.email_id}`;
      await axiosData.put(`localservices/${email}`, {
        status: "Close",
        statusClosedBy,
      });
      await axiosData.put(`urgentservices/urgent/${email}`, {
        status: "Close",
      });
      setGetEmail("");
      setOpenCloseDialog(false);
      fetchApi();
      getProviderEmailData();
      setIsLoading(false);
    } catch (err) {
      console.log("Error", err);
    }
  };
  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    const sortedData = useApi.sort((a, b) => {
      const valueA = a[property];
      const valueB = b[property];

      if (valueA === valueB) {
        return -1;
      }

      if (valueA < valueB) {
        return isAsc ? -1 : 1;
      }
      if (valueA > valueB) {
        return isAsc ? 1 : -1;
      }
      return 0;
    });
    setSortedData(sortedData);
  };

  const getFilterValue = (statusvalue, value, alldatas) => {
    if (value === "daterange") {
      const get = alldatas.filter((i) => i.assigned_date === todayDate);
      statusForAll(statusvalue, get);
      setdateenable(true);
    } else if (value === "today") {
      const get = alldatas.filter((i) => i.assigned_date === todayDate);
      statusForAll(statusvalue, get);
    } else if (value === "yesterday") {
      const yes = alldatas.filter((i) => i.assigned_date === yesterdayDate);
      statusForAll(statusvalue, yes);
    } else if (value === "week") {
      const startWeek = moment().subtract(1, "weeks").startOf("week");
      const endWeek = moment().subtract(1, "weeks").endOf("week");
      function getDatesInRange(startDate, endDate) {
        const date = new Date(startDate.getTime());
        const dates = [];
        while (date <= endDate) {
          dates.push(moment(date).format("MM/DD/YYYY"));
          date.setDate(date.getDate() + 1);
        }
        return dates;
      }
      const d1 = new Date(startWeek);
      const d2 = new Date(endWeek);

      const weekvalue = getDatesInRange(d1, d2);

      const resDates = weekvalue.map((getdate) => {
        const getDate2 = getdate;
        const getDate3 = getDate2
          .split("/")
          .map((e) => (e[0] === "0" ? e.slice() : e));
        const getDateFormat =
          getDate3[1] + "-" + getDate3[0] + "-" + getDate3[2];

        return getDateFormat;
      });

      const dataapp = [];
      resDates.map((datas) => {
        const dd = alldatas.filter((ite) => ite.assigned_date === datas);
        const filterdat = dd.map((item) => dataapp.push(item));
        return filterdat;
      });

      const week = dataapp;

      statusForAll(statusvalue, week);
    } else if (value === "month") {
      var currentMonth = moment(todate).format("MM");
      var currentyear = moment(todate).format("YYYY");

      const minsmonth = currentMonth - 1;
      if (currentMonth === "01") {
        var call = currentyear - 1;
        const endOfMonth = moment(
          `${currentyear}-${currentMonth}`,
          "YYYY-MM"
        ).endOf("month");
        const endofdate = endOfMonth.format("DD-MM-YYYY");
        const mon = alldatas.filter((item) =>
          moment(item.assigned_date).isBetween(
            `01-12-${call}`,
            endofdate,
            null,
            "[]"
          )
        );
        statusForAll(statusvalue, mon);
      } else {
        const endOfMonth = moment(
          `${currentyear}-${minsmonth}`,
          "DD-MM-YYYY"
        ).endOf("month");
        const startOfMonth = moment(
          `${currentyear}-${minsmonth}`,
          "YYYY-MM"
        ).startOf("month");
        const startOfdate = startOfMonth.format("DD-MM-YYYY");
        const date1 = moment(startOfdate, "DD-MM-YYYY");
        const endofdate = endOfMonth.format("DD-MM-YYYY");
        const date2 = moment(endofdate, "DD-MM-YYYY");
        const mon = alldatas.filter((item) => {
          const date = moment(item.assigned_date, "DD-MM-YYYY");
          return moment(date).isBetween(date1, date2, null, "[]");
        });
        statusForAll(statusvalue, mon);
      }
    } else if (value === "Total") {
      statusForAll(statusvalue, alldatas);
    }
  };
  const statusForAll = (statusvalue, get) => {
    if (statusvalue === "all") {
      const data = [...get];
      setUseApi(data);
      setTotalRows(data.length);
      setPage(0);
    } else if (statusvalue === volunteerDistrict.district) {
      const data = get.filter((i) => i.votingDistrict === statusvalue);
      setUseApi(data);
      setTotalRows(data.length);
      setPage(0);
    } else if (statusvalue === volunteerDistrict.industry) {
      const data = get.filter((i) => i.industry === statusvalue);
      setUseApi(data);
      setTotalRows(data.length);
      setPage(0);
    }
  };
  const statusForDateSearch = (statusvalue, get) => {
    if (statusvalue === "all") {
      const data = [...get];
      return data;
    } else if (statusvalue === volunteerDistrict.district) {
      const today = get.filter((i) => i.votingDistrict === statusvalue);
      const data = today;
      return data;
    } else if (statusvalue === volunteerDistrict.industry) {
      const today = get.filter((i) => i.industry === statusvalue);
      const data = today;
      return data;
    }
  };
  const handleStartDateChange = (event) => {
    setsearchicon(false);
    setsearchdata("");
    setStartDate(event);
    setstartError(null);
    if (startDate === null) {
      setstartdisabled(false);
    }
  };
  const handleEndDateChange = (event) => {
    setsearchicon(false);
    setsearchdata("");
    setEndDate(event);
    setendError(null);
    if (endDate === null) {
      setenddisabled(false);
    }
  };

  const handleClickFilter = () => {
    const afterStatusFilter = statusForDateSearch(filterName.status, getApi);
    const filteredData = afterStatusFilter.filter((d) => {
      const date = moment(d.assigned_date, "DD-MM-YYYY");
      const start = moment(startDate).subtract(1, "days");
      return date.isBetween(start, endDate, null, "[]");
    });
    setUseApi(filteredData);
    setTotalRows(filteredData.length);
    setPage(0);
  };

  const generatePdf = () => {
    const doc = new jsPDF();

    const headers = [
      [
        "Full Name",
        "Email",
        "Mobile",
        "Gender",
        "Experience",
        "Work Status",
        "Industry",
        "Current Location",
        "Voting District",
        "MP Constituency",
        "MLA Constituency",
        "Pincode",
        "Created Date",
        "Status",
      ],
    ];

    const data = useApi.map((user) => {
      return [
        user.full_name,
        user.email_id,
        user.mobile_number,
        user.gender,
        user.Experience,
        user.work_status,
        user.industry,
        user.currentLocation,
        user.votingDistrict,
        user.mpConstituency,
        user.mlaConstituency,
        user.pincode,
        user.assigned_date,
        user.status,
      ];
    });

    doc.autoTable({
      head: headers,
      body: data,
      columnStyles: {
        columnWidth: 400, // Set the width of all columns to 50
      },
      headerStyles: { fontSize: 6 },
      bodyStyles: { fontSize: 6, width: 400 },
    });

    doc.save(`Job-Seeker-(${moment().format("DD-MM-YY")})`);
  };

  const handleClickRefresh = () => {
    setsearchdata("");
    setStartDate(moment());
    setEndDate(moment());
    setFilterName({
      ...filterName,
      date: "today",
      status: "all",
    });
    setstartError(null);
    setendError(null);
    setstartdisabled(false);
    setenddisabled(false);
    getFilterValue("all", "today", getApi);
  };
  const selectedApiForExcel = useApi.map((i) => {
    return {
      "Full Name": i.full_name,
      Email: i.email_id,
      Mobile: i.mobile_number,
      Industry: i.industry,
      gender: i.gender,
      "Current Location": i.currentLocation,
      "Voting District": i.votingDistrict,
      "MP Constituency": i.mpConstituency,
      "MLA Constituency": i.mlaConstituency,
      "Created Date": i.assigned_date,
    };
  });

  const handleToolClose = () => {
    setDateRange({
      ...dateRange,
      openTooltip: false,
      endDate: "",
    });
  };
  const handleToolStartClose = () => {
    setDateRange({
      ...dateRange,
      startDate: "",
      openStartTool: false,
    });
  };

  const closeFunctionCall = () => {
    setStartDate(moment());
    setEndDate(moment());
    setFilterName({
      ...filterName,
      date: "today",
      status: "all",
    });
    setstartError(null);
    setendError(null);
    setstartdisabled(false);
    setenddisabled(false);
    getFilterValue("all", "today", getApi);
  };

  const getProviderEmailData = async () => {
    try {
      const getOneAxios = await axiosData.get(`localservices/${ProviderMail}`);
      const { data } = getOneAxios;

      //setGetOneProvider(data);

      // setLoading({ ...loading, dialogLoad: false });
    } catch (err) {
      console.log("Error", err);
    }
  };
  useEffect(() => {
    getProviderDetails();
    fetchApi();
    getProviderEmailData();
    handleSort(orderBy);
  }, []);

  const searchdatas = (searchdata) => {
    const afterStatusFilter = statusForDateSearch(filterName.status, getApi);
    const searchtext = new RegExp(searchdata, "i");
    const outputString = searchdata.replace(/\s/g, "");
    const searchtext2 = new RegExp(outputString, "i");
    const datas = afterStatusFilter.filter((item) => {
      var valuess =
        item.pincode.match(searchtext) ||
        item.village_town_name.match(searchtext2) ||
        item.area_street_name.match(searchtext2) ||
        item.type_of_service.match(searchtext2);
      if (valuess != null) {
        return item;
      }
    });
    setUseApi(datas);
    setTotalRows(datas.length);
    setPage(0);
  };
  useEffect(() => {
    let timeout;
    if (dateRange.openStartTool || dateRange.openTooltip) {
      timeout = setTimeout(() => {
        dateRange.openStartTool
          ? handleToolStartClose()
          : dateRange.openTooltip
          ? handleToolClose()
          : handleToolClose();
      }, 3000);
    }
    return () => clearTimeout(timeout);
  }, [dateRange.openStartTool, dateRange.openTooltip]);
  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        <Box>
          <Card sx={{ width: "50%", padding: "20px" }}>
            {" "}
            <Typography variant="h5" sx={{ fontFamily: "Barlow-Bold" }}>
              {" "}
              Your Provider Id:{allProviderData.provider_id}{" "}
            </Typography>
          </Card>
        </Box>
        <Box>
          <StatCardsForServiceProvider
            getApi={getApi}
            serviceName={serviceName}
          />
        </Box>
        <Box>
          {" "}
          <Card sx={{ padding: "20px" }}>
            {" "}
            <Grid container spacing={3} width="100%">
              <Grid item xs={12} sm={6} md={2}>
                {" "}
                <FormControl
                  sx={{ width: { md: "100%", xs: "100%", sm: "100%" } }}
                >
                  <InputLabel>Filter By Date</InputLabel>
                  <Select
                    variant="standard"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={filterName.date}
                    onChange={(e) => {
                      setsearchicon(false);
                      setsearchdata("");
                      setFilterName({
                        ...filterName,
                        date: e.target.value,
                      });
                      getFilterValue(filterName.status, e.target.value, getApi);
                    }}
                  >
                    <MenuItem value="Total">Total</MenuItem>
                    <MenuItem value="today">Today</MenuItem>
                    <MenuItem value="yesterday">Yesterday</MenuItem>
                    <MenuItem value="week">Last Week</MenuItem>
                    <MenuItem value="month">Last month</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={2} sm={6}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <ClickAwayListener onClickAway={handleToolStartClose}>
                    <Tooltip
                      PopperProps={{
                        disablePortal: true,
                        sx: {
                          "& .MuiTooltip-tooltip": {
                            backgroundColor: "#FF0000",
                          },
                        },
                      }}
                      open={dateRange.openStartTool}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      title={dateRange.startDate}
                      onClose={handleToolStartClose}
                      arrow
                    >
                      <Box
                        sx={{
                          width: { md: "100%", xs: "100%", sm: "100%" },
                        }}
                      >
                        <DatePicker
                          disableFuture
                          label="Start Date"
                          value={startDate}
                          format="DD-MM-YYYY"
                          onChange={(e) => handleStartDateChange(e)}
                          onError={(newError) => {
                            setstartError(newError);
                            const boolenvalue =
                              newError === "invalidDate" ||
                              newError === "maxDate" ||
                              newError === "minDate" ||
                              (starterror === null && startDate === null)
                                ? true
                                : false;

                            setstartdisabled(boolenvalue);
                          }}
                          slotProps={{
                            textField: {
                              helperText: starterrorMessage,
                              onFocus: () => {
                                if (starterror === null && startDate === null) {
                                  setStartDate(moment());
                                  setstartdisabled(false);
                                }
                              },
                            },
                          }}
                          maxDate={endDate || maxDate}
                        />
                      </Box>
                    </Tooltip>
                  </ClickAwayListener>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={2} sm={6}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  {/* <ClickAwayListener onClickAway={handleToolClose}>
                          <Tooltip
                            PopperProps={{
                              disablePortal: true,
                              sx: {
                                "& .MuiTooltip-tooltip": {
                                  backgroundColor: "#FF0000",
                                },
                              },
                            }}
                            open={dateRange.openTooltip}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            onClose={handleToolClose}
                            title={dateRange.endDate}
                            leaveDelay={1}
                            arrow
                          > */}
                  <Box>
                    <DatePicker
                      label="End Date"
                      value={endDate}
                      format="DD-MM-YYYY"
                      onChange={(e) => handleEndDateChange(e)}
                      onError={(newError) => {
                        setendError(newError);
                        const bollenerror =
                          newError === "invalidDate" ||
                          newError === "minDate" ||
                          newError === "maxDate" ||
                          (enderror === null && endDate === null)
                            ? true
                            : false;

                        setenddisabled(bollenerror);
                      }}
                      slotProps={{
                        textField: {
                          helperText: enderrorMessage,
                          onFocus: () => {
                            if (enderror === null && endDate === null) {
                              setEndDate(moment());
                              setenddisabled(false);
                            }
                          },
                        },
                      }}
                      minDate={startDate}
                      maxDate={maxDate}
                    />
                  </Box>
                  {/* </Tooltip>
                        </ClickAwayListener> */}
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12} md={2} sm={4} alignItems="center">
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <Tooltip title="Filter" placement="bottom">
                    <Fab
                      disabled={
                        startdisabled === true || enddisabled === true
                          ? true
                          : false
                      }
                      size="small"
                      sx={{
                        color: "white",
                        backgroundColor: "#339fcd",
                        boxShadow: 0,
                        "&:hover": { backgroundColor: "#217396" },
                      }}
                      onClick={() => {
                        handleClickFilter();
                      }}
                    >
                      <CheckRoundedIcon />
                    </Fab>
                  </Tooltip>
                  <Tooltip title="Refresh" placement="bottom">
                    <Fab
                      size="small"
                      sx={{
                        color: "white",
                        backgroundColor: "#339fcd",
                        boxShadow: 0,
                        "&:hover": { backgroundColor: "#217396" },
                      }}
                      onClick={handleClickRefresh}
                    >
                      <RefreshSharpIcon />
                    </Fab>
                  </Tooltip>
                </Box>
              </Grid>
              <Grid item xs={8} md={2} sm={4}>
                <TextField
                  fullWidth
                  label="Search"
                  variant="outlined"
                  value={searchdata}
                  onKeyDown={(e) =>
                    ["(", ")", "^", "[", "]", "*", "<", ">", "\\"].includes(
                      e.key
                    ) && e.preventDefault()
                  }
                  onChange={(e) => {
                    setsearchdata(e.currentTarget.value);

                    if (e.currentTarget.value.length === 0) {
                      closeFunctionCall();
                    }

                    if (e.currentTarget.value.length >= 3) {
                      searchdatas(e.currentTarget.value);
                    }
                  }}
                />
              </Grid>
              {/* <Grid item xs={4} md={2} sm={4}>
                      <Box
                        sx={{
                          display: "flex",
                          height: "100%",
                          alignItems: "center",
                        }}
                      >
                        {searchicon ? (
                          <Fab
                            size="small"
                            sx={{
                              color: "white",
                              backgroundColor: "#FF0000",
                              boxShadow: 0,
                              "&:hover": { backgroundColor: "#cc0000" },
                            }}
                            onClick={() => {
                              closeFunctionCall();

                              setsearchicon(false);
                              setsearchdata("");
                            }}
                          >
                            <CloseIcon />
                          </Fab>
                        ) : (
                          <Fab
                            size="small"
                            sx={{
                              color: "white",
                              backgroundColor: "#339fcd",
                              boxShadow: 0,
                              "&:hover": { backgroundColor: "#217396" },
                            }}
                            onClick={() => setsearchicon(true)}
                          >
                            <SearchRoundedIcon />
                          </Fab>
                        )}
                      </Box>
                    </Grid> */}
            </Grid>
            <TableContainer
              component={Paper}
              sx={{ marginTop: "10px", boxShadow: 0, maxHeight: 440 }}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow className="table-row">
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === "full_name"}
                        direction={orderBy === "full_name" ? order : "asc"}
                        onClick={() => handleSort("full_name")}
                      >
                        Pincode
                      </TableSortLabel>
                    </TableCell>
                    <TableCell> Village/Town</TableCell>
                    <TableCell> Area/Street</TableCell>

                    <TableCell> Types of service</TableCell>
                    <TableCell>
                      {" "}
                      {/* <TableSortLabel
                        active={orderBy === "work_status"}
                        direction={orderBy === "work_status" ? order : "asc"}
                        onClick={() => handleSort("work_status")}
                      > */}
                      Need Type
                      {/* </TableSortLabel> */}
                    </TableCell>

                    <TableCell>Status</TableCell>
                    <TableCell align="center"> Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {useApi.length > 0 ? (
                    <>
                      {useApi

                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((item) => (
                          <TableRow key={item._id} hover className="tablecell">
                            <TableCell>{item.pincode}</TableCell>
                            <TableCell align="left">
                              {" "}
                              {item.village_town_name}{" "}
                            </TableCell>
                            <TableCell>{item.area_street_name}</TableCell>

                            <TableCell>{item.type_of_service}</TableCell>
                            <TableCell>{item.need_type}</TableCell>
                            <TableCell>
                              <Chip
                                label={
                                  item.status === "Close"
                                    ? item.statusClosedBy.provider_id ===
                                      allProviderData.provider_id
                                      ? "Closed"
                                      : "Closed By Others"
                                    : item.status
                                }
                                color={
                                  item.status === "Open"
                                    ? "warning"
                                    : item.status === "Assigned"
                                    ? "success"
                                    : "error"
                                }
                                variant="contained"
                              />
                            </TableCell>
                            <TableCell>
                              <Box
                                display={"flex"}
                                justifyContent={"center"}
                                gap={"10px"}
                              >
                                <Tooltip title="Full Details" placement="top">
                                  <IconButton
                                    className="icon-btn"
                                    size="Medium"
                                    onClick={() => {
                                      getOneData(item._id);

                                      handleChange();
                                    }}
                                  >
                                    <OpenInNewRoundedIcon />
                                  </IconButton>
                                </Tooltip>
                                {item.status !== "Close" && (
                                  <Button
                                    variant="contained"
                                    sx={{
                                      textTransform: "capitalize",
                                      borderRadius: "20px",
                                      fontFamily: "Poppins-Regular",
                                      boxShadow: 0,
                                    }}
                                    onClick={() => {
                                      setOpenCloseDialog(true);
                                      setGetEmail(item._id);
                                      setProviderForPut({
                                        ...providerForPut,
                                        name: getOneProvider.contact_person_name,
                                        email_id: getOneProvider.email_id,
                                        provider_id: getOneProvider.provider_id,
                                      });
                                    }}
                                    startIcon={<CloseRoundedIcon />}
                                  >
                                    Close
                                  </Button>
                                )}
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))}
                    </>
                  ) : (
                    <>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>

                        <TableCell>
                          {" "}
                          <Typography
                            variant="body1"
                            sx={{ fontFamily: "Barlow-Bold" }}
                          >
                            NO Data Available
                          </Typography>
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25]}
              component="div"
              count={totalRows}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Box>
      </Box>
      <LocalServiceDialog
        open={open}
        handleChange={handleChange}
        getOne={getOne}
        setOpen={setOpen}
        filterName={filterName}
        setFilterName={setFilterName}
        fetchApi={fetchApi}
        dialogLoad={loading.dialogLoad}
        setGetOne={setGetOne}
      />
      <Dialog open={openCloseDialog} fullWidth>
        <DialogTitle>Confirm</DialogTitle>
        <DialogContent>Are You sure you want to close?</DialogContent>
        <DialogActions>
          {" "}
          <Button
            onClick={() => {
              setOpenCloseDialog(false);
            }}
            variant="outlined"
            sx={{
              backgroundColor: "#f2f2f2",
              textTransform: "lowercase",

              color: "black",
              borderColor: "white",
              fontFamily: "Poppins-Medium",
              "&:hover": {
                textTransform: "lowercase",
                color: "#f2f2f2",
                backgroundColor: "#339fcd",
                borderColor: "#f2f2f2",
                fontFamily: "Poppins-Medium",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="outlined"
            disabled={isLoading}
            sx={{
              textTransform: "lowercase",

              color: "white",
              backgroundColor: "#ed1067",
              borderColor: "#ed1067",
              fontFamily: "Poppins-Medium",
              transition: "all 0.3s",
              "&:hover": {
                textTransform: "lowercase",

                color: "#ed1067",
                borderColor: "#ed1067",
                fontFamily: "Poppins-Medium",
              },
            }}
            onClick={() => {
              updateClose(getEmail);
            }}
          >
            Close
            {isLoading && (
              <CircularProgress
                size={24}
                style={{
                  color: "#ffffff",
                  marginLeft: 12,
                }}
              />
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ProviderDashboard;
