import React, { useState, useEffect } from "react";
import {
  Table,
  TableContainer,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  Card,
  IconButton,
  Tooltip,
  TablePagination,
  Grid,
  Typography,
  TableSortLabel,
  TextField,
  Chip,
  Box,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Fab,
  ClickAwayListener,
  Skeleton,
} from "@mui/material";
import useAxios from "../../../api/useAxios";
import CloseIcon from "@mui/icons-material/Close";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import employerdefaultimg from "../../../img/employerProfile/employerUpdate-logo.png";
import RefreshSharpIcon from "@mui/icons-material/RefreshSharp";
import { saveAs } from "file-saver";

import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
import moment from "moment";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import StatCards from "./StatCards/StatCards";
import SkeletonMlaCard from "./StatCards/SkeletonMlaCard";
import EmployerDialog from "./DialogBoxes/EmployerDialog";
import ExportCS from "./ExportComponents/ExportCS";
import ExportPDF from "./ExportComponents/ExportPDF";
import AutoApproveSnack from "./SnackBars/AutoApproveSnack";
import { useContext } from "react";
import { CurrentStateContext } from "../../../App";

function EmployerData() {
  const [getApi, setGetApi] = useState([]);
  const [useApi, setUseApi] = useState([]);
  const [page, setPage] = useState(0);
  // const [decide, seDecide] = useState("");
  const adminEmployeeSearch = sessionStorage.getItem("admin_employee_search");
  const [totalRows, setTotalRows] = useState(0);
  const [updateEmployerSetting, setUpdateEmployerSetting] = useState({
    noOfHot: 0,
    noOfStandard: 0,
  });
  const [dateenable, setdateenable] = useState(false);
  const [open, setOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [getOne, setGetOne] = useState({});
  const [searchdata, setsearchdata] = useState("");
  const [searchicon, setsearchicon] = useState(false);
  const [loading, setLoading] = useState({ isLoading: true, dialogLoad: true });
  const [filterName, setFilterName] = useState({
    date: "today",
    status: "all",
    isLoading: false,
  });
  let adminDetails_CEO = JSON.parse(localStorage.getItem("admin_dev"));
  const [dateRange, setDateRange] = useState({
    startDate: "",
    endDate: "",
    openTooltip: false,
    openStartTool: false,
  });
  const [error, setError] = useState(null);
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const [startdisabled, setstartdisabled] = useState(false);
  const [enddisabled, setenddisabled] = useState(false);
  const snack = JSON.parse(sessionStorage.getItem("employer-setting"));
  const [openSnack, setOpenSnack] = useState(snack);
  const { getAdminSetting } = useContext(CurrentStateContext);
  // <!----SORt--->
  const [sortedData, setSortedData] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");

  const todate = moment();
  const todayDate = moment().format("DD-MM-YYYY");
  const yesterdayDate = moment().subtract(1, "day").format("DD-MM-YYYY");

  // <!---SORT FUNCTION-->
  const [starterror, setstartError] = useState(null);
  const [enderror, setendError] = useState(null);
  const minDate = moment("01-06-2023", "DD-MM-YYYY");
  const maxDate = moment();
  const starterrorMessage = React.useMemo(() => {
    switch (starterror) {
      case "maxDate": {
        return `Please select a date before the end date`;
      }
      case "minDate": {
        return `Please select a date before the end date`;
      }
      case "invalidDate": {
        return "Invalid date range";
      }
      default: {
        return "";
      }
    }
  }, [starterror]);
  const enderrorMessage = React.useMemo(() => {
    switch (enderror) {
      case "maxDate": {
        return `Please select today date or before the today date`;
      }
      case "minDate": {
        return `Please select a date after the start date`;
      }
      case "invalidDate": {
        return "Invalid date range";
      }
      default: {
        return "";
      }
    }
  }, [enderror]);
  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    const sortedData = getApi.sort((a, b) => {
      const valueA = a[property];
      const valueB = b[property];
      // Sort the rows with the same value in the primary column before others
      if (valueA === valueB) {
        return -1;
      }
      // Sort the rows based on the selected column
      if (valueA < valueB) {
        return isAsc ? -1 : 1;
      }
      if (valueA > valueB) {
        return isAsc ? 1 : -1;
      }
      return 0;
    });
    setSortedData(sortedData);
  };

  const handleChange = () => {
    setOpen(!open);
    setGetOne({});
    setLoading({ ...loading, dialogLoad: true });
  };

  const axiosData = useAxios();
  const baseUrl = axiosData.defaults.baseURL;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // <!-----API FUNCTIONS---->
  const getOneData = async (id) => {
    try {
      const getOneAxios = await axiosData.get(`employers/${id}`);
      const { data } = getOneAxios;

      setGetOne(data);
      setUpdateEmployerSetting({
        ...updateEmployerSetting,
        noOfHot: data.no_of_hot_posting,
        noOfStandard: data.no_of_Standard_posting,
      });
      setLoading({ ...loading, dialogLoad: false });
    } catch (err) {
      console.log("Error", err);
    }
  };
  // <!----GET--->

  const fetchApi = async () => {
    try {
      const useAxiosData = await axiosData.get("employers");
      const { data } = useAxiosData;
      if (useAxiosData.status === 204) {
        setGetApi([]);
      } else {
        data.reverse();
        let bool = false;
        if (adminEmployeeSearch !== null) {
          bool = adminEmployeeSearch.length > 0;
        }
        if (true === bool) {
          searchdatas(adminEmployeeSearch, data);
          setGetApi(data);
          setsearchicon(true);
        } else {
          setGetApi(data);
          getFilterValue(filterName.status, filterName.date, data);
        }
      }
      // setGetApi(data);
      // getFilterValue(filterName.status, filterName.date, data);
      setLoading({ ...loading, isLoading: false });
    } catch (err) {
      console.log("Error", err);
    }
  };
  // <!---PUT--->

  const downloadResume = (mobile, name) => {
    saveAs(`${baseUrl}employers/proof/${mobile}`, `Proof of ${name}`);
  };
  const getFilterValue = (statusvalue, value, alldatas) => {
    if (value === "daterange") {
      const get = alldatas.filter((i) => i.createdDate === todayDate);
      statusForAll(statusvalue, get);
      setdateenable(true);
    } else if (value === "today") {
      const get = alldatas.filter((i) => i.createdDate === todayDate);
      statusForAll(statusvalue, get);
    } else if (value === "yesterday") {
      const yes = alldatas.filter((i) => i.createdDate === yesterdayDate);
      statusForAll(statusvalue, yes);
    } else if (value === "week") {
      const startWeek = moment().subtract(1, "weeks").startOf("week");
      const endWeek = moment().subtract(1, "weeks").endOf("week");
      function getDatesInRange(startDate, endDate) {
        const date = new Date(startDate.getTime());
        const dates = [];
        while (date <= endDate) {
          dates.push(moment(date).format("MM/DD/YYYY"));
          date.setDate(date.getDate() + 1);
        }
        return dates;
      }
      const d1 = new Date(startWeek);
      const d2 = new Date(endWeek);

      const weekvalue = getDatesInRange(d1, d2);

      const resDates = weekvalue.map((getdate) => {
        const getDate2 = getdate;
        const getDate3 = getDate2
          .split("/")
          .map((e) => (e[0] === "0" ? e.slice() : e));
        const getDateFormat =
          getDate3[1] + "-" + getDate3[0] + "-" + getDate3[2];

        return getDateFormat;
      });

      const dataapp = [];
      resDates.map((datas) => {
        const dd = alldatas.filter((ite) => ite.createdDate === datas);
        const filterdat = dd.map((item) => dataapp.push(item));
        return filterdat;
      });

      const week = dataapp;

      statusForAll(statusvalue, week);
    } else if (value === "month") {
      var currentMonth = moment(todate).format("MM");
      var currentyear = moment(todate).format("YYYY");

      const minsmonth = currentMonth - 1;
      if (currentMonth === "01") {
        var call = currentyear - 1;
        const endOfMonth = moment(
          `${currentyear}-${currentMonth}`,
          "YYYY-MM"
        ).endOf("month");
        const endofdate = endOfMonth.format("DD-MM-YYYY");
        const mon = alldatas.filter((item) =>
          moment(item.createdDate).isBetween(
            `01-12-${call}`,
            endofdate,
            null,
            "[]"
          )
        );
        statusForAll(statusvalue, mon);
      } else {
        const endOfMonth = moment(
          `${currentyear}-${minsmonth}`,
          "DD-MM-YYYY"
        ).endOf("month");
        const startOfMonth = moment(
          `${currentyear}-${minsmonth}`,
          "YYYY-MM"
        ).startOf("month");
        const startOfdate = startOfMonth.format("DD-MM-YYYY");
        const date1 = moment(startOfdate, "DD-MM-YYYY");
        const endofdate = endOfMonth.format("DD-MM-YYYY");
        const date2 = moment(endofdate, "DD-MM-YYYY");
        const mon = alldatas.filter((item) => {
          const date = moment(item.createdDate, "DD-MM-YYYY");
          return moment(date).isBetween(date1, date2, null, "[]");
        });
        statusForAll(statusvalue, mon);
      }
    } else if (value === "Total") {
      statusForAll(statusvalue, alldatas);
    }
  };
  const statusForAll = (statusvalue, get) => {
    if (statusvalue === "all") {
      const data = [...get];
      setUseApi(data);
      setTotalRows(data.length);
      setPage(0);
    } else if (
      statusvalue === "A" ||
      statusvalue === "P" ||
      statusvalue === "D"
    ) {
      const today = get.filter((i) => i.status === statusvalue);
      const data = today;
      setUseApi(data);
      setTotalRows(data.length);
      setPage(0);
    }
  };
  // const handleStartDateChange = (event) => {
  //   const newStartDate = moment(event);

  //   const yearOfStart = newStartDate.year();
  //   setStartDate(newStartDate);
  //   const isStartDateBeforeEndDate = newStartDate.isBefore(endDate);
  //   const isStartDateAfterEndDate = newStartDate.isAfter(endDate);
  //   if (
  //     isStartDateBeforeEndDate === true &&
  //     isStartDateAfterEndDate === false &&
  //     yearOfStart > 2022
  //   ) {
  //     setStartDate(newStartDate);
  //     setdisabled(false);
  //     setDateRange({
  //       ...dateRange,
  //       startDate: " ",
  //       openStartTool: false,
  //     });
  //   } else if (
  //     isStartDateBeforeEndDate === false &&
  //     isStartDateAfterEndDate === false &&
  //     newStartDate === endDate &&
  //     newStartDate !== endDate &&
  //     yearOfStart > 2022
  //   ) {
  //     setStartDate(newStartDate);
  //     setdisabled(false);
  //     setDateRange({
  //       ...dateRange,
  //       startDate: " ",
  //       openStartTool: false,
  //     });
  //   } else if (
  //     isStartDateBeforeEndDate === false &&
  //     isStartDateAfterEndDate === false
  //   ) {
  //     setStartDate(newStartDate);
  //     setdisabled(false);
  //     setDateRange({
  //       ...dateRange,
  //       startDate: " ",
  //       openStartTool: false,
  //       openTooltip: false,
  //     });
  //   } else if (
  //     isStartDateBeforeEndDate === true &&
  //     isStartDateAfterEndDate === false &&
  //     yearOfStart < 2000
  //   ) {
  //     setdisabled(true);
  //     setDateRange({
  //       ...dateRange,
  //       startDate: "Start date is too old",
  //       openStartTool: true,
  //     });
  //   } else if (
  //     isStartDateBeforeEndDate === false &&
  //     isStartDateAfterEndDate === true &&
  //     newStartDate !== endDate &&
  //     yearOfStart > 2022
  //   ) {
  //     setdisabled(true);
  //     setDateRange({
  //       ...dateRange,
  //       startDate: "Start date must be before the end date",
  //       openStartTool: true,
  //     });
  //   } else if (
  //     isStartDateBeforeEndDate === false &&
  //     isStartDateAfterEndDate === true &&
  //     newStartDate !== endDate &&
  //     yearOfStart > 2022
  //   ) {
  //     setdisabled(true);
  //     setDateRange({
  //       ...dateRange,
  //       startDate: "",
  //       openStartTool: true,
  //     });
  //   }
  // };
  // const handleEndDateChange = (event) => {
  //   const newEndDate = moment(event);
  //   const todayDate = moment();
  //   const yearOfEnd = newEndDate.year();
  //   setEndDate(newEndDate);

  //   const isStartDateBeforeEndDate = newEndDate.isBefore(startDate);
  //   const isStartDateAfterEndDate = newEndDate.isAfter(startDate);
  //   const istodayDateAfterEndDate = newEndDate.isAfter(todayDate);
  //   if (
  //     isStartDateBeforeEndDate === false &&
  //     isStartDateAfterEndDate === true &&
  //     istodayDateAfterEndDate === true
  //   ) {
  //     setdisabled(true);
  //     setDateRange({
  //       ...dateRange,
  //       endDate: "End date must be before today date",
  //       openTooltip: true,
  //     });
  //   } else if (
  //     isStartDateBeforeEndDate === true &&
  //     isStartDateAfterEndDate === false
  //   ) {
  //     setdisabled(true);
  //     setDateRange({
  //       ...dateRange,
  //       endDate: "End date must be  after start date ",
  //       openTooltip: true,
  //     });
  //   } else if (
  //     isStartDateBeforeEndDate === false &&
  //     isStartDateAfterEndDate === true &&
  //     yearOfEnd > 2022
  //   ) {
  //     setEndDate(newEndDate);
  //     setdisabled(false);
  //     setDateRange({
  //       ...dateRange,
  //       openTooltip: false,
  //       endDate: "",
  //     });
  //     handleToolClose();
  //   } else if (
  //     isStartDateBeforeEndDate === false &&
  //     isStartDateAfterEndDate === true &&
  //     yearOfEnd < 2000
  //   ) {
  //     setdisabled(true);
  //     setDateRange({
  //       ...dateRange,
  //       endDate: "End date too old ",
  //       openTooltip: true,
  //     });
  //   }
  // };
  const handleStartDateChange = (date) => {
    if (searchicon === true) {
      setsearchicon(false);
      setsearchdata("");
      sessionStorage.setItem("admin_employee_search", "");
    }
    if (startDate === null) {
      setstartdisabled(false);
    }
    setStartDate(date);
    setError(null);
  };

  const handleEndDateChange = (date) => {
    if (searchicon === true) {
      setsearchicon(false);
      setsearchdata("");
      sessionStorage.setItem("admin_employee_search", "");
    }
    if (endDate === null) {
      setenddisabled(false);
    }
    setEndDate(date);
    setError(null);
  };
  const handleToolClose = () => {
    setDateRange({
      ...dateRange,
      openTooltip: false,
      endDate: "",
    });
  };
  const handleToolStartClose = () => {
    setDateRange({
      ...dateRange,
      startDate: "",
      openStartTool: false,
    });
  };

  const handleClickFilter = (statusvalue) => {
    const filteredData = getApi.filter((d) => {
      const date = moment(d.createdDate, "DD-MM-YYYY");
      const start = moment(startDate).subtract(1, "days");
      return date.isBetween(start, endDate, null, "[]");
    });
    statusForAll(statusvalue, filteredData);
  };
  const refreshData = () => {
    setsearchdata("");
    setStartDate(moment());
    setEndDate(moment());
    setFilterName({
      ...filterName,
      date: "today",
      status: "all",
    });
    setendError(null);
    setstartError(null);
    setstartdisabled(false);
    setenddisabled(false);
    getFilterValue("all", "today", getApi);
  };
  const headers = [
    [
      "Company Name",
      "Industry",
      "District",
      "Contact Person",
      "Designation",
      "Email Id",
      "Mobile Number",
      "Company URL",
      "Company Address",
      "City",
      "Pincode",
    ],
  ];

  const datas = useApi.map((user) => {
    return [
      user.company_name,
      user.industry,
      user.district,
      user.contact_person,
      user.designation,
      user.email_id,
      user.mobile_number,
      user.company_url,
      user.company_address,
      user.city,
      user.pincode,
    ];
  });
  const selectedApiForExcel = useApi.map((i) => {
    return {
      "Company Name": i.company_name,
      Email: i.email_id,
      Mobile: i.mobile_number,
      Industry: i.industry,
      "Contact Person": i.contact_person,
      District: i.district,
      Designation: i.designation,
      "Company URL": i.company_url,
      "Company Address": i.company_address,
      "Established Year": i.established_year,
    };
  });

  useEffect(() => {
    fetchApi();
    handleSort(sortedData);
  }, []);
  const searchdatas = (searchdata, getApi) => {
    const datas = getApi.filter((item) => {
      const searchtext = new RegExp(searchdata, "i");
      const outputString = searchdata.replace(/\s/g, "");
      const searchtext2 = new RegExp(outputString, "i");
      var valuess =
        item.company_name.match(searchtext) ||
        item.email_id.match(searchtext2) ||
        item.district.match(searchtext2);
      // item.mlaConstituency.match(searchtext2) ||
      // item.mpConstituency.match(searchtext2);
      if (valuess != null) {
        return item;
      }
    });
    setUseApi(datas);
    setTotalRows(datas.length);
    setPage(0);
  };
  useEffect(() => {
    let timeout;
    if (dateRange.openStartTool || dateRange.openTooltip) {
      timeout = () => {
        dateRange.openStartTool
          ? handleToolStartClose()
          : dateRange.openTooltip
          ? handleToolClose()
          : !dateRange.openStartTool
          ? handleToolClose()
          : handleToolClose();
      };
    }
    return () => clearTimeout(timeout);
  }, [dateRange.openStartTool, dateRange.openTooltip]);
  if (!loading.isLoading) {
    return (
      <>
        {getAdminSetting.employerApproval === true && openSnack === true ? (
          <>
            <AutoApproveSnack
              openSnack={openSnack}
              setOpenSnack={setOpenSnack}
              full_name={"Employer"}
              snackOption={"employer-setting"}
            />
          </>
        ) : null}
        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <Card
            sx={{ borderRadius: "15px", boxShadow: "3px 3px 10px 3px #dddddd" }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                padding: "10px",
              }}
            >
              <h3 className="seeker-text">Employers Data</h3>
            </Box>
          </Card>
          <Card>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: "20px",
              }}
            >
              <StatCards getApi={getApi} />
            </Box>
          </Card>

          <Card sx={{ padding: "20px" }}>
            <Paper>
              <Box
                sx={{
                  padding: "20px",
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                  backgroundColor: "#f2f2f2",
                }}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} md={1.7}>
                    <FormControl
                      sx={{ width: { md: "100%", xs: "100%", sm: "100%" } }}
                    >
                      <InputLabel>Filter By Status</InputLabel>
                      <Select
                        variant="standard"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={filterName.status}
                        onChange={(e) => {
                          if (searchicon === true) {
                            setsearchicon(false);
                            setsearchdata("");
                            sessionStorage.setItem("admin_employee_search", "");
                          }
                          if (dateenable) {
                            handleClickFilter(e.target.value);
                          } else {
                            getFilterValue(
                              e.target.value,
                              filterName.date,
                              getApi
                            );
                          }
                          setFilterName({
                            ...filterName,
                            status: e.target.value,
                          });
                        }}
                      >
                        <MenuItem value={"all"}>All</MenuItem>

                        <MenuItem value={"A"}>Approved</MenuItem>
                        <MenuItem value={"P"}>Pending</MenuItem>
                        <MenuItem value={"D"}>Decline</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  {dateenable === false ? (
                    <Grid item xs={12} sm={6} md={1.7}>
                      {" "}
                      <FormControl
                        sx={{ width: { md: "100%", xs: "100%", sm: "100%" } }}
                      >
                        <InputLabel>Filter By Date</InputLabel>
                        <Select
                          variant="standard"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={filterName.date}
                          onChange={(e) => {
                            if (searchicon === true) {
                              setsearchicon(false);
                              setsearchdata("");
                              sessionStorage.setItem(
                                "admin_employee_search",
                                ""
                              );
                            }
                            setFilterName({
                              ...filterName,
                              date: e.target.value,
                            });
                            getFilterValue(
                              filterName.status,
                              e.target.value,
                              getApi
                            );
                          }}
                        >
                          <MenuItem value="Total">Total</MenuItem>
                          <MenuItem value={"today"}>Today</MenuItem>
                          <MenuItem value={"yesterday"}>Yesterday</MenuItem>
                          <MenuItem value={"week"}>Last Week</MenuItem>
                          <MenuItem value={"month"}>Last month</MenuItem>
                          <MenuItem value="daterange">Select By Date</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  ) : null}
                  {dateenable ? (
                    <Grid item xs={12} sm={6} md={1.7}>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        {/* <ClickAwayListener onClickAway={handleToolStartClose}>
                          <Tooltip
                            PopperProps={{
                              disablePortal: true,
                              sx: {
                                "& .MuiTooltip-tooltip": {
                                  backgroundColor: "#FF0000",
                                },
                              },
                            }}
                            open={dateRange.openStartTool}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            onClose={
                              dateRange.openStartTool === false ||
                              dateRange.openTooltip === false ||
                              handleToolStartClose
                            }
                            title={dateRange.startDate}
                            leaveDelay={1}
                            arrow
                          > */}
                        <Box>
                          <DatePicker
                            label="Start Date"
                            value={startDate}
                            format="DD-MM-YYYY"
                            onChange={handleStartDateChange}
                            onError={(newError) => {
                              setstartError(newError);
                              const boolenvalue =
                                newError === "invalidDate" ||
                                newError === "maxDate" ||
                                newError === "minDate" ||
                                (starterror === null && startDate === null)
                                  ? true
                                  : false;

                              setstartdisabled(boolenvalue);
                            }}
                            slotProps={{
                              textField: {
                                helperText: starterrorMessage,
                                onFocus: () => {
                                  if (
                                    starterror === null &&
                                    startDate === null
                                  ) {
                                    setStartDate(moment());
                                    setstartdisabled(false);
                                  }
                                },
                              },
                            }}
                            // minDate={minDate}
                            maxDate={endDate || maxDate}
                          />
                        </Box>
                        {/* </Tooltip>
                        </ClickAwayListener> */}
                      </LocalizationProvider>
                    </Grid>
                  ) : null}

                  {dateenable ? (
                    <Grid item xs={12} sm={6} md={1.7}>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        {/* <ClickAwayListener onClickAway={handleToolClose}>
                          <Tooltip
                            PopperProps={{
                              disablePortal: true,
                              sx: {
                                "& .MuiTooltip-tooltip": {
                                  backgroundColor: "#FF0000",
                                },
                              },
                            }}
                            open={dateRange.openTooltip}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            onClose={
                              dateRange.openStartTool === false ||
                              dateRange.openTooltip === false ||
                              handleToolClose
                            }
                            title={dateRange.endDate}
                            leaveDelay={1}
                            arrow
                          > */}
                        <Box>
                          <DatePicker
                            label="End Date"
                            value={endDate}
                            format="DD-MM-YYYY"
                            onChange={handleEndDateChange}
                            onError={(newError) => {
                              setendError(newError);
                              const bollenerror =
                                newError === "invalidDate" ||
                                newError === "minDate" ||
                                newError === "maxDate" ||
                                (enderror === null && endDate === null)
                                  ? true
                                  : false;

                              setenddisabled(bollenerror);
                            }}
                            slotProps={{
                              textField: {
                                helperText: enderrorMessage,
                                onFocus: () => {
                                  if (enderror === null && endDate === null) {
                                    setEndDate(moment());
                                    setenddisabled(false);
                                  }
                                },
                              },
                            }}
                            // minDate={startDate || minDate}
                            maxDate={maxDate}
                            minDate={startDate}
                          />
                        </Box>
                        {/* </Tooltip>
                        </ClickAwayListener> */}
                      </LocalizationProvider>
                    </Grid>
                  ) : null}
                  <Grid item xs={12} md={1.7} sm={4} alignItems="center">
                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      {dateenable ? (
                        <>
                          <Tooltip title="Filter" placement="bottom">
                            <Fab
                              disabled={
                                startdisabled === true || enddisabled === true
                                  ? true
                                  : false
                              }
                              size="small"
                              sx={{
                                color: "white",
                                backgroundColor: "#339fcd",
                                boxShadow: 0,
                                "&:hover": { backgroundColor: "#217396" },
                              }}
                              onClick={() => {
                                handleClickFilter(filterName.status);
                              }}
                            >
                              <CheckRoundedIcon />
                            </Fab>
                          </Tooltip>
                          <Tooltip title="Close" placement="bottom">
                            <Fab
                              size="small"
                              sx={{
                                color: "white",
                                backgroundColor: "#339fcd",
                                boxShadow: 0,
                                "&:hover": { backgroundColor: "#217396" },
                              }}
                              onClick={() => {
                                setdateenable(false);
                                refreshData();
                              }}
                            >
                              <CloseRoundedIcon />
                            </Fab>
                          </Tooltip>
                        </>
                      ) : null}
                      <Tooltip title="Refresh" placement="bottom">
                        <Fab
                          size="small"
                          sx={{
                            color: "white",
                            backgroundColor: "#339fcd",
                            boxShadow: 0,
                            "&:hover": { backgroundColor: "#217396" },
                          }}
                          onClick={() => {
                            refreshData();
                          }}
                        >
                          <RefreshSharpIcon />
                        </Fab>
                      </Tooltip>
                    </Box>
                  </Grid>
                  <Grid item xs={8} md={1.7} sm={4}>
                    {searchicon ? (
                      <TextField
                        label="Search"
                        variant="outlined"
                        value={searchdata ? searchdata : adminEmployeeSearch}
                        onKeyDown={(e) =>
                          [
                            "(",
                            ")",
                            "^",
                            "[",
                            "]",
                            "*",
                            "<",
                            ">",
                            "\\",
                          ].includes(e.key) && e.preventDefault()
                        }
                        onChange={(e) => {
                          setsearchdata(e.currentTarget.value);
                          sessionStorage.setItem(
                            "admin_employee_search",
                            e.currentTarget.value
                          );
                          if (e.currentTarget.value.length === 0) {
                            setUseApi(getApi);
                            setTotalRows(getApi.length);
                            setPage(0);
                          }
                          if (e.currentTarget.value.length >= 3) {
                            searchdatas(e.currentTarget.value, getApi);
                          }
                        }}
                      />
                    ) : (
                      <>
                        <Box display="flex">
                          {adminDetails_CEO?.emailId ===
                          process.env
                            .REACT_APP_EMAILID_FOR_ADMIN_DEVELOPER ? null : (
                            <Tooltip title="Export As Excel" placement="bottom">
                              <ExportCS
                                selectedApiForExcel={selectedApiForExcel}
                                fileName={"Employer-Data"}
                              />
                              {/* <IconButton size="small" onClick={exportToCSV}>
                              <img src={xls} alt="" />
                            </IconButton> */}
                            </Tooltip>
                          )}
                          {adminDetails_CEO?.emailId ===
                          process.env
                            .REACT_APP_EMAILID_FOR_ADMIN_DEVELOPER ? null : (
                            <Tooltip title="Export As PDF" placement="bottom">
                              <ExportPDF
                                headers={headers}
                                datas={datas}
                                fileName={"Employer-Data"}
                              />
                            </Tooltip>
                          )}
                        </Box>
                      </>
                    )}
                  </Grid>
                  <Grid item xs={4} md={1.7} sm={4}>
                    <Box
                      sx={{
                        display: "flex",
                        height: "100%",
                        alignItems: "center",
                      }}
                    >
                      {" "}
                      {searchicon ? (
                        <Fab
                          size="small"
                          sx={{
                            color: "white",
                            backgroundColor: "#FF0000",
                            boxShadow: 0,
                            "&:hover": { backgroundColor: "#cc0000" },
                          }}
                          onClick={() => {
                            setsearchicon(false);
                            setsearchdata("");
                            sessionStorage.setItem("admin_employee_search", "");
                            getFilterValue(
                              filterName.status,
                              filterName.date,
                              getApi
                            );
                          }}
                        >
                          <CloseIcon />
                        </Fab>
                      ) : (
                        <Fab
                          size="small"
                          sx={{
                            color: "white",
                            backgroundColor: "#339fcd",
                            boxShadow: 0,
                            "&:hover": { backgroundColor: "#217396" },
                          }}
                          onClick={() => setsearchicon(true)}
                        >
                          <SearchRoundedIcon />
                        </Fab>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow className="table-row">
                    <TableCell>Profile</TableCell>
                    <TableCell>
                      {" "}
                      <TableSortLabel
                        active={orderBy === "company_name"}
                        direction={orderBy === "company_name" ? order : "asc"}
                        onClick={() => handleSort("company_name")}
                      >
                        Company Name
                      </TableSortLabel>
                    </TableCell>
                    <TableCell> Industry</TableCell>

                    <TableCell> Created Date</TableCell>
                    <TableCell> Email</TableCell>
                    <TableCell> District</TableCell>
                    <TableCell align="center">
                      <TableSortLabel
                        active={orderBy === "company_name"}
                        direction={orderBy === "company_name" ? order : "asc"}
                        onClick={() => handleSort("company_name")}
                      >
                        Status
                      </TableSortLabel>
                    </TableCell>
                    <TableCell> Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {useApi.length > 0 ? (
                    <>
                      {useApi
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((item) => (
                          <TableRow key={item._id} hover>
                            <TableCell>
                              <img
                                src={`${baseUrl}employers/logo/email/${item.email_id}/Admin`}
                                onError={(e) => {
                                  e.target.src = employerdefaultimg;
                                }}
                                alt="img"
                                height={"50"}
                                width="60"
                                //  style={{ clipPath: "circle(50%)" }}
                              />
                            </TableCell>
                            <TableCell>{item.company_name}</TableCell>
                            <TableCell> {item.industry} </TableCell>
                            <TableCell>{item.createdDate}</TableCell>
                            <TableCell>{item.email_id}</TableCell>
                            <TableCell> {item.district}</TableCell>
                            <TableCell align="center">
                              <Tooltip
                                title={
                                  item.status === "A"
                                    ? "Approved"
                                    : item.status === "D"
                                    ? "Declined"
                                    : "Pending"
                                }
                                placement="right"
                              >
                                <Chip
                                  label={item.status}
                                  color={
                                    item.status === "P"
                                      ? "warning"
                                      : item.status === "A"
                                      ? "success"
                                      : "error"
                                  }
                                  variant="contained"
                                />
                              </Tooltip>
                            </TableCell>

                            <TableCell>
                              <Tooltip title="Full Details" placement="top">
                                <IconButton
                                  className="icon-btn"
                                  size="Medium"
                                  onClick={() => {
                                    getOneData(item._id);
                                    handleChange();
                                  }}
                                >
                                  <OpenInNewRoundedIcon />
                                </IconButton>
                              </Tooltip>
                              {adminDetails_CEO?.emailId ===
                              process.env
                                .REACT_APP_EMAILID_FOR_ADMIN_DEVELOPER ? null : (
                                <Tooltip title="Download Proof" placement="top">
                                  <IconButton
                                    className="icon-btn"
                                    onClick={() =>
                                      downloadResume(
                                        item.mobile_number,
                                        item.company_name
                                      )
                                    }
                                    size="medium"
                                    disabled={!item.proofUpload}
                                  >
                                    <FileDownloadIcon />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                    </>
                  ) : (
                    <>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell>
                          {" "}
                          <Typography
                            variant="body1"
                            sx={{ fontFamily: "Barlow-Bold" }}
                          >
                            NO Data Available
                          </Typography>
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </>
                  )}
                </TableBody>
              </Table>{" "}
              <TablePagination
                rowsPerPageOptions={[10, 25]}
                component="div"
                count={totalRows}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          </Card>
          <EmployerDialog
            open={open}
            handleChange={handleChange}
            getOne={getOne}
            setOpen={setOpen}
            filterName={filterName}
            setFilterName={setFilterName}
            fetchApi={fetchApi}
            dialogLoad={loading.dialogLoad}
            setGetOne={setGetOne}
            getOneData={getOneData}
            updateEmployerSetting={updateEmployerSetting}
            setUpdateEmployerSetting={setUpdateEmployerSetting}
          />
        </Box>
      </>
    );
  } else {
    return (
      <>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <Card sx={{ padding: "20px", borderRadius: "20px" }}>
            <Skeleton
              animation="wave"
              variant="text"
              width={230}
              height={40}
              sx={{ borderRadius: "15px" }}
            />
          </Card>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            <Card
              sx={{
                borderRadius: "20px",

                padding: "20px",
              }}
            >
              <SkeletonMlaCard />
            </Card>
          </Box>
          <Card
            sx={{
              borderRadius: "20px",

              padding: "20px",

              height: "500px",
              gap: "20px",
            }}
          >
            <Skeleton variant="rounded" />
            <Skeleton variant="rounded" width="100%" height="300px" />
          </Card>
        </Box>
      </>
    );
  }
}

export default EmployerData;
