import axios from "axios";
import { useEffect, useState } from "react";

export const useLocationFind = () => {
  const [locationData, setLocationData] = useState(null);
  const [locatioError, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const position = await getCurrentPosition();
        const { latitude, longitude } = position.coords;
        const locationName = await getLocationName(latitude, longitude);
        setLocationData(locationName);
      } catch (error) {
        console.error("Error getting user location:", error);
        setError(error);
      }
    };

    const getCurrentPosition = () =>
      new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });

    const getLocationName = async (latitude, longitude) => {
      try {
        const response = await axios.get(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?types=poi&access_token=pk.eyJ1IjoiZGhpdnlhMzM5OSIsImEiOiJjbG9qcGJ1NjMxeXZsMmttZWY4MWMzY3NqIn0.dE8R-_vKBUlPc_YjNmjmLw`
        );
        console.log("Location data:", response.data);
        const locationDetails = {
          area: response.data.features[0].context[0].text,
          pincode: response.data.features[0].context[1].text,
          state: response.data.features[0].context[5].text,
          country: response.data.features[0].context[6].text,
          district: response.data.features[0].context[4].text,
        };

        localStorage.setItem("currentLocationArea", locationDetails.area);
        localStorage.setItem("currentLocationPincode", locationDetails.pincode);
        localStorage.setItem("currentLocationState", locationDetails.state);
        localStorage.setItem("currentLocationCountry", locationDetails.country);
        localStorage.setItem(
          "currentLocationDistrict",
          locationDetails.district
        );

        return locationDetails;
      } catch (error) {
        console.error("Error fetching location details:", error);
        throw error;
      }
    };

    const storedArea = localStorage.getItem("currentLocationArea");
    const storedPincode = localStorage.getItem("currentLocationPincode");

    fetchData();
  }, []); // Empty dependency array to run the effect only once

  return { locationData, locatioError };
};
