import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { ROUTINGDATA } from "../ROUTINGDATA";
import useAxios from "../../api/useAxios";
import YouTubeIcon from "@mui/icons-material/YouTube";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import Container from "@mui/material/Container";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import logo from "../../img/logo_for-landingpage-3.png";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { CurrentStateContext } from "../../App";
import "./TermsAndCondition.css";
import { ReactComponent as TwitterX } from "../../img/twitter-x-seeklogo.com-4.svg";

const PrivacyFooter = () => {
  const axiosData = useAxios();
  const { languagetranslator } = useContext(CurrentStateContext);
  const { t, i18n, ready } = useTranslation();

  const navigate = useNavigate();

  const changeLanguage = (languagetranslator) => {
    i18n.changeLanguage(languagetranslator);
  };
  useEffect(() => {
    changeLanguage(languagetranslator);
  }, [languagetranslator]);

  const lang = sessionStorage.getItem("lang");

  if (ready) {
    return (
      <div>
        <div id="footer" className="footer-landing">
          <div className="footer-contectbox">
            <div className="footersidecontect">
              <div className="footerlogo footerbox">
                <div className="headerlogoimg-footer">
                  <img
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate(ROUTINGDATA.LAND);
                    }}
                    src={logo}
                    alt="img"
                  />
                </div>
                <div>
                  <p>{t("Sub_Title")}</p>
                </div>
                <div className="footermediaicons">
                  <div className="connectwithus">{t("Connect_with_us")}</div>
                  <div className="footer-landing-icon">
                    <div className="footer-icon">
                      <a
                        href="https://www.linkedin.com/company/velaivendum-com/"
                        target="_blank"
                      >
                        <LinkedInIcon
                          className="footer-icon-hover-5"
                          sx={{
                            padding: "8px",
                            backgroundColor: "white",
                            color: "#0077b5",
                            fontSize: "25px",
                            borderRadius: "50%",
                            transition: "0.5s",
                            cursor: "pointer",
                          }}
                        />
                      </a>
                      <a
                        href="https://www.youtube.com/channel/UCDWhilQYlVkMVhy_fEMjufw"
                        target="_blank"
                      >
                        <YouTubeIcon
                          className="footer-icon-hover-2"
                          sx={{
                            padding: "8px",
                            backgroundColor: "white",
                            color: "#ff0000",
                            fontSize: "25px",
                            borderRadius: "50%",
                            transition: "0.5s",
                            cursor: "pointer",
                          }}
                        />
                      </a>
                      <a
                        href="https://www.facebook.com/profile.php?id=61551213240226"
                        target="_blank"
                      >
                        <FacebookIcon
                          className="footer-icon-hover-1"
                          sx={{
                            padding: "8px",
                            backgroundColor: "white",
                            color: "#1877f2",
                            fontSize: "25px",
                            borderRadius: "50%",
                            transition: "0.5s",
                            cursor: "pointer",
                          }}
                        />
                      </a>

                      <a href="https://twitter.com/velaivendum" target="_blank">
                        <TwitterX
                          className="footer-icon-hover-3"
                          style={{
                            padding: "8px",
                            backgroundColor: "white",
                            color: "white",
                            fontSize: "25px",
                            width: "26px",
                            height: "26px",
                            borderRadius: "50%",
                            transition: "0.5s",
                            cursor: "pointer",
                          }}
                        />
                      </a>
                      <a
                        href="https://www.instagram.com/velaivendum/"
                        target="_blank"
                      >
                        <InstagramIcon
                          className="footer-icon-hover-4"
                          sx={{
                            padding: "8px",
                            backgroundColor: "white",
                            color: "#ed1067",
                            fontSize: "25px",
                            borderRadius: "50%",
                            transition: "0.5s",
                            cursor: "pointer",
                          }}
                        />
                      </a>
                    </div>
                  </div>
                  <div>
                    <div>
                      <h3>Legal</h3>
                    </div>
                    <text
                      onClick={() => {
                        navigate(
                          `${ROUTINGDATA.LAND}${ROUTINGDATA.TERMSANDCONDITION}`
                        );
                        window.scrollTo(0, 0);
                      }}
                      className="legalFooter"
                    >
                      Terms & Conditions
                    </text>{" "}
                    |
                    <text
                      onClick={() => {
                        navigate(
                          `${ROUTINGDATA.LAND}${ROUTINGDATA.PrivacyPolicy}`
                        );
                        window.scrollTo(0, 0);
                      }}
                      style={{ marginLeft: "5px" }}
                      className="legalFooter"
                    >
                      Privacy Policy
                    </text>{" "}
                    |
                    <text
                      onClick={() => {
                        navigate(
                          `${ROUTINGDATA.LAND}${ROUTINGDATA.PurchasePolicy}`
                        );
                        window.scrollTo(0, 0);
                      }}
                      style={{ marginLeft: "5px" }}
                      className="legalFooter"
                    >
                      Purchase Policy
                    </text>{" "}
                    |
                    <text
                      onClick={() => {
                        navigate(
                          `${ROUTINGDATA.LAND}${ROUTINGDATA.RefundPolicy}`
                        );
                        window.scrollTo(0, 0);
                      }}
                      style={{ marginLeft: "5px" }}
                      className="legalFooter"
                    >
                      Refund Policy
                    </text>{" "}
                    |
                    <text
                      onClick={() => {
                        navigate(
                          `${ROUTINGDATA.LAND}${ROUTINGDATA.DeliveryPolicy}`
                        );
                        window.scrollTo(0, 0);
                      }}
                      style={{ marginLeft: "5px" }}
                      className="legalFooter"
                    >
                      Delivery Policy
                    </text>{" "}
                    |
                    <text
                      onClick={() => {
                        navigate(
                          `${ROUTINGDATA.LAND}${ROUTINGDATA.PricingPolicy}`
                        );
                        window.scrollTo(0, 0);
                      }}
                      style={{ marginLeft: "5px" }}
                      className="legalFooter"
                    >
                      Pricing Policy
                    </text>
                  </div>
                </div>
              </div>
              <div className="footercenterlinks">
                <div>
                  <h5>{t("quick_links")}</h5>
                </div>
                <div className="footerlinks">
                  <text
                    onClick={() => {
                      navigate(
                        `${ROUTINGDATA.LAND}${ROUTINGDATA.MAIN}${ROUTINGDATA.JOBSEEK}`,
                        {}
                      );
                    }}
                  >
                    <ArrowRightIcon />
                    {t("JOB_SEEKER")}
                  </text>
                  <text
                    onClick={() =>
                      navigate(
                        `${ROUTINGDATA.LAND}${ROUTINGDATA.MAIN}${ROUTINGDATA.JOBPROVIDER}`
                      )
                    }
                  >
                    <ArrowRightIcon />
                    {t("EMPLOYER")}
                  </text>
                  <text
                    onClick={() =>
                      navigate(
                        `${ROUTINGDATA.LAND}${ROUTINGDATA.MAIN}${ROUTINGDATA.VOLUNTEER}`
                      )
                    }
                  >
                    <ArrowRightIcon />
                    {t("VOLUNTEER")}
                  </text>
                  <text
                    onClick={() =>
                      navigate(
                        `${ROUTINGDATA.LAND}${ROUTINGDATA.MAIN}${ROUTINGDATA.MENTOR}`
                      )
                    }
                  >
                    <ArrowRightIcon />
                    {t("MENTOR")}
                  </text>
                  <text
                    onClick={() =>
                      navigate(ROUTINGDATA.LAND + ROUTINGDATA.LANDPAGECONTACTUS)
                    }
                  >
                    <ArrowRightIcon />
                    {t("Call-To-Action-1")}
                  </text>
                </div>
              </div>
              <div className="footercenterlinks">
                <div>
                  <h5>{t("Call-To-Action-1")}</h5>
                </div>
                <div className="footerlinks-1">
                  <text>{t("address")}</text>
                  <text>
                    {" "}
                    {t("phone")}:<br></br>+91 9080400330
                  </text>
                  <text> {t("Email")}: contactus@usglobalsolutions.com</text>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-copyright">
            <p>
              &copy;{new Date().getFullYear()}
              <span
                style={{
                  textDecoration: "underline",
                  fontWeight: "400",
                  color: "#0976d3",
                }}
              >
                <a
                  style={{
                    textDecoration: "underline",
                    fontWeight: "400",
                    color: "#0976d3",
                    marginLeft: "5px",
                  }}
                  href="https://usglobalsolutions.com/"
                  target="blank"
                >
                  US Global Solutions
                </a>
              </span>
              <span
                style={{
                  fontWeight: "normal",
                  marginLeft: "5px",
                  marginRight: "3px",
                }}
              >
                |
              </span>{" "}
              All Rights Reserved
            </p>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div
        style={{
          height: "100vh",
          display: "grid",
          placeItems: "center",
          // transform: "translate(0,50vh)",
        }}
      >
        <div class="dots"></div>
      </div>
    );
  }
};

export default PrivacyFooter;
