import { Box } from "@mui/material";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Switch,
  Typography,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  Container,
  FormControl,
  FormLabel,
  TextField,
  InputAdornment,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Grid,
  Tooltip,
  Slider,
} from "@mui/material";
import {
  PercentRounded as PercentRoundedIcon,
  CurrencyRupeeRounded as CurrencyRupeeRoundedIcon,
  PostAdd as PostAddIcon,
} from "@mui/icons-material";
import { ROUTINGDATA } from "../../../ROUTINGDATA";
import React, { useEffect, useRef, useState } from "react";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import useAxios from "../../../../api/useAxios";
import { useFormik } from "formik";
import * as Yup from "yup";
import AdminImageUpload from "./AdminImageUpload";
import EditIcon from "@mui/icons-material/Edit";
import sampleImage from "../../../../img/aboutus-img.jpg";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DialogContentText from "@mui/material/DialogContentText";
import AvatarEditor from "react-avatar-editor";
import Slide from "@mui/material/Slide";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import VideocamIcon from "@mui/icons-material/Videocam";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import { P } from "react-html5video";
import { saveAs } from "file-saver";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ImageUploadSettings() {
  const axiosData = useAxios();
  const [uploadedImages, setUploadedImages] = useState([]);
  const [loader, setLoader] = useState(false);
  const [getimage, setgetimage] = useState([]);
  const [getimagedata, setgetimagedata] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [name, setname] = useState("");
  const childref = useRef();
  const baseUrl = axiosData.defaults.baseURL;
  const [limit, setLimit] = useState(false);
  const fileInputField = useRef(null);
  const imageInputField = useRef(null);
  const [filterdata, setfilterdata] = useState("");
  const [accept, setaccept] = useState("image/*");
  const [toShowImage, setToShowImage] = useState(null);
  const [deleteImage, setDeleteImage] = useState(false);
  const inputRef = useRef(null);
  const fileInputRef = useRef(null);
  const [file, setFile] = useState("");
  const [resume, setResume] = useState("");

  const getimages = async (pageno = 0) => {
    setIsLoading(true);
    const response = await axiosData.get(
      ROUTINGDATA.IMAGE + `/page/${pageno}`,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (getimagedata.length === 0 || pageno === 0) {
      if (response.data?.data) {
        setgetimagedata([...response.data.data].reverse());
      } else {
        setgetimagedata([]);
      }
    } else {
      if (response.data?.data) {
        getimagedata.push(response.data.data);
        setgetimagedata([...getimagedata].reverse());
      }
    }

    setgetimage(response.data);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };
  useEffect(() => {
    getimages();
  }, []);
  const formik = useFormik({
    initialValues: {
      name: "",
      fileType: "",
      image: null,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .max(50, "Must be 50 characters or less")
        .required("Name must be Required"),
    }),

    onSubmit: async (values) => {
      if (uploadedImages) {
        if (uploadedImages) {
          values.image = uploadedImages[0];
        }
        if (videoFile) {
          values.image = videoFile;
        }
        if (selectedValue) {
          values.fileType = selectedValue;
        }
        if (resume) {
          values.image = resume;
        }
        setIsSubmitting(true);
        await axiosData.post(ROUTINGDATA.IMAGE, values, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      }
      setIsSubmitting(false);
      setUploadedImages([]);

      if (selectedValue === "Image") {
        deleteFile();
      } else if (selectedValue === "Video") {
        deleteVideoFile();
      } else {
        adminDeleteFile();
      }

      formik.handleReset();
      getimages();
      childref.current.delete();
    },
  });

  const deleteFile = () => {
    setUploadedImages([]);
    setToShowImage(null);
    setLimit(false);
    fileInputField.current.value = "";
  };
  const delteimage = async (id) => {
    await axiosData.delete(`${ROUTINGDATA.IMAGE}/${id}`, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    getimages();
  };

  const [image, setimage] = useState();
  const [open, setOpen] = useState(false);
  const [ref, setr] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  var editor = "";
  const [picture, setPicture] = useState({
    cropperOpen: false,
    img: null,
    zoom: 2,
    croppedImg: " ",
  });

  const handleSlider = (event, value) => {
    setPicture({
      ...picture,
      zoom: value,
    });
  };

  const handleCancel = () => {
    setPicture({
      ...picture,
      cropperOpen: false,
    });
  };

  const setEditorRef = (ed) => {
    editor = ed;
  };
  function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  const handleSave = async (id) => {
    const file = picture.img;

    const values = { image: "" };
    if (setEditorRef) {
      if (picture) {
        imageInputField.current.value = "";
        if (selectedValue === "Image") {
          const canvasScaled = editor.getImageScaledToCanvas();
          const croppedImg = canvasScaled.toDataURL();

          if (croppedImg) {
            setPicture({
              ...picture,
              img: null,
              cropperOpen: false,
              croppedImg: croppedImg,
            });
          }

          var block = croppedImg.split(";");

          var contentType = block[0].split(":")[1];
          var realData = block[1].split(",")[1];
          const img = b64toBlob(realData, contentType);

          values.image = img;
        } else if (selectedValue === "Video") {
          values.image = file;
        } else {
          values.image = file;
        }

        setIsLoading(true);

        const response = await axiosData.put(
          `${ROUTINGDATA.IMAGE}/${id}`,
          values,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response) {
          getimages();
          setOpen(false);
          setr(true);
        }
        setIsLoading(false);
      }
    }
  };

  const [imageSize, setImageSize] = useState(false);

  const handleFileChange = (e) => {
    let imgFile = e.target.files[0];

    // Check if a file is selected
    if (!imgFile) {
      return;
    }

    // Check if the file size is less than 2MB
    if (selectedValue === "Image") {
      const maxSize = 5 * 1024 * 1024; // 2MB in bytes
      if (imgFile.size > maxSize) {
        // Handle the case when the image exceeds the size limit
        setImageSize(true);
        imageInputField.current.value = "";
        return;
      }

      // If the file is valid, proceed with setting the state
      handleClickOpen();
      setPicture({
        ...picture,
        img: imgFile,
        cropperOpen: true,
      });
    } else if (selectedValue === "Video") {
      setPicture({
        ...picture,
        img: URL.createObjectURL(imgFile),
        cropperOpen: true,
      });
      handleClickOpen();
    } else {
      console.log(e);
      setPicture({
        ...picture,
        img: imgFile,
        name: e.target.files[0].name,
        cropperOpen: true,
      });
      handleClickOpen();
    }
  };

  const [mentorPagination, setMentorPagination] = useState(8);

  const allLoadMore = () => {
    setMentorPagination(mentorPagination + 8);
  };

  const allLoadLess = () => {
    setMentorPagination(mentorPagination - 8);
  };

  const [selectedValue, setSelectedValue] = useState("Image");

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
    if (event.target.value === "Image") {
      setaccept("image/*");
    } else if (event.target.value === "Video") {
      setaccept(".mov,.mp4");
    } else {
      setaccept(".docx,.doc,.pdf");
    }
    setfilterdata(event.target.value);
    formik.handleReset();
    // if (inputRef !== null || inputRef !== undefined) {
    //   inputRef.current.value = "";
    // }
    // if (fileInputRef !== null || fileInputRef !== undefined) {
    //   fileInputRef.current.value = "";
    // }
    // if (imageInputField !== null || imageInputField !== undefined) {
    //   imageInputField.current.value = "";
    // }
    // if (fileInputField !== null || fileInputField !== undefined) {
    //   fileInputField.current.value = "";
    // }
  };

  const [source, setSource] = useState();
  const [videoFile, setVideoFile] = useState();

  const handleVideoFileChange = async (e) => {
    const file = e.target.files[0];
    console.log("file:", file);
    setVideoFile(file);
    const url = URL.createObjectURL(file);
    setSource(url);
    console.log("url:", url);
  };

  const handleChoose = (e) => {
    inputRef.current.click();
  };

  const deleteVideoFile = () => {
    inputRef.current.value = "";
    setSource("");
    setVideoFile("");
  };
  const [data, setData] = useState(false);
  const MAX_FILE_SIZE = 1048576 * 10;
  const handleAdminFileChange = (e) => {
    if (e.target.files[0].size > MAX_FILE_SIZE) {
      setFile("File is too large");
      setResume(false);
    } else {
      setResume(e.target.files[0]);
      setFile(e.target.files[0].name);
    }
    setData(e.target.files[0].size);
  };
  const adminDeleteFile = () => {
    fileInputRef.current.value = "";
    setFile(null);
    setData(null);
    setResume(null);
    formik.setFieldValue("fileUpload", null);
  };
  const getvalue = async (e) => {
    if (e) {
      const value = e.target.value.replace(/[^a-z.,' ]/gi, "");
      if (value && value.length >= 3) {
        const response = await axiosData.get(ROUTINGDATA.IMAGE + `/${value}`, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        if (response.data.status === 1001) {
          formik.errors.name = "";
        } else {
          formik.errors.name = "Name not avilable";
        }
      }
      formik.setFieldValue("name", value);
    }
  };
  // if (loader) {
  return (
    <>
      <Box>
        <FormControl>
          <FormLabel id="demo-row-radio-buttons-group-label">
            Select Upload Type
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={selectedValue}
            onChange={handleRadioChange}
          >
            <FormControlLabel value="Image" control={<Radio />} label="Image" />
            <FormControlLabel value="Video" control={<Radio />} label="Video" />
            <FormControlLabel value="File" control={<Radio />} label="File" />
          </RadioGroup>
        </FormControl>
        <Card
          sx={{
            width: "100%",
            boxShadow: "3px 3px 10px 3px #ddd",
            borderRadius: "6px",
          }}
        >
          <Container fixed>
            <CardContent>
              <Box display="flex" flexDirection={"column"} gap={"20px"}>
                <Box display="flex" flexDirection={"column"} gap={"5px"}>
                  <Typography
                    variant="h5"
                    sx={{ fontFamily: "Poppins-SemiBold" }}
                  >
                    Upload Settings
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={{ fontFamily: "Barlow-Medium" }}
                  ></Typography>
                </Box>
                <form onSubmit={formik.handleSubmit}>
                  <Box>
                    <Grid sx={{ mb: 2 }} item xs={12} md={6} lg={6}>
                      <FormControl fullWidth>
                        <FormLabel sx={{ fontWeight: "bold" }}>Name</FormLabel>
                        <TextField
                          required
                          fullWidth
                          placeholder={"Enter Name"}
                          id="name"
                          name="name"
                          onBlur={formik.handleBlur}
                          onChange={getvalue}
                          value={formik.values.name.replace(/[^a-z'. ]/gi, "")}
                          error={
                            formik.touched.name && Boolean(formik.errors.name)
                          }
                          helperText={formik.touched.name && formik.errors.name}
                        />
                      </FormControl>
                    </Grid>
                    {selectedValue === "Image" ? (
                      <AdminImageUpload
                        ref={childref}
                        uploadedImages={uploadedImages}
                        setUploadedImages={setUploadedImages}
                        deleteFile={deleteFile}
                        limit={limit}
                        setLimit={setLimit}
                        fileInputField={fileInputField}
                        toShowImage={toShowImage}
                        setToShowImage={setToShowImage}
                      />
                    ) : null}
                    {selectedValue === "Video" ? (
                      <>
                        <input
                          ref={inputRef}
                          className="VideoInput_input"
                          type="file"
                          onChange={handleVideoFileChange}
                          accept=".mov,.mp4"
                        />
                        <Grid
                          container
                          rowSpacing={3}
                          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                          sx={{ mb: 2 }}
                        >
                          <Grid item xs={12} md={6} lg={6}>
                            <div className="AdminSettingImageUploadContent">
                              <div className="AdminSettingImageUploadBorder">
                                <div>
                                  <Tooltip
                                    title="Upload video"
                                    placement="top"
                                    arrow
                                  >
                                    <IconButton onClick={handleChoose}>
                                      <VideocamIcon
                                        sx={{
                                          color: "#1976d2",
                                          fontSize: 50,
                                          cursor: "pointer",
                                        }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </div>
                                <Typography
                                  variant="caption"
                                  sx={{ textAlign: "center" }}
                                >
                                  Upload video
                                </Typography>
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={12} md={6} lg={6}>
                            <div>
                              {source ? (
                                <>
                                  <video
                                    width="100%"
                                    height="150px"
                                    controls
                                    src={source}
                                  />
                                  <div className="AdminSettingImageDelete">
                                    <Tooltip title="Delete video" arrow>
                                      <IconButton
                                        onClick={() => {
                                          deleteVideoFile();
                                        }}
                                      >
                                        <DeleteIcon
                                          sx={{
                                            color: "rgb(243 81 81)",

                                            borderRadius: "50%",
                                          }}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </div>
                                </>
                              ) : null}
                            </div>
                          </Grid>
                        </Grid>
                      </>
                    ) : null}

                    {selectedValue === "File" ? (
                      <Grid container>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <input
                            className="resumefile"
                            id="file-upload"
                            type="file"
                            accept=".docx,.doc,.pdf,"
                            onChange={handleAdminFileChange}
                            ref={fileInputRef}
                            required
                          />
                          <div className="fileuploadcenter">
                            <Button
                              disabled={file ? true : false}
                              htmlFor="file"
                              variant="contained"
                              color="error"
                              sx={{ borderRadius: 10, mr: -2 }}
                              className="fileUpload"
                              name="adminFileUpload"
                              onClick={() => {
                                fileInputRef.current.click();
                              }}
                              onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              value={formik.values.adminFileUpload}
                            >
                              <DriveFolderUploadIcon className="resumeIcon" />
                              Upload File
                            </Button>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} sx={{ mt: 2 }}>
                          {file ? (
                            <div className="fileName">
                              <Typography
                                variant="div"
                                sizewidth="2px"
                                sx={
                                  resume
                                    ? {
                                        color: "rgb(16 143 178)",
                                      }
                                    : { color: "red" }
                                }
                                className="fileNameSize"
                              >
                                {`${file}`}
                              </Typography>
                              <Tooltip title="Clear" arrow>
                                <IconButton>
                                  <CloseIcon
                                    className="iconsize"
                                    sx={{ color: "red" }}
                                    onClick={() => {
                                      adminDeleteFile();
                                    }}
                                  />
                                </IconButton>
                              </Tooltip>
                            </div>
                          ) : null}
                        </Grid>
                      </Grid>
                    ) : null}

                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                      disabled={
                        // uploadedImages.length !== 0 &&
                        formik.isValid &&
                        formik.dirty &&
                        // source &&
                        isSubmitting === false
                          ? false
                          : true
                      }
                    >
                      Submit
                      {isSubmitting && (
                        <CircularProgress
                          size={24}
                          sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            marginTop: "-12px",
                            marginLeft: "-12px",
                          }}
                        />
                      )}
                    </Button>
                  </Box>
                </form>
              </Box>
            </CardContent>
          </Container>
        </Card>
        {isLoading !== true ? (
          <div
            className={
              selectedValue === "Video"
                ? "AdminSettingVideocard"
                : selectedValue === "Image"
                ? "MentorGrid"
                : "AdminSettingFilecard"
            }
          >
            {getimagedata
              ? getimagedata.length !== 0
                ? getimagedata.map((item, index) => {
                    if (item.fileType === selectedValue) {
                      return (
                        <>
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              marginBottom: "16px",
                              marginTop: "16px",
                            }}
                          >
                            <div
                              className={
                                selectedValue === "Image"
                                  ? "AdminSettingImagecard"
                                  : ""
                              }
                            >
                              {isLoading === true ? (
                                <div
                                  class="member"
                                  sx={{ height: "520px", mt: 10 }}
                                >
                                  <CircularProgress
                                    size={100}
                                    style={{
                                      color: "#339fcd",
                                    }}
                                  />
                                </div>
                              ) : selectedValue === "Image" ? (
                                <div class="member" sx={{ height: "220px" }}>
                                  <Tooltip title={item.name} arrow>
                                    <p
                                      style={{
                                        fontWeight: "bold",
                                        wordWrap: "break-word",
                                      }}
                                    >
                                      <b>File Name:</b>{" "}
                                      <b className="adminfileNameSize">
                                        {item.name}
                                      </b>
                                    </p>
                                  </Tooltip>
                                  <img
                                    src={`${baseUrl}${ROUTINGDATA.IMAGE}/${item.name}`}
                                    className="member-img"
                                    alt="img"
                                    // onError={(e) => {
                                    //   e.target.src = mentor;
                                    // }}
                                  />
                                  <Tooltip
                                    title="Edit"
                                    arrow
                                    onClick={() => {
                                      setaccept("image/*");
                                      setname(item.name);
                                      imageInputField.current.click();
                                    }}
                                  >
                                    <div className="adminImageEdit">
                                      <EditIcon
                                        className="socialEditIcon"
                                        sx={{
                                          color: "#0077b5",
                                          padding: "5px",
                                          borderRadius: "50%",
                                          transition: "0.3s",
                                          cursor: "pointer",
                                          fontSize: "25px",
                                          backgroundColor: "white",
                                        }}
                                      />
                                    </div>
                                  </Tooltip>
                                  <Tooltip
                                    onClick={() => {
                                      setname(item.name);
                                      setDeleteImage(true);
                                    }}
                                    title="Delete"
                                    arrow
                                  >
                                    <div
                                      className="adminImageDelete"
                                      // style={{ marginRight: "45px" }}
                                    >
                                      <DeleteOutlineIcon
                                        className="socialDeleteOutlineIcon"
                                        sx={{
                                          color: "#0077b5",
                                          padding: "5px",
                                          borderRadius: "50%",
                                          transition: "0.3s",
                                          cursor: "pointer",
                                          fontSize: "25px",
                                          backgroundColor: "white",
                                        }}
                                      />
                                    </div>
                                  </Tooltip>
                                </div>
                              ) : selectedValue === "Video" ? (
                                <>
                                  <div>
                                    <div>
                                      <video
                                        width="200px"
                                        height="150px"
                                        controls
                                        src={`${baseUrl}${ROUTINGDATA.IMAGE}/${item.name}`}
                                      />
                                      <p
                                        style={{
                                          fontWeight: "bold",
                                          wordWrap: "break-word",
                                        }}
                                      >
                                        <b>File Name:</b> {item.name}
                                      </p>
                                      <div className="AdminSettingvideoDelete">
                                        <Tooltip title="Delete video" arrow>
                                          <IconButton
                                            onClick={() => {
                                              setname(item.name);
                                              setDeleteImage(true);
                                            }}
                                          >
                                            <DeleteIcon
                                              sx={{
                                                color: "rgb(243 81 81)",

                                                borderRadius: "50%",
                                              }}
                                            />
                                          </IconButton>
                                        </Tooltip>
                                        <Tooltip
                                          title="Edit"
                                          arrow
                                          onClick={() => {
                                            setaccept(".mov,.mp4");
                                            setname(item.name);
                                            imageInputField.current.click();
                                          }}
                                        >
                                          <div>
                                            <EditIcon
                                              className="socialEditIcon"
                                              sx={{
                                                color: "#0077b5",
                                                padding: "5px",
                                                borderRadius: "50%",
                                                transition: "0.3s",
                                                cursor: "pointer",
                                                fontSize: "35px",
                                                backgroundColor: "white",
                                                mb: -1,
                                              }}
                                            />
                                          </div>
                                        </Tooltip>
                                      </div>{" "}
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <div>
                                  <div className="fileName">
                                    <Typography variant="div" sizewidth="2px">
                                      <b>File Name:</b> {item.name}
                                    </Typography>
                                    <Tooltip title="delete" arrow>
                                      <DeleteOutlineIcon
                                        className="socialEditIcon"
                                        sx={{
                                          color: "#0077b5",
                                          padding: "5px",
                                          borderRadius: "50%",
                                          transition: "0.3s",
                                          cursor: "pointer",
                                          fontSize: "35px",
                                          backgroundColor: "white",
                                        }}
                                        onClick={() => {
                                          setname(item.name);
                                          setDeleteImage(true);
                                        }}
                                      />
                                    </Tooltip>
                                    <Tooltip
                                      title="Edit"
                                      arrow
                                      onClick={() => {
                                        setaccept(".docx,.doc,.pdf,");
                                        setname(item.name);
                                        imageInputField.current.click();
                                      }}
                                    >
                                      <div>
                                        <EditIcon
                                          className="socialEditIcon"
                                          sx={{
                                            color: "#0077b5",
                                            padding: "5px",
                                            borderRadius: "50%",
                                            transition: "0.3s",
                                            cursor: "pointer",
                                            fontSize: "35px",
                                            backgroundColor: "white",
                                            mb: -1,
                                          }}
                                        />
                                      </div>
                                    </Tooltip>
                                    <Tooltip
                                      title="download"
                                      arrow
                                      onClick={() => {
                                        saveAs(
                                          `${baseUrl}${ROUTINGDATA.IMAGE}/${item.name}`
                                        );
                                      }}
                                    >
                                      <div>
                                        <FileDownloadOutlinedIcon
                                          className="socialEditIcon"
                                          sx={{
                                            color: "#0077b5",
                                            padding: "5px",
                                            borderRadius: "50%",
                                            transition: "0.3s",
                                            cursor: "pointer",
                                            fontSize: "35px",
                                            backgroundColor: "white",
                                            mb: -1,
                                          }}
                                        />
                                      </div>
                                    </Tooltip>
                                  </div>
                                </div>
                              )}

                              <Dialog open={deleteImage}>
                                <DialogTitle>Confirm</DialogTitle>
                                <DialogContent>
                                  Are You sure you want to delete this image?
                                </DialogContent>
                                <DialogActions>
                                  {" "}
                                  <Button
                                    onClick={() => {
                                      setDeleteImage(false);
                                    }}
                                    variant="outlined"
                                    sx={{
                                      backgroundColor: "#f2f2f2",
                                      textTransform: "lowercase",

                                      color: "black",
                                      borderColor: "white",
                                      fontFamily: "Poppins-Medium",
                                      "&:hover": {
                                        textTransform: "lowercase",
                                        color: "#f2f2f2",
                                        backgroundColor: "#339fcd",
                                        borderColor: "#f2f2f2",
                                        fontFamily: "Poppins-Medium",
                                      },
                                    }}
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    type="submit"
                                    variant="outlined"
                                    disabled={isLoading}
                                    sx={{
                                      textTransform: "lowercase",

                                      color: "white",
                                      backgroundColor: "#ed1067",
                                      borderColor: "#ed1067",
                                      fontFamily: "Poppins-Medium",
                                      transition: "all 0.3s",
                                      "&:hover": {
                                        textTransform: "lowercase",

                                        color: "#ed1067",
                                        borderColor: "#ed1067",
                                        fontFamily: "Poppins-Medium",
                                      },
                                    }}
                                    onClick={() => {
                                      setDeleteImage(false);
                                      delteimage(name);
                                    }}
                                  >
                                    Yes
                                    {isLoading && (
                                      <CircularProgress
                                        size={24}
                                        style={{
                                          color: "#ffffff",
                                          marginLeft: 12,
                                        }}
                                      />
                                    )}
                                  </Button>
                                </DialogActions>
                              </Dialog>

                              <div class="member-info">
                                <input
                                  ref={imageInputField}
                                  style={{ display: "none" }}
                                  type="file"
                                  accept={accept}
                                  onChange={handleFileChange}
                                />

                                <Dialog
                                  open={open}
                                  TransitionComponent={Transition}
                                  keepMounted
                                  aria-describedby="alert-dialog-slide-description"
                                >
                                  <DialogTitle sx={{ m: 0, fontWeight: 600 }}>
                                    {item.name}
                                  </DialogTitle>
                                  {selectedValue === "Image" ? (
                                    <DialogContent>
                                      <DialogContentText id="alert-dialog-slide-description">
                                        {picture.cropperOpen && (
                                          <Box display="block">
                                            <AvatarEditor
                                              ref={setEditorRef}
                                              image={picture.img}
                                              width={200}
                                              height={200}
                                              border={20}
                                              color={[255, 255, 255, 0.6]}
                                              rotate={0}
                                              scale={picture.zoom}
                                            />
                                            <Slider
                                              aria-label="raceSlider"
                                              value={picture.zoom}
                                              min={1}
                                              max={10}
                                              step={0.1}
                                              onChange={handleSlider}
                                            ></Slider>
                                          </Box>
                                        )}
                                      </DialogContentText>
                                    </DialogContent>
                                  ) : selectedValue === "Video" ? (
                                    <video
                                      width="100%"
                                      height="150px"
                                      controls
                                      src={picture.img}
                                    />
                                  ) : (
                                    <Grid
                                      item
                                      xs={12}
                                      sm={12}
                                      md={6}
                                      lg={6}
                                      sx={{ mt: 2 }}
                                    >
                                      <div className="fileName">
                                        <Typography
                                          variant="div"
                                          sizewidth="2px"
                                          className="fileNameSize"
                                        >
                                          {`${picture.name}`}
                                        </Typography>
                                        <Tooltip title="Clear" arrow>
                                          <IconButton>
                                            <CloseIcon
                                              className="iconsize"
                                              sx={{ color: "red" }}
                                              onClick={() => {
                                                adminDeleteFile();
                                              }}
                                            />
                                          </IconButton>
                                        </Tooltip>
                                      </div>
                                    </Grid>
                                  )}
                                  <DialogActions>
                                    <Button
                                      onClick={() => {
                                        handleCancel();
                                        handleClose();
                                      }}
                                    >
                                      Cancel
                                    </Button>
                                    <Button
                                      variant="contained"
                                      onClick={() => {
                                        handleSave(name);
                                        handleClose();
                                      }}
                                    >
                                      Save
                                    </Button>
                                  </DialogActions>
                                </Dialog>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    }
                  })
                : null
              : null}
          </div>
        ) : (
          <div
            style={{
              height: "70vh",
              display: "grid",
              placeItems: "center",
            }}
          >
            <div class="dots"></div>
          </div>
        )}
      </Box>
      <div className="pagination">
        {getimage && (
          <Button
            disabled={getimage.is_last_record_set}
            variant="contained"
            onClick={() => {
              const pageno = getimage.page_number + 1;
              getimages(pageno);
            }}
          >
            See More
          </Button>
        )}
      </div>
    </>
  );
  // } else {
  //   return <b>Loading....</b>;
  // }
}

export default ImageUploadSettings;
