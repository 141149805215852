import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router";
import { ROUTINGDATA } from "../../ROUTINGDATA";
import { useParams } from "react-router-dom";
import useAxios from "../../../api/useAxios";
import successImage from "../../../img/paymentSuccessful.png";
import failedImage from "../../../img/paymentFailed.png";
import Grid from "@mui/material/Grid";

const Paymentmessage = () => {
  const navigate = useNavigate();
  const axiosData = useAxios();
  const [data, setdata] = useState();
  const [paydetials, setpaydetials] = useState();
  const [loading, setLoading] = useState(true);
  const [dynamicmessage, setdyn] = useState("");
  let params = useParams();
  let userDetails = JSON.parse(localStorage.getItem("user"));

  // const searchQuery = useSearchParams()[0];
  // const referenceNum = searchQuery.get("reference");

  const routePostedJobs = () => {
    navigate(ROUTINGDATA.LAND + ROUTINGDATA.MAIN + ROUTINGDATA.POSTJOB, {
      state: userDetails.userId,
    });
  };
  const getpaymentdetials = async () => {
    const _id = params.id;

    try {
      const response = await axiosData.post(
        ROUTINGDATA.PAYMENT + `/status/${_id}`
      );
      setdata(response.data);
      const paymentdetials = await axiosData.post(
        ROUTINGDATA.PAYMENT + `/get`,
        {
          listingQuery: { merchantTransactionId: _id },
        }
      );
      setpaydetials(paymentdetials.data);
      console.log(response);
      if (paymentdetials.data.paymentcategory === "Ad") {
        setdyn("Your AD has been posted ");
      } else {
        setdyn("You can now post your Job");
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };
  useEffect(() => {
    getpaymentdetials();
  }, []);
  const navi = () => {
    if (data.success &&paydetials.status==="COMPLETED") {
      navigate(
        ROUTINGDATA.LAND + ROUTINGDATA.MAIN + paydetials.data.successnavigate,
        {
          state: userDetails.userId,
        }
      );
    } else {
      navigate(
        ROUTINGDATA.LAND + ROUTINGDATA.MAIN + paydetials.data.failednavigation
      );
    }
  };
  const homescreen = () => {
    navigate(ROUTINGDATA.LAND);
  };
  if (loading === false) {
    return (
      <>
        {/* <div>
        {data ? (
          <>
            <h3>{data.code} </h3>
            <h5>Your Payment Id - {params.id}</h5>
            <Button
              variant="contained"
              onClick={() => {
                routePostedJobs();
              }}
            >
              OK
            </Button>
          </>
        ) : null}
      </div> */}
        <Grid
          className="paymentContentGrid"
          container
          spacing={2}
          justifyContent="center"
          alignItems="center"
        >
          <Grid className="paymentSideImage" xs={false} sm={false} md={6}>
            <img
              src={data.success &&paydetials.status==="COMPLETED" ? successImage : failedImage}
              alt="successImage"
              height="400px"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <h1 style={{ fontFamily: "Poppins-SemiBold", color: "#222" }}>
              {data.success &&paydetials.status==="COMPLETED" ? "Payment Successful" : "You're almost there!"}
            </h1>
            <p
              style={{
                fontFamily: "Poppins-SemiBold",
                color: "#444",
                fontSize: "16px",
              }}
            >
              {data.success  &&paydetials.status==="COMPLETED"? "Thank You" : null}
            </p>
            <p
              style={{
                fontFamily: "Poppins-SemiBold",
                color: "#444",
                fontSize: "14px",
              }}
            >
              {data
                ? `Your Payment ID : ${data.data.merchantTransactionId}`
                : null}
            </p>
            <p
              style={{
                fontFamily: "Poppins-SemiBold",
                color: "#444",
                fontSize: "14px",
              }}
            >
              {data.success  &&paydetials.status==="COMPLETED"
                ? `Your Payment was successful! ${dynamicmessage}`
                : "Sorry, we had an issue confirming your Payment. Please try again"}
            </p>
            <div>
              {paydetials.paymentcategory !== "Ad"||paydetials.status!=="COMPLETED" ? (
                <Button
                  className="paymentButton"
                  sx={{
                    mr: 2,
                    borderRadius: "8px",
                    fontFamily: "Poppins-Medium",
                    backgroundColor: "#EC2172",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#d91261",
                    },
                  }}
                  onClick={() => {
                    navi();
                  }}
                >
                  {data.success  &&paydetials.status==="COMPLETED" ? "Post Job" : "Try Again"}
                </Button>
              ) : null}
              <Button
                className="paymentButton"
                sx={{
                  borderRadius: "8px",
                  color: "#108FB2",
                  border: "1px solid #108FB2",
                  transition: "background-color 0.3s ease-in-out",
                  "&:hover": {
                    color: "#0D6E8A",
                  },
                }}
                onClick={homescreen}
              >
                Home Screen
              </Button>
            </div>
          </Grid>
        </Grid>
      </>
    );
  } else {
    return (
      <div
        style={{
          height: "70vh",
          display: "grid",
          placeItems: "center",
        }}
      >
        <div class="dots"></div>
      </div>
    );
  }
};

export default Paymentmessage;
