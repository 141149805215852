import React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { Buffer } from "buffer";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import "./Advertisement.css";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import IconButton from "@mui/material/IconButton";
import { useLocation } from "react-router-dom";
import { List } from "@mui/icons-material";
import useAxios from "../../../api/useAxios";
import { Slide } from "@mui/material";
import { ROUTINGDATA } from "../../ROUTINGDATA";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function AdvertisePayment() {
  let location = useLocation();
  const axiosData = useAxios();
  const [adData, setadData] = useState();
  const searchQuery = useSearchParams()[0];
  const referenceNum = searchQuery.get("reference");
  const [successPostAd, setSuccessPostAd] = useState(false);
  const get = async () => {
    const response = await axiosData.get(``);
    console.log(response);
    setadData(response.data);
  };
  const postAd = async (adpicdatafinal, addUpload) => {
    try {
      const getOneAxios = await axiosData.post(
        `/advertisement`,
        {
          company_url: adpicdatafinal.company_url,
          image_position: adpicdatafinal.image_position,
          days_conut: adpicdatafinal.days_conut,
          Ad_district: adpicdatafinal.Ad_district,
          Ad_pincode: adpicdatafinal.Ad_pincode,
          addUpload: addUpload,
          payment_amount: adpicdatafinal.pay_amount,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(getOneAxios.status);
      if (getOneAxios.status === 201) {
        sessionStorage.removeItem("adUpload");
        sessionStorage.removeItem("adinfo");
        setSuccessPostAd(true);
      }
    } catch (err) {
      console.log("Error", err);
    }
  };
  useEffect(() => {
    console.log("useeffect running", referenceNum, location.state);
    setadData(location.state);
    if (referenceNum) {
      const adUpload = sessionStorage.getItem("adUpload");
      const binaryString = atob(adUpload.split(",")[1]); // Binary data string
      // const blob = new Blob([binaryString], { type: "image/png" });
      const adinfo = JSON.parse(sessionStorage.getItem("adinfo"));
      const docBuffer = Buffer.from(binaryString, "binary");
      const blob = new Blob([docBuffer]);
      console.log("useeffect2 running", adinfo, adUpload);
      console.log("posted successfully", adinfo);
      console.log("running...");
      console.log("blob", blob);
      if (adinfo) {
        postAd(adinfo.adpicdata, blob);
      }
    }
  }, []);

  const payForAd = async () => {
    console.log(location.state);
    try {
      const getOneAxios = await axiosData.post(
        `/advertisement`,
        {
          company_url: location.state.addata.company_url,
          image_position: location.state.addata.image_position,
          days_conut: location.state.addata.days_conut,
          Ad_district: location.state.addata.Ad_district,
          Ad_pincode: location.state.addata.Ad_pincode,
          addUpload: location.state.addata.addUpload,
          videoThumbnailImage: location.state.addata.videoThumbnailImage,
          total_amount: location.state.addata.total_amount,
          ad_payment_type: location.state.addata.ad_payment_type,
          ad_email_id: location.state.addata.ad_email_id,
          ad_mobile_number: location.state.addata.ad_mobile_number,
          ad_person_name: location.state.addata.ad_person_name,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const data = getOneAxios.data;
      if (data) {
        const response = await axiosData.post(`${ROUTINGDATA.PAYMENT}`, {
          merchantTransactionId: "MUID" + Date.now(),
          merchantUserId: "T" + Date.now(),
          amount: getOneAxios.data.total_amount,
          data: {
            image_position: getOneAxios.data.image_position,
            district: getOneAxios.data.Ad_district,
            mobile: getOneAxios.data.ad_mobile_number,
            email: getOneAxios.data.ad_email_id,
            pincode: getOneAxios.data.pincode,
            area: getOneAxios.data.area_name,
            company_url: getOneAxios.data.company_url,
            no_posting: 1,
            ad_payment_type: getOneAxios.data.ad_payment_type,
            updateQuery: {
              status: "Close",
              received_amount: getOneAxios.data.total_amount,
            },
            listingQuery: { _id: getOneAxios.data._id },
            collection: "advertisements",
            emailtemplate: "paymentmessage",
            successnavigate: "",
            failednavigation: "advertisement",
            link: "https://www.velaivendum.com/#/main/advertisement",
            admintemplate: "admintemplate",
          },

          emailvarialbles: [
            {
              key: "name",
              value: getOneAxios.data.ad_person_name,
            },
          ],
          send: true,
          usertype: "AdUser",
          paymentcategory: "Ad",
          callbackUrl: `https://velaivendum.com/p-services/payment/phonepe/callback`,
          redriectUrl: `https://www.velaivendum.com/#/main/payment`,
        });
        window.open(response.data);
      }
      // return data;
    } catch (err) {
      console.log("Error", err);
    }
  };
  const navigate = useNavigate();
  return (
    <>
      <Dialog
        TransitionComponent={Transition}
        open={successPostAd}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "rgb(16 143 178)",
            width: "300px",
          },
        }}
      >
        <DialogTitle
          bgcolor="rgb(16 143 178)"
          sx={{ color: "white", fontWeight: 600 }}
        >
          Confirmation
        </DialogTitle>
        <DialogContent sx={{ mt: 1 }}>
          <DialogContentText sx={{ color: "white", fontWeight: 600 }}>
            Successfully this advertisement Posted!
            <p>Your payment reference Id - {referenceNum}</p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            variant="outlined"
            onClick={() => {
              setSuccessPostAd(false);
              navigate(`${ROUTINGDATA.LAND}`);
            }}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>

      {/* <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Card
          className="PaymentDetailsCard"
          sx={{ p: 3, textAlign: "center", width: "500px", height: "300px" }}
        >
          <Typography sx={{ fontWeight: "bold", fontSize: "24px"}}>
            Payment Details
          </Typography>
          <Typography sx={{ mt: 5 ,fontWeight: "bold", fontSize: "34px"}}>
            <IconButton sx={{ mr: -1 ,fontWeight: "bold"}}>
              <CurrencyRupeeIcon />
            </IconButton>
            <b>{location.state.pay_amount}</b>
          </Typography>
          <Typography  sx={{ mt: 3 ,fontWeight: "bold",textAlign: "left"}}>The Advertisement will be displayed in {location.state.image_position}</Typography>
          <Typography  sx={{ mt: 1,fontWeight: "bold",textAlign: "left" }}>Validity till {location.state.daysvalid}</Typography>
          <Typography  sx={{ mt: 2 }}>Please verify Details Before pay</Typography>
          <Button sx={{ mt: 4 }} variant="contained">
            Pay Now
          </Button>
        </Card>
      </Box> */}
      <div id="paymentpage" className="paymentpage">
        <div className="info-payment">
          <div className="info-header">
            <h3>Choose Your</h3>
            <h3 style={{ color: "#038fc7" }}> Pricing Plan</h3>
          </div>
          <p className="info-sentence">
            We can help you Reach the best Advertising{" "}
          </p>
          {/* <p className="info-sentence" style={{ color: "gray" }}>
            The price of advertising content can vary widely depending on
            several factors, including the advertisement position,The validity
            of an advertisement the reach of the advertising, and the complexity
            of the creative work involved.
          </p>
          <p className="info-sentence" style={{ color: "gray" }}>
            Remember that advertising is an investment, and the price you pay
            for content should align with your advertising goals and expected
            returns. It's essential to have a clear budget in mind and to
            communicate your requirements and expectations with the content
            creators or agencies you work with to ensure a successful
            advertising campaign.
          </p> */}
          <ul>
            <li>
              <div>
                <DoubleArrowIcon
                  sx={{ marginTop: "4px", padding: "5px", color: "#038fc7" }}
                />
              </div>
              <p className="info-sentence" style={{ color: "gray" }}>
                Align content pricing with your goals. Consider factors like
                position, validity, reach, and creative complexity for effective
                and strategic advertising.
              </p>
            </li>
            <li>
              <div>
                <DoubleArrowIcon
                  sx={{ marginTop: "4px", padding: "5px", color: "#038fc7" }}
                />
              </div>
              <p className="info-sentence" style={{ color: "gray" }}>
                Treat advertising as an investment. Set a clear budget, ensuring
                costs match expected returns. Smart budgeting is vital for a
                successful campaign.
              </p>
            </li>
            <li>
              <div>
                <DoubleArrowIcon
                  sx={{ marginTop: "4px", padding: "5px", color: "#038fc7" }}
                />
              </div>
              <p className="info-sentence" style={{ color: "gray" }}>
                Communicate openly with creators or agencies. Clearly convey
                your requirements and expectations. Collaboration and
                transparency are key for a thriving advertising campaign.
              </p>
            </li>
            <li>
              <div>
                <DoubleArrowIcon
                  sx={{ marginTop: "4px", padding: "5px", color: "#038fc7" }}
                />
              </div>
              <p className="info-sentence" style={{ color: "gray" }}>
                Prioritize creative excellence over quantity. A quality-focused
                approach ensures impactful advertising content that resonates
                with your audience for lasting results.
              </p>
            </li>
          </ul>
          <div className="info-sentence">
            <text
              className="info-sentence font-mark"
              onClick={() => {
                navigate(`${ROUTINGDATA.LAND}${ROUTINGDATA.PurchasePolicy}`);
                window.scrollTo(0, 0);
              }}
            >
              Purchase Policy
            </text>{" "}
            |{" "}
            <text
              className="info-sentence font-mark"
              onClick={() => {
                navigate(`${ROUTINGDATA.LAND}${ROUTINGDATA.RefundPolicy}`);
                window.scrollTo(0, 0);
              }}
            >
              Refund Policy
            </text>{" "}
            |{" "}
            <text
              className="info-sentence font-mark"
              onClick={() => {
                navigate(`${ROUTINGDATA.LAND}${ROUTINGDATA.DeliveryPolicy}`);
                window.scrollTo(0, 0);
              }}
            >
              Delivery Policy
            </text>{" "}
            |{" "}
            <text
              className="info-sentence font-mark"
              onClick={() => {
                navigate(`${ROUTINGDATA.LAND}${ROUTINGDATA.PricingPolicy}`);
                window.scrollTo(0, 0);
              }}
            >
              Pricing Policy
            </text>
          </div>
        </div>

        <div className="template-price">
          <div className="price-card">
            <h4>Advertisement Payment</h4>
            <p>Package</p>
            <div className="amount-alignment">
              <CurrencyRupeeIcon
                sx={{
                  fontSize: "38px",
                  color: "#038fc7",
                }}
              />
              <span>{location.state?.pay_amount}</span>
            </div>
            <div className="list-the-info">
              <ul>
                <li> Position {location.state?.image_position}</li>
                <li>Validity till {location.state?.daysvalid}</li>
                <li> Advertisement Package</li>
                <li> Verify Details Before payment</li>
              </ul>
            </div>
            <div>
              <Button
                variant="contained"
                onClick={async () => {
                  payForAd();
                }}
              >
                Pay Now
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdvertisePayment;
