import {
  Card,
  Typography,
  DialogActions,
  Button,
  IconButton,
  DialogTitle,
  Dialog,
  DialogContent,
  Grid,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  Tooltip,
  CardActions,
  DialogContentText,
} from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import React, { useEffect, useState } from "react";
import WestIcon from "@mui/icons-material/West";
import "./PostJob.css";
import { useNavigate } from "react-router-dom";
import useAxios from "../../../api/useAxios";
import { Box } from "@mui/system";
import DangerousIcon from "@mui/icons-material/Dangerous";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import AodIcon from "@mui/icons-material/Aod";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import CardContent from "@mui/material/CardContent";
import SeekerDialog from "../../../components/admin-panel/admin-components/DialogBoxes/SeekerDialog";
import { ROUTINGDATA } from "../../ROUTINGDATA";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment/moment";
function SeekersApplied() {
  const axiosData = useAxios();
  const navigate = useNavigate();

  let userDetails = JSON.parse(localStorage.getItem("user"));
  const [applyDetails, setApplyDetails] = useState([]);
  const [afApproveDetails, setAfApproveDetails] = useState([]);
  const [afPendingDetails, setAfPendingDetails] = useState([]);
  const [afRejectedDetails, setAfRejectedDetails] = useState([]);
  const [activePaper, setActivePaper] = useState("applicants");
  const [approvePagination, setApprovePagination] = useState(10);
  const [pendingPagination, setPendingPagination] = useState(10);
  const [rejectedPagination, setRejectedPagination] = useState(10);
  const [allPagination, setAllPagination] = useState(10);
  const [approveDatas, setApproveDatas] = useState([]);
  const [pendingDatas, setPendingDatas] = useState([]);
  const [rejectedDatas, setRejectedDatas] = useState([]);
  const [approvePopup, setApprovePopup] = useState(false);
  const [selectProfilePopup, setSelectProfilePopup] = useState(false);
  const [getOneSeeker, setGetOneSeeker] = useState({});
  const [applyJob, setApplyJob] = useState(false);
  const [noProfile, setNoProfile] = useState(false);
  const [pendingJob, setPendingJob] = useState(false);
  const [open, setOpen] = useState(false);
  const [filterDetails, setfilterDetails] = useState([]);
  const [apiData, setApiData] = useState([]);
  const [apiDataPen, setApiDataPen] = useState([]);
  const [apiDataApr, setApiDataApr] = useState([]);
  const [apiDataRej, setApiDataRej] = useState([]);
  const [pendingMsg, setPendingMsg] = useState();
  const [afPendingMsg, setAfPendingMsg] = useState({});
  const [declineMsg, setDeclineMsg] = useState();
  const [approveMsg, setApproveMsg] = useState();
  const [afApproveMsg, setAfApproveMsg] = useState({});
  const [afDeclineMsg, setAfDeclineMsg] = useState({});
  const [filterName, setFilterName] = useState({
    date: "Total",
    isLoading: false,
  });

  const todate = moment();
  const todayDate = moment().format("DD-MM-YYYY");
  const yesterdayDate = moment().subtract(1, "day").format("DD-MM-YYYY");
  const [loading, setLoading] = useState(true);
  const formik = useFormik({
    initialValues: {
      notes: "Your Profile has been Shortlisted.",
    },
    validationSchema: Yup.object({
      notes: Yup.string(),
    }),
    onSubmit: async (values) => {
      const resData = values.notes;
      var strippedHtml = resData.replace(/<[^>]+>/g, "");
      setPendingMsg(strippedHtml);
      setAfPendingMsg({ approve_reason: strippedHtml });
      const id = afPendingDetails._id;
      const commentsAppr = strippedHtml;
      try {
        await axiosData.patch(
          "jobapply/updatestatus",
          {
            id: id,
            status: "P",
            notes: commentsAppr,
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        ShortlistedProfile(afPendingDetails, resData);
        getAppliedSeekers();
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleChange = () => {
    setOpen(!open);
  };
  const [rejectedPopup, setRejectedPopup] = useState(false);
  const getAppliedSeekers = async () => {
    try {
      const response = await axiosData.get(
        `jobapply/getbyemployerid/${userDetails.userId}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.data === "") {
        setLoading(false);
        setApplyJob(true);
      } else if (response.data) {
        const applicants = response.data.filter((datas) => {
          if (datas.status === "") {
            return datas;
          }
        });
        const approveSeeker = response.data.filter((datas) => {
          if (datas.status === "A") {
            return datas;
          }
        });
        const pendingSeeker = response.data.filter((datas) => {
          if (datas.status === "P") {
            return datas;
          }
        });
        const rejectedSeeker = response.data.filter((datas) => {
          if (datas.status === "R") {
            return datas;
          }
        });

        if (activePaper === "applicants") {
          if (applicants.length === 0) {
            setNoProfile(true);
          }
        } else if (activePaper === "pending-profiles") {
          if (pendingSeeker.length === 0) {
            setNoProfile(true);
          }
        } else if (activePaper === "offered-jobs") {
          if (approveSeeker.length === 0) {
            setNoProfile(true);
          }
        } else {
          if (rejectedSeeker.length === 0) {
            setNoProfile(true);
          }
        }
        setApproveDatas(approveSeeker.reverse());
        setPendingDatas(pendingSeeker.reverse());
        setRejectedDatas(rejectedSeeker.reverse());
        setApplyDetails(applicants.reverse());
        setApiData(applicants);
        setApiDataPen(pendingSeeker);
        setApiDataApr(approveSeeker);
        setApiDataRej(rejectedSeeker);
      }
      setLoading(false);
    } catch (err) {}
  };
  const approvePutCall = async (data) => {
    setAfApproveMsg({ approve_reason: approveMsg });
    const id = data._id;
    const commentsAppr = afApproveMsg;
    var strippedHtml = commentsAppr.replace(/<[^>]+>/g, "");
    setApprovePopup(false);
    try {
      const res = await axiosData.patch(
        "jobapply/updatestatus",
        {
          id: id,
          status: "A",
          notes: strippedHtml,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res) {
        OfferedProfile(data, afApproveMsg);
        getAppliedSeekers();
      }
    } catch (err) {
      console.log(err);
    }
    getAppliedSeekers();
    formik.handleReset("");
  };
  const OfferedProfile = async (data, afApproveMsg) => {
    const id = data._id;
    const noteProfile = afApproveMsg;

    try {
      await axiosData.post(
        "jobapply/OfferEmail",
        {
          _id: id,
          seeker_name: data.seeker_name,
          company_name: userDetails.company_name,
          seeker_email: data.seeker_email,
          notes: noteProfile,
          job_title: data.job_title,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setApproveMsg("");
    } catch (err) {
      console.log(err);
    }
    getAppliedSeekers();
  };
  const approvePendingCall = async (data) => {
    setSelectProfilePopup(false);
    const commentsAppr = pendingMsg;
    const id = data._id;
    try {
      const datares = await axiosData.patch(
        "jobapply/updatestatus",
        {
          id: id,
          status: "P",
          notes: commentsAppr,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    } catch (err) {
      console.log(err);
    }
    getAppliedSeekers();
  };

  const getFilterValue = (value, alldatas) => {
    if (value === "Total") {
      setAllPagination(10);
      setfilterDetails("Total");
      setApiData([...applyDetails]);
      setApiDataPen([...pendingDatas]);
      setApiDataApr([...approveDatas]);
      setApiDataRej([...rejectedDatas]);
    } else if (value === "today") {
      const get = alldatas.filter((i) => i.apply_date === todayDate);
      if (get.length === 0) {
        setfilterDetails("Today");
        setPendingJob(true);
      } else {
        if (activePaper === "applicants") {
          setAllPagination(10);
          setApiData(get.reverse());
        } else if (activePaper === "pending-profiles") {
          setPendingPagination(10);
          setApiDataPen(get.reverse());
        } else if (activePaper === "offered-jobs") {
          setApprovePagination(10);
          setApiDataApr(get.reverse());
        } else {
          setRejectedPagination(10);
          setApiDataRej(get.reverse());
        }
      }
    } else if (value === "yesterday") {
      const yes = alldatas.filter((i) => i.apply_date === yesterdayDate);

      if (yes.length === 0) {
        setfilterDetails("Yesterday");
        setPendingJob(true);
      } else {
        if (activePaper === "applicants") {
          setApiData(yes.reverse());
        } else if (activePaper === "pending-profiles") {
          setApiDataPen(yes.reverse());
        } else if (activePaper === "offered-jobs") {
          setApiDataApr(yes.reverse());
        } else {
          setApiDataRej(yes.reverse());
        }
      }
    } else if (value === "week") {
      const startWeek = moment().subtract(1, "weeks").startOf("week");

      const endWeek = moment().subtract(1, "weeks").endOf("week");
      function getDatesInRange(startDate, endDate) {
        const date = new Date(startDate.getTime());
        const dates = [];
        while (date <= endDate) {
          dates.push(moment(date).format("MM/DD/YYYY"));
          date.setDate(date.getDate() + 1);
        }
        return dates;
      }
      const d1 = new Date(startWeek);
      const d2 = new Date(endWeek);

      const weekvalue = getDatesInRange(d1, d2);

      const resDates = weekvalue.map((getdate) => {
        const getDate2 = getdate;
        const getDate3 = getDate2
          .split("/")
          .map((e) => (e[0] === "0" ? e.slice() : e));
        const getDateFormat =
          getDate3[1] + "-" + getDate3[0] + "-" + getDate3[2];

        return getDateFormat;
      });

      const dataapp = [];

      resDates.map((datas) => {
        const dd = alldatas.filter((ite) => ite.apply_date === datas);
        const filterdat = dd.map((item) => dataapp.push(item));
        return filterdat;
      });

      const week = dataapp;
      if (week.length === 0) {
        setfilterDetails("Last Week");
        setPendingJob(true);
      } else {
        if (activePaper === "applicants") {
          setApiData(week.reverse());
        } else if (activePaper === "pending-profiles") {
          setApiDataPen(week.reverse());
        } else if (activePaper === "offered-jobs") {
          setApiDataApr(week.reverse());
        } else {
          setApiDataRej(week.reverse());
        }
      }
    } else if (value === "month") {
      var currentMonth = moment(todate).format("MM");
      var currentyear = moment(todate).format("YYYY");

      const minsmonth = currentMonth - 1;
      if (currentMonth === "01") {
        var call = currentyear - 1;
        const endOfMonth = moment(
          `${currentyear}-${currentMonth}`,
          "YYYY-MM"
        ).endOf("month");
        const endofdate = endOfMonth.format("DD-MM-YYYY");
        const mon = alldatas.filter((item) =>
          moment(item.apply_date).isBetween(
            `01-12-${call}`,
            endofdate,
            null,
            "[]"
          )
        );
        if (mon.length === 0) {
          setfilterDetails("Last Month");
          setPendingJob(true);
        } else {
          if (activePaper === "applicants") {
            setApiData(mon);
          } else if (activePaper === "pending-profiles") {
            setApiDataPen(mon);
          } else if (activePaper === "offered-jobs") {
            setApiDataApr(mon);
          } else {
            setApiDataRej(mon);
          }
        }
      } else {
        const endOfMonth = moment(
          `${currentyear}-${minsmonth}`,
          "DD-MM-YYYY"
        ).endOf("month");
        const startOfMonth = moment(
          `${currentyear}-${minsmonth}`,
          "YYYY-MM"
        ).startOf("month");
        const startOfdate = startOfMonth.format("DD-MM-YYYY");
        const date1 = moment(startOfdate, "DD-MM-YYYY");
        const endofdate = endOfMonth.format("DD-MM-YYYY");
        const date2 = moment(endofdate, "DD-MM-YYYY");
        const mon = alldatas.filter((item) => {
          const date = moment(item.apply_date, "DD-MM-YYYY");
          return moment(date).isBetween(date1, date2, null, "[]");
        });

        if (mon.length === 0) {
          setfilterDetails("Last Month");
          setPendingJob(true);
        } else {
          if (activePaper === "applicants") {
            setApiData(mon);
          } else if (activePaper === "pending-profiles") {
            setApiDataPen(mon);
          } else if (activePaper === "offered-jobs") {
            setApiDataApr(mon);
          } else {
            setApiDataRej(mon);
          }
        }
      }
    }
  };
  const ShortlistedProfile = async (data, resData) => {
    const id = data._id;
    const noteProfile = resData;
    try {
      await axiosData.post(
        "jobapply/email",
        {
          _id: id,
          seeker_name: data.seeker_name,
          company_name: userDetails.company_name,
          seeker_email: data.seeker_email,
          notes: noteProfile,
          job_title: data.job_title,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    } catch (err) {
      console.log(err);
    }
    getAppliedSeekers();
  };
  const rejectPutCall = async (data) => {
    setAfDeclineMsg({ decline_reason: declineMsg });
    const id = data._id;
    const commentsDec = afDeclineMsg;
    var strippedHtml = commentsDec.replace(/<[^>]+>/g, "");
    setRejectedPopup(false);
    try {
      const res = await axiosData.patch(
        "jobapply/updatestatus",
        {
          id: id,
          status: "R",
          notes: strippedHtml,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res) {
        RejectedProfile(data, afDeclineMsg);
        getAppliedSeekers();
      }
    } catch (err) {
      console.log(err);
    }
    getAppliedSeekers();
  };
  const RejectedProfile = async (data, afDeclineMsg) => {
    const id = data._id;
    const noteProfile = afDeclineMsg;
    try {
      await axiosData.post(
        "jobapply/RejectEmail",
        {
          _id: id,
          seeker_name: data.seeker_name,
          company_name: userDetails.company_name,
          seeker_email: data.seeker_email,
          notes: noteProfile,
          job_title: data.job_title,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setDeclineMsg("");
    } catch (err) {
      console.log(err);
    }
    getAppliedSeekers();
  };
  useEffect(() => {
    if (userDetails === null || userDetails === undefined) {
      navigate(
        `${ROUTINGDATA.LAND}${ROUTINGDATA.MAIN}${ROUTINGDATA.PageNotFound}`
      );
    } else {
      getAppliedSeekers();
    }
  }, []);
  const viewProfile = async (id) => {
    try {
      const getOneAxios = await axiosData.get(`seekers/${id}`);
      const { data } = getOneAxios;
      setGetOneSeeker(data);
    } catch (err) {
      console.log(err);
    }
  };
  const allLoadMore = () => {
    setAllPagination(allPagination + 10);
  };

  const allLoadLess = () => {
    setAllPagination(allPagination - 10);
  };
  const approveLoadMore = () => {
    setApprovePagination(approvePagination + 10);
  };

  const approveLoadLess = () => {
    setApprovePagination(approvePagination - 10);
  };

  const pendingLoadMore = () => {
    setPendingPagination(pendingPagination + 10);
  };

  const pendingLoadLess = () => {
    setPendingPagination(pendingPagination - 10);
  };

  const rejectedLoadMore = () => {
    setRejectedPagination(rejectedPagination + 10);
  };

  const rejectedLoadLess = () => {
    setRejectedPagination(rejectedPagination - 10);
  };

  const handleClick = (event) => {
    setActivePaper(event);
  };
  const approvePopupMethod = (data) => {
    setApprovePopup(true);
    setAfApproveDetails(data);
  };
  const rejectedPopupMethod = (data) => {
    setRejectedPopup(true);
    setAfRejectedDetails(data);
  };
  const selectProfile = (data) => {
    setSelectProfilePopup(true);
    setAfPendingDetails(data);
    pendingProfilePutcall(data);
  };
  const pendingProfilePutcall = (data) => {
    const id = data._id;
  };
  if (loading === false) {
    return (
      <div>
        <div className="arrow-link">
          <div className="headingPart">
            <Tooltip
              arrow
              onClick={() => {
                navigate(
                  ROUTINGDATA.LAND +
                    ROUTINGDATA.MAIN +
                    ROUTINGDATA.EMPLOYERUPDATE,
                  {
                    state: userDetails.emailId,
                  }
                );
              }}
              className="GoBackTooltip"
              title="Go Back"
            >
              <IconButton
                id="backIcon"
                sx={{
                  backgroundColor: "#339fcd",
                  justifyContent: "center",
                  height: "40px",
                  marginTop: "20px",
                  marginLeft: "20px",
                }}
              >
                <WestIcon sx={{ color: "#fff" }} />
              </IconButton>
            </Tooltip>
            <Typography id="headingSeekers">Applied Seekers Data</Typography>
            <div className="filter">
              <Grid item xs={12} sm={6} md={1.7}>
                <FormControl sx={{ width: 200, marginRight: "20px" }}>
                  <InputLabel sx={{ fontSize: "22px", color: "green" }}>
                    Filter By Date
                  </InputLabel>
                  <Select
                    sx={{ marginTop: "20px", marginRight: "20px" }}
                    displayEmpty
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={filterName.date}
                    onChange={(e) => {
                      setFilterName({ ...filterName, date: e.target.value });
                      getFilterValue(
                        e.target.value,
                        activePaper === "applicants"
                          ? applyDetails
                          : activePaper === "pending-profiles"
                          ? pendingDatas
                          : activePaper === "offered-jobs"
                          ? approveDatas
                          : rejectedDatas
                      );
                    }}
                  >
                    <MenuItem value="Total">Total</MenuItem>
                    <MenuItem value="today">Today</MenuItem>
                    <MenuItem value="yesterday">Yesterday</MenuItem>
                    <MenuItem value="week">Last Week</MenuItem>
                    <MenuItem value="month">Last month</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </div>
          </div>
          <div className="main-cards">
            <Box
              sx={{ background: "#483d8b", color: "#ffffff" }}
              className={
                activePaper === "applicants"
                  ? "active-paper"
                  : "link-active-paper"
              }
              onClick={() => {
                if (applyDetails.length === 0) {
                  setNoProfile(true);
                }
                handleClick("applicants");
                getFilterValue("Total");
                setFilterName({ ...filterName, date: "Total" });
              }}
            >
              <Typography id="heading">
                <span id="headingSpan">
                  <AodIcon />
                </span>
                {applyDetails.length}
              </Typography>
              <Typography id="heading1">Applicants</Typography>
            </Box>
            <Box
              className={
                activePaper === "pending-profiles"
                  ? "active-paper"
                  : "link-active-paper"
              }
              onClick={() => {
                if (pendingDatas.length === 0) {
                  setNoProfile(true);
                }
                handleClick("pending-profiles");
                getFilterValue("Total");
                setFilterName({ ...filterName, date: "Total" });
              }}
              sx={{ background: "#ff8c00", color: "#ffffff" }}
            >
              <Typography id="heading">
                <span id="headingSpan">
                  <PendingActionsIcon />
                </span>
                {pendingDatas.length}
              </Typography>
              <Typography id="heading1">Pending Profiles</Typography>
            </Box>
            <Box
              className={
                activePaper === "offered-jobs"
                  ? "active-paper"
                  : "link-active-paper"
              }
              onClick={() => {
                if (approveDatas.length === 0) {
                  setNoProfile(true);
                }
                handleClick("offered-jobs");
                getFilterValue("Total");
                setFilterName({ ...filterName, date: "Total" });
              }}
              sx={{ background: "#008000", color: "#ffffff" }}
            >
              <Typography id="heading">
                <span id="headingSpan">
                  <HowToRegIcon />
                </span>
                {approveDatas.length}
              </Typography>
              <Typography id="heading1">Offered Jobs</Typography>
            </Box>
            <Box
              className={
                activePaper === "rejected-profiles"
                  ? "active-paper"
                  : "link-active-paper"
              }
              onClick={() => {
                if (rejectedDatas.length === 0) {
                  setNoProfile(true);
                }
                handleClick("rejected-profiles");
                getFilterValue("Total");
                setFilterName({ ...filterName, date: "Total" });
              }}
              sx={{ background: "#ff0000", color: "#ffffff" }}
            >
              <Typography id="heading">
                <span id="headingSpan">
                  <DangerousIcon />
                </span>
                {rejectedDatas.length}
              </Typography>
              <Typography id="heading1">Rejected Profiles</Typography>
            </Box>
          </div>
        </div>

        {activePaper === "applicants" ? (
          apiData.length === 0 ? (
            <Typography sx={{ textAlign: "center" }}>
              <h3>No Data Available</h3>
            </Typography>
          ) : (
            <>
              <div className="main-div">
                <div className="cards">
                  {apiData.slice(0, allPagination).map((data, index) => (
                    <Card className="car" key={index}>
                      <CardContent>
                        <Typography sx={{ marginY: 0.5 }}>
                          <div className="jobDetails">
                            <span className="font">Job Title:</span>
                            <div>{data.job_title}</div>
                          </div>
                        </Typography>
                        <Typography sx={{ marginY: 0.5 }}>
                          <div className="jobDetails">
                            <span className="font">Seeker ID:</span>
                            <div>{data.seeker_id}</div>
                          </div>
                        </Typography>
                        <Typography sx={{ marginY: 0.5 }}>
                          <div className="jobDetails">
                            <span className="font">Name:</span>
                            <div>{data.seeker_name}</div>
                          </div>
                        </Typography>
                        <Typography sx={{ marginY: 0.5 }}>
                          <div className="jobDetails">
                            <span className="font">Email ID:</span>
                            <div>
                              <Typography
                                gutterBottom
                                sx={{
                                  wordWrap: "break-word",
                                }}
                              >
                                {data.seeker_email}
                              </Typography>
                            </div>
                          </div>
                        </Typography>
                        <Typography sx={{ marginY: 0.5 }}>
                          <div className="jobDetails">
                            <span className="font">Mobile Number:</span>
                            <div>{data.seeker_mobile_number}</div>
                          </div>
                        </Typography>
                        <Typography sx={{ marginY: 0.5 }}>
                          <div className="jobDetails">
                            <span className="font">Applied Date:</span>
                            <div>{data.apply_date}</div>
                          </div>
                        </Typography>

                        <Typography sx={{ marginY: 0.5 }}>
                          <div className="jobDetails">
                            <span className="font">Status:</span>
                            <div>
                              {data.status === ""
                                ? "Processing"
                                : data.status === "P"
                                ? "Pending"
                                : data.status === "A"
                                ? "Approved"
                                : "Rejected"}
                            </div>
                          </div>
                        </Typography>
                      </CardContent>
                      <CardActions sx={{ justifyContent: "end" }}>
                        <Button
                          variant="contained"
                          color="secondary"
                          size="small"
                          onClick={() => selectProfile(data)}
                        >
                          Select Profile
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={() => {
                            viewProfile(data.seeker_id);
                            handleChange();
                          }}
                        >
                          View Profile
                        </Button>
                      </CardActions>
                    </Card>
                  ))}
                </div>

                <div className="pagination">
                  {allPagination > 10 && (
                    <Button variant="outlined" onClick={allLoadLess}>
                      Load Less
                    </Button>
                  )}
                  {allPagination < apiData.length && (
                    <Button variant="contained" onClick={allLoadMore}>
                      Load More
                    </Button>
                  )}
                </div>
              </div>
            </>
          )
        ) : activePaper === "pending-profiles" ? (
          apiDataPen.length === 0 ? (
            <Typography sx={{ textAlign: "center" }}>
              <h3>No Data Available</h3>
            </Typography>
          ) : (
            <>
              <div className="main-div">
                <div className="cards">
                  {apiDataPen.slice(0, pendingPagination).map((data, index) => (
                    <Card className="car" key={index}>
                      <Typography sx={{ marginY: 0.5 }}>
                        <div className="jobDetails">
                          <span className="font">Job Title:</span>
                          <div>{data.job_title}</div>
                        </div>
                      </Typography>
                      <Typography sx={{ marginY: 0.5 }}>
                        <div className="jobDetails">
                          <span className="font">Seeker ID:</span>
                          <div>{data.seeker_id}</div>
                        </div>
                      </Typography>
                      <Typography sx={{ marginY: 0.5 }}>
                        <div className="jobDetails">
                          <span className="font">Name:</span>
                          <div>{data.seeker_name}</div>
                        </div>
                      </Typography>
                      <Typography sx={{ marginY: 0.5 }}>
                        <div className="jobDetails">
                          <span className="font">Email ID:</span>
                          <div>
                            <Typography
                              gutterBottom
                              sx={{
                                wordWrap: "break-word",
                              }}
                            >
                              {data.seeker_email}
                            </Typography>
                          </div>
                        </div>
                      </Typography>
                      <Typography sx={{ marginY: 0.5 }}>
                        <div className="jobDetails">
                          <span className="font">Mobile Number:</span>
                          <div>{data.seeker_mobile_number}</div>
                        </div>
                      </Typography>
                      <Typography sx={{ marginY: 0.5 }}>
                        <div className="jobDetails">
                          <span className="font">Applied Date:</span>
                          <div>{data.apply_date}</div>
                        </div>
                      </Typography>
                      <Typography
                        sx={{
                          marginY: 0.5,
                          display:
                            data.notes === "" || data.notes === undefined
                              ? "none"
                              : "block",
                        }}
                      >
                        <div className="jobDetails">
                          <span className="font">Comments:</span>
                          <div
                            style={{
                              maxHeight: "4em",
                              overflowY: "auto",
                              height: "50px",
                            }}
                          >
                            {data.notes}
                          </div>
                        </div>
                      </Typography>
                      <Typography sx={{ marginY: 0.5 }}>
                        <div className="jobDetails">
                          <span className="font">Status:</span>
                          <div>
                            {data.status === "P"
                              ? "Pending"
                              : data.status === "A"
                              ? "Approved"
                              : "Rejected"}
                          </div>
                        </div>
                      </Typography>

                      <CardActions sx={{ justifyContent: "end" }}>
                        <Button
                          variant="contained"
                          color="success"
                          size="small"
                          disabled={data.status === "A" ? true : false}
                          onClick={() => {
                            approvePopupMethod(data);
                          }}
                        >
                          Approve
                        </Button>
                        <Button
                          variant="contained"
                          color="error"
                          size="small"
                          disabled={data.status === "R" ? true : false}
                          onClick={() => {
                            rejectedPopupMethod(data);
                          }}
                        >
                          Decline
                        </Button>

                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={() => {
                            viewProfile(data.seeker_id);
                            handleChange();
                          }}
                        >
                          View Profile
                        </Button>
                      </CardActions>
                    </Card>
                  ))}
                </div>

                <div className="pagination">
                  {pendingPagination > 10 && (
                    <Button variant="outlined" onClick={pendingLoadLess}>
                      Load Less
                    </Button>
                  )}
                  {pendingPagination < apiDataPen.length && (
                    <Button variant="contained" onClick={pendingLoadMore}>
                      Load More
                    </Button>
                  )}
                </div>
              </div>
            </>
          )
        ) : activePaper === "offered-jobs" ? (
          apiDataApr.length === 0 ? (
            <Typography sx={{ textAlign: "center" }}>
              <h3>No Data Available</h3>
            </Typography>
          ) : (
            <>
              <div className="main-div">
                <div className="cards">
                  {apiDataApr.slice(0, approvePagination).map((data, index) => (
                    <Card className="car" key={index}>
                      <CardContent>
                        <Typography sx={{ marginY: 0.5 }}>
                          <div className="jobDetails">
                            <span className="font">Job Title:</span>
                            <div>{data.job_title}</div>
                          </div>
                        </Typography>
                        <Typography sx={{ marginY: 0.5 }}>
                          <div className="jobDetails">
                            <span className="font">Seeker ID:</span>
                            <div>{data.seeker_id}</div>
                          </div>
                        </Typography>
                        <Typography sx={{ marginY: 0.5 }}>
                          <div className="jobDetails">
                            <span className="font">Name:</span>
                            <div>{data.seeker_name}</div>
                          </div>
                        </Typography>
                        <Typography sx={{ marginY: 0.5 }}>
                          <div className="jobDetails">
                            <span className="font">Email ID:</span>
                            <div>
                              <Typography
                                gutterBottom
                                sx={{
                                  wordWrap: "break-word",
                                }}
                              >
                                {data.seeker_email}
                              </Typography>
                            </div>
                          </div>
                        </Typography>
                        <Typography sx={{ marginY: 0.5 }}>
                          <div className="jobDetails">
                            <span className="font">Mobile Number:</span>
                            <div>{data.seeker_mobile_number}</div>
                          </div>
                        </Typography>
                        <Typography sx={{ marginY: 0.5 }}>
                          <div className="jobDetails">
                            <span className="font">Applied Date:</span>
                            <div>{data.apply_date}</div>
                          </div>
                        </Typography>
                        <Typography
                          sx={{
                            marginY: 0.5,
                            display:
                              data.notes === "" || data.notes === undefined
                                ? "none"
                                : "block",
                          }}
                        >
                          <div className="jobDetails">
                            <span className="font">Comments:</span>
                            <div
                              style={{
                                maxHeight: "4em",
                                overflowY: "auto",
                                height: "50px",
                              }}
                            >
                              {data.notes}
                            </div>
                          </div>
                        </Typography>
                        <Typography sx={{ marginY: 0.5 }}>
                          <div className="jobDetails">
                            <span className="font">Status:</span>
                            <div>
                              {data.status === "P"
                                ? "Pending"
                                : data.status === "A"
                                ? "Approved"
                                : "Rejected"}
                            </div>
                          </div>
                        </Typography>

                        <CardActions sx={{ justifyContent: "end" }}>
                          <Button
                            variant="contained"
                            color="success"
                            size="small"
                            disabled={data.status === "A" ? true : false}
                            onClick={() => {
                              approvePopupMethod(data);
                            }}
                          >
                            Approve
                          </Button>
                          <Button
                            variant="contained"
                            color="error"
                            size="small"
                            disabled={data.status === "R" ? true : false}
                            onClick={() => {
                              rejectedPopupMethod(data);
                            }}
                          >
                            Decline
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => {
                              viewProfile(data.seeker_id);
                              handleChange();
                            }}
                          >
                            View Profile
                          </Button>
                        </CardActions>
                      </CardContent>
                    </Card>
                  ))}
                </div>
                <div className="pagination">
                  {approvePagination > 10 && (
                    <Button variant="outlined" onClick={approveLoadLess}>
                      Load Less
                    </Button>
                  )}
                  {approvePagination < apiDataApr.length && (
                    <Button variant="contained" onClick={approveLoadMore}>
                      Load More
                    </Button>
                  )}
                </div>
              </div>
            </>
          )
        ) : apiDataRej.length === 0 ? (
          <Typography sx={{ textAlign: "center" }}>
            <h3>No Data Available</h3>
          </Typography>
        ) : (
          <>
            <div className="main-div">
              <div className="cards">
                {apiDataRej.slice(0, rejectedPagination).map((data, index) => (
                  <Card className="car" key={index}>
                    <Typography sx={{ marginY: 0.5 }}>
                      <div className="jobDetails">
                        <span className="font">Job Title:</span>
                        <div>{data.job_title}</div>
                      </div>
                    </Typography>
                    <Typography sx={{ marginY: 0.5 }}>
                      <div className="jobDetails">
                        <span className="font">Seeker ID:</span>
                        <div>{data.seeker_id}</div>
                      </div>
                    </Typography>
                    <Typography sx={{ marginY: 0.5 }}>
                      <div className="jobDetails">
                        <span className="font">Name:</span>
                        <div>{data.seeker_name}</div>
                      </div>
                    </Typography>
                    <Typography sx={{ marginY: 0.5 }}>
                      <div className="jobDetails">
                        <span className="font">Email ID:</span>
                        <div>
                          <Typography
                            gutterBottom
                            sx={{
                              wordWrap: "break-word",
                            }}
                          >
                            {data.seeker_email}
                          </Typography>
                        </div>
                      </div>
                    </Typography>
                    <Typography sx={{ marginY: 0.5 }}>
                      <div className="jobDetails">
                        <span className="font">Mobile Number:</span>
                        <div>{data.seeker_mobile_number}</div>
                      </div>
                    </Typography>
                    <Typography sx={{ marginY: 0.5 }}>
                      <div className="jobDetails">
                        <span className="font">Applied Date:</span>
                        <div>{data.apply_date}</div>
                      </div>
                    </Typography>
                    <Typography
                      sx={{
                        marginY: 0.5,
                        display:
                          data.notes === "" || data.notes === undefined
                            ? "none"
                            : "block",
                      }}
                    >
                      <div className="jobDetails">
                        <span className="font">Comments:</span>
                        <div
                          style={{
                            maxHeight: "4em",
                            overflowY: "auto",
                            height: "50px",
                          }}
                        >
                          {data.notes}
                        </div>
                      </div>
                    </Typography>
                    <Typography sx={{ marginY: 0.5 }}>
                      <div className="jobDetails">
                        <span className="font">Status:</span>
                        <div>
                          {data.status === "P"
                            ? "Pending"
                            : data.status === "A"
                            ? "Approved"
                            : "Rejected"}
                        </div>
                      </div>
                    </Typography>

                    <CardActions sx={{ justifyContent: "end" }}>
                      <Button
                        variant="contained"
                        color="success"
                        size="small"
                        disabled={data.status === "A" ? true : false}
                        onClick={() => {
                          approvePopupMethod(data);
                        }}
                      >
                        Approve
                      </Button>
                      <Button
                        variant="contained"
                        color="error"
                        size="small"
                        disabled={data.status === "R" ? true : false}
                        onClick={() => {
                          // rejectPutCall(data._id);
                        }}
                      >
                        Decline
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => {
                          viewProfile(data.seeker_id);
                          handleChange();
                        }}
                      >
                        View Profile
                      </Button>
                    </CardActions>
                  </Card>
                ))}
              </div>
              <div className="pagination">
                {rejectedPagination > 10 ? (
                  <Button variant="contained" onClick={rejectedLoadLess}>
                    Load Less
                  </Button>
                ) : (
                  <></>
                )}
                {rejectedPagination < apiDataRej.length && (
                  <Button variant="contained" onClick={rejectedLoadMore}>
                    Load More
                  </Button>
                )}
              </div>
            </div>
          </>
        )}
        <SeekerDialog
          open={open}
          handleChange={handleChange}
          getOne={getOneSeeker}
          setOpen={setOpen}
        />
        <Dialog
          open={approvePopup}
          afApproveDetails={afApproveDetails}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{ "& .MuiPaper-root": { width: "500px" } }}
        >
          <DialogTitle
            id="alert-dialog-title"
            bgcolor="rgb(16 143 178)"
            sx={{ color: "white", fontWeight: 600 }}
          >
            {"Information"}
          </DialogTitle>
          <DialogContent sx={{ mt: 3 }}>
            <DialogContentText id="alert-dialog-description">
              Are you sure want to Approve ?
            </DialogContentText>
            <Grid item xs={12} sx={{ marginTop: 1 }}>
              <Typography>Comments:</Typography>
              <ReactQuill
                name="notes"
                defaultValue={"Reason of selected Your profile is"}
                onChange={(e) => {
                  setAfApproveMsg(e);
                }}
                modules={{
                  toolbar: [
                    ["bold", "italic", "underline", "strike"],
                    ["blockquote", "code-block"],
                    [{ list: "ordered" }, { list: "bullet" }],
                    [{ header: [1, 2, 3, 4, 5, 6, false] }],
                    [{ color: [] }, { background: [] }],
                    [{ font: [] }],
                    [{ align: [] }],
                    ["link", "image"],
                    ["clean"],
                    [{}],
                  ],
                }}
                multiline
                rows={3}
                placeholder="Write something amazing..."
                className="scrollable-container-postjob"
              />
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setApprovePopup(false);
                formik.handleReset("");
              }}
            >
              No
            </Button>
            <Button
              onClick={() => {
                approvePutCall(afApproveDetails);
              }}
              autoFocus
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={rejectedPopup}
          afRejectedDetails={afRejectedDetails}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{ "& .MuiPaper-root": { width: "500px" } }}
        >
          <DialogTitle
            id="alert-dialog-title"
            bgcolor="rgb(16 143 178)"
            sx={{ color: "white", fontWeight: 600 }}
          >
            {"Information"}
          </DialogTitle>
          <DialogContent sx={{ mt: 3 }}>
            <DialogContentText id="alert-dialog-description">
              Are you sure want to Decline ?
            </DialogContentText>
            <Grid item xs={12} sx={{ marginTop: 1 }}>
              <Typography>Comments:</Typography>
              <ReactQuill
                defaultValue={"Reason of Rejected your Profile is "}
                name="notes"
                onChange={(e) => {
                  setAfDeclineMsg(e);
                }}
                modules={{
                  toolbar: [
                    ["bold", "italic", "underline", "strike"],
                    ["blockquote", "code-block"],
                    [{ list: "ordered" }, { list: "bullet" }],
                    [{ header: [1, 2, 3, 4, 5, 6, false] }],
                    [{ color: [] }, { background: [] }],
                    [{ font: [] }],
                    [{ align: [] }],
                    ["link", "image"],
                    ["clean"],
                    [{}],
                  ],
                }}
                multiline
                rows={3}
                placeholder="Write something amazing..."
                className="scrollable-container-postjob"
              />
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setRejectedPopup(false);
                formik.handleReset("");
              }}
            >
              No
            </Button>
            <Button
              onClick={() => {
                rejectPutCall(afRejectedDetails);
                formik.handleReset("");
              }}
              autoFocus
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={applyJob}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{ "& .MuiPaper-root": { width: "500px" } }}
        >
          <DialogTitle
            id="alert-dialog-title"
            bgcolor="rgb(16 143 178)"
            sx={{ color: "white", fontWeight: 600 }}
          >
            {"Information"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" sx={{ mt: 3 }}>
              No One Applied for Your Company!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                navigate(
                  ROUTINGDATA.LAND +
                    ROUTINGDATA.MAIN +
                    ROUTINGDATA.EMPLOYERUPDATE,
                  {
                    state: userDetails.emailId,
                  }
                );
                setApplyJob(false);
              }}
              autoFocus
            >
              ok
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={pendingJob}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{ "& .MuiPaper-root": { width: "500px" } }}
        >
          <DialogTitle
            id="alert-dialog-title"
            bgcolor="rgb(16 143 178)"
            sx={{ color: "white", fontWeight: 600 }}
          >
            {"Information"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" sx={{ mt: 3 }}>
              No Profile is available for this {filterDetails} filter!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setPendingJob(false);
                getFilterValue("Total");
                setFilterName({ ...filterName, date: "Total" });
              }}
            >
              ok
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={selectProfilePopup}
          afApproveDetails={afApproveDetails}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{ "& .MuiPaper-root": { width: "500px" } }}
        >
          <form onSubmit={formik.handleSubmit}>
            <DialogTitle
              id="alert-dialog-title"
              bgcolor="rgb(16 143 178)"
              sx={{ color: "white", fontWeight: 600 }}
            >
              {"Confirmation"}
            </DialogTitle>
            <DialogContent sx={{ mt: 3 }}>
              <DialogContentText id="alert-dialog-description">
                This Profile is Selected for Interview
              </DialogContentText>
              <Grid item xs={12} sx={{ marginTop: 1 }}>
                <Typography>Comments:</Typography>
                <ReactQuill
                  name="notes"
                  value={formik.values.notes}
                  //ref={quill}
                  onChange={(e) => {
                    formik.setFieldValue("notes", e);
                  }}
                  // onBlur={formik.handleBlur}
                  modules={{
                    toolbar: [
                      ["bold", "italic", "underline", "strike"],
                      ["blockquote", "code-block"],
                      [{ list: "ordered" }, { list: "bullet" }],
                      [{ header: [1, 2, 3, 4, 5, 6, false] }],
                      [{ color: [] }, { background: [] }],
                      [{ font: [] }],
                      [{ align: [] }],
                      ["link", "image"],
                      ["clean"],
                      [{}],
                    ],
                  }}
                  multiline
                  rows={3}
                  placeholder="Write something amazing..."
                  className="scrollable-container-postjob"
                />
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setSelectProfilePopup(false);
                  formik.handleReset("");
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  approvePendingCall(afPendingDetails);
                  formik.handleReset();
                }}
                autoFocus
                type="submit"
              >
                Confirm
              </Button>
            </DialogActions>
          </form>
        </Dialog>
        <Dialog
          open={noProfile}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{ "& .MuiPaper-root": { width: "500px" }, zIndex: "9" }}
        >
          <DialogTitle
            id="alert-dialog-title"
            bgcolor="rgb(16 143 178)"
            sx={{ color: "white", fontWeight: 600 }}
          >
            {"Information"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              sx={{ mt: 3, color: "black", fontWeight: 500 }}
            >
              {activePaper === "pending-profiles"
                ? "No Pending Profile to Display!"
                : activePaper === "offered-jobs"
                ? "No Offered Profile to Display!"
                : activePaper === "rejected-profiles"
                ? "No Rejected Profile to Display!"
                : "No Applicants Profile to Display!"}
            </DialogContentText>
            <DialogContentText id="alert-dialog-description" sx={{ mt: 1 }}>
              Please make a different filter selection.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={() => {
                setNoProfile(false);
              }}
              //autoFocus
            >
              ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  } else {
    return (
      <div
        style={{
          height: "70vh",
          display: "grid",
          placeItems: "center",
        }}
      >
        <div class="dots"></div>
      </div>
    );
  }
}

export default SeekersApplied;
