import React from "react";
import {
  Box,
  Card,
  Grid,
  styled,
  Typography,
  IconButton,
  Tooltip,
} from "@mui/material";
import CountUp from "react-countup";
import {
  HourglassFullRounded as HourglassFullRoundedIcon,
  AddTaskRounded as AddTaskRoundedIcon,
} from "@mui/icons-material";
import { ROUTINGDATA } from "../../../ROUTINGDATA";
import { useNavigate } from "react-router-dom";
const StyledCard = styled(Card)(({ theme }) => ({
  "&.MuiCard-root": {
    [theme.breakpoints.down("sm")]: {
      padding: "18px !important",
      flexDirection: "row",
      gap: "10px",
    },

    padding: "25px",
    borderRadius: ".25rem",

    display: "flex",
    flexDirection: "column",
    alignContent: "space-between",
    boxShadow: "0 0 8px 2px rgba(0,0,0,0.1) !important",
    cursor: "pointer",
    transition: "0.2s",
    "&.MuiCard-root:hover": {
      boxShadow: "0 0 8px 2px rgba(0,0,0,0.4) !important",
    },
  },
}));

function VolunteerNeedsupportCard({ state }) {
  const verifierVolunteer = JSON.parse(localStorage.getItem("volunteer"));
  const volunteerValue = `Volunteer_${verifierVolunteer}`;
  const navigate = useNavigate();
  const pendingList = state.filter((item) => item.shortList === "");

  const supportedList = 0;
  const shortListed = state.filter((item) => {
    return item.shortList === `Volunteer_${verifierVolunteer}`;
  });
  const [total, pending, shortlist, supported] = [
    state,
    pendingList,
    shortListed,
    supportedList,
  ];
  const handleClick = (event) => {
    sessionStorage.setItem("vol_act", event);
  };

  const cardList = [
    {
      name: "Total Need Support",
      count: total.length,
      bgColor: "#1976D2",
      svg: (
        <IconButton
          size="large"
          sx={{
            backgroundColor: "#1976D2",
            color: "#ffffff",
            "&.MuiIconButton-root:hover": {
              backgroundColor: "#1976D2",
              color: "#ffffff",
            },
          }}
        >
          <HourglassFullRoundedIcon fontSize="large" />
        </IconButton>
      ),
      filterValue: "all",
    },

    {
      name: "Review pending",
      count: pending.length,
      bgColor: "#FFB300",
      svg: (
        <IconButton
          size="large"
          sx={{
            backgroundColor: "#FFB300",
            color: "#ffffff",
            "&.MuiIconButton-root:hover": {
              backgroundColor: "#FFB300",
              color: "#ffffff",
            },
          }}
        >
          <AddTaskRoundedIcon fontSize="large" />
        </IconButton>
      ),
      filterValue: "Pending",
    },
    {
      name: "Shortlisted Data",
      count: shortlist.length,
      bgColor: "#6a1b9a",
      svg: (
        <IconButton
          size="large"
          sx={{
            backgroundColor: "#6a1b9a",
            color: "#ffffff",
            "&.MuiIconButton-root:hover": {
              backgroundColor: "#6a1b9a",
              color: "#ffffff",
            },
          }}
        >
          <AddTaskRoundedIcon fontSize="large" />
        </IconButton>
      ),
      filterValue: "all",
    },
    {
      name: "Supported Data",
      count: supported,
      bgColor: "#2e7d32",
      svg: (
        <IconButton
          size="large"
          sx={{
            backgroundColor: "#2e7d32",
            color: "#ffffff",
            "&.MuiIconButton-root:hover": {
              backgroundColor: "#2e7d32",
              color: "#ffffff",
            },
          }}
        >
          <AddTaskRoundedIcon fontSize="large" />
        </IconButton>
      ),
      filterValue: volunteerValue,
    },
  ];

  return (
    <>
      <Grid
        container
        direction="row"
        spacing={5}
        md={12}
        sx={{ mb: "24px", width: "100%" }}
      >
        {cardList.map((item, index) => (
          <Grid item xs={12} md={3} key={index}>
            <Tooltip title="Go to this Filter" arrow>
              <StyledCard
                sx={{ background: "#ffffff" }}
                elevation={6}
                onClick={() => {
                  if (index === 2) {
                    navigate(
                      ROUTINGDATA.LAND +
                        ROUTINGDATA.volunteerPanel +
                        "/" +
                        ROUTINGDATA.volunteerShortlisted
                    );
                  } else if (index === 3) {
                    navigate(
                      ROUTINGDATA.LAND +
                        ROUTINGDATA.volunteerPanel +
                        "/" +
                        ROUTINGDATA.volunteerSupported
                    );
                  } else {
                    navigate(
                      ROUTINGDATA.LAND +
                        ROUTINGDATA.volunteerPanel +
                        "/" +
                        ROUTINGDATA.volunteerNeedsupport,
                      {
                        state: { volunteerRadioValue: item.filterValue },
                      }
                    );
                    handleClick("needsupport");
                  }
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{ color: "white" }}
                    display={"flex"}
                    alignItems={"center"}
                    // bgcolor={item.bgColor}
                  >
                    {item.svg}
                  </Box>

                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Box display={"flex"} justifyContent={"flex-end"}>
                      <CountUp
                        style={{
                          fontSize: "26px",
                          color: "black",
                        }}
                        start={0}
                        end={item.count}
                        duration={1}
                      />
                    </Box>
                    <Box display={"flex"} justifyContent={"flex-end"}>
                      <Typography
                        variant="body1"
                        sx={{ fontFamily: "Poppins-SemiBold", color: "#00000" }}
                      >
                        {item.name}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </StyledCard>
            </Tooltip>
          </Grid>
        ))}
      </Grid>
    </>
  );
}

export default VolunteerNeedsupportCard;
