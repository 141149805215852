import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../Login.css";
import Box from "@mui/material/Box";
import MailLockRoundedIcon from "@mui/icons-material/MailLockRounded";
import useAxios from "../../../api/useAxios";
import ErrorSharpIcon from "@mui/icons-material/ErrorSharp";
import { ROUTINGDATA } from "../../ROUTINGDATA";
import bgImg from "../../../img/login image/job-search.jpg";
import {
  Button,
  IconButton,
  TextField,
  Grid,
  Typography,
  FormHelperText,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import Divider from "@mui/material/Divider";
import logo from "../../../img/logo_for-landingpage-3.png";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

function OtpforPassword() {
  const axiosData = useAxios();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [openWrongOTP, setOpenWrongOTP] = React.useState(false);
  const handleClickOpenWrongOTP = () => {
    setOpenWrongOTP(true);
  };
  const handleCloseWrongOTP = () => {
    setOpenWrongOTP(false);
  };
  const [otp, setOtp] = useState();
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(30);
  let location = useLocation();
  const currentUrl = location.pathname;
  const formik = useFormik({
    initialValues: {},
    validationSchema: Yup.object({
      otp: Yup.string()
        .matches(/^[0-9]+$/, "Must be only digits")
        .required("Required"),
    }),
    onSubmit: async (values) => {
      try {
        const dataaxios = await axiosData.get(
          `password/${location.state.email_id === process.env.REACT_APP_EMAILID_FOR_ADMIN?process.env.REACT_APP_OTP_EMAIL_FOR_ADMIN: location.state.email_id}`,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        const otpvalue = dataaxios.data.otp;
        if (otpvalue == values.otp) {
          setOtp(otp);
          navigate(`${ROUTINGDATA.LAND}${ROUTINGDATA.ForgotPwOtp}`, {
            state: { email_id: location.state.email_id },
          });
          try {
            await axiosData.delete(`password/${location.state.email_id === process.env.REACT_APP_EMAILID_FOR_ADMIN?process.env.REACT_APP_OTP_EMAIL_FOR_ADMIN: location.state.email_id}`, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            });
          } catch (err) {
            console.log(err);
          }
        } else if (
          dataaxios.data.otp === undefined ||
          dataaxios.data.otp === null
        ) {
          handleClickOpen();
        } else {
          handleClickOpenWrongOTP();
        }
      } catch (err) {
        console.log(err);
      }
    },
  });
  useEffect(() => {
    const interval = setInterval(async () => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          try {
            await axiosData.delete(`password/${location.state.email_id === process.env.REACT_APP_EMAILID_FOR_ADMIN?process.env.REACT_APP_OTP_EMAIL_FOR_ADMIN: location.state.email_id}`, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            });
          } catch (err) {
            console.log(err);
          }
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  useEffect(() => {
    window.history.forward();
    const url = currentUrl;
    window.onbeforeunload = function () {
      try {
        axiosData.delete(`password/${location.state.email_id === process.env.REACT_APP_EMAILID_FOR_ADMIN?process.env.REACT_APP_OTP_EMAIL_FOR_ADMIN: location.state.email_id}`, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      } catch (err) {
        console.log(err);
      }
      return "Are you sure?";
    };
  }, []);
  const resendOTP = async () => {
    setMinutes(1);
    setSeconds(30);
    const email_id = `${location.state.email_id === process.env.REACT_APP_EMAILID_FOR_ADMIN?process.env.REACT_APP_OTP_EMAIL_FOR_ADMIN: location.state.email_id}`;
    try {
      await axiosData.post("password", { email_id });
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <React.Fragment>
      <div id="forgotpasswordpage" className="forgotpasswordpage">
        <div className="forgotpasswordpage-logo-top">
          <div className="forgotpasswordimg">
            <img src={logo} />
          </div>
        </div>
        <div className="forgotpassword-input-box">
          <form onSubmit={formik.handleSubmit}>
            <div className="login-reg">
              <p>
                Welcome to{" "}
                <span>
                  <text>VELAI</text>VENDUM.com
                </span>
              </p>
            </div>
            <Divider />
            <div className="login-space">
              <div className="login-title">
                <h2>Forgot Password</h2>
              </div>
              <div className="forgotpassword-contect">
                <p>
                  Enter your correct Otp and then only you can change your password
                </p>
              </div>
              <div className="space-forgotpassword">
                <div className="login-textfield-1">
                  <label>Enter Otp</label>
                  <TextField
                    id="arrow-hide-otp-page"
                    variant="outlined"
                    size="small"
                    type="number"
                    className="arrow-hide-otp-page"
                    placeholder="- - - -"
                    inputProps={{
                      maxLength: 4,
                    }}
                    name="otp"
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 4);
                    }}
                    onChange={formik.handleChange}
                    value={formik.values.otp}
                    onBlur={formik.handleBlur}
                    fullWidth
                  />
                  <FormHelperText>
                    {/* {formik.touched.otp && formik.errors.otp ? (
                        <Typography sx={{ color: "red", fontSize: "13px" }}>
                          {formik.errors.otp}
                        </Typography>
                      ) : (
                        <Typography
                          sx={{ fontSize: "13px", visibility: "hidden" }}
                        >
                          none
                        </Typography>
                      )} */}
                    {seconds > 0 || minutes > 0 ? (
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontFamily: " Poppins-Regular",
                        }}
                      >
                        Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}
                        :{seconds < 10 ? `0${seconds}` : seconds}
                      </Typography>
                    ) : (
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontFamily: " Poppins-Regular",
                        }}
                      >
                        "Didn't receive OTP?"
                        <span onClick={resendOTP} style={{color:"red",marginLeft:"5px",cursor:"pointer"}}>Resend OTP</span>
                      </Typography>
                    )}
                  </FormHelperText>
                </div>
                <div className="loginbtn">
                  <Button variant="contained"  disabled={formik.isValid && formik.dirty ? false : true} fullWidth type="submit">
                  Submit
                  </Button>
                </div>
                <div className="back-to-loginpage">
                  <ArrowBackIosIcon fontSize="13px" sx={{ color: "#2b7695",visibility:"hidden" }} />
                  <text style={{visibility:"hidden"}} >Back to Login</text>
                </div>
              </div>
            </div>
          </form>
        </div>
        <Dialog
        open={open}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "#339fcd",
            borderRadius: "10px",
            padding: "10px 10px",
          },
        }}
      >
        <Box>
          <DialogTitle sx={{ fontFamily: "Poppins-SemiBold" }}>
            <div style={{ display: "flex", gap: "3px", alignItems: "center" }}>
              <ErrorSharpIcon
                sx={{ mt: -0.3, color: "crimson" }}
                fontSize="large"
              />
              <Typography variant="h5" sx={{ ml: 0.3 }}>
                User has entered expired OTP!
              </Typography>
            </div>
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              sx={{ fontFamily: "Poppins-Medium", color: "crimson" }}
            >
              Please click Resend OTP and enter OTP again.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              sx={{
                textTransform: "lowercase",
                color: "#ed1067",
                borderColor: "#ed1067",
                fontFamily: "Poppins-Medium",
                "&:hover": {
                  textTransform: "lowercase",
                  color: "white",
                  backgroundColor: "#ed1067",
                  borderColor: "#ed1067",
                  fontFamily: "Poppins-Medium",
                },
              }}
              onClick={() => {
                formik.handleReset("");
                handleClose();
              }}
            >
              Ok
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      <Dialog
        open={openWrongOTP}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "#339fcd",
            borderRadius: "10px",
            padding: "10px 10px",
          },
        }}
      >
        <Box>
          <DialogTitle sx={{ fontFamily: "Poppins-SemiBold" }}>
            <div style={{ display: "flex", gap: "3px", alignItems: "center" }}>
              <ErrorSharpIcon
                sx={{ mt: -0.3, color: "crimson" }}
                fontSize="large"
              />
              <Typography variant="h5" sx={{ ml: 0.3 }}>
                User has entered Wrong OTP!
              </Typography>
            </div>
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              sx={{ fontFamily: "Poppins-Medium", color: "crimson" }}
            >
              Please enter correct OTP.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              sx={{
                textTransform: "lowercase",
                color: "#ed1067",
                borderColor: "#ed1067",
                fontFamily: "Poppins-Medium",
                "&:hover": {
                  textTransform: "lowercase",
                  color: "white",
                  backgroundColor: "#ed1067",
                  borderColor: "#ed1067",
                  fontFamily: "Poppins-Medium",
                },
              }}
              onClick={() => {
                formik.handleReset("");
                handleCloseWrongOTP();
              }}
            >
              Ok
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      </div>
    </React.Fragment>
  );
}

export default OtpforPassword;
