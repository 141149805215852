import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import DescriptionIcon from "@mui/icons-material/Description";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../api/useAxios";
import {
  TextField,
  Tooltip,
  IconButton,
  CircularProgress,
} from "@mui/material";
import WestIcon from "@mui/icons-material/West";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Autocomplete from "@mui/material/Autocomplete";
import { useFormik } from "formik";
import { industry } from "../../skeleton/jobseeker/cdata";
import moment from "moment";
import Grid from "@mui/system/Unstable_Grid/Grid";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import employerProfile from "../../../img/employerProfile/employerUpdate-logo.png";
import { ROUTINGDATA } from "../../ROUTINGDATA";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const renderParagraphs = (description) => {
  const parser = new DOMParser();
  const htmlDoc = parser.parseFromString(description, "text/html");
  const paragraphs = htmlDoc.querySelectorAll("p");
  const listItems = htmlDoc.querySelectorAll("li");

  for (let i = 0; i < paragraphs.length; i++) {
    const paragraph = paragraphs[i];
    if (paragraph.innerHTML.trim() === "<br>") {
      paragraph.parentNode.removeChild(paragraph);
    }
  }

  for (let i = 0; i < listItems.length; i++) {
    const listItem = listItems[i];
    if (listItem.innerHTML.trim() === "<br>") {
      listItem.parentNode.removeChild(listItem);
    }
  }

  const firstElement = [...paragraphs, ...listItems].find(
    (element) => element.innerText.trim() !== ""
  );

  if (listItems) {
    listItems.forEach((item) => {
      item.classList.add("selected-li");
    });
  }

  return firstElement ? firstElement.outerHTML : null;
};

const getTodayDate = moment().format("DD-MM-YYYY");
function Searchpage() {
  const seeker = JSON.parse(localStorage.getItem("seeker"));
  let location = useLocation();
  let params = useParams();
  const axiosData = useAxios();
  const navigate = useNavigate();
  const [jobDetails, setJobDetails] = useState([]);
  const [jobDetails1, setJobDetails1] = useState();

  const [seekerProfileDetails, setSeekerProfileDetails] = useState();
  const [Applyed, setApplyed] = useState([]);
  const [jobApplied, setJobApplied] = useState(false);
  const [description, setDescription] = useState([]);
  const [description2, setDescription2] = useState([]);
  const [openMoreDetails, setOpenMoreDetails] = useState(false);
  const [openMoreDetails2, setOpenMoreDetails2] = useState(false);
  const [jobDescription, setJobDescription] = useState([]);
  const [jobDescription2, setJobDescription2] = useState();
  let seekerDetails = JSON.parse(localStorage.getItem("seeker"));
  const [searchPopUp, setSearchPopUp] = useState(false);
  const [mobilesearch, setmobilesearch] = useState(false);
  const [searchQuery, setSearchQuery] = useState([]);
  const [suggestionArray, setSuggestionArray] = useState([]);
  const [suggestionArrayl, setSuggestionArrayl] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState({
    key_skills: null,
    job_location: null,
    industry: null,
    page_number: 1,
  });
  const [loadMore, setLoadMore] = useState(false);
  const [loadDisabled, setLoadDisabled] = useState([]);
  const [loading, setLoading] = useState(true);
  const [login, setLogin] = useState(false);
  const [noResultPopUp, setNoResultPopUp] = useState(false);
  const [totalData, setTotalData] = useState();
  const [searchTotalData, setSearchTotalData] = useState();
  const [searchjobid, setsearchjobid] = useState();
  const [higlightjob, sethiglightjob] = useState("");
  const [higlightjobs_1, sethiglightjobs_1] = useState(true);
  let emailName2;
  if (seekerDetails) {
    emailName2 = seekerDetails.emailId;
  }
  const searchResult1 = async (result) => {
    selectedOptions.industry = result.industry;
    selectedOptions.key_skills = result.key_skills;
    selectedOptions.job_location = result.job_location;
    setSelectedOptions(selectedOptions);
    if (params.id !== "result") {
      try {
        const response1 = await axiosData.get(`jobs/getOne/${params.id}`, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        if (response1.status === 200) {
          selectedOptions.key_skills = response1.data.job_title;
          result.key_skills = response1.data.job_title;
          setJobDetails1(response1.data);
          setsearchjobid(response1.data.job_id);
          setDescription2(response1.data.job_description);

          try {
            const response = await axiosData.post(`jobs/search`, result, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            });
            if (response.data) {
              if (response.data.data) {
                const activeJobs = response.data.data.filter(
                  (datas) => datas.job_status === "Active"
                );
                const filterjobs = activeJobs.filter(
                  (items) => items._id !== response1.data._id
                );
                setTotalData(response.data.total_record);
                setLoadDisabled(response.data.is_last_record_set);
                setJobDetails([...filterjobs]);
                setDescription([...filterjobs.map((job) => job.description)]);
              } else {
                const activeJobs = response.data.filter(
                  (datas) => datas.job_status === "Active"
                );
                const filterjobs = activeJobs.filter(
                  (items) => items._id !== response1.data._id
                );
                setJobDetails([...filterjobs]);
                setDescription([...filterjobs.map((job) => job.description)]);
                setTotalData(response.data.total_record);
                setLoadDisabled(response.data.is_last_record_set);
              }
              const temp = response.data.is_last_record_set;
              setLoading(false);
            }
            if (response.status === 204) {
              setSelectedOptions({
                key_skills: null,
                job_location: null,
                industry: null,
              });
              setSearchTotalData("");
              setTotalData(0);
              setLoading(false);
              setNoResultPopUp(true);
            }
          } catch (err) {
            // alert("No jobs for your search");

            console.log(err);
          }
        } else {
          sethiglightjobs_1(false);
          setSelectedOptions("");
          setSearchTotalData("");
          setTotalData(0);
          setSelectedOptions({
            key_skills: null,
            job_location: null,
            industry: null,
          });
          setSelectedOptions(selectedOptions);
          setLoading(false);
          setNoResultPopUp(true);
        }
      } catch (err) {}
    } else if (location.state.search._id) {
      try {
        const response1 = await axiosData.get(
          `urgentjobs/getOne/${location.state.search._id}`,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        setJobDetails1(response1.data);
        setsearchjobid(response1.data.job_id);
        setDescription2(response1.data.job_description);
        try {
          const response = await axiosData.post(`jobs/search`, result, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          if (response.data) {
            if (response.data.data) {
              const activeJobs = response.data.data.filter(
                (datas) => datas.job_status === "Active"
              );
              const filterjobs = activeJobs.filter(
                (items) => items._id !== response1.data.job_id
              );
              setTotalData(response.data.total_record);
              setLoadDisabled(response.data.is_last_record_set);
              setJobDetails([...filterjobs]);
              setDescription([...filterjobs.map((job) => job.description)]);
            } else {
              const activeJobs = response.data.filter(
                (datas) => datas.job_status === "Active"
              );
              const filterjobs = activeJobs.filter(
                (items) => items._id !== response1.data.job_id
              );
              setJobDetails([...filterjobs]);
              setDescription([...filterjobs.map((job) => job.description)]);
              setTotalData(response.data.total_record);
              setLoadDisabled(response.data.is_last_record_set);
            }
            const temp = response.data.is_last_record_set;
            setLoading(false);
          }
          if (response.status === 204) {
            setSelectedOptions({
              key_skills: null,
              job_location: null,
              industry: null,
            });
            setSearchTotalData("");
            setTotalData(0);
            setLoading(false);
            setNoResultPopUp(true);
          }
        } catch (err) {
          // alert("No jobs for your search");

          console.log(err);
        }
      } catch (err) {}
    } else {
      sethiglightjobs_1(false);
      sethiglightjob("");
      try {
        const response = await axiosData.post(`jobs/search`, result, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        if (response.data) {
          if (response.data.data) {
            setTotalData(response.data.total_record);
            setLoadDisabled(response.data.is_last_record_set);
            setJobDetails([...response.data.data]);
            setDescription([
              ...response.data.data.map((job) => job.description),
            ]);
          } else {
            setJobDetails([...response.data]);
            setDescription([...response.data.map((job) => job.description)]);
            setTotalData(response.data.total_record);
            setLoadDisabled(response.data.is_last_record_set);
          }
          const temp = response.data.is_last_record_set;
          setLoading(false);
        }
        if (response.status === 204) {
          setSelectedOptions("");
          setSearchTotalData("");
          setTotalData(0);
          setSelectedOptions({
            key_skills: null,
            job_location: null,
            industry: null,
          });
          setSelectedOptions(selectedOptions);
          setLoading(false);
          setNoResultPopUp(true);
        }
      } catch (err) {
        // alert("No jobs for your search");

        console.log(err);
      }
    }
  };

  useEffect(() => {
    if (params.id !== "result") {
      if (emailName2) {
        navigate(
          `${ROUTINGDATA.LAND}${ROUTINGDATA.MAIN}${ROUTINGDATA.seekerProfile}`,
          { state: emailName2 }
        );
        navigate("/main/RecommendedJob", {
          state: {
            industry: {
              industry: "",
              key_skills: "",
              job_location: "",
              page_number: 1,
              job_id: params.id,
            },
            _id: seekerDetails._id,
            email_id: emailName2,
            from: "LandingPage",
          },
        });
      } else {
        sethiglightjob(params.id);
        searchResult1({
          industry: "",
          key_skills: "",
          job_location: "",
          page_number: 1,
        });
      }
    } else {
      sethiglightjob(location.state.search._id);
      searchResult1(location.state.search);
    }
  }, []);
  const moreDetails2 = (description) => {
    setOpenMoreDetails2(true);
    setJobDescription2(jobDetails1.job_description);
  };
  const moreDetails = (description) => {
    setOpenMoreDetails(true);
    setJobDescription(
      jobDetails.find((item) => item.job_description === description)
    );
  };
  const baseUrl = axiosData.defaults.baseURL;
  const Apply = async (job) => {
    setLogin(true);
  };

  const searchobj = (values, string) => {
    selectedOptions[string] = values;
    setSelectedOptions(selectedOptions);
    setSuggestionArray([]);
    setSuggestionArrayl([]);
  };

  const handleSuggestionslocation = async (event) => {
    let query = event.target.value.replace(/[^a-z., ]/gi, "");

    setSearchQuery(query);
    searchobj(query, "job_location");

    if (query.length >= 3) {
      try {
        const response = await axiosData.get(
          `/jobs/suggestionlocation/${query}`
        );
        setSuggestionArrayl(response.data);
        if (response.data) {
          response.data = response.data.filter((item) => {
            return !!item;
          });
          setSuggestionArrayl([...response.data]);
        } else {
          setSuggestionArrayl([]);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleSuggestionsSkills = async (event) => {
    let query = event.target.value;

    if (query.startsWith("#")) {
      query = query.slice(1);
    }

    setSuggestionArray([]);
    searchobj(query, "key_skills");
    setSearchQuery(query);
    if (query.length >= 3) {
      try {
        const response = await axiosData.get(`/jobs/suggestion/${query}`);
        setSuggestionArray(response.data);
        if (response.data) {
          response.data = response.data.filter((item) => {
            return !!item;
          });
          setSuggestionArray([...response.data]);
        } else {
          setSuggestionArray([]);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const searchResult = async (result) => {
    if (
      (result.industry === null || result.industry === "") &&
      (result.key_skills === null || result.key_skills === "") &&
      (result.job_location === null || result.job_location === "")
    ) {
      // alert("atlest one field is requried");
      setSearchPopUp(true);
    } else {
      setLoading(true);
      try {
        const response = await axiosData.post(`jobs/search`, selectedOptions, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (response.data) {
          setSearchTotalData(response.data.total_record);
          setLoadDisabled(response.data.is_last_record_set);
          setJobDetails([...response.data.data]);
          setDescription([...response.data.data.map((job) => job.description)]);

          setmobilesearch(false);
          setLoading(false);
        }
        if (response.status === 204) {
          setSelectedOptions({
            key_skills: null,
            job_location: null,
            industry: null,
          });

          setSearchTotalData("");
          setTotalData(0);
          setJobDetails([]);
          setDescription([]);
          setLoading(false);
          setNoResultPopUp(true);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleSearch = () => {
    selectedOptions.page_number = 1;
    setSelectedOptions(selectedOptions);
    searchResult(selectedOptions);
    sethiglightjobs_1(false);
    sethiglightjob("");
  };

  const loadMoreData = async () => {
    setLoadMore(true);
    if (
      selectedOptions.industry === null &&
      selectedOptions.key_skills === null &&
      selectedOptions.job_location === null
    ) {
      // alert("atlest one field is requried");
      setSearchPopUp(true);
    } else {
      try {
        const response = await axiosData.post(`jobs/search`, selectedOptions, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (response.data) {
          if (higlightjob) {
            const filterjobs = response.data.data.filter(
              (items) => items._id !== searchjobid
            );
            setLoadDisabled(response.data.is_last_record_set);
            setJobDetails([...jobDetails, ...filterjobs]);
            setDescription([
              ...description,
              ...filterjobs.map((job) => job.description),
            ]);
            setmobilesearch(false);
            setLoading(false);
          } else {
            sethiglightjobs_1(false);
            sethiglightjob("");
            setLoadDisabled(response.data.is_last_record_set);
            setJobDetails([...jobDetails, ...response.data.data]);
            setDescription([
              ...description,
              ...response.data.data.map((job) => job.description),
            ]);
            setmobilesearch(false);
            setLoading(false);
          }
        }
        if (response.status === 204) {
          setSelectedOptions({
            key_skills: null,
            job_location: null,
            industry: null,
          });
          setSelectedOptions(selectedOptions);
          setSearchTotalData("");
          setTotalData(0);
          setLoading(false);
          setNoResultPopUp(true);
        }
      } catch (err) {
        // alert("No jobs for your search");

        console.log(err);
      }
    }
    setLoadMore(false);
  };

  const filterOptions = (options, { inputValue }) => {
    const inputValueLower = inputValue.toLowerCase();
    const startsWithInput = [];
    const containsInput = [];

    options.forEach((option) => {
      const optionLower = option.toLowerCase();
      if (optionLower.startsWith(inputValueLower)) {
        startsWithInput.push(option);
      } else if (optionLower.includes(inputValueLower)) {
        containsInput.push(option);
      }
    });

    return [...startsWithInput, ...containsInput];
  };

  const [industryArray, setIndustryArray] = useState();

  const IndustryData = async () => {
    try {
      const getOneAxios = await axiosData.get(`/General/seekerSettings`);
      const data = getOneAxios?.data?.industryData;
      if (data) {
        setIndustryArray(data);
      }
      return data;
    } catch (err) {
      console.log("Error", err);
    }
  };

  useEffect(() => {
    IndustryData();
  }, []);

  if (loading === false) {
    return (
      <div>
        <div
          style={{
            marginTop: "15px",
            marginBottom: "15px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Typography sx={{ fontSize: "28px", fontWeight: 600 }}>
              Search Jobs
            </Typography>
          </div>
          <div>
            <Tooltip
              arrow
              onClick={() => {
                navigate("/");
              }}
              className="GoBackTooltip"
              title="Go Back"
            >
              <IconButton
                className="GoBackTooltip  arrow"
                sx={{ backgroundColor: "#339fcd" }}
              >
                <WestIcon sx={{ color: "#fff" }} />
              </IconButton>
            </Tooltip>
          </div>
        </div>

        <div id="search-part" className="search-part">
          <div className="search-auto" style={{ border: "1px solid #108fb2" }}>
            <Autocomplete
              disablePortal
              freeSolo
              options={suggestionArray}
              value={selectedOptions.key_skills}
              onChange={(event, value) => {
                searchobj(value, "key_skills");
              }}
              disableUnderline={false}
              sx={{ width: { xs: 350, md: 330, lg: 350 } }}
              renderInput={(params) => (
                <TextField
                  disableUnderline={false}
                  className="autocom-search-first"
                  sx={{
                    "& fieldset": { border: "none" },
                  }}
                  onChange={handleSuggestionsSkills}
                  {...params}
                  label={"Enter KeySkills / Designations / Companies"}
                  size="small"
                />
              )}
            />
            {industryArray && (
              <Autocomplete
                disablePortal
                id="Select_experience"
                name="Select_experience"
                filterOptions={filterOptions}
                options={industryArray}
                value={selectedOptions.industry}
                onChange={(event, value) => {
                  searchobj(value, "industry");
                }}
                sx={{ width: { xs: 350, md: 180, lg: 300 } }}
                renderInput={(params) => (
                  <TextField
                    className="autocom-search-first"
                    sx={{
                      "& fieldset": { border: "none" },
                    }}
                    {...params}
                    label={"Enter Industry"}
                    size="small"
                  />
                )}
              />
            )}
            <Autocomplete
              disablePortal
              freeSolo
              id="Enter_location"
              name="Enter_location"
              options={suggestionArrayl}
              onChange={(event, value) => searchobj(value, "job_location")}
              value={selectedOptions.job_location}
              sx={{ width: { xs: 350, md: 180, lg: 300 } }}
              renderInput={(params) => (
                <TextField
                  className="autocom-search"
                  onChange={handleSuggestionslocation}
                  {...params}
                  sx={{
                    "& fieldset": { border: "none" },
                  }}
                  label={"Enter Location"}
                  size="small"
                />
              )}
            />
            <Button
              sx={{
                borderRadius: "50px",
                padding: "10px 33px",
              }}
              onClick={handleSearch}
              variant="contained"
            >
              {"search"}
            </Button>
          </div>
        </div>

        <Box
          className="mobile-view-search"
          sx={{ display: { xs: "flex", md: "none", lg: "none" } }}
        >
          <Button
            variant="outlined"
            className={
              mobilesearch === "true" ? "mobile-search-active" : "mobile-search"
            }
            onClick={() => {
              setmobilesearch(true);
            }}
          >
            <SearchIcon sx={{ color: "#ed1067" }} />
            <p>Search...</p>
          </Button>
        </Box>

        <Drawer
          sx={{
            "& .MuiDrawer-paper": {
              height: "50%",
              background: "rbga(255,255,255,0.8)",
            },
          }}
          open={mobilesearch}
          anchor={"top"}
        >
          <div className="phone-view">
            <div className="mobile-search-a">
              <Autocomplete
                disablePortal
                freeSolo
                options={suggestionArray}
                value={selectedOptions.key_skills}
                onChange={(event, value) => {
                  searchobj(value, "key_skills");
                }}
                disableUnderline={false}
                sx={{ width: { xs: 350, md: 330, lg: 350 } }}
                renderInput={(params) => (
                  <TextField
                    disableUnderline={false}
                    className="autocom-search-first"
                    sx={{
                      "& fieldset": { border: "none" },
                    }}
                    value={searchQuery}
                    onChange={handleSuggestionsSkills}
                    {...params}
                    label={"Enter KeySkills / Designations / Companies"}
                    size="small"
                  />
                )}
              />
              {industryArray && (
                <Autocomplete
                  disablePortal
                  id="Select_experience"
                  name="Select_experience"
                  filterOptions={filterOptions}
                  options={industryArray}
                  value={selectedOptions.industry}
                  onChange={(event, value) => {
                    searchobj(value, "industry");
                  }}
                  sx={{ width: { xs: 350, md: 180, lg: 300 } }}
                  renderInput={(params) => (
                    <TextField
                      className="autocom-search-first"
                      sx={{
                        "& fieldset": { border: "none" },
                      }}
                      value={searchQuery}
                      {...params}
                      label={"Enter Industry"}
                      size="small"
                    />
                  )}
                />
              )}
              <Autocomplete
                disablePortal
                freeSolo
                id="Enter_location"
                name="Enter_location"
                options={suggestionArrayl}
                value={selectedOptions.job_location}
                onChange={(event, value) => searchobj(value, "job_location")}
                sx={{ width: { xs: 350, md: 180, lg: 300 } }}
                renderInput={(params) => (
                  <TextField
                    className="autocom-search"
                    value={searchQuery}
                    onChange={handleSuggestionslocation}
                    {...params}
                    sx={{
                      "& fieldset": { border: "none" },
                    }}
                    label={"Enter Location"}
                    size="small"
                  />
                )}
              />
              <Button
                sx={{
                  borderRadius: "50px",
                  padding: "10px 33px",
                }}
                onClick={handleSearch}
                variant="contained"
              >
                {"search"}
              </Button>
              <div className="mobile-close-btn">
                <IconButton
                  onClick={() => {
                    setmobilesearch(false);
                  }}
                >
                  <CloseIcon sx={{ color: "red" }} />
                </IconButton>
              </div>
            </div>
          </div>
        </Drawer>

        <Dialog
          TransitionComponent={Transition}
          open={noResultPopUp}
          // onClose={() => setNoResultPopUp(false)}
          sx={{ "& .MuiPaper-root": { color: "white", width: "400px" } }}
        >
          <DialogTitle
            bgcolor="rgb(16 143 178)"
            sx={{ backgroundColor: "rgb(16 143 178)", fontWeight: 600 }}
          >
            Job Search
          </DialogTitle>
          <DialogContent sx={{ mt: 3 }}>
            <DialogContentText sx={{ fontWeight: 600 }}>
              No Jobs for your search
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => {
                setSelectedOptions({
                  key_skills: null,
                  job_location: null,
                  industry: null,
                });

                setNoResultPopUp(false);
              }}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          TransitionComponent={Transition}
          open={jobApplied}
          // onClose={() => setJobApplied(false)}
          sx={{
            "& .MuiPaper-root": {
              backgroundColor: "rgb(16 143 178)",
              width: "300px",
            },
          }}
        >
          <DialogTitle
            bgcolor="rgb(16 143 178)"
            sx={{ color: "white", fontWeight: 600 }}
          >
            Job Apply
          </DialogTitle>
          <DialogContent sx={{ mt: 1 }}>
            <DialogContentText
              sx={{ color: "white", fontWeight: 600 }}
            ></DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => setJobApplied(false)}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          TransitionComponent={Transition}
          open={openMoreDetails2}
          jobDescription2={jobDescription2}
          // onClose={() => setOpenMoreDetails(false)}
          sx={{ "& .MuiPaper-root": { width: "500px" } }}
        >
          <DialogTitle
            bgcolor="rgb(16 143 178)"
            sx={{ color: "white", fontWeight: 600 }}
          >
            Job Description
          </DialogTitle>
          <DialogContent sx={{ mt: 1 }}>
            {jobDescription2 ? (
              <DialogContentText
                dangerouslySetInnerHTML={{
                  __html: jobDescription2,
                }}
              ></DialogContentText>
            ) : (
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: "16px",
                  ml: 0.5,
                  mt: 2,
                }}
                color="text.secondary"
              >
                No description for this job
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              sx={{ mr: 2, mb: 1 }}
              variant="outlined"
              onClick={() => setOpenMoreDetails2(false)}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          TransitionComponent={Transition}
          open={openMoreDetails}
          jobDescription={jobDescription}
          // onClose={() => setOpenMoreDetails(false)}
          sx={{ "& .MuiPaper-root": { width: "500px" } }}
        >
          <DialogTitle
            bgcolor="rgb(16 143 178)"
            sx={{ color: "white", fontWeight: 600 }}
          >
            Job Description
          </DialogTitle>
          <DialogContent sx={{ mt: 1 }}>
            {jobDescription.job_description ? (
              <DialogContentText
                dangerouslySetInnerHTML={{
                  __html: jobDescription.job_description,
                }}
              ></DialogContentText>
            ) : (
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: "16px",
                  ml: 0.5,
                  mt: 2,
                }}
                color="text.secondary"
              >
                No description for this job
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              sx={{ mr: 2, mb: 1 }}
              variant="outlined"
              onClick={() => setOpenMoreDetails(false)}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          TransitionComponent={Transition}
          open={searchPopUp}
          // onClose={() => setSearchPopUp(false)}
          sx={{ "& .MuiPaper-root": { color: "white", width: "400px" } }}
        >
          <DialogTitle
            bgcolor="rgb(16 143 178)"
            sx={{ backgroundColor: "rgb(16 143 178)", fontWeight: 600 }}
          >
            search
          </DialogTitle>
          <DialogContent sx={{ mt: 5, mb: 2 }}>
            <DialogContentText sx={{ fontWeight: 600 }}>
              Atleast one field is requried for search !
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => setSearchPopUp(false)}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          TransitionComponent={Transition}
          open={login}
          // onClose={() => setLogin(false)}
          sx={{ "& .MuiPaper-root": { color: "white", width: "400px" } }}
        >
          <DialogTitle
            bgcolor="rgb(16 143 178)"
            sx={{ backgroundColor: "rgb(16 143 178)", fontWeight: 600 }}
          >
            Login
          </DialogTitle>
          <DialogContent sx={{ mt: 5, mb: 2 }}>
            <DialogContentText sx={{ fontWeight: 600 }}>
              Please login first to apply job !
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="error"
              variant="outlined"
              onClick={() => setLogin(false)}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                navigate("/login");
                setLogin(false);
              }}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        <Typography
          sx={{ fontSize: "18px", ml: 1, mb: 1, mt: -4, fontWeight: 500 }}
        >
          <b style={{ fontSize: "22px" }}>
            {searchTotalData ? searchTotalData : totalData}{" "}
          </b>
          Search data founded{" "}
        </Typography>
        <div className="jobFullContend">
          <div className="recommendedJobCard">
            {higlightjobs_1 ? (
              <Card
                className="recommendedJob-InnerCard"
                key={jobDetails1._id}
                sx={{
                  mt: 2,
                  border: "5px solid #ed1e70",
                  display: higlightjobs_1 ? "block" : "none",
                }}
              >
                <div
                  className="ApplyCard"
                  sx={{ display: higlightjobs_1 ? "block" : "none" }}
                >
                  <CardContent>
                    <Typography
                      className="nameTitleServiceProvider"
                      sx={{
                        fontWeight: 600,
                        fontSize: "26px",
                        wordWrap: "break-word",
                        lineHeight: "2em",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        transition: "0.5s",
                        backgroundColor: "#22668a",
                        color: "white",
                        padding: "0px 5px",
                        textTransform: "uppercase",
                        borderRadius: "5px",
                        marginBottom: "10px",
                      }}
                    >
                      {jobDetails1.job_title}
                    </Typography>

                    <Grid container>
                      <Grid lg={9} md={9} sm={9} xs={9}>
                        <Typography
                          gutterBottom
                          sx={{ fontWeight: 600, fontSize: "20px" }}
                        >
                          {jobDetails1.company_name}
                        </Typography>
                        <Grid lg={10} md={10} sm={10} xs={10}>
                          <Typography
                            gutterBottom
                            sx={{
                              fontWeight: 600,
                              fontSize: "14px",
                              ml: 0.4,
                              wordWrap: "break-word",
                            }}
                            color="text.secondary"
                          >
                            {jobDetails1.industry}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        className="CompanySideLogo"
                        lg={3}
                        md={3}
                        sm={2}
                        xs={2}
                      >
                        <img
                          alt=""
                          id="employerProfile"
                          src={
                            jobDetails1.employer_email_id
                              ? `${baseUrl}employers/logo/email/${jobDetails1.employer_email_id}/users`
                              : null
                          }
                          onError={(e) => {
                            e.target.src = employerProfile;
                          }}
                          className="jobImgLogo"
                        />
                      </Grid>
                    </Grid>

                    <div className="JobsAlignment">
                      <div>
                        <Typography
                          className="JobsNameAlignment"
                          gutterBottom
                          sx={{ fontWeight: 600, fontSize: "14px", mr: 1 }}
                        >
                          <text>Skills</text>
                          <text>:</text>
                        </Typography>
                      </div>
                      <div>
                        <Grid lg={12} md={12} sm={12} xs={12}>
                          <Typography
                            gutterBottom
                            sx={{
                              fontWeight: 600,
                              fontSize: "14px",
                              wordWrap: "break-word",
                            }}
                            color="text.secondary"
                          >
                            {jobDetails1.key_skills.trim().replace(/,$/, ".") ||
                              null}
                          </Typography>
                        </Grid>
                      </div>
                    </div>
                    <div className="JobsAlignment">
                      <div>
                        <Typography
                          className="JobsNameAlignment"
                          gutterBottom
                          sx={{ fontWeight: 600, fontSize: "14px", mr: 1 }}
                        >
                          <text>Experience</text>
                          <text>:</text>
                        </Typography>
                      </div>
                      <div>
                        <Typography
                          gutterBottom
                          sx={{ fontWeight: 600, fontSize: "14px" }}
                          color="text.secondary"
                        >
                          {jobDetails1.experience_type === "Freshers Only"
                            ? "Freshers Only"
                            : `${jobDetails1.experience_min}${"-"}${
                                jobDetails1.experience_max
                              }`}
                        </Typography>
                      </div>
                    </div>
                    <div className="JobsAlignment">
                      <div>
                        <Typography
                          className="JobsNameAlignment"
                          gutterBottom
                          sx={{ fontWeight: 600, fontSize: "14px", mr: 1 }}
                        >
                          <text>Salary</text>
                          <text>:</text>
                        </Typography>
                      </div>
                      <div>
                        <Typography
                          gutterBottom
                          sx={{
                            fontWeight: 600,
                            fontSize: "14px",
                            wordWrap: "break-word",
                            lineHeight: "2em",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            transition: "0.5s",
                            backgroundColor: "#22668a",
                            color: "white",
                            padding: "0px 5px",
                            textTransform: "uppercase",
                            borderRadius: "5px",
                          }}
                          color="text.secondary"
                        >
                          {jobDetails1.salary_range_max
                            ? `${jobDetails1.salary_range_min}${"-"}${
                                jobDetails1.salary_range_max
                              }${
                                jobDetails1.salary_type === "Month"
                                  ? " Per Month"
                                  : jobDetails1.salary_type === "Year"
                                  ? " LPA"
                                  : null
                              }`
                            : "Not Disclosed"}
                        </Typography>
                      </div>
                    </div>
                    <div className="JobsAlignment">
                      <div>
                        <Typography
                          className="JobsNameAlignment"
                          gutterBottom
                          sx={{ fontWeight: 600, fontSize: "14px", mr: 1 }}
                        >
                          <text>Location</text>
                          <text>:</text>
                        </Typography>
                      </div>
                      <div>
                        <Typography
                          gutterBottom
                          sx={{ fontWeight: 600, fontSize: "14px" }}
                          color="text.secondary"
                        >
                          {jobDetails1.job_location}
                        </Typography>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        marginTop: "5px",
                        marginBottom: "30px",
                      }}
                    >
                      <DescriptionIcon
                        fontSize="small"
                        sx={{ color: "rgb(145 145 145)" }}
                      />
                      {jobDetails1.job_description ? (
                        <Typography
                          className="JobDescriptionOverflow"
                          gutterBottom
                          sx={{
                            fontWeight: 600,
                            fontSize: "14px",
                            ml: 0.5,
                            mt: -1.8,
                          }}
                          color="text.secondary"
                          dangerouslySetInnerHTML={{
                            __html: renderParagraphs(
                              jobDetails1.job_description
                            ),
                          }}
                        ></Typography>
                      ) : (
                        <Typography
                          sx={{
                            fontWeight: 600,
                            fontSize: "14px",
                            ml: 0.5,
                          }}
                          color="text.secondary"
                        >
                          No description for this job
                        </Typography>
                      )}
                    </div>
                  </CardContent>

                  <div className="ApplyButton">
                    <CardActions
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        width: "99%",
                        marginTop: "20px",
                      }}
                    >
                      <Button
                        onClick={() => {
                          moreDetails2(jobDetails1.job_description);
                        }}
                        sx={{ ml: 1, mr: 1 }}
                        variant="outlined"
                        color="success"
                        size="small"
                      >
                        View More
                      </Button>

                      {jobDetails1.job_status === "InActive" ? (
                        <Button disabled variant="contained" size="small">
                          Apply
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          size="small"
                          onClick={() => {
                            Apply(jobDetails1);
                          }}
                        >
                          Apply
                        </Button>
                      )}
                    </CardActions>
                  </div>
                </div>
              </Card>
            ) : null}
            {jobDetails.map((jobs, index) => (
              <Card
                className="recommendedJob-InnerCard"
                key={jobs._id}
                sx={{ mt: 2 }}
              >
                <div className="ApplyCard">
                  <CardContent>
                    <Typography
                      className="nameTitleServiceProvider"
                      sx={{
                        fontWeight: 600,
                        fontSize: "26px",
                        wordWrap: "break-word",
                        lineHeight: "2em",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        transition: "0.5s",
                        backgroundColor: "#22668a",
                        color: "white",
                        padding: "0px 5px",
                        textTransform: "uppercase",
                        borderRadius: "5px",
                        marginBottom: "10px",
                      }}
                    >
                      {jobs.job_title}
                    </Typography>

                    <Grid container>
                      <Grid lg={9} md={9} sm={9} xs={9}>
                        <Typography
                          gutterBottom
                          sx={{ fontWeight: 600, fontSize: "20px" }}
                        >
                          {jobs.company_name}
                        </Typography>
                        <Grid lg={10} md={10} sm={10} xs={10}>
                          <Typography
                            gutterBottom
                            sx={{
                              fontWeight: 600,
                              fontSize: "14px",
                              ml: 0.4,
                              wordWrap: "break-word",
                            }}
                            color="text.secondary"
                          >
                            {jobs.industry}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        className="CompanySideLogo"
                        lg={3}
                        md={3}
                        sm={2}
                        xs={2}
                      >
                        <img
                          alt=""
                          id="employerProfile"
                          src={
                            jobs.employer_email_id
                              ? `${baseUrl}employers/logo/email/${jobs.employer_email_id}/users`
                              : null
                          }
                          onError={(e) => {
                            e.target.src = employerProfile;
                          }}
                          className="jobImgLogo"
                        />
                      </Grid>
                    </Grid>

                    <div className="JobsAlignment">
                      <div>
                        <Typography
                          className="JobsNameAlignment"
                          gutterBottom
                          sx={{ fontWeight: 600, fontSize: "14px", mr: 1 }}
                        >
                          <text>Skills</text>
                          <text>:</text>
                        </Typography>
                      </div>
                      <div>
                        <Grid lg={12} md={12} sm={12} xs={12}>
                          <Typography
                            gutterBottom
                            sx={{
                              fontWeight: 600,
                              fontSize: "14px",
                              wordWrap: "break-word",
                            }}
                            color="text.secondary"
                          >
                            {jobs.key_skills.trim().replace(/,$/, ".") || null}
                          </Typography>
                        </Grid>
                      </div>
                    </div>
                    <div className="JobsAlignment">
                      <div>
                        <Typography
                          className="JobsNameAlignment"
                          gutterBottom
                          sx={{ fontWeight: 600, fontSize: "14px", mr: 1 }}
                        >
                          <text>Experience</text>
                          <text>:</text>
                        </Typography>
                      </div>
                      <div>
                        <Typography
                          gutterBottom
                          sx={{ fontWeight: 600, fontSize: "14px" }}
                          color="text.secondary"
                        >
                          {jobs.experience_type === "Freshers Only"
                            ? "Freshers Only"
                            : `${jobs.experience_min}${"-"}${
                                jobs.experience_max
                              }`}
                        </Typography>
                      </div>
                    </div>
                    <div className="JobsAlignment">
                      <div>
                        <Typography
                          className="JobsNameAlignment"
                          gutterBottom
                          sx={{ fontWeight: 600, fontSize: "14px", mr: 1 }}
                        >
                          <text>Salary</text>
                          <text>:</text>
                        </Typography>
                      </div>
                      <div>
                        <Typography
                          gutterBottom
                          sx={{
                            fontWeight: 600,
                            fontSize: "14px",
                            wordWrap: "break-word",
                            lineHeight: "2em",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            transition: "0.5s",
                            backgroundColor: "#22668a",
                            color: "white",
                            padding: "0px 5px",
                            textTransform: "uppercase",
                            borderRadius: "5px",
                          }}
                          color="text.secondary"
                        >
                          {jobs.salary_range_max
                            ? `${jobs.salary_range_min}${"-"}${
                                jobs.salary_range_max
                              }${
                                jobs.salary_type === "Month"
                                  ? " Per Month"
                                  : jobs.salary_type === "Year"
                                  ? " LPA"
                                  : null
                              }`
                            : "Not Disclosed"}
                        </Typography>
                      </div>
                    </div>
                    <div className="JobsAlignment">
                      <div>
                        <Typography
                          className="JobsNameAlignment"
                          gutterBottom
                          sx={{ fontWeight: 600, fontSize: "14px", mr: 1 }}
                        >
                          <text>Location</text>
                          <text>:</text>
                        </Typography>
                      </div>
                      <div>
                        <Typography
                          gutterBottom
                          sx={{ fontWeight: 600, fontSize: "14px" }}
                          color="text.secondary"
                        >
                          {jobs.job_location}
                        </Typography>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        marginTop: "5px",
                        marginBottom: "30px",
                      }}
                    >
                      <DescriptionIcon
                        fontSize="small"
                        sx={{ color: "rgb(145 145 145)" }}
                      />
                      {jobs.job_description ? (
                        <Typography
                          className="JobDescriptionOverflow"
                          gutterBottom
                          sx={{
                            fontWeight: 600,
                            fontSize: "14px",
                            ml: 0.5,
                            mt: -1.8,
                          }}
                          color="text.secondary"
                          dangerouslySetInnerHTML={{
                            __html: renderParagraphs(jobs.job_description),
                          }}
                        ></Typography>
                      ) : (
                        <Typography
                          sx={{
                            fontWeight: 600,
                            fontSize: "14px",
                            ml: 0.5,
                          }}
                          color="text.secondary"
                        >
                          No description for this job
                        </Typography>
                      )}
                    </div>
                  </CardContent>

                  <div className="ApplyButton">
                    <CardActions
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        width: "99%",
                        marginTop: "20px",
                      }}
                    >
                      <Button
                        onClick={() => {
                          moreDetails(jobs.job_description);
                        }}
                        sx={{ ml: 1, mr: 1 }}
                        variant="outlined"
                        color="success"
                        size="small"
                      >
                        View More
                      </Button>

                      {jobs.job_status === "InActive" ? (
                        <Button disabled variant="contained" size="small">
                          Apply
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          size="small"
                          onClick={() => {
                            Apply(jobs);
                          }}
                        >
                          Apply
                        </Button>
                      )}
                    </CardActions>
                  </div>
                </div>
              </Card>
            ))}
          </div>
          <div style={{ width: "98%", textAlign: "right" }}>
            <Button
              disabled={loadDisabled}
              onClick={() => {
                selectedOptions.page_number++;
                setSelectedOptions(selectedOptions);
                loadMoreData(selectedOptions);
              }}
              sx={{ mb: 2 }}
              variant="contained"
            >
              Load more
              {loadMore && (
                <CircularProgress
                  size={24}
                  style={{
                    color: "#ffffff",
                    marginLeft: 12,
                  }}
                />
              )}
            </Button>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div
        style={{
          height: "70vh",
          display: "grid",
          placeItems: "center",
          // transform: "translate(0,50vh)",
        }}
      >
        <div class="dots"></div>
      </div>
    );
  }
}

export default Searchpage;
