import React from "react";
import {
  Dialog,
  Button,
  Typography,
  Box,
  CircularProgress,
  DialogTitle,
  IconButton,
} from "@mui/material";
import {
  WarningRounded as WarningRoundedIcon,
  CloseRounded as CloseRoundedIcon,
} from "@mui/icons-material";
function PartnerInvestorConfirm({
  openConfirm,
  setOpenConfirm,
  partnerN,
  getId,
  updateAllocatePincode,
  pinLoad,
  namePI,
  allP,
  selectpinOpt,
  setForSelectByArea,
  setAllP,
  setSelectPinOpt,
  selectByPin,
}) {
  const setforupload = selectpinOpt === "single" ? selectByPin : allP;
  const showingdialog = setforupload.map((i) => {
    return i.pincode;
  });
  const pincodes = showingdialog.join(", ");

  return (
    <>
      <Dialog
        open={openConfirm}
        fullWidth
        sx={{ "& .MuiPaper-root": { borderRadius: 0 } }}
      >
        <DialogTitle bgcolor={"#055779"} sx={{ padding: 0, height: "100%" }}>
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                height: "100%",
                padding: "5px",
                width: "90%",
              }}
            >
              {/* <Box sx={{ height: "100%", display: "flex", alignItems: "center" }}> */}
              <Typography
                sx={{ display: "flex", alignItems: "center", color: "#ffffff" }}
              >
                Confirm
              </Typography>{" "}
              {/* </Box> */}
              <IconButton
                sx={{ color: "#ffffff" }}
                onClick={() => setOpenConfirm(false)}
              >
                <CloseRoundedIcon />
              </IconButton>{" "}
            </Box>
          </Box>
        </DialogTitle>

        <Box
          sx={{
            padding: "20px",
            paddingTop: "15px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            flexDirection: "column",
          }}
        >
          <Box
            display="flex"
            sx={{
              height: "100%",
              alignItems: "center",
              justifyContent: "flex-start",
              width: "100%",
              gap: "10px",
            }}
          >
            <WarningRoundedIcon fontSize="large" />{" "}
            <Typography>
              Are you sure to submit these ({pincodes}) pincodes to a {namePI} "
              {partnerN}"
            </Typography>
          </Box>

          <Box
            sx={{
              paddingTop: "20px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              width: "100%",
              gap: "10px",
            }}
          >
            <Button
              onClick={() => {
                setOpenConfirm(false);
              }}
              variant="outlined"
              sx={{
                backgroundColor: "#f2f2f2",
                textTransform: "lowercase",

                color: "black",
                borderColor: "white",
                fontFamily: "Poppins-Medium",
                "&:hover": {
                  textTransform: "lowercase",
                  color: "#f2f2f2",
                  backgroundColor: "#339fcd",
                  borderColor: "#f2f2f2",
                  fontFamily: "Poppins-Medium",
                },
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="outlined"
              disabled={pinLoad}
              sx={{
                textTransform: "Capitalize",

                color: "white",
                backgroundColor: "#ed1067",
                borderColor: "#ed1067",
                fontFamily: "Poppins-Medium",
                transition: "all 0.3s",
                width: "20%",
                "&:hover": {
                  textTransform: "Capitalize",

                  color: "#ed1067",
                  borderColor: "#ed1067",
                  fontFamily: "Poppins-Medium",
                  width: "20%",
                },
              }}
              onClick={() => {
                updateAllocatePincode(getId, allP, selectpinOpt, selectByPin);
                setAllP([]);
                setForSelectByArea([]);
                setSelectPinOpt("single");
              }}
            >
              Submit
              {pinLoad && (
                <CircularProgress
                  size={24}
                  style={{
                    color: "#ffffff",
                    marginLeft: 12,
                  }}
                />
              )}
            </Button>
          </Box>
        </Box>

        {/* <DialogActions>
            </DialogActions> */}
      </Dialog>
    </>
  );
}

export default PartnerInvestorConfirm;
