import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  Button,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  Box,
  Divider,
  Chip,
  IconButton,
  Tooltip,
  Skeleton,
  TextField,
} from "@mui/material";
import {
  FileDownload as FileDownloadIcon,
  CloseRounded as CloseRoundedIcon,
  DoneRounded as DoneRoundedIcon,
  RefreshSharp as RefreshSharpIcon,
} from "@mui/icons-material/";
import useAxios from "../../../../api/useAxios";
import ApproveDialog from "./ApproveDialog";
import AdminSnackBar from "../SnackBars/AdminSnackBar";
import DeclineDialog from "./DeclineDialog";
import defaultimg from "../../../../img/mentorImage.png";
import employerdefaultimg from "../../../../img/employerProfile/employerUpdate-logo.png";

import { saveAs } from "file-saver";

export default function EmployerDialog({
  open,
  handleChange,
  getOne,
  setOpen,
  filterName,
  setFilterName,
  fetchApi,
  dialogLoad,
  getOneData,
  updateEmployerSetting,
  setUpdateEmployerSetting,
}) {
  const [getPutId, setGetPutId] = useState(0);
  const [getEmail, setGetEmail] = useState("");
  const [getPass, setGetPass] = useState("");
  const [value, setValue] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [snackOption, setSnackOption] = useState("");
  const [approveOpen, setApproveOpen] = useState(false);
  const [declineOpen, setDeclineOpen] = useState(false);
  let adminDetails_CEO = JSON.parse(localStorage.getItem("admin_dev"));
  const axiosData = useAxios();
  const baseUrl = axiosData.defaults.baseURL;

  const downloadResume = (mobile, name) => {
    saveAs(`${baseUrl}employers/proof/${mobile}`, `Proof of ${name}`);
  };

  const updateApprove = async (id, email_id, password) => {
    setFilterName({ ...filterName, isLoading: true });
    try {
      axiosData.put(`employers/${id}`, { status: "A" });
      await axiosData.put(`users/${email_id}`, {
        password: password,
        status: "Active",
        usertype: "provider",
      });

      setGetPutId(0);
      setGetEmail("");
      setGetPass("");
      setOpen(false);
      setApproveOpen(false);
      setOpenSnack(true);
      setSnackOption("success");
      setOpen(false);
      setApproveOpen(false);
      setOpenSnack(true);
      setSnackOption("success");
      fetchApi();
    } catch (err) {
      console.log("Error", err);
    }
    setFilterName({ ...filterName, isLoading: false });
  };
  const updateDecline = async (id, email_id) => {
    setFilterName({ ...filterName, isLoading: true });
    try {
      await axiosData.put(`employers/${id}`, { status: "D", reason: value });
      axiosData.put(
        `users/${email_id}`,
        { status: "InActive", usertype: "provider", reason: value },

        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setGetPutId(0);
      setGetEmail("");
      setDeclineOpen(false);
      setOpen(false);
      setOpenSnack(true);
      setSnackOption("error");
      fetchApi();
    } catch (err) {
      console.log("Error", err);
    }
    setFilterName({ ...filterName, isLoading: false });
  };
  const exceptThisSymbols = ["e", "E", "+", "-", ".", "ArrowUp", "ArrowDown"];
  const updateSetting = async (id, value, name, stateVal) => {
    try {
      axiosData.put(`employers/${id}`, { [name]: value });
      setUpdateEmployerSetting({ ...updateEmployerSetting, [stateVal]: value });
      fetchApi();
    } catch (err) {
      console.log("err", err);
    }
    getOneData(id);
  };

  return (
    <>
      <Dialog fullWidth open={open} onClose={handleChange}>
        <DialogTitle bgcolor="#055779" color="white">
          <Box id="main-box">
            <Box>
              <div id className="align-img">
                <img
                  src={
                    getOne?.logoUpload
                      ? `${baseUrl}employers/logo/email/${getOne?.email_id}/Admin`
                      : ""
                  }
                  onError={(e) => {
                    e.target.src = employerdefaultimg;
                  }}
                  alt="img"
                  id="employerProfile"
                  className="emplogo"
                />
              </div>
            </Box>
            <Box>
              <Typography
                variant="body1"
                sx={{
                  textTransform: "uppercase",
                  fontFamily: "Barlow-Bold",
                }}
              >
                <div className="titleseeker">
                  {getOne.company_name}
                  <div
                    style={{
                      textTransform: "capitalize",
                      fontFamily: "Barlow-Regular",
                    }}
                  >
                    {getOne.industry}
                  </div>
                </div>
              </Typography>
            </Box>
            <Box></Box>
          </Box>
        </DialogTitle>
        <DialogContent dividers="paper" sx={{ backgroundColor: "#f2f2f2" }}>
          <Box sx={{ padding: "20px", width: "100%" }}>
            <Grid container md={12} columnSpacing={1} direction="column">
              <Divider
                sx={{
                  "&::before, &::after": {
                    borderColor: "#055779",
                  },
                  color: "#055779 ",
                }}
                textAlign="left"
              >
                Settings
              </Divider>{" "}
              <Box sx={{ padding: "20px" }}>
                <Typography variant="h6">
                  <div className="align-text">
                    <span className="full-details">No. of hot position:</span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        <>
                          <TextField
                            // margin="normal"
                            labelId="demo-simple-select-label"
                            required
                            disabled={
                              adminDetails_CEO?.emailId ===
                              process.env.REACT_APP_EMAILID_FOR_ADMIN_DEVELOPER
                                ? true
                                : false
                            }
                            name="contact_number"
                            sx={{ width: "80px" }}
                            // label={t("Mobile_Number")}
                            type="Number"
                            onWheel={(e) => {
                              e.preventDefault();
                              e.target.blur();
                            }}
                            onKeyDown={(e) =>
                              exceptThisSymbols.includes(e.key) &&
                              e.preventDefault()
                            }
                            id="mobile_number"
                            value={updateEmployerSetting.noOfHot}
                            onChange={(e) => {
                              const { value } = e.target;
                              setUpdateEmployerSetting({
                                ...updateEmployerSetting,
                                noOfHot: value,
                              });
                            }}
                          />{" "}
                          <IconButton
                            disabled={
                              updateEmployerSetting.noOfHot ===
                                getOne.no_of_hot_posting ||
                              updateEmployerSetting.noOfHot === null ||
                              updateEmployerSetting.noOfHot === "" ||
                              updateEmployerSetting.noOfHot === undefined
                                ? true
                                : false
                            }
                            onClick={() => {
                              updateSetting(
                                getOne._id,
                                updateEmployerSetting.noOfHot,
                                "no_of_hot_posting",
                                "noOfHot"
                              );
                            }}
                            size="small"
                            sx={{
                              color: "white",
                              backgroundColor: "#339fcd",
                              boxShadow: 0,
                              "&:hover": { backgroundColor: "#217396" },
                              mx: 2,
                            }}
                          >
                            <DoneRoundedIcon />
                          </IconButton>
                          <IconButton
                            size="small"
                            disabled={
                              updateEmployerSetting.noOfHot ===
                              getOne.no_of_hot_posting
                                ? true
                                : false
                            }
                            onClick={() => {
                              setUpdateEmployerSetting({
                                ...updateEmployerSetting,
                                noOfHot: getOne.no_of_hot_posting,
                              });
                            }}
                            sx={{
                              color: "white",
                              backgroundColor: "#339fcd",
                              boxShadow: 0,
                              "&:hover": { backgroundColor: "#217396" },
                            }}
                          >
                            <RefreshSharpIcon />
                          </IconButton>
                        </>
                      )}
                    </div>
                  </div>
                </Typography>{" "}
                <Typography variant="h6">
                  <div className="align-text">
                    <span className="full-details">
                      No. of Standard Posting:
                    </span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : (
                        <>
                          <TextField
                            // margin="normal"
                            labelId="demo-simple-select-label"
                            required
                            name="contact_number"
                            disabled={
                              adminDetails_CEO?.emailId ===
                              process.env.REACT_APP_EMAILID_FOR_ADMIN_DEVELOPER
                                ? true
                                : false
                            }
                            // label={t("Mobile_Number")}
                            sx={{ width: "80px" }}
                            type="Number"
                            onWheel={(e) => {
                              e.preventDefault();
                              e.target.blur();
                            }}
                            onKeyDown={(e) =>
                              exceptThisSymbols.includes(e.key) &&
                              e.preventDefault()
                            }
                            id="mobile_number"
                            onChange={(e) => {
                              const { value } = e.target;
                              setUpdateEmployerSetting({
                                ...updateEmployerSetting,
                                noOfStandard: value,
                              });
                            }}
                            value={updateEmployerSetting.noOfStandard}
                          />{" "}
                          <IconButton
                            disabled={
                              updateEmployerSetting.noOfStandard ===
                                getOne.no_of_Standard_posting ||
                              updateEmployerSetting.noOfStandard === null ||
                              updateEmployerSetting.noOfStandard === "" ||
                              updateEmployerSetting.noOfStandard === undefined
                                ? true
                                : false
                            }
                            size="small"
                            onClick={() => {
                              updateSetting(
                                getOne._id,
                                updateEmployerSetting.noOfStandard,
                                "no_of_Standard_posting",
                                "noOfStandard"
                              );
                            }}
                            sx={{
                              color: "white",
                              backgroundColor: "#339fcd",
                              boxShadow: 0,
                              "&:hover": { backgroundColor: "#217396" },
                              mx: 2,
                            }}
                          >
                            <DoneRoundedIcon />
                          </IconButton>
                          <IconButton
                            size="small"
                            disabled={
                              updateEmployerSetting.noOfStandard ===
                              getOne.no_of_Standard_posting
                                ? true
                                : false
                            }
                            sx={{
                              color: "white",
                              backgroundColor: "#339fcd",
                              boxShadow: 0,
                              "&:hover": { backgroundColor: "#217396" },
                            }}
                            onClick={() => {
                              setUpdateEmployerSetting({
                                ...updateEmployerSetting,
                                noOfStandard: getOne.no_of_Standard_posting,
                              });
                            }}
                          >
                            <RefreshSharpIcon />
                          </IconButton>
                        </>
                      )}
                    </div>
                  </div>
                </Typography>
              </Box>
              <Divider
                sx={{
                  "&::before, &::after": {
                    borderColor: "#055779",
                  },
                  color: "#055779 ",
                }}
                textAlign="left"
              >
                Company Details
              </Divider>{" "}
              <Box sx={{ padding: "20px" }}>
                <Typography variant="h6">
                  <div className="align-text">
                    <span className="full-details">Company Name:</span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : getOne.company_name ? (
                        getOne.company_name
                      ) : (
                        "Not Yet Updated"
                      )}
                    </div>
                  </div>
                </Typography>
                <Typography variant="h6">
                  <div className="align-text">
                    <span className="full-details"> Email:</span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : getOne.email_id ? (
                        getOne.email_id
                      ) : (
                        "Not Yet Updated"
                      )}
                    </div>
                  </div>
                </Typography>{" "}
                <Typography variant="h6">
                  <div className="align-text">
                    <span className="full-details"> Industry:</span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : getOne.industry ? (
                        getOne.industry
                      ) : (
                        "Not Yet Updated"
                      )}
                    </div>
                  </div>
                </Typography>{" "}
                <Typography variant="h6">
                  <div className="align-text">
                    <span className="full-details"> District:</span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : getOne.district ? (
                        getOne.district
                      ) : (
                        "Not Yet Updated"
                      )}
                    </div>
                  </div>
                </Typography>
                <Typography variant="h6">
                  <div className="align-text">
                    <span className="full-details"> Company Address:</span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : getOne.company_address ? (
                        getOne.company_address
                      ) : (
                        "Not Yet Updated"
                      )}
                    </div>
                  </div>
                </Typography>
                <Typography variant="h6">
                  <div className="align-text">
                    <span className="full-details"> City:</span>
                    <div>
                      {" "}
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : getOne.city ? (
                        getOne.city
                      ) : (
                        "Not Yet Updated"
                      )}
                    </div>
                  </div>
                </Typography>
                <Typography variant="h6">
                  <div className="align-text">
                    <span className="full-details"> Pin-code:</span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : getOne.pincode ? (
                        getOne.pincode
                      ) : (
                        "Not Yet Updated"
                      )}
                    </div>
                  </div>
                </Typography>
                <Typography variant="h6">
                  <div className="align-text">
                    <span className="full-details"> Area:</span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : getOne.area ? (
                        getOne.area
                      ) : (
                        "Not Yet Updated"
                      )}
                    </div>
                  </div>
                </Typography>
                <Typography variant="h6">
                  <div className="align-text">
                    <span className="full-details">Company URL:</span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : getOne.company_url ? (
                        getOne.company_url
                      ) : (
                        "Not Yet Updated"
                      )}
                    </div>
                  </div>
                </Typography>
                <Typography variant="h6">
                  <div className="align-text">
                    <span className="full-details">Landline Number:</span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : getOne.landline_number ? (
                        getOne.landline_number
                      ) : (
                        "Not Yet Updated"
                      )}
                    </div>
                  </div>
                </Typography>
                <Typography variant="h6">
                  <div className="align-text">
                    <span className="full-details">Established Year:</span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : getOne.established_year ? (
                        getOne.established_year
                      ) : (
                        "Not Yet Updated"
                      )}
                    </div>
                  </div>
                </Typography>
              </Box>
              <Divider
                sx={{
                  "&::before, &::after": {
                    borderColor: "#055779",
                  },
                  color: "#055779 ",
                }}
                textAlign="left"
              >
                Government Proof of Company
              </Divider>
              <Box sx={{ padding: "20px" }}>
                <Typography variant="h6">
                  <div className="align-text">
                    <span className="full-details"> Proof Type:</span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : getOne.proof_name ? (
                        getOne.proof_name
                      ) : (
                        "Not Yet Updated"
                      )}
                    </div>
                  </div>
                </Typography>
                <Typography variant="h6">
                  <div className="align-text">
                    <span className="full-details"> Proof Name:</span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : getOne.proofUpload?.name ? (
                        getOne.proofUpload?.name
                      ) : (
                        "Not Yet Updated"
                      )}
                    </div>
                  </div>
                </Typography>

                {adminDetails_CEO?.emailId ===
                process.env.REACT_APP_EMAILID_FOR_ADMIN_DEVELOPER ? null : (
                  <Typography variant="h6">
                    <div className="align-text">
                      <span className="full-details"> Download:</span>
                      <div>
                        {" "}
                        <Tooltip title="Download Proof" placement="top">
                          <IconButton
                            className="icon-btn"
                            onClick={() =>
                              downloadResume(
                                getOne.mobile_number,
                                getOne.company_name
                              )
                            }
                            size="medium"
                            disabled={!getOne.proofUpload}
                          >
                            <FileDownloadIcon />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                  </Typography>
                )}
              </Box>
              <Divider
                sx={{
                  "&::before, &::after": {
                    borderColor: "#055779",
                  },
                  color: "#055779 ",
                }}
                textAlign="left"
              >
                Contact Details
              </Divider>
              <Box sx={{ padding: "20px" }}>
                <Typography variant="h6">
                  <div className="align-text">
                    <span className="full-details">Contact Person:</span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : getOne.contact_person ? (
                        getOne.contact_person
                      ) : (
                        "Not Yet Updated"
                      )}
                    </div>
                  </div>
                </Typography>
                <Typography variant="h6">
                  <div className="align-text">
                    <span className="full-details"> Designation:</span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : getOne.designation ? (
                        getOne.designation
                      ) : (
                        "Not Yet Updated"
                      )}
                    </div>
                  </div>
                </Typography>
                <Typography variant="h6">
                  <div className="align-text">
                    <span className="full-details"> Email:</span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : getOne.contact_email_id ? (
                        getOne.contact_email_id
                      ) : (
                        "Not Yet Updated"
                      )}
                    </div>
                  </div>
                </Typography>{" "}
                <Typography variant="h6">
                  <div className="align-text">
                    <span className="full-details"> Mobile:</span>
                    <div>
                      {dialogLoad ? (
                        <Skeleton
                          animation="wave"
                          variant="text"
                          width={230}
                          height={40}
                          sx={{ borderRadius: "15px" }}
                        />
                      ) : getOne.mobile_number ? (
                        getOne.mobile_number
                      ) : (
                        "Not Yet Updated"
                      )}
                    </div>
                  </div>
                </Typography>
              </Box>
              <Divider
                sx={{
                  "&::before, &::after": {
                    borderColor: "#055779",
                  },
                  color: "#055779 ",
                }}
                textAlign="left"
              >
                Status
              </Divider>
              <Box sx={{ padding: "20px" }}>
                <Typography textAlign="center" variant="h6">
                  <div className="align-text">
                    <span
                      className="full-details"
                      style={{ textAlign: "left" }}
                    >
                      {" "}
                      Status:
                    </span>
                    <div style={{ textAlign: "left" }}>
                      <Chip
                        label={getOne.status}
                        color={
                          getOne.status === "P"
                            ? "warning"
                            : getOne.status === "A"
                            ? "success"
                            : "error"
                        }
                        variant="contained"
                        sx={{ textAlign: "left" }}
                      />
                    </div>
                  </div>
                </Typography>
                {getOne.status === "D" ? (
                  <>
                    <Typography variant="h6">
                      <div className="align-text">
                        <span className="full-details"> Reason:</span>
                        <div>{getOne.reason}</div>
                      </div>
                    </Typography>
                  </>
                ) : null}
              </Box>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button sx={{ textTransform: "capitalize" }} onClick={handleChange}>
            Close
          </Button>
          {adminDetails_CEO?.emailId ===
          process.env.REACT_APP_EMAILID_FOR_ADMIN_DEVELOPER ? null : (
            <Button
              sx={{ textTransform: "capitalize" }}
              className="action-btn"
              onClick={() => {
                setGetPutId(getOne._id);
                setGetEmail(getOne.email_id);
                setGetPass(getOne.password);
                setApproveOpen(true);
              }}
              variant="contained"
              color="success"
              disabled={getOne.status === "A" ? true : false}
            >
              Approve
            </Button>
          )}{" "}
          {adminDetails_CEO?.emailId ===
          process.env.REACT_APP_EMAILID_FOR_ADMIN_DEVELOPER ? null : (
            <Button
              sx={{ textTransform: "capitalize" }}
              className="action-btn"
              color="error"
              onClick={() => {
                setGetPutId(getOne._id);
                setGetEmail(getOne.email_id);

                setDeclineOpen(open);
              }}
              variant="contained"
              disabled={getOne.status === "D" ? true : false}
            >
              Decline
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <ApproveDialog
        approveOpen={approveOpen}
        setApproveOpen={setApproveOpen}
        updateApprove={updateApprove}
        filterName={filterName}
        getPutId={getPutId}
        getEmail={getEmail}
        getPass={getPass}
      />
      <DeclineDialog
        declineOpen={declineOpen}
        setDeclineOpen={setDeclineOpen}
        value={value}
        setValue={setValue}
        filterName={filterName}
        updateDecline={updateDecline}
        getPutId={getPutId}
        getEmail={getEmail}
      />
      <AdminSnackBar
        openSnack={openSnack}
        setOpenSnack={setOpenSnack}
        snackOption={snackOption}
        full_name={getOne.company_name}
      />
    </>
  );
}
