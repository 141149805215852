/* eslint-disable eqeqeq */
import React, { useState } from "react";
import {
  Dialog,
  Button,
  DialogContent,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import ReactQuill from "react-quill";
import useAxios from "../../../../api/useAxios";
import { ReactComponent as Warning } from "../../../../img/admin-panel/warning-circle-svgrepo-com.svg";
import { useLocation } from "react-router-dom";
import { ROUTINGDATA } from "../../../ROUTINGDATA";
import { useFormik } from "formik";
import * as Yup from "yup";
import ContactSupportSnack from "../SnackBars/ContactSupportSnack";
function ContactSupportDialog({
  formikProps,

  openContactDialog,
  setOpenContactDialog,
  name,
  contactPerson,
  setOpenSupportSnack,
}) {
  const axiosData = useAxios();
  const location = useLocation();

  return (
    <>
      <Dialog
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "#339fcd",
            borderRadius: "20px",
          },
        }}
        open={openContactDialog}
        fullWidth
      >
        <Box>
          <Box>
            <form onSubmit={formikProps.handleSubmit}>
              <DialogContent>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "space-around",
                    height: "100%",
                  }}
                >
                  <Box sx={{ paddingTop: "10px" }}>
                    <Warning height={100} width={100} />
                  </Box>
                  <Typography
                    variant="h4"
                    sx={{ paddingTop: "10px", fontFamily: "Poppins-SemiBold" }}
                  >
                    {" "}
                    Are you sure?
                  </Typography>
                  <Typography
                    sx={{
                      paddingTop: "10px",
                      fontFamily: "Poppins-Medium",
                      color: "white",
                    }}
                  >
                    Please Confirm Details and submit
                  </Typography>

                  {/* {currentUrl === volunteerRoute ||
                  currentUrl === MLAroute ||
                  currentUrl === MProute ? (
                    <Box
                      sx={{
                        backgroundColor: "#fff",
                        pt: 1.5,
                        borderRadius: 2,
                        pb: 1.5,
                        pr: 0.5,
                        pl: 1,
                        overflowX: "scroll",
                        maxWidth: "100%",
                        "&::-webkit-scrollbar": {
                          display: "none",
                        },
                        scrollbarWidth: "none",
                      }}
                    >
                      <ReactQuill
                        className="scrollable-container-volunteer"
                        name="notes"
                        value={formik.values.notes}
                        onChange={(value) => {
                          if (value === "<p><br></p>") {
                            formik.setFieldValue("notes", "");
                            formik.handleReset();
                          } else {
                            formik.setFieldValue("notes", value);
                          }
                        }}
                        modules={{
                          toolbar: [
                            ["bold", "italic", "underline", "strike"],
                            ["blockquote", "code-block"],
                            [{ list: "ordered" }, { list: "bullet" }],
                            [{ header: [1, 2, 3, 4, 5, 6, false] }],
                            [{ color: [] }, { background: [] }],
                            [{ font: [] }],
                            [{ align: [] }],
                            ["clean"],
                          ],
                        }}
                        placeholder="Write notes.."
                      />{" "}
                      {formik.touched.notes && formik.errors.notes ? (
                        <div style={{ color: "red", fontSize: "13px" }}>
                          {formik.errors.notes}
                        </div>
                      ) : null}
                    </Box>
                  ) : null} */}

                  <Box
                    sx={{
                      paddingTop: "20px",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                      width: "100%",
                    }}
                  >
                    <Button
                      onClick={() => {
                        setOpenContactDialog(false);
                      }}
                      variant="outlined"
                      sx={{
                        backgroundColor: "#f2f2f2",
                        textTransform: "lowercase",

                        color: "black",
                        borderColor: "white",
                        fontFamily: "Poppins-Medium",
                        "&:hover": {
                          textTransform: "lowercase",
                          color: "#f2f2f2",
                          backgroundColor: "#339fcd",
                          borderColor: "#f2f2f2",
                          fontFamily: "Poppins-Medium",
                        },
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="outlined"
                      // disabled={openState.isLoading}
                      sx={{
                        textTransform: "lowercase",

                        color: "white",
                        backgroundColor: "#ed1067",
                        borderColor: "#ed1067",
                        fontFamily: "Poppins-Medium",
                        transition: "all 0.3s",
                        "&:hover": {
                          textTransform: "lowercase",

                          color: "#ed1067",
                          borderColor: "#ed1067",
                          fontFamily: "Poppins-Medium",
                        },
                      }}
                      onClick={() => {
                        formikProps.handleSubmit();
                        // updateFinalVerify("Completed");
                        setOpenSupportSnack(true);
                      }}
                    >
                      Submit
                    </Button>
                  </Box>
                </Box>
              </DialogContent>
            </form>
          </Box>
        </Box>
      </Dialog>
    </>
  );
}

export default ContactSupportDialog;
