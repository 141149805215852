import React, { useEffect, useState } from "react";
import {
  Table,
  TableContainer,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  Box,
  Card,
  Typography,
  IconButton,
  Tooltip,
  TablePagination,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  FormControl,
  Grid,
  Paper,
  TextField,
  Chip,
  Button,
} from "@mui/material";
import {
  OpenInNewRounded as OpenInNewRoundedIcon,
  CloseRounded as CloseRoundedIcon,
  PostAdd as PostAddIcon,
  Error,
} from "@mui/icons-material";
import defaultimg from "../../../../img/mentorImage.png";
import { useLocation, useNavigate } from "react-router-dom";
import useAxios from "../../../../api/useAxios";
import { ROUTINGDATA } from "../../../ROUTINGDATA";
import StatMLAcards from "../../../admin-panel/admin-components/StatCards/StatMLAcards";
import ApproveDialog from "../../../admin-panel/admin-components/DialogBoxes/ApproveDialog";
import ExportCS from "../../../admin-panel/admin-components/ExportComponents/ExportCS";

function MLAconstituency1() {
  const location = useLocation();
  const currentUrl = location.pathname;
  const shortRoute =
    ROUTINGDATA.LAND +
    ROUTINGDATA.MPConstituency +
    "/" +
    ROUTINGDATA.MP_MLAShortlisted;

  const navigate = useNavigate();
  const axiosData = useAxios();
  const baseUrl = axiosData.defaults.baseURL;

  const [MLAneed, setMLAneed] = useState([]);
  const [allData, setAllData] = useState([]);
  const [MLAEmail, setMLAEmail] = useState("");
  const [approveOpen, setApproveOpen] = useState(false);

  const [page, setPage] = useState(0);
  const [totalRows, setTotalRows] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchicon, setsearchicon] = useState(false);
  const [searchdata, setsearchdata] = useState("");
  const [isLoading0, setIsLoading0] = useState(false);
  const [MLALoading, setMLAloading] = useState(false);

  const MPMLAPageNumbe = sessionStorage.getItem("mpmla_page_number");
  const MPMLAPageNumber = parseInt(MPMLAPageNumbe);
  const setMPMLAPageNumber = MPMLAPageNumber ? MPMLAPageNumber : 0;
  const state = location.state.mla;

  const needSupportFilterValue = location.state.needSupportFilterValue;
  const typesOfSupportFilterValue = location.state.typesOfSupportFilterValue;
  const load = location.state.load;
  const ind = location.state.index;
  const shortlisted = sessionStorage.getItem(`shortradio${ind}`);
  const mpmlaneedradio = sessionStorage.getItem(`mpmlaneedradio${ind}`);
  const mpmlaneedsearch = sessionStorage.getItem(`mpmlaneedsearch${ind}`);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const openApproveDialog = () => {
    setApproveOpen(true);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const finalverifier = (value, fullarray) => {
    if (value === "all") {
      const needSupportArray = [...fullarray.needSupport];
      setMLAneed(needSupportArray.reverse());
      setTotalRows(needSupportArray.length);
      setPage(setMPMLAPageNumber);
    } else if (value === "Accommodation") {
      const AccomodationArray = [...fullarray.Accommodation];

      setMLAneed(AccomodationArray.reverse());
      setTotalRows(AccomodationArray.length);
      setPage(setMPMLAPageNumber);
    } else if (value === "Training") {
      const TrainingArray = [...fullarray.Training];

      setMLAneed(TrainingArray.reverse());
      setTotalRows(TrainingArray.length);
      setPage(setMPMLAPageNumber);
    } else if (value === "Course Fees") {
      const CourseArray = [...fullarray["Course Fees"]];

      setMLAneed(CourseArray.reverse());
      setTotalRows(CourseArray.length);
      setPage(setMPMLAPageNumber);
    } else if (value === "Mentorship") {
      const MentorshipArray = [...fullarray.Mentorship];

      setMLAneed(MentorshipArray.reverse());
      setTotalRows(MentorshipArray.length);
      setPage(setMPMLAPageNumber);
    } else if (value === "Others") {
      const OthersArray = [...fullarray.Others];
      setMLAneed(OthersArray.reverse());
      setTotalRows(OthersArray.length);
      setPage(setMPMLAPageNumber);
    }
  };

  const updateAdminVerify = async (email_id, decide, notes) => {
    setIsLoading0(true);
    const mlaemail = sessionStorage.getItem(`mlaemailid${ind}`);
    const value = { email_id: mlaemail };

    try {
      await axiosData.put(
        `/seeker/email/${email_id}`,
        { notes: notes },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      await axiosData.put(`family/admin/${email_id}`, {
        shortList: decide,
        shortlistNotes: notes,
      });
      finalverifier(totalfiltervalue, allData);
      getMpEmailId(value);

      setApproveOpen(false);
    } catch (err) {
      console.log("Error", err);
    }

    setIsLoading0(false);
  };

  const closeFunctionCall = () => {
    finalverifier(mpmlaneedradio ? mpmlaneedradio : "all", allData);
  };
  const searchdatas = (searchdata, searchAllData) => {
    const datas = searchAllData.filter((item) => {
      const searchtext = new RegExp(searchdata, "i");
      var valuess =
        item.Name.match(searchtext) || item.email_id.match(searchtext);

      if (valuess != null) {
        return item;
      }
    });
    setMLAneed(datas);
    setTotalRows(datas.length);
    setPage(0);
  };
  const shortlistBy = (shortvalue, total) => {
    if (shortvalue === "MP") {
      const fill = total.filter((d) => {
        return d.shortList === "MP";
      });
      setMLAneed(fill);
      setTotalRows(fill.length);
      setPage(0);
    } else if (shortvalue === "MLA") {
      const fill = total.filter((d) => {
        return d.shortList === "MLA";
      });
      setMLAneed(fill);
      setTotalRows(fill.length);
      setPage(0);
    } else if (shortvalue === "Volunteer") {
      const volun = total.filter((d) => {
        const dhiv = d.shortList.split("_")[0];
        if (dhiv === "Volunteer") {
          return dhiv;
        }
      });
      setMLAneed(volun);
      setTotalRows(volun.length);
      setPage(setMPMLAPageNumber);
    } else if (shortvalue === "Pending") {
      const fill = total.filter((d) => d.shortList === "");
      setMLAneed(fill);
      setTotalRows(fill.length);
      setPage(setMPMLAPageNumber);
    }
  };

  //<.. needsupport  radio button Session valule..>
  const shortlist_session_value =
    shortlisted === "MP" ||
    shortlisted === "MLA" ||
    shortlisted === "Volunteer" ||
    shortlisted === "Pending"
      ? shortlisted
      : false;
  //<.. typesof  radio button Session valule..>
  const typesof_session_value =
    mpmlaneedradio === "Accommodation" ||
    mpmlaneedradio === "Training" ||
    mpmlaneedradio === "all" ||
    mpmlaneedradio === "Course Fees" ||
    mpmlaneedradio === "Mentorship" ||
    mpmlaneedradio === "Others"
      ? mpmlaneedradio
      : false;
  //<.. needsupport  radio button State valule..>
  const shortlist_state_value =
    needSupportFilterValue === "MP" ||
    needSupportFilterValue === "MLA" ||
    needSupportFilterValue === "Volunteer" ||
    needSupportFilterValue === "Pending"
      ? needSupportFilterValue
      : false;
  //<.. typesof  radio button State valule..>
  const typesof_state_value =
    typesOfSupportFilterValue === "Accommodation" ||
    typesOfSupportFilterValue === "Training" ||
    typesOfSupportFilterValue === "all" ||
    typesOfSupportFilterValue === "Course Fees" ||
    typesOfSupportFilterValue === "Mentorship" ||
    typesOfSupportFilterValue === "Others"
      ? typesOfSupportFilterValue
      : false;
  //<..check two session value of needsupport and typeofsupport..>
  const totalsessionfiltervalue =
    shortlist_session_value === false
      ? typesof_session_value === false
        ? "all"
        : typesof_session_value
      : shortlist_session_value;
  //<..check two State value of needsupport and typeofsupport..>
  const totalstatefiltervalue =
    shortlist_state_value === false
      ? typesof_state_value === false
        ? false
        : typesof_state_value
      : shortlist_state_value;
  //<..check  session and state value ..>
  const totalfiltervalue =
    shortlist_session_value === false && typesof_session_value === false
      ? totalstatefiltervalue === false
        ? totalsessionfiltervalue
        : totalstatefiltervalue
      : totalsessionfiltervalue;
  //---------

  const getMpEmailId = async (value) => {
    try {
      const MpData = await axiosData.post("mpmla/mlaconstituencyseeker", value);
      const { data } = MpData;
      setAllData(data[0]);
      let bool = false;
      if (mpmlaneedsearch !== null) {
        bool = mpmlaneedsearch.length > 0;
      }

      if (true === bool) {
        searchdatas(mpmlaneedsearch, data[0].needSupport);
        setsearchicon(true);
      } else if (
        totalfiltervalue === "MP" ||
        totalfiltervalue === "MLA" ||
        totalfiltervalue === "Volunteer" ||
        totalfiltervalue === "Pending"
      ) {
        shortlistBy(totalfiltervalue, data[0].needSupport);
      } else if (
        totalfiltervalue === "Accommodation" ||
        totalfiltervalue === "Training" ||
        totalfiltervalue === "all" ||
        totalfiltervalue === "Course Fees" ||
        totalfiltervalue === "Mentorship" ||
        totalfiltervalue === "Others"
      ) {
        finalverifier(totalfiltervalue, data[0]);
      }
      setMLAloading(true);
    } catch (err) {
      console.log("Error", err);
    }
  };
  const selectedApiForExcel = MLAneed.map((i) => {
    return {
      "Full Name": i.Name,
      Email: i.email_id,
      Mobile: i.mobile_number,
      "Types Of Support": i.TypeOfSupport,
      "Approx Amount": i.amount,

      "Voting District": i.votingDistrict,
      "MP Constituency": i.mpConstituency,
      "MLA Constituency": i.mlaConstituency,
    };
  });
  useEffect(() => {
    const mlaname = state.mla_constituency;
    const lowercasemla = mlaname.toLowerCase();
    const value = { email_id: `${lowercasemla}.mp@gmail.com` };
    const email = `${lowercasemla}.mp@gmail.com`;
    sessionStorage.setItem(`mlaemailid${ind}`, email);
    getMpEmailId(value);

    setPage(setMPMLAPageNumber);
    sessionStorage.removeItem("mpmla_page_number");
    // sessionStorage.removeItem(`mpmlaneedradio${ind}`);
    // sessionStorage.removeItem(`shortradio${ind}`);
  }, [state, shortlisted, needSupportFilterValue, typesOfSupportFilterValue]);
  if (MLALoading) {
    return (
      <>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <Box>
            <Card sx={{ padding: "20px" }}>
              <Typography
                variant="body1"
                sx={{ fontFamily: "Barlow-Bold", textTransform: "capitalize" }}
              >
                {state.mla_constituency}'s&nbsp;
                {currentUrl == shortRoute
                  ? "Short Listed Data"
                  : " Need Support"}
              </Typography>
            </Card>
          </Box>
          {currentUrl == shortRoute ? null : <StatMLAcards state={state} />}

          <Card sx={{ boxShadow: 0 }}>
            <Paper>
              <Box
                sx={{
                  padding: "20px",

                  backgroundColor: "#f2f2f2",
                }}
              >
                <Grid container>
                  <Grid item md={8}>
                    {currentUrl == shortRoute ? null : (
                      <FormControl>
                        <FormLabel id="demo-row-radio-buttons-group-label">
                          Types Of Support
                        </FormLabel>
                        <RadioGroup
                          row
                          id="radio-btn"
                          value={totalfiltervalue}
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          sx={{ marginRight: "18px" }}
                          onChange={(e) => {
                            if (searchicon === true) {
                              setsearchicon(false);
                              setsearchdata("");
                              sessionStorage.setItem(
                                `mpmlaneedsearch${ind}`,
                                ""
                              );
                            }
                            sessionStorage.setItem(`shortradio${ind}`, null);
                            sessionStorage.setItem(
                              `mpmlaneedradio${ind}`,
                              e.target.value
                            );

                            finalverifier(e.target.value, allData);
                          }}
                        >
                          <FormControlLabel
                            name="usertype"
                            control={<Radio value={"all"} />}
                            label="All"
                          ></FormControlLabel>
                          <FormControlLabel
                            name="usertype"
                            control={<Radio value={"Training"} />}
                            label="Training"
                          ></FormControlLabel>
                          <FormControlLabel
                            name="usertype"
                            control={<Radio value={"Accommodation"} />}
                            label="Accommodation"
                          />
                          <FormControlLabel
                            name="usertype"
                            control={<Radio value={"Course Fees"} />}
                            label="Course Fees"
                          />
                          <FormControlLabel
                            name="usertype"
                            control={<Radio value={"Mentorship"} />}
                            label="Mentorship"
                          />
                          <FormControlLabel
                            name="usertype"
                            control={<Radio value={"Others"} />}
                            label="Others"
                          />
                        </RadioGroup>
                      </FormControl>
                    )}
                  </Grid>
                  <Grid item md={4}>
                    <TextField
                      fullWidth
                      label="Search"
                      variant="outlined"
                      value={searchdata ? searchdata : mpmlaneedsearch}
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            sx={{
                              visibility: `${
                                searchicon ? "visible" : "hidden"
                              }`,
                            }}
                            onClick={() => {
                              closeFunctionCall();
                              setsearchicon(false);
                              setsearchdata("");
                              sessionStorage.setItem(
                                `mpmlaneedsearch${ind}`,
                                ""
                              );
                            }}
                          >
                            <CloseRoundedIcon />
                          </IconButton>
                        ),
                      }}
                      onKeyDown={(e) =>
                        ["(", ")", "^", "[", "]", "*", "<", ">", "\\"].includes(
                          e.key
                        ) && e.preventDefault()
                      }
                      onChange={(e) => {
                        sessionStorage.setItem(`shortradio${ind}`, null);
                        sessionStorage.setItem(`mpmlaneedradio${ind}`, "all");
                        setsearchdata(e.currentTarget.value);
                        sessionStorage.setItem(
                          `mpmlaneedsearch${ind}`,
                          e.currentTarget.value
                        );
                        if (e.currentTarget.value.length === 0) {
                          finalverifier(
                            mpmlaneedradio ? mpmlaneedradio : "all",
                            allData
                          );
                          setTotalRows(allData.needSupport.length);
                          setPage(0);
                        }
                        if (e.currentTarget.value.length >= 3) {
                          searchdatas(
                            e.currentTarget.value,
                            allData.needSupport
                          );
                        }
                        setsearchicon(true);
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Paper>
            <Paper>
              <Box
                sx={{
                  padding: "20px",

                  backgroundColor: "#f2f2f2",
                }}
              >
                <Grid container>
                  <Grid item md={8}>
                    <FormControl>
                      <FormLabel id="demo-row-radio-buttons-group-label">
                        Filter By ShortList
                      </FormLabel>
                      <RadioGroup
                        row
                        id="radio-btn"
                        value={totalfiltervalue}
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        sx={{ marginRight: "18px" }}
                        onChange={(e) => {
                          if (searchicon === true) {
                            setsearchicon(false);
                            setsearchdata("");
                            sessionStorage.setItem(`mpmlaneedsearch${ind}`, "");
                          }
                          sessionStorage.setItem(
                            `shortradio${ind}`,
                            e.target.value
                          );
                          sessionStorage.setItem(`mpmlaneedradio${ind}`, null);
                          shortlistBy(e.target.value, allData.needSupport);
                        }}
                      >
                        <FormControlLabel
                          name="usertype"
                          control={<Radio value="Pending" />}
                          label="Pending"
                        ></FormControlLabel>
                        <FormControlLabel
                          name="usertype"
                          control={<Radio value={"MP"} />}
                          label="Completed By MP"
                        />
                        <FormControlLabel
                          name="usertype"
                          control={<Radio value={"MLA"} />}
                          label="Completed by MLA"
                        />
                        <FormControlLabel
                          name="usertype"
                          control={<Radio value={"Volunteer"} />}
                          label="Completed by Volunteer"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  <Grid item md={2}>
                    <Tooltip title="Export As Excel" placement="bottom">
                      <ExportCS
                        selectedApiForExcel={selectedApiForExcel}
                        fileName={"Seeker-NeedSupport-Data"}
                      />
                    </Tooltip>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow className="table-row">
                    <TableCell>Profile</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Family Income</TableCell>

                    <TableCell>ShortList Status</TableCell>

                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {MLAneed.length > 0 ? (
                    <>
                      {MLAneed.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      ).map((item) => {
                        let shortlistStat =
                          item.shortList === ""
                            ? "pending"
                            : item.shortList === "MP"
                            ? "Completed By You"
                            : item.shortList === "MLA"
                            ? "Completed By MLA"
                            : "Completed By Volunteer";
                        return (
                          <TableRow className="tablecell">
                            <TableCell>
                              <img
                                src={`${baseUrl}seekers/profile/email/${item.email_id}`}
                                height={"50"}
                                width="50"
                                onError={(e) => {
                                  e.target.src = defaultimg;
                                }}
                                style={{ clipPath: "circle(50%)" }}
                                alt="img"
                              />
                            </TableCell>
                            <TableCell>{item.Name}</TableCell>
                            <TableCell>{item.email_id}</TableCell>
                            <TableCell>{item.FamilyIncome}</TableCell>
                            <TableCell>
                              <Chip
                                label={shortlistStat}
                                variant="filled"
                                color={
                                  item.shortList === ""
                                    ? "warning"
                                    : item.shortList === "MLA" ||
                                      item.shortList === "MP"
                                    ? "info"
                                    : "success"
                                }
                                sx={{
                                  color: "#ffffff",
                                }}
                              />
                            </TableCell>

                            <TableCell>
                              <Tooltip title="Full Details" placement="top">
                                <IconButton
                                  className="icon-btn"
                                  size="Medium"
                                  onClick={() => {
                                    navigate(
                                      `${ROUTINGDATA.LAND}${ROUTINGDATA.MPConstituency}/${ROUTINGDATA.MP_MLAfullDetais}`,
                                      {
                                        state: item.email_id,
                                      }
                                    );
                                    sessionStorage.setItem(
                                      "mpmla_page_number",
                                      page
                                    );
                                  }}
                                >
                                  <OpenInNewRoundedIcon />
                                </IconButton>
                              </Tooltip>
                              {item.shortList === "" ? (
                                <Tooltip>
                                  <Button
                                    startIcon={<PostAddIcon />}
                                    variant="contained"
                                    sx={{
                                      textTransform: "capitalize ",
                                      boxShadow: 0,
                                      borderRadius: "16px",
                                    }}
                                    onClick={() => {
                                      setMLAEmail(item.email_id);
                                      openApproveDialog();
                                    }}
                                  >
                                    Shortlist
                                  </Button>
                                </Tooltip>
                              ) : null}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </>
                  ) : (
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell>
                        <Typography
                          variant="body1"
                          sx={{ fontFamily: "Barlow-Bold" }}
                        >
                          NO Data Available
                        </Typography>
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25]}
              component="div"
              count={totalRows}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Box>

        <ApproveDialog
          approveOpen={approveOpen}
          setApproveOpen={setApproveOpen}
          mlaEmail={MLAEmail}
          isLoading={isLoading0}
          MLA={"MP"}
          updateAdminVerify={updateAdminVerify}
        />
      </>
    );
  } else {
    return (
      <div
        style={{
          height: "70vh",
          display: "grid",
          placeItems: "center",
        }}
      >
        <div class="dots"></div>
      </div>
    );
  }
}

export default MLAconstituency1;
