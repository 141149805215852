import React, { useEffect, useState } from "react";
import "./ServiceSubscription.css";
import {
  Card,
  Typography,
  Button,
  FormControl,
  OutlinedInput,
  MenuItem,
  Select,
} from "@mui/material";
import Grid from "@mui/system/Unstable_Grid/Grid";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { useNavigate } from "react-router";
import { ROUTINGDATA } from "../../../ROUTINGDATA";
import useAxios from "../../../../api/useAxios";

function ServiceSubscription() {
  const navigate = useNavigate();
  const [userData, setuserData] = useState();
  const [loading, setLoading] = useState(false);
  const [standPackage, setStandPackage] = useState("");
  const [prePackage, setPrePackage] = useState("");
  const [standTotal, setStandTotal] = useState(0);
  const [preTotal, setPreTotal] = useState(0);
  const [disStandAmount, setDisStandAmount] = useState();
  const [disHotAmount, setDisHotAmount] = useState();
  const [empsettings, setempsettings] = useState();

  const axiosData = useAxios();
  let userDetails = JSON.parse(localStorage.getItem("service-provider"));
  const get = async () => {
    setLoading(false);
    console.log("service");
    const setting = await axiosData.get(`localserviceprovider/settings`);
    console.log("setting:", setting.data);
    setempsettings(setting.data);
    // const response = await axiosData.get(
    //   `localserviceprovider/email/${userDetails.emailId}/users`
    // );
    // console.log(response);
    // setuserData(response.data);
    setPrePackage(1);
    setStandPackage(10);
    setLoading(true);
  };
  useEffect(() => {
    // if (userDetails === null || userDetails === undefined) {
    //   navigate(
    //     `${ROUTINGDATA.LAND}${ROUTINGDATA.MAIN}${ROUTINGDATA.PageNotFound}`
    //   );
    // } else {
    //   get();
    // }
    get();
  }, []);
  // useEffect(() => {
  //   if (empsettings) {
  //     if (standPackage >= 10) {
  //       setStandTotal(standPackage * empsettings.standard_price);
  //       setDisStandAmount(
  //         standPackage * empsettings.standard_price -
  //           (standPackage * empsettings.standard_price) / empsettings.discount
  //       );
  //     } else {
  //       setStandTotal(standPackage * empsettings.standard_price);
  //     }
  //     if (prePackage >= 5) {
  //       setPreTotal(prePackage * empsettings.premimum_price);

  //       console.log(" empsettings.discount_valid", empsettings.discount);
  //       setDisHotAmount(
  //         prePackage * empsettings.premimum_price -
  //           (prePackage * empsettings.premimum_price) / empsettings.discount
  //       );
  //     } else {
  //       setPreTotal(prePackage * empsettings.premimum_price);
  //     }
  //   }
  // }, [standPackage, prePackage]);
  const subscribe = async (subscribeType, type, no_posting) => {
    await axiosData.put(
      `localserviceprovider/email/${userDetails.emailId}`,
      { subscribeType: subscribeType, [type]: no_posting },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    navigate(
      ROUTINGDATA.LAND +
        ROUTINGDATA.MAIN +
        ROUTINGDATA.Searchpageforlocalservices,
      {
        state: userDetails.userId,
      }
    );
  };
  // const freeHandleChange = (event) => {
  //   setFreePackage(event.target.value);
  // };
  const standHandleChange = (event) => {
    setStandPackage(event.target.value);
  };
  const preHandleChange = (event) => {
    setPrePackage(event.target.value);
  };
  const freebtn = {
    "&.MuiButton-contained": {
      backgroundColor: "#196b19",
      marginTop: 2,
      width: "50%",
    },
  };
  const Standardbtn = {
    "&.MuiButton-contained": {
      backgroundColor: "#008ac0",
      margin: 2,
      width: "50%",
    },
  };
  const Premiumbtn = {
    "&.MuiButton-contained": {
      backgroundColor: "#e40048",
      margin: 2,
      width: "50%",
    },
  };

  const ITEM_HEIGHT = 40;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 50,
      },
    },
  };
  if (loading) {
    return (
      <div>
        <p
          style={{ textAlign: "center", fontSize: "16px", fontWeight: "bold" }}
        >
          To view service contact details, please purchase BuyService using any
          of the following packages:
        </p>
        <div className="sub-Container">
          <div className="sub-main-grid">
            <div class="flex-row-item">
              <Card className="sub-cards1" sx={{ m: 2 }}>
                <Typography
                  sx={{
                    fontSize: 23,
                    textAlign: "center",
                    color: "#fff",
                    p: 1.5,
                    backgroundColor: "#e40048",
                    fontWeight: 600,
                  }}
                >
                  One BuyService
                </Typography>

                <div style={{ paddingLeft: "30px", paddingRight: "30px" }}>
                  <div
                    style={{
                      textAlign: "center",
                      marginTop: "50px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <CurrencyRupeeIcon />
                    <Typography variant="h4" sx={{ textAlign: "center" }}>
                      {empsettings.buyServicePrice}
                    </Typography>
                    <Typography
                      sx={{
                        textAlign: "center",
                        fontSize: "12px",
                        fontWeight: "bold",
                        mt: 2.5,
                      }}
                    >
                      Only
                    </Typography>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{ margin: 4, fontSize: 16, textAlign: "center" }}
                    >
                      BuyService Quantity
                    </Typography>
                    <FormControl sx={{ width: "80px" }} size="small">
                      <Select
                        labelId="demo-customized-select-label"
                        id="demo-customized-select"
                        value={standPackage}
                        onChange={standHandleChange}
                        input={<OutlinedInput />}
                        MenuProps={MenuProps}
                      >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                        <MenuItem value={40}>40</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={60}>60</MenuItem>
                        <MenuItem value={70}>70</MenuItem>
                        <MenuItem value={80}>80</MenuItem>
                        <MenuItem value={90}>90</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                      </Select>
                    </FormControl>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginX: 50,
                    }}
                  >
                    <Typography>Total</Typography>

                    <Typography sx={{ paddingLeft: 2 }}>
                      <b> {standPackage * empsettings.buyServicePrice}</b>
                    </Typography>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      marginTop: "10px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CheckRoundedIcon sx={{ color: "green", mr: 0.5 }} />
                    <span>{empsettings.oneBuyService} BuyService</span>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "end",
                    justifyContent: "center",
                    marginTop: "20px",
                  }}
                >
                  <Button
                    variant="contained"
                    sx={Premiumbtn}
                    // onClick={async () => {
                    //   subscribe("Premimum", "no_of_hot_posting", prePackage);
                    // }}
                  >
                    BUY NOW
                  </Button>
                </div>
              </Card>
            </div>
            <div class="flex-row-item">
              <Card className="sub-cards1" sx={{ m: 2 }}>
                <Typography
                  sx={{
                    fontSize: 23,
                    color: "#fff",
                    p: 1.5,
                    backgroundColor: "#008ac0",
                    fontWeight: 600,
                    textAlign: "center",
                  }}
                >
                  Monthly BuyService
                </Typography>

                <div style={{ paddingLeft: "30px", paddingRight: "30px" }}>
                  <div
                    style={{
                      textAlign: "center",
                      marginTop: "20px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        textAlign: "center",
                        marginTop: "20px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <CurrencyRupeeIcon />
                      <Typography variant="h4" sx={{ textAlign: "center" }}>
                        {empsettings.buyServicePrice}
                      </Typography>
                      <Typography
                        sx={{
                          textAlign: "center",
                          fontSize: "12px",
                          fontWeight: "bold",
                          mt: 2.5,
                        }}
                      >
                        Per BuyService
                      </Typography>
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    <Typography
                      sx={{ textAlign: "center", fontSize: "16px", mr: 2 }}
                    >
                      Total Price{" "}
                      <CurrencyRupeeIcon style={{ fontSize: "12px" }} />
                      <del style={{ fontWeight: "bold", color: "gray" }}>
                        {empsettings.buyServicePrice * prePackage * 20}
                      </del>
                    </Typography>
                    <Typography sx={{ textAlign: "center", fontSize: "16px" }}>
                      <CurrencyRupeeIcon style={{ fontSize: "12px" }} />
                      <span style={{ fontWeight: "bold" }}>
                        {(empsettings.buyServicePrice *
                          prePackage *
                          empsettings.monthlyBuyservices) /
                          2}
                      </span>{" "}
                      per month
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{ margin: 4, fontSize: 16, textAlign: "center" }}
                    >
                      BuyService month
                    </Typography>
                    <FormControl sx={{ width: "80px" }} size="small">
                      <Select
                        labelId="demo-customized-select-label"
                        id="demo-customized-select"
                        value={prePackage}
                        onChange={preHandleChange}
                        input={<OutlinedInput />}
                        MenuProps={MenuProps}
                      >
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={6}>6</MenuItem>
                        <MenuItem value={7}>7</MenuItem>
                        <MenuItem value={8}>8</MenuItem>
                        <MenuItem value={9}>9</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={11}>11</MenuItem>
                        <MenuItem value={12}>12</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginX: 50,
                    }}
                  >
                    <Typography>Total</Typography>

                    <Typography sx={{ paddingLeft: 2 }}>
                      <b> {prePackage * 1000}</b>
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      marginTop: "10px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CheckRoundedIcon sx={{ color: "green", mr: 0.5 }} />
                    <span>
                      {empsettings.monthlyBuyservices} BuyService in a month
                    </span>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "end",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    variant="contained"
                    sx={Standardbtn}
                    // onClick={async () => {
                    //   subscribe(
                    //     "Standard",
                    //     "no_of_Standard_posting",
                    //     standPackage
                    //   );
                    // }}
                  >
                    BUY NOW
                  </Button>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div
        style={{
          height: "70vh",
          display: "grid",
          placeItems: "center",
        }}
      ></div>
    );
  }
}

export default ServiceSubscription;
