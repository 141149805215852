import React, { useState, useEffect } from "react";
import Tooltip from "@mui/material/Tooltip";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import { Toolbar, Typography, IconButton, Button } from "@mui/material";
import logo from "../../../../img/logo_for-landingpage-3.png";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MoreIcon from "@mui/icons-material/MoreVert";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router";
import { ROUTINGDATA } from "../../../ROUTINGDATA";
import { useLocation } from "react-router-dom";
import { useContext } from "react";
import { CurrentStateContext } from "../../../../App";
import { useTranslation } from "react-i18next";
export default function LocalServiceHeader(props) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const { t, i18n, ready } = useTranslation();
  //   const { languagetranslator } = useContext(CurrentStateContext);
  let userDetails = JSON.parse(localStorage.getItem("user"));
  let emailName;
  let fullName;
  if (userDetails) {
    emailName = userDetails.emailId;
    fullName = userDetails.company_name;
  }
  const logoutClickedemployer = () => {
    localStorage.removeItem("user");
    navigate(`${ROUTINGDATA.LAND}`);
  };
  let seekerDetails = JSON.parse(localStorage.getItem("seeker"));
  let emailName2;
  let fullName2;
  const logoutClickedseeker = () => {
    localStorage.removeItem("seeker");
    navigate(`${ROUTINGDATA.LAND}`);
  };
  if (seekerDetails) {
    emailName2 = seekerDetails.emailId;
    fullName2 = seekerDetails.name;
  }
  let mentorDetails = JSON.parse(localStorage.getItem("mentor"));
  let emailNamementor;
  let fullNamementor;
  const logoutClickedmentor = () => {
    localStorage.removeItem("mentor");
    navigate(`${ROUTINGDATA.LAND}`);
  };
  if (mentorDetails) {
    emailNamementor = mentorDetails.email_id;
    fullNamementor = mentorDetails.MentorName;
  }

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    sessionStorage.removeItem("activeLink");
    setAnchorEl(null);

    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const lang = sessionStorage.getItem("lang");

  const location = useLocation();
  const currentUrl = location.pathname;

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem
        onClick={() => {
          handleMenuClose();
        }}
      >
        <LogoutIcon sx={{ color: "gray" }} />
        <span className="logout">Logout</span>
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem
        sx={{
          display:
            currentUrl ===
              ROUTINGDATA.LAND + ROUTINGDATA.MAIN + ROUTINGDATA.JOBSEEK ||
            currentUrl ===
              ROUTINGDATA.LAND + ROUTINGDATA.MAIN + ROUTINGDATA.JOBPROVIDER ||
            currentUrl ===
              ROUTINGDATA.LAND + ROUTINGDATA.MAIN + ROUTINGDATA.VOLUNTEER ||
            currentUrl ===
              ROUTINGDATA.LAND + ROUTINGDATA.MAIN + ROUTINGDATA.MENTOR
              ? "block"
              : "none",
        }}
        onClick={() => {
          navigate(`${ROUTINGDATA.LAND}${ROUTINGDATA.LOGIN}`);
          handleMenuClose();
        }}
      >
        <span className="logout">{t("Login")}</span>
      </MenuItem>
      <MenuItem
        onClick={() => {
          navigate(ROUTINGDATA.LAND);

          handleMenuClose();
        }}
      >
        <span className="logout">Logout</span>
      </MenuItem>
    </Menu>
  );
  if (ready) {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="static"
          className="headerpart"
          sx={{ boxShadow: "0px 0px 8px 2px rgba(16, 143, 178, 0.5)" }}
        >
          <Toolbar>
            <Typography variant="h6" component="div">
              <div className={"headerlogoimg"}>
                <img
                  alt=""
                  style={{ cursor: "pointer" }}
                  src={logo}
                  onClick={() => navigate(ROUTINGDATA.LAND)}
                />
              </div>
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
          </Toolbar>
        </AppBar>
        {renderMobileMenu}
        {renderMenu}
      </Box>
    );
  } else {
    return (
      <div
        style={{
          height: "70vh",
          display: "grid",
          placeItems: "center",
          // transform: "translate(0,50vh)",
        }}
      >
        <div class="dots"></div>
      </div>
    );
  }
}
