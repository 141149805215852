/* eslint-disable react-hooks/exhaustive-deps */
import { Container, Button } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { useFormik } from "formik";
import mission from "../../../img/mission-img.jpg";
import NaturePeopleIcon from "@mui/icons-material/NaturePeople";
import PeopleAltRoundedIcon from "@mui/icons-material/PeopleAltRounded";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { Drawer } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import Card from "@mui/material/Card";
import {
  CurrencyExchangeRounded as CurrencyExchangeRoundedIcon,
  HandshakeRounded as HandshakeRoundedIcon,
} from "@mui/icons-material";
import cardadevetisement from "../../../img/card-advertisement.jpg";
import bannermain from "../../../img/centerbanneradvertisement.jpg";
import cenetrbanner from "../../../img/centerbanner.jpg";

import latestjobimg from "../../../img/login image/logo.png";
import facebookimg from "../../../img/bsnner-img/1080px face book.png";
import facebookimg1 from "../../../img/bsnner-img/istagram-story.png";

import facebookpopup from "../../../img/bsnner-img/samplepopupfacebookimg.png";

import moment from "moment/moment";
import { ReactComponent as TwitterX } from "../../../img/twitter-x-seeklogo.com-4.svg";
import TwitterIcon from "@mui/icons-material/Twitter";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import BroadcastOnPersonalIcon from "@mui/icons-material/BroadcastOnPersonal";
import aboutsimg from "../../../img/aboutus-img.jpg";

import lastimg from "../../../img/lastcontect.jpg";
import last2 from "../../../img/lastcontect2.jpeg";
import last3 from "../../../img/bottomimg.jpg";
import "../Landingpage.css";
import SearchIcon from "@mui/icons-material/Search";
import BusinessIcon from "@mui/icons-material/Business";

import WysiwygIcon from "@mui/icons-material/Wysiwyg";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { EngineeringRounded as EngineeringRoundedIcon } from "@mui/icons-material";

import logo from "../../../img/logo_for-landingpage-3.png";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AltRouteIcon from "@mui/icons-material/AltRoute";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import WorkIcon from "@mui/icons-material/Work";
import FilterTiltShiftIcon from "@mui/icons-material/FilterTiltShift";
import YouTubeIcon from "@mui/icons-material/YouTube";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import SensorOccupiedIcon from "@mui/icons-material/SensorOccupied";
import VideoCameraFrontIcon from "@mui/icons-material/VideoCameraFront";
import CommentBankIcon from "@mui/icons-material/CommentBank";
import StorageIcon from "@mui/icons-material/Storage";
import ContactsIcon from "@mui/icons-material/Contacts";
import EmojiTransportationIcon from "@mui/icons-material/EmojiTransportation";
import SupportIcon from "@mui/icons-material/Support";
import ModelTrainingIcon from "@mui/icons-material/ModelTraining";
import CampaignIcon from "@mui/icons-material/Campaign";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import side from "../../../img/sideadd.jpg";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import Marquee from "react-fast-marquee";
import { useNavigate } from "react-router-dom";
import { ROUTINGDATA } from "../../ROUTINGDATA";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import useAxios from "../../../api/useAxios";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Box, padding } from "@mui/system";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import { industry } from "../../skeleton/jobseeker/cdata";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Slide from "@mui/material/Slide";
import { useContext } from "react";
import { CurrentStateContext } from "../../../App";
import splbanner from "../../../img/bsnner-img/splbanner-15.png";
import axios from "axios";
import CountUp from "react-countup";
import videoSource from "../../../img/bsnner-img/vvVideo.mp4";
import InputAdornment from "@mui/material/InputAdornment";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Topbanner from "../../../img/bsnner-img/top-banner-1.png";
import BottomBanner from "../../../img/bsnner-img/bottom-banner.png";
import { imagegrid } from "./ImageData";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-cube";
import "swiper/css/pagination";
import { Autoplay, EffectCube, Pagination } from "swiper/modules";
import { click } from "@testing-library/user-event/dist/click";

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Landingpagecontext() {
  const [searchQuery, setSearchQuery] = useState([]);
  const [readmore, setreadmore] = useState(false);
  const [mobilesearch, setmobilesearch] = useState(false);
  const [open, setOpen] = useState(false);
  const [suggestionArray, setSuggestionArray] = useState([]);
  const [suggestionArrayl, setSuggestionArrayl] = useState([]);
  const [searchPopUp, setSearchPopUp] = useState(false);
  const [openService, setOpenService] = useState(false);
  const [openServicefocus, setOpenServicefocus] = useState(false);
  const [isAdvertisementOpen, setAdvertisementOpen] = useState(false);
  const [facebookpopup_popup, setfacebookpopup_popup] = useState(false);

  const [pincodeValue, setPincodeValue] = useState("");
  const [Areaname, setAreaname] = useState("");
  const [servicetype, setServicetype] = useState("");
  const [selectdistrict, setSelectDistrict] = useState("");
  const [recentJobs, setRecentJobs] = useState([]);
  const [recentlocalJobs, setRecentlocalJobs] = useState([]);
  const [recentlocalServices, setRecentlocalServices] = useState([]);
  const [addGetCenter, setAddGetCenter] = useState([]);
  const [addGetSideRight, setAddGetSideRight] = useState([]);
  const [addGetSideLeft, setAddGetSideLeft] = useState([]);
  const [addGetfacebookadd, setAddfacebookadd] = useState([]);
  const [addGetinstagramadd, setAddinstagramadd] = useState([]);
  const [addfacebookpopupadd, setfacebookpopupadd] = useState([]);

  // const [addGetTopLeft, setAddGetTopLeft] = useState([]);
  const [addGetBottom, setAddGetBottom] = useState([]);
  const [addGetBottomFooter, setAddGetBottomFooter] = useState([]);
  const [addGetPopup, setAddGetPopup] = useState([]);
  const [addGettopbanner, setaddGettopbanner] = useState([]);
  // const [timedelay, settimedelay] = useState(false);
  const [districtData, setDistrictData] = useState([]);
  //const [userDistrict, setUserDistrict] = useState("");
  //const [searchdata, setsearchdata] = useState("");
  const [searchResultDatas, setsearchResultDatas] = useState([]);
  const [showHiddenDiv, setShowHiddenDiv] = useState(false);
  const [searchvalue, setsearchvalue] = useState({
    industry: "",
    job_location: "",
    key_skills: "",
  });
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [topbanner, settopbanner] = useState(false);
  const [facebookpopup_1, setfacebookpopup_1] = useState(false);
  const [facebookpopup_2, setfacebookpopup_2] = useState(false);
  const [bottombanner, setbottombanner] = useState(false);
  const [countSeeker, setCountSeeker] = useState(0);
  const [countProvider, setCountProvider] = useState(0);
  const [countVolunteer, setCountVolunteer] = useState(0);
  const [countneedsupport, setCountneedsupport] = useState(0);
  const [countServiceProvider, setcountServiceProvider] = useState(0);
  const [countservicesrequest, setcountservicesrequest] = useState(0);
  const [countpostajob, setcountpostajob] = useState(0);
  const [getImageApi, setGetImageApi] = useState();
  //const [isDistrictOpen, setDistrictOpen] = useState(false);
  const [noResultPopUp, setNoResultPopUp] = useState(false);
  const emp_email = sessionStorage.getItem("emp_email");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentIndexsideleft, setCurrentIndexsideleft] = useState(0);
  const [currentIndexsideright, setCurrentIndexsideright] = useState(0);
  const [currentIndexpopup, setCurrentIndexpopup] = useState(0);
  const [currentIndextopbanner, setCurrentIndextopbanner] = useState(0);
  const [currentIndexbottomfooter, setCurrentIndexbottomfooter] = useState(0);
  const [currentIndexfacebookadd, setCurrentIndexfacebookadd] = useState(0);
  const [currentIndexinstagramadd, setCurrentIndexinstagramadd] = useState(0);
  const [currentIndexfacebookpopupadd, setCurrentfacebookpopupadd] =
    useState(0);
  const [
    currentIndexfacebookpopup_down_add,
    setCurrentIndexfacebookpopup_down_add,
  ] = useState(0);

  const [facebookaddlength, setfacebookaddlength] = useState(0);
  const [instagramaddlength, setinstagramaddlength] = useState(0);
  const [facebookpopupaddlength, setfacebookpopupaddlength] = useState(0);
  const [centerAdlength, setCenterAdlength] = useState(0);
  const [sideleftAdlength, setsideleftAdlength] = useState(0);
  const [siderightAdlength, setsiderightAdlength] = useState(0);
  const [popupAdlength, setpopupAdlength] = useState(0);
  const [topAdlength, settopAdlength] = useState(0);
  const [bottomfooterAdlength, setbottomfooterAdlength] = useState(0);
  // const userDistrictget = localStorage.getItem("DistrictUser");
  // useEffect(() => {
  //   setTimeout(() => {
  //     settimedelay(true);
  //   }, 10000);
  // }, []);
  const { languagetranslator } = useContext(CurrentStateContext);
  const navigate = useNavigate();
  const { t, i18n, ready } = useTranslation();
  const axiosData = useAxios();
  const baseUrl = axiosData.defaults.baseURL;
  const [location, setLocation] = useState("");

  const getCurrentLocation = async () => {
    try {
      const position = await getCurrentPosition();
      const { latitude, longitude } = position.coords;
      const locationName = await getLocationName(latitude, longitude);
      setLocation(locationName);
    } catch (error) {
      console.error("Error getting user location:", error);
      localStorage.setItem("currentLocationArea", "");
      localStorage.setItem("currentLocationPincode", "");
      localStorage.setItem("currentLocationState", "");
      localStorage.setItem("currentLocationCountry", "");
      localStorage.setItem("currentLocationDistrict", "");
      addGet();
    }
  };

  const getCurrentPosition = () =>
    new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    });

  const getLocationName = async (latitude, longitude) => {
    try {
      const response = await axios.get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?types=poi&access_token=pk.eyJ1IjoiZGhpdnlhMzM5OSIsImEiOiJjbG9qcGJ1NjMxeXZsMmttZWY4MWMzY3NqIn0.dE8R-_vKBUlPc_YjNmjmLw`
      );
      //console.log("res",response.data.features[0])
      localStorage.setItem(
        "currentLocationArea",
        response.data.features[0].context[0].text
      );
      localStorage.setItem(
        "currentLocationPincode",
        response.data.features[0].context[1].text
      );
      localStorage.setItem(
        "currentLocationState",
        response.data.features[0].context[5].text
      );
      localStorage.setItem(
        "currentLocationCountry",
        response.data.features[0].context[6].text
      );
      localStorage.setItem(
        "currentLocationDistrict",
        response.data.features[0].context[4].text
      );
      addGet();
    } catch (error) {
      console.error("Error fetching location details:", error);
      throw error;
    }
  };

  let seekerDetails = JSON.parse(localStorage.getItem("seeker"));
  let emailName2;
  if (seekerDetails) {
    emailName2 = seekerDetails.emailId;
  }
  async function getToDoProgress() {
    try {
      const response = await axiosData.get("seekers/Count");
      if (response.data === "") {
        setCountSeeker(0);
      } else {
        setCountSeeker(response.data);
      }

      const res = await axiosData.get("employers/Count");

      if (res.data === "") {
        setCountProvider(0);
      } else {
        setCountProvider(res.data);
      }

      const need = await axiosData.get("family/needsupport/Count");

      if (need.data === "") {
        setCountneedsupport(0);
      } else {
        setCountneedsupport(need.data);
      }

      const volunteer = await axiosData.get("volunteer/Count");

      if (volunteer.data === "") {
        setCountVolunteer(0);
      } else {
        setCountVolunteer(volunteer.data);
      }
      const serviceprvider = await axiosData.get("localserviceprovider/Count");

      if (serviceprvider.data === "") {
        setcountServiceProvider(0);
      } else {
        setcountServiceProvider(serviceprvider.data);
      }
      const postajob = await axiosData.get("localjobs/Count");
      if (postajob.data === "") {
        setcountpostajob(0);
      } else {
        setcountpostajob(postajob.data);
      }
      const servicerequest = await axiosData.get("localservices/Count");
      if (servicerequest.data === "") {
        setcountservicesrequest(0);
      } else {
        setcountservicesrequest(servicerequest.data);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  }
  const changeLanguage = (languagetranslator) => {
    i18n.changeLanguage(languagetranslator);
  };

  const pincodedropdown = [
    { label: "Local Jobs" },
    { label: "Local Services" },
    { label: "Service Providers" },
  ];

  const latestjobs = async () => {
    try {
      const response = await axiosData.get(`urgentjobs/allJobs/landing`, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.data) {
        const week = response.data;
        setRecentJobs([...week.reverse()]);
      }
      if (response.status === 204 || response.data.length === 0) {
        setLoading(false);
        setRecentJobs("");
      }
    } catch (err) {
      console.log(err);
    }
  };
  const latestLocaljobs = async () => {
    try {
      const response = await axiosData.get(`urgentlocaljobs/allJobs/landing`, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.data) {
        const week = response.data;
        setRecentlocalJobs([...week.reverse()]);
      }
      if (response.status === 204 || response.data.length === 0) {
        setLoading(false);
        setRecentlocalJobs("");
      }
    } catch (err) {
      console.log(err);
    }
  };
  const latestLocalservices = async () => {
    try {
      const response = await axiosData.get(`urgentservices/allJobs/landing`, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.data) {
        const week = response.data;
        setRecentlocalServices([...week.reverse()]);
      }
      if (response.status === 204 || response.data.length === 0) {
        setLoading(false);
        setRecentlocalServices("");
      }
    } catch (err) {
      console.log(err);
    }
  };
  const vodingDistrict = async () => {
    try {
      const getOneAxios = await axiosData.get(`/distritarea`);
      const data = getOneAxios.data;
      if (data) {
        const getDistrict = data.map((i) => {
          return i.District;
        });
        getDistrict.sort();
        setsearchResultDatas(getDistrict);
        setDistrictData(getDistrict);
        setLoading(false);
      }
      return data;
    } catch (err) {
      console.log("Error", err);
    }
  };
  const addGet = async () => {
    const storedLocation = localStorage.getItem("currentLocationPincode");
    const storedLocationDistrict = localStorage.getItem(
      "currentLocationDistrict"
    );
    setLoading(true);
    try {
      const response = await axiosData.get(`advertisement/allAds/landing`, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.data) {
        if (storedLocationDistrict === "" || storedLocation === "") {
          const center = response.data.filter(
            (data) => data.image_position === "centerimage"
          );
          setCenterAdlength(center.length <= 0 ? 0 : center.length);
          setAddGetCenter([...center.reverse()]);

          const topbanner = response.data.filter(
            (data) => data.image_position === "topimage"
          );
          settopAdlength(topbanner.length <= 0 ? 0 : topbanner.length);
          setaddGettopbanner([...topbanner.reverse()]);
          const sideRight = response.data.filter(
            (data) => data.image_position === "sideimageright"
          );
          setsiderightAdlength(sideRight.length <= 0 ? 0 : sideRight.length);
          setAddGetSideRight([...sideRight.reverse()]);

          const sideLeft = response.data.filter(
            (data) => data.image_position === "sideimageleft"
          );
          setsideleftAdlength(sideLeft.length <= 0 ? 0 : sideLeft.length);
          setAddGetSideLeft([...sideLeft.reverse()]);
          const bottom = response.data.filter(
            (data) => data.image_position === "bottomimage"
          );
          setAddGetBottom([...bottom.reverse()]);
          const popup = response.data.filter(
            (data) => data.image_position === "popupimage"
          );
          if (popup.length <= 0) {
            setfacebookpopup_popup(true);
          } else {
            setfacebookpopup_popup(false);
          }
          setpopupAdlength(popup.length <= 0 ? 0 : popup.length);
          setAddGetPopup([...popup.reverse()]);
          const bottomfooter = response.data.filter(
            (data) => data.image_position === "bottomimagefooter"
          );
          setbottomfooterAdlength(
            bottomfooter.length <= 0 ? 0 : bottomfooter.length
          );
          setAddGetBottomFooter([...bottomfooter.reverse()]);
          const facebookaddL = response.data.filter(
            (data) => data.image_position === "facebookpostsize"
          );
          setfacebookaddlength(
            facebookaddL.length <= 0 ? 0 : facebookaddL.length
          );
          setAddfacebookadd([...facebookaddL.reverse()]);

          const instagramaddL = response.data.filter(
            (data) => data.image_position === "instagrampostsize"
          );
          setinstagramaddlength(
            instagramaddL.length <= 0 ? 0 : instagramaddL.length
          );
          setAddinstagramadd([...instagramaddL.reverse()]);

          const facebookpopup = response.data.filter(
            (data) => data.image_position === "popupfacebookimage"
          );
          setfacebookpopupaddlength(
            facebookpopup.length <= 0 ? 0 : facebookpopup.length
          );
          setfacebookpopupadd([...facebookpopup.reverse()]);
          setLoading(false);
          setAdvertisementOpen(true);
        } else {
          const districtDatas = response.data.filter(
            (datas) =>
              datas.Ad_district === storedLocationDistrict ||
              datas.Ad_district === "Select All"
          );
          const week = districtDatas.filter(
            (datas) =>
              datas.pincode === storedLocation || datas.pincode === "Select All"
          );
          // const week = response.data.filter(
          //   (data) => data.Ad_district === storedLocation
          // );
          if (
            week === "" ||
            week === undefined ||
            week === null ||
            week.length === 0
          ) {
            const center = districtDatas.filter(
              (data) => data.image_position === "centerimage"
            );
            setCenterAdlength(center.length <= 0 ? 0 : center.length);
            setAddGetCenter([...center.reverse()]);

            const topbanner = districtDatas.filter(
              (data) => data.image_position === "topimage"
            );
            settopAdlength(topbanner.length <= 0 ? 0 : topbanner.length);
            setaddGettopbanner([...topbanner.reverse()]);

            const sideRight = districtDatas.filter(
              (data) => data.image_position === "sideimageright"
            );
            setsiderightAdlength(sideRight.length <= 0 ? 0 : sideRight.length);
            setAddGetSideRight([...sideRight.reverse()]);

            const facebookaddL = districtDatas.filter(
              (data) => data.image_position === "facebookpostsize"
            );
            setfacebookaddlength(
              facebookaddL.length <= 0 ? 0 : facebookaddL.length
            );
            setAddfacebookadd([...facebookaddL.reverse()]);

            const instagramaddL = districtDatas.filter(
              (data) => data.image_position === "instagrampostsize"
            );
            setinstagramaddlength(
              instagramaddL.length <= 0 ? 0 : instagramaddL.length
            );
            setAddinstagramadd([...instagramaddL.reverse()]);

            const facebookpopup = districtDatas.filter(
              (data) => data.image_position === "popupfacebookimage"
            );
            setfacebookpopupaddlength(
              facebookpopup.length <= 0 ? 0 : facebookpopup.length
            );
            setfacebookpopupadd([...facebookpopup.reverse()]);

            const sideLeft = districtDatas.filter(
              (data) => data.image_position === "sideimageleft"
            );
            setsideleftAdlength(sideLeft.length <= 0 ? 0 : sideLeft.length);
            setAddGetSideLeft([...sideLeft.reverse()]);

            const bottom = districtDatas.filter(
              (data) => data.image_position === "bottomimage"
            );
            setAddGetBottom([...bottom.reverse()]);

            const popup = districtDatas.filter(
              (data) => data.image_position === "popupimage"
            );
            if (popup.length <= 0) {
              setfacebookpopup_popup(true);
            } else {
              setfacebookpopup_popup(false);
            }
            setpopupAdlength(popup.length <= 0 ? 0 : popup.length);
            setAddGetPopup([...popup.reverse()]);
            const bottomfooter = districtDatas.filter(
              (data) => data.image_position === "bottomimagefooter"
            );
            setbottomfooterAdlength(
              bottomfooter.length <= 0 ? 0 : bottomfooter.length
            );
            setAddGetBottomFooter([...bottomfooter.reverse()]);
            setLoading(false);
            setAdvertisementOpen(true);
          } else if (
            districtDatas === "" ||
            districtDatas === undefined ||
            districtDatas === null ||
            districtDatas.length === 0
          ) {
            const center = response.data.filter(
              (data) => data.image_position === "centerimage"
            );
            setCenterAdlength(center.length <= 0 ? 0 : center.length);
            setAddGetCenter([...center.reverse()]);

            const topbanner = response.data.filter(
              (data) => data.image_position === "topimage"
            );
            settopAdlength(topbanner.length <= 0 ? 0 : topbanner.length);
            setaddGettopbanner([...topbanner.reverse()]);
            const sideRight = response.data.filter(
              (data) => data.image_position === "sideimageright"
            );
            setsiderightAdlength(sideRight.length <= 0 ? 0 : sideRight.length);
            setAddGetSideRight([...sideRight.reverse()]);

            const sideLeft = response.data.filter(
              (data) => data.image_position === "sideimageleft"
            );
            setsideleftAdlength(sideLeft.length <= 0 ? 0 : sideLeft.length);
            setAddGetSideLeft([...sideLeft.reverse()]);
            const bottom = response.data.filter(
              (data) => data.image_position === "bottomimage"
            );
            setAddGetBottom([...bottom.reverse()]);
            const popup = response.data.filter(
              (data) => data.image_position === "popupimage"
            );
            if (popup.length <= 0) {
              setfacebookpopup_popup(true);
            } else {
              setfacebookpopup_popup(false);
            }
            setpopupAdlength(popup.length <= 0 ? 0 : popup.length);
            setAddGetPopup([...popup.reverse()]);
            const bottomfooter = response.data.filter(
              (data) => data.image_position === "bottomimagefooter"
            );
            setbottomfooterAdlength(
              bottomfooter.length <= 0 ? 0 : bottomfooter.length
            );
            setAddGetBottomFooter([...bottomfooter.reverse()]);
            const facebookaddL = response.data.filter(
              (data) => data.image_position === "facebookpostsize"
            );
            setfacebookaddlength(
              facebookaddL.length <= 0 ? 0 : facebookaddL.length
            );
            setAddfacebookadd([...facebookaddL.reverse()]);

            const instagramaddL = response.data.filter(
              (data) => data.image_position === "instagrampostsize"
            );
            setinstagramaddlength(
              instagramaddL.length <= 0 ? 0 : instagramaddL.length
            );
            setAddinstagramadd([...instagramaddL.reverse()]);

            const facebookpopup = response.data.filter(
              (data) => data.image_position === "popupfacebookimage"
            );
            setfacebookpopupaddlength(
              facebookpopup.length <= 0 ? 0 : facebookpopup.length
            );
            setfacebookpopupadd([...facebookpopup.reverse()]);
            setLoading(false);
            setAdvertisementOpen(true);
          } else {
            const center = week.filter(
              (data) => data.image_position === "centerimage"
            );
            setCenterAdlength(center.length <= 0 ? 0 : center.length);
            setAddGetCenter([...center.reverse()]);

            const topbanner = week.filter(
              (data) => data.image_position === "topimage"
            );
            settopAdlength(topbanner.length <= 0 ? 0 : topbanner.length);
            setaddGettopbanner([...topbanner.reverse()]);
            const sideRight = week.filter(
              (data) => data.image_position === "sideimageright"
            );
            setsiderightAdlength(sideRight.length <= 0 ? 0 : sideRight.length);
            setAddGetSideRight([...sideRight.reverse()]);

            const sideLeft = week.filter(
              (data) => data.image_position === "sideimageleft"
            );
            setsideleftAdlength(sideLeft.length <= 0 ? 0 : sideLeft.length);
            setAddGetSideLeft([...sideLeft.reverse()]);
            const bottom = week.filter(
              (data) => data.image_position === "bottomimage"
            );
            setAddGetBottom([...bottom.reverse()]);
            const popup = week.filter(
              (data) => data.image_position === "popupimage"
            );
            if (popup.length <= 0) {
              setfacebookpopup_popup(true);
            } else {
              setfacebookpopup_popup(false);
            }
            setpopupAdlength(popup.length <= 0 ? 0 : popup.length);
            setAddGetPopup([...popup.reverse()]);
            const bottomfooter = week.filter(
              (data) => data.image_position === "bottomimagefooter"
            );
            setbottomfooterAdlength(
              bottomfooter.length <= 0 ? 0 : bottomfooter.length
            );
            setAddGetBottomFooter([...bottomfooter.reverse()]);
            const facebookaddL = week.filter(
              (data) => data.image_position === "facebookpostsize"
            );
            setfacebookaddlength(
              facebookaddL.length <= 0 ? 0 : facebookaddL.length
            );
            setAddfacebookadd([...facebookaddL.reverse()]);

            const instagramaddL = week.filter(
              (data) => data.image_position === "instagrampostsize"
            );
            setinstagramaddlength(
              instagramaddL.length <= 0 ? 0 : instagramaddL.length
            );
            setAddinstagramadd([...instagramaddL.reverse()]);

            const facebookpopup = week.filter(
              (data) => data.image_position === "popupfacebookimage"
            );
            setfacebookpopupaddlength(
              facebookpopup.length <= 0 ? 0 : facebookpopup.length
            );
            setfacebookpopupadd([...facebookpopup.reverse()]);
            setLoading(false);
            setAdvertisementOpen(true);
          }
        }
      } else if (response.status === 204 || response.data.length === 0) {
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const [top, settop] = useState("56%");

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPosition =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScrollPosition > 0) {
        settop("61%"); // Change the header background color to blue when scrolled down
      } else {
        settop("68%"); // Change the header background color to transparent when at the top
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const stickyDivStyle = {
    position: "fixed",
    top: top, // You can adjust the value to set the position where the div becomes sticky
    backgroundColor: "lightgray",
    width: "120px",
    padding: "10px",
    height: "600px",
    transform: "translateY(-51%)",
    transition: "0.3s",
    zIndex: "100", // You may adjust the z-index to make sure it appears above other elements if necessary
  };
  const stickyDivStylees = {
    position: "fixed",
    top: top,
    right: "0%", // You can adjust the value to set the position where the div becomes sticky
    backgroundColor: "lightgray",
    padding: "10px",
    width: "120px",
    height: "600px",
    transform: "translateY(-51%)",
    transition: "0.3s",
    zIndex: "100", // You may adjust the z-index to make sure it appears above other elements if necessary
  };
  const [block, setblock] = useState("true");
  const [block_1, setblock_1] = useState("true");

  useEffect(() => {
    const handleScroll = () => {
      const threshold = 0.5 * window.innerHeight; // Adjust this threshold as needed
      const scrollY = window.scrollY;
      if (scrollY > threshold) {
        setShowHiddenDiv(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const handleSuggestionslocation = async (event) => {
    let query = event.target.value.replace(/[^a-z., ]/gi, "");

    setSuggestionArray([]);
    setSuggestionArrayl([]);
    setSearchQuery(query);

    formik.setFieldValue("job_location", query);
    if (query.length >= 3) {
      try {
        const response = await axiosData.get(
          `/jobs/suggestionlocation/${query}`
        );
        setSuggestionArrayl(response.data);
        if (response.data) {
          response.data = response.data.filter((item) => {
            return !!item;
          });
          setSuggestionArrayl([...response.data]);
        } else {
          setSuggestionArrayl([]);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };
  const handleSuggestionsSkills = async (event) => {
    let query = event.target.value;

    if (query.startsWith("#")) {
      query = query.slice(1);
    }

    setSuggestionArray([]);
    setSearchQuery(query);
    formik.setFieldValue("key_skills", query);
    if (query.length >= 3) {
      try {
        const response = await axiosData.get(`/jobs/suggestion/${query}`);
        setSuggestionArray(response.data);
        if (response.data) {
          response.data = response.data.filter((item) => {
            return !!item;
          });
          setSuggestionArray([...response.data]);
        } else {
          setSuggestionArray([]);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };
  const formik = useFormik({
    initialValues: {
      key_skills: null,
      industry: null,
      job_location: null,
      page_number: 1,
    },
    onSubmit: (values) => {
      if (
        (values.industry === null || values.industry === "") &&
        (values.key_skills === null || values.key_skills === "") &&
        (values.job_location === null || values.job_location === "")
      ) {
        setSearchPopUp(true);
        // alert("atlest one field is requried");
      } else if (emailName2) {
        navigate(
          `${ROUTINGDATA.LAND}${ROUTINGDATA.MAIN}${ROUTINGDATA.seekerProfile}`,
          { state: emailName2 }
        );
        navigate("/main/RecommendedJob", {
          state: {
            industry: values,
            _id: seekerDetails._id,
            email_id: emailName2,
            from: "LandingPage",
          },
        });
      } else {
        navigate("/main/searchpage/result", {
          state: {
            search: values,
            email_id: "",
          },
        });
      }
    },
  });
  const searchforlocalservice = () => {
    if (
      servicetype.label === null ||
      servicetype.label === "" ||
      servicetype.label === undefined
    ) {
      setPincodeValue(pincodeValue);
      setAreaname(Areaname);
      setOpenService(true);
    } else {
      navigate("/main/searchpageforlocalservices", {
        state: {
          pincode: pincodeValue,
          Area: Areaname,
          servicetype: servicetype.label,
        },
      });
    }
  };
  useEffect(() => {
    getToDoProgress();
    if (isAdvertisementOpen === false) {
      setfacebookpopup_popup(true);
      settopbanner(true);
      setbottombanner(true);
      setfacebookpopup_1(true);
      setfacebookpopup_2(true);
      setfacebookpopup_popup(true);
    }
    changeLanguage(languagetranslator);
  }, [languagetranslator]);
  useEffect(() => {
    const storedLocation = localStorage.getItem("currentLocationPincode");
    const storedLocationDistrict = localStorage.getItem(
      "currentLocationDistrict"
    );
    if (
      (storedLocation === "" ||
        storedLocation === null ||
        storedLocation === undefined) &&
      (storedLocationDistrict === "" ||
        storedLocationDistrict === null ||
        storedLocationDistrict === undefined)
    ) {
      getCurrentLocation();
    } else {
      addGet();
    }
    if (isAdvertisementOpen === false) {
      setfacebookpopup_popup(true);
      settopbanner(true);
      setbottombanner(true);
      setfacebookpopup_1(true);
      setfacebookpopup_2(true);
      setfacebookpopup_popup(true);
    }
    vodingDistrict();
    IndustryData();
    //setUserDistrict("");
    const popupIndex = JSON.parse(
      window.localStorage.getItem("currentIndexpopup")
    );
    setCurrentIndexpopup(popupIndex === null ? 0 : popupIndex);
    const facebookpopupIndex = JSON.parse(
      window.localStorage.getItem("currentIndexpopupfacebook")
    );
    setCurrentfacebookpopupadd(
      facebookpopupIndex === null ? 0 : facebookpopupIndex
    );
    setRecentJobs("");
    setRecentlocalJobs("");
    setRecentlocalServices("");
    latestjobs();
    latestLocaljobs();
    latestLocalservices();
  }, []);
  const lang = sessionStorage.getItem("lang");

  const filterOptions = (options, { inputValue }) => {
    const inputValueLower = inputValue.toLowerCase();
    const startsWithInput = [];
    const containsInput = [];

    options.forEach((option) => {
      const optionLower = option.toLowerCase();
      if (optionLower.startsWith(inputValueLower)) {
        startsWithInput.push(option);
      } else if (optionLower.includes(inputValueLower)) {
        containsInput.push(option);
      }
    });

    return [...startsWithInput, ...containsInput];
  };
  const inputRef = useRef(null);
  const handlefocus = () => {
    if (openServicefocus) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 400);
    }
  };
  useEffect(() => {
    if (openServicefocus) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 500);
    }
  }, [openServicefocus]);
  const [industryArray, setIndustryArray] = useState();

  const IndustryData = async () => {
    try {
      const getOneAxios = await axiosData.get(`/General/seekerSettings`);
      const data = getOneAxios?.data?.industryData;
      if (data) {
        setIndustryArray(data);
        setLoading(false);
      }
      return data;
    } catch (err) {
      console.log("Error", err);
    }
  };

  // useEffect(() => {
  //   IndustryData();
  //   addGet();
  //   setTimeout(() => {
  //   console.log("userDistrictgetuserDistrictget",userDistrictget)
  //   if (userDistrictget === "" || userDistrictget === undefined ||
  //   userDistrictget === null
  //   ) {
  //     setDistrictOpen(true);
  //   } else {
  //     const userDistrictget = localStorage.getItem("DistrictUser");
  //     addGet(userDistrictget);
  //   setUserDistrict(userDistrictget);
  //   setDistrictOpen(false);
  //   setAdvertisementOpen(true);
  //   }
  //   setAdvertisementOpen(true);
  //    }, 1000);
  // }, []);
  const searchbytitledatas = (searchdata, getApi) => {
    setLoading(true);
    const datas = getApi.filter((item) => {
      const outputString = searchdata.replace(/\s/g, "");
      const searchtext2 = new RegExp(outputString, "i");
      if (searchtext2) {
        var valuess = item.replace(/\s/g, "").toLowerCase().match(searchtext2);
      }
      if (valuess != null) {
        return item;
      }
    });
    if (datas) {
      setLoading(false);
      if (datas.length === 0) {
        setsearchResultDatas(datas);
        // setNoResultPopUp(true);
      } else {
        setsearchResultDatas(datas);
      }
    }
  };
  const featuresRoute = useRef(null);
  const aboutusRoute = useRef(null);
  const handleLinkClick = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleCloseAdvertisementclose = () => {
    setAdvertisementOpen(false);

    settopbanner(true);
    setbottombanner(true);
    setfacebookpopup_1(true);
    setfacebookpopup_2(true);
  };
  const updateClicksCount = async (clicks, id) => {
    try {
      const useAxiosData2 = await axiosData.put(`advertisement/${id}`, {
        number_of_clicks: clicks,
      });
      if (useAxiosData2.status === 204) {
      } else {
        addGet();
      }
    } catch (err) {
      console.log("Error", err);
    }
  };
  useEffect(() => {
    const interval = setInterval(() => {
      // if(addGetCenter.length === 0){
      //   setCurrentIndex(0);
      // }else{
      if (currentIndex === centerAdlength - 1) {
        setCurrentIndex(0);
      } else if (centerAdlength - 1 === 0) {
        setCurrentIndex(0);
      } else {
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }
      //}
    }, 20000);

    return () => {
      clearInterval(interval);
    };
  }, [currentIndex]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentIndextopbanner === topAdlength - 1) {
        setCurrentIndextopbanner(0);
      } else if (topAdlength === 1) {
        setCurrentIndextopbanner(0);
      } else if (topAdlength - 1 === 0) {
        setCurrentIndextopbanner(0);
      } else {
        setCurrentIndextopbanner((prevIndex) => prevIndex + 1);
      }
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, [currentIndextopbanner]);
  useEffect(() => {
    const interval = setInterval(() => {
      if (currentIndexbottomfooter === bottomfooterAdlength - 1) {
        setCurrentIndexbottomfooter(0);
      } else if (bottomfooterAdlength - 1 === 0) {
        setCurrentIndexbottomfooter(0);
      } else {
        setCurrentIndexbottomfooter((prevIndex) => prevIndex + 1);
      }
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, [currentIndexbottomfooter]);
  useEffect(() => {
    const interval = setInterval(() => {
      if (currentIndexfacebookadd === facebookaddlength - 1) {
        setCurrentIndexfacebookadd(0);
      } else if (facebookaddlength - 1 === 0) {
        setCurrentIndexfacebookadd(0);
      } else {
        setCurrentIndexfacebookadd((prevIndex) => prevIndex + 1);
      }
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, [currentIndexfacebookadd]);
  useEffect(() => {
    const interval = setInterval(() => {
      if (currentIndexfacebookpopup_down_add === facebookpopupaddlength - 1) {
        setCurrentIndexfacebookpopup_down_add(0);
      } else if (facebookpopupaddlength - 1 === 0) {
        setCurrentIndexfacebookpopup_down_add(0);
      } else {
        setCurrentIndexfacebookpopup_down_add((prevIndex) => prevIndex + 1);
      }
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, [currentIndexfacebookadd]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentIndexinstagramadd === instagramaddlength - 1) {
        setCurrentIndexinstagramadd(0);
      } else if (instagramaddlength - 1 === 0) {
        setCurrentIndexinstagramadd(0);
      } else {
        setCurrentIndexinstagramadd((prevIndex) => prevIndex + 1);
      }
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, [currentIndexinstagramadd]);
  useEffect(() => {
    const facebookpopupIndex = JSON.parse(
      window.localStorage.getItem("currentIndexpopupfacebook")
    );
    setCurrentfacebookpopupadd(
      facebookpopupIndex === null ? 0 : facebookpopupIndex
    );
  }, []);
  useEffect(() => {
    if (currentIndexfacebookpopupadd >= facebookpopupaddlength) {
      window.localStorage.setItem("currentIndexpopupfacebook", 0);
    } else if (facebookpopupaddlength - 1 === 0) {
      window.localStorage.setItem("currentIndexpopupfacebook", 0);
    } else {
      window.localStorage.setItem(
        "currentIndexpopupfacebook",
        currentIndexfacebookpopupadd
      );
    }
  }, [currentIndexfacebookpopupadd]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentIndexsideleft === sideleftAdlength - 1) {
        setCurrentIndexsideleft(0);
      } else if (sideleftAdlength - 1 === 0) {
        setCurrentIndexsideleft(0);
      } else {
        setCurrentIndexsideleft((prevIndex) => prevIndex + 1);
      }
    }, 10000);
    return () => {
      clearInterval(interval);
    };
  }, [currentIndexsideleft]);
  useEffect(() => {
    const interval = setInterval(() => {
      if (currentIndexsideright === siderightAdlength - 1) {
        setCurrentIndexsideright(0);
      } else if (siderightAdlength - 1 === 0) {
        setCurrentIndexsideright(0);
      } else {
        setCurrentIndexsideright((prevIndex) => prevIndex + 1);
      }
    }, 10000);
    return () => {
      clearInterval(interval);
    };
  }, [currentIndexsideright]);

  useEffect(() => {
    const popupIndex = JSON.parse(
      window.localStorage.getItem("currentIndexpopup")
    );
    setCurrentIndexpopup(popupIndex === null ? 0 : popupIndex);
  }, []);

  useEffect(() => {
    if (currentIndexpopup >= popupAdlength) {
      window.localStorage.setItem("currentIndexpopup", 0);
    } else if (popupAdlength - 1 === 0) {
      window.localStorage.setItem("currentIndexpopup", 0);
    } else {
      window.localStorage.setItem("currentIndexpopup", currentIndexpopup);
    }
  }, [currentIndexpopup]);

  if (ready) {
    return (
      <div>
        {/* <Dialog open={isAdvertisementOpen} onClose={handleCloseAdvertisementclose}>
      <DialogTitle>Advertisement</DialogTitle>
      <DialogContent>
        <div>Your advertisement content goes here.</div>
        <Button variant="contained" color="primary" onClick={handleCloseAdvertisementclose}>
          Close
        </Button>
      </DialogContent>
    </Dialog> */}
        {topbanner ? (
          addGettopbanner &&
          (addGettopbanner === "" ||
            addGettopbanner === undefined ||
            addGettopbanner === null ||
            currentIndextopbanner === topAdlength ||
            addGettopbanner.length <= 0) ? null : (
            <div className="top-advertisement">
              <div
                style={{
                  textAlign: "right",
                  position: "absolute",
                  right: "0%",
                  zIndex: "9999",
                }}
              >
                <IconButton
                  sx={{ backgroundColor: "transparent" }}
                  onClick={() => {
                    settopbanner(false);
                  }}
                >
                  <CloseIcon sx={{ color: "#0675a2", fontSize: 18 }} />
                </IconButton>
              </div>
              {/* <img src={Topbanner} alt="img" /> */}
              {addGettopbanner.map((item, index) => {
                if (index === currentIndextopbanner) {
                  return (
                    <a
                      key={index}
                      href={item.company_url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        key={item._id}
                        src={`${baseUrl}advertisement/advertisement/image/${item._id}/users`}
                        alt={`Img ${index + 1}`}
                        className={`sliding-image ${
                          index === currentIndextopbanner ? "active" : " "
                        }`}
                        onClick={() => {
                          const clickvalue = item.number_of_clicks + 1;
                          updateClicksCount(clickvalue, item._id);
                        }}
                      />
                    </a>
                  );
                }
              })}
            </div>
          )
        ) : (
          ""
        )}

        {/* {isDistrictOpen ? (
          <div
            style={{
              height: "100%",
              width: "100%",
              backgroundColor: "rgba(19, 18, 18, 0.7)",
              position: "fixed",
              zIndex: "99999",
              display: "grid",
              placeItems: "center",
            }}
          >
            <div
              className="popupboxcenter-main  maptamilnadu"
              style={{
                backgroundColor: "white",
              }}
            >
              <div style={{ marginTop: "20px", padding: "15px" }}>
                <TextField
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <span
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <SearchIcon fontSize="small" />
                        </span>
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    setsearchResultDatas(districtData);
                    setsearchdata(e.target.value);
                    if (e.target.value.length === 0 || e.target.value === "") {
                      setsearchResultDatas(districtData);
                    }
                    if (e.target.value.length < 3) {
                      setsearchResultDatas(districtData);
                    }
                    if (e.target.value.length >= 3) {
                      setsearchdata(e.target.value);
                      searchbytitledatas(e.target.value, districtData);
                    }
                  }}
                  value={searchdata.replace(/[^a-z'., ]/gi, "")}
                  size="small"
                  id="fullWidth"
                  placeholder="Search for your city.."
                  className="districk"
                />
              </div>
              <div style={{ textAlign: "center" }}>
                <h4 className="headerfordistrick">Tamil Nadu Districts</h4>
              </div>
              <div
                className="scroll-districk"
                style={{
                  marginTop: "20px",
                  padding: "15px",
                  overflow: "hidden",
                  overflowX: "hidden",
                  overflowY: "scroll",
                  height: "348px",
                }}
              >
                {searchResultDatas === "" || searchResultDatas.length === 0 ? (
                  <div
                    style={{
                      textAlign: "center",
                      padding: "7px",
                      fontSize: "15px",
                    }}
                  >
                    <h3>
                      There are no districts available in Tamil Nadu for your
                      search
                    </h3>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "20% 20% 20% 20% 20%",
                      margin: "10px",
                    }}
                  >
                    {searchResultDatas.map((item,index) => (
                      <div className="distirick-box">
                        <div className="districk-img">
                          <img
                            src={imagegrid[index]}
                            alt="no"
                            className="img-fordistrick"
                          />
                        </div>
                        <div
                          className="listdistrick"
                          style={{
                            padding: "7px",
                            fontSize: "14px",
                            color: "gray",
                          }}
                        >
                          <span
                            className="hoverdistrick"
                            onClick={(e) => {
                              window.localStorage.setItem(
                                "DistrictUser",
                                e.target.innerHTML
                              );
                              setDistrictOpen(false);
                              setUserDistrict(e.target.innerHTML);
                              addGet(e.target.innerHTML);
                            }}
                          >
                            {item}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : null} */}

        {isAdvertisementOpen === true ? (
          addGetPopup ? (
            addGetPopup === "" ||
            addGetPopup === undefined ||
            addGetPopup === null ||
            addGetPopup.length <= 0 ? null : (
              // (
              //   <div
              //     style={{
              //       height: "100%",
              //       width: "100%",
              //       backgroundColor: "rgba(19, 18, 18, 0.7)",
              //       position: "fixed",
              //       zIndex: "99999",
              //       display: "grid",
              //       placeItems: "center",
              //     }}
              //   >
              //     <div
              //       className="popupboxcenter"
              //       style={{
              //         borderRadius: "15px",
              //         backgroundColor: "white",
              //         position: "relative",
              //       }}
              //     >
              //       <div
              //         style={{
              //           textAlign: "right",
              //           position: "absolute",
              //           right: "0%",
              //           zIndex: "9999",
              //         }}
              //       >
              //         <IconButton
              //           onClick={() => {
              //             handleCloseAdvertisementclose();
              //             setCurrentIndexpopup(currentIndexpopup + 1);
              //           }}
              //           sx={{ backgroundColor: "white" }}
              //         >
              //           <CloseIcon sx={{ color: "#0675a2", fontSize: 18 }} />
              //         </IconButton>
              //       </div>
              //       <a
              //         href="https://www.velaivendum.com/#/main/jobseeker/"
              //         target="_blank"
              //         style={{ width: "100%" }}
              //         rel="noreferrer"
              //       >
              //         <video loop muted autoPlay controls className="videopopup">
              //           <source src={videoSource} type="video/mp4" />
              //         </video>
              //       </a>
              //     </div>
              //   </div>
              // )
              <div
                style={{
                  height: "100%",
                  width: "100%",
                  backgroundColor: "rgba(19, 18, 18, 0.7)",
                  position: "fixed",
                  zIndex: "99999",
                  display: "grid",
                  placeItems: "center",
                }}
              >
                <div
                  className="popupboxcenter"
                  style={{
                    borderRadius: "15px",
                    backgroundColor: "white",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      textAlign: "right",
                      position: "absolute",
                      right: "0%",
                      zIndex: "9999",
                    }}
                  >
                    <IconButton
                      onClick={() => {
                        handleCloseAdvertisementclose();
                        setCurrentIndexpopup(currentIndexpopup + 1);
                        setfacebookpopup_popup(true);
                      }}
                      sx={{ backgroundColor: "white" }}
                    >
                      <CloseIcon sx={{ color: "#0675a2", fontSize: 18 }} />
                    </IconButton>
                  </div>
                  {/* {addGetPopup === "" ||
              addGetPopup === undefined ||
              addGetPopup === null
                ? null
                : addGetPopup.map((item, index) => {
                    if (index === currentIndexpopup) {
                      return (
                        <a
                          key={currentIndexpopup}
                          href={`http://${item.company_url}`}
                          target="_blank"
                          style={{ width: "100%" }}
                          rel="noreferrer"
                        >
                          <img
                            key={item._id}
                            src={`http://localhost:8088/p-services/advertisement/advertisement/image/${item._id}/users`}
                            alt={`Img ${index + 1}`}
                            onClick={() => {}}
                          />
                        </a>
                      );
                    }
                  })} */}
                  {/* <a
                key={currentIndexpopup}
                href="http://www.velaivendum.com/#/main/jobseeker/"
                target="_blank"
                style={{ width: "100%" }}
              >
                <img src={splbanner} alt="" />
              </a> */}
                  {/* { timedelay ? (
                    <a
                      href="https://www.velaivendum.com/#/main/jobseeker/"
                      target="_blank"
                      style={{ width: "100%" }}
                      rel="noreferrer"
                    >
                      <video
                        loop
                        muted
                        autoPlay
                        controls
                        className="videopopup"
                      >
                        <source src={videoSource} type="video/mp4" />
                      </video>
                    </a>
                  ) : addGetPopup === "" ||
                    addGetPopup === undefined ||
                    addGetPopup === null ||
                    addGetPopup.length <= 0 ? null : (
                    addGetPopup.map((item, index) => {
                      if (index === currentIndexpopup) {
                        return (
                          <a
                            key={currentIndexpopup}
                            href={item.company_url}
                            target="blank"
                            style={{ width: "100%" }}
                          >
                            <img
                              key={item._id}
                              src={`${baseUrl}advertisement/advertisement/image/${item._id}/users`}
                              alt={`Img ${index + 1}`}
                            />
                          </a>
                        );
                      }
                    })
                  )} */}
                  {addGetPopup.map((item, index) => {
                    if (index === currentIndexpopup) {
                      return (
                        <a
                          key={currentIndexpopup}
                          href={
                            item.company_url === "" ? null : item.company_url
                          }
                          target="blank"
                          style={{ width: "100%" }}
                        >
                          {item.addUpload.mimetype === "video/mp4" ? (
                            <video
                              poster={
                                item?.videoThumbnailImage
                                  ? `${baseUrl}advertisement/advertisement/image/thumbnail/${item._id}/users`
                                  : null
                              }
                              muted
                              controls
                              key={item._id}
                              className="videopopup"
                              alt={`video ${index + 1}`}
                              // onClick={() => {
                              //   const clickvalue = item.number_of_clicks + 1;
                              //   updateClicksCount(clickvalue, item._id);
                              // }}
                            >
                              <source
                                src={`${baseUrl}advertisement/advertisement/image/${item._id}/users`}
                                type="video/mp4"
                              />
                            </video>
                          ) : (
                            <img
                              key={item._id}
                              src={`${baseUrl}advertisement/advertisement/image/${item._id}/users`}
                              alt={`Img ${index + 1}`}
                              onClick={() => {
                                const clickvalue = item.number_of_clicks + 1;
                                updateClicksCount(clickvalue, item._id);
                              }}
                            />
                          )}
                        </a>
                      );
                    }
                  })}
                </div>
              </div>
            )
          ) : null
        ) : null}

        {facebookpopup_popup === true ? (
          addfacebookpopupadd &&
          (addfacebookpopupadd === "" ||
            addfacebookpopupadd === undefined ||
            addfacebookpopupadd === null ||
            currentIndexfacebookpopupadd === facebookpopupaddlength ||
            addfacebookpopupadd.length <= 0) ? null : (
            <div
              style={{
                height: "100%",
                width: "100%",
                backgroundColor: "rgba(19, 18, 18, 0.7)",
                position: "fixed",
                zIndex: "99999",
                display: "grid",
                placeItems: "center",
              }}
            >
              <div
                className="popupboxcenterfacebook"
                style={{
                  borderRadius: "15px",
                  backgroundColor: "white",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    textAlign: "right",
                    position: "absolute",
                    right: "0%",
                    zIndex: "9999",
                  }}
                >
                  <IconButton
                    onClick={() => {
                      handleCloseAdvertisementclose();
                      setCurrentfacebookpopupadd(
                        currentIndexfacebookpopupadd + 1
                      );
                      setfacebookpopup_popup(false);
                    }}
                    sx={{ backgroundColor: "white" }}
                  >
                    <CloseIcon sx={{ color: "#0675a2", fontSize: 18 }} />
                  </IconButton>
                </div>
                {addfacebookpopupadd.map((item, index) => {
                  if (index === currentIndexfacebookpopupadd) {
                    return (
                      <a
                        key={index}
                        href={item.company_url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          key={item._id}
                          src={`${baseUrl}advertisement/advertisement/image/${item._id}/users`}
                          alt={`Img ${index + 1}`}
                          className={`sliding-mage ${
                            index === currentIndexfacebookpopupadd
                              ? "active"
                              : " "
                          }`}
                          onClick={() => {
                            const clickvalue = item.number_of_clicks + 1;
                            updateClicksCount(clickvalue, item._id);
                          }}
                        />
                      </a>
                    );
                  }
                })}
              </div>
            </div>
          )
        ) : null}

        <Dialog
          TransitionComponent={Transition}
          open={openService}
          onClose={() => setOpenService(false)}
          sx={{ "& .MuiPaper-root": { color: "white", width: "400px" } }}
        >
          <DialogTitle
            bgcolor="rgb(16 143 178)"
            sx={{ backgroundColor: "rgb(16 143 178)", fontWeight: 600 }}
          >
            {t("search")}
          </DialogTitle>
          <DialogContent sx={{ mt: 5, mb: 2 }}>
            <DialogContentText sx={{ fontWeight: 600 }}>
              {t("Types_of_Service_is_required")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => {
                setOpenService(false);
                setOpenServicefocus(true);
                handlefocus();
              }}
            >
              {t("Ok")}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          TransitionComponent={Transition}
          open={searchPopUp}
          // onClose={() => setSearchPopUp(false)}
          sx={{ "& .MuiPaper-root": { color: "white", width: "400px" } }}
        >
          <DialogTitle
            bgcolor="rgb(16 143 178)"
            sx={{ backgroundColor: "rgb(16 143 178)", fontWeight: 600 }}
          >
            {t("search")}
          </DialogTitle>
          <DialogContent sx={{ mt: 5, mb: 2 }}>
            <DialogContentText sx={{ fontWeight: 600 }}>
              {t("Atleast_one_field_is_requried_for_search")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => setSearchPopUp(false)}
            >
              {t("Ok")}
            </Button>
          </DialogActions>
        </Dialog>
        <div className="herotextimg">
          <div className="maintextforhero">
            <div id="search-part" className="search-part">
              <form onSubmit={formik.handleSubmit}>
                <div className="search-auto">
                  <Autocomplete
                    disablePortal
                    freeSolo
                    disableUnderline={false}
                    name="key_skills"
                    id="Enterskills_designations_companies"
                    options={suggestionArray}
                    onBlur={formik.handleBlur}
                    onChange={(event, value) => {
                      setSuggestionArray([]);
                      setsearchvalue({ ...searchvalue, key_skills: value });
                      formik.setFieldValue("key_skills", value);
                    }}
                    value={formik.values.key_skills}
                    sx={{ width: { xs: 350, md: 330, lg: 350 } }}
                    renderInput={(params) => (
                      <TextField
                        disableUnderline={false}
                        className="autocom-search-first"
                        sx={{
                          "& fieldset": { border: "none" },
                        }}
                        onChange={handleSuggestionsSkills}
                        name="key_skills"
                        id="Enterskills_designations_companies"
                        {...params}
                        label={t("Enterskills-designations-companies")}
                        size="small"
                      />
                    )}
                  />
                  {industryArray && (
                    <Autocomplete
                      disablePortal
                      disableUnderline={false}
                      filterOptions={filterOptions}
                      id="Select_experience"
                      name="industry"
                      options={industryArray}
                      onBlur={formik.handleBlur}
                      onChange={(event, value) => {
                        setsearchvalue({ ...searchvalue, industry: value });
                        formik.setFieldValue("industry", value);
                      }}
                      value={formik.values.industry}
                      sx={{ width: { xs: 350, md: 180, lg: 320 } }}
                      renderInput={(params) => (
                        <TextField
                          className="autocom-search-first"
                          sx={{
                            "& fieldset": { border: "none" },
                          }}
                          id="Select_experience"
                          name="industry"
                          {...params}
                          label={t("Industry")}
                          size="small"
                        />
                      )}
                    />
                  )}
                  <Autocomplete
                    disablePortal
                    freeSolo
                    id="Enter_location"
                    name="job_location"
                    options={suggestionArrayl}
                    onBlur={formik.handleBlur}
                    onChange={(event, value) => {
                      setSuggestionArrayl([]);
                      setsearchvalue({ ...searchvalue, job_location: value });
                      formik.setFieldValue("job_location", value);
                    }}
                    value={formik.values.job_location}
                    sx={{ width: { xs: 350, md: 180, lg: 320 } }}
                    renderInput={(params) => (
                      <TextField
                        className="autocom-search"
                        {...params}
                        sx={{
                          "& fieldset": { border: "none" },
                        }}
                        onChange={handleSuggestionslocation}
                        id="Enter_location"
                        name="Enter_location"
                        label={t("Enter-location")}
                        size="small"
                      />
                    )}
                  />
                  <Button
                    sx={{
                      borderRadius: "50px",
                      padding: "10px 33px",
                    }}
                    type="submit"
                    variant="contained"
                  >
                    {t("search")}
                  </Button>
                </div>
              </form>
            </div>
            <Box
              className="mobile-view-search"
              sx={{
                display: { xs: "flex", md: "none", lg: "none" },
                padding: "5px",
              }}
            >
              <Button
                variant="outlined"
                className={
                  mobilesearch === "true"
                    ? "mobile-search-active"
                    : "mobile-search"
                }
                onClick={() => {
                  setmobilesearch(true);
                }}
              >
                <PersonSearchIcon sx={{ color: "#ed1067" }} />
                {t("search")}...
              </Button>
            </Box>
            <Drawer
              sx={{
                "& .MuiDrawer-paper": {
                  height: "50%",
                  background: "rbga(255,255,255,0.8)",
                },
              }}
              open={mobilesearch}
              anchor={"top"}
            >
              <div className="phone-view">
                <form onSubmit={formik.handleSubmit}>
                  <div className="mobile-search-a">
                    <Autocomplete
                      disablePortal
                      freeSolo
                      disableUnderline={false}
                      name="key_skills"
                      id="Enterskills_designations_companies"
                      options={suggestionArray}
                      onBlur={formik.handleBlur}
                      onChange={(event, value) => {
                        setSuggestionArray([]);
                        setsearchvalue({ ...searchvalue, key_skills: value });
                        formik.setFieldValue("key_skills", value);
                      }}
                      value={formik.values.key_skills}
                      sx={{ width: { xs: 350, md: 330, lg: 350 } }}
                      renderInput={(params) => (
                        <TextField
                          disableUnderline={false}
                          className="autocom-search-first"
                          onChange={handleSuggestionsSkills}
                          name="key_skills"
                          id="Enterskills_designations_companies"
                          {...params}
                          label={t("Enterskills-designations-companies")}
                          size="small"
                        />
                      )}
                    />
                    {industryArray && (
                      <Autocomplete
                        disablePortal
                        id="Select_experience"
                        name="industry"
                        filterOptions={filterOptions}
                        options={industryArray}
                        onBlur={formik.handleBlur}
                        onChange={(event, value) => {
                          setsearchvalue({ ...searchvalue, industry: value });
                          formik.setFieldValue("industry", value);
                        }}
                        value={formik.values.industry}
                        sx={{ width: { xs: 350, md: 180, lg: 300 } }}
                        renderInput={(params) => (
                          <TextField
                            className="autocom-search-first"
                            id="Select_experience"
                            name="industry"
                            {...params}
                            label={t("Industry")}
                            size="small"
                          />
                        )}
                      />
                    )}
                    <Autocomplete
                      disablePortal
                      freeSolo
                      id="Enter_location"
                      name="job_location"
                      options={suggestionArrayl}
                      onBlur={formik.handleBlur}
                      onChange={(event, value) => {
                        setSuggestionArray([]);
                        setsearchvalue({ ...searchvalue, job_location: value });
                        formik.setFieldValue("job_location", value);
                      }}
                      value={formik.values.job_location}
                      sx={{ width: { xs: 350, md: 180, lg: 300 } }}
                      renderInput={(params) => (
                        <TextField
                          className="autocom-search"
                          {...params}
                          onChange={handleSuggestionslocation}
                          id="Enter_location"
                          name="Enter_location"
                          label={t("Enter-location")}
                          size="small"
                        />
                      )}
                    />
                    <Button
                      sx={{
                        borderRadius: "50px",
                        padding: "10px 33px",
                      }}
                      type="submit"
                      variant="contained"
                    >
                      {t("search")}
                    </Button>
                    <div className="mobile-close-btn">
                      <IconButton
                        onClick={() => {
                          setmobilesearch(false);
                        }}
                      >
                        <CloseIcon sx={{ color: "red" }} />
                      </IconButton>
                    </div>
                  </div>
                </form>
              </div>
            </Drawer>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "20px",
                flexWrap: "wrap",
              }}
            >
              <h3>
                {t("Welcome_to_Velaivendum")}
                {lang === "tamil" ? null : (
                  <text>
                    VELAIVENDUM<span>.com</span>
                  </text>
                )}
              </h3>
            </div>
            <p className={lang === "tamil" ? "tamil-p" : ""}>
              {" "}
              {t("Sub_Title")}
            </p>

            <div className="sidetitle-for-landingpage-1">
              {lang === "tamil" ? (
                <h3 style={{ margin: "0" }}>
                  <span style={{ color: "#1da1f2" }}>ப</span>திவுக்காக
                </h3>
              ) : (
                <h3 style={{ margin: "0" }}>
                  <span style={{ color: "#1da1f2" }}>F</span>or{" "}
                  <span style={{ color: "#1da1f2" }}>R</span>egistration
                </h3>
              )}
            </div>

            <div className="reg-btn">
              <Button
                variant="outlined"
                className="btn-reg"
                onClick={() => {
                  navigate(`${ROUTINGDATA.MAIN}${ROUTINGDATA.JOBSEEK}`, {
                    state: navigate(),
                  });
                }}
                sx={{
                  backgroundColor: "#0675a2",
                  color: "white",
                  border: "1px solid #0675a2",
                  borderRadius: "50px",
                }}
              >
                <SearchIcon
                  className="iconforcontect"
                  sx={{
                    fontSize: "20px",
                    padding: "5px",
                    borderRadius: "50%",
                    color: "#ed1067",
                    transition: "0.5s",
                    marginRight: "10px",
                    backgroundColor: "white",
                  }}
                />
                <text
                  style={{
                    fontFamily: "Bralow-Bold",
                    fontWeight: 700,
                    paddingTop: "4px",
                  }}
                >
                  {t("JOB_SEEKER")}
                </text>
              </Button>
              <Button
                variant="outlined"
                className="btn-reg"
                sx={{
                  backgroundColor: "#0675a2",
                  color: "white",
                  border: "1px solid #0675a2",

                  borderRadius: "50px",
                }}
                onClick={() =>
                  navigate(`${ROUTINGDATA.MAIN}${ROUTINGDATA.JOBPROVIDER}`, {
                    state: { registertype: "home", registerby: "home" },
                  })
                }
              >
                <BusinessIcon
                  className="iconforcontect"
                  sx={{
                    fontSize: "20px",
                    padding: "5px",
                    borderRadius: "50%",
                    color: "#ed1067",
                    transition: "0.5s",
                    marginRight: "10px",
                    backgroundColor: "white",
                  }}
                />
                <text
                  style={{
                    fontFamily: "Bralow-Bold",
                    fontWeight: 700,
                    paddingTop: "4px",
                  }}
                >
                  {t("EMPLOYER")}
                </text>
              </Button>
              <Button
                variant="outlined"
                className="btn-reg"
                sx={{
                  backgroundColor: "#0675a2",
                  color: "white",
                  border: "1px solid #0675a2",

                  borderRadius: "50px",
                }}
                onClick={() =>
                  navigate(`${ROUTINGDATA.MAIN}${ROUTINGDATA.VOLUNTEER}`)
                }
              >
                <VolunteerActivismIcon
                  className="iconforcontect"
                  sx={{
                    fontSize: "20px",
                    padding: "5px",
                    borderRadius: "50%",
                    color: "#ed1067",
                    transition: "0.5s",
                    marginRight: "10px",
                    backgroundColor: "white",
                  }}
                />
                <text
                  style={{
                    fontFamily: "Bralow-Bold",
                    fontWeight: 700,
                    paddingTop: "4px",
                  }}
                >
                  {t("Help_for_Job_Seekers")}
                </text>
              </Button>

              <Button
                variant="outlined"
                className="btn-reg"
                sx={{
                  backgroundColor: "#0675a2",
                  color: "white",
                  border: "1px solid #0675a2",

                  borderRadius: "50px",
                }}
                onClick={() =>
                  navigate(`${ROUTINGDATA.MAIN}${ROUTINGDATA.MENTOR}`, {
                    state: { registertype: "home", registerby: "home" },
                  })
                }
              >
                <PeopleAltRoundedIcon
                  className="iconforcontect"
                  sx={{
                    fontSize: "20px",
                    padding: "5px",
                    borderRadius: "50%",
                    color: "#ed1067",
                    transition: "0.5s",
                    marginRight: "10px",
                    backgroundColor: "white",
                  }}
                />
                <text
                  style={{
                    fontFamily: "Bralow-Bold",
                    fontWeight: 700,
                    paddingTop: "4px",
                  }}
                >
                  {t("Mentor")}
                </text>
              </Button>
              <Button
                variant="outlined"
                className="btn-reg"
                sx={{
                  backgroundColor: "#0675a2",
                  color: "white",
                  border: "1px solid #0675a2",
                  display: { xs: "none", md: "flex" },
                  borderRadius: "50px",
                }}
                onClick={() => {
                  navigate(
                    ROUTINGDATA.LAND +
                      ROUTINGDATA.MAIN +
                      ROUTINGDATA.ADVERTISEMENT
                    // {
                    //   state: { user_id: userDetails.userId, company_name: userDetails.company_name,email_id:userDetails.emailId,mobile_number:userDetails.mobile_number },
                    // }
                  );
                }}
              >
                <CampaignIcon
                  className="iconforcontect"
                  sx={{
                    fontSize: "20px",
                    padding: "5px",
                    borderRadius: "50%",
                    color: "#ed1067",
                    transition: "0.5s",
                    marginRight: "10px",
                    backgroundColor: "white",
                  }}
                />
                <text
                  style={{
                    fontFamily: "Bralow-Bold",
                    fontWeight: 700,
                    paddingTop: "4px",
                  }}
                >
                  {t("Create_post_Ad")}
                </text>
              </Button>
            </div>
          </div>
        </div>
        <div class="section-title" style={{ marginTop: "30px" }}>
          <h2 className={lang === "tamil" ? "section-title-p-tag" : ""}>
            {t("LATEST_JOBS")}
          </h2>
        </div>

        {showHiddenDiv === true ? (
          addGetSideLeft &&
          (addGetSideLeft === "" ||
            addGetSideLeft === undefined ||
            addGetSideLeft === null ||
            addGetSideLeft.length <= 0) ? null : (
            <div
              className={block === false ? "hidendiv-2" : "hidendiv"}
              style={stickyDivStyle}
            >
              {addGetSideLeft.map((item, index) => {
                if (index === currentIndexsideleft) {
                  return (
                    <a
                      key={index}
                      href={item.company_url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        key={item._id}
                        src={`${baseUrl}advertisement/advertisement/image/${item._id}/users`}
                        alt={`Img ${index + 1}`}
                        className={`sliding-image ${
                          index === currentIndexsideleft ? "active" : ""
                        }`}
                        onClick={() => {
                          const clickvalue = item.number_of_clicks + 1;
                          updateClicksCount(clickvalue, item._id);
                        }}
                      />
                    </a>
                  );
                }
              })}
              <div
                className="close"
                onClick={() => {
                  setblock(false);
                }}
              >
                <IconButton>
                  <CloseIcon
                    sx={{ color: "#0675a2", fontSize: 13 }}
                    fontSize="small"
                  />
                </IconButton>
              </div>
            </div>
          )
        ) : null}
        {showHiddenDiv === true ? (
          addGetSideRight &&
          (addGetSideRight === "" ||
            addGetSideRight === undefined ||
            addGetSideRight === null ||
            addGetSideRight.length <= 0) ? null : (
            <div
              className={block_1 === false ? "hidendiv-2" : "hidendiv-1"}
              style={stickyDivStylees}
            >
              {addGetSideRight.map((item, index) => {
                if (index === currentIndexsideright) {
                  return (
                    <a
                      key={index}
                      href={item.company_url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        key={item._id}
                        src={`${baseUrl}advertisement/advertisement/image/${item._id}/users`}
                        alt={`Img ${index + 1}`}
                        className={`sliding-image ${
                          index === currentIndexsideright ? "active" : ""
                        }`}
                        onClick={() => {
                          const clickvalue = item.number_of_clicks + 1;
                          updateClicksCount(clickvalue, item._id);
                        }}
                      />
                    </a>
                  );
                }
              })}
              <div
                className="close-1"
                onClick={() => {
                  setblock_1(false);
                }}
              >
                <IconButton>
                  <CloseIcon
                    sx={{ color: "#0675a2", fontSize: 13 }}
                    fontSize="small"
                  />
                </IconButton>
              </div>{" "}
            </div>
          )
        ) : null}

        <div id="latest jobs">
          {/* <div className="slide"> */}
          {recentJobs === "" ||
          recentJobs === undefined ||
          recentJobs === null ? null : (
            <Marquee pauseOnHover speed={150}>
              {recentJobs.map((item, index) => {
                return (
                  <div
                    key={item._id}
                    className="latestjobsbox"
                    style={{
                      display: "grid",
                      gap: "10px",
                      gridTemplateColumns: "20% 70%",
                      padding: "10px",
                    }}
                    onClick={async () => {
                      try {
                        const response = await axiosData.get(
                          `urgentjobs/getOne/${item._id}`,
                          {
                            headers: {
                              "Content-Type": "multipart/form-data",
                            },
                          }
                        );
                        const values = {};
                        // values.job_title = item.job_title
                        values.key_skills = item.job_title;
                        // values.job_location = item.job_location;
                        //values.company_name =item.company_name;
                        values.page_number = 1;
                        values._id = item._id;
                        values.job_id = item.job_id;
                        //values.industry = item.industry;
                        try {
                          const responseRelatedJobs = await axiosData.post(
                            `urgentjobs/recommended`,
                            values,
                            {
                              headers: {
                                "Content-Type": "multipart/form-data",
                              },
                            }
                          );
                          const filterJobs = responseRelatedJobs.data.filter(
                            (datas) => datas._id !== item._id
                          );
                          if (emailName2) {
                            navigate(
                              `${ROUTINGDATA.LAND}${ROUTINGDATA.MAIN}${ROUTINGDATA.seekerProfile}`,
                              { state: emailName2 }
                            );
                            navigate("/main/RecommendedJob", {
                              state: {
                                industry: values,
                                _id: seekerDetails._id,
                                email_id: emailName2,
                                from: "LandingPage",
                              },
                            });
                          } else {
                            navigate("/main/searchpage/result", {
                              state: {
                                search: values,
                                email_id: "",
                              },
                            });
                          }
                        } catch (err) {}
                      } catch (err) {}
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        width="85%"
                        height="100%"
                        style={{ borderRadius: "50%" }}
                        src={`p-services/employers/logo/email/${item.employer_email_id}/users`}
                        alt="Companylogo"
                        onError={(e) => {
                          e.target.src = latestjobimg;
                        }}
                      />
                    </div>
                    <div>
                      <div className="headerforlatestjob">{item.job_title}</div>
                      <div className="latestcompanyname">
                        {item.company_name}
                      </div>
                      <div className="latestcompanyname-1">
                        {item.salary_range_min} - {item.salary_range_max}{" "}
                        {item.salary_type === "Month" ? (
                          <>Per Month</>
                        ) : item.salary_type === "Year" ? (
                          <>LPA</>
                        ) : null}
                      </div>
                    </div>
                  </div>
                );
              })}
            </Marquee>
          )}
        </div>

        <div id="countnumber" className="countnumber">
          <div className="countbox">
            <div className="countboxbox  countbox-1">
              <div className="count-box-contect">
                {/* <SearchIcon sx={{ fontSize: "30px", color: "#ed1067" }} /> */}
                <CountUp
                  className="count-box-h3"
                  start={0}
                  end={countSeeker}
                  duration={5}
                />
              </div>
              <p> {t("JOB_SEEKER")}</p>
            </div>
            <div className="countboxbox  countbox-2">
              <div className="count-box-contect">
                {/* <BusinessIcon sx={{ fontSize: "30px", color: "#ed1067" }} /> */}
                <CountUp
                  className="count-box-h3"
                  start={0}
                  end={countProvider}
                  duration={5}
                />
              </div>
              <p>{t("EMPLOYER")}</p>
            </div>
            <div className="countboxbox  countbox-3">
              <div className="count-box-contect">
                {/* <BusinessIcon sx={{ fontSize: "30px", color: "#ed1067" }} /> */}
                <CountUp
                  className="count-box-h3"
                  start={0}
                  end={countVolunteer}
                  duration={5}
                />
              </div>
              <p> {t("Help_for_Job_Seekers")}</p>
            </div>
            <div className="countboxbox  countbox-3">
              <div className="count-box-contect">
                {/* <BusinessIcon sx={{ fontSize: "30px", color: "#ed1067" }} /> */}
                <CountUp
                  className="count-box-h3"
                  start={0}
                  end={countneedsupport}
                  duration={5}
                />
              </div>
              <p>{t("NEEDSUPPORT")}</p>
            </div>
          </div>
        </div>
        <div class="section-title">
          <h2 className={lang === "tamil" ? "section-title-p-tag" : ""}>
            {t("LATEST_LOCAL_JOBS")}
          </h2>
          {/* <p>Local Jobs</p> */}
        </div>
        <div id="latest jobs">
          {recentlocalJobs === "" ||
          recentlocalJobs === undefined ||
          recentlocalJobs === null ? null : (
            <Marquee pauseOnHover speed={150} direction="right">
              {recentlocalJobs.map((jobs, index) => {
                return (
                  <div
                    key={jobs._id}
                    className="latestjobsbox"
                    style={{
                      display: "grid",
                      gap: "10px",
                      gridTemplateColumns: "20% 70%",
                      padding: "10px",
                    }}
                    onClick={async () => {
                      try {
                        const response = await axiosData.get(
                          `urgentlocaljobs/getOne/${jobs._id}`,
                          {
                            headers: {
                              "Content-Type": "multipart/form-data",
                            },
                          }
                        );
                        const values = {};
                        values.job_title = jobs.job_title;

                        //values.type_of_service = jobs.job_location;
                        //values.shop_name =jobs.company_name;
                        // values.page_number = 1;
                        //values.industry = jobs.industry;
                        try {
                          const responseRelatedJobs = await axiosData.post(
                            `urgentlocaljobs/recommended`,
                            values,
                            {
                              headers: {
                                "Content-Type": "multipart/form-data",
                              },
                            }
                          );
                          const filterJobs = responseRelatedJobs.data.filter(
                            (datas) => datas._id !== jobs._id
                          );

                          navigate("/main/searchpageforlocalservices", {
                            state: {
                              _id: jobs._id,
                              job_id: jobs.job_id,
                              job_title: jobs.job_title,
                              servicetype: "Local Jobs",
                            },
                          });
                        } catch (err) {}
                      } catch (err) {}
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        width="85%"
                        height="85%"
                        style={{ borderRadius: "50%" }}
                        src={`${baseUrl}localjobs/logo/jobid/${jobs.user_id}/localjobs`}
                        alt="Companylogo"
                        onError={(e) => {
                          e.target.src = latestjobimg;
                        }}
                      />
                    </div>
                    <div>
                      <div className="headerforlatestjob">{jobs.job_title}</div>
                      <div className="latestcompanyname">{jobs.shop_name}</div>
                      <div className="latestcompanyname-1">
                        {jobs.salary_range_min}-{jobs.salary_range_max} |{" "}
                        {jobs.area_street_name}
                      </div>
                    </div>
                  </div>
                );
              })}
            </Marquee>
          )}
        </div>
        <div id="Localjobservice" className="Localjobservice">
          <div className="sidetitle-for-landingpage  sidetitle">
            <h2>{t("Local_Jobs_/_Services")}</h2>
          </div>
          <div className="Localjobservicebtn">
            <div>
              <fieldset
                className="Localpostjob"
                onClick={() =>
                  navigate(ROUTINGDATA.MAIN + ROUTINGDATA.LOCALJOB)
                }
              >
                <legend style={{ textAlign: "center" }}>
                  <WysiwygIcon
                    className="Localjobicons"
                    sx={{
                      transition: "0.3s",
                      backgroundColor: "white",
                      color: "#ed1e70",
                      fontSize: "30px",
                      border: "1px solid #ed1e70",
                      padding: "5px",
                      borderRadius: "50%",
                    }}
                  />
                </legend>
                {t("Post_a_Local_Jobs")}
              </fieldset>
            </div>
            <div>
              <fieldset
                className="Localjobservicebtnname"
                onClick={() =>
                  navigate(ROUTINGDATA.MAIN + ROUTINGDATA.LOCAL_SERVICES, {
                    state: { registertype: "home", registerby: "home" },
                  })
                }
              >
                <legend style={{ textAlign: "center" }}>
                  {" "}
                  <ManageAccountsIcon
                    className="Localjobicons"
                    sx={{
                      transition: "0.3s",
                      backgroundColor: "white",
                      color: "#ed1e70",
                      fontSize: "30px",
                      border: "1px solid #ed1e70",
                      padding: "5px",
                      borderRadius: "50%",
                    }}
                  />
                </legend>
                {t("Service_Request")}
              </fieldset>
            </div>
            <div>
              <fieldset
                className="Localjobservicebtnname"
                onClick={() =>
                  navigate(
                    ROUTINGDATA.MAIN + ROUTINGDATA.LOCAL_SERVICE_PROVIDER,
                    {
                      state: { registertype: "home", registerby: "home" },
                    }
                  )
                }
              >
                <legend style={{ textAlign: "center" }}>
                  <EngineeringRoundedIcon
                    className="Localjobicons"
                    sx={{
                      transition: "0.3s",
                      backgroundColor: "white",
                      color: "#ed1e70",
                      fontSize: "30px",
                      border: "1px solid #ed1e70",
                      padding: "5px",
                      borderRadius: "50%",
                    }}
                  />
                </legend>
                {t("Service_Provider")}
              </fieldset>
            </div>
          </div>
        </div>

        <div class="section-title">
          <h2 className={lang === "tamil" ? "section-title-p-tag" : ""}>
            {t("LATEST_LOCAL_SERVICES")}
          </h2>
          {/* <p>Local Jobs</p> */}
        </div>
        <div id="latest jobs" style={{ display: "flex", padding: "10px 0px" }}>
          {recentlocalServices === "" ||
          recentlocalServices === undefined ||
          recentlocalServices === null ? null : (
            <Marquee pauseOnHover speed={150}>
              {recentlocalServices.map((jobs, index) => {
                return (
                  <div
                    key={jobs._id}
                    className="latestjobsbox-1"
                    style={{
                      display: "grid",
                      gap: "10px",
                      gridTemplateColumns: "20% 70%",
                      padding: "10px",
                    }}
                    onClick={async () => {
                      //values.type_of_service = jobs.job_location;
                      //values.shop_name =jobs.company_name;
                      // values.page_number = 1;
                      //values.industry = jobs.industry;

                      navigate("/main/searchpageforlocalservices", {
                        state: {
                          _id: jobs._id,
                          job_id: jobs.job_id,
                          type_of_service: jobs.type_of_service,
                          servicetype: "Local Services",
                        },
                      });
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        width="100%"
                        style={{ borderRadius: "50%" }}
                        src={latestjobimg}
                        alt="Companylogo"
                        onError={(e) => {
                          e.target.src = latestjobimg;
                        }}
                      />
                    </div>
                    <div>
                      <div className="headerforlatestjob-1">
                        {jobs.type_of_service}
                      </div>
                      <div className="latestcompanyname">
                        {" "}
                        {jobs.place_of_service === "Company"
                          ? jobs.shop_name
                          : `${jobs.place_of_service}${""} Service`}
                      </div>
                      <div className="latestcompanyname-1">
                        {jobs.area_street_name}
                      </div>
                    </div>
                  </div>
                );
              })}
            </Marquee>
          )}
        </div>
        <div id="countnumber" className="countnumber">
          <div className="countbox">
            <div className="countboxbox  countbox-1">
              <div className="count-box-contect">
                {/* <SearchIcon sx={{ fontSize: "30px", color: "#ed1067" }} /> */}
                <CountUp
                  className="count-box-h3"
                  start={0}
                  end={countpostajob}
                  duration={5}
                />
              </div>
              <p>{t("LOCAL_JOBS")}</p>
            </div>
            <div className="countboxbox  countbox-2">
              <div className="count-box-contect">
                {/* <BusinessIcon sx={{ fontSize: "30px", color: "#ed1067" }} /> */}
                <CountUp
                  className="count-box-h3"
                  start={0}
                  end={countservicesrequest}
                  duration={5}
                />
              </div>
              <p>{t("Service_Request")}</p>
            </div>
            <div className="countboxbox  countbox-3">
              <div className="count-box-contect">
                {/* <BusinessIcon sx={{ fontSize: "30px", color: "#ed1067" }} /> */}
                <CountUp
                  className="count-box-h3"
                  start={0}
                  end={countServiceProvider}
                  duration={5}
                />
              </div>
              <p>{t("Service_Provider")}</p>
            </div>
          </div>
        </div>
        <div id="search-part" className="search-part-2">
          <form>
            <div className="search-auto serachforlocal">
              <TextField
                className="autocom-search-first"
                type="number"
                sx={{
                  "& fieldset": { border: "none" },
                }}
                onChange={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 6);
                  setPincodeValue(e.target.value);
                }}
                id="Enter_location"
                name="Enter_location"
                label={t("Pincode")}
                size="small"
              />
              <TextField
                className="autocom-search-first"
                sx={{
                  "& fieldset": { border: "none" },
                }}
                onChange={(e) => {
                  setAreaname(e.target.value);
                }}
                id="Enter_location"
                name="Enter_location"
                label={t("Areaname")}
                size="small"
              />

              <Autocomplete
                disablePortal
                disableUnderline={false}
                disableClearable
                id="Select_experience"
                name="serviceType"
                options={pincodedropdown}
                onChange={(event, value) => {
                  setServicetype(value);
                }}
                value={servicetype}
                sx={{ width: { xs: 350, md: 250, lg: 320 } }}
                renderInput={(params) => (
                  <TextField
                    className="autocom-search-first"
                    sx={{
                      "& fieldset": { border: "none" },
                    }}
                    inputRef={inputRef}
                    //{...openServicefocus ? 'autoFocus' : null}
                    //autoFocus={openServicefocus}
                    id="Select_experience"
                    name="serviceType"
                    onChange={(e) => setServicetype(e.target.value)}
                    {...params}
                    label={t("Typesofservices")}
                    size="small"
                  />
                )}
              />
              <Button
                sx={{
                  borderRadius: "50px",
                  padding: "10px 33px",
                }}
                // type="submit"
                onClick={() => searchforlocalservice()}
                variant="contained"
              >
                {t("search")}
              </Button>
            </div>
          </form>
        </div>
        {addGetCenter &&
        (addGetCenter === "" ||
          addGetCenter === undefined ||
          addGetCenter === null ||
          currentIndex === centerAdlength ||
          addGetCenter.length <= 0) ? null : (
          <div
            id="advertismentcenterbanner-part"
            className="advertismentcenterbanner-part"
          >
            <Container
              fixed
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="banneradvertisment">
                {" "}
                {addGetCenter.map((item, index) => {
                  if (index === currentIndex) {
                    return (
                      <a
                        key={index}
                        href={item.company_url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          key={item._id}
                          src={`${baseUrl}advertisement/advertisement/image/${item._id}/users`}
                          alt={`Img ${index + 1}`}
                          className={`sliding-image ${
                            index === currentIndex ? "active" : ""
                          }`}
                          onClick={() => {
                            const clickvalue = item.number_of_clicks + 1;
                            updateClicksCount(clickvalue, item._id);
                          }}
                        />
                      </a>
                    );
                  }
                })}
              </div>

              {/* <div>
        <img src={bannermain} alt="" />
          </div> */}
            </Container>
          </div>
        )}
        <div
          id="aboutus"
          ref={aboutusRoute}
          className="aboutus-for-landingpage"
        >
          <div className="sidetitle-for-landingpage">
            <h2>{t("About_Us")}</h2>

            <p className="aboutus-for-landingpage-downpara1">
              {t("Tamil_Nadu_#1_Professional_Platform")}
            </p>

            <p className="aboutus-for-landingpage-downpara2">
              {t("Velaivendum_is_a_safe_platform")}
            </p>
          </div>
          {facebookpopup_1 ? (
            addfacebookpopupadd &&
            (addfacebookpopupadd === "" ||
              addfacebookpopupadd === undefined ||
              addfacebookpopupadd === null ||
              currentIndexfacebookpopup_down_add === facebookpopupaddlength ||
              addfacebookpopupadd.length <= 0) ? null : (
              <div className="about-facebook-adds">
                <div className="about-facebook-adds-img">
                  <div className="close">
                    <IconButton
                      onClick={() => {
                        setCurrentIndexfacebookpopup_down_add(
                          currentIndexfacebookpopup_down_add + 1
                        );
                        setfacebookpopup_1(false);
                      }}
                    >
                      {" "}
                      <CloseIcon
                        sx={{ color: "#0675a2", fontSize: 13 }}
                        fontSize="small"
                      />
                    </IconButton>
                  </div>
                  {addfacebookpopupadd.map((item, index) => {
                    if (index === currentIndexfacebookpopup_down_add) {
                      return (
                        <a
                          key={index}
                          href={item.company_url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            key={item._id}
                            src={`${baseUrl}advertisement/advertisement/image/${item._id}/users`}
                            alt={`Img ${index + 1}`}
                            className={`sliding-mage ${
                              index === currentIndexfacebookpopup_down_add
                                ? "active"
                                : " "
                            }`}
                            onClick={() => {
                              const clickvalue = item.number_of_clicks + 1;
                              updateClicksCount(clickvalue, item._id);
                            }}
                          />
                        </a>
                      );
                    }
                  })}
                </div>
              </div>
            )
          ) : (
            ""
          )}
          <Container fixed>
            <div className="aboutscontect-for-landingpage">
              <div className="aboutscontect-box-for-landingpage">
                <div className="aboutcontext-1-box">
                  <h3>{t("Get_Guidance")}</h3>

                  <img src={aboutsimg} alt="img" />
                  <div className="aboutcontext-contect-box">
                    <p>{t("aboutcontext-contect-box")}</p>
                  </div>
                </div>
              </div>
              <div className="aboutscontect-box-for-landingpage">
                <div className="aboutcontext-1-box">
                  <p>{t("Velaivendum_is_one_of_the_authentic_job")}</p>
                  <div className="listpart-in-abouts">
                    <div className="listtype-box">
                      <div>
                        <CheckCircleIcon sx={{ color: "#ed1067", mt: 1 }} />
                      </div>
                      <div>
                        <p>{t("It-is-gaining-ground-across")}</p>
                      </div>
                    </div>
                    <div className="listtype-box">
                      <div>
                        <CheckCircleIcon sx={{ color: "#ed1067", mt: 1 }} />
                      </div>
                      <div>
                        <p>{t("Adding-more-and-more")}</p>
                      </div>
                    </div>
                    <div className="listtype-box">
                      <div>
                        <CheckCircleIcon sx={{ color: "#ed1067", mt: 1 }} />
                      </div>
                      <div>
                        <p>{t("While-Velaivendum-is-the")}</p>
                      </div>
                    </div>
                  </div>

                  <div className="vission-content">
                    <h4>{t("VISION")}</h4>
                    <p>{t("Our-dedicated-teams")}</p>{" "}
                  </div>

                  <div className="vission-content">
                    <h4>{t("MISSION")}</h4>

                    <p>{t("Founded-in-2022")} </p>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
        <div id="card-advertisment" className="card-advertisment">
          <Marquee pauseOnHover style={{ padding: "10px 0px" }}>
            {addGetBottom &&
            (addGetBottom === "" ||
              addGetBottom === undefined ||
              addGetBottom === null ||
              addGetBottom.length <= 0)
              ? null
              : addGetBottom.map((item, index) => {
                  // if (index === currentIndexpopup) {
                  return (
                    <div className="card-advertisment-part">
                      <a
                        key={index}
                        href={item.company_url === "" ? null : item.company_url}
                        target="_blank"
                        style={{ width: "100%" }}
                        rel="noreferrer"
                      >
                        {item.addUpload.mimetype === "video/mp4" ? (
                          <video
                            poster={
                              item?.videoThumbnailImage
                                ? `${baseUrl}advertisement/advertisement/image/thumbnail/${item._id}/users`
                                : null
                            }
                            muted
                            controls
                            key={item._id}
                            style={{ width: "100%", height: "100%" }}
                            alt={`video ${index + 1}`}
                            // onClick={() => {
                            //   const clickvalue = item.number_of_clicks + 1;
                            //   updateClicksCount(clickvalue, item._id);
                            // }}
                          >
                            <source
                              src={`${baseUrl}advertisement/advertisement/image/${item._id}/users`}
                              type="video/mp4"
                            />
                          </video>
                        ) : (
                          <img
                            key={item._id}
                            src={`${baseUrl}advertisement/advertisement/image/${item._id}/users`}
                            alt={`Img ${index + 1}`}
                            onClick={() => {
                              const clickvalue = item.number_of_clicks + 1;
                              updateClicksCount(clickvalue, item._id);
                            }}
                          />
                        )}
                      </a>
                    </div>
                  );
                  //}
                })}

            {/* <div className="card-advertisment-part">
              <img src={cardadevetisement} />
            </div>
            <div className="card-advertisment-part">
              <img src={cardadevetisement} />
            </div>
            <div className="card-advertisment-part">
              <img src={cardadevetisement} />
            </div>
            <div className="card-advertisment-part">
              <img src={cardadevetisement} />
            </div>
            <div className="card-advertisment-part">
              <img src={cardadevetisement} />
            </div>
            <div className="card-advertisment-part">
              <img src={cardadevetisement} />
            </div> */}
          </Marquee>
        </div>
        {/* <div id="count-of-member" className="count">
          <div className="four-count">
            <div className="count-box">
              <div className="count-box-contect">
                <SearchIcon sx={{ fontSize: "40px", color: "#ed1067" }} />
                <h3>{countSeeker}+</h3>
              </div>
              <p> {t("JOB_SEEKER")} </p>
            </div>
            <div className="count-box">
              <div className="count-box-contect">
                <BusinessIcon sx={{ fontSize: "40px", color: "#ed1067" }} />
                <h3>{countProvider}+</h3>
              </div>
              <p>{t("EMPLOYER")}</p>
            </div>
            <div className="count-box">
            <div className="count-box-contect">
              <EngineeringIcon sx={{ fontSize: "40px", color: "#ed1067" }} />
              <h3>13+</h3>
            </div>
            <p>SELF-EMPLOYED</p>
          </div>
          <div className="count-box">
            <div className="count-box-contect">
              <SupervisedUserCircleIcon
                sx={{ fontSize: "40px", color: "#ed1067" }}
              />
              <h3>3+</h3>
            </div>
            <p>LABOUR</p>
          </div>
          </div>
        </div> */}
        <div id="mission" className="mission-for-landingpage">
          <Container>
            <div className="missionbox-of-landing">
              <div className="mission-box">
                <div className="sideof-mission1">
                  <h3> {t("Fulfilling")}</h3>

                  {/* <span>M</span>ISSION */}

                  <p>{t("We-assist-in-understanding")}</p>

                  <div>
                    <Button
                      variant="outlined"
                      onClick={() => navigate(ROUTINGDATA.LANDPAGECONTACTUS)}
                      sx={{
                        borderColor: "#ed1067",
                        padding: "5px 20px",
                        textTransform: "capitalize",
                        fontFamily: "Barlow-Medium",
                        color: "#ed1067",
                        fontWeight: "700",
                        fontSize: "16px",
                      }}
                    >
                      {t("Call-To-Action")} {t("us")}
                    </Button>
                  </div>
                </div>
                <div className="sideof-mission">
                  <img src={mission} alt="img" />
                </div>
              </div>
            </div>
          </Container>
        </div>

        <div className="advetisement-for-faceboob(1080px)">
          <Container>
            <div className="advetisement-social-mediapx">
              <div style={{ width: "350px" }}>
                {addGetfacebookadd &&
                (addGetfacebookadd === "" ||
                  addGetfacebookadd === undefined ||
                  addGetfacebookadd === null ||
                  currentIndexfacebookadd === facebookaddlength ||
                  addGetfacebookadd.length <= 0) ? null : (
                  // <Swiper
                  //   effect={"cube"}
                  //   grabCursor={true}
                  //   cubeEffect={{
                  //     shadow: true,
                  //     slideShadows: true,
                  //     shadowOffset: 20,
                  //     shadowScale: 0.94,
                  //   }}
                  //   autoplay={{
                  //     delay: 5500,
                  //     disableOnInteraction: false,
                  //   }}
                  //   pagination={true}
                  //   modules={[Autoplay, EffectCube, Pagination]}
                  //   className="mySwiper"
                  // >

                  <div className="dd-advetisement">
                    {addGetfacebookadd.map((item, index) => {
                      if (index === currentIndexfacebookadd) {
                        return (
                          //  <SwiperSlide >
                          <a
                            key={index}
                            href={item.company_url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              key={item._id}
                              src={`${baseUrl}advertisement/advertisement/image/${item._id}/users`}
                              alt={`Img ${index + 1}`}
                              className={`sliding-imae ${
                                index === currentIndexfacebookadd
                                  ? "active"
                                  : ""
                              }`}
                              onClick={() => {
                                const clickvalue = item.number_of_clicks + 1;
                                updateClicksCount(clickvalue, item._id);
                              }}
                            />
                          </a>
                          //  </SwiperSlide>
                        );
                      }
                    })}
                  </div>
                  //  </Swiper>
                )}
              </div>
              <div>
                <div className="img-ista" style={{ width: "300px" }}>
                  {addGetinstagramadd &&
                  (addGetinstagramadd === "" ||
                    addGetinstagramadd === undefined ||
                    addGetinstagramadd === null ||
                    currentIndexinstagramadd === instagramaddlength ||
                    addGetinstagramadd.length <= 0) ? null : (
                    <div>
                      {addGetinstagramadd.map((item, index) => {
                        if (index === currentIndexinstagramadd) {
                          return (
                            <a
                              key={index}
                              href={item.company_url}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                key={item._id}
                                src={`${baseUrl}advertisement/advertisement/image/${item._id}/users`}
                                alt={`Img ${index + 1}`}
                                className={`sliding-imae ${
                                  index === currentIndexinstagramadd
                                    ? "active"
                                    : ""
                                }`}
                                onClick={() => {
                                  const clickvalue = item.number_of_clicks + 1;
                                  updateClicksCount(clickvalue, item._id);
                                }}
                              />
                            </a>
                          );
                        }
                      })}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Container>
        </div>

        <div
          id="features"
          ref={featuresRoute}
          className="features-for-landingpage"
        >
          {facebookpopup_2 ? (
            addfacebookpopupadd &&
            (addfacebookpopupadd === "" ||
              addfacebookpopupadd === undefined ||
              addfacebookpopupadd === null ||
              currentIndexfacebookpopup_down_add === facebookpopupaddlength ||
              addfacebookpopupadd.length <= 0) ? null : (
              <div className="about-facebook-adds">
                <div className="about-facebook-adds-img">
                  <div className="close">
                    <IconButton
                      onClick={() => {
                        setCurrentIndexfacebookpopup_down_add(
                          currentIndexfacebookpopup_down_add + 1
                        );
                        setfacebookpopup_2(false);
                      }}
                    >
                      {" "}
                      <CloseIcon
                        sx={{ color: "#0675a2", fontSize: 13 }}
                        fontSize="small"
                      />
                    </IconButton>
                  </div>
                  {addfacebookpopupadd.map((item, index) => {
                    if (index === currentIndexfacebookpopup_down_add) {
                      return (
                        <a
                          key={index}
                          href={item.company_url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            key={item._id}
                            src={`${baseUrl}advertisement/advertisement/image/${item._id}/users`}
                            alt={`Img ${index + 1}`}
                            className={`sliding-mage ${
                              index === currentIndexfacebookpopup_down_add
                                ? "active"
                                : " "
                            }`}
                            onClick={() => {
                              const clickvalue = item.number_of_clicks + 1;
                              updateClicksCount(clickvalue, item._id);
                            }}
                          />
                        </a>
                      );
                    }
                  })}
                </div>
              </div>
            )
          ) : (
            ""
          )}
          <div className="background-for-features">
            <div className="sidetitle-for-landingpage">
              <h2 style={{ color: "white" }}>{t("Features")}</h2>
            </div>
            <Container fixed>
              <div className="features-of-list">
                <div className="features-list">
                  <div>
                    <WorkIcon className="features-list-icon" />
                    <h4>{t("TYPES-OF-JOBS")}</h4>
                    <p>{t("Candidates-can-find")}</p>
                  </div>
                  <a
                    className="youtube"
                    href="https://www.youtube.com/channel/UCDWhilQYlVkMVhy_fEMjufw"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div style={{ color: "white" }}>
                      <YouTubeIcon className="features-list-icon youtubeicon" />
                      <h4>{t("YOUTUBE-CHANNEL")}</h4>
                      <p>{t("Velaivendum-YouTube")}</p>
                    </div>
                  </a>
                  <div>
                    <FilterTiltShiftIcon className="features-list-icon" />
                    <h4>{t("AREA-SPECIFIC")}</h4>

                    <p>{t("The-workforce-requirement")}</p>
                  </div>
                  <div>
                    <AltRouteIcon className="features-list-icon" />
                    <h4>{t("CAREER-GUIDANCE")}</h4>
                    <p>{t("In-addition-to-job-search")}</p>
                  </div>
                  <div>
                    <SensorOccupiedIcon className="features-list-icon" />
                    <h4>{t("LOCALISED-LABOUR-FORCE")}</h4>
                    <p>{t("Impetus-would-be-given")}</p>
                  </div>
                  <div>
                    <AccountBoxIcon className="features-list-icon" />
                    <h4>{t("LOW-PROFILE-JOBS")}</h4>
                    <p>{t("It-is-a-platform")}</p>
                  </div>
                  {readmore ? (
                    <>
                      <div>
                        <BroadcastOnPersonalIcon className="features-list-icon" />
                        <h4>{t("CLOSE-CIRCUIT")}</h4>
                        <p>{t("Different-location")}</p>
                      </div>
                      <div>
                        <VideoCameraFrontIcon className="features-list-icon" />
                        <h4>{t("PROFILE-VIDEOS")}</h4>
                        <p>{t("Both-employers")}</p>
                      </div>
                      <div>
                        <ContactsIcon className="features-list-icon" />
                        <h4>{t("DIRECT-CONTACT")}</h4>
                        <p>{t("When-one-finds")}</p>
                      </div>
                      <div>
                        <CommentBankIcon className="features-list-icon" />
                        <h4>{t("MENTORSHIP-SUPPORT")}</h4>
                        <p>{t("The-mentorship-facility")}</p>
                      </div>

                      <div>
                        <StorageIcon className="features-list-icon" />
                        <h4>{t("RICH-DATABASE")}</h4>
                        <p>{t("This-platform-would")}</p>
                      </div>
                      <div>
                        <EmojiTransportationIcon className="features-list-icon" />
                        <h4>{t("NATION-BUILDING")}</h4>
                        <p>{t("Velaivendum-strongly")}</p>
                      </div>
                      <div>
                        <SupportIcon className="features-list-icon" />
                        <h4>{t("END-TO-END-SUPPORT")}</h4>
                        <p>{t("Right-from-identification")}</p>
                      </div>
                      <div>
                        <ModelTrainingIcon className="features-list-icon" />
                        <h4>{t("TRAINING-CELL")}</h4>
                        <p>{t("Skilled-professionals")}</p>
                      </div>
                      <div>
                        <NaturePeopleIcon className="features-list-icon" />
                        <h4>{t("DEMOCRATIC-IN-NATURE")}</h4>
                        <p>{t("Employers-candidates")}</p>
                      </div>
                    </>
                  ) : null}
                </div>
                <div className="features-list-button">
                  <Button
                    variant="contained"
                    onClick={() => {
                      setreadmore(!readmore);
                    }}
                  >
                    {readmore ? `${t("Readless")}` : `${t("Readmore")}`}
                    {readmore ? (
                      <ArrowUpwardIcon
                        sx={{ fontSize: "18px", marginLeft: "5px" }}
                      />
                    ) : (
                      <ArrowDownwardIcon
                        sx={{ fontSize: "18px", marginLeft: "5px" }}
                      />
                    )}
                  </Button>
                </div>
              </div>
            </Container>
          </div>
        </div>
        <div id="Lastcontect" className="Lastcontect-for-landingpage">
          <div className="Lastcontect-frame">
            <Container fixed>
              <div className="lastcontect-split">
                <div className="img-contect">
                  <div className="img-overflow">
                    <img src={last3} alt="img" />
                  </div>

                  <div
                    className={
                      lang === "tamil"
                        ? "lastcontect-landing-english"
                        : "lastcontect-landing-tamil"
                    }
                  >
                    {t("Velaivendum-is-a-safe")}
                  </div>
                </div>
                <div className="img-contect">
                  <div className="img-overflow">
                    <img src={last2} alt="img" />
                  </div>

                  <div
                    className={
                      lang === "tamil"
                        ? "lastcontect-landing-english"
                        : "lastcontect-landing-tamil"
                    }
                  >
                    {t("they-would-be-starting")}
                  </div>
                </div>
                <div className="img-contect">
                  <div className="img-overflow">
                    <img src={lastimg} alt="img" />
                  </div>

                  <div
                    className={
                      lang === "tamil"
                        ? "lastcontect-landing-english"
                        : "lastcontect-landing-tamil"
                    }
                  >
                    {t("Corporates-can-expand")}
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </div>
        <div id="footer" className="footer-landing">
          {bottombanner ? (
            addGetBottomFooter &&
            (addGetBottomFooter === "" ||
              addGetBottomFooter === undefined ||
              addGetBottomFooter === null ||
              currentIndexbottomfooter === bottomfooterAdlength ||
              addGetBottomFooter.length <= 0) ? null : (
              <div className="bottom-advertisement">
                <div
                  style={{
                    textAlign: "right",
                    position: "absolute",
                    right: "0%",
                    zIndex: "9999",
                  }}
                >
                  <IconButton
                    sx={{ backgroundColor: "white" }}
                    onClick={() => {
                      setbottombanner(false);
                    }}
                  >
                    <CloseIcon sx={{ color: "#0675a2", fontSize: 18 }} />
                  </IconButton>
                </div>
                {addGetBottomFooter.map((item, index) => {
                  if (index === currentIndexbottomfooter) {
                    return (
                      <a
                        key={index}
                        href={item.company_url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          key={item._id}
                          src={`${baseUrl}advertisement/advertisement/image/${item._id}/users`}
                          alt={`Img ${index + 1}`}
                          className={`sliding-image ${
                            index === currentIndexbottomfooter ? "active" : ""
                          }`}
                          onClick={() => {
                            const clickvalue = item.number_of_clicks + 1;
                            updateClicksCount(clickvalue, item._id);
                          }}
                        />
                      </a>
                    );
                  }
                })}
              </div>
            )
          ) : null}
          {/* <div className="footer-contect">
            <h3>VELAIVENDUM.com</h3>
            <p>{t("Making-recruiting")}</p>
          </div>
          <div className="footer-landing-icon">
            <div className="footer-icon">
              <FacebookIcon
                className="footer-icon-hover-1"
                sx={{
                  padding: "8px",
                  backgroundColor: "white",
                  color: "#1877f2",
                  fontSize: "25px",
                  borderRadius: "50%",
                  transition: "0.5s",
                  cursor: "pointer",
                }}
              />
              <a
                href="https://www.youtube.com/channel/UCDWhilQYlVkMVhy_fEMjufw"
                target="_blank"
              >
                <YouTubeIcon
                  className="footer-icon-hover-2"
                  sx={{
                    padding: "8px",
                    backgroundColor: "white",
                    color: "#ff0000",
                    fontSize: "25px",
                    borderRadius: "50%",
                    transition: "0.5s",
                    cursor: "pointer",
                  }}
                />
              </a>
              <TwitterIcon
                className="footer-icon-hover-3"
                sx={{
                  padding: "8px",
                  backgroundColor: "white",
                  color: "#1da1f2",
                  fontSize: "25px",
                  borderRadius: "50%",
                  transition: "0.5s",
                  cursor: "pointer",
                }}
              />
              <InstagramIcon
                className="footer-icon-hover-4"
                sx={{
                  padding: "8px",
                  backgroundColor: "white",
                  color: "#ed1067",
                  fontSize: "25px",
                  borderRadius: "50%",
                  transition: "0.5s",
                  cursor: "pointer",
                }}
              />
              <LinkedInIcon
                className="footer-icon-hover-5"
                sx={{
                  padding: "8px",
                  backgroundColor: "white",
                  color: "#0077b5",
                  fontSize: "25px",
                  borderRadius: "50%",
                  transition: "0.5s",
                  cursor: "pointer",
                }}
              />
            </div>
          </div> */}
          <div className="footer-contectbox">
            <div className="footersidecontect">
              <div className="footerlogo footerbox">
                <div className="headerlogoimg-footer">
                  <img
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigate(ROUTINGDATA.LAND);
                    }}
                    src={logo}
                    alt="img"
                  />
                </div>
                <div>
                  <p> {t("Sub_Title")}</p>
                </div>
                <div className="footermediaicons">
                  <div className="connectwithus">{t("Connect_with_us")}</div>
                  <div className="footer-landing-icon">
                    <div className="footer-icon">
                      <a
                        href="https://www.linkedin.com/company/velaivendum-com/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <LinkedInIcon
                          className="footer-icon-hover-5"
                          sx={{
                            padding: "8px",
                            backgroundColor: "white",
                            color: "#0077b5",
                            fontSize: "25px",
                            borderRadius: "50%",
                            transition: "0.5s",
                            cursor: "pointer",
                          }}
                        />
                      </a>
                      <a
                        href="https://www.youtube.com/channel/UCDWhilQYlVkMVhy_fEMjufw"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <YouTubeIcon
                          className="footer-icon-hover-2"
                          sx={{
                            padding: "8px",
                            backgroundColor: "white",
                            color: "#ff0000",
                            fontSize: "25px",
                            borderRadius: "50%",
                            transition: "0.5s",
                            cursor: "pointer",
                          }}
                        />
                      </a>
                      <a
                        href="https://www.facebook.com/profile.php?id=61551213240226"
                        target="_black"
                      >
                        <FacebookIcon
                          className="footer-icon-hover-1"
                          sx={{
                            padding: "8px",
                            backgroundColor: "white",
                            color: "#1877f2",
                            fontSize: "25px",
                            borderRadius: "50%",
                            transition: "0.5s",
                            cursor: "pointer",
                          }}
                        />
                      </a>
                      <a
                        href="https://twitter.com/velaivendum"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <TwitterX
                          className="footer-icon-hover-3"
                          style={{
                            padding: "8px",
                            backgroundColor: "white",
                            color: "white",
                            fontSize: "25px",
                            width: "26px",
                            height: "26px",
                            borderRadius: "50%",
                            transition: "0.5s",
                            cursor: "pointer",
                          }}
                        />
                      </a>
                      <a
                        href="https://www.instagram.com/velaivendum/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <InstagramIcon
                          className="footer-icon-hover-4"
                          sx={{
                            padding: "8px",
                            backgroundColor: "white",
                            color: "#ed1067",
                            fontSize: "25px",
                            borderRadius: "50%",
                            transition: "0.5s",
                            cursor: "pointer",
                          }}
                        />
                      </a>
                    </div>
                  </div>
                  <div>
                    <div>
                      <h3>Legal</h3>
                    </div>
                    <text
                      onClick={() => {
                        navigate(
                          `${ROUTINGDATA.LAND}${ROUTINGDATA.TERMSANDCONDITION}`
                        );
                        window.scrollTo(0, 0);
                      }}
                      className="legalFooter"
                    >
                      Terms & Conditions
                    </text>{" "}
                    |
                    <text
                      onClick={() => {
                        navigate(
                          `${ROUTINGDATA.LAND}${ROUTINGDATA.PrivacyPolicy}`
                        );
                        window.scrollTo(0, 0);
                      }}
                      style={{ marginLeft: "5px" }}
                      className="legalFooter"
                    >
                      Privacy Policy
                    </text>{" "}
                    |
                    <text
                      onClick={() => {
                        navigate(
                          `${ROUTINGDATA.LAND}${ROUTINGDATA.PurchasePolicy}`
                        );
                        window.scrollTo(0, 0);
                      }}
                      style={{ marginLeft: "5px" }}
                      className="legalFooter"
                    >
                      Purchase Policy
                    </text>{" "}
                    |
                    <text
                      onClick={() => {
                        navigate(
                          `${ROUTINGDATA.LAND}${ROUTINGDATA.RefundPolicy}`
                        );
                        window.scrollTo(0, 0);
                      }}
                      style={{ marginLeft: "5px" }}
                      className="legalFooter"
                    >
                      Refund Policy
                    </text>{" "}
                    |
                    <text
                      onClick={() => {
                        navigate(
                          `${ROUTINGDATA.LAND}${ROUTINGDATA.DeliveryPolicy}`
                        );
                        window.scrollTo(0, 0);
                      }}
                      style={{ marginLeft: "5px" }}
                      className="legalFooter"
                    >
                      Delivery Policy
                    </text>{" "}
                    |
                    <text
                      onClick={() => {
                        navigate(
                          `${ROUTINGDATA.LAND}${ROUTINGDATA.PricingPolicy}`
                        );
                        window.scrollTo(0, 0);
                      }}
                      style={{ marginLeft: "5px" }}
                      className="legalFooter"
                    >
                      Pricing Policy
                    </text>
                  </div>
                </div>
              </div>
              <div className="footercenterlinks">
                <div>
                  <h5>{t("quick_links")}</h5>
                </div>
                <div className="footerlinks">
                  <text
                    onClick={() => {
                      navigate(`${ROUTINGDATA.MAIN}${ROUTINGDATA.JOBSEEK}`, {});
                    }}
                  >
                    <ArrowRightIcon />
                    {t("JOB_SEEKER")}
                  </text>
                  <text
                    onClick={() =>
                      navigate(`${ROUTINGDATA.MAIN}${ROUTINGDATA.JOBPROVIDER}`)
                    }
                  >
                    <ArrowRightIcon />
                    {t("EMPLOYER")}
                  </text>
                  <text
                    onClick={() =>
                      navigate(`${ROUTINGDATA.MAIN}${ROUTINGDATA.VOLUNTEER}`)
                    }
                  >
                    <ArrowRightIcon />
                    {t("VOLUNTEER")}
                  </text>
                  {/* <text
                    onClick={() =>
                      navigate(`${ROUTINGDATA.MAIN}${ROUTINGDATA.MENTOR}`)
                    }
                  >
                    <ArrowRightIcon />
                    {t("MENTOR")}
                  </text> */}
                  <text
                    className="linkcolor"
                    onClick={() => handleLinkClick(featuresRoute)}
                    style={{ color: "white" }}
                  >
                    <ArrowRightIcon />
                    {t("Features")}
                  </text>

                  {/* <text onClick={() => navigate(ROUTINGDATA.LOGIN)} >
                    <ArrowRightIcon />
                    Login
                  </text> */}

                  <text
                    className="linkcolor"
                    onClick={() => handleLinkClick(aboutusRoute)}
                    style={{ color: "white" }}
                  >
                    <ArrowRightIcon />
                    {t("About_Us")}
                  </text>

                  <text
                    onClick={() =>
                      navigate(ROUTINGDATA.LAND + ROUTINGDATA.LANDPAGECONTACTUS)
                    }
                  >
                    <ArrowRightIcon />
                    {t("Call-To-Action-1")}
                  </text>
                  {/* <text
                   onClick={() => {
                    navigate(ROUTINGDATA.LAND + ROUTINGDATA.BECOMEAPARTNER);
                  }}
                  >
                    <ArrowRightIcon />
                    BECOME A PARTNER
                  </text>
                  <text
                   onClick={() => {
                    navigate(ROUTINGDATA.LAND + ROUTINGDATA.BECOMEAINVESTOR);
                  }}
                  >
                    <ArrowRightIcon />
                    BECOME A INVESTOR
                  </text> */}
                </div>
              </div>
              <div className="footercenterlinks">
                <div>
                  <h5>{t("Call-To-Action-1")}</h5>
                </div>
                <div className="footerlinks-1">
                  <text>{t("address")}</text>
                  <text>
                    {" "}
                    {t("phone")}:<br></br>+91 9080400330
                  </text>
                  <text> {t("Email")}: contactus@usglobalsolutions.com</text>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{ display: "flex", justifyContent: "center", gap: "20px" }}
          >
            <Button
              variant="outlined"
              className="btn-reg"
              sx={{
                backgroundColor: "#ec2172",
                color: "white",
                border: "1px solid #0675a2",

                borderRadius: "50px",
              }}
              onClick={() => {
                navigate(ROUTINGDATA.LAND + ROUTINGDATA.BECOMEAPARTNER, {
                  state: { registertype: "home", registerby: "home" },
                });
              }}
            >
              <HandshakeRoundedIcon
                className="iconforcontect"
                sx={{
                  fontSize: "20px",
                  padding: "5px",
                  borderRadius: "50%",
                  color: "#ed1067",
                  transition: "0.5s",
                  marginRight: "10px",
                  backgroundColor: "white",
                }}
              />
              <text
                style={{
                  fontFamily: "Bralow-Bold",
                  fontWeight: 700,
                  paddingTop: "4px",
                }}
              >
                BECOME A TRAINING PARTNER
              </text>
            </Button>
            <Button
              variant="outlined"
              className="btn-reg"
              sx={{
                backgroundColor: "#0675a2",
                color: "white",
                border: "1px solid #0675a2",

                borderRadius: "50px",
              }}
              onClick={() => {
                navigate(ROUTINGDATA.LAND + ROUTINGDATA.BECOMEAINVESTOR);
              }}
            >
              <CurrencyExchangeRoundedIcon
                className="iconforcontect"
                sx={{
                  fontSize: "20px",
                  padding: "5px",
                  borderRadius: "50%",
                  color: "#ed1067",
                  transition: "0.5s",
                  marginRight: "10px",
                  backgroundColor: "white",
                }}
              />
              <text
                style={{
                  fontFamily: "Bralow-Bold",
                  fontWeight: 700,
                  paddingTop: "4px",
                }}
              >
                BECOME A INVESTOR
              </text>
            </Button>
          </div>
          <div className="footer-copyright">
            <p>
              &copy;{new Date().getFullYear()}
              <span
                style={{
                  textDecoration: "underline",
                  fontWeight: "400",
                  color: "#0976d3",
                }}
              >
                <a
                  style={{
                    textDecoration: "underline",
                    fontWeight: "400",
                    color: "#0976d3",
                    marginLeft: "5px",
                  }}
                  href="https://usglobalsolutions.com/"
                  target="blank"
                >
                  US Global Solutions
                </a>
              </span>
              <span
                style={{
                  fontWeight: "normal",
                  marginLeft: "5px",
                  marginRight: "3px",
                }}
              >
                |
              </span>{" "}
              All Rights Reserved
            </p>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div
        style={{
          height: "100vh",
          display: "grid",
          placeItems: "center",
          // transform: "translate(0,50vh)",
        }}
      >
        <div class="dots"></div>
      </div>
    );
  }
}

export default Landingpagecontext;
