import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  TextField,
  CircularProgress,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import EditIcon from "@mui/icons-material/Edit";
import Context from "../Context";
import * as Yup from "yup";
import useAxios from "../../../../api/useAxios";
import { useLocation } from "react-router";
import { useFormik } from "formik";
import Tooltip from "@mui/material/Tooltip";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ProfileSummery({ setput }) {
  const [open, setOpen] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleClickOpen = (Context) => {
    formik.setFieldValue("ProfileSummary", Context.profile.ProfileSummary);
    setOpen(true);
  };

  const handleClose = () => {
    formik.handleReset();
    setOpen(false);
  };

  const axiosData = useAxios();

  const Location = useLocation();

  const formik = useFormik({
    initialValues: {
      ProfileSummary: "",
    },
    validationSchema: Yup.object({
      ProfileSummary: Yup.string()
        .max(250, "Must be 250 characters or less")
        .required("Profile Summary  must be Required"),
    }),
    onSubmit: async (values) => {
      setIsSubmitting(true);
      await axiosData.put(`/seeker/email/${Location.state}`, values, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setput(true);
      setIsSubmitting(false);
      handleClose();
      formik.handleReset("");
    },
  });

  return (
    <Context.Consumer>
      {(Context) => {
        return (
          <div>
            <Dialog
              open={open}
              TransitionComponent={Transition}
              keepMounted
              aria-describedby="alert-dialog-slide-description"
            >
              <form onSubmit={formik.handleSubmit}>
                <DialogTitle>
                  <Typography variant="h5">
                    <b> Profile Summary</b>
                  </Typography>
                  <Typography>
                    Your Profile Summary should mention the highlights of your
                    career and education, what your professional interests are,
                    and what kind of a career you are looking for.
                  </Typography>
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-slide-description">
                    <Grid item xs={12} sm={12} md={8} square>
                      <Box>
                        <Box sx={{ mt: 1 }}>
                          <TextField
                            margin="normal"
                            fullWidth
                            multiline
                            rows={3}
                            placeholder="Add Profile Summary"
                            id="Profile Summary"
                            label="Profile Summary"
                            name="ProfileSummary"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.ProfileSummary}
                          />{" "}
                          {formik.touched.ProfileSummary &&
                            formik.errors.ProfileSummary && (
                              <Typography
                                sx={{ color: "red", fontSize: "13px" }}
                              >
                                {formik.errors.ProfileSummary}
                              </Typography>
                            )}
                        </Box>
                      </Box>
                    </Grid>
                  </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ mr: 2 }}>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={
                      formik.isValid && formik.dirty && isSubmitting === false
                        ? false
                        : true
                    }
                  >
                    Update
                    {isSubmitting && (
                      <CircularProgress
                        size={24}
                        style={{
                          color: "#ffffff",

                          marginLeft: 12,
                        }}
                      />
                    )}
                  </Button>
                </DialogActions>
              </form>
            </Dialog>
            <Grid xs={12} md={12} lg={12}>
              <Box
                id="ProfileSummary"
                sx={{
                  fontSize: "18px",
                  textTransform: "uppercase",
                  mb: 1,
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <b style={{color:"rgb(16 143 178)"}}>PROFILE SUMMARY</b>
                <Button
                  sx={{ ml: -1.5, mt: -1 }}
                  onClick={() => handleClickOpen(Context)}
                >
                  <Tooltip title="Edit" arrow>
                    <EditIcon />
                    </Tooltip>
                </Button>
              </Box>
              <hr/>
              <Box className="scrollable-container">
                {Context.profile.ProfileSummary ? (
                  <Typography sx={{ fontWeight: 600 }} color="text.secondary">
                    {Context.profile.ProfileSummary}
                  </Typography>
                ) : (
                  <Typography sx={{ fontWeight: 600 }} color="text.secondary">
                    Mention Your Profile Summary.
                  </Typography>
                )}
              </Box>
            </Grid>
          </div>
        );
      }}
    </Context.Consumer>
  );
}

export default ProfileSummery;