import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { ROUTINGDATA } from "../ROUTINGDATA";
import useAxios from "../../api/useAxios";
import Container from "@mui/material/Container";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { CurrentStateContext } from "../../App";
import "./TermsAndCondition.css";
import { ReactComponent as TwitterX } from "../../img/twitter-x-seeklogo.com-4.svg";
import PrivacyFooter from "./PrivacyFooter";

const DeliveryPolicy = () => {
  const axiosData = useAxios();
  const { languagetranslator } = useContext(CurrentStateContext);
  const { t, i18n, ready } = useTranslation();

  const navigate = useNavigate();

  const changeLanguage = (languagetranslator) => {
    i18n.changeLanguage(languagetranslator);
  };
  useEffect(() => {
    changeLanguage(languagetranslator);
  }, [languagetranslator]);

  const lang = sessionStorage.getItem("lang");

  if (ready) {
    return (
      <div>
        <div className="herotextimg-2">
          <div className="maintextforhero-2">
            <div className="TermsAndCondition-center">
              <h2>Shipping & Delivery Policy</h2>
            </div>
          </div>
        </div>
        <div className="TermsAndConditionBackpage">
          <div className="TermsAndConditionBackpagefonts">
            <span
              className="Termsbackbtn"
              onClick={() => navigate(ROUTINGDATA.LAND)}
              style={{ color: "#135c82" }}
            >
              {t("Home")}
            </span>
            / <span style={{ color: "black" }}>Shipping & Delivery Policy</span>
          </div>
        </div>
        <Container>
          <div>
            <h2>Shipping & Delivery Policy</h2>
            <p>Shipping is not applicable for business.</p>
          </div>
        </Container>

        <PrivacyFooter />
      </div>
    );
  } else {
    return (
      <div
        style={{
          height: "100vh",
          display: "grid",
          placeItems: "center",
          // transform: "translate(0,50vh)",
        }}
      >
        <div class="dots"></div>
      </div>
    );
  }
};

export default DeliveryPolicy;
