import React, { useEffect } from "react";
import { useState, useRef } from "react";

import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";

import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { ThemeProvider } from "@mui/material/styles";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";
import Select from "@mui/material/Select";
import { Formik } from "formik";
import * as Yup from "yup";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import useAxios from "../../../../api/useAxios";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";

import {
  MailOutline as MailOutlineIcon,
  PersonPinCircleRounded as PersonPinCircleRoundedIcon,
  HomeRounded as HomeRoundedIcon,
  DescriptionRounded as DescriptionRoundedIcon,
  HomeWorkRounded as HomeWorkRoundedIcon,
  ShareLocationRounded as ShareLocationRoundedIcon,
  ContactPageRounded as ContactPageRoundedIcon,
  VolunteerActivismRounded as VolunteerActivismRoundedIcon,
  ShoppingCartRounded as ShoppingCartRoundedIcon,
  FmdGoodRounded as FmdGoodRoundedIcon,
} from "@mui/icons-material";
import logo from "../../../../img/logo_for-landingpage-3.png";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
// import { industry } from "./cdata";
import { useNavigate, Outlet } from "react-router";
import { ROUTINGDATA } from "../../../ROUTINGDATA";
// import Toplogo from "../../../img/logo_for-landingpage-3.png";
import { FormHelperText, CircularProgress } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";

import Popper from "@mui/material/Popper";
import moment from "moment";

import WysiwygIcon from "@mui/icons-material/Wysiwyg";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

let response = "";

const exceptThisSymbols = ["e", "E", "+", "-", ".", "ArrowUp", "ArrowDown"];
const place_of_service = ["Home", "Shop", "Other"];

function LocalServicesForm() {
  const ref = React.createRef(null);
  const navigate = useNavigate();
  const theme = useTheme();
  const location = useLocation();
  //  translation
  const { t, i18n, ready } = useTranslation();
  //
  const axiosData = useAxios();
  const [WorkType, SetWorktype] = useState("Fresher");
  const [district_name, setDistrict_name] = useState("");
  const [pincodeByDistrict, setPincodeByDistrict] = useState([]);
  const [districtData, setDistrictData] = useState([]);
  const [data, setData] = useState(false);
  const [file, setFile] = useState("");
  const [resume, setResume] = useState("");
  const [open, setOpen] = useState(false);
  let [mobileError, setmobileError] = useState("");
  let [emailError, setEmailError] = useState(true);
  const [valid, setvalid] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [focusout, setfocus] = useState();
  const [formEmail, setFormEmail] = useState("");
  const [formEmailError, setFormEmailError] = useState("");
  const [focusb, setfocusb] = useState();
  const [emailPopUp, setEmailPopUp] = useState(false);
  const [mobilePopUp, setMobilePopUp] = useState(false);
  const [mentorExperience, setMentorExperience] = useState("");
  //const [pinLoad, setPinLoad] = useState(false);
  const [isFocused, setIsFocused] = useState({
    pincode: false,
    village_town_name: false,
    area_street_name: false,
    place_of_service: false,
    type_of_service: false,
    contact_number: false,
    contact_person_name: false,
    expiry_date_from: false,
    expiry_date_to: false,
    need_type: false,
    shop_name: false,
    shop_address: false,
    job_description: false,
  });

  const handleFocus = (field) => () => {
    setIsFocused((prevState) => ({
      ...prevState,
      [field]: true,
    }));
  };
  const handleBlur = (field) => () => {
    setIsFocused((prevState) => ({
      ...prevState,
      [field]: false,
    }));
  };
  const [captureBlur, setCaptureBlur] = useState({
    contact_number: false,
    landline_number: false,
    email_id: false,
  });
  const handleClick1 = () => {
    setOpen(true);
  };
  const handleClose1 = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const [experience, setExperience] = useState([
    "0.5 Year",
    "1 Year",
    "1.5 Years",
    "2 Years",
    "2.5 Years",
    "3 Years",
    "3.5 Years",
    "4 Years",
    "4.5 Years",
    "5 Years",
    "5.5 Years",
    "6 Years",
    "6.5 Years",
    "7 Years",
    "7.5 Years",
    "8 Years",
    "8.5 Years",
    "9 Years",
    "9.5 Years",
    "10 Years",
    "10.5 Years",
    "11 Years",
    "11.5 Years",
    "12 Years",
    "12.5 Years",
    "13 Years",
    "13.5 Years",
    "14 Years",
    "14.5 Years",
    "More than 15 Years",
  ]);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const filterOptions = (options, { inputValue }) => {
    const inputValueLower = inputValue.toLowerCase();
    const startsWithInput = [];
    const containsInput = [];

    options.forEach((option) => {
      const optionLower = option.toLowerCase();
      if (optionLower.startsWith(inputValueLower)) {
        startsWithInput.push(option);
      } else if (optionLower.includes(inputValueLower)) {
        containsInput.push(option);
      }
    });

    return [...startsWithInput, ...containsInput];
  };

  const MAX_FILE_SIZE = 1048576 * 10;
  const errors = {};
  const error = () => {
    errors.email_id = `email is exist `;
  };
  const [alertm, setalert] = useState(false);
  const [alerte, setalerte] = useState(false);
  const [suggestionArrayl, setSuggestionArrayl] = useState([]);
  const [pincode, setPincode] = useState([]);
  const [village, setVillage] = useState([]);
  const [pinLoad, setpinLoad] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState({
    pincode: "",
  });
  const [searchQuery, setSearchQuery] = useState("");

  //   useEffect(() => {
  //     if (alertm !== false) {
  //       setMobilePopUp(true);
  //       formik.setFieldValue("mobile_number", "");
  //     }
  //     setalert(false);
  //   }, [alertm]);
  //   useEffect(() => {
  //     if (alerte !== false) {
  //       setEmailPopUp(true);
  //       setFormEmail("");
  //       formik.setFieldValue("email_id", "");
  //     }
  //     setalerte(false);
  //   }, [alerte]);
  const searchobj = (values, string) => {
    selectedOptions[string] = values;
    setSelectedOptions(selectedOptions);

    setSuggestionArrayl([]);
    setSuggestionArrayl([]);
  };
  const validate = async (values) => {
    const errors = {};
    if (!values.mobile_number) {
      errors.mobile_number = t("Mobile_Number_must_be_Required");
      setfocusb(true);
      setvalid(true);
    } else if (/^\d{9}$/.test(values.mobile_number)) {
      setfocusb();
      setvalid(true);
    } else if (/^\d{10}$/.test(values.mobile_number)) {
      if (valid) {
        const controller = new AbortController();
        try {
          const response = await axiosData.get(
            `users/Mobile/${values.mobile_number}`
          );

          if (response.status === 200) {
            setalert(true);
          } else if (response.status === 204) {
            setalert(false);
          }

          setvalid(false);

          return () => controller.abort();
        } catch (error) {}
      }
    }

    return errors;
  };
  const fileInputField = useRef(null);
  const reader = new FileReader();
  const [selectedIndustry, setSelectedIndustry] = useState();
  const handleFileChange = (e) => {
    if (e.target.files[0].size > MAX_FILE_SIZE) {
      setFile("File is too large");
      setResume(false);
    } else {
      setResume(e.target.files[0]);
      setFile(e.target.files[0].name);
    }
    setData(e.target.files[0].size);
  };

  const [mpConstituency, setMP] = useState({
    code: 4,
    District: "",
    ParlimentConstituency: [],
  });
  var [mlaConstituency, setMLA] = useState([]);
  const [active, setActive] = useState("8");
  const handleClick = (event) => {
    setActive(event.target.id);
  };

  const [serviceName, setServiceName] = useState([]);
  const [jobList, setJobList] = useState([]);
  const getJobList = async () => {
    try {
      const getOneAxios = await axiosData.get(`/localjobslists`);
      const data = getOneAxios.data;
      if (data) {
        const GetVillageName = data.map((i) => {
          return i.service_name;
        });
        setServiceName(GetVillageName);
        setJobList(data);

        // GetVillageName.sort();
      }
      return data;
    } catch (err) {
      console.log("Error", err);
    }
  };
  const lang = sessionStorage.getItem("lang");
  const pincodeList = async (pincodes) => {
    try {
      const getOneAxios = await axiosData.get(
        `/pincodearea/getArea/${pincodes}`
      );
      const data = getOneAxios.data;
      if (data) {
        const getPincode = data.map((i) => {
          return i.village_name;
        });
        getPincode.sort();
        setPincode(getPincode);
      }
      return data;
    } catch (err) {
      console.log("Error", err);
    }
  };
  const handleInputChange = (event, newValue) => {
    if (newValue.length < 7) {
      setSearchQuery(newValue);
    }
  };
  const handleSuggestionsPincode = async (event) => {
    console.log("query", event);
    // searchobj(event.target.value, "pincode");
    // setSearchQuery(query);
    // if (query.length === 6) {
    //   setpinLoad(true);
    //   // pincodeList(query);
    //   //         const response = await axiosData.get(
    //   //           `/pincodearea/suggestion/${query}`
    //   //         );
    //   //
    //   // console.log("pincodelist",response.data)
    //   //         const pincode = response.data;
    //   //         const pincodeArray = pincode.map((item) => {
    //   //           return String(item);
    //   //         });
    //   //
    //   //         if (response.data) {
    //   //           response.data = response.data.filter((item) => {
    //   //             return !!item;
    //   //           });
    //   //           setSuggestionArrayl(pincodeArray);
    //   //           // const getPincode = pincode.PostOffice.map((i) => {
    //   //           //   return i.Name;
    //   //           // });
    //   //           // getPincode.sort();
    //   //           // setPincode(getPincode);
    //   //         } else {
    //   //           setSuggestionArrayl([]);
    //   //         }
    // }
    try {
      const getOneAxios = await axiosData.get(`/pincodearea/getArea/${event}`);
      const data = getOneAxios.data;
      if (getOneAxios.data) {
        const pincodeArray = data.map((item) => {
          return String(item.pincode);
        });
        if (pincodeArray === "") {
          setSuggestionArrayl([]);
        } else {
          setSuggestionArrayl(pincodeArray);
        }
        const getPincode = data.map((i) => {
          return i.village_name;
        });
        getPincode.sort();
        setPincode(...getPincode);
        setpinLoad(false);
      } else {
        setSuggestionArrayl([]);
      }
    } catch (err) {
      console.log("Error", err);
    }
  };
  const initialValues = {
    pincode: "",
    village_town_name: "",
    area_street_name: "",
    place_of_service: "Company",
    type_of_service: "",
    contact_number: "",
    contact_person_name: "",
    status: "",
    need_type: "Normal",
    shop_name: "",
    shop_address: "",
    job_description: "",
  };

  const validationSchema = Yup.object().shape({
    pincode: Yup.number()
      .required(t("Pin_code_is_Required"))
      .min(600001, t("Not Valid pin code!"))
      .max(643253, t("Not Valid pin code!")),
    village_town_name: Yup.string()
      .max(50, t("Must_be_50_characters_or_less"))
      .required("Village/Town Name must be Required"),

    area_street_name: Yup.string()
      .max(50, t("Must_be_50_characters_or_less"))
      .required(t("Area/Street_Name_must_be_Required")),

    shop_name: Yup.string()
      .max(50, t("Must_be_50_characters_or_less"))
      .required(t("Shop_Name_must_be_Required")),
    shop_address: Yup.string()
      .max(200, t("Must_be_200_characters_or_less"))
      .required(t("Shop_address_must_be_Required")),

    type_of_service: Yup.string().required(t("Select_Types_of_Services")),
    place_of_service: Yup.string().required(t("Select_Place_of_Services")),
    need_type: Yup.string().required(t("Select_Place_of_Services")),
    contact_person_name: Yup.string()
      .max(50, t("Must_be_50_characters_or_less"))
      .required(t("Contact_Person_Name_must_be_Required")),
    job_description: Yup.string().required(
      t("job_description_must_be_Required")
    ),
    contact_number: Yup.number()
      .min(1000000000, t("Not_Valid_Mobile_Number!"))
      .max(9999999999, t("Not_Valid_Mobile_Number!"))
      .required(t("Mobile_Number_must_be_Required")),
    // starRating: Yup.number()
    // .min(1000000000, t("Not_Valid_Mobile_Number!"))
    // .max(9999999999, t("Not_Valid_Mobile_Number!"))
    // .required(t("Mobile_Number_must_be_Required")),
    // remarks: Yup.string()
    //   .max(50, "Must be 50 characters or less")
    //   .required("Remarks must be required"),
    // expiry_date_from: Yup.date()
    //   .required("From date is required")
    //   .min(yesterday, "From Date should be start From Today")
    //   .max(
    //     Yup.ref("expiry_date_to"),
    //     "From date must be equal to or smaller than the to date"
    //   )
    //   .test(
    //     "from-daqte-test",
    //     "From date cannot be greater than the to date",
    //     function (value) {
    //       const toDate = this.resolve(Yup.ref("expiry_date_to"));
    //       if (value && toDate && moment(value).isAfter(toDate)) {
    //         return false;
    //       }
    //       return true;
    //     }
    //   ),
    // expiry_date_to: Yup.date()
    //   .required("To date is required")
    //   .min(
    //     Yup.ref("expiry_date_from"),
    //     "To date must be equal to or greater than the from date"
    //   ),
  });
  const handleFormikSubmit = async (value, { resetForm }) => {
    setIsSubmitting(true);
    const expFrom = { ...value.expiry_date_from };

    const momentData = moment(expFrom);
    const formattedData = momentData.format("DD-MM-YYYY");
    const expTo = { ...value.expiry_date_to };

    const momentDataTo = moment(expTo);
    const formattedDataTo = momentDataTo.format("DD-MM-YYYY");
    try {
      const response = await axiosData.post("/localservices", {
        district: district_name,
        pincode: value.pincode,
        village_town_name: value.village_town_name,
        area_street_name: value.area_street_name,
        place_of_service: value.place_of_service,
        type_of_service: value.type_of_service,
        contact_number: value.contact_number,
        contact_person_name: value.contact_person_name,
        job_description: value.job_description,
        need_type: value.need_type,
        shop_name: value.shop_name,
        shop_address: value.shop_address,
        registertype: location.state.registertype,
        registerby: location.state.registerby,
      });
      if (response.data) {
        if (value.need_type === "Urgent") {
          try {
            await axiosData.post(
              "/urgentservices",
              {
                // district: district_name,
                pincode: value.pincode,
                village_town_name: value.village_town_name,
                area_street_name: value.area_street_name,
                user_id: response.data.user_id,
                job_id: response.data._id,
                place_of_service: value.place_of_service,
                type_of_service: value.type_of_service,
                contact_number: value.contact_number,
                contact_person_name: value.contact_person_name,
                job_description: value.job_description,
                need_type: value.need_type,
                shop_name: value.shop_name,
                shop_address: value.shop_address,
                registertype: location.state.registertype,
                registerby: location.state.registerby,
              },
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );
          } catch (err) {
            console.log(err);
          }
        }
      }
      resetForm();
      setIsFocused({
        ...isFocused,
        pincode: false,
        village_town_name: false,
        area_street_name: false,
        place_of_service: false,
        type_of_service: false,
        contact_number: false,
        contact_person_name: false,
        expiry_date_from: false,
        expiry_date_to: false,
        need_type: false,
        shop_name: false,
        shop_address: false,
        job_description: false,
      });
      if (location.state.registertype === "home") {
        navigate(
          `${ROUTINGDATA.LAND}${ROUTINGDATA.MAIN}${ROUTINGDATA.ThankYouForLocal}`
        );
      } else {
        navigate(-1);
      }
    } catch (err) {
      console.error(err);
    }
    setIsSubmitting(false);
  };
  const getpincode = async (value) => {
    try {
      const getOneAxios = await axiosData.get(`/uniqueallocation/${value}`);
      const data = getOneAxios.data;
      if (data) {
        setPincodeByDistrict(data[0].Pincode);
        console.log("vanthuta", data);
      }
    } catch (err) {
      console.log("Error", err);
    }
  };
  const vodingDistrict = async () => {
    try {
      const getOneAxios = await axiosData.get(`/distritarea`);
      const data = getOneAxios.data;
      if (data) {
        const getDistrict = data.map((i) => {
          return i.District;
        });
        getDistrict.sort();
        setDistrictData(getDistrict);
      }
      return data;
    } catch (err) {
      console.log("Error", err);
    }
  };
  useEffect(() => {
    vodingDistrict();
    getJobList();
  }, []);

  if (ready) {
    return (
      <ThemeProvider theme={theme}>
        <Dialog TransitionComponent={Transition} open={emailPopUp}>
          <DialogTitle
            bgcolor="rgb(16 143 178)"
            sx={{ color: "white", fontWeight: 600 }}
          >
            {t("Email_Exits")}
          </DialogTitle>
          <DialogContent sx={{ mt: 3 }}>
            <DialogContentText sx={{ fontWeight: 600 }}>
              {t("This_Email_Id_already_exist")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => setEmailPopUp(false)}
            >
              {t("Ok")}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog TransitionComponent={Transition} open={mobilePopUp}>
          <DialogTitle
            bgcolor="rgb(16 143 178)"
            sx={{ color: "white", fontWeight: 600 }}
          >
            {t("Mobile_Number_Exits")}
          </DialogTitle>
          <DialogContent sx={{ mt: 3 }}>
            <DialogContentText sx={{ fontWeight: 600 }}>
              {t("This_Mobile_number_already_exist")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => setMobilePopUp(false)}
            >
              {t("Ok")}
            </Button>
          </DialogActions>
        </Dialog>
        <Grid
          container
          component="main"
          sx={{ height: "100vh" }}
          className="mainGrid"
        >
          <CssBaseline />
          <Grid className="imgGrid" item xs={false} sm={false} md={4}>
            <Box>
              <Box className="sideReg">
                <div className="logo1">
                  <img src={logo} alt="logo" style={{ width: "75%" }} />
                </div>
                <Typography
                  variant="h5"
                  component="div"
                  className={lang === "tamil" ? "side-tamil-1" : "side-english"}
                  gutterBottom
                  sx={{ fontWeight: "bold", fontFamily: "Barlow-Bold" }}
                >
                  {t("By_Providing_Local_Services")}
                </Typography>
                <Box sx={{ display: "flex" }}>
                  <CheckCircleIcon
                    sx={{ mb: -0.6, mr: 0.5, color: "green" }}
                    color="green"
                  />{" "}
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    gutterBottom
                    className={lang === "tamil" ? "side-tamil" : "side-english"}
                  >
                    {t("Introduce_Your_Company")}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <CheckCircleIcon sx={{ mb: -0.6, mr: 0.5, color: "green" }} />{" "}
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    className={lang === "tamil" ? "side-tamil" : "side-english"}
                    gutterBottom
                  >
                    {t("Spell_Out_The_Top_Benefits")}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <CheckCircleIcon sx={{ mb: -0.6, mr: 0.5, color: "green" }} />{" "}
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    gutterBottom
                    className={lang === "tamil" ? "side-tamil" : "side-english"}
                  >
                    {t("Best_Candidates_Reach_Out_To_You_Directly")}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid
            className="secondGrid"
            item
            xs={12}
            sm={12}
            md={8}
            component={Paper}
            elevation={7}
          >
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleFormikSubmit}
            >
              {(formikProps) => {
                // if (formikProps.values.place_of_service !== "Company") {
                //   formikProps.setFieldValue("shop_name", "null");
                //   formikProps.setFieldValue("shop_name", "null");
                // }

                return (
                  <>
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      my={7}
                      alignItems={"center"}
                    >
                      <IconButton
                        disableFocusRipple
                        disableRipple
                        disableTouchRipple
                        sx={{
                          border: "2px solid rgb(237 30 112)",
                          mt: -3,
                          mb: 2,
                          cursor: "default",
                        }}
                      >
                        <WysiwygIcon
                          sx={{ fontSize: 50, color: "rgb(237 30 112)" }}
                        />
                      </IconButton>

                      <Typography
                        sx={{
                          color: "rgb(19 92 130)",
                          fontWeight: "bold",
                          fontFamily: "Poppins-Regular",
                        }}
                        className="example"
                        // component="h1"
                        variant="h5"
                      >
                        {t("Local_Services")}
                        {/* {t("JobSeeker_Registration")} */}
                      </Typography>
                      <Box
                        sx={{ mt: 3 }}
                        display={"flex"}
                        flexDirection={"column"}
                        //   my={8}
                        alignItems={"center"}
                        width="100%"
                        gap={"20px"}
                      >
                        <FormControl fullWidth>
                          <Autocomplete
                            fullWidth
                            disableClearable={district_name ? false : true}
                            filterOptions={filterOptions}
                            name="district_name"
                            id="votingDistrict"
                            value={district_name}
                            onChange={(event, value, reason) => {
                              setDistrict_name(value);
                              console.log("value", value);
                              getpincode(value);

                              if (reason === "selectOption") {
                                // getPincodeByDistrict(value);
                              }
                              if (reason === "clear") {
                                formikProps.setFieldValue("pincode", "");
                                formikProps.setFieldValue(
                                  "village_town_name",
                                  ""
                                );
                                formikProps.setFieldValue(
                                  "area_street_name",
                                  ""
                                );
                              }
                            }}
                            options={districtData}
                            getOptionLabel={(option) => option}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                InputLabelProps={{
                                  style: { marginLeft: "7px" },
                                }}
                                label="District"
                              />
                            )}
                            onOpen={(event) => event.preventDefault()}
                            PopperComponent={({ children, ...popperProps }) => (
                              <Popper
                                {...popperProps}
                                placement="bottom-start"
                                modifiers={{
                                  flip: {
                                    enabled: false,
                                  },
                                  preventOverflow: {
                                    enabled: true,
                                    boundariesElement: "scrollParent",
                                  },
                                }}
                                PopperProps={{
                                  placement: "bottom-start",
                                }}
                              >
                                <Paper>{children}</Paper>
                              </Popper>
                            )}
                          />
                        </FormControl>
                        <Tooltip
                          title={
                            district_name === ""
                              ? null
                              : "Select district first"
                          }
                          arrow
                        >
                          <FormControl fullWidth>
                            <FormLabel
                              // focused={isFocused.pin}
                              style={{
                                color:
                                  isFocused.pincode === true &&
                                  // formikProps.touched.pincode === false &&
                                  (formikProps.errors.pincode === undefined ||
                                    formikProps.touched.pincode === undefined)
                                    ? "#1976d5"
                                    : formikProps.errors.pincode !==
                                        undefined &&
                                      formikProps.touched.pincode === true
                                    ? "#de6363"
                                    : "#666666",
                              }}
                              sx={{ fontWeight: "bold" }}
                            >
                              {t("Pincode")}
                            </FormLabel>
                            <Autocomplete
                              disableClearable={district_name ? false : true}
                              disabled={district_name ? false : "true"}
                              disablePortal
                              id="pincode"
                              name="pincode"
                              options={pincodeByDistrict}
                              onChange={(event, value, reason) => {
                                searchobj(value, "pincode");
                                formikProps.setFieldValue("pincode", value);
                                handleSuggestionsPincode(value);
                                if (reason === "clear") {
                                  formikProps.setFieldValue(
                                    "village_town_name",
                                    ""
                                  );
                                  formikProps.setFieldValue(
                                    "area_street_name",
                                    ""
                                  );
                                }
                              }}
                              value={formikProps.values.pincode}
                              // options={pincodeByDistrict}
                              inputValue={searchQuery}
                              onInputChange={handleInputChange}
                              renderInput={(params) => (
                                <TextField
                                  fullWidth
                                  inputProps={{
                                    onFocus: () => {
                                      setIsFocused({
                                        ...isFocused,
                                        pincode: true,
                                      });
                                    },
                                    onBlur: () => {
                                      setIsFocused({
                                        ...isFocused,
                                        pincode: false,
                                      });
                                    },
                                  }}
                                  labelId="demo-simple-select-label"
                                  name="pincode"
                                  // label={t("Pincode")}
                                  type="number"
                                  id="pincode"
                                  value={searchQuery}
                                  onWheel={(e) => {
                                    e.preventDefault();
                                    e.target.blur();
                                  }}
                                  onKeyDown={(e) =>
                                    exceptThisSymbols.includes(e.key) &&
                                    e.preventDefault()
                                  }
                                  placeholder={t("Enter_Pincode(Optional)")}
                                  onBlur={formikProps.handleBlur}
                                  onChange={(e) => {
                                    handleSuggestionsPincode(e);
                                    if (e.target.value.length < 7) {
                                      handleSuggestionsPincode(e);
                                      if (
                                        formikProps.values.village_town_name
                                      ) {
                                        formikProps.setFieldValue(
                                          "village_town_name",
                                          ""
                                        );
                                        formikProps.setFieldValue(
                                          "area_street_name",
                                          ""
                                        );
                                      }
                                    }
                                    // } else {
                                    //   alert("Enter only 6 digit");
                                    // }
                                  }}
                                  {...params}
                                  error={
                                    formikProps.touched.pincode &&
                                    Boolean(formikProps.errors.pincode)
                                  }
                                  helperText={
                                    formikProps.touched.pincode &&
                                    formikProps.errors.pincode
                                  }
                                  InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        {pinLoad ? (
                                          <CircularProgress
                                            // size={24}
                                            style={{
                                              width: "1.7rem",
                                              height: "1.7rem",
                                              color:
                                                isFocused.pincode === true &&
                                                // formikProps.touched.pincode === false &&
                                                (formikProps.errors.pincode ===
                                                  undefined ||
                                                  formikProps.touched
                                                    .pincode === undefined)
                                                  ? "#1976d5"
                                                  : formikProps.errors
                                                      .pincode !== undefined &&
                                                    formikProps.touched
                                                      .pincode === true
                                                  ? "#de6363"
                                                  : "#666666",
                                            }}
                                          />
                                        ) : (
                                          <ShareLocationRoundedIcon
                                            sx={{
                                              color:
                                                isFocused.pincode === true &&
                                                // formikProps.touched.pincode === false &&
                                                (formikProps.errors.pincode ===
                                                  undefined ||
                                                  formikProps.touched
                                                    .pincode === undefined)
                                                  ? "#1976d5"
                                                  : formikProps.errors
                                                      .pincode !== undefined &&
                                                    formikProps.touched
                                                      .pincode === true
                                                  ? "#de6363"
                                                  : "#666666",
                                            }}
                                          />
                                        )}{" "}
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              )}
                            />
                          </FormControl>
                        </Tooltip>
                        <Tooltip
                          title={
                            formikProps.values.pincode
                              ? ""
                              : t("Enter_Pincode_First")
                          }
                        >
                          <FormControl
                            fullWidth
                            disabled={formikProps.values.pincode ? false : true}
                          >
                            <FormLabel
                              sx={{ fontWeight: "bold" }}
                              style={{
                                color:
                                  isFocused.village_town_name === true &&
                                  (formikProps.errors.village_town_name ===
                                    undefined ||
                                    formikProps.touched.village_town_name ===
                                      undefined)
                                    ? "#1976d5"
                                    : formikProps.errors.village_town_name !==
                                        undefined &&
                                      formikProps.touched.village_town_name ===
                                        true
                                    ? "#de6363"
                                    : "#666666",
                              }}
                            >
                              {t("Select_Post_Office")}
                            </FormLabel>
                            <Select
                              fullWidth
                              name="village_town_name"
                              labelId="demo-simple-select-label"
                              id="village_town_name"
                              // label="village_town_name"
                              required
                              // inputProps={{
                              //   onFocus: () => {
                              //     setIsFocused({
                              //       ...isFocused,
                              //       village_town_name: true,
                              //     });
                              //   },
                              //   onBlur: () => {
                              //     setIsFocused({
                              //       ...isFocused,
                              //       village_town_name: false,
                              //     });
                              //   },
                              // }}
                              placeholder="Enter"
                              sx={{ margin: "0" }}
                              onBlur={() => {
                                formikProps.setFieldTouched(
                                  "village_town_name"
                                );

                                setIsFocused({
                                  ...isFocused,
                                  village_town_name: false,
                                });
                              }}
                              onChange={formikProps.handleChange}
                              value={formikProps.values.village_town_name}
                              InputLabelProps={{
                                style: { color: "black" },
                              }}
                              InputProps={{
                                style: { color: "black" },
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <HomeWorkRoundedIcon
                                      sx={{
                                        color:
                                          isFocused.village_town_name ===
                                            true &&
                                          (formikProps.errors
                                            .village_town_name === undefined ||
                                            formikProps.touched
                                              .village_town_name === undefined)
                                            ? "#1976d5"
                                            : formikProps.errors
                                                .village_town_name !==
                                                undefined &&
                                              formikProps.touched
                                                .village_town_name === true
                                            ? "#de6363"
                                            : "#666666",
                                      }}
                                    />{" "}
                                  </InputAdornment>
                                ),
                              }}
                              onFocus={() => {
                                setIsFocused({
                                  ...isFocused,
                                  village_town_name: true,
                                });
                              }}
                              error={
                                formikProps.touched.village_town_name &&
                                Boolean(formikProps.errors.village_town_name)
                              }
                              helperText={
                                formikProps.touched.village_town_name &&
                                formikProps.errors.village_town_name
                              }
                              displayEmpty
                              MenuProps={MenuProps}
                            >
                              <MenuItem
                                value=""
                                disabled
                                disableRipple
                                disableTouchRipple
                              >
                                {t("Select_Post_Office_Name")}
                              </MenuItem>
                              {pincode &&
                                pincode.map((element, index) => {
                                  return (
                                    <MenuItem key={index} value={element}>
                                      {element}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                            <FormHelperText style={{ color: "#de6363" }}>
                              {formikProps.touched.village_town_name &&
                              formikProps.errors.village_town_name
                                ? formikProps.errors.village_town_name
                                : ""}
                            </FormHelperText>
                          </FormControl>
                        </Tooltip>
                        <Tooltip
                          title={
                            formikProps.values.village_town_name
                              ? ""
                              : t("Select_Post_Office_Name")
                          }
                        >
                          <FormControl fullWidth>
                            <FormLabel
                              sx={{ fontWeight: "bold" }}
                              style={{
                                color:
                                  isFocused.area_street_name === true &&
                                  (formikProps.errors.area_street_name ===
                                    undefined ||
                                    formikProps.touched.area_street_name ===
                                      undefined)
                                    ? "#1976d5"
                                    : formikProps.errors.area_street_name !==
                                        undefined &&
                                      formikProps.touched.area_street_name ===
                                        true
                                    ? "#de6363"
                                    : "#666666",
                              }}
                            >
                              {t("Area/Street_Name")}
                            </FormLabel>
                            <TextField
                              disabled={
                                formikProps.values.village_town_name
                                  ? false
                                  : true
                              }
                              // margin="normal"
                              labelId="demo-simple-select-label"
                              inputProps={{
                                onFocus: () => {
                                  setIsFocused({
                                    ...isFocused,
                                    area_street_name: true,
                                  });
                                },
                                onBlur: () => {
                                  setIsFocused({
                                    ...isFocused,
                                    area_street_name: false,
                                  });
                                },
                              }}
                              fullWidth
                              required
                              placeholder={t("Enter_Area_or_Street_Name")}
                              id="area_street_name"
                              // label={t("Full_Name")}
                              name="area_street_name"
                              sx={{ margin: "0" }}
                              onBlur={formikProps.handleBlur}
                              onChange={(e) => {
                                const { value } = e.target;
                                const shopValue = value.replace(
                                  /[^a-zA-Z0-9., ]/g,
                                  ""
                                );
                                formikProps.setFieldValue(
                                  "area_street_name",
                                  shopValue
                                );
                              }}
                              value={formikProps.values.area_street_name}
                              InputLabelProps={{
                                style: { color: "black" },
                              }}
                              InputProps={{
                                style: { color: "black" },
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <img
                                      style={{
                                        width: "1.7rem",
                                        height: "1.7rem",
                                        // color: "#757575",
                                      }}
                                      src={`https://img.icons8.com/ios-filled/50/${
                                        isFocused.area_street_name === true &&
                                        (formikProps.errors.area_street_name ===
                                          undefined ||
                                          formikProps.touched
                                            .area_street_name === undefined)
                                          ? "1976d5"
                                          : formikProps.errors
                                              .area_street_name !== undefined &&
                                            formikProps.touched
                                              .area_street_name === true
                                          ? "de6363"
                                          : "666666"
                                      }/street-view.png`}
                                      alt="street-view"
                                    />
                                  </InputAdornment>
                                ),
                              }}
                              error={
                                formikProps.touched.area_street_name &&
                                Boolean(formikProps.errors.area_street_name)
                              }
                              helperText={
                                formikProps.touched.area_street_name &&
                                formikProps.errors.area_street_name
                              }
                            />
                          </FormControl>
                        </Tooltip>
                        <Tooltip
                          title={
                            formikProps.values.type_of_service
                              ? null
                              : t("Select_Service_Name_That_You_are_offering")
                          }
                          arrow
                        >
                          <FormControl
                            // disabled={
                            //   formikProps.values.votingDistrict ? false : true
                            // }

                            fullWidth
                            required
                          >
                            <FormLabel
                              sx={{ fontWeight: "bold" }}
                              style={{
                                color:
                                  isFocused.type_of_service === true &&
                                  (formikProps.errors.type_of_service ===
                                    undefined ||
                                    formikProps.touched.type_of_service ===
                                      undefined)
                                    ? "#1976d5"
                                    : formikProps.errors.type_of_service !==
                                        undefined &&
                                      formikProps.touched.type_of_service ===
                                        true
                                    ? "#de6363"
                                    : "#666666",
                              }}
                            >
                              {t("Types_of_Services")}
                              {/* {t("MP_Constituency")} */}
                            </FormLabel>
                            <Autocomplete
                              disableClearable={
                                formikProps.values.type_of_service
                                  ? false
                                  : true
                              }
                              filterOptions={filterOptions}
                              id="type_of_service"
                              name="type_of_service"
                              options={serviceName}
                              onBlur={formikProps.handleBlur}
                              onChange={(event, value) => {
                                setSelectedIndustry(value);
                                formikProps.setFieldValue(
                                  "type_of_service",
                                  value
                                );
                              }}
                              value={formikProps.values.type_of_service}
                              renderInput={(params) => (
                                <TextField
                                  required
                                  {...params}
                                  InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <img
                                          style={{
                                            width: "1.7rem",
                                            height: "1.7rem",
                                            // color: "#757575",
                                          }}
                                          src={`https://img.icons8.com/glyph-neue/64/${
                                            isFocused.type_of_service ===
                                              true &&
                                            (formikProps.errors
                                              .type_of_service === undefined ||
                                              formikProps.touched
                                                .type_of_service === undefined)
                                              ? "1976d5"
                                              : formikProps.errors
                                                  .type_of_service !==
                                                  undefined &&
                                                formikProps.touched
                                                  .type_of_service === true
                                              ? "de6363"
                                              : "666666"
                                          }/service.png`}
                                          alt="service"
                                        />
                                      </InputAdornment>
                                    ),
                                  }}
                                  id="type_of_service"
                                  // label={t("Preffered_Industry")}
                                  name="type_of_service"
                                  // onClick={() =>
                                  //   params.inputProps.onInputClick()
                                  // }
                                  placeholder={t("Types_of_Services")}
                                  error={
                                    formikProps.touched.type_of_service &&
                                    Boolean(formikProps.errors.type_of_service)
                                  }
                                  helperText={
                                    formikProps.touched.type_of_service &&
                                    formikProps.errors.type_of_service
                                  }
                                  onFocus={() =>
                                    setIsFocused({
                                      ...isFocused,
                                      type_of_service: true,
                                    })
                                  }
                                  onBlur={() =>
                                    setIsFocused({
                                      ...isFocused,
                                      type_of_service: false,
                                    })
                                  }
                                />
                              )}
                              onOpen={(event) => event.preventDefault()}
                              PopperComponent={({
                                children,
                                ...popperProps
                              }) => (
                                <Popper
                                  {...popperProps}
                                  placement="bottom-start"
                                  modifiers={{
                                    flip: {
                                      enabled: false,
                                    },
                                    preventOverflow: {
                                      enabled: true,
                                      boundariesElement: "scrollParent",
                                    },
                                  }}
                                  PopperProps={{
                                    placement: "bottom-start", // Placement set to "bottom-start"
                                  }}
                                >
                                  <Paper>{children}</Paper>
                                </Popper>
                              )}
                              ListboxProps={{
                                style: {
                                  maxHeight: "180px",
                                  overflowY: "auto",
                                },
                              }}
                            />
                          </FormControl>
                        </Tooltip>
                        <FormControl fullWidth>
                          <FormLabel
                            id="demo-controlled-radio-buttons-group"
                            sx={{ fontWeight: "bold" }}
                          >
                            {t("Service_Need_Type")}
                          </FormLabel>
                          <RadioGroup
                            row
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="need_type"
                            value={formikProps.values.need_type}
                            onChange={formikProps.handleChange}
                            onBlur={formikProps.handleBlur}
                          >
                            <FormControlLabel
                              value="Normal"
                              control={<Radio />}
                              label={t("Normal")}
                            />
                            <FormControlLabel
                              value="Urgent"
                              control={<Radio />}
                              label={t("Urgent")}
                            />
                            {/* <FormControlLabel
                              value="Later"
                              control={<Radio />}
                              label="Later"
                            /> */}
                          </RadioGroup>
                        </FormControl>
                        <FormControl fullWidth>
                          <FormLabel
                            id="demo-controlled-radio-buttons-group"
                            sx={{ fontWeight: "bold" }}
                          >
                            {t("Place_of_Service")}
                          </FormLabel>
                          <RadioGroup
                            row
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="place_of_service"
                            value={formikProps.values.place_of_service}
                            onChange={(e) => {
                              formikProps.setFieldValue(
                                "place_of_service",
                                e.target.value
                              );
                              if (
                                e.target.value === "Home" ||
                                e.target.value === "Others"
                              ) {
                                formikProps.setFieldValue("shop_name", "null");
                                formikProps.setFieldValue(
                                  "shop_address",
                                  "null"
                                );
                              }
                              if (e.target.value === "Company") {
                                formikProps.setFieldValue("shop_name", "");
                                formikProps.setFieldValue("shop_address", "");
                              }
                              // formikProps.handleChange(e)
                            }}
                            onBlur={formikProps.handleBlur}
                          >
                            <FormControlLabel
                              value="Company"
                              control={<Radio />}
                              label={t("Company")}
                            />
                            <FormControlLabel
                              value="Home"
                              control={<Radio />}
                              label={t("Home")}
                            />
                            <FormControlLabel
                              value="Others"
                              control={<Radio />}
                              label={t("Others")}
                            />
                          </RadioGroup>
                        </FormControl>
                        {formikProps.values.place_of_service === "Company" ? (
                          <>
                            <FormControl fullWidth>
                              <FormLabel
                                sx={{ fontWeight: "bold" }}
                                style={{
                                  color:
                                    isFocused.shop_name === true &&
                                    (formikProps.errors.shop_name ===
                                      undefined ||
                                      formikProps.touched.shop_name ===
                                        undefined)
                                      ? "#1976d5"
                                      : formikProps.errors.shop_name !==
                                          undefined &&
                                        formikProps.touched.shop_name === true
                                      ? "#de6363"
                                      : "#666666",
                                }}
                              >
                                {t("Company_/_Shop_Name")}
                              </FormLabel>
                              <TextField
                                // margin="normal"
                                labelId="demo-simple-select-label"
                                inputProps={{
                                  onFocus: () => {
                                    setIsFocused({
                                      ...isFocused,
                                      shop_name: true,
                                    });
                                  },
                                  onBlur: () => {
                                    setIsFocused({
                                      ...isFocused,
                                      shop_name: false,
                                    });
                                  },
                                }}
                                fullWidth
                                required
                                placeholder={t("Enter_Company_/_Shop_name")}
                                id="shop_name"
                                // label={t("Full_Name")}
                                name="shop_name"
                                sx={{ margin: "0" }}
                                onBlur={formikProps.handleBlur}
                                onChange={(e) => {
                                  const { value } = e.target;
                                  const shopValue = value.replace(
                                    /[^a-z'. ]/gi,
                                    ""
                                  );
                                  formikProps.setFieldValue(
                                    "shop_name",
                                    shopValue
                                  );
                                }}
                                value={formikProps.values.shop_name}
                                InputLabelProps={{
                                  style: { color: "black" },
                                }}
                                InputProps={{
                                  style: { color: "black" },
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <ShoppingCartRoundedIcon
                                        style={{
                                          color:
                                            isFocused.shop_name === true &&
                                            (formikProps.errors.shop_name ===
                                              undefined ||
                                              formikProps.touched.shop_name ===
                                                undefined)
                                              ? "#1976d5"
                                              : formikProps.errors.shop_name !==
                                                  undefined &&
                                                formikProps.touched
                                                  .shop_name === true
                                              ? "#de6363"
                                              : "#666666",
                                        }}
                                      />{" "}
                                    </InputAdornment>
                                  ),
                                }}
                                error={
                                  formikProps.touched.shop_name &&
                                  Boolean(formikProps.errors.shop_name)
                                }
                                helperText={
                                  formikProps.touched.shop_name &&
                                  formikProps.errors.shop_name
                                }
                              />
                            </FormControl>
                            <FormControl fullWidth>
                              <FormLabel
                                sx={{ fontWeight: "bold" }}
                                style={{
                                  color:
                                    isFocused.shop_address === true &&
                                    (formikProps.errors.shop_address ===
                                      undefined ||
                                      formikProps.touched.shop_address ===
                                        undefined)
                                      ? "#1976d5"
                                      : formikProps.errors.shop_address !==
                                          undefined &&
                                        formikProps.touched.shop_address ===
                                          true
                                      ? "#de6363"
                                      : "#666666",
                                }}
                              >
                                {t("Company_/_Shop_Address")}
                              </FormLabel>
                              <TextField
                                // margin="normal"
                                labelId="demo-simple-select-label"
                                inputProps={{
                                  onFocus: () => {
                                    setIsFocused({
                                      ...isFocused,
                                      shop_address: true,
                                    });
                                  },
                                  onBlur: () => {
                                    setIsFocused({
                                      ...isFocused,
                                      shop_address: false,
                                    });
                                  },
                                }}
                                fullWidth
                                required
                                placeholder={t("Enter_Company_/_Shop_address")}
                                id="Name"
                                // label={t("Full_Name")}
                                name="shop_address"
                                sx={{ margin: "0" }}
                                onBlur={formikProps.handleBlur}
                                onChange={(e) => {
                                  const { value } = e.target;
                                  const shopValue = value.replace(
                                    /[^a-zA-Z0-9,/. #]/g,
                                    ""
                                  );
                                  formikProps.setFieldValue(
                                    "shop_address",
                                    shopValue
                                  );
                                }}
                                value={formikProps.values.shop_address}
                                InputLabelProps={{
                                  style: { color: "black" },
                                }}
                                InputProps={{
                                  style: { color: "black" },
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <FmdGoodRoundedIcon
                                        style={{
                                          color:
                                            isFocused.shop_address === true &&
                                            (formikProps.errors.shop_address ===
                                              undefined ||
                                              formikProps.touched
                                                .shop_address === undefined)
                                              ? "#1976d5"
                                              : formikProps.errors
                                                  .shop_address !== undefined &&
                                                formikProps.touched
                                                  .shop_address === true
                                              ? "#de6363"
                                              : "#666666",
                                        }}
                                      />{" "}
                                    </InputAdornment>
                                  ),
                                }}
                                error={
                                  formikProps.touched.shop_address &&
                                  Boolean(formikProps.errors.shop_address)
                                }
                                helperText={
                                  formikProps.touched.shop_address &&
                                  formikProps.errors.shop_address
                                }
                              />
                            </FormControl>
                          </>
                        ) : null}

                        <FormControl fullWidth>
                          <FormLabel
                            sx={{ fontWeight: "bold" }}
                            style={{
                              color:
                                isFocused.contact_person_name === true &&
                                (formikProps.errors.contact_person_name ===
                                  undefined ||
                                  formikProps.touched.contact_person_name ===
                                    undefined)
                                  ? "#1976d5"
                                  : formikProps.errors.contact_person_name !==
                                      undefined &&
                                    formikProps.touched.contact_person_name ===
                                      true
                                  ? "#de6363"
                                  : "#666666",
                            }}
                          >
                            {t("Contact_Person_Name")}
                          </FormLabel>
                          <TextField
                            // margin="normal"
                            labelId="demo-simple-select-label"
                            inputProps={{
                              onFocus: () => {
                                setIsFocused({
                                  ...isFocused,
                                  contact_person_name: true,
                                });
                              },
                              onBlur: () => {
                                setIsFocused({
                                  ...isFocused,
                                  contact_person_name: false,
                                });
                              },
                            }}
                            fullWidth
                            required
                            placeholder={t("Enter_Your_Contact_Person_Name")}
                            id="Name"
                            // label={t("Full_Name")}
                            name="contact_person_name"
                            sx={{ margin: "0" }}
                            onBlur={formikProps.handleBlur}
                            onChange={(e) => {
                              const { value } = e.target;
                              const shopValue = value.replace(
                                /[^a-z'. ]/gi,
                                ""
                              );
                              formikProps.setFieldValue(
                                "contact_person_name",
                                shopValue
                              );
                            }}
                            value={formikProps.values.contact_person_name}
                            InputLabelProps={{
                              style: { color: "black" },
                            }}
                            InputProps={{
                              style: { color: "black" },
                              startAdornment: (
                                <InputAdornment position="start">
                                  <ContactPageRoundedIcon
                                    sx={{
                                      color:
                                        isFocused.contact_person_name ===
                                          true &&
                                        (formikProps.errors
                                          .contact_person_name === undefined ||
                                          formikProps.touched
                                            .contact_person_name === undefined)
                                          ? "#1976d5"
                                          : formikProps.errors
                                              .contact_person_name !==
                                              undefined &&
                                            formikProps.touched
                                              .contact_person_name === true
                                          ? "#de6363"
                                          : "#666666",
                                    }}
                                  />{" "}
                                </InputAdornment>
                              ),
                            }}
                            error={
                              formikProps.touched.contact_person_name &&
                              Boolean(formikProps.errors.contact_person_name)
                            }
                            helperText={
                              formikProps.touched.contact_person_name &&
                              formikProps.errors.contact_person_name
                            }
                          />
                        </FormControl>
                        <FormControl fullWidth>
                          <FormLabel
                            sx={{ fontWeight: "bold" }}
                            style={{
                              color:
                                isFocused.contact_number === true &&
                                (formikProps.errors.contact_number ===
                                  undefined ||
                                  formikProps.touched.contact_number ===
                                    undefined ||
                                  captureBlur.contact_number === false)
                                  ? "#1976d5"
                                  : formikProps.errors.contact_number !==
                                      undefined &&
                                    formikProps.touched.contact_number ===
                                      true &&
                                    captureBlur.contact_number === true
                                  ? "#de6363"
                                  : "#666666",
                            }}
                          >
                            {t("Contact_Number")}
                          </FormLabel>
                          <TextField
                            // margin="normal"
                            labelId="demo-simple-select-label"
                            inputProps={{
                              onFocus: () => {
                                setIsFocused({
                                  ...isFocused,
                                  contact_number: true,
                                });
                              },
                              onBlur: () => {
                                setIsFocused({
                                  ...isFocused,
                                  contact_number: false,
                                });
                              },
                            }}
                            onBlurCapture={() =>
                              setCaptureBlur({
                                ...captureBlur,
                                contact_number: true,
                              })
                            }
                            fullWidth
                            required
                            placeholder={t("Enter_Your_Mobile_Number")}
                            name="contact_number"
                            // label={t("Mobile_Number")}
                            type="Number"
                            onWheel={(e) => {
                              e.preventDefault();
                              e.target.blur();
                            }}
                            onKeyDown={(e) =>
                              exceptThisSymbols.includes(e.key) &&
                              e.preventDefault()
                            }
                            id="mobile_number"
                            onBlur={formikProps.handleBlur}
                            onChange={(e) => {
                              setCaptureBlur({
                                ...captureBlur,
                                contact_number: false,
                              });
                              const { value } = e.target;
                              if (value.length <= 10) {
                                formikProps.handleChange(e);
                              }
                            }}
                            value={formikProps.values.contact_number}
                            InputLabelProps={{
                              style: { color: "black" },
                            }}
                            InputProps={{
                              maxLength: 10,
                              startAdornment: (
                                <InputAdornment position="start">
                                  <b
                                    style={{
                                      color:
                                        isFocused.contact_number === true &&
                                        (formikProps.errors.contact_number ===
                                          undefined ||
                                          formikProps.touched.contact_number ===
                                            undefined ||
                                          captureBlur.contact_number === false)
                                          ? "#1976d5"
                                          : formikProps.errors
                                              .contact_number !== undefined &&
                                            formikProps.touched
                                              .contact_number === true &&
                                            captureBlur.contact_number === true
                                          ? "#de6363"
                                          : "#666666",
                                    }}
                                  >
                                    +91
                                  </b>
                                </InputAdornment>
                              ),
                            }}
                            error={
                              formikProps.touched.contact_number &&
                              Boolean(formikProps.errors.contact_number) &&
                              captureBlur.contact_number
                            }
                            helperText={
                              formikProps.touched.contact_number &&
                              captureBlur.contact_number &&
                              formikProps.errors.contact_number
                            }
                          />
                        </FormControl>

                        <FormControl>
                          <FormLabel
                            sx={{ fontWeight: "bold" }}
                            style={{
                              color:
                                isFocused.job_description === true &&
                                (formikProps.errors.job_description ===
                                  undefined ||
                                  formikProps.touched.job_description ===
                                    undefined)
                                  ? "#1976d5"
                                  : formikProps.errors.job_description !==
                                      undefined &&
                                    formikProps.touched.job_description === true
                                  ? "#de6363"
                                  : "#666666",
                            }}
                          >
                            {t("Job_Description")}
                          </FormLabel>
                          <ReactQuill
                            value={formikProps.values.job_description}
                            onFocus={() => {
                              setIsFocused({
                                ...isFocused,
                                job_description: true,
                              });
                            }}
                            onBlur={() => {
                              setIsFocused({
                                ...isFocused,
                                job_description: false,
                              });
                            }}
                            onChange={(value) => {
                              formikProps.setFieldValue(
                                "job_description",
                                value
                              );
                              setMentorExperience(value);
                            }}
                            modules={{
                              toolbar: [
                                ["bold", "italic", "underline", "strike"],
                                ["blockquote", "code-block"],
                                [{ list: "ordered" }, { list: "bullet" }],
                                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                                [{ color: [] }, { background: [] }],
                                [{ font: [] }],
                                [{ align: [] }],
                                ["clean"],
                              ],
                            }}
                            placeholder={t("Enter_Notes_you_want_to_add")}
                            className="scrollable-container-postjob"
                          />
                        </FormControl>
                        <Tooltip
                          arrow
                          title={t("Please_Fill_all_the_fields")}
                          sx={{ width: "100%" }}
                        >
                          <Box width="100%">
                            <Button
                              type="submit"
                              disabled={
                                formikProps.isValid &&
                                formikProps.dirty &&
                                (mentorExperience === "<p><br></p>") ===
                                  false &&
                                isSubmitting === false
                                  ? false
                                  : true
                              }
                              fullWidth
                              variant="contained"
                              sx={{
                                mt: 3,
                                mb: 2,
                                textTransform: "capitalize",
                                backgroundColor: "#135c82",
                                transition: "0.3s all",
                                "&:hover": { backgroundColor: "#022638" },
                              }}
                              onClick={formikProps.handleSubmit}
                            >
                              {t("Submit")}
                              {isSubmitting && (
                                <CircularProgress
                                  size={24}
                                  style={{
                                    color: "#ffffff",

                                    marginLeft: 12,
                                  }}
                                />
                              )}
                            </Button>
                          </Box>
                        </Tooltip>
                      </Box>
                    </Box>
                  </>
                );
              }}
            </Formik>
          </Grid>
        </Grid>
        <Outlet />
      </ThemeProvider>
    );
  } else {
    return (
      <div
        style={{
          height: "70vh",
          display: "grid",
          placeItems: "center",
        }}
      >
        <div class="dots"></div>
      </div>
    );
  }
}
export default LocalServicesForm;
