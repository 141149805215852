import React from "react";
import { Snackbar, Typography, Alert } from "@mui/material";
function AdminSnackBar({ openSnack, setOpenSnack, snackOption, full_name }) {
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openSnack}
        autoHideDuration={3000}
        onClose={() => setOpenSnack(false)}
      >
        <Alert
          severity={
            snackOption === "success"
              ? "success"
              : snackOption === "error"
              ? "error"
              : snackOption === "info"
              ? "info"
              : ""
          }
          variant="filled"
          onClose={() => setOpenSnack(false)}
        >
          <Typography variant="body1">
            <span>{full_name}</span>&nbsp;
            {snackOption === "success"
              ? " was Approved !"
              : snackOption === "error"
              ? "was Declined !"
              : snackOption === "info"
              ? "was Hold"
              : ""}
          </Typography>
        </Alert>
      </Snackbar>
    </>
  );
}

export default AdminSnackBar;
