import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import DescriptionIcon from "@mui/icons-material/Description";
import { useLocation, useNavigate } from "react-router-dom";
import useAxios from "../../../../api/useAxios";
import employerProfile from "../../../../img/employerProfile/employerUpdate-logo.png";
import {
  TextField,
  Tooltip,
  IconButton,
  CircularProgress,
} from "@mui/material";
import WestIcon from "@mui/icons-material/West";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Autocomplete from "@mui/material/Autocomplete";
import { industry } from "../../jobseeker/cdata";
import moment from "moment";
import Grid from "@mui/system/Unstable_Grid/Grid";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import RefreshIcon from "@mui/icons-material/Refresh";
import { ROUTINGDATA } from "../../../ROUTINGDATA";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const renderParagraphs = (description) => {
  const parser = new DOMParser();
  const htmlDoc = parser.parseFromString(description, "text/html");
  const paragraphs = htmlDoc.querySelectorAll("p");
  const listItems = htmlDoc.querySelectorAll("li");

  for (let i = 0; i < paragraphs.length; i++) {
    const paragraph = paragraphs[i];
    if (paragraph.innerHTML.trim() === "<br>") {
      paragraph.parentNode.removeChild(paragraph);
    }
  }

  for (let i = 0; i < listItems.length; i++) {
    const listItem = listItems[i];
    if (listItem.innerHTML.trim() === "<br>") {
      listItem.parentNode.removeChild(listItem);
    }
  }

  const firstElement = [...paragraphs, ...listItems].find(
    (element) => element.innerText.trim() !== ""
  );

  if (listItems) {
    listItems.forEach((item) => {
      item.classList.add("selected-li");
    });
  }

  return firstElement ? firstElement.outerHTML : null;
};

const getTodayDate = moment().format("DD-MM-YYYY");
function RecommendedJob() {
  let location = useLocation();
  const axiosData = useAxios();
  const [mobilesearch, setmobilesearch] = useState(false);
  const navigate = useNavigate();
  const [jobDetails, setJobDetails] = useState([]);
  const [seekerProfileDetails, setSeekerProfileDetails] = useState();
  const [Applyed, setApplyed] = useState([]);
  const [jobApplied, setJobApplied] = useState(false);
  const [description, setDescription] = useState([]);
  const [openMoreDetails, setOpenMoreDetails] = useState(false);
  const [jobDescription, setJobDescription] = useState([]);
  let seekerDetails = JSON.parse(localStorage.getItem("seeker"));
  const [searchPopUp, setSearchPopUp] = useState(false);
  const [noResultPopUp, setNoResultPopUp] = useState(false);
  const [searchQuery, setSearchQuery] = useState([]);
  const [suggestionArray, setSuggestionArray] = useState([]);
  const [suggestionArrayl, setSuggestionArrayl] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState({
    key_skills: null,
    job_location: null,
    industry: null,
    page_number: 1,
  });
  const [openMoreDetails2, setOpenMoreDetails2] = useState(false);
  const [jobDetails1, setJobDetails1] = useState();
  const [jobDescription2, setJobDescription2] = useState();
  const [description2, setDescription2] = useState([]);
  const [loadMore, setLoadMore] = useState(false);
  const [loadDisabled, setLoadDisabled] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalData, setTotalData] = useState();
  const [searchTotalData, setSearchTotalData] = useState();
  const [declined, setDeclined] = useState();
  const [searchjobid, setsearchjobid] = useState();
  const [higlightjob, sethiglightjob] = useState("");
  const [higlightjobs_1,sethiglightjobs_1] = useState(true);
  const baseUrl = axiosData.defaults.baseURL;
  const getSeekerDetail = async () => {
    try {
      const response = await axiosData.get(`seekers/${seekerDetails.id}`, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data) {
        setSeekerProfileDetails(response.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const searchResult1 = async (result) => {
    if(location.state.search.job_id){
      try{
        const response1 = await axiosData.get(`jobs/getOne/${location.state.search.job_id}`, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        setJobDetails1(response1.data); 
        setsearchjobid(response1.data.job_id);
        setDescription2(response1.data.job_description);
    try {
      const response = await axiosData.post(`jobs/search`, result, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data) {
        setJobDetails([...response.data]);
        setDescription([...response.data.map((job) => job.description)]);
        setLoadDisabled(response.data.is_last_record_set);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  }catch(err){

  }}else{
    sethiglightjobs_1(false);
    sethiglightjob("")
    try {
      const response = await axiosData.post(`jobs/search`, result, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response.data) {
        setJobDetails([...response.data]);
        setDescription([...response.data.map((job) => job.description)]);
        setLoadDisabled(response.data.is_last_record_set);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  };
}
  if (location.state.email_id === "") {
    searchResult1(location.state.search);
  }
  const jobs = async (hint) => {
    setLoading(true);
    setSelectedOptions({
      key_skills: null,
      job_location: null,
      industry: null,
    });
    if(location.state.industry.job_id && hint){
      try{
        const response1 = await axiosData.get(`jobs/getOne/${location.state.industry.job_id}`, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        setJobDetails1(response1.data); 
        setsearchjobid(response1.data.job_id);
        setDescription2(response1.data.job_description);
    try {
      const response = await axiosData.post(
        `jobs/search`,
        { industry: seekerDetails.industry, page_number: 1 },

        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data) {
        setSearchTotalData("");
        const activeJobs  = response.data.filter((datas)=>datas.job_status === "Active")
        const filterjobs =activeJobs.filter((items)=>items._id !== location.state.search.job_id);
        setTotalData(response.data.total_record);
        setJobDetails([...filterjobs.data].reverse());
        setDescription([...filterjobs.data].reverse());

        setSelectedOptions({
          industry: seekerDetails.industry,
          key_skills: null,
          job_location: null,
          page_number: 1,
        });
        setLoadDisabled(response.data.is_last_record_set);
        setLoading(false);
      }
      if (response.status === 204) {
        setSelectedOptions({
          key_skills: null,
          job_location: null,
          industry: null,
        });
       
        setTotalData(0);
        setJobDetails([]);
        setDescription([]);
        setLoading(false);
        setNoResultPopUp(true);
      }
    } catch (err) {
      console.log(err);
    }}catch(err){

    }}
    else{
      sethiglightjobs_1(false)
      sethiglightjob("")
      setSelectedOptions({
        industry: seekerDetails.industry,
        key_skills: null,
        job_location: null,
        page_number: 1,
      });
      try {
        const response = await axiosData.post(
          `jobs/search`,
          { industry: seekerDetails.industry, page_number: 1 },
  
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
  
        if (response.data) {
          setSearchTotalData("");
          setTotalData(response.data.total_record);
          setJobDetails([...response.data.data].reverse());
          setDescription([...response.data.data].reverse());
  
          setSelectedOptions({
            industry: seekerDetails.industry,
            key_skills: null,
            job_location: null,
            page_number: 1,
          });
          setLoadDisabled(response.data.is_last_record_set);
          setLoading(false);
        }
        if (response.status === 204) {
          
          setSearchTotalData("");
          setTotalData(0);
          setJobDetails([]);
          setDescription([]);
          setLoading(false);
          setNoResultPopUp(true);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };
  const filterOptions = (options, { inputValue }) => {
    const inputValueLower = inputValue.toLowerCase();
    const startsWithInput = [];
    const containsInput = [];

    options.forEach((option) => {
      const optionLower = option.toLowerCase();
      if (optionLower.startsWith(inputValueLower)) {
        startsWithInput.push(option);
      } else if (optionLower.includes(inputValueLower)) {
        containsInput.push(option);
      }
    });

    return [...startsWithInput, ...containsInput];
  };

  const [industryArray, setIndustryArray] = useState();

  const IndustryData = async () => {
    try {
      const getOneAxios = await axiosData.get(`/General/seekerSettings`);
      const data = getOneAxios?.data?.industryData;
      if (data) {
        setIndustryArray(data);
      }
      return data;
    } catch (err) {
      console.log("Error", err);
    }
  };

  useEffect(() => {
    IndustryData();
  }, []);

  const ApplyedJob = async () => {
    try {
      const response = await axiosData.get(
        `jobapply/getbyseekerid/${seekerDetails.id}`,
        { industry: location.state.industry },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.data) {
        setApplyed(response.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (seekerDetails === null || seekerDetails === undefined) {
      navigate(
        `${ROUTINGDATA.LAND}${ROUTINGDATA.MAIN}${ROUTINGDATA.PageNotFound}`
      );
    } else {
      console.log("landing",location)
      if (location.state.from === "LandingPage") {
        getSeekerDetail();
        ApplyedJob();
        setSelectedOptions({
          key_skills: location.state.industry.key_skills,
          job_location: location.state.industry.job_location,
          industry: location.state.industry.industry,
          page_number:1,

        });
        searchResult(location.state.industry);
      } else {
        sethiglightjobs_1(false)
        getSeekerDetail();
        ApplyedJob();
        jobs();  
        getSeekerDetail();
      }
    }
  }, []);
  const moreDetails2 = (description) => {
  
    setOpenMoreDetails2(true);
    setJobDescription2(
      jobDetails1.job_description
    );
 
};
  const moreDetails = (description) => {
    setOpenMoreDetails(true);
    setJobDescription(
      jobDetails.find((item) => item.job_description === description)
    );
  };

  const Apply = async (job) => {
    let res;
    try {
      res = await axiosData.post(
        `jobapply/`,
        {
          job_id: job._id,
          employer_id: job.user_id,
          seeker_id: seekerDetails.id,
          seeker_name: seekerProfileDetails.full_name,
          seeker_email: seekerProfileDetails.email_id,
          seeker_mobile_number: seekerProfileDetails.mobile_number,
          contact_email_id: job.contact_email_id,
          job_title: job.job_title,
          contact_mobile_number:job.phone_number,
          source_from:`Jobpost-${job._id}`,
          fileName:seekerProfileDetails.fileUpload.name
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res.status === 201) {
        if (
          selectedOptions.industry !== null ||
          selectedOptions.key_skills !== null ||
          selectedOptions.job_location !== null
        ) {
          ApplyedJob();
          const temp = description;
          const temp2 = jobDetails;
          setDescription([]);
          setJobDetails([]);
          setJobDetails([...jobDetails]);
          setDescription([...temp]);
        } else {
          ApplyedJob();
          jobs();
        }
        setJobApplied(true);
      }
    } catch (error) {
      if (error.response.status === 403) {
        localStorage.removeItem("seeker");
        setDeclined(true);
      }
    }
  
  };
  const Apply2 = async (job) => {
    let res;
      try {
          res = await axiosData.post(
            `jobapply/`,
            {
              job_id: job._id,
              employer_id: job.user_id,
              seeker_id: seekerDetails.id,
              seeker_name: seekerProfileDetails.full_name,
              seeker_email: seekerProfileDetails.email_id,
              seeker_mobile_number: seekerProfileDetails.mobile_number,
              contact_email_id: job.contact_email_id,
              job_title: job.job_title,
              contact_mobile_number:job.phone_number,
              source_from:`Jobpost-${job._id}`
            },
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          if (res.status === 201) {
            if (
              selectedOptions.industry !== null ||
              selectedOptions.key_skills !== null ||
              selectedOptions.job_location !== null
            ) {
              ApplyedJob();
              const temp = description;
              const temp2 = jobDetails;
              setDescription([]);
              setJobDetails([]);
              setJobDetails([...jobDetails]);
              setDescription([...temp]);
            } else {
              ApplyedJob();
              jobs();
            }
            setJobApplied(true);
          }
        } catch (error) {
          if (error.response.status === 403) {
            localStorage.removeItem("seeker");
            setDeclined(true);
          }
        }
  };
  const match = (Id) => {
    let value = true;
    Applyed.map((values, index) => {
      if (Id === values.job_id) {
        value = false;
      }
    });

    return value;
  };

  const handleSuggestionslocation = async (event) => {
    let query = event.target.value.replace(
      /[^a-z., ]/gi,
      ""
    );

    setSearchQuery(query);
    searchobj(query, "job_location");

    if (query.length >= 3) {
      try {
        const response = await axiosData.get(
          `/jobs/suggestionlocation/${query}`
        );
        setSuggestionArrayl(response.data);
        if (response.data) {
          response.data = response.data.filter((item) => {
            return !!item;
          });
          setSuggestionArrayl([...response.data]);
        } else {
          setSuggestionArrayl([]);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };
  const handleSuggestionsSkills = async (event) => {
    let query = event.target.value;

    if (query.startsWith("#")) {
      query = query.slice(1);
    }
  
    setSuggestionArray([]);
    searchobj(query, "key_skills");
    setSearchQuery(query);
    
    if (query.length >= 3) {
      try {
        const response = await axiosData.get(`/jobs/suggestion/${query}`);
        setSuggestionArray(response.data);
        if (response.data) {
          response.data = response.data.filter((item) => {
            return !!item;
          });
          setSuggestionArray([...response.data]);
        } else {
          setSuggestionArray([]);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };
  const searchobj = (values, string) => {
    selectedOptions[string] = values;
    setSelectedOptions(selectedOptions);

    setSuggestionArray([]);
    setSuggestionArrayl([]);
  };
  const searchResult = async (result) => {
    if (
     (( result.industry === null||result.industry ==="") &&
      (result.key_skills === null||result.key_skills==="") &&
     ( result.job_location === null||result.job_location ===""))&&!result.job_id
    ) {
      setSearchPopUp(true);
    } else {
      if(location.state.industry.job_id&&!result.industry&&!result.job_location ){
        try{
          const response1 = await axiosData.get(`jobs/getOne/${location.state.industry.job_id}`, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          if(response1.status===200){
          result.key_skills=response1.data.job_title;
          selectedOptions.key_skills=response1.data.job_title;
          setSelectedOptions(selectedOptions)
          setJobDetails1(response1.data); 
          setsearchjobid(response1.data.job_id);
          setDescription2(response1.data.job_description);
      try {
        const response = await axiosData.post(`jobs/search`, result, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        if (response.data) {
          const activeJobs  = response.data.data.filter((datas)=>datas.job_status === "Active")
          const filterjobs = activeJobs.filter((items)=>items._id !== response1.data._id);
          setSearchTotalData(response.data.total_record);
          setLoadDisabled(response.data.is_last_record_set);
          setJobDetails([...filterjobs]);
          setDescription([...filterjobs.map((job) => job.description)]);

          setmobilesearch(false);
          setLoading(false);
        }
        if (response.status === 204) {
          setSelectedOptions({
            key_skills: null,
            job_location: null,
            industry: null,
          });
          setSearchTotalData(0);
          setTotalData(0);
          setLoading(false);
          setNoResultPopUp(true);
          setJobDetails([]);
          setDescription([]);
        
       
        }
      } catch (err) {
        console.log(err);
      }}else{
        sethiglightjobs_1(false);
        setSelectedOptions("");
        setSearchTotalData(0);
        setTotalData(0);
        setSelectedOptions({
          key_skills: null,
          job_location: null,
          industry: null,
        });
        setSelectedOptions(selectedOptions);
        setLoading(false);
        setNoResultPopUp(true);
      }
    }catch(err){
    }}else{
      sethiglightjobs_1(false);
      sethiglightjob("");
      try {
        const response = await axiosData.post(`jobs/search`, result, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (response.data) {
          setSearchTotalData(response.data.total_record);
          setLoadDisabled(response.data.is_last_record_set);
          setJobDetails([...response.data.data]);
          setDescription([...response.data.data.map((job) => job.description)]);

          setmobilesearch(false);
          setLoading(false);
        }
        if (response.status === 204) {
          setSelectedOptions({
            key_skills: null,
            job_location: null,
            industry: null,
          });
          setJobDetails([]);
          setDescription([]);
          setSearchTotalData(0);
          setTotalData(0);
          setNoResultPopUp(true);
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
      }
    }
  }
  };

  const handleSearch = () => {
    sethiglightjobs_1(false);
    sethiglightjob("");
    selectedOptions.page_number = 1;
    setSelectedOptions(selectedOptions);
  if(selectedOptions){  searchResult(selectedOptions);}
    setSelectedOptions(selectedOptions);
  };
  const refresh = () => {
    setSuggestionArray([]);
    setSuggestionArrayl([]);
    jobs(false);
  };

  const loadMoreData = async () => {
    setLoadMore(true);
    if (
      selectedOptions.industry === null &&
      selectedOptions.key_skills === null &&
      selectedOptions.job_location === null
    ) {
      setSearchPopUp(true);
    } else {
      try {
        const response = await axiosData.post(`jobs/search`, selectedOptions, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (response.data) {
          setJobDetails([...jobDetails, ...response.data.data]);
          setDescription([
            ...description,
            ...response.data.data.map((job) => job.description),
          ]);

          setLoadDisabled(response.data.is_last_record_set);
          setmobilesearch(false);
          setLoading(false);
        }
        if (response.status === 204) {
          setSearchTotalData("");
          setTotalData(0);
          setSelectedOptions({
            key_skills: null,
            job_location: null,
            industry: null,
          });
          setJobDetails([]);
          setDescription([])
          setLoading(false);
          setNoResultPopUp(true);
        }
      } catch (err) {
        console.log(err);
      }
    }
    setLoadMore(false);
  };
  if (loading === false) {
    return (
      <div>
        <Dialog TransitionComponent={Transition} open={declined}>
          <DialogTitle
            bgcolor="rgb(16 143 178)"
            sx={{ color: "white", fontWeight: 600 }}
          >
            Declined alert !
          </DialogTitle>
          <DialogContent sx={{ mt: 3 }}>
            <DialogContentText sx={{ fontWeight: 600 }}>
              {
                "Your profile is declined by admin so please contact contactus@usglobalsolutions.com."
              }
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setDeclined(false);
                navigate(`${ROUTINGDATA.LAND}`);
              }}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          TransitionComponent={Transition}
          open={openMoreDetails2}
          jobDescription2={jobDescription2}
          // onClose={() => setOpenMoreDetails(false)}
          sx={{ "& .MuiPaper-root": { width: "500px" } }}
        >
          <DialogTitle
            bgcolor="rgb(16 143 178)"
            sx={{ color: "white", fontWeight: 600 }}
          >
            Job Description
          </DialogTitle>
          <DialogContent sx={{ mt: 1 }}>
            {jobDescription2 ? (
              <DialogContentText
                dangerouslySetInnerHTML={{
                  __html: jobDescription2,
                }}
              ></DialogContentText>
            ) : (
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: "16px",
                  ml: 0.5,
                  mt: 2,
                }}
                color="text.secondary"
              >
                No description for this job
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              sx={{ mr: 2, mb: 1 }}
              variant="outlined"
              onClick={() => setOpenMoreDetails2(false)}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        <div
          style={{
            marginTop: "15px",
            marginBottom: "15px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Typography sx={{ fontSize: "28px", fontWeight: 600 }}>
              Recommended Jobs
            </Typography>
          </div>
          <div>
            <Tooltip
              arrow
              onClick={() => {
                navigate("/main/seekerProfile", {
                  state: location.state.email_id,
                });
              }}
              className="GoBackTooltip"
              title="Go Back"
            >
              <IconButton
                className="GoBackTooltip  arrow"
                sx={{ backgroundColor: "#339fcd" }}
              >
                <WestIcon sx={{ color: "#fff" }} />
              </IconButton>
            </Tooltip>
          </div>
        </div>

        <div id="search-part" className="search-part">
          <div className="search-auto" style={{ border: "1px solid #108fb2" }}>
            <Autocomplete
              disablePortal
              value={selectedOptions.key_skills}
              options={suggestionArray}
              onChange={(event, value) => {
                searchobj(value, "key_skills");
              }}
              disableUnderline={false}
              sx={{ width: { xs: 350, md: 330, lg: 350 } }}
              renderInput={(params) => (
                <TextField
                  disableUnderline={false}
                  className="autocom-search-first"
                  sx={{
                    "& fieldset": { border: "none" },
                  }}
                  onChange={handleSuggestionsSkills}
                  {...params}
                  label={"Enter KeySkills / Designations / Companies"}
                  size="small"
                />
              )}
            />
            {industryArray && (
              <Autocomplete
                disablePortal
                filterOptions={filterOptions}
                id="Select_experience"
                name="Select_experience"
                options={industryArray}
                value={selectedOptions.industry}
                onChange={(event, value) => {
                  searchobj(value, "industry");
                }}
                sx={{ width: { xs: 350, md: 180, lg: 300 } }}
                renderInput={(params) => (
                  <TextField
                    className="autocom-search-first"
                    sx={{
                      "& fieldset": { border: "none" },
                    }}
                    value={searchQuery}
                    {...params}
                    label={"Enter Industry"}
                    size="small"
                  />
                )}
              />
            )}
              <Autocomplete
                disablePortal
                id="Enter_location"
                name="Enter_location"
                options={suggestionArrayl}
                onChange={(event, value) => searchobj(value, "job_location")}
                value={selectedOptions.job_location}
                sx={{ width: { xs: 350, md: 180, lg: 300 } }}
                renderInput={(params) => (
                  <TextField
                    className="autocom-search"
                    value={searchQuery}
                    onChange={handleSuggestionslocation}
                    {...params}
                    sx={{
                      "& fieldset": { border: "none" },
                    }}
                    label={"Enter Location"}
                    size="small"
                  />
                )}
              />
            <Button
              sx={{
                borderRadius: "50px",
                padding: "10px 33px",
              }}
              onClick={handleSearch}
              variant="contained"
            >
              {"search"}
            </Button>
          </div>
          <Tooltip
            arrow
            onClick={refresh}
            className="GoBackTooltip"
            title="Refresh"
          >
            <IconButton
              className="GoBackTooltip  arrow"
              sx={{ backgroundColor: "#339fcd" }}
            >
              <RefreshIcon sx={{ color: "#fff" }} />
            </IconButton>
          </Tooltip>
        </div>

        <Box
          className="mobile-view-search"
          sx={{ display: { xs: "flex", md: "none", lg: "none" } }}
        >
          <Button
            variant="outlined"
            className={
              mobilesearch === "true" ? "mobile-search-active" : "mobile-search"
            }
            onClick={() => {
              setmobilesearch(true);
            }}
          >
            <SearchIcon sx={{ color: "#ed1067" }} />
            <p>Search...</p>
          </Button>
        </Box>

        <Drawer
          sx={{
            "& .MuiDrawer-paper": {
              height: "50%",
              background: "rbga(255,255,255,0.8)",
            },
          }}
          open={mobilesearch}
          anchor={"top"}
        >
          <div className="phone-view">
            <div className="mobile-search-a">
              <Autocomplete
                disablePortal
                value={selectedOptions.key_skills}
                options={suggestionArray}
                onChange={(event, value) => {
                  searchobj(value, "key_skills");
                }}
                disableUnderline={false}
                sx={{ width: { xs: 350, md: 330, lg: 350 } }}
                renderInput={(params) => (
                  <TextField
                    disableUnderline={false}
                    className="autocom-search-first"
                    sx={{
                      "& fieldset": { border: "none" },
                    }}
                    value={searchQuery}
                    onChange={handleSuggestionsSkills}
                    {...params}
                    label={"Enter KeySkills / Designations / Companies"}
                    size="small"
                  />
                )}
              />
              {industryArray && (
                <Autocomplete
                  disablePortal
                  id="Select_experience"
                  name="Select_experience"
                  filterOptions={filterOptions}
                  options={industryArray}
                  value={selectedOptions.industry}
                  onChange={(event, value) => {
                    searchobj(value, "industry");
                  }}
                  sx={{ width: { xs: 350, md: 180, lg: 300 } }}
                  renderInput={(params) => (
                    <TextField
                      className="autocom-search-first"
                      sx={{
                        "& fieldset": { border: "none" },
                      }}
                      value={searchQuery}
                      {...params}
                      label={"Enter Industry"}
                      size="small"
                    />
                  )}
                />
              )}
              
              <Autocomplete
                disablePortal
                id="Enter_location"
                name="Enter_location"
                options={suggestionArrayl}
                onChange={(event, value) => searchobj(value, "job_location")}
                value={selectedOptions.job_location}
                sx={{ width: { xs: 350, md: 180, lg: 300 } }}
                renderInput={(params) => (
                  <TextField
                    className="autocom-search"
                    value={searchQuery}
                    onChange={handleSuggestionslocation}
                    {...params}
                    sx={{
                      "& fieldset": { border: "none" },
                    }}
                    label={"Enter Location"}
                    size="small"
                  />
                )}
              />
              <div style={{ display: "flex" }}>
                <Button
                  sx={{
                    borderRadius: "50px",
                    padding: "10px 33px",
                    mr: 2,
                  }}
                  onClick={handleSearch}
                  variant="contained"
                >
                  {"search"}
                </Button>
                <Tooltip
                  arrow
                  onClick={refresh}
                  className="GoBackTooltip"
                  title="Refresh"
                >
                  <IconButton
                    className="GoBackTooltip  arrow"
                    sx={{ backgroundColor: "#339fcd" }}
                  >
                    <RefreshIcon sx={{ color: "#fff" }} />
                  </IconButton>
                </Tooltip>
              </div>
              <div className="mobile-close-btn">
                <IconButton
                  onClick={() => {
                    setmobilesearch(false);
                  }}
                >
                  <CloseIcon sx={{ color: "red" }} />
                </IconButton>
              </div>
            </div>
          </div>
        </Drawer>

        <Dialog
          TransitionComponent={Transition}
          open={noResultPopUp}
          sx={{ "& .MuiPaper-root": { color: "white", width: "400px" } }}
        >
          <DialogTitle
            bgcolor="rgb(16 143 178)"
            sx={{ backgroundColor: "rgb(16 143 178)", fontWeight: 600 }}
          >
            Job Search
          </DialogTitle>
          <DialogContent sx={{ mt: 3 }}>
            <DialogContentText sx={{ fontWeight: 600 }}>
              No Jobs for your search
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => {
                  setSuggestionArray([]);
                  setSuggestionArrayl([]);
                  setSelectedOptions({
                    key_skills: null,
                    job_location: null,
                    industry: null,
                  });
                 
                  setNoResultPopUp(false);
                
              }}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          TransitionComponent={Transition}
          open={jobApplied}
          sx={{ "& .MuiPaper-root": { width: "300px" } }}
        >
          <DialogTitle
            bgcolor="rgb(16 143 178)"
            sx={{ color: "white", fontWeight: 600 }}
          >
            Job Apply
          </DialogTitle>
          <DialogContent sx={{ mt: 3 }}>
            <DialogContentText sx={{ fontWeight: 600 }}>
              Your job applied successfully!.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => setJobApplied(false)}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          TransitionComponent={Transition}
          open={openMoreDetails}
          jobDescription={jobDescription}
          sx={{ "& .MuiPaper-root": { width: "500px" } }}
        >
          <DialogTitle
            bgcolor="rgb(16 143 178)"
            sx={{ color: "white", fontWeight: 600 }}
          >
            Job Description
          </DialogTitle>
          <DialogContent sx={{ mt: 1 }}>
            {jobDescription.job_description ? (
              <DialogContentText
                dangerouslySetInnerHTML={{
                  __html: jobDescription.job_description,
                }}
              ></DialogContentText>
            ) : (
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: "16px",
                  ml: 0.5,
                  mt: 2,
                }}
                color="text.secondary"
              >
                No description for this job
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              sx={{ mr: 2, mb: 1 }}
              variant="outlined"
              onClick={() => 
                
                setOpenMoreDetails(false)}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          TransitionComponent={Transition}
          open={searchPopUp}
          sx={{ "& .MuiPaper-root": { color: "white", width: "400px" } }}
        >
          <DialogTitle
            bgcolor="rgb(16 143 178)"
            sx={{ backgroundColor: "rgb(16 143 178)", fontWeight: 600 }}
          >
            Search
          </DialogTitle>
          <DialogContent sx={{ mt: 5, mb: 2 }}>
            <DialogContentText sx={{ fontWeight: 600 }}>
              Atleast one field is requried for search !
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => setSearchPopUp(false)}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        <Typography
          sx={{ fontSize: "18px", ml: 1, mb: 1, mt: -4, fontWeight: 500 }}
        >
          <b style={{ fontSize: "22px", marginRight: "5px" }}>
            {searchTotalData ? searchTotalData : totalData}
          </b>
          Search data found
        </Typography>
        <div className="jobFullContend">
          <div className="recommendedJobCard">
          {higlightjobs_1?( 
         <Card
                className="recommendedJob-InnerCard"
                key={jobDetails1._id}
                sx={{ mt: 2 ,border:"5px solid #ed1e70",display:higlightjobs_1?"block":"none"}}
              >
                <div className="ApplyCard" sx={{display:higlightjobs_1?"block":"none"}}>
                  <CardContent>
                  {jobDetails1.job_status === "InActive" ? (
                      <div className="AppliedDiv">
                        <Chip
                          className="AppliedButton"
                          sx={{ ml: 1, mr: -3, mt: -3.5 }}
                          label="Expired"
                          size="small"
                          color="error"
                          variant="outlined"
                        />
                      </div>
                    ) : (
                      <div className="AppliedDiv">
                        {!match(jobDetails1._id) && (
                          <Chip
                            className="AppliedButton"
                            sx={{ ml: 1, mr: -3, mt: -3.5 }}
                            label="Applied"
                            size="small"
                            color="primary"
                            variant="outlined"
                          />
                        )}
                      </div>
                    )}
                    <Typography className="nameTitleServiceProvider" sx={{ fontWeight: 600, fontSize: "26px",
                                  wordWrap: "break-word",
                                  lineHeight: "2em",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  transition: "0.5s",
                                  backgroundColor: "#22668a",
                                  color: "white",
                                  padding: "0px 5px",
                                  textTransform: "uppercase",
                                  borderRadius: "5px",
                                  marginBottom:"10px"
                              }}>
                    {jobDetails1.job_title}
                    </Typography>

                    <Grid container>
                      <Grid lg={9} md={9} sm={9} xs={9}>
                        <Typography
                          gutterBottom
                          sx={{ fontWeight: 600, fontSize: "20px" }}
                        >
                          {jobDetails1.company_name}
                        </Typography>
                        <Grid lg={10} md={10} sm={10} xs={10}>
                          <Typography
                            gutterBottom
                            sx={{
                              fontWeight: 600,
                              fontSize: "14px",
                              ml: 0.4,
                              wordWrap: "break-word",
                            }}
                            color="text.secondary"
                          >
                            {jobDetails1.industry}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        className="CompanySideLogo"
                        lg={3}
                        md={3}
                        sm={2}
                        xs={2}
                      >
                        <img
                          alt=""
                          id="employerProfile"
                          src={
                            jobDetails1.employer_email_id
                              ? `${baseUrl}employers/logo/email/${jobDetails1.employer_email_id}/users`
                              : employerProfile
                          }
                          onError={(e)=>{
                            e.target.src=employerProfile
                          }}
                          className="jobImgLogo"
                        />
                      </Grid>
                    </Grid>

                    <div className="JobsAlignment">
                      <div>
                        <Typography
                          className="JobsNameAlignment"
                          gutterBottom
                          sx={{ fontWeight: 600, fontSize: "14px", mr: 1 }}
                        >
                          <text>Skills</text>
                          <text>:</text>
                        </Typography>
                      </div>
                      <div>
                        <Grid lg={12} md={12} sm={12} xs={12}>
                          <Typography
                            gutterBottom
                            sx={{
                              fontWeight: 600,
                              fontSize: "14px",
                              wordWrap: "break-word",
                            }}
                            color="text.secondary"
                          >
                            {jobDetails1.key_skills || null}
                          </Typography>
                        </Grid>
                      </div>
                    </div>
                    <div className="JobsAlignment">
                      <div>
                        <Typography
                          className="JobsNameAlignment"
                          gutterBottom
                          sx={{ fontWeight: 600, fontSize: "14px", mr: 1 }}
                        >
                          <text>Experience</text>
                          <text>:</text>
                        </Typography>
                      </div>
                      <div>
                        <Typography
                          gutterBottom
                          sx={{ fontWeight: 600, fontSize: "14px" }}
                          color="text.secondary"
                        >
                          { jobDetails1.experience_type === "Freshers Only" ? "Freshers Only" : `${jobDetails1.experience_min}${"-"}${jobDetails1.experience_max}`}
                        </Typography>
                      </div>
                    </div>
                    <div className="JobsAlignment">
                      <div>
                        <Typography
                          className="JobsNameAlignment"
                          gutterBottom
                          sx={{ fontWeight: 600, fontSize: "14px", mr: 1 }}
                        >
                          <text>Salary</text>
                          <text>:</text>
                        </Typography>
                      </div>
                      <div>
                        <Typography
                          gutterBottom
                          sx={{ fontWeight: 600, fontSize: "14px",wordWrap: "break-word",
                          lineHeight: "2em",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          transition: "0.5s",
                          backgroundColor: "#22668a",
                          color: "white",
                          padding: "0px 5px",
                          textTransform: "uppercase",
                          borderRadius: "5px" }}
                          color="text.secondary"
                        >
                          {jobDetails1.salary_range_max
                            ? `${jobDetails1.salary_range_min}${"-"}${
                              jobDetails1.salary_range_max
                              }${jobDetails1.salary_type === "Month"
                              ? " Per Month"
                              : jobDetails1.salary_type === "Year"
                              ? " LPA"
                              : null}`
                            : "Not Disclosed"}
                        </Typography>
                      </div>
                    </div>
                    <div className="JobsAlignment">
                      <div>
                        <Typography
                          className="JobsNameAlignment"
                          gutterBottom
                          sx={{ fontWeight: 600, fontSize: "14px", mr: 1 }}
                        >
                          <text>Location</text>
                          <text>:</text>
                        </Typography>
                      </div>
                      <div>
                        <Typography
                          gutterBottom
                          sx={{ fontWeight: 600, fontSize: "14px" }}
                          color="text.secondary"
                        >
                          {jobDetails1.job_location}
                        </Typography>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        marginTop: "5px",
                        marginBottom: "30px",
                      }}
                    >
                      <DescriptionIcon
                        fontSize="small"
                        sx={{ color: "rgb(145 145 145)" }}
                      />
                      {jobDetails1.job_description ? (
                        <Typography
                          className="JobDescriptionOverflow"
                          gutterBottom
                          sx={{
                            fontWeight: 600,
                            fontSize: "14px",
                            ml: 0.5,
                            mt: -1.8,
                          }}
                          color="text.secondary"
                          dangerouslySetInnerHTML={{
                            __html: renderParagraphs(jobDetails1.job_description),
                          }}
                        ></Typography>
                      ) : (
                        <Typography
                          sx={{
                            fontWeight: 600,
                            fontSize: "14px",
                            ml: 0.5,
                          }}
                          color="text.secondary"
                        >
                          No description for this job
                        </Typography>
                      )}
                    </div>
                  </CardContent>

                  <div className="ApplyButton">
                    <CardActions
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        width: "99%",
                        marginTop: "20px",
                      }}
                    >
                      <Button
                        onClick={() => {
                          moreDetails2(jobDetails1.job_description);
                        }}
                        sx={{ ml: 1, mr: 1 }}
                        variant="outlined"
                        color="success"
                        size="small"
                      >
                        View More
                      </Button>

                      {match(jobDetails1._id) &&
                        (jobDetails1.job_status === "InActive" ? (
                          <Button disabled variant="contained" size="small">
                            Apply
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            size="small"
                            onClick={() => {
                              Apply2(jobDetails1);
                            }}
                          >
                            Apply
                          </Button>
                        ))}
                    </CardActions>
                  </div>
                </div>
              </Card>):null}
            {jobDetails.map((jobs, index) => (
              <Card className="recommendedJob-InnerCard" key={jobs._id}>
                <div className="ApplyCard">
                  <CardContent>
                    {jobs.job_status === "InActive" ? (
                      <div className="AppliedDiv">
                        <Chip
                          className="AppliedButton"
                          sx={{ ml: 1, mr: -3, mt: -3.5 }}
                          label="Expired"
                          size="small"
                          color="error"
                          variant="outlined"
                        />
                      </div>
                    ) : (
                      <div className="AppliedDiv">
                        {!match(jobs._id) && (
                          <Chip
                            className="AppliedButton"
                            sx={{ ml: 1, mr: -3, mt: -3.5 }}
                            label="Applied"
                            size="small"
                            color="primary"
                            variant="outlined"
                          />
                        )}
                      </div>
                    )}
                    <Typography className="nameTitleServiceProvider" sx={{ fontWeight: 600, fontSize: "26px", wordWrap: "break-word",
                                  lineHeight: "2em",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  transition: "0.5s",
                                  backgroundColor: "#22668a",
                                  color: "white",
                                  padding: "0px 5px",
                                  textTransform: "uppercase",
                                  borderRadius: "5px",
                                  marginBottom:"10px" }}>
                      {jobs.job_title}
                    </Typography>

                    <Grid container>
                      <Grid lg={9} md={9} sm={9} xs={9}>
                        <Typography
                          gutterBottom
                          sx={{ fontWeight: 600, fontSize: "20px" }}
                        >
                          {jobs.company_name}
                        </Typography>
                        <Grid lg={10} md={10} sm={10} xs={10}>
                          <Typography
                            gutterBottom
                            sx={{
                              fontWeight: 600,
                              fontSize: "14px",
                              ml: 0.4,
                              wordWrap: "break-word",
                            }}
                            color="text.secondary"
                          >
                            {jobs.industry}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        className="CompanySideLogo"
                        lg={3}
                        md={3}
                        sm={2}
                        xs={2}
                      >
                        <img
                          alt=""
                          id="employerProfile"
                          src={
                            jobs.employer_email_id
                              ? `${baseUrl}employers/logo/email/${jobs.employer_email_id}/users`
                              : null
                          }
                          onError={(e)=>{
                             e.target.src=employerProfile
                          }}
                          className="jobImgLogo"
                        />
                      </Grid>
                    </Grid>

                    <div className="JobsAlignment">
                      <div>
                        <Typography
                          className="JobsNameAlignment"
                          gutterBottom
                          sx={{ fontWeight: 600, fontSize: "14px", mr: 1 }}
                        >
                          <text>Skills</text>
                          <text>:</text>
                        </Typography>
                      </div>
                      <div>
                        <Grid lg={12} md={12} sm={12} xs={12}>
                          <Typography
                            gutterBottom
                            sx={{
                              fontWeight: 600,
                              fontSize: "14px",
                              wordWrap: "break-word",
                            }}
                            color="text.secondary"
                          >
                            {jobs.key_skills&&jobs.key_skills.trim().replace(/,$/, '.') || null}
                          </Typography>
                        </Grid>
                      </div>
                    </div>
                    <div className="JobsAlignment">
                      <div>
                        <Typography
                          className="JobsNameAlignment"
                          gutterBottom
                          sx={{ fontWeight: 600, fontSize: "14px", mr: 1 }}
                        >
                          <text>Experience</text>
                          <text>:</text>
                        </Typography>
                      </div>
                      <div>
                        <Typography
                          gutterBottom
                          sx={{ fontWeight: 600, fontSize: "14px" }}
                          color="text.secondary"
                        >
                        { jobs.experience_type === "Freshers Only" ? "Freshers Only" : `${jobs.experience_min}${"-"}${jobs.experience_max}`}
                        </Typography>
                      </div>
                    </div>
                    <div className="JobsAlignment">
                      <div>
                        <Typography
                          className="JobsNameAlignment"
                          gutterBottom
                          sx={{ fontWeight: 600, fontSize: "14px", mr: 1 }}
                        >
                          <text>Salary</text>
                          <text>:</text>
                        </Typography>
                      </div>
                      <div>
                        <Typography
                          gutterBottom
                          sx={{ fontWeight: 600, fontSize: "14px" , wordWrap: "break-word",
                          lineHeight: "2em",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          transition: "0.5s",
                          backgroundColor: "#22668a",
                          color: "white",
                          padding: "0px 5px",
                          textTransform: "uppercase",
                          borderRadius: "5px"}}
                          color="text.secondary"
                        >
                        { jobs.salary_range_max  ? `${jobs.salary_range_min}${"-"}${jobs.salary_range_max}${jobs.salary_type === "Month"
                                  ? " Per Month"
                                  : jobs.salary_type === "Year"
                                  ? " LPA"
                                  : null}` : "Not Disclosed"}
                        </Typography>
                      </div>
                    </div>
                    <div className="JobsAlignment">
                      <div>
                        <Typography
                          className="JobsNameAlignment"
                          gutterBottom
                          sx={{ fontWeight: 600, fontSize: "14px", mr: 1 }}
                        >
                          <text>Location</text>
                          <text>:</text>
                        </Typography>
                      </div>
                      <div>
                        <Typography
                          gutterBottom
                          sx={{ fontWeight: 600, fontSize: "14px" }}
                          color="text.secondary"
                        >
                          {jobs.job_location}
                        </Typography>
                      </div>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        marginTop: "5px",
                        marginBottom: "30px",
                      }}
                    >
                      <DescriptionIcon
                        fontSize="small"
                        sx={{ color: "rgb(145 145 145)" }}
                      />
                      {jobs.job_description ? (
                        <Typography
                          className="JobDescriptionOverflow"
                          gutterBottom
                          sx={{
                            fontWeight: 600,
                            fontSize: "14px",
                            ml: 0.5,
                            mt: -1.8,
                          }}
                          color="text.secondary"
                          dangerouslySetInnerHTML={{
                            __html: renderParagraphs(jobs.job_description),
                          }}
                        ></Typography>
                      ) : (
                        <Typography
                          sx={{
                            fontWeight: 600,
                            fontSize: "14px",
                            ml: 0.5,
                          }}
                          color="text.secondary"
                        >
                          No description for this job
                        </Typography>
                      )}
                    </div>
                  </CardContent>
                  <div className="ApplyButton">
                    <CardActions
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        width: "99%",
                        marginTop: "20px",
                      }}
                    >
                      <Button
                        onClick={() => {
                          moreDetails(jobs.job_description);
                        }}
                        sx={{ ml: 1, mr: 1 }}
                        variant="outlined"
                        color="success"
                        size="small"
                      >
                        View More
                      </Button>

                      {match(jobs._id) &&
                        (jobs.job_status === "InActive" ? (
                          <Button disabled variant="contained" size="small">
                            Apply
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            size="small"
                            onClick={() => {
                              Apply(jobs);
                            }}
                          >
                            Apply
                          </Button>
                        ))}
                    </CardActions>
                  </div>
                </div>
              </Card>
            ))}
          </div>
          <div style={{ width: "98%", textAlign: "right" }}>
            <Button
              disabled={loadDisabled}
              onClick={() => {
                selectedOptions.page_number++;
                setSelectedOptions(selectedOptions);
                loadMoreData(selectedOptions);
              }}
              sx={{ mb: 2 }}
              variant="contained"
            >
              Load more
              {loadMore && (
                <CircularProgress
                  size={24}
                  style={{
                    color: "#ffffff",
                    marginLeft: 12,
                  }}
                />
              )}
            </Button>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div
        style={{
          height: "70vh",
          display: "grid",
          placeItems: "center",
        }}
      >
        <div class="dots"></div>
      </div>
    );
  }
}

export default RecommendedJob;
 