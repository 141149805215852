import React, { useState, useEffect } from "react";
import {
  Table,
  TableContainer,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  Card,
  IconButton,
  Tooltip,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  Paper,
  Chip,
  TableSortLabel,
  Box,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Fab,
  InputLabel,
  ClickAwayListener,
  Skeleton,
  Button,
  FormLabel,
  Autocomplete,
  Popper,
} from "@mui/material";
import useAxios from "../../../api/useAxios";
import { useNavigate } from "react-router-dom";
import {
  OpenInNewRounded as OpenInNewRoundedIcon,
  LinkedIn as LinkedInIcon,
  CheckRounded as CheckRoundedIcon,
  FileDownload as FileDownloadIcon,
  RefreshSharp as RefreshSharpIcon,
  VideocamRounded as VideocamRoundedIcon,
  CloseRounded as CloseRoundedIcon,
  ShareLocationRounded as ShareLocationRoundedIcon,
  InfoOutlined as InfoOutlinedIcon,
} from "@mui/icons-material/";

import { saveAs } from "file-saver";
import defaultimg from "../../../img/mentorImage.png";

import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import CloseIcon from "@mui/icons-material/Close";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import InvestorDialog from "./DialogBoxes/InvestorDialog";
import moment from "moment/moment";

import StatCards from "./StatCards/StatCards";

import "jspdf-autotable";
import Breadcrumb from "../../Dashboards/BreadCrumb";
import SkeletonMlaCard from "./StatCards/SkeletonMlaCard";
import ExportCS from "./ExportComponents/ExportCS";
import ExportPDF from "./ExportComponents/ExportPDF";
import { useContext } from "react";
import { CurrentStateContext } from "../../../App";
import AutoApproveSnack from "./SnackBars/AutoApproveSnack";
import PartnerInvestorStatCard from "./StatCards/PartnerInvestorStatCard";
import PincodeAllocateDialog from "./DialogBoxes/PincodeAllocateDialog";
import AllocatePinSnack from "./SnackBars/AllocatePinSnack";
import { ROUTINGDATA } from "../../ROUTINGDATA";

function AdminInvestors() {
  const [getApi, setGetApi] = useState([]);
  const [useApi, setUseApi] = useState([]);
  const [page, setPage] = useState(0);
  const [decide, setDecide] = useState(false);
  const [dateRange, setDateRange] = useState({
    startDate: "",
    endDate: "",
    openTooltip: false,
    openStartTool: false,
  });

  const [loading, setLoading] = useState({ isLoading: true, dialogLoad: true });

  const [totalRows, setTotalRows] = useState(0);
  const [open, setOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [getOne, setGetOne] = useState({});
  const [searchdata, setsearchdata] = useState("");
  const [startdisabled, setstartdisabled] = useState(false);
  const [enddisabled, setenddisabled] = useState(false);
  const [dateenable, setdateenable] = useState(false);
  // <!----SORt--->
  const [sortedData, setSortedData] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [isFocused, setIsFocused] = useState({
    startDate: false,
    endDate: false,
  });

  // <!---DATEINPUTSORT--->
  // const [startDate, setStartDate] = useState(null);
  // const [endDate, setEndDate] = useState(null);

  const [searchicon, setsearchicon] = useState(false);

  const [filterName, setFilterName] = useState({
    date: "today",
    status: "all",
    isLoading: false,
  });
  const [starterror, setstartError] = useState(null);
  const [enderror, setendError] = useState(null);
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());
  const [pinSnack, setPinSnack] = useState(false);
  const snack = JSON.parse(sessionStorage.getItem("seeker-setting"));
  let adminDetails_CEO = JSON.parse(localStorage.getItem("admin_dev"));
  const [openSnack, setOpenSnack] = useState(snack);
  // const setGe
  const { getAdminSetting } = useContext(CurrentStateContext);
  const todate = moment();
  const todayDate = moment().format("DD-MM-YYYY");
  const yesterdayDate = moment().subtract(1, "day").format("DD-MM-YYYY");
  const adminSeekerSearch = sessionStorage.getItem("admin_seeker_search");
  const navigate = useNavigate();
  const selectedApiForExcel = useApi.map((i) => {
    return {
      "Full Name": i.full_name,
      Email: i.email_id,
      Mobile: i.mobile_number,
      Industry: i.industry,
      "Current Location": i.currentLocation,
      "Voting District": i.votingDistrict,
      "MP Constituency": i.mpConstituency,
      "MLA Constituency": i.mlaConstituency,
      "Created Date": i.createdDate,
      Experience: i.Experience,
      "Pin-code": i.pincode,
    };
  });
  const headers = [
    [
      "Full Name",
      "Email",
      "Mobile",

      "Industry",
      "Current Location",
      "Voting District",
      "MP Constituency",
      "MLA Constituency",

      "Created Date",
    ],
  ];
  const datas = useApi.map((user) => {
    return [
      user.full_name,
      user.email_id,
      user.mobile_number,

      user.industry,
      user.currentLocation,
      user.votingDistrict,
      user.mpConstituency,
      user.mlaConstituency,

      user.createdDate,
    ];
  });
  const handleChange = () => {
    setOpen(!open);
    setGetOne({});
    setLoading({ ...loading, dialogLoad: true });
  };
  const handleToolClose = () => {
    setDateRange({
      ...dateRange,
      openTooltip: false,
      endDate: "",
    });
  };
  const handleToolStartClose = () => {
    setDateRange({
      ...dateRange,
      startDate: "",
      openStartTool: false,
    });
  };

  const axiosData = useAxios();
  const baseUrl = axiosData.defaults.baseURL;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // <!----GET--->

  const fetchApi = async () => {
    try {
      const useAxiosData = await axiosData.get("investors");
      const { data } = useAxiosData;
      if (useAxiosData.status === 204) {
        setGetApi([]);
      } else {
        data.reverse();
        let bool = false;
        if (adminSeekerSearch !== null) {
          bool = adminSeekerSearch.length > 0;
        }
        if (true === bool) {
          searchdatas(adminSeekerSearch, data);
          setGetApi(data);
          setsearchicon(true);
        } else {
          setGetApi(data);
          getFilterValue(
            filterName.status,
            filterName.date,
            data,
            district_name
          );
        }
      }

      // setGetApi(data);

      setLoading({ ...loading, isLoading: false });
    } catch (err) {
      console.log("Error", err);
    }
  };
  const handleStartFocusChange = () => {
    setIsFocused({ ...isFocused, startDate: true });
  };
  const handleEndFocusChange = () => {
    setIsFocused({ ...isFocused, endDate: true });
  };
  const handleStartDateBlurChange = () => {
    setIsFocused({ ...isFocused, startDate: false });
    if (!startDate) {
      const today = moment();
      setStartDate(today);
    }
  };
  const handleEndDateBlurChange = () => {
    setIsFocused({ ...isFocused, endDate: true });
    if (!endDate) {
      const today = moment();
      setEndDate(today);
    }
  };
  // <!-----API FUNCTIONS---->
  const getOneData = async (id) => {
    try {
      const getOneAxios = await axiosData.get(`investors/${id}`);
      const { data } = getOneAxios;

      setGetOne(data);
      setLoading({ ...loading, dialogLoad: false });
    } catch (err) {
      console.log("Error", err);
    }
  };
  const minDate = moment("01-06-2023", "DD-MM-YYYY");
  const maxDate = moment();
  const starterrorMessage = React.useMemo(() => {
    switch (starterror) {
      case "maxDate": {
        return `Please select a date before the end date`;
      }
      case "minDate": {
        return `Please select a date before the end date`;
      }
      case "invalidDate": {
        return "Invalid date range";
      }
      default: {
        return "";
      }
    }
  }, [starterror]);
  const enderrorMessage = React.useMemo(() => {
    switch (enderror) {
      case "maxDate": {
        return `Please select today date or before the today date`;
      }
      case "minDate": {
        return `Please select a date after the start date`;
      }
      case "invalidDate": {
        return "Invalid date range";
      }
      default: {
        return "";
      }
    }
  }, [enderror]);
  // <!----FILE-DOWNLOAD-->
  const downloadResume = (id) => {
    saveAs(`${baseUrl}seekers/resume/${id}`, id + "Resume");
  };
  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    const sortedData = useApi.sort((a, b) => {
      const valueA = a[property];
      const valueB = b[property];
      // return valueB - valueA;
      // Sort the rows with the same value in the primary column before others
      if (valueA === valueB) {
        return -1;
      }
      // Sort the rows based on the selected column
      if (valueA < valueB) {
        return isAsc ? -1 : 1;
      }
      if (valueA > valueB) {
        return isAsc ? 1 : -1;
      }
      return 0;
    });
    setSortedData(sortedData);
  };

  const getFilterValue = (statusvalue, value, alldatas, districtValue) => {
    if (value === "daterange") {
      const get = alldatas.filter((i) => i.createdDate === todayDate);
      statusForAll(statusvalue, get, districtValue);
      setdateenable(true);
    } else if (value === "today") {
      const get = alldatas.filter((i) => i.createdDate === todayDate);
      statusForAll(statusvalue, get, districtValue);
    } else if (value === "yesterday") {
      const yes = alldatas.filter((i) => i.createdDate === yesterdayDate);
      statusForAll(statusvalue, yes, districtValue);
    } else if (value === "week") {
      const startWeek = moment().subtract(1, "weeks").startOf("week");
      const endWeek = moment().subtract(1, "weeks").endOf("week");
      function getDatesInRange(startDate, endDate) {
        const date = new Date(startDate.getTime());
        const dates = [];
        while (date <= endDate) {
          dates.push(moment(date).format("MM/DD/YYYY"));
          date.setDate(date.getDate() + 1);
        }
        return dates;
      }
      const d1 = new Date(startWeek);
      const d2 = new Date(endWeek);

      const weekvalue = getDatesInRange(d1, d2);

      const resDates = weekvalue.map((getdate) => {
        const getDate2 = getdate;
        const getDate3 = getDate2
          .split("/")
          .map((e) => (e[0] === "0" ? e.slice() : e));
        const getDateFormat =
          getDate3[1] + "-" + getDate3[0] + "-" + getDate3[2];

        return getDateFormat;
      });

      const dataapp = [];
      resDates.map((datas) => {
        const dd = alldatas.filter((ite) => ite.createdDate === datas);
        const filterdat = dd.map((item) => dataapp.push(item));
        return filterdat;
      });

      const week = dataapp;

      statusForAll(statusvalue, week, districtValue);
    } else if (value === "month") {
      var currentMonth = moment(todate).format("MM");
      var currentyear = moment(todate).format("YYYY");

      const minsmonth = currentMonth - 1;
      if (currentMonth === "01") {
        var call = currentyear - 1;
        const endOfMonth = moment(
          `${currentyear}-${currentMonth}`,
          "YYYY-MM"
        ).endOf("month");
        const endofdate = endOfMonth.format("DD-MM-YYYY");
        const mon = alldatas.filter((item) =>
          moment(item.createdDate).isBetween(
            `01-12-${call}`,
            endofdate,
            null,
            "[]"
          )
        );
        statusForAll(statusvalue, mon, districtValue);
      } else {
        const endOfMonth = moment(
          `${currentyear}-${minsmonth}`,
          "DD-MM-YYYY"
        ).endOf("month");
        const startOfMonth = moment(
          `${currentyear}-${minsmonth}`,
          "YYYY-MM"
        ).startOf("month");
        const startOfdate = startOfMonth.format("DD-MM-YYYY");
        const date1 = moment(startOfdate, "DD-MM-YYYY");
        const endofdate = endOfMonth.format("DD-MM-YYYY");
        const date2 = moment(endofdate, "DD-MM-YYYY");
        const mon = alldatas.filter((item) => {
          const date = moment(item.createdDate, "DD-MM-YYYY");
          return moment(date).isBetween(date1, date2, null, "[]");
        });
        statusForAll(statusvalue, mon, districtValue);
      }
    } else if (value === "Total") {
      statusForAll(statusvalue, alldatas, districtValue);
    }
  };
  // const n=["\"]
  const statusForAll = (statusvalue, get, dis) => {
    if (statusvalue === "all") {
      const data = [...get];
      if (dis === "") {
        setUseApi(data);
        setTotalRows(data.length);
        setPage(0);
      } else {
        districtFilter(data, dis);
      }
    } else if (
      statusvalue === "S" ||
      statusvalue === "P" ||
      statusvalue === "D" ||
      statusvalue === "H"
    ) {
      const today = get.filter((i) => i.status === statusvalue);
      const data = today;
      if (dis === "") {
        setUseApi(data);
        setTotalRows(data.length);
        setPage(0);
      } else {
        districtFilter(data, dis);
      }
    }
  };
  const districtFilter = (data, dis) => {
    const dist = data.filter((i) => i.district_name === dis);
    setUseApi(dist);
    setTotalRows(dist.length);
    setPage(0);
  };
  const handleStartDateChange = (date) => {
    if (searchicon === true) {
      setsearchicon(false);
      setsearchdata("");
      sessionStorage.setItem("admin_seeker_search", "");
    }
    if (startDate === null) {
      setstartdisabled(false);
    }
    setStartDate(date);
    setstartError(null);
  };
  const handleKeyDownChange = (e) => {
    ["ArrowUp", "ArrowDown"].includes(e.key) && e.preventDefault();
  };
  const handleEndDateChange = (date) => {
    if (searchicon === true) {
      setsearchicon(false);
      setsearchdata("");
      sessionStorage.setItem("admin_seeker_search", "");
    }
    if (endDate === null) {
      setenddisabled(false);
    }
    setEndDate(date);
    setendError(null);
  };
  const handleClickFilter = (statusvalue) => {
    const filteredData = getApi.filter((d) => {
      const date = moment(d.createdDate, "DD-MM-YYYY");
      const start = moment(startDate).subtract(1, "days");
      return date.isBetween(start, endDate, null, "[]");
    });
    statusForAll(statusvalue, filteredData);
  };

  const handleClickRefresh = () => {
    setsearchdata("");
    setStartDate(moment());
    setEndDate(moment());
    setFilterName({
      ...filterName,
      date: "today",
      status: "all",
    });
    setstartdisabled(false);
    setenddisabled(false);
    setstartError(null);
    setendError(null);
    setDistrict_name("");
    getFilterValue("all", "today", getApi, "");
  };

  const closeFunctionCall = () => {
    setStartDate(moment());
    setEndDate(moment());
    setFilterName({
      ...filterName,
      date: "today",
      status: "all",
    });

    getFilterValue("all", "today", getApi, district_name);
  };
  const [getId, setGetId] = useState("");
  const [pinLoad, setPinLoad] = useState(false);
  const [district_name, setDistrict_name] = useState("");
  const [getPincode, setGetPincode] = useState([]);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [allocatePinDialog, setAllocatePinDialog] = useState(false);
  const [partnerN, setPartnerN] = useState("");
  const updateAllocatePincode = async (id, allP, selectpinOpt, selectByPin) => {
    setPinLoad(true);
    const setforupload = selectpinOpt === "single" ? selectByPin : allP;
    try {
      await axiosData.put(`investors/${id}`, {
        allocate_pincode_postOffice: setforupload,
      });
      const pinarray = setforupload.map((i) => {
        return i.pincode;
      });

      const villarray = setforupload.flatMap((i) => {
        return i.village_name;
      });

      await axiosData.put("pincodeAllocation/investor/updatepincode", {
        district: district_name,
        pincode: pinarray,
        postOffice: villarray,
      });
      setTimeout(() => {
        setGetPincode([]);
        // setDistrict_name("");
      }, 4000);
      setOpenConfirm(false);
      setPinLoad(false);
      setAllocatePinDialog(false);
      getPincodeByDistrict(district_name);
      fetchApi();
      setPinSnack(true);
    } catch (err) {}
  };
  const [pincodeByDistrict, setPincodeByDistrict] = useState([]);
  const [areaNameByDistrict, setAreaNameByDistrict] = useState([]);
  const [pincodeAreaData, setPincodeAreaData] = useState([]);
  const [districtData, setDistrictData] = useState([]);
  const getPincodeByDistrict = async (value) => {
    try {
      const pin = await axiosData(`uniqueallocation/investors/${value}`);
      const { data } = pin;
      if (data) {
        // setPincodeByDistrict(getpin[0]);
        // setAreaNameByDistrict(getpost[0]);
        setPincodeAreaData(data[0].Pincode);
      }
    } catch (err) {}
  };
  const filterOptions = (options, { inputValue }) => {
    const inputValueLower = inputValue.toLowerCase();
    const startsWithInput = [];
    const containsInput = [];

    options.forEach((option) => {
      const optionLower = option.toLowerCase();
      if (optionLower.startsWith(inputValueLower)) {
        startsWithInput.push(option);
      } else if (optionLower.includes(inputValueLower)) {
        containsInput.push(option);
      }
    });

    return [...startsWithInput, ...containsInput];
  };
  const vodingDistrict = async () => {
    try {
      const getOneAxios = await axiosData.get(`/distritarea`);
      const data = getOneAxios.data;
      if (data) {
        const getDistrict = data.map((i) => {
          return i.District;
        });
        getDistrict.sort();
        setDistrictData(getDistrict);
      }
      return data;
    } catch (err) {
      console.log("Error", err);
    }
  };
  useEffect(() => {
    fetchApi();
    vodingDistrict();
    handleSort(orderBy);
  }, []);
  const searchdatas = (searchdata, getApi) => {
    const searchtext = new RegExp(searchdata, "i");
    const outputString = searchdata.replace(/\s/g, "");
    const searchtext2 = new RegExp(outputString, "i");
    const datas = getApi.filter((item) => {
      var valuess =
        item.partner_name.match(searchtext) || item.email_id.match(searchtext2);
      // item.votingDistrict.match(searchtext2) ||
      // item.mlaConstituency.match(searchtext2) ||
      // item.mpConstituency.match(searchtext2);
      if (valuess != null) {
        return item;
      }
    });
    setUseApi(datas);
    setTotalRows(datas.length);
    setPage(0);
  };
  useEffect(() => {
    let timeout;
    if (dateRange.openStartTool || dateRange.openTooltip) {
      timeout = () => {
        dateRange.openStartTool
          ? handleToolStartClose()
          : dateRange.openTooltip
          ? handleToolClose()
          : !dateRange.openStartTool
          ? handleToolClose()
          : handleToolClose();
      };
    }
    return () => clearTimeout(timeout);
  }, [dateRange.openStartTool, dateRange.openTooltip]);

  if (!loading.isLoading) {
    return (
      <>
        {/* {getAdminSetting.jobSeekerApproval === true && openSnack === true ? (
          <>
            <AutoApproveSnack
              openSnack={openSnack}
              setOpenSnack={setOpenSnack}
              full_name={"Job Seeker"}
              snackOption={"seeker-setting"}
            />
          </>
        ) : null} */}
        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <Breadcrumb />
          <Card
            sx={{ borderRadius: "15px", boxShadow: "3px 3px 10px 3px #dddddd" }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                padding: "10px",
              }}
            >
              <h3 className="seeker-text">Investors Data</h3>
            </Box>
          </Card>
          <Card>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: "20px",
              }}
            >
              <PartnerInvestorStatCard getApi={getApi} />
            </Box>
          </Card>

          <Card sx={{ padding: "20px" }}>
            <Paper sx={{ backgroundColor: "#f2f2f2" }}>
              <Box
                sx={{
                  padding: "20px",
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                }}
              >
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} md={1.7}>
                    <FormControl
                      sx={{ width: { md: "100%", xs: "100%", sm: "100%" } }}
                    >
                      <InputLabel>Filter By Status</InputLabel>
                      <Select
                        variant="standard"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={filterName.status}
                        onChange={(e) => {
                          if (searchicon === true) {
                            setsearchicon(false);
                            setsearchdata("");
                            sessionStorage.setItem("admin_seeker_search", "");
                          }
                          if (dateenable) {
                            handleClickFilter(e.target.value);
                          } else {
                            getFilterValue(
                              e.target.value,
                              filterName.date,
                              getApi,
                              district_name
                            );
                          }
                          setFilterName({
                            ...filterName,
                            status: e.target.value,
                          });
                        }}
                      >
                        <MenuItem value="all">All</MenuItem>
                        <MenuItem value={"S"}>Selected</MenuItem>
                        <MenuItem value={"P"}>Pending</MenuItem>
                        <MenuItem value={"D"}>Decline</MenuItem>
                        <MenuItem value={"H"}>Hold</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  {dateenable === false ? (
                    <Grid item xs={12} sm={6} md={1.7}>
                      {" "}
                      <FormControl
                        sx={{ width: { md: "100%", xs: "100%", sm: "100%" } }}
                      >
                        <InputLabel>Filter By Date</InputLabel>
                        <Select
                          variant="standard"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={filterName.date}
                          onChange={(e) => {
                            if (searchicon === true) {
                              setsearchicon(false);
                              setsearchdata("");
                              sessionStorage.setItem("admin_seeker_search", "");
                            }
                            setFilterName({
                              ...filterName,
                              date: e.target.value,
                            });
                            getFilterValue(
                              filterName.status,
                              e.target.value,
                              getApi,
                              district_name
                            );
                          }}
                        >
                          <MenuItem value="Total">Total</MenuItem>
                          <MenuItem value="today">Today</MenuItem>
                          <MenuItem value="yesterday">Yesterday</MenuItem>
                          <MenuItem value="week">Last Week</MenuItem>
                          <MenuItem value="month">Last month</MenuItem>
                          <MenuItem value="daterange">Select By Date</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  ) : null}
                  <Grid item xs={12} sm={6} md={2}>
                    <FormControl fullWidth>
                      {/* <FormLabel sx={{ fontWeight: "bold" }}>
                        District Name
                      </FormLabel> */}
                      <Autocomplete
                        fullWidth
                        disableClearable={district_name ? false : true}
                        filterOptions={filterOptions}
                        name="district_name"
                        id="votingDistrict"
                        value={district_name}
                        onChange={(event, value, reason) => {
                          setDistrict_name(value);
                          console.log("value", value);
                          getFilterValue(
                            filterName.status,
                            filterName.date,
                            getApi,
                            value
                          );
                          if (reason === "selectOption") {
                            getPincodeByDistrict(value);
                          }
                          if (reason === "clear") {
                            setDistrict_name("");
                            setPincodeByDistrict([]);
                            setTimeout(() => {
                              getFilterValue(
                                filterName.status,
                                filterName.date,
                                getApi,
                                ""
                              );
                              // fetchApi();
                            }, 500);
                          }
                        }}
                        options={districtData}
                        getOptionLabel={(option) => option}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputLabelProps={{
                              style: { marginLeft: "7px" },
                            }}
                            label="District"
                          />
                        )}
                        onOpen={(event) => event.preventDefault()}
                        PopperComponent={({ children, ...popperProps }) => (
                          <Popper
                            {...popperProps}
                            placement="bottom-start"
                            modifiers={{
                              flip: {
                                enabled: false,
                              },
                              preventOverflow: {
                                enabled: true,
                                boundariesElement: "scrollParent",
                              },
                            }}
                            PopperProps={{
                              placement: "bottom-start",
                            }}
                          >
                            <Paper>{children}</Paper>
                          </Popper>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  {dateenable ? (
                    <Grid item xs={12} md={2} sm={6}>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <Box
                          sx={{
                            width: { md: "100%", xs: "100%", sm: "100%" },
                          }}
                        >
                          <DatePicker
                            label="Start Date"
                            value={startDate}
                            format="DD-MM-YYYY"
                            // inputRef={}
                            onChange={handleStartDateChange}
                            onError={(newError) => {
                              setstartError(newError);
                              const boolenvalue =
                                newError === "invalidDate" ||
                                newError === "maxDate" ||
                                newError === "minDate" ||
                                (starterror === null && startDate === null)
                                  ? true
                                  : false;

                              setstartdisabled(boolenvalue);
                            }}
                            slotProps={{
                              textField: {
                                helperText: starterrorMessage,
                                required: true,
                                onFocus: () => {
                                  if (
                                    starterror === null &&
                                    startDate === null
                                  ) {
                                    setStartDate(moment());
                                    setstartdisabled(false);
                                  }
                                },
                              },
                            }}
                            // minDate={minDate}
                            maxDate={endDate || maxDate}
                          />
                        </Box>
                      </LocalizationProvider>
                    </Grid>
                  ) : null}
                  {dateenable ? (
                    <Grid item xs={12} md={2} sm={6}>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <Box>
                          <DatePicker
                            label="End Date"
                            value={endDate}
                            format="DD-MM-YYYY"
                            onChange={handleEndDateChange}
                            onError={(newError) => {
                              setendError(newError);
                              const bollenerror =
                                newError === "invalidDate" ||
                                newError === "minDate" ||
                                newError === "maxDate" ||
                                (enderror === null && endDate === null)
                                  ? true
                                  : false;

                              setenddisabled(bollenerror);
                            }}
                            slotProps={{
                              textField: {
                                helperText: enderrorMessage,
                                required: true,
                                onFocus: () => {
                                  if (enderror === null && endDate === null) {
                                    setEndDate(moment());
                                    setenddisabled(false);
                                  }
                                },
                              },
                            }}
                            minDate={startDate}
                            maxDate={maxDate}
                          />
                        </Box>
                      </LocalizationProvider>
                    </Grid>
                  ) : null}
                  <Grid item xs={12} md={1.7} sm={4} alignItems="center">
                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      {dateenable ? (
                        <>
                          <Tooltip title="Filter" placement="bottom">
                            <Fab
                              disabled={
                                startdisabled === true || enddisabled === true
                                  ? true
                                  : false
                              }
                              size="small"
                              sx={{
                                color: "white",
                                backgroundColor: "#339fcd",
                                boxShadow: 0,
                                "&:hover": { backgroundColor: "#217396" },
                              }}
                              onClick={() => {
                                handleClickFilter(filterName.status);
                              }}
                            >
                              <CheckRoundedIcon />
                            </Fab>
                          </Tooltip>
                          <Tooltip title="Close" placement="bottom">
                            <Fab
                              size="small"
                              sx={{
                                color: "white",
                                backgroundColor: "#339fcd",
                                boxShadow: 0,
                                "&:hover": { backgroundColor: "#217396" },
                              }}
                              onClick={() => {
                                setdateenable(false);
                                handleClickRefresh();
                              }}
                            >
                              <CloseRoundedIcon />
                            </Fab>
                          </Tooltip>
                        </>
                      ) : null}

                      <Tooltip title="Refresh" placement="bottom">
                        <Fab
                          size="small"
                          sx={{
                            color: "white",
                            backgroundColor: "#339fcd",
                            boxShadow: 0,
                            "&:hover": { backgroundColor: "#217396" },
                          }}
                          onClick={handleClickRefresh}
                        >
                          <RefreshSharpIcon />
                        </Fab>
                      </Tooltip>
                    </Box>
                  </Grid>
                </Grid>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    variant="contained"
                    sx={{ textTransform: "capitalize" }}
                    onClick={() => {
                      navigate(
                        `${ROUTINGDATA.LAND}${ROUTINGDATA.BECOMEAINVESTOR}`
                      );
                    }}
                  >
                    Create Investor
                  </Button>
                </div>
              </Box>
              <Box display="flex">
                <Box display="flex" alignItems={"center"}>
                  <InfoOutlinedIcon />
                </Box>
                <b>Note:</b>
                <Box>
                  <Typography variant="caption">
                    Select District name Before allocating pincode to Investor
                  </Typography>
                </Box>
              </Box>
            </Paper>
            <TableContainer
              component={Paper}
              sx={{ marginTop: "10px", boxShadow: 0, maxHeight: 440 }}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow className="table-row">
                    {/* <TableCell> Profile</TableCell> */}
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === "full_name"}
                        direction={orderBy === "full_name" ? order : "asc"}
                        onClick={() => handleSort("full_name")}
                      >
                        Name
                      </TableSortLabel>
                    </TableCell>
                    <TableCell> Email</TableCell>
                    <TableCell> Created Date</TableCell>

                    <TableCell> Mobile</TableCell>
                    <TableCell>
                      {" "}
                      <TableSortLabel
                        active={orderBy === "work_status"}
                        direction={orderBy === "work_status" ? order : "asc"}
                        onClick={() => handleSort("work_status")}
                      >
                        District
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="center">
                      <TableSortLabel
                        active={orderBy === "status"}
                        direction={orderBy === "status" ? order : "asc"}
                        onClick={() => handleSort("status")}
                      >
                        {" "}
                        Status
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="center"> Actions</TableCell>
                    <TableCell align="center">
                      Total Pincode Allocated
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {useApi.length > 0 ? (
                    <>
                      {useApi

                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((item) => (
                          <TableRow key={item._id} hover className="tablecell">
                            <TableCell>{item.investor_name}</TableCell>
                            <TableCell align="left">
                              {" "}
                              {item.email_id}{" "}
                            </TableCell>
                            <TableCell>{item.createdDate}</TableCell>

                            <TableCell>{item.mobileNumber}</TableCell>
                            <TableCell>{item.district_name}</TableCell>
                            <TableCell align="center">
                              <Tooltip
                                title={
                                  item.status === "A"
                                    ? "Approved"
                                    : item.status === "D"
                                    ? "Declined"
                                    : "Pending"
                                }
                                placement="right"
                              >
                                <Chip
                                  label={
                                    item.status === "P"
                                      ? "Pending"
                                      : item.status === "S"
                                      ? "Selected"
                                      : item.status === "H"
                                      ? "In Hold"
                                      : "Declined"
                                  }
                                  color={
                                    item.status === "P"
                                      ? "warning"
                                      : item.status === "S"
                                      ? "success"
                                      : item.status === "H"
                                      ? "info"
                                      : "error"
                                  }
                                  variant="contained"
                                />
                              </Tooltip>
                            </TableCell>
                            <TableCell>
                              <Box
                                display="flex"
                                justifyContent={"center"}
                                gap="10px"
                              >
                                <Tooltip title="Full Details" placement="top">
                                  <IconButton
                                    className="icon-btn"
                                    size="Medium"
                                    onClick={() => {
                                      getOneData(item._id);
                                      handleChange();
                                    }}
                                  >
                                    <OpenInNewRoundedIcon />
                                  </IconButton>
                                </Tooltip>
                                {item.status === "S" ? (
                                  adminDetails_CEO?.emailId !=
                                  process.env
                                    .REACT_APP_EMAILID_FOR_ADMIN_DEVELOPER ? (
                                    <Button
                                      variant="contained"
                                      disabled={
                                        item.allocate_pincode_postOffice
                                          .length > 0
                                          ? true
                                          : false
                                      }
                                      sx={{
                                        textTransform: "capitalize ",
                                        boxShadow: 0,
                                        borderRadius: "16px",
                                      }}
                                      startIcon={<ShareLocationRoundedIcon />}
                                      onClick={() => {
                                        setPartnerN(item.investor_name);
                                        setGetId(item._id);
                                        setAllocatePinDialog(true);
                                      }}
                                    >
                                      {item.allocate_pincode_postOffice.length >
                                      0
                                        ? "pincode Allocated"
                                        : "Allocate Pincode"}
                                    </Button>
                                  ) : null
                                ) : null}
                              </Box>
                            </TableCell>
                            <TableCell align="center">
                              {" "}
                              {item.allocate_pincode_postOffice.length > 0
                                ? item.allocate_pincode_postOffice.length
                                : "Not yet allocated"}
                            </TableCell>
                          </TableRow>
                        ))}
                    </>
                  ) : (
                    <>
                      <TableRow>
                        {/* <TableCell></TableCell> */}
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell>
                          {" "}
                          <Typography
                            variant="body1"
                            sx={{ fontFamily: "Barlow-Bold" }}
                          >
                            NO Data Available
                          </Typography>
                        </TableCell>
                        {/* <TableCell></TableCell> */}
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25]}
              component="div"
              count={totalRows}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
          <InvestorDialog
            open={open}
            handleChange={handleChange}
            getOne={getOne}
            setOpen={setOpen}
            filterName={filterName}
            setFilterName={setFilterName}
            fetchApi={fetchApi}
            dialogLoad={loading.dialogLoad}
            setGetOne={setGetOne}
          />
          <PincodeAllocateDialog
            allocatePinDialog={allocatePinDialog}
            setAllocatePinDialog={setAllocatePinDialog}
            partnerN={partnerN}
            getId={getId}
            pinLoad={pinLoad}
            setPinLoad={setPinLoad}
            updateAllocatePincode={updateAllocatePincode}
            getPincode={getPincode}
            setGetPincode={setGetPincode}
            district_name={district_name}
            setDistrict_name={setDistrict_name}
            openConfirm={openConfirm}
            setOpenConfirm={setOpenConfirm}
            namePI={"Investor"}
            pincodeByDistrict={pincodeByDistrict}
            setPincodeByDistrict={setPincodeByDistrict}
            setPinSnack={setPinSnack}
            areaNameByDistrict={areaNameByDistrict}
            setAreaNameByDistrict={setAreaNameByDistrict}
            pincodeAreaData={pincodeAreaData}
            setPincodeAreaData={setPincodeAreaData}
          />

          {/* <!-----For APPROVE---> */}

          {/* <!----VIDEO_DIALOG */}

          <Dialog
            open={decide}
            onClose={() => setDecide(false)}
            fullWidth
            sx={{
              "& .MuiPaper-root": {
                backgroundColor: "#339fcd",
                borderRadius: "20px",
                height: "50%",
              },
            }}
          >
            <DialogTitle>
              <Box textAlign="right">
                <IconButton onClick={() => setDecide(false)}>
                  <CloseRoundedIcon />
                </IconButton>
              </Box>
            </DialogTitle>
            <DialogContent>
              <video
                className="VideoInput_video"
                width="100%"
                height="100%"
                controls
                src={
                  getOne?.videoUpload
                    ? `${baseUrl}seekers/video/${getOne.mobile_number}`
                    : ""
                }
              />
            </DialogContent>
          </Dialog>
        </Box>
        <AllocatePinSnack
          pinSnack={pinSnack}
          setPinSnack={setPinSnack}
          message={"Investor"}
          getPincode={getPincode}
          partnerN={partnerN}
        />
      </>
    );
  } else {
    return (
      <>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <Card sx={{ padding: "20px", borderRadius: "20px" }}>
            <Skeleton
              animation="wave"
              variant="text"
              width={230}
              height={40}
              sx={{ borderRadius: "15px" }}
            />
          </Card>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            <Card
              sx={{
                borderRadius: "20px",

                padding: "20px",
              }}
            >
              <SkeletonMlaCard />
            </Card>
          </Box>
          <Card
            sx={{
              borderRadius: "20px",

              padding: "20px",

              height: "500px",
              gap: "20px",
            }}
          >
            <Skeleton variant="rounded" />
            <Skeleton variant="rounded" width="100%" height="300px" />
          </Card>
        </Box>
      </>
    );
  }
}

export default AdminInvestors;
