import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import EditIcon from "@mui/icons-material/Edit";
import * as Yup from "yup";
import Tooltip from "@mui/material/Tooltip";
import useAxios from "../../../../api/useAxios";
import { useLocation } from "react-router";
import { useFormik } from "formik";

import Context from "../Context";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function WorkDetails({ setput }) {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const [open, setOpen] = React.useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleClickOpen = (work_details, PrefferedRole) => {
    setOpen(true);
    formik.setFieldValue("PrefferedRole", PrefferedRole);
    formik.setFieldValue("current_position", work_details.current_position);
    formik.setFieldValue(
      "current_companyName",
      work_details.current_companyName
    );
    formik.setFieldValue("notice_period", work_details.notice_period);
    formik.setFieldValue("current_CTC", work_details.current_CTC);
    formik.setFieldValue("expected_CTC", work_details.expected_CTC);
  };

  const handleClose = () => {
    formik.handleReset("");
    setOpen(false);
  };
  const axiosData = useAxios();

  const location = useLocation();

  const formik = useFormik({
    initialValues: {
      current_position: "",
      current_companyName: "",
      notice_period: "",
      current_CTC: "",
      expected_CTC: "",
      PrefferedRole: "",
    },

    validationSchema: Yup.object({
      current_position: Yup.string().required(
        "Current Position must be required"
      ),
      current_companyName: Yup.string().required(
        "Company name must be required"
      ),
      notice_period: Yup.string().required("Notice Period must be required"),
      current_CTC: Yup.string().required("Current CTC must be required"),
      expected_CTC: Yup.string(),
      PrefferedRole: Yup.string().required("Preferred Role must be required"),
    }),
    onSubmit: async (values) => {
      setIsSubmitting(true);
      await axiosData.put(`seeker/email/${location.state}`, {
        work_details: {
          current_position: values.current_position,
          current_companyName: values.current_companyName,
          notice_period: values.notice_period,
          current_CTC: values.current_CTC,
          expected_CTC: values.expected_CTC,
        },
        PrefferedRole: values.PrefferedRole,
      });
      setput(true);
      setIsSubmitting(false);
      formik.handleReset("");
      handleClose();
    },
  });
  const handleEducationChange = () => {
    // const selectedEducation = event.target.value;
    formik.setFieldValue("PrefferedRole", "");
    formik.setFieldValue("current_position", "");
    formik.setFieldValue("current_companyName", "");
    formik.setFieldValue("notice_period", "");
    formik.setFieldValue("current_CTC", "");
    formik.setFieldValue("expected_CTC", "");
  };
  const [schoolType, setSchoolType] = useState([
    "Immediate",
    "15 Days",
    "30 Days",
    "60 Days",
    "90 Days",
  ]);
  return (
    <Context.Consumer>
      {(context) => {
        let WorkDetails = "";
        if (context.profile.work_details) {
          WorkDetails = context.profile.work_details;
        }

        return (
          <div>
            <Dialog
              open={open}
              TransitionComponent={Transition}
              keepMounted
              aria-describedby="alert-dialog-slide-description"
              sx={{ "& .MuiPaper-root": { width: "500px" } }}
            >
              <form onSubmit={formik.handleSubmit}>
                <DialogTitle>
                  <Typography variant="h5">
                    <b>Work Details</b>
                  </Typography>
                  <Grid item xs={12} sm={12} md={8} square>
                    <Typography
                      sx={{
                        fontWeight: 600,
                        fontSize: "16px",
                        mt: 1,
                      }}
                      color="text.secondary"
                    >
                      Mention your work details.
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Box sx={{ p: 3 }}>
                        <Tooltip title="Enter your current position" arrow>
                          <FormControl
                            sx={{ mt: 2 }}
                            className="Educationfieldsize"
                          >
                            <TextField
                              placeholder="Enter your Current Position"
                              value={formik.values.current_position}
                              onChange={(e) => {
                                const value = e.target.value;
                                formik.setFieldValue("current_position", value);
                              }}
                              onBlur={formik.handleBlur}
                              id="current_position"
                              label="Current Position"
                              name="current_position"
                            />
                            {formik.touched.current_position &&
                              formik.errors.current_position && (
                                <Typography
                                  sx={{ color: "red", fontSize: "13px" }}
                                >
                                  {formik.errors.current_position}
                                </Typography>
                              )}
                          </FormControl>
                        </Tooltip>
                        <Tooltip title="Enter your Preferred position" arrow>
                          <FormControl
                            sx={{ mt: 2 }}
                            className="Educationfieldsize"
                          >
                            <TextField
                              placeholder="Enter your Preferred Position"
                              value={formik.values.PrefferedRole}
                              onChange={(e) => {
                                const value = e.target.value;
                                formik.setFieldValue("PrefferedRole", value);
                              }}
                              onBlur={formik.handleBlur}
                              id="PrefferedRole"
                              label="Preferred Position"
                              name="PrefferedRole"
                            />
                            {formik.touched.PrefferedRole &&
                              formik.errors.PrefferedRole && (
                                <Typography
                                  sx={{ color: "red", fontSize: "13px" }}
                                >
                                  {formik.errors.PrefferedRole}
                                </Typography>
                              )}
                          </FormControl>
                        </Tooltip>
                        <Tooltip title="Enter your current company name" arrow>
                          <FormControl
                            sx={{ mt: 2 }}
                            className="Educationfieldsize"
                          >
                            <TextField
                              placeholder="Enter your current company name"
                              value={formik.values.current_companyName}
                              onChange={(e) => {
                                const value = e.target.value;
                                formik.setFieldValue(
                                  "current_companyName",
                                  value
                                );
                              }}
                              onBlur={formik.handleBlur}
                              id="current_companyName"
                              label="Current Company Name"
                              name="current_companyName"
                            />
                            {formik.touched.current_companyName &&
                              formik.errors.current_companyName && (
                                <Typography
                                  sx={{ color: "red", fontSize: "13px" }}
                                >
                                  {"Current Company Name must be required"}
                                </Typography>
                              )}
                          </FormControl>
                        </Tooltip>
                        <Tooltip title="Choose your notice period" arrow>
                          <FormControl
                            sx={{ mt: 2 }}
                            className="Educationfieldsize"
                          >
                            <Autocomplete
                              disableClearable={
                                formik.values.notice_period ? false : true
                              }
                              name="notice_period"
                              id="notice_period"
                              options={schoolType}
                              value={formik.values.notice_period}
                              onChange={(e, newValue) => {
                                formik.setFieldValue("notice_period", newValue);
                              }}
                              onBlur={formik.handleBlur}
                              renderInput={(params) => (
                                <TextField {...params} label="Notice Period" />
                              )}
                            />
                            {formik.touched.notice_period &&
                              formik.errors.notice_period && (
                                <Typography
                                  sx={{ color: "red", fontSize: "13px" }}
                                >
                                  {"Notice Period must required"}
                                </Typography>
                              )}
                          </FormControl>
                        </Tooltip>
                        <Tooltip title="Enter Current CTC" arrow>
                          <FormControl
                            sx={{ mt: 2 }}
                            className="Educationfieldsize"
                          >
                            <TextField
                              placeholder="Enter Current CTC"
                              value={formik.values.current_CTC}
                              onChange={(e) => {
                                const value = e.target.value;
                                formik.setFieldValue("current_CTC", value);
                              }}
                              onBlur={formik.handleBlur}
                              id="current_CTC"
                              label="Current CTC"
                              name="current_CTC"
                            />

                            {formik.touched.current_CTC &&
                              formik.errors.current_CTC && (
                                <Typography
                                  sx={{ color: "red", fontSize: "13px" }}
                                >
                                  {formik.errors.current_CTC}
                                </Typography>
                              )}
                          </FormControl>
                        </Tooltip>
                        <Tooltip title="Enter Expected CTC" arrow>
                          <FormControl
                            sx={{ mt: 2 }}
                            className="Educationfieldsize"
                          >
                            <TextField
                              placeholder="Enter Expected CTC"
                              value={formik.values.expected_CTC}
                              onChange={(e) => {
                                const value = e.target.value;
                                formik.setFieldValue("expected_CTC", value);
                              }}
                              onBlur={formik.handleBlur}
                              id="expected_CTC"
                              label="Expected CTC"
                              name="expected_CTC"
                            />
                            <FormHelperText>
                              {formik.touched.expected_CTC &&
                              formik.errors.expected_CTC ? (
                                <Typography
                                  sx={{
                                    color: "red",
                                    fontSize: "13px",
                                    ml: -0.8,
                                  }}
                                >
                                  {formik.errors.expected_CTC}
                                </Typography>
                              ) : (
                                <Typography
                                  sx={{
                                    fontSize: "13px",
                                    visibility: "hidden",
                                  }}
                                >
                                  none
                                </Typography>
                              )}
                            </FormHelperText>
                          </FormControl>
                        </Tooltip>
                      </Box>
                    </Box>
                  </Grid>
                </DialogTitle>
                <DialogActions sx={{ mr: 2, mt: -6, mb: 1 }}>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={
                      formik.isValid && formik.dirty && isSubmitting === false
                        ? false
                        : true
                    }
                    variant="contained"
                    type="submit"
                  >
                    Update
                    {isSubmitting && (
                      <CircularProgress
                        size={24}
                        style={{
                          color: "#ffffff",
                          marginLeft: 12,
                        }}
                      />
                    )}
                  </Button>
                </DialogActions>
              </form>
            </Dialog>
            <Grid xs={12} md={12} lg={12}>
              <Box
                id="WorkDetails"
                sx={{ fontSize: "18px", textTransform: "uppercase" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <b style={{ color: "rgb(16 143 178)" }}>Work Details *</b>
                  <Button
                    sx={{ ml: -1.5, mt: -1 }}
                    onClick={() =>
                      handleClickOpen(
                        WorkDetails,
                        context.profile.PrefferedRole
                      )
                    }
                  >
                    <Tooltip title="Edit" arrow>
                      <EditIcon />
                    </Tooltip>
                  </Button>
                </Box>
              </Box>
              <hr />
              {WorkDetails.current_position ? (
                <>
                  {" "}
                  <Typography sx={{
                      fontWeight: 600,
                      display: "grid",
                      gridTemplateColumns: "25% 5% 70%",
                      marginBottom:"5px"
                    }}>
                    <div>Current Position</div>
                    <div>:</div>
                    <div><Typography
                        color="text.secondary"
                        sx={{ fontWeight: 600 }}
                      >{WorkDetails.current_position} </Typography></div>
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      display: "grid",
                      gridTemplateColumns: "25% 5% 70%",
                      marginBottom:"5px"
                    }}
                  >
                    <div>Preferred Position</div>
                    <div>:</div>
                    <div>
                      <Typography
                        color="text.secondary"
                        sx={{ fontWeight: 600 }}
                      >
                        {context.profile.PrefferedRole}{" "}
                      </Typography>
                    </div>
                  </Typography>
                  <Typography sx={{
                      fontWeight: 600,
                      display: "grid",
                      gridTemplateColumns: "25% 5% 70%",
                      marginBottom:"5px"
                    }}>
                    <div>Current Company Name</div>
                    <div>:</div>
                    <div><Typography
                        color="text.secondary"
                        sx={{ fontWeight: 600 }}
                      >{WorkDetails.current_companyName} </Typography></div>
                  </Typography>
                  <Typography  sx={{
                      fontWeight: 600,
                      display: "grid",
                      gridTemplateColumns: "25% 5% 70%",
                      marginBottom:"5px"
                    }}>
                    <div>Notice Period</div>
                    <div>:</div>
                    <div><Typography
                        color="text.secondary"
                        sx={{ fontWeight: 600 }}
                      >{WorkDetails.notice_period} </Typography></div>
                  </Typography>
                  <Typography  sx={{
                      fontWeight: 600,
                      display: "grid",
                      gridTemplateColumns: "25% 5% 70%",
                      marginBottom:"5px"
                    }}>
                    <div>Current CTC</div>
                    <div>:</div>
                    <div><Typography
                        color="text.secondary"
                        sx={{ fontWeight: 600 }}
                      >{WorkDetails.current_CTC} </Typography></div>
                  </Typography>
                  <Typography  sx={{
                      fontWeight: 600,
                      display: "grid",
                      gridTemplateColumns: "25% 5% 70%",
                      marginBottom:"5px"
                    }}>
                    <div>Expected CTC</div>
                    <div>:</div>
                    <div><Typography
                        color="text.secondary"
                        sx={{ fontWeight: 600 }}
                      >{WorkDetails.expected_CTC} </Typography></div>
                  </Typography>
                </>
              ) : (
                <Typography
                  sx={{
                    fontWeight: 600,
                    textTransform: "uppercase",
                    fontSize: "14px",
                    mt: 1,
                  }}
                  color="text.secondary"
                >
                  Mention your work details.
                </Typography>
              )}
            </Grid>
          </div>
        );
      }}
    </Context.Consumer>
  );
}

export default WorkDetails;
